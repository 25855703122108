import React from 'react';
import logo from '../../assets/images/cloud-forest-x-logo.png';
import logoX from '../../assets/images/x-logo.png';
import logoB from '../../assets/images/cloud-forest-x-logo-b.png';
import logoXB from '../../assets/images/x-logo-b.png';
import { Link } from 'react-router-dom';

export const CFLogo: React.FC = () => {
  return (
    <div className="cf-Logo">
      <img src={logo} className="cfTree" />
      <span>
        <span className="cloud">Cloud</span>
        <span className="forest">Forest</span>
      </span>
      <div className="cfx">
        <img src={logoX} />
      </div>
    </div>
  );
};

export const CFLogoB: React.FC = () => {
  return (
    <div className="cf-Logo blue">
      <Link to="/" className="d-flex">
        <img src={logoB} className="cfTree" />
        <span>
          <span className="cloud">Cloud</span>
          <span className="forest">Forest</span>
        </span>
        <div className="cfx">
          <img src={logoXB} />
        </div>
      </Link>
    </div>
  );
};

export default CFLogo;
