import React from 'react';
import './Billing.css';
import { ReactComponent as Rightsizing } from '../../assets/images/rightsizing.svg';
import { ReactComponent as IdleResources } from '../../assets/images/IdleResources.svg';
import { ReactComponent as ScheduleInstances } from '../../assets/images/ScheduleInstances.svg';
import { ReactComponent as CostSaving } from '../../assets/images/costSaving.svg';
import { ReactComponent as Inventory } from '../../assets/images/Inventories.svg';
import { ReactComponent as CostForecasting } from '../../assets/images/costForecasting.svg';
import { ReactComponent as BillingSimplefied } from '../../assets/images/BillingSimplified.svg';
import { ReactComponent as SecurityScan } from '../../assets/images/securityScan.svg';
import { ReactComponent as UptimeMonitoring } from '../../assets/images/monitoring.svg';

const AllServices: any = {
  RIGHTSIZING: 'Rightsizing',
  'IDLE RESOURCES': 'Idle Resources',
  'SCHEDULE INSTANCES': 'Schedule Instances',
  'COST SAVING': 'Cost Saving',
  INVENTORY: 'Inventories',
  'COST FORECASTING': 'Cost Forecasting',
  'BILLING SIMPLIFIED': 'Billing Simplified',
  'SECURITY SCAN': 'Security',
  'UPTIME MONITORING': 'Monitoring',
};
const AllServicesIcons: any = {
  RIGHTSIZING: <Rightsizing width={'80%'} />,
  'IDLE RESOURCES': <IdleResources width={'80%'} />,
  'SCHEDULE INSTANCES': <ScheduleInstances width={'80%'} />,
  'COST SAVING': <CostSaving width={'80%'} />,
  INVENTORY: <Inventory width={'80%'} />,
  'COST FORECASTING': <CostForecasting width={'80%'} />,
  'BILLING SIMPLIFIED': <BillingSimplefied width={'80%'} />,
  'SECURITY SCAN': <SecurityScan width={'80%'} />,
  'UPTIME MONITORING': <UptimeMonitoring width={'80%'} />,
};

const PlanDetails: React.FC = (props: any) => {
  const ActiveFeatures = props.location.state.ActiveFeatures;
  const InactiveFeatures = Object.keys(AllServices).filter(
    (service: string) => !ActiveFeatures.includes(service)
  );
  const PlanPrice = props.location.state.PlanPrice;
  const PlanImage = props.location.state.PlanImage;
  const PlanName = props.location.state.PlanName;
  const PlanDate = props.location.state.PlanDate;
  const PlanExpiryDays = props.location.state.PlanExpiryDays;

  const PlanExpiryDate = new Date(
    new Date(PlanDate).setDate(new Date(PlanDate).getDate() + PlanExpiryDays)
  ).toLocaleDateString();
  return (
    <div className="PD-OuterContainer">
      <div>
        <div className="PD-Title">Active Plan</div>
        <div className="PD-PlanNameCont">
          <div className="PD-Flex">
            <div className="ml-30 mt-40">
              <div className="PD-PlanName">{PlanName}</div>
              <div className="PD-PlanPrice mt-5">${PlanPrice}</div>
              <div className="PD-ExpDate mt-30">Exp. Date</div>
              <div className="PD-ExpDateText mt-5">{PlanExpiryDate}</div>
            </div>
            <div className="ml-130 mt-30 imgBox">
              <img src={PlanImage} alt="PlanImage" style={{ width: '70%' }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="PD-Title">Active Services</div>
          <div className="PD-ActiveServicesOutCont mt-5">
            {ActiveFeatures.map((feature: string) => (
              <div className="PD-FeatureBox mt-20 ">
                <div>{AllServicesIcons[feature]}</div>
                <div>{AllServices[feature]}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="PD-Title mt-20">Suggested Services</div>
          <div className="PD-InactiveServicesOutCont mt-5">
            {InactiveFeatures.length ? (
              InactiveFeatures.map((feature: string) => (
                <div className="PD-FeatureBox mt-20 ">
                  <div>{AllServicesIcons[feature]}</div>
                  <div>{AllServices[feature]}</div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: 'center' }} className="mt-20">
                No Suggested Service
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
