import React from 'react';
import { Grid } from '@material-ui/core';

import { Route } from 'react-router-dom';
import { BarGraphAndPieChart } from './BarGraphAndPieChart/BarGraphAndPieChart';
import { RoutesFun } from '../../schemas';

const CostForecasting: React.FC = () => {
  const Routes= RoutesFun();
  return (
    <Grid container>
      <Route path={Routes.CostForecasting} component={BarGraphAndPieChart} />
    </Grid>
  );
};

export default CostForecasting;
