import moment from 'moment';
import { rangeToStatuses } from '../../components/UrlMonitoring/config';
import {
  customNotificationService,
  slackNotificationManagementService,
  uptimeService,
  urlMonitoringService,
} from '../../services';
import { PRIMARY_REGION } from '../../utils/constants';
import { MONITORING_TYPES } from '../../components/Modals/UptimeMonitoringModal/config';

export interface FetchMonitoring {
  FriendlyName: string;
  Region: string;
  Type: string;
  TimeRange: string;
}

export interface MonitoringXLSReport {
  urlId: number;
  // timeRange: string;
  startDate: number;
  timeZone: string;
}

export interface NotificationLogsProps {
  urlId: number;
  startDate: number;
  endDate: number;
}

interface UrlNotification {
  Id: number;
  UrlId: number;
  FriendlyName: string;
  NotificationType: string;
  Value: string;
  NotificationId: number;
  createdAt: string;
  updatedAt: string;
}

export interface URLMonitoringData {
  Id: number;
  CompanyId: number;
  FriendlyName: string;
  WebUrl: string | any;
  Type: string;
  IsMonitoring: boolean;
  LastActive: boolean;
  IsSSL: boolean;
  Timeout: number;
  Frequency: number;
  IsDeleted: boolean;
  Method: string;
  Body: any; // Assuming this can be null or an object
  Headers: any; // Assuming this can be null or an object
  Port: any; // Assuming this can be null or a number
  refreshTokenAPI: any; // Assuming this can be null or an object
  acceptedStatuses: number[];
  Region: string;
  Maintenance: any; // Assuming this can be null or a string
  SSLStatus: any; // Assuming this can be null or a string
  LastChecked: string;
  SSLLastChecked: any; // Assuming this can be null or a string
  SSLExpiry: any; // Assuming this can be null or a string
  LastNotified: string;
  NotificationInterval: number;
  NotificationCount: number;
  EscalationThreshold: number;
  MonitoringLabels: any; // Assuming this can be null or an object
  createdAt: string;
  updatedAt: string;
  UrlNotifications: UrlNotification[];
}

interface configInterface {
  addURL: (params: any) => any;
  getURLs: (pageNo: number, Type: string) => any;
  deleteURL: (params: any) => any;
  editURL: (params: any) => any;
  fetchMonitoringDetail: (params: FetchMonitoring) => any;
  fetchSlackData: () => any;
  getCompanyEmails: () => any;
  addUrlMaintenance: (params: any) => any;
  getOrgGchat: () => any;
  editUrlMaintenance: (params: any) => any;
  toggleMonitoring: (params: any) => any;
  environmentLabels: () => any;
  getMonitoringReport: (params: MonitoringXLSReport) => any;
}

export const config: configInterface = {
  addURL: (params: any) => uptimeService.addURL(params),
  getURLs: (pageNo: number, Type: string) =>
    uptimeService.getURLs(pageNo, Type),
  deleteURL: (params: any) => uptimeService.deleteURL(params),
  editURL: (params: any) => uptimeService.editURL(params),
  fetchMonitoringDetail: (params: FetchMonitoring) =>
    uptimeService.fetchMonitoringDetail(params),
  fetchSlackData: () => slackNotificationManagementService.FetchSlackCreds(),
  getCompanyEmails: () => slackNotificationManagementService.FetchEmails(),
  addUrlMaintenance: (params: any) =>
    urlMonitoringService.addUrlMaintenance(params),
  getOrgGchat: () => customNotificationService.getGchatCredsData(),
  editUrlMaintenance: (params: any) =>
    urlMonitoringService.editUrlMaintenance(params),
  toggleMonitoring: (params: any) => uptimeService.toggleMonitoring(params),
  environmentLabels: () => uptimeService.fetchEnvironmentLabels(),
  getMonitoringReport: (params: MonitoringXLSReport) =>
    uptimeService.downloadMonitoringReport(params),
};

export const acceptedStatusHandler = (urlData: any, status?: any) => {
  return urlData.acceptedStatuses
    ?.reduce((acc: number[], item: number) => {
      if (Object.keys(rangeToStatuses).includes(item.toString())) {
        acc = [...acc, ...rangeToStatuses[item]];
      } else {
        acc = [...acc, item];
      }
      return acc;
    }, [])
    .includes(status ? status : urlData.MonitoringData[0]?.Status);
};

export const returnSearchResults = (
  data: any,
  searchText: string,
  tabValue: string
) =>
  searchText
    ? Object.entries(data).reduce((obj: any, [key, value]: [string, any]) => {
        const lowerCaseSearchText = searchText.trim().toLowerCase();
        const lowerCaseKey = key.trim().toLowerCase();

        // Check if the key matches the search text
        if (lowerCaseKey.includes(lowerCaseSearchText)) {
          obj[key] = value;
          return obj;
        }

        // Check if any WebUrl matches the search text

        const matchingUrls = value.filter((item: any) => {
          const domain =
            tabValue === MONITORING_TYPES.HTTPs
              ? item.WebUrl && new URL(item.WebUrl).hostname
              : item.WebUrl + item.Port?.toString();
          return domain.toLowerCase().includes(lowerCaseSearchText);
        });

        if (matchingUrls.length > 0) {
          obj[key] = matchingUrls;
        }

        return obj;
      }, {})
    : data;

export const returnEnvironmentSelectResult = (URLData: any, label: string) =>
  label !== ''
    ? Object.fromEntries(
        Object.entries(URLData).filter(([, value]: any) => {
          const firstMonitoringLabels = value?.[0]?.MonitoringLabels;

          return (
            firstMonitoringLabels &&
            firstMonitoringLabels.flat().includes(label)
          );
        })
      )
    : URLData;
export const quickStatusFormatter = async (urlData: any) => {
  const totalEntries = Object.values(urlData).flat().length;
  const monitoringCount: any = Object.values(urlData)
    .flat()
    .reduce(
      (acc: any, obj: any) => {
        const key = obj.Value ? 'upTimeCount' : 'downTimeCount';
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      },
      { upTimeCount: 0, downTimeCount: 0 }
    );

  const { upTimeCount, downTimeCount } = monitoringCount;

  const result = Object.entries(urlData).reduce(
    (acc: any, [region, data]: any) => {
      const firstResponseTime = Object.keys(urlData)?.includes(PRIMARY_REGION)
        ? urlData[PRIMARY_REGION][urlData[PRIMARY_REGION].length - 1]
            ?.ResponseTime
        : data[data.length - 1]?.ResponseTime || 0;
      const avgResponseSum = data.reduce(
        (sum: any, entry: any) => sum + entry.ResponseTime,
        0
      );

      return {
        firstValue:
          acc.firstValue === undefined
            ? {
                region: Object.keys(urlData)?.includes(PRIMARY_REGION)
                  ? PRIMARY_REGION
                  : region,
                responseTime: firstResponseTime,
              }
            : acc.firstValue,
        avgResponse: acc.avgResponse + avgResponseSum,
        upTime: acc.upTime,
        downTime: acc.downTime,
      };
    },
    { avgResponse: 0, upTime: 0, downTime: 0, firstValue: undefined }
  );

  result.avgResponse /= totalEntries || 1;
  result.upTime = (upTimeCount / totalEntries) * 100 || 0;
  result.downTime = (downTimeCount / totalEntries) * 100 || 0;

  return result;
};

export const parseDateString = (dateString: string) => {
  const [day, month, year, hours, minutes] = dateString.split(/[\s-:]+/);
  return new Date(+year, +month - 1, +day, +hours, +minutes);
};

interface Entry {
  Status: number;
  Date: string;
  Time: string;
  DateTime?: string;
  Reason: string;
  ResponseTime: number;
  Region: string;
}

interface RegionData {
  [key: string]: Entry[];
}

export const formatDataToLocalTime = (data: RegionData): RegionData => {
  Object.values(data).forEach((regionData: Entry[]) => {
    regionData.map((entry: Entry) => {
      entry.DateTime = moment
        .utc(`${entry.Date} ${entry.Time}`)
        .local()
        .format('DD-MM-YYYY HH:mm');
    });
  });
  return data;
};

export const globalLocalTimeFormatter = (Date: string, Time: string) => {
  return moment.utc(`${Date} ${Time}`).local().format('DD-MM-YYYY HH:mm');
};

export const underMaintenanceDurationHandler = (item: any) => {
  return (
    new Date().getTime() < +item.Maintenance.split('-')[1] &&
    new Date().getTime() > +item.Maintenance.split('-')[0]
  );
};

interface TypeInterface {
  uptime: string;
  downtime: string;
  maintenance: string;
  paused: string;
  reset: string;
}

export const tileType: TypeInterface = {
  uptime: 'uptime',
  downtime: 'downtime',
  maintenance: 'maintenance',
  paused: 'paused',
  reset: 'reset',
};

export const DEFAULT_LABELS: string[] = [
  'production',
  'internal',
  'inhouse',
  'staging',
  'development',
];

export const MONITORING_XLX_TIME_RANGE = {
  weekly: 'weekly',
  monthly: 'monthly',
  bimonthly: 'bimonthly',
  trimonthly: 'trimonthly',
};
