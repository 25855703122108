import { HttpService } from '../HttpService/HttpService';

export class TagDetailService {
  constructor(private httpService: HttpService) {}

  async AwsTagDetailsByResource(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/invoice/awsTagDetailsByResource',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async AzureTagDetailsByResource(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/invoice/azureTagDetailsByResource',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
