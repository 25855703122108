import { HttpService } from '../HttpService/HttpService';
import { AccountFieldProps, AccountProps } from '../../schemas/account.schema';
import { LOCAL_STORAGE_VARIABLES } from '../../utils/constants';
import { APIS } from '../../utils/apiConfig';

export class AccountService {
  constructor(private httpService: HttpService) {}

  async getAccountDetails(account: string): Promise<any> {
    try {
      if (account === 'GOOGLE') {
        const response: any = [];
        return response;
      }
      const response = await this.httpService.get(
        `/accounts/getAllAccounts?CloudProvider=${account}`
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getAllSubscriptionDetails() {
    try {
      const response = await this.httpService.get(
        '/accounts/getAllSubscriptions'
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getAllAccounts() {
    try {
      const response = await this.httpService.get(APIS.GET_ACCOUNT_LIST());
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getSubscriptionDetails(id: any, CloudProvider: string): Promise<any> {
    try {
      if (id) {
        const response = await this.httpService.get(
          `/accounts/getAccountDetails?AccountId=${id}&CloudProvider=${CloudProvider}`
        );

        const { data } = response;
        if (data.sc == 1) {
          return data.result;
        }
        return response;
      }
    } catch (e) {
      throw e;
    }
  }

  async getLocations(): Promise<any> {
    try {
      const response = await this.httpService.get('/accounts/getLocations');
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getServiceTypes(): Promise<any> {
    try {
      const response = await this.httpService.get(
        '/accounts/getAzureAccountServiceTypes'
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getAccountTypes(): Promise<any> {
    try {
      const response = await this.httpService.get(
        '/accounts/getAzureAccountTypes'
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async createAccount(params: AccountFieldProps): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/accounts/createAccount',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async editAccount(params: AccountProps): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/accounts/updateAccount',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async deleteAccount(params: {
    Id: number;
    CloudProvider: string;
  }): Promise<any> {
    try {
      const response = await this.httpService.delete(
        '/accounts/deleteAccount',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getAccountData(Id: number, cloudProvider: string): Promise<any> {
    try {
      const params: { Id: number; CloudProvider: string } = {
        Id: Id,
        CloudProvider: cloudProvider,
      };
      const response = await this.httpService.get('/accounts/getAccount/', {
        params,
      });
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getSelectedAccount(): Promise<any> {
    try {
      const response = await this.httpService.get(
        '/accounts/getSelectedAccount/'
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async setAccount(params: AccountProps): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/accounts/selectAccount/',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  setLoginData = (email: any, password: any) => {
    localStorage.setItem('emailId', email);
    localStorage.setItem('password', password);
  };
  getLoginData = (data: string) => {
    return localStorage.getItem(data) ? localStorage.getItem(data) : '';
  };

  async getRefreshToken(): Promise<any> {
    try {
      const response = await this.httpService.get('/users/refreshToken');

      const { data } = response;
      if (data.sc == 1) {
        localStorage.setItem('auth-state', data.result?.token);
        localStorage.setItem(
          LOCAL_STORAGE_VARIABLES.LAST_TOKEN_REFRESH,
          new Date().toISOString()
        );
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
}
