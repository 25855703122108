import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { sortAndDeduplicateDiagnostics } from 'typescript';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderTop: {
      borderTop: '2px solid #1582dc',
    },
    monthToDateHeader: {
      fontSize: '1.4rem',
      marginTop: '1rem',
      paddingLeft: '1rem',
    },
    monthToDateEarnings: {
      fontSize: '2.4rem',
      color: '#2B8DDF',
      paddingLeft: '1rem',
      marginTop: '1rem',
    },
    chartsContainer: {
      marginTop: '3rem',
    },
    chartBox: {
      width: '50%',
    },
    paper: {
      width: '100%',
      boxShadow:'none',
      border:'1px solid #ccc'
    },
    chartTitle: {
      padding: '2rem',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    barContainer: {
      width: '100%',
      height: '430px',
      marginTop: '10px',
    },
  })
);

export default useStyles;
