import axios from 'axios';
import config from '../../config/GlobalConfig';
import { AuthService } from '../AuthService/AuthService';
import { TOKEN_TIMING } from '../../utils/constants';
// import qs from 'qs';

let flag = true;

export class APIService {
  private token: string = '';

  constructor(private authService: AuthService) {}
  create() {
    this.token = this.authService.getToken();
    const apiInstance = axios.create({
      baseURL: `${config.apiBaseUrl}api/`,
      //timeout: 300000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this.token,
      },
      // paramsSerializer: (params) => {
      //   return qs.stringify(params, { encode: false });
      // },
    });

    apiInstance.interceptors.response.use(
      async (response) => {
        const expiryTime: number = +localStorage.getItem('token_expiry_time')!;

        //if expiry time of token and present time difference is less then 15 min then hit refresh token api and set new auth
        if (
          expiryTime &&
          expiryTime - Date.now() <
            TOKEN_TIMING.REFRESH_TOKEN_API_HITTING_TIME &&
          flag
        ) {
          flag = false;
          const res: any = await axios.get(
            `${config.apiBaseUrl}api/users/refreshToken`,
            {
              headers: {
                Authorization: localStorage.getItem('auth-state'),
              },
            }
          );

          localStorage.setItem(
            'token_expiry_time',
            (Date.now() + TOKEN_TIMING.TOKEN_EXPIRY_TIME).toString()
          );

          localStorage.setItem('auth-state', res.data.result.token.toString());
          setTimeout(() => {
            flag = true;
          }, 1000 * 60);
        }

        return response;
      },
      (error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = '/unauthorized';
        } else if (error.response && error.response.status === 401) {
          // console.log('refresh token expired');

          // window.location.href = ;
          return Promise.reject(error.response.data);
        } else if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response);
      }
    );
    return apiInstance;
  }
}
