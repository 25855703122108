import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';
import { handleError } from '../../utils/config';
import { useHistory } from 'react-router';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { config } from '../Reports/config';

const InventoryResourceReport: React.FC = (props: any) => {
  const ResourceName = props.location.state.resourceName;
  const SubscriptionId = props.location.state.subscriptionId;
  const AzureId = props.location.state.azureId;
  const AccountId = props.location.state.accountId;
  const CloudProvider = props.location.state.cloudProvider;
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [resourcesData, setResourcesData] = React.useState<any>({});
  const [loader, setLoader] = React.useState(true);

  const history = useHistory();

  const getData = async () => {
    try {
      let params;
      if (CloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (AzureId === 'all' && SubscriptionId === 'all') {
          params = { CloudProvider, SubscriptionId: 'all' };
        } else if (AzureId !== 'all' && SubscriptionId === 'all') {
          params = { CloudProvider, AzureId: AzureId };
        } else if (AzureId !== 'all' && SubscriptionId !== 'all') {
          params = { CloudProvider, SubscriptionId: SubscriptionId };
        }
      } else {
        if (AccountId === 'all') {
          params = { CloudProvider, SubscriptionId: 'all' };
        } else {
          params = { CloudProvider, SubscriptionId: AccountId };
        }
      }

      params = { ...params, serviceType: ResourceName };

      const result = await config.getServiceResourceCost(params);
      result.summary.sort((a: any, b: any) => b.Cost - a.Cost);
      setResourcesData(result);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loader && <LoadingScreen />}
      <div className="sContainer">
        <Grid item md={12} sm={12} xs={12}>
          <span className="inventoryReportsHeading">{ResourceName}</span>
        </Grid>
      </div>
      {!loader ? (
        <TableContainer className="accContainer">
          <Table className="table " size="small" aria-label="a dense table">
            <TableHead className="tableHead">
              <TableRow>
                <TableCell width="1%" align="center" className="heading">
                  Sr. No.
                </TableCell>
                <TableCell align="left" className="heading">
                  Resource Id
                </TableCell>
                <TableCell align="left" className="heading">
                  Resource Name
                </TableCell>
                <TableCell align="left" className="heading">
                  Previous Month Cost
                </TableCell>
                <TableCell align="left" className="heading">
                  Current Month Cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resourcesData?.summary?.length ? (
                resourcesData?.summary?.map((service: any, index: number) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{service.ResourceId}</TableCell>
                    {CloudProvider === CLOUD_PROVIDER_SERVICES.AZURE ? (
                      <TableCell>{service.SubscriptionName}</TableCell>
                    ) : (
                      <TableCell>{service?.ResourceName}</TableCell>
                    )}
                    <TableCell>
                      {resourcesData?.currency + +service.prevCost}
                    </TableCell>
                    <TableCell>
                      {resourcesData?.currency + +service.Cost}
                    </TableCell>
                  </TableRow>
                ))
              ) : loader ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> Loading...</TableCell>
                </TableRow>
              ) : resourcesData?.summary?.length === 0 ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> No Records</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default InventoryResourceReport;
