import moment from 'moment';
import React from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { STATUS } from '../../utils/constants';

const toPascalCase = (str: string) => {
  if (!str) return '';
  return `${str[0].toUpperCase()}${str.substring(1)}`;
};

interface NotificationBodyProps {
  details: any;
  showUpdatedBy?: boolean;
  onClick?: () => void;
}

interface NotificationProps {
  className: string;
  totalNotifications: number;
  isItemLoaded: (index: number) => boolean;
  notificationList: any[];
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<any> | null;
  itemCount: number;
  getItemSize: (index: number) => number;
}

export const NotificationBody: React.FC<NotificationBodyProps> = (
  props: any
) => {
  const { details, showUpdatedBy = false, onClick } = props;

  return (
    <div>
      <span
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        onClick={onClick}
      >
        {/*item.InstanceName*/} {details?.Description}
      </span>
      <div className="updatedBy_nTime">
        <span className="nTime">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-clock-history"
            viewBox="0 0 16 16"
          >
            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
          </svg>
          <i className="ml-5">
            {moment(moment.utc(details?.createdAt).toDate())
              .local()
              .format('MMM DD, YYYY HH:mm:ss')}
          </i>
        </span>
        {showUpdatedBy && details?.UpdatedBy ? (
          <span className="nUpdatedBy">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person"
              viewBox="0 0 16 16"
            >
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
            </svg>
            <i className="ml-5">
              {toPascalCase(details?.FirstName) +
                ' ' +
                toPascalCase(details?.LastName)}
            </i>
          </span>
        ) : null}
      </div>
    </div>
  );
};

export const Notifications: React.FC<NotificationProps> = (props) => {
  const {
    totalNotifications,
    isItemLoaded,
    notificationList,
    loadMoreItems,
    getItemSize,
  } = props;

  const Item = (props: any) => {
    const item: any = notificationList[props.index];

    if (!isItemLoaded(props.index)) {
      return (
        <></>
        // <li style={props.style} className="InProgress">
        //   <span> Loading... </span>
        // </li>
      );
    }

    return (
      <li
        style={props.style}
        className={`${
          item.Status == STATUS['In Progress'] || item.Status == 'incomplete'
            ? 'InProgress'
            : STATUS[item.Status]
        } mb-10`}
        key={props.index}
      >
        <span>
          {item.Status == STATUS['In Progress'] ||
          item.Status == 'incomplete' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          ) : (
            ''
          )}

          {item.Status == STATUS.Failed ||
          item.Status == 'requires_source' ||
          item.Status == 'past_due' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-exclamation-triangle"
              viewBox="0 0 16 16"
            >
              <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
              <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
            </svg>
          ) : (
            ''
          )}

          {item.Status == STATUS.Success ||
          item.Status == 'active' ||
          item.Status == 'succeeded' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              fill="currentColor"
              className="bi bi-check2-circle"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
            </svg>
          ) : (
            ''
          )}

          {item.Status == STATUS.Info ||
          item.Status == 'incomplete_expired' ||
          item.Status == 'Cancel Subscription' ||
          item.Status == 'canceled' ? (
            // item.Status ==
            //   Object.keys(STATUS).find((key) => STATUS[key] === item.Status)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          ) : (
            ''
          )}
        </span>

        <NotificationBody details={item} showUpdatedBy={true} />
      </li>
    );
  };

  return (
    <ul className="d-flex" style={{ minWidth: '300px' }}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={totalNotifications}
        loadMoreItems={loadMoreItems}
        threshold={12}
        minimumBatchSize={20}
      >
        {({ onItemsRendered, ref }) => (
          <VariableSizeList
            height={400}
            width="100%"
            itemSize={getItemSize}
            onItemsRendered={onItemsRendered}
            ref={ref}
            {...props}
          >
            {Item}
          </VariableSizeList>
        )}
      </InfiniteLoader>
    </ul>
  );
};
