import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  AppBar,
} from '@material-ui/core';
import { RoutesFun } from '../../../schemas/index';
import CFLogo from '../../../components/cfLogo/cfLogo';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { config } from './config';
import apiConfig from '../../../config/GlobalConfig';
import {
  DOCUMENT_UPLOADE_RESPONSES,
  ERRORS,
  LOCAL_STORAGE_VARIABLES,
} from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import './UploadedDocumentStatic.css';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { ReactComponent as SecurityImg } from '../../../assets/navigationSvg/security.svg';
import { ReactComponent as ReportImg } from '../../../assets/navigationSvg/reports.svg';
import { ReactComponent as BillingImg } from '../../../assets/navigationSvg/billing.svg';
import { ReactComponent as SavingImg } from '../../../assets/navigationSvg/piggyBank.svg';
import { ReactComponent as ForcastImg } from '../../../assets/navigationSvg/barChart.svg';
import { ReactComponent as ScheduleImg } from '../../../assets/navigationSvg/calander.svg';
import { ReactComponent as UptimeMonitoring } from '../../../assets/navigationSvg/UptimeMonitoring.svg';
import { ReactComponent as DocSvg } from '../../../assets/ICONS/docSvg.svg';
import { useDispatch } from 'react-redux';
import { fetchUserData } from '../../../store/thunks';
import { authService } from '../../../services';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const UploadedDocumentStatic = () => {
  const Routes = RoutesFun();
  const [loader, setLoader] = useState(false);
  const [networkError, setNetworkError] = useState('');
  const [docs, setDocs] = useState([]);
  const [companyName, setCompanyName] = useState<string>('');
  const [planStatus, setPlanStatus] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [showStaticPage, setShowStaticPage] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;

  const getUploadedDocuments = async () => {
    try {
      setLoader(true);
      const searchParams = new URLSearchParams(search);
      const token = searchParams.get('token');
      if (token) {
        const result = await config.getUploadedDocumentsStatic(token);

        if (result.message === DOCUMENT_UPLOADE_RESPONSES.LOGIN_SUCCESSFULLY) {
          const newToken = result.result.token;
          localStorage.removeItem('LOGIN_ACCESS');
          localStorage.setItem('token_expiry_time', '0');
          const currDate = new Date().toISOString();
          localStorage.setItem(
            LOCAL_STORAGE_VARIABLES.LAST_TOKEN_REFRESH,
            currDate
          );
          authService.removeAuth();
          authService.setAuth(newToken);
          localStorage.setItem('CompanyName', result.result.CompanyName);
          localStorage.setItem('RoleId', result.result.RoleId);
          localStorage.setItem(
            'token_expiry_time',
            (Date.now() + 1000 * 60 * 60).toString()
          );
          dispatch(fetchUserData());
          if (
            result.result.IsVerified == 1 &&
            result.result.AccountId != null
          ) {
            window.location.href =
              '/' + result.result.CompanyName + '/uploaded-documents';
          } else if (
            result.result.IsVerified == 1 &&
            result.result.AccountId == null
          ) {
            history.push('/' + result.result.CompanyName + '/accounts');
            window.location.reload();
          } else if (
            result &&
            result.result.IsVerified == 1 &&
            !result.result.RemainingDays
          ) {
            setPlanStatus(
              'Your Plan is expired. Contact support team as support@cloudforestx.com'
            );
            history.push('/login');
          } else {
            localStorage.removeItem('auth-state');
            history.push('/login');
          }
        } else if (
          result.message === DOCUMENT_UPLOADE_RESPONSES.UPLOADED_DOCUMENT_LIST
        ) {
          if (Object.keys(result.documents).length) {
            setDocs(Object.values(result.documents));
          }
          localStorage.setItem(
            'token_expiry_time',
            (Date.now() + 1000 * 60 * 60).toString()
          );
          const company: any = Object.values(result.documents);
          setCompanyName(company[0].company);
          setShowStaticPage(true);
        }
      }
      setLoader(false);
    } catch (e: any) {
      if (e.message === DOCUMENT_UPLOADE_RESPONSES.TOKEN_EXPIRED) {
        setErrorMsg('Link is expired, Please check your email for new link');
      } else if (e.message === DOCUMENT_UPLOADE_RESPONSES.INVALID_TOKEN) {
        setErrorMsg('Invalid Link');
      } else {
        setErrorMsg(e.message);
      }
    } finally {
    }
  };

  const openDocInNewTab = (docName: string) => {
    window.open(docName);
  };

  useEffect(() => {
    getUploadedDocuments();
  }, []);

  const RefreshLink = () => {
    return (
      <>
        <Typography
          variant="h6"
          className="cLogo d-flex align-center paddingAppBar"
        >
          <CFLogo />
          <span style={{ fontSize: '20px' }}>
            {' '}
            | <span className="ml-5 ">{companyName}</span>
          </span>
          <span
            className="marginLeft"
            onClick={() => {
              window.open('https://cloudforestx.com/');
            }}
          >
            Visit CloudForestX
          </span>
        </Typography>
      </>
    );
  };

  return (
    <React.Fragment>
      {showStaticPage ? (
        <>
          <AppBar position="static" className="appBar">
            <Toolbar className="toolBar">{RefreshLink()}</Toolbar>
          </AppBar>
          <div className=" uploadedDocOuterCont">
            <div className="UploadedDoc-navBar">
              <div className="leftNav">
                <ul>
                  <li>
                    <Link
                      to={Routes.Inventories}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="cube1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-box"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                        </svg>
                      </span>
                      <span className="cube2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-box"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                        </svg>
                      </span>
                      <span className="cube3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-box"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                        </svg>
                      </span>
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Inventory Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={currentPage === Routes.IdleInstance ? 'activeTab' : ''}
                  >
                    <Link
                      to={Routes.IdleInstance}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-layers"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0l3.515-1.874zM8 9.433 1.562 6 8 2.567 14.438 6 8 9.433z" />
                      </svg>
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Idle Instance Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={
                  //   currentPage.split('/')[2] === Routes.RightSizing.split('/')[2]
                  //     ? 'activeTab'
                  //     : ''
                  // }
                  >
                    <Link
                      to={Routes.RightSizing}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-check2-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                      </svg>
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for RightSizing Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={currentPage === Routes.ScheduleVM ? 'activeTab' : ''}
                  >
                    <Link
                      to={Routes.ScheduleVM}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <ScheduleImg width={20} height={20} />
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Schedule Instances Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={
                  //   currentPage.split('/')[2] ===
                  //   Routes.CostForecasting.split('/')[2]
                  //     ? 'activeTab'
                  //     : ''
                  // }
                  >
                    <Link
                      to={Routes.CostForecasting}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <ForcastImg width={20} height={20} />
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Cost Forecasting Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={currentPage === Routes.CostSaving ? 'activeTab' : ''}
                  >
                    <Link
                      to={Routes.CostSaving}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <SavingImg width={20} height={20} />
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Cost Saving Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={currentPage === Routes.Invoice ? 'activeTab' : ''}
                  >
                    <Link
                      to={Routes.Invoice}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <BillingImg width={20} height={20} />
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Billing Simplified Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>

                  <li
                  // className={currentPage === Routes.SecurityScan ? 'activeTab' : ''}
                  >
                    <Link
                      to={Routes.SecurityScan}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <SecurityImg width={20} height={20} />
                      <div className="iconLabel">
                        Upgrade to Growth Plan for Security Access
                      </div>
                    </Link>
                  </li>

                  <li
                  // className={
                  //   currentPage === Routes.InventoryReports ? 'activeTab' : ''
                  // }
                  >
                    <Link
                      to={Routes.InventoryReports}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <ReportImg width={20} height={20} />
                      <div className="iconLabel">
                        Upgrade to Growth Plan for Inventory Reports Access
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={Routes.UptimeMonitoring}
                      style={{
                        color: '#bfb6b6',
                        background: 'rgb(226, 226, 226)',
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <UptimeMonitoring width={20} height={20} />
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">
                          Upgrade to Growth Plan for Monitoring Access
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>
                  {/* //upload docs */}
                  <li className="activeTab">
                    <Link to={Routes.UploadedDocumentStatic}>
                      <DocSvg width={25} height={25} color="black" />
                      {window.outerWidth > 768 ? (
                        <div className="iconLabel">Uploaded Documents</div>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="uploadedDoc-UpperStrip securityHeading ml-10">
                Uploaded Documents
              </div>
              <div className="mt-15 ml-10 w-80">
                <TableContainer className="accContainer">
                  <Table
                    className="table"
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead className="tableHead">
                      <TableRow>
                        <TableCell width="2%" align="right" className="heading">
                          Sr. No.
                        </TableCell>
                        <TableCell width="30%" align="left" className="heading">
                          Document Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {docs.length ? (
                        docs.map((doc: any, i: number) => (
                          <TableRow key={doc + i}>
                            <TableCell
                              align="center"
                              className="tableCell uploadedDoc-serialNo"
                            >
                              {i + 1}
                            </TableCell>
                            <TableCell className="tableCell" width="20%">
                              {Object.values(doc?.docName).map(
                                (docName: any, i: number) => (
                                  <TableRow key={i}>
                                    <span
                                      className="docName"
                                      onClick={() =>
                                        openDocInNewTab(
                                          Object.values(doc.docLink)[
                                            i
                                          ] as string
                                        )
                                      }
                                    >
                                      {docName}
                                    </span>
                                  </TableRow>
                                )
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : loader ? (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell> Loading...</TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell> No Documents Uploaded yet</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </>
      ) : errorMsg ? (
        <div className="validatingUserOuterCont">
          <div className="text-center mt-40 noRecordsFound">{errorMsg}</div>
        </div>
      ) : (
        <div className="validatingUserOuterCont">
          <LinearProgress sx={{ width: '50%', marginLeft: '26%' }} />
          <div className="text-center mt-40 noRecordsFound">
            Validating User
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadedDocumentStatic;
