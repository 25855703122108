import { IconButton, Tab, Tabs, Tooltip, makeStyles } from '@material-ui/core';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ClickAwayListener,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { userState } from '../../store';
import { Add } from '@mui/icons-material';
import { returnSearchResults, underMaintenanceDurationHandler } from './config';
import AddCircle from '@mui/icons-material/AddCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NOTIFICATION_PROVIDER, PRIMARY_REGION } from '../../utils/constants';
import moment from 'moment';
import UrlMaintenanceModal from '../../components/Modals/UptimeMonitoringModal/UrlMaintenance';
import UptimeMonitoringModal from '../../components/Modals/UptimeMonitoringModal/UptimeMonitoringModal';
import {
  ListI,
  UrlMaintenanceInterface,
  submitUrlInterface,
} from './URLMonitoring';
import { MONITORING_TYPES } from '../../components/Modals/UptimeMonitoringModal/config';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CsvUploadModal from '../../components/Modals/UptimeMonitoringModal/MultipleUrlDocUpload';

interface Props {
  URLData: any;
  monitoringStatusHandler: (key: string) => void;
  handleUrlMaintenanceSubmition: (
    props: UrlMaintenanceInterface
  ) => Promise<void>;
  onSubmitUrl: (params: submitUrlInterface) => void;
  handleUrlMaintenanceModal: () => void;
  handleUrlMonitoringModal: () => void;
  handleCsvUploadModal: () => void;
  onCloseUptimeMonitoringModal: () => void;
  onCloseCsvUploadModal: () => void;
  closeMaintenanceModal: () => void;
  handleAccordionFriendlyNameSearch: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleTabValue: (value: string) => void;
  toggleAllURLsState: (status: boolean) => void;
  handleEnvironmentChange: (event: SelectChangeEvent<string>) => void;
  loaderHandler: (boolean: boolean) => void;
  csvUploadStatusHandler: (boolean: boolean) => void;
  tabValue: string;
  parentLoader: boolean;
  tabChangeLoader: boolean;
  toggleMonitoringLoader: boolean;
  accordionFriendlyNameSearch: string;
  showUptimeMonitorigModal: boolean;
  openUrlMaintenanceModal: boolean;
  openCsvUploadModal: boolean;
  toggledMonitoringItem: string | null;
  orgEmails: string[];
  orgSlackGroups: string[];
  orgGchats: string[];
  webhookUrlMapping: { [key: string]: string };
  gchat_IdMapping: { [key: string]: any };
  email_IdMapping: { [key: string]: any };
  slackChannel_IdMapping: { [key: string]: any };
  list: ListI;
  clickedData: any;
  selectedRegion: string;
  environmentLabels: string[];
  environmentLabel: string;
}

export interface URLMonitoringConfigInterface {
  monitoringType: string;
  friendlyName: string;
  environment: string;
  webUrl: string;
  monitoringInterval: number;
  monitoringTimeout: number;
  monitoringNotificationInterval: number;
  Region: string[];
  IsSSL: boolean;
  selectedEmail: string[];
  IsMonitoring?: boolean;
  UrlId?: any;
  selectedSlackGroups: string[];
  selectedGchats: string[];
  acceptedStatuses: any[]; // Assuming acceptedStatuses can be of any type
  escalationType: string;
  Escalations: any[]; // Assuming Escalations can be of any type
  EscalationThreshold: number | null;
  Headers: any; // Assuming Headers can be of any type
  Body: any; // Assuming Body can be of any type
  Method: string;
  Port: string;
  refreshTokenAPI: string;
}

const useToggleStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  button: {
    margin: theme.spacing(1),
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    padding: theme.spacing(0.5),
    // background: '#e7e7e7',
  },
}));

const URLAccordionSection: React.FC<Props> = (props) => {
  const {
    URLData,
    parentLoader,
    monitoringStatusHandler,
    loaderHandler,
    csvUploadStatusHandler,
    onSubmitUrl,
    handleUrlMaintenanceSubmition,
    handleUrlMaintenanceModal,
    handleUrlMonitoringModal,
    handleCsvUploadModal,
    onCloseUptimeMonitoringModal,
    onCloseCsvUploadModal,
    closeMaintenanceModal,
    handleAccordionFriendlyNameSearch,
    handleEnvironmentChange,
    handleTabValue,
    tabChangeLoader,
    tabValue,
    accordionFriendlyNameSearch,
    toggleMonitoringLoader,
    showUptimeMonitorigModal,
    openCsvUploadModal,
    openUrlMaintenanceModal,
    toggledMonitoringItem,
    webhookUrlMapping,
    gchat_IdMapping,
    email_IdMapping,
    slackChannel_IdMapping,
    orgEmails,
    orgSlackGroups,
    orgGchats,
    list,
    clickedData,
    environmentLabels,
    environmentLabel,
    toggleAllURLsState,
  } = props;
  const [isPlayStatus, setIsPlayStatus] = useState(true);

  const [allStatusToggleLoading, setAllStatusToggleLoading] = useState(false);
  const userData = useSelector(userState).userData;
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const [
    uptimeMonitoringModalDetails,
    setUptimeMonitoringModalDetils,
  ] = useState<URLMonitoringConfigInterface>({
    monitoringType: '',
    friendlyName: '',
    environment: '',
    webUrl: '',
    monitoringInterval: 60,
    monitoringTimeout: 30,
    monitoringNotificationInterval: 40,
    Region: [],
    IsSSL: false,
    selectedEmail: [],
    selectedSlackGroups: [],
    selectedGchats: [],
    acceptedStatuses: [],
    escalationType: '',
    Escalations: [],
    EscalationThreshold: null,
    Headers: {},
    Body: {},
    Method: '',
    Port: '',
    refreshTokenAPI: '',
  });

  const [expandIconIsClicked, setExpandIconIsClicked] = useState<boolean>(
    false
  );
  const [copied, setCopied] = useState(false);

  const [maintenanceUrlData, setMaintenanceUrlData] = useState<{
    [key: string]: any;
  }>({});
  const [isAddMaintenanceUrl, setIsAddMaintenanceUrl] = useState(false);
  const company = localStorage.getItem('CompanyName');
  const [selectedLabel, setSelectedLabel] = useState<string | null>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useToggleStyles();

  const handleToggle = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const selectedEnvironmentHandler = (label: string) => {
    setSelectedLabel(label);
  };

  const planType = userData?.plan.name;

  const handleCopy = (webUrl: string) => {
    navigator.clipboard
      .writeText(webUrl)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    const isExpandIconClicked =
      (event.target as HTMLElement).closest(
        '.MuiAccordionSummary-expandIconWrapper'
      ) !== null;
    setExpandIconIsClicked(isExpandIconClicked);
    setExpanded(isExpanded ? panel : null);
  };

  const handleTabChange = (event: any, newValue: string) => {
    handleTabValue(newValue);
  };

  const handleFriendlyNameClick = (
    clickedData: any,
    regionSelected: string
  ) => {
    history.push({
      pathname:
        '/' +
        company +
        '/uptime+ssl-monitoring/' +
        Object.keys(clickedData)[0].split(' ').join('-').toLocaleLowerCase(),
      state: { clickedData, regionSelected },
    });
  };

  const toggleURLStatusButton = () => {
    let countPausedUrl = 0;
    let countMonitoredUrl = 0;
    Object.values(URLData).forEach((el: any) => {
      if (el[0].IsMonitoring === true) {
        countMonitoredUrl++;
      } else {
        countPausedUrl++;
      }
    });
    if (countPausedUrl >= countMonitoredUrl) {
      setIsPlayStatus(true);
    } else {
      setIsPlayStatus(false);
    }
  };

  useMemo(() => {
    showUptimeMonitorigModal && handleClose();
    openCsvUploadModal && handleClose();
  }, [showUptimeMonitorigModal, openCsvUploadModal]);

  useEffect(() => {
    toggleURLStatusButton();
  }, [URLData]);

  return (
    <>
      {' '}
      <div className="firstURLSection">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="URL and Port"
          className="tab-wrapper"
          indicatorColor="primary"
        >
          <Tab label="URL" className="tab" value={MONITORING_TYPES.HTTPs} />
          <Tab label="Port" className="tab" value={MONITORING_TYPES.PORT} />
        </Tabs>
        <div className="searchContainer selectEnvironmentBox">
          <FormControl fullWidth>
            <InputLabel
              style={{ fontSize: '14px' }}
              id="demo-simple-select-label"
            >
              Select Environment
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              style={{ fontSize: '14px' }}
              inputProps={{ style: { fontSize: 16 } }}
              id="demo-simple-select"
              value={environmentLabel}
              label="Environment"
              onChange={handleEnvironmentChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '40vh',
                  },
                },
              }}
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {environmentLabels.map((menuItem: string) => (
                <MenuItem
                  onKeyDown={(e) => e.stopPropagation()}
                  key={menuItem}
                  value={menuItem}
                >
                  {menuItem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="searchContainer">
          <TextField
            style={{ width: '65%' }}
            label={`Friendly Name/${
              tabValue === MONITORING_TYPES.HTTPs ? 'Url' : 'Port'
            }`}
            variant="outlined"
            onChange={handleAccordionFriendlyNameSearch}
            defaultValue={accordionFriendlyNameSearch}
            className="w-full"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {/* <Button className={classes.button} onClick={handleToggle}>
              Toggle Popper
            </Button> */}
          <Button
            variant="contained"
            onClick={handleToggle}
            startIcon={<AddCircle />}
          >
            Add
          </Button>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            className={classes.popper}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.paper}>
                <div className="popperSection">
                  <button
                    type="button"
                    className="btn btn-primary mr-10 "
                    onClick={() => {
                      setSelectedLabel('');
                      setUptimeMonitoringModalDetils({
                        monitoringType:
                          tabValue === MONITORING_TYPES.HTTPs
                            ? 'https'
                            : 'tcp-port',
                        friendlyName: '',
                        environment: '',
                        webUrl:
                          tabValue === MONITORING_TYPES.HTTPs ? 'https://' : '',
                        monitoringInterval: planType === 'Basic' ? 5 : 3,
                        monitoringTimeout: 12000,
                        monitoringNotificationInterval: 5000 * 60,
                        Region: ['us-east-1'],
                        IsSSL: false,
                        selectedEmail: [],
                        selectedSlackGroups: [],
                        acceptedStatuses: ['2000'],
                        selectedGchats: [],
                        escalationType: '',
                        Escalations: [],
                        Body: {},
                        Method: '',
                        Headers: {
                          Authorization: '--------',
                        },
                        Port: '',
                        refreshTokenAPI: '',
                        EscalationThreshold: 1,
                      });
                      handleUrlMonitoringModal();
                    }}
                  >
                    Add {tabValue === 'tcp-port' ? 'port' : 'url'}
                  </button>
                  <div style={{ background: '#d0cccc', width: '2px' }}></div>
                  <button
                    type="button"
                    className="btn btn-primary ml-10 "
                    onClick={() => handleCsvUploadModal()}
                  >
                    Upload CSV
                  </button>
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>

          <IconButton
            onClick={() => {
              toggleAllURLsState(isPlayStatus);
              setIsPlayStatus(!isPlayStatus);
            }}
          >
            {allStatusToggleLoading ? (
              <PendingOutlinedIcon
                sx={{
                  fontSize: '25px',
                  borderRadius: '10px',
                }}
              />
            ) : isPlayStatus ? (
              <Tooltip title="Start monitoring for all urls">
                <PlayCircleOutlineIcon
                  sx={{
                    fontSize: '25px',
                    borderRadius: '10px',
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Pause monitoring for all urls">
                <PauseCircleOutlineIcon
                  sx={{
                    fontSize: '25px',
                    borderRadius: '10px',
                  }}
                />
              </Tooltip>
            )}
          </IconButton>
        </div>
        <div className="accordionContainer">
          {parentLoader || tabChangeLoader ? (
            <div className="noDataMessage">
              <Typography variant="body1">Loading...</Typography>
            </div>
          ) : Object.keys(URLData).length > 0 ? (
            Object.keys(
              returnSearchResults(
                URLData,
                accordionFriendlyNameSearch,
                tabValue
              )
            ).length > 0 ? (
              Object.entries(
                returnSearchResults(
                  URLData,
                  accordionFriendlyNameSearch,
                  tabValue
                )
              ).map(([key, value]: any) => (
                <Accordion
                  key={key}
                  expanded={expanded === key && expandIconIsClicked}
                  onChange={handleChange(key)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordionSummary"
                    style={{
                      opacity: `${!value[0].IsMonitoring ? '63%' : '100%'}`,
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className={`UrlregionCount ${
                        value.filter((item: any) => item.Maintenance)?.length >
                          0 &&
                        value
                          .filter((item: any) => item.Maintenance)
                          .some(
                            (item: any) =>
                              item.Maintenance &&
                              new Date().getTime() <
                                +item.Maintenance?.split('-')[1] &&
                              new Date().getTime() >
                                +item.Maintenance?.split('-')[0]
                          )
                          ? 'maintenanceBox'
                          : value.every(
                              (item: any) =>
                                item.LastChecked === null && item.IsMonitoring
                            )
                          ? 'connectingBox'
                          : value.every(
                              (item: any) =>
                                !item.LastActive && item.LastActive !== null
                            )
                          ? 'downTimeBox'
                          : value.every(
                              (item: any) =>
                                item.LastActive || item.LastActive === null
                            )
                          ? 'uptimeBox'
                          : 'mixedUrlStatus'
                      }`}
                    >
                      {value.length}
                    </Typography>
                    {key?.toString().length > 10 ? (
                      <Tooltip title={key}>
                        <div
                          onClick={() =>
                            handleFriendlyNameClick(
                              { [key]: value },
                              value?.some(
                                (obj: any) => obj['Region'] === PRIMARY_REGION
                              )
                                ? PRIMARY_REGION
                                : value[0].Region
                            )
                          }
                          className="accFriendlyName text-overflow-ellipsis"
                        >
                          {key}
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        onClick={() =>
                          handleFriendlyNameClick(
                            {
                              [key]: value,
                            },
                            value?.some(
                              (obj: any) => obj['Region'] === PRIMARY_REGION
                            )
                              ? PRIMARY_REGION
                              : value[0].Region
                          )
                        }
                        className="accFriendlyName"
                      >
                        {key}
                      </div>
                    )}

                    <div className="hoverableIcon">
                      <Tooltip title="Edit URL">
                        <BorderColorOutlinedIcon
                          onClick={() => {
                            const Emails: string[] = [];
                            const SlackGroups: string[] = [];
                            const GchatGroups: string[] = [];
                            const Escalations: string[] = [];
                            let escalationType = '';

                            if (value[0].UrlNotifications.length) {
                              value[0].UrlNotifications.forEach((row: any) => {
                                if (
                                  row.NotificationType ===
                                  NOTIFICATION_PROVIDER.EMAIL
                                )
                                  Emails.push(row.Value);
                                if (
                                  row.NotificationType ===
                                  NOTIFICATION_PROVIDER.SLACK
                                )
                                  SlackGroups.push(row.FriendlyName);
                                if (
                                  row.NotificationType ===
                                  NOTIFICATION_PROVIDER.GCHAT
                                )
                                  GchatGroups.push(row.Value);

                                if (
                                  row.NotificationType ===
                                    NOTIFICATION_PROVIDER.ESCALATION_EMAIL ||
                                  row.NotificationType ===
                                    NOTIFICATION_PROVIDER.ESCALATION_GCHAT ||
                                  row.NotificationType ===
                                    NOTIFICATION_PROVIDER.ESCALATION_SLACK
                                ) {
                                  Escalations.push(row.Value);
                                  escalationType =
                                    row.NotificationType ===
                                    NOTIFICATION_PROVIDER.ESCALATION_EMAIL
                                      ? 'Email'
                                      : row.NotificationType ===
                                        NOTIFICATION_PROVIDER.ESCALATION_GCHAT
                                      ? 'Gchat'
                                      : 'Slack';
                                }
                              });
                            }
                            setSelectedLabel(
                              value[0].MonitoringLabels
                                ? value[0].MonitoringLabels
                                : null
                            );

                            setUptimeMonitoringModalDetils({
                              monitoringType: value[0].Type,
                              friendlyName: value[0].FriendlyName,
                              environment: value[0].MonitoringLabels
                                ? value[0].MonitoringLabels[0]
                                : selectedLabel,
                              webUrl: value[0].WebUrl,
                              monitoringInterval: value[0].Frequency,
                              monitoringTimeout: value[0].Timeout,
                              monitoringNotificationInterval:
                                value[0].NotificationInterval,
                              IsSSL: value[0].IsSSL,
                              Region:
                                value?.reduce(
                                  (acc: string[], currUrlData: any) => {
                                    acc.push(currUrlData.Region);
                                    return acc;
                                  },
                                  []
                                ) || [],
                              selectedEmail: Emails,
                              selectedSlackGroups: SlackGroups,
                              selectedGchats: GchatGroups,
                              escalationType,
                              Escalations,
                              EscalationThreshold: value[0].EscalationThreshold,
                              acceptedStatuses: value[0].acceptedStatuses || [],
                              IsMonitoring: value[0].IsMonitoring,
                              UrlId: value[0].Id,
                              Headers: value[0]?.Headers
                                ? Object.keys(value[0]?.Headers).length
                                  ? value[0].Headers
                                  : {
                                      Authorization: '--------',
                                    }
                                : {},
                              Method: value[0].Method,
                              Body: value[0].Body,
                              Port: value[0].Port,
                              refreshTokenAPI: value[0].refreshTokenAPI,
                            });
                            handleUrlMonitoringModal();
                          }}
                          color="action"
                        />
                      </Tooltip>
                    </div>

                    <div
                      key={key}
                      onClick={() => monitoringStatusHandler(key)}
                      className="hoverableIcon"
                    >
                      {toggledMonitoringItem === key &&
                      toggleMonitoringLoader ? (
                        <PendingOutlinedIcon color="action" />
                      ) : !value[0].IsMonitoring ? (
                        <Tooltip title="Start Monitoring">
                          <PlayCircleOutlineIcon color="action" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Pause Monitoring">
                          <PauseCircleOutlineIcon color="action" />
                        </Tooltip>
                      )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails
                    key={key + 'acc'}
                    style={{
                      opacity: `${!value[0].IsMonitoring ? '63%' : '100%'}`,
                    }}
                  >
                    {tabValue === MONITORING_TYPES.PORT ? (
                      <div key={key + 'acc2'} className="urlName">
                        <div
                          className={
                            value[0].WebUrl.toString().length > 40
                              ? 'text-overflow-ellipsis'
                              : ''
                          }
                        >
                          {value[0].WebUrl}:{value[0].Port}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={key + 'newAcc'}
                        className="urlName"
                        onMouseEnter={() => setCopied(false)}
                        onClick={() => handleCopy(value[0].WebUrl)}
                      >
                        <Tooltip
                          title={!copied ? 'Copy to clipboard' : 'Copied!'}
                        >
                          <div
                            className={
                              value[0].WebUrl.toString().length > 40
                                ? 'text-overflow-ellipsis'
                                : ''
                            }
                          >
                            {value[0].WebUrl}
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    <ul key={key + 'ul'} className="UrlAccRegionDetail">
                      {value?.map((item: any) => (
                        <>
                          {item.Maintenance ? (
                            <li
                              key={item.Id}
                              className=" cursor-pointer accDetailSection"
                              style={{
                                opacity: '63%',
                              }}
                            >
                              {underMaintenanceDurationHandler(item) ? (
                                <Tooltip
                                  title={`Under Maintenance till ${moment(
                                    +item.Maintenance.split('-')[1]
                                  ).format('DD-MM-YYYY')} ${moment(
                                    +item.Maintenance.split('-')[1]
                                  ).format('HH:mm:ss')}`}
                                >
                                  <div
                                    className={
                                      clickedData &&
                                      clickedData?.regionSelected ===
                                        item.Region &&
                                      Object.keys(
                                        clickedData.clickedData
                                      )[0] === item.FriendlyName
                                        ? 'activeRegion regionName'
                                        : 'regionName'
                                    }
                                    onClick={() =>
                                      handleFriendlyNameClick(
                                        {
                                          [item.FriendlyName]: [item],
                                        },
                                        item.Region
                                      )
                                    }
                                  >
                                    {item.Region}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div
                                  className={
                                    clickedData &&
                                    clickedData?.regionSelected ===
                                      item.Region &&
                                    Object.keys(clickedData.clickedData)[0] ===
                                      item.FriendlyName
                                      ? 'activeRegion regionName'
                                      : 'regionName'
                                  }
                                  onClick={() =>
                                    handleFriendlyNameClick(
                                      {
                                        [item.FriendlyName]: [item],
                                      },
                                      item.Region
                                    )
                                  }
                                >
                                  {item.Region}
                                </div>
                              )}

                              <div>
                                <Tooltip title="Edit URL Maintenance">
                                  <ConstructionOutlinedIcon
                                    // fontSize="large"
                                    className="mr-20 pointer"
                                    onClick={() => {
                                      setMaintenanceUrlData(item);
                                      handleUrlMaintenanceModal();
                                      setIsAddMaintenanceUrl(false);
                                    }}
                                  />
                                </Tooltip>
                              </div>

                              <div>
                                {item.Maintenance &&
                                new Date().getTime() <
                                  +item.Maintenance?.split('-')[1] &&
                                new Date().getTime() >
                                  +item.Maintenance?.split('-')[0] ? (
                                  <div className="maintenancedotURL"></div>
                                ) : item.LastActive ? (
                                  <div className="activeURL"></div>
                                ) : (
                                  <div className="deactiveURL"></div>
                                )}
                              </div>
                            </li>
                          ) : (
                            <li key={item.Id} className="accDetailSection">
                              <div
                                className={
                                  clickedData &&
                                  clickedData?.regionSelected === item.Region &&
                                  Object.keys(clickedData.clickedData)[0] ===
                                    item.FriendlyName
                                    ? 'activeRegion regionName'
                                    : 'regionName'
                                }
                                onClick={() =>
                                  handleFriendlyNameClick(
                                    {
                                      [item.FriendlyName]: [item],
                                    },
                                    item.Region
                                  )
                                }
                              >
                                {item.Region}
                              </div>
                              <div>
                                <Tooltip title="Add URL Maintenance">
                                  <Add
                                    // fontSize="large"
                                    className="mr-20 pointer"
                                    onClick={() => {
                                      setMaintenanceUrlData(item);
                                      handleUrlMaintenanceModal();
                                      setIsAddMaintenanceUrl(true);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <div>
                                {item.LastActive ? (
                                  <div className="activeURL"></div>
                                ) : (
                                  <div className="deactiveURL"></div>
                                )}
                              </div>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <div className="noDataMessage">
                <Typography variant="body1">No data found</Typography>
              </div>
            )
          ) : (
            <div className="noDataMessage">
              <Typography variant="body1">No data found</Typography>
            </div>
          )}
        </div>
      </div>
      {openCsvUploadModal && (
        <CsvUploadModal
          loaderHandler={loaderHandler}
          csvUploadStatusHandler={csvUploadStatusHandler}
          onClose={onCloseCsvUploadModal}
          isCsvModalOpen={openCsvUploadModal}
        />
      )}
      {showUptimeMonitorigModal && (
        <UptimeMonitoringModal
          show={showUptimeMonitorigModal}
          onClose={onCloseUptimeMonitoringModal}
          uptimeMonitoringDetails={uptimeMonitoringModalDetails}
          emails={orgEmails}
          slackGroups={orgSlackGroups}
          onSubmit={onSubmitUrl}
          webhookUrlMapping={webhookUrlMapping}
          SlackChannel_Id={slackChannel_IdMapping}
          GchatChannel_Id={gchat_IdMapping}
          Email_Id={email_IdMapping}
          list={list}
          gchats={orgGchats}
          environmentLabels={environmentLabels}
          selectedEnvironmentHandler={selectedEnvironmentHandler}
          selectedLabel={
            Array.isArray(selectedLabel)
              ? selectedLabel.flat()[0]
              : selectedLabel
          }
        />
      )}
      {openUrlMaintenanceModal && (
        <UrlMaintenanceModal
          show={openUrlMaintenanceModal}
          isAddMaintenanceUrl={isAddMaintenanceUrl}
          onClose={closeMaintenanceModal}
          handleUrlMaintenanceSubmition={handleUrlMaintenanceSubmition}
          urlData={maintenanceUrlData}
        />
      )}
    </>
  );
};

export default URLAccordionSection;
