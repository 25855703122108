import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountService,
  authService,
  httpService,
  userService,
} from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import { handleError } from '../../../utils/config';

const CPVForm = (props: any) => {
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'CSP',
    AccountPlan: '',
    AccountType: '',
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        // const response = await accountService.createAccount(accountDetails);
        // // toasterService.show(response, 'success');
        // history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <p className="mb-20">
                Get Consent from partners, which will be used, along with a
                grant, to make further calls to Partner Center APIs.
              </p>
              <p className="mb-10">
                This process will enable CloudForestX to access your Azure
                CSP/Partner account billing and invoice data using Control Panel
                Vendor (CPV) application.
              </p>
              <ul className={classes.list}>
                <ol className={helperClasses.p0}>
                  <li>
                    Choose the correct type of account through the Azure Account
                    Type drop-down.
                  </li>
                  <li>
                    Initiate the credential process by clicking the
                    <b>Initiate Consent</b> button. Azure Active Directory will
                    launch and display a list of required permissions to
                    retrieve your data.
                  </li>
                  <p>
                    <b>Note:</b> To view this page, you might have to log into
                    the Azure Active Directory.
                  </p>
                  <li>
                    Finally, click <b>Accept</b> to provide consent to the app
                    to interact with the Partner Center APIs.
                  </li>
                </ol>
              </ul>
              <p className="mb-10">
                You will be redirected back to the Credentialing page, where
                your data will be automatically saved, once consent is granted
                by Azure.
              </p>
              <p className="mb-10">
                After this, we will be able to interact with the Partner Center
                APIs and pull data into your account. You can return to this
                page and follow the same procedure if re-consent is required to
                authorize this interaction at any time.
              </p>
              <p>Choose your Azure Account type:</p>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  ACCOUNT TYPE
                </InputLabel>
                <Select
                  id="demo-mutiple-name"
                  // multiple
                  name="AccountType"
                  value={accountDetails.AccountType}
                  ref={register({ required: true })}
                  onChange={handleChange}
                  input={<Input />}
                  // MenuProps={MenuProps}
                >
                  {['Commercial', 'Azure Government', 'Azure German'].map(
                    (item: any) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
                <span className={classes.warningMessage}>
                  {errors?.AccountType && 'AccountType is required'}
                </span>
              </FormControl>
              <span></span>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <p>Please choose the Partner Center Billing Version to use:*</p>
              <p>
                *Select between establishing a connection with either Classic
                CSP or Azure Plan. Only customers with the selected agreements
                will be allowed into this account. Separate accounts will be
                required if you have customers on both Azure Plan and Classic
                CSP.
              </p>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={`${classes.formControl} mb-30`}>
                <InputLabel id="demo-mutiple-name-label">
                  ACCOUNT PLAN
                </InputLabel>
                <Select
                  id="demo-mutiple-name"
                  // multiple
                  name="AccountPlan"
                  value={accountDetails.AccountPlan}
                  ref={register({ required: true })}
                  onChange={handleChange}
                  input={<Input />}
                  // MenuProps={MenuProps}
                >
                  {['Azure Plan', 'Classic CSP'].map((item: any) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <span className={classes.warningMessage}>
                  {errors?.AccountPlan && 'AccountType is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}></Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button className="noBtn" onClick={props.cancel}>
                BACK
              </button>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button type="submit" className="yesBtn">
                UPDATE
              </button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CPVForm;
