import { APIS } from '../../utils/apiConfig';
import { HttpService } from '../HttpService/HttpService';

export class UploadedDocumentsService {
  constructor(private httpService: HttpService) {}

  async getUploadedDocuments(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_UPLOADED_DOCUMENTS(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async shareDoc(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.SHARE_DOC(), params);
      const { data } = response;
      return data;
    } catch (e) {
      throw e;
    }
  }
}
