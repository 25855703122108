import { APIS } from '../../utils/apiConfig';
import { GchatParamsInterface } from '../../utils/interfaces';
import { HttpService } from '../HttpService/HttpService';

export class CustomNotificationService {
  constructor(private httpService: HttpService) {}
  async updateUserNotification(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.POST_CUSTOM_NOTIFICATION(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async companyNotificaion(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.COMPANY_NOTIFICATION(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async fetchSlackChannelOnService(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.FETCH_ON_SLACK_CHANNELS_OF_SERVICE(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getServiceNotificationStatus(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.FETCH_SERVICE_NOTIFICATION_STATUS(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async updateSlackCreds(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.UPDATE_SLACK_CREDS(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async AddEmail(email: any): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.ADD_EMAIL(), email);
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async setGchatCredsData(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.SET_GCHAT_DATA(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getGchatCredsData(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_GCHAT_DATA());
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async updateGchatCredsData(params: GchatParamsInterface): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.UPDATE_GCHAT_DATA(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async deleteGchatCredsData(id: number): Promise<any> {
    try {
      const response = await this.httpService.delete(APIS.DELETE_GCHAT_DATA(), {
        id,
      });
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getNonRegisteredEmail(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_NON_REGISTERED_EMAIL(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async deleteNonRegisteredEmail(Email: string): Promise<any> {
    try {
      const response = await this.httpService.delete(
        APIS.DELETE_NON_REGISTERED_EMAIL(),
        { Email }
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
}
