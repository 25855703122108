import { Box, LinearProgress } from '@material-ui/core';
import CountryFlag from '../flagMap/flag';

export interface CountryCardProps {
  countryName: string;
  countryCode: string;
  count: number;
  totalCount: number;
}

export const CountryCard = ({
  countryName,
  countryCode,
  count,
  totalCount,
}: CountryCardProps) => {
  // const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

  const normalise = (value: number) => ((value - 1) * 100) / (totalCount - 1);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        marginRight: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          marginBottom: '5px',
        }}
      >
        <div>
          <CountryFlag countryCode={countryCode} width="30px" />
        </div>
        <div>
          <span style={{ display: 'inline' }}>{countryName}</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'right',
          marginBottom: '5px',
        }}
      >
        {count}
      </div>
      <LinearProgress variant="determinate" value={normalise(count)} />
    </Box>
  );
};
