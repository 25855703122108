import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import './LoadingScreen.css';

export const LoadingScreen: React.FC = () => {
  return (
    <Fragment>
      <div className="loaderContainer">
        <div className="lds-ellipsis">
          {/* <div><FontAwesomeIcon icon={faCloud} className="font-20px" /></div>
          <div><FontAwesomeIcon icon={faCloud} className="font-25px" /></div>
          <div><FontAwesomeIcon icon={faCloud} className="font-20px" /></div>
          <div><FontAwesomeIcon icon={faCloud} /></div> */}
          <div></div><div></div><div></div><div></div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoadingScreen;