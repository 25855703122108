import { Skeleton, Box, Paper } from '@mui/material';

const SkeletonRUMCard = () => {
  return (
    <Box
      key="skeleton-rum-card"
      className="dashboard-container"
      style={{ marginBottom: '1rem' }}
    >
      {/* Header Skeleton */}
      <Box
        mb={2}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="text" width={355} height={35} /> {/* Site name */}
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton
            variant="rectangular"
            width={'11rem'}
            style={{ borderRadius: '5px' }}
            height={35}
          />{' '}
          {/* View Details button */}
          <Skeleton
            variant="circular"
            width={35}
            height={35}
            style={{ marginLeft: '10px' }}
          />{' '}
          {/* Copy button */}
          <Skeleton
            variant="circular"
            width={35}
            height={35}
            style={{ marginLeft: '10px' }}
          />{' '}
          {/* Delete button */}
        </Box>
      </Box>
      <Paper
        className="dashboard-card d-flex"
        style={{ padding: '16px', borderRadius: '8px' }}
      >
        {/* Content Skeleton - Active Sessions and Load Time */}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            width: '80%',
          }}
        >
          {/* Active Sessions */}
          <Box style={{ width: '48%' }}>
            <div>
              <div className="activeLoadBoxes">
                <div>
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* Active Sessions Title */}
                  <Skeleton variant="text" width={50} height={40} />{' '}
                  {/* Active Sessions Number */}
                </div>
                <div className="comparison">
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* 1 hour ago */}
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* 1 day ago */}
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* 1 week ago */}
                </div>
              </div>
            </div>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={'72%'}
              style={{ marginTop: '1rem', borderRadius: '5px' }}
            />{' '}
            {/* Active Sessions Graph */}
          </Box>
          <div className="breakLine"></div>
          {/* Load Time */}
          <Box style={{ width: '48%' }}>
            <div>
              <div className="activeLoadBoxes">
                <div>
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* Active Sessions Title */}
                  <Skeleton variant="text" width={50} height={40} />{' '}
                  {/* Active Sessions Number */}
                </div>
                <div className="comparison">
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* 1 hour ago */}
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* 1 day ago */}
                  <Skeleton variant="text" width={150} height={25} />{' '}
                  {/* 1 week ago */}
                </div>
              </div>
            </div>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={'72%'}
              style={{ marginTop: '1rem', borderRadius: '5px' }}
            />{' '}
            {/* Active Sessions Graph */}
          </Box>
        </Box>

        <div className="breakLine"></div>

        {/* Right Section - Pageviews/Min, Bounce Rate, and Apdex Score */}
        <Box style={{ width: '17%' }}>
          {/* Pageviews/Min */}
          <Box style={{ width: '32%' }}>
            <Skeleton variant="text" width={160} height={25} />{' '}
            {/* Pageviews/Min Title */}
            <Skeleton variant="text" width={80} height={40} />{' '}
            {/* Pageviews/Min Number */}
            <Skeleton variant="text" width={100} height={25} />{' '}
            {/* 1 hour ago */}
          </Box>
          <div className="middleBreakLine"></div>

          {/* Bounce Rate */}
          <Box style={{ width: '32%' }}>
            <Skeleton variant="text" width={160} height={25} />{' '}
            {/* Bounce Rate Title */}
            <Skeleton variant="text" width={80} height={40} />{' '}
            {/* Bounce Rate Number */}
            <Skeleton variant="text" width={100} height={25} />{' '}
            {/* 1 hour ago */}
          </Box>
          <div className="middleBreakLine"></div>
          {/* Apdex Score */}
          <Box style={{ width: '32%' }}>
            <Skeleton variant="text" width={160} height={25} />{' '}
            {/* Apdex Score Title */}
            <Skeleton variant="text" width={80} height={40} />{' '}
            {/* Apdex Score Number */}
            <Skeleton variant="text" width={100} height={25} />{' '}
            {/* 1 hour ago */}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SkeletonRUMCard;
