import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Theme } from '@mui/material';
import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/userSlice';
import { config } from './config';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import { exportToFile } from '../../../utils/formateXLSX';

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    border: '2px dashed #00bbdc',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(40),
    outline: 'none',
    width: '40rem',
    cursor: 'pointer',
  },
}));

const useStylesRedZone = makeStyles((theme: Theme) => ({
  dropzone: {
    border: '2px dashed red',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(40),
    outline: 'none',
    width: '40rem',
    cursor: 'pointer',
  },
}));

interface CsvPropsI {
  onClose: () => void;
  loaderHandler: (boolean: boolean) => void;
  csvUploadStatusHandler: (boolean: boolean) => void;
  isCsvModalOpen: boolean;
}

const CsvUploadModal: React.FC<CsvPropsI> = (props: CsvPropsI) => {
  const {
    onClose,
    loaderHandler,
    isCsvModalOpen,
    csvUploadStatusHandler,
  } = props;
  const [redDropZone, setRedDropZone] = useState(false);
  const [files, setFiles] = useState<any>();
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(true);
  const classes = useStyles();
  const classesRedZone = useStylesRedZone();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxSize: 5 * 1024 * 1024, // 10mb
    maxFiles: 1,
  });

  useMemo(() => {
    if (fileRejections.length) {
      setRedDropZone(true);
      setIsSubmitBtnDisabled(true);
      dispatch(
        userActions.replaceUserState({
          errorMessage: fileRejections[0].errors[0].message,
        })
      );
    }
    if (acceptedFiles.length) {
      setRedDropZone(false);
      setIsSubmitBtnDisabled(false);
      setFiles(acceptedFiles[0]);
    }
  }, [redDropZone, fileRejections, userActions, isSubmitBtnDisabled]);

  const uploadCsvHandler = async () => {
    //logic
    try {
      if (files) {
        const formData = new FormData();
        formData.append('csvFile', files);
        loaderHandler(true);
        const res = await config.uploadUrlCsv(formData);
        if (res) {
          dispatch(
            userActions.replaceUserState({
              successMessage:
                'CSV uploaded. Check status of URLs in downloaded file.',
            })
          );
          exportToFile(res, 'url_template.csv');
          setFiles(null);
          csvUploadStatusHandler(true);
        }
      }
    } catch (e: any) {
      // setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        dispatch(
          userActions.replaceUserState({
            errorMessage: e.error?.message,
          })
        );
        handleError(e, history);
      }
    } finally {
      loaderHandler(false);
      csvUploadStatusHandler(false);
    }
  };

  const downloadCsvHandler = async () => {
    try {
      const result = await config.downloadCsvTemplate();
      exportToFile(result, 'url_template.csv');
    } catch (e: any) {
      // setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  return (
    <Modal
      isOpen={isCsvModalOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{
        overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 },
      }}
      className="fixModal"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Upload CSV file</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <div className="UrlDocUpload">
          <div
            {...getRootProps({
              className: !redDropZone
                ? classes.dropzone
                : classesRedZone.dropzone,
            })}
          >
            <input {...getInputProps()} />

            <div
              className={
                redDropZone
                  ? 'dragDropBox red hoveredDropBox'
                  : 'dragDropBox hoveredDropBox'
              }
            >
              <div>Drag 'n' drop file here, or click to select file</div>
              <div>(Only *.csv file will be accepted)</div>
              {files && (
                <div className="fileName">
                  {files.path} - {files.size} bytes
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-0 modalFooter">
          <div className="d-flex space-between w-100">
            <button
              type="button"
              className="btn btn-outline-secondary d-flex"
              onClick={downloadCsvHandler}
            >
              Template csv
              <div className="icon-wrapper">
                <DownloadIcon sx={{ fontSize: 17 }} />
              </div>
            </button>
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ml-10 "
                onClick={() => {
                  uploadCsvHandler(), onClose;
                }}
                disabled={isSubmitBtnDisabled}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Grid>
    </Modal>
  );
};

export default CsvUploadModal;
