import { NotificationLogsProps } from '../../../pages/UptimeMonitoring/config';
import { uptimeService } from '../../../services';

// export interface CsvParamsI {
//   csvFile: any;
// }
interface ConfigI {
  uploadUrlCsv: (params: any) => any;
  downloadCsvTemplate: () => any;
  getUrlNotificationLogs: (params: NotificationLogsProps) => any;
}

export const config: ConfigI = {
  uploadUrlCsv: (params: any) => uptimeService.uploadCsvFileHandler(params),
  downloadCsvTemplate: () => uptimeService.downloadCsvTemplate(),
  getUrlNotificationLogs: (params: NotificationLogsProps) =>
    uptimeService.getUrlNotificationLogs(params),
};

export const validateJSON = (json: string) => {
  try {
    const parsedJSON = JSON.parse(json);
    return {
      isValid: true,
      parsedJSON,
    };
  } catch (error: any) {
    return {
      isValid: false,
      error: error.message,
    };
  }
};

interface MONITORING_TYPESI {
  HTTPs: string;
  HTTP: string;
  PORT: string;
}

export const MONITORING_TYPES: MONITORING_TYPESI = {
  HTTPs: 'https',
  HTTP: 'http',
  PORT: 'tcp-port',
};

export const MonitoringIntervalMarks = [
  {
    value: 0, // for 1 minute
    label: '1m',
  },
  {
    value: 15, // for 3 minutes
    label: '3m',
  },
  {
    value: 29, // for 5 minutes
    label: '5m',
  },
  {
    value: 43, // for 30 minutes
    label: '30m',
  },
  {
    value: 57, // for 1 hour
    label: '1h',
  },
  {
    value: 71, // for 6 hours
    label: '6h',
  },
  {
    value: 85, // for 12 hours
    label: '12h',
  },
  {
    value: 100, // for 24 hours
    label: '24h',
  },
];
export const monitoringIntervalObj: { [key: number]: string } = {
  0: '1',
  15: '3',
  29: '5',
  43: '30',
  57: '60',
  71: '360',
  85: '720',
  100: '1440',
};

export const monitoringIntervalReverse: { [key: number]: number } = {
  1: 0,
  3: 15,
  5: 29,
  30: 43,
  60: 57, // 1 hour
  360: 71, // 6 hours
  720: 85, // 12 hours
  1440: 100, // 24 hours
};


export const timeoutMarks = [
  {
    value: 10,
    label: '10s',
  },
  {
    value: 20,
    label: '20s',
  },
  {
    value: 30,
    label: '30s',
  },
  {
    value: 40,
    label: '40s',
  },
  {
    value: 50,
    label: '50s',
  },
  {
    value: 60,
    label: '60s',
  },
];
export const NotificationIntervalMarks = [
  {
    value: 0,
    label: '2m',
  },
  {
    value: 15,
    label: '5m',
  },
  {
    value: 29,
    label: '15m',
  },
  {
    value: 43,
    label: '30m',
  },
  {
    value: 57,
    label: '1h',
  },
  {
    value: 71,
    label: '6h',
  },
  {
    value: 85,
    label: '12h',
  },
  {
    value: 100,
    label: '24h',
  },
];




export const monitoringNotificationIntervalObj: { [key: number]: string } = {
  0: '2', // 2 minutes
  15: '5', // 5 minutes
  29: '15', // 15 minutes
  43: '30', // 30 minutes
  57: '60', // 1 hour (60 minutes)
  71: '360', // 6 hours (360 minutes)
  85: '720', // 12 hours (720 minutes)
  100: '1440', // 24 hours (1440 minutes)
};

export const monitoringNotificationIntervalRev: { [key: number]: number } = {
  2: 0, // 2 minutes corresponds to 0%
  5: 15, // 5 minutes corresponds to 15%
  15: 29, // 15 minutes corresponds to 29%
  30: 43, // 30 minutes corresponds to 43%
  60: 57, // 1 hour (60 minutes) corresponds to 57%
  360: 71, // 6 hours (360 minutes) corresponds to 71%
  720: 85, // 12 hours (720 minutes) corresponds to 85%
  1440: 100, // 24 hours (1440 minutes) corresponds to 100%
};

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const statusRange: any = {
  '100 - 199': 1000,
  '200 - 299': 2000,
  '300 - 399': 3000,
  '400 - 499': 4000,
  '500 - 599': 5000,
};

export const statusConvert: any = {
  '1000': '100 - 199',
  '2000': '200 - 299',
  '3000': '300 - 399',
  '4000': '400 - 499',
  '5000': '500 - 599',
};

export const statusCodes: any = [
  '100 - 199',
  '200 - 299',
  '300 - 399',
  '400 - 499',
  '500 - 599',
  '100',
  '101',
  '102',
  '103',
  '200',
  '201',
  '202',
  '203',
  '204',
  '205',
  '206',
  '207',
  '208',
  '226',
  '300',
  '301',
  '302',
  '303',
  '304',
  '305',
  '306',
  '307',
  '308',
  '400',
  '401',
  '402',
  '403',
  '404',
  '405',
  '406',
  '407',
  '408',
  '409',
  '410',
  '411',
  '412',
  '413',
  '414',
  '415',
  '416',
  '417',
  '418',
  '421',
  '422',
  '423',
  '424',
  '425',
  '426',
  '428',
  '429',
  '431',
  '451',
  '500',
  '501',
  '502',
  '503',
  '504',
  '505',
  '506',
  '507',
  '508',
  '510',
  '511',
];

export const regionsData = {
  'ap-northeast-1': 'Asia Pacific ap-northeast-1 (Tokyo)',
  'ap-northeast-2': 'Asia Pacific ap-northeast-1 (Seoul)',
  'ap-northeast-3': 'Asia Pacific ap-northeast-3 (Osaka)',
  'ap-south-1': 'Asia Pacific ap-south-1 (Mumbai)',
  'ap-southeast-1': 'Asia Pacific ap-southeast-1 (Singapore)',
  'ap-southeast-2': 'Asia Pacific ap-southeast-2 (Sydney)',
  'ca-central-1': 'Canada ca-central-1 (Central)',
  'eu-central-1': 'Europe eu-central-1 (Frankfurt)',
  'eu-north-1': 'Europe eu-north-1 (Stockholm)',
  'eu-west-1': 'Europe eu-west-1 (Ireland)',
  'eu-west-2': 'Europe eu-west-2 (London)',
  'eu-west-3': 'Europe eu-west-3 (Paris)',
  'sa-east-1': 'South America sa-east-1 (São Paulo)',
  'us-east-1': 'US East (N. Virginia)',
  'us-east-2': 'US East (Ohio)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
};

export const filterArray = (
  arrayToBeFiltered: string[],
  inputArray: string[]
) => {
  return arrayToBeFiltered.filter((el) => !inputArray.includes(el));
};

export const filterByWebUrl = (data: any, webUrl: string): any[] =>
  Object.values(data).flatMap((items: any) =>
    items.filter((item: any) => item.WebUrl === webUrl)
  );

export const filterByRegion = (items: any[], regions: string[]): any[] =>
  items.filter((item) => regions.includes(item.Region));

interface ExcalationType {
  Slack: string;
  Gchat: string;
  Email: string;
}

export const ESCALATION_TYPE: ExcalationType = {
  Email: 'Email',
  Gchat: 'Gchat',
  Slack: 'Slack',
};

interface IconProp {
  Slack: string;
  GChat: string;
  Mail: string;
}

export const ICON_TYPE: IconProp = {
  Slack: 'Slack',
  GChat: 'GChat',
  Mail: 'Mail',
};

interface StatusProp {
  Uptime: string;
  Downtime: string;
  ['SSL Expiry']: string;
}

export const STATUS_TYPE: StatusProp = {
  Uptime: 'Uptime',
  Downtime: 'Downtime',
  ['SSL Expiry']: 'SSL Expiry',
};
