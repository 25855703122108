import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import { accountService } from '../../../services';
import { DashboardHeader } from '../../../components/Header/DashboardHeader';
import useStyles from './ConfigureAccount.style';
import { RoutesFun } from '../../../schemas';
import { useHistory } from 'react-router-dom';
import EAForm from './EAForm';
import CSPForm from './CSPForm';
import Subscription from './Subscription';
import AWSform from './ConfigureAccount-AWS';
import ActiveDirectory from './ActiveDirectory';
import Retail from './Retail';
import { Navigation } from '../../../components/Navigation/Navigation';

interface Configureprops {
  childern?: React.ReactNode;
  location?: {
    state?: {
      Name: string;
      CloudProvider: string;
      Location: string;
      PayeeOrganisation: string;
      Attributes: string;
    };
  };
}

export const ConfigureAccountPage: React.FC<Configureprops> = (props) => {
  const Routes=RoutesFun();
  const classes = useStyles();
  const history = useHistory();
  let state: any;

  if (props.location && props.location.state) {
    state = props.location.state;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ServiceType: 'Subscription',
  });
  const [serviceType, setServiceType] = React.useState<string[]>([]);
  const [selectedServiceType, setSelectedServiceType] = React.useState<any>(
    'Subscription'
  );

  const { register, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const handleTypeChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    if (event.target.name === 'ServiceType') {
      setSelectedServiceType(event.target.value);
    }
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  const cancel = () => {
    history.push({
      pathname: Routes.CreateAccount,
      state: state,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getAllServiceTypes = async () => {
    const result = await accountService.getServiceTypes();
    setServiceType(result);
  };

  const selectedPlan: { [key: string]: any } = {
    EA: (
      <EAForm
        accountDetails={accountDetails}
        handleTypeChange={handleTypeChange}
        cancel={cancel}
      />
    ),
    CSP: (
      <CSPForm
        accountDetails={accountDetails}
        handleTypeChange={handleTypeChange}
        cancel={cancel}
      />
    ),
    Subscription: (
      <Subscription
        accountDetails={accountDetails}
        handleTypeChange={handleTypeChange}
        cancel={cancel}
      />
    ),
    'Active Directory': (
      <ActiveDirectory
        accountDetails={accountDetails}
        handleTypeChange={handleTypeChange}
        cancel={cancel}
      />
    ),
    Retail: (
      <Retail
        accountDetails={accountDetails}
        handleTypeChange={handleTypeChange}
        cancel={cancel}
      />
    ),
  };

  useEffect(() => {
    getAllServiceTypes();
    // setType(state);
  }, []);

  return (
    <Fragment>
      <DashboardHeader />

      <Grid container className="innerContainer">
        <div className="splitLayout">
          <div className="leftNav">
            <Navigation />
          </div>

          <div className="rightContent">
            <div className="createAccountContainer">
              <div className="innerContainer">
                <div className="sContainer mb-15">
                  <div className="inventoryHeading pl-5">
                    <span className="font-30px fw-300">Configure Account</span>
                    <span className="instanceInfo ml-20 font-20px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        className="bi bi-question-circle ml-5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="configureAccountForm">
                  {state.CloudProvider === 'AZURE' ? (
                    <div className="w-100">
                      <p>I would like to:</p>
                      {serviceType.length ? (
                        <FormControl
                          variant="outlined"
                          className="mt-15 mb-10 configureFormElement w-100"
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="type"
                            name="Type"
                            value={selectedServiceType}
                            // ref={register({ required: true })}
                            defaultValue={selectedServiceType}
                            onChange={handleTypeChange}
                          >
                            {serviceType.map((item: any) => (
                              <MenuItem
                                key={item.Id}
                                value={item.TypeShorthand}
                              >
                                {item.Type}
                              </MenuItem>
                            ))}
                          </Select>
                          <span className={classes.warningMessage}>
                            {errors?.ServiceType && 'ServiceType is required'}
                          </span>
                        </FormControl>
                      ) : null}

                      {selectedPlan[selectedServiceType]}
                    </div>
                  ) : state.CloudProvider === 'AWS' ? (
                    <AWSform cancel={cancel} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};
