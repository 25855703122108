import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      width: '88%',
      margin: '0 auto',
      padding: '50px 15px',
      '& .fa-cog': {
        padding: '0 5px',
      },
    },
    innerContainer: {
      padding: '15px 0',
      fontSize: '12px',
    },
    '& .MuiPaper-root': {
      top: '345px',
    },
    '& li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root': {
      borderBottom: '1px solid black',
    },
    subHeader: {
      borderBottom: '1px solid black',
      padding: '20px',
    },
    list: {
      paddingLeft: '20px',
    },
    colBtn: {
      padding: 0,
      fontSize: '16px',
      lineHeight: 0,
    },
    btn: {
      background: '#1986e0',
      color: 'white',
      borderRadius: 'revert',
      margin: '0px 10px',
      '& .fa-plus-circle': {
        margin: '0 5px',
      },
    },
    headerGrid: {
      backgroundColor: 'white',
      margin: '20px 0px',
    },
    acntHeader: {
      backgroundColor: '#fff',
      padding: '15px',
      margin: '10px 0px',
      cursor: 'pointer    ',
    },
    styleColor: {
      color: '#00c83c',
    },
    styleIcon: {
      padding: '0 5px',
      cursor: 'pointer',
    },
    accordionHeader: {
      background: '#fbfbfb',
      borderTop: '2px solid #1582dc',
      padding: '18px 5px ',
      '& .accordionTitle': {
        padding: '5px 20px',
      },
      '& .actionsBtn': {
        textAlign: 'center',
        padding: '5px 20px',
        color: '#333',
      },
      '& .helpBtn': {
        background: '#777',
        border: '1px solid #777',
        boxSizing: 'border-box',
        color: 'white',
        width: '100px',
        padding: '5px',
        marginLeft: '15px',
        cursor: 'pointer',
        '&:hover': {
          background: '#777',
          color: '#fff',
        },
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
    footer: {
      // padding: '12px 10px',
      '& .yesBtn': {
        background: '#1583dc',
        border: '1px solid #1583dc',
        boxSizing: 'border-box',
        color: 'white',
        width: '200px',
        padding: '12px',
        float: 'left',
        cursor: 'pointer',
        '&:hover': {
          background: '#1678c8',
          border: '1px solid #1678c8',
        },
      },
      '& .noBtn': {
        background: '#777',
        border: '1px solid #777',
        boxSizing: 'border-box',
        color: 'white',
        width: '200px',
        padding: '12px',
        float: 'right',
        cursor: 'pointer',
        '&:hover': {
          background: '#777',
          color: '#fff',
        },
      },
    },
    accordionContainer: {
      padding: '0',
      margin: '10px 0 !important',
      boxShadow: 'none',
      '& .accordionContainerHead': {
        background: '#e6f4ff',
      },
      '& th.MuiTableCell-root': {
        fontSize: '12px',
      },
      '& td.MuiTableCell-root': {
        fontSize: '14px',
      },
      '& .actionsBtn': {
        display: 'flex',
        justifyContent: 'center',
        '& [data-prefix="fas"]': {
          margin: '0 16px',
          fontSize: '15px',
          alignSelf: 'center',
          '&.fa-toggle-on': {
            fontSize: '18px',
          },
        },
        '& span': {
          alignSelf: 'center',
          width: 'auto',
          height: 'auto',
          fontSize: 'initial',
        },
      },
      '& .accordionTitle': {
        '& span': {
          display: 'inline-block',
          padding: '8px',
        },
        '& p': {
          fontSize: '15px',
          padding: '8px 10px',
          display: 'inline-block',
        },
      },
      '& .accordionContentBox': {
        background: '#fdfdfd',
        padding: '0 !important',
        '& .accordionContentDesc': {
          background: '#f6f8fa',
          padding: '5px',
          fontSize: '14px',
          margin: '3px !important',
          boxShadow: '0 3px 3px #ccc',
          '& span': {
            display: 'inline-block',
            padding: '0',
            //   color: '#0078db',
            //   background: 'transparent',
          },
        },
        '& .accordionContent': {
          display: 'flex',
          '& span': {
            display: 'flex',
            //   background: '#e8e8e8',
            //   padding: '8px 4px 8px 5px',
            //
          },
          '& .accDetails': {
            padding: '0px',
            width: '100%',
            '& .accDetailRow': {
              borderBottom: '1px solid #ccc',
            },
            '& .accDetailArea': {
              padding: '8px',
              fontSize: '13px',
              '& b': {
                fontWeight: '500',
              },
              '& span': {
                display: 'inline-block',
                padding: '0',
                color: '#0078db',
                background: 'transparent',
              },
              '& ul': {
                margin: '5px 0',
                padding: '0',
                '& li': {
                  listStyle: 'none',
                  width: '24%',
                  display: 'inline-block',
                  '& span': {
                    color: 'inherit',
                  },
                },
              },
            },
          },
        },
      },
      '& .Mui-expanded .accordionTitle span .fa-caret-right': {
        transform: 'rotate(90deg)',
      },
    },
    warningMessage: {
      padding: '2px 8px',
      color: 'red',
      width: '100%',
      maxWidth: '350px',
      borderRadius: '5px',
      fontSize: '12px',
      textAlign: 'left',
    },
    typography: {
      padding: theme.spacing(2),
    },
    popoverWidth: {
      maxWidth: '1000px',
    },
    disabledButton: {
      cursor: 'default !important',
      filter: 'brightness(130%)',
    }
  })
);

export default useStyles;
