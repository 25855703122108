import React, { useEffect } from 'react';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas';
import schedulingImage0 from '../../../assets/images/scheduling-top-banner.png';
import schedulingImage1 from '../../../assets/images/vm-scheduling-tab.png';
import schedulingImage2 from '../../../assets/images/staging-shutdown-tab.png';
import schedulingImage3 from '../../../assets/images/qa-tab.png';
import schedulingImage4 from '../../../assets/images/identify-0-period-tab.png';

export const SchedulingDetail: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const Routes=RoutesFun();
  return (
    <React.Fragment>
      <div className="outerContiner scrollContainer">
        <SupportHeader />

        <div className="home-banner cf-row p-0 flex-container homeBanner">
          <div className="innerContainer12 schedulingDetailImage">
            <div className="homeBannerText">
              <h1>CloudForestX &amp; Scheduling</h1>
              <p className="fw-300 mb-20">
              CloudForestX comes with one of a kind approach towards cloud schedule which comes with various actions including shutting down during non-production times, turning off staging and quality assurance resources, among others.
              </p>
            </div>
            <div className="mb-5 flex-container flex-img d-none">
              <img className="flex-img " src={schedulingImage0} width="100%" />
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
          <div className="supportContent">
            <div className="innerContainer12 flex-container my-20">
              <div className="py-0 px-20">
                <div className="innerContainer12">
                  <p className="mt-10">
                  With the rising usage of the Azure / AWS cloud, managing expenses for their usage has become a pivotal element for every organization. Improper timing of cloud running time is what leads to unwanted expenses. CloudForestX has come up with an outstanding solution to eradicate this issue through its scheduling.
                  </p>

                  <p className="mt-20">
                  Scheduling is probably one of the easiest and biggest factors to optimize the running cloud and manage the expenses. By timing the cloud uptime correctly, CloudForestX ensures that you get the benefits of Microsoft Azure / AWS without unnecessary spending.
                  </p>

                  <p className="mt-20">
                  CloudForestX allows users to make the most out of their Azure / AWS cloud with our scheduling service, ensuring that they only pay for the running time of their cloud.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
                <div className="contentArea">
                    <div>
                    <h4>
                        <span className="blueL">Virtual Machine Scheduling</span>
                        {/* <span>Solution for Enterprises</span> */}
                    </h4>
                    <p className="mt-20 mb-30">
                    CloudForestX will run multiple tests and research on your production hours to schedule the running of virtual machines, eradicating unnecessary costs.
                    </p>
                    </div>
                </div>

                <div className="contentImage">
                    <img
                    className="flex-img img-width"
                    src={schedulingImage1}
                    width="100%"
                    />
                </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container my-30">
                <div className="contentArea">
                    <div>
                    <h4>
                      <span className="red">Staging Shutdown</span>
                    </h4>
                    <p className="mt-20 mb-30">
                    Staging when not required could be hefty for the organization. By analyzing and reviewing the cloud, CloudForestX can shut down the staging resources to keep the cloud computing costs in check.
                    </p>
                    </div>
                </div>
                
                <div className="contentImage">
                    <img
                    className="flex-img img-width"
                    src={schedulingImage2}
                    width="100%"
                    />
                </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
              <div className="contentArea">
                <div>
                  <h4>
                    <span className="greenL">Quality Assurance</span>
                  </h4>
                  <p className="mt-20 mb-30">
                  Quality assurance system attains data to identify potential issues. CloudForestX can find out the perfect time for running QA and turn it off when it is not needed, drastically reducing the cloud expenses.
                  </p>
                </div>
              </div>
            
              <div className="contentImage">
                <img
                className="flex-img img-width"
                src={schedulingImage3}
                width="100%"
                />
              </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container my-40">
              <div className="contentArea">
                <div>
                  <h4>
                    <span className="purple">Identify Zero Usage Periods</span>
                  </h4>
                  <p className="mt-20 mb-30">
                  With expertise in scheduling, CloudForestX will identify zero usage periods on your cloud and schedule it’s on and off, allowing you to pay for the cloud services when you are actually using them.
                  </p>
                </div>
              </div>
            
              <div className="contentImage my-20">
                <img
                className="flex-img img-width"
                src={schedulingImage4}
                width="100%"
                />
              </div>
            </div>
        </div>

        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SchedulingDetail;
