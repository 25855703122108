export const exportToFile = (fileBuffer: string, fileName: string) => {
  const byteCharacters = atob(fileBuffer);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray]);
  const url = URL.createObjectURL(blob);
  const dummyLink = document.createElement('a');
  dummyLink.href = url;
  dummyLink.download = `${fileName}`;
  dummyLink.click();
};
