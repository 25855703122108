import { useState, useCallback } from 'react';

interface UseCopyTextReturn {
  copy: boolean;
  copyToClipboard: (text: string) => void;
  error: string | null;
}

export const useCopyText = (): UseCopyTextReturn => {
  const [copy, setCopy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopy(true);
        setError(null);
        setTimeout(() => setCopy(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        setError('Failed to copy text');
        console.error('Error copying to clipboard:', err);
      });
  }, []);

  return { copy, copyToClipboard, error };
};
