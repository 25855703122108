
import { io, Socket } from 'socket.io-client';
import config from './config/GlobalConfig';
let socket: Socket | null = null;

export const initializeSocket = (): Socket => {
  if (!socket) {
    // Initialize the socket connection if it hasn't been initialized
    socket = io(config.apiBaseUrl || '', {
      withCredentials: true,
    });
    console.log('Socket connection initialized');
  }
  return socket;
};

export const getSocket = (): Socket => {
  if (!socket) {
    throw new Error('Socket not initialized. Call initializeSocket first.');
  }
  return socket;
};
