import { Grid } from '@material-ui/core';
import React from 'react';
import Modal from 'react-modal';
import './DeleteModal.css';

interface deleteModal {
  show: boolean;
  onClose?: { (): void };
  metaData: string;
  deleteFunction: any;
  isDisabled?: boolean;
}

const DeleteModal: React.FC<deleteModal> = ({
  show,
  onClose,
  metaData,
  deleteFunction,
  isDisabled,
}) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Delete Confirmation</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>
        <div className="alertMessage ">
          Are you sure you want to delete {metaData} ?
        </div>
        <div className="mt-0 modalFooter">
          <button
            type="button"
            className="btn btn-primary ml-10 "
            onClick={() => {
              deleteFunction(), onClose;
            }}
            disabled={isDisabled}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};

export default DeleteModal;
