import { HttpService } from '../HttpService/HttpService';

export class SubscriptionsService {
  constructor(private httpService: HttpService) {}
  async getFilterData(cp: any): Promise<any> {
    let accountSubscription = '';
    cp === 'AZURE'
      ? (accountSubscription = '/accounts/getSubscription')
      : cp === 'AWS'
      ? (accountSubscription = '/accounts/getIAMUserId')
      : (accountSubscription = '/accounts/getSubscription');
    try {
      const response = await this.httpService.get(accountSubscription);
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
}
