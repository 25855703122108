import { APIS } from '../../../utils/apiConfig';
import { HttpService } from '../../HttpService/HttpService';

export class AdminPanelBillingService {
  constructor(private httpService: HttpService) {}
  async getAllFeatures(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_ALL_FEATURES(), {});
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async getOrgBillingHistory(params: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ORG_BILLING_HISTORY(params),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async getActiveSub(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_ACTIVE_SUB(), {});
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async getAllBillingRequests(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ALL_BILLING_REQUEST(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async setBillingRequest(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.SET_BILLING_REQUEST(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
}
