import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import './CostSaving.css';
import { useHistory } from 'react-router-dom';
import { costSavingService } from '../../services';
import {
  SavingAnalysisProps,
  CostSavingProps,
} from '../../schemas/costsaving.schema';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../store/index';
import {
  costFormatter2,
  currencySymbolHandler,
  handleError,
} from '../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

/*-----Redirect to 403 page---------- */

import { FEATURES } from '../../utils/constants';
import { ALL_VALUE } from '../Invoice/config';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';

const storageKeys = {
  CS_SELECTED_SUBSCRIPTION: 'CS_SELECTED_SUBSCRIPTION',
  RIGHTSIZING_SELECTED_AZUREID: 'RIGHTSIZING_SELECTED_AZUREID',
};

export const getSelectedSubscription = (): string => {
  return localStorage.getItem(storageKeys.CS_SELECTED_SUBSCRIPTION) || 'all';
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.CS_SELECTED_SUBSCRIPTION, value);
};

export const CostSaving: React.FC = () => {
  const history = useHistory();
  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = selectedAccountInfo.selectedAccount;
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const isAccountSelected = selectedAccountInfo.isAccountSelected;
  const isAccountActive = selectedAccount?.Credentials;

  const [SavingAnalysis, setSavingAnalysis] = React.useState<
    SavingAnalysisProps[]
  >([]);
  const [loader, setLoader] = React.useState(false);
  const [SavingAreas, setSavingAreas] = React.useState<CostSavingProps[]>([]);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );

  /*-----Redirect to 403 page---------- */
  const [pageLoad, setPagDone] = React.useState(false);
  const userData = useSelector(userState);
  const userPlans = userData.userData?.plan.features;

  const setCostSavingData = (data: any) => {
    if (data && data.length > 0) {
      setSavingAnalysis(data[0].Saving_Analysis);
      setSavingAreas(data[0].Saving_Areas);
    }
  };

  const getCostSavingData = async () => {
    try {
      setLoader(true);
      const fallBackResult = [
        {
          Saving_Analysis: {
            Possible_Monthly_Savings: '0.00',
            Potential_Spot_Savings: '0.00',
            Realised_Savings: '0.00',
          },
          Saving_Areas: [
            { Idle_Resources: '0.00', Category: 'idleresource' },
            { Mis_Provisioned_Resources: '0.00', Category: 'rightsizing' },
            {
              Previous_Generation_Resources: '0.00',
              Category: 'rightsizing',
            },
            {
              'Reserve_Purchase_Recommendations#(Yearly_Monthly_Savings)':
                '0.00',
              Category: 'rightsizing',
            },
            { Scheduling: '0.00', Category: 'schedulevm' },
          ],
          Currency_Symbol: '',
        },
      ];
      setCostSavingData(fallBackResult);
      const cloudProvider = selectedAccount?.CloudProvider.toUpperCase();
      let params;
      if (cloudProvider == CLOUD_PROVIDER_SERVICES.AZURE) {
        params = {
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
        const rows =
          isAccountSelected && isAccountActive
            ? await costSavingService.getCostSavingDataRes(params)
            : fallBackResult;
        setCostSavingData(rows);
      } else {
        params = {
          subscriptionId: selectedSubscriptionId,
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
        const rows =
          isAccountSelected && isAccountActive
            ? await costSavingService.getAWSCostSavingDataRes(params)
            : '';
        setCostSavingData(rows);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setUpdatedCurrency(selectedCurrency);
      setLoader(false);
    }
  };

  const handleClick = (urlPath: string, tab: string) => {
    const company = localStorage.getItem('CompanyName');
    const tabValue: { [key: string]: string } = {
      Idle_Resources: 'idle',
      Unused_Resources: 'unused',
      Mis_Provisioned_Resources: 'rightsizing',
      Previous_Generation_Resources: 'previousGeneration',
      'Reserve_Purchase_Recommendations#(Yearly_Monthly_Savings)': 'reserved',
    };
    if (urlPath === 'schedulevm') {
      history.push(`/${company}/${urlPath.toLowerCase()}`);
    } else {
      history.push(`/${company}/${urlPath.toLowerCase()}/${tabValue[tab]}`);
    }
  };

  useEffect(() => {
    getCostSavingData();
  }, [
    selectedSubscriptionId,
    isAccountActive,
    isAccountSelected,
    selectedAccountId,
    selectedCurrency,
  ]);

  useEffect(() => {
    setLoader(true);
    setPagDone(false);
    setTimeout(() => {
      const name = userPlans?.includes(FEATURES.COST_SAVING);
      if (name) {
        setPagDone(true);
        setLoader(false);
      }
    }, 1000);
  }, [userPlans]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  return (
    <Fragment>
      {loader && <LoadingScreen />}
      {pageLoad ? (
        <>
          <div className="sContainer mb-15">
            <div className="inventoryHeading font-26px fw-300 pl-5">
              Cost Savings
            </div>
            <div>
              <SelectedAccount onAccountChange={onAccountChange} />
            </div>
          </div>
          <div className="outerContainer">
            <div className="">
              <form className="container" noValidate></form>

              <div>
                <Grid container spacing={0} className="cardSummary bg-white">
                  {Object.entries(SavingAnalysis).map(
                    ([key, value]: any, index) => (
                      <Grid
                        className="d-flex"
                        item
                        md={4}
                        sm={4}
                        xs={12}
                        key={'save' + index}
                      >
                        <Card className="d-flex mt-0 w-100">
                          <div className="catImage">
                            {key === 'Possible_Monthly_Savings' ? (
                              <i className="bi bi-calendar3 font-35px"></i>
                            ) : key === 'Potential_Spot_Savings' ? (
                              <i className="bi bi-vinyl font-40px"></i>
                            ) : (
                              <div className="realisedSavingIcon">
                                {currencySymbolHandler(updatedCurrency)}
                              </div>
                            )}
                          </div>
                          <div className="details">
                            <CardContent className="content">
                              <div className="w-100">
                                <p>{key.split('_').join(' ')}</p>
                                <h5 className="fw-500">
                                  {/* <span className="pr-2">{CurrencySymbol}</span> */}
                                  <span>
                                    {costFormatter2(updatedCurrency, value)}
                                    {/* {Number(value)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                                  </span>
                                </h5>
                              </div>
                            </CardContent>
                          </div>
                        </Card>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid
                  container
                  spacing={0}
                  className="mt-30 mb-30 shortSummary"
                >
                  {SavingAreas.map((details: CostSavingProps, i: any) => (
                    <Grid item md={4} sm={4} xs={12} key={'item' + i}>
                      <span
                        onClick={() =>
                          handleClick(
                            Object.values(details)[1],
                            Object.keys(details)[0]
                          )
                        }
                        // to={
                        //   Object.values(details)[1]
                        //     ? Object.values(details)[1]
                        //     : 'costsaving'
                        // }
                        className="cardLink"
                      >
                        <div key={'savingCard' + i}>
                          <h5 className="fw-500">
                            {/* <span className="pr-2">{CurrencySymbol}</span> */}
                            <span>
                              {costFormatter2(
                                updatedCurrency,
                                Object.values(details)[0]
                              )}
                              {/* {Number(Object.values(details)[0])
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                            </span>
                          </h5>
                          <p>
                            {Object.keys(details)[0]
                              .replace(/_/g, ' ')
                              .split('#')
                              .map((value, i) => (
                                <span key={i}>
                                  {value} <br />
                                </span>
                              ))}
                          </p>
                        </div>
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </div>

              <div className="mt-30">&nbsp;</div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default CostSaving;
