import { rightSizingService, urlMonitoringService } from '../../services';
import { RightSizingInput } from './RightsizingAndIdleInput';

export const config = {
  getSSLDays: () => urlMonitoringService.getSSLDays(),
  setSSLDays: (params: { RecommendedDays: string }) =>
    urlMonitoringService.setSSLDays(params),

  deleteSSLDays: () => urlMonitoringService.deleteSSLDays(),

  setRightsizingdays: (params: RightSizingInput) =>
    rightSizingService.setRightsizingDays(params),
  getRightsizingdays: (accountId: string, cloudProvider: string) =>
    rightSizingService.getRightsizingDays(accountId, cloudProvider),
};
