const AUTH_STATE = 'auth-state';
export class AuthService {
  // constructor() {}
  setAuth = (access_token: string) => {
    localStorage.setItem(AUTH_STATE, access_token);
  };

  removeAuth = () => {
    localStorage.removeItem(AUTH_STATE);
  };

  checkAuth = () => {
    const token = localStorage.getItem(AUTH_STATE) || '';
    return token.length > 0;
  };

  getToken = () => {
    return localStorage.getItem(AUTH_STATE) || '';
  };

  refreshToken = (token: string) => {
    localStorage.removeItem(AUTH_STATE);
    localStorage.setItem(AUTH_STATE, token);
  };
}
