import React, { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import './AdminDashboard.css';
import {
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableContainer } from '@mui/material';
import { ReactComponent as EditIcon } from '../../../assets/ICONS/material-symbols_edit-outline.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/ICONS/ic_round-delete-outline.svg';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Teams from './components/Teams';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas';
import { ERRORS } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import { config } from './config';
import CompanyTile from './components/CompanyTile';
import EditUserModal from '../../components/Modals/EditUsersModal/EditUserModal';
import DeleteModal from '../../../components/Modals/DeleteModal/DeleteModal';
import AddOrganizationModal from '../../components/Modals/AddOrganizationModal/AddOrganizationModal';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useSelector } from 'react-redux';
import { userState } from '../../../store';
export const ROLES = ['', 'Super Admin', 'Admin', 'Employee', 'QA'];

interface CompaniesDetailsInterface {
  name: string;
  cloudProviders: string[];
}

let flag = false;

const AdminDashboard = () => {
  const openDocInNewTab = (link: string) => {
    window.open(link);
  };
  const [loader, setLoader] = useState({
    companies: false,
    usersList: false,
    documentList: false,
  });
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [companiesDetails, setCompaniesDetails] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const [
    showAddOrganizationModal,
    setShowAddOrganizationModal,
  ] = useState<boolean>(false);
  const [clickedUserDetails, setClickedUserDetails] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [docs, setDocs] = useState([]);
  const [search, setSearch] = useState('');
  const [searchDocument, setSearchDocument] = useState('');
  const [changeArrow, setChangeArrow] = useState('desc');
  const [cfxTeam, setCfxTeam] = useState([]);
  const history = useHistory();
  const Routes = RoutesFun();
  const userData = useSelector(userState);
  const user = userData.userData;

  const fetchAllOrganizaionDetails = async () => {
    try {
      setLoader((prevValue: any) => ({
        ...prevValue,
        companies: true,
      }));
      const res = await config.fetchCompaniesData();
      setCompaniesDetails(res);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevValue: any) => ({
        ...prevValue,
        companies: false,
      }));
    }
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };
  const handleSearchDocumentChange = (e: any) => {
    setSearchDocument(e.target.value);
  };

  const getAllUsers = async () => {
    try {
      setLoader((prevValue: any) => ({ ...prevValue, usersList: true }));
      const rows = await config.getAllUsersForSuperAdmin();
      if (rows.length) {
        rows.sort((a: any, b: any) => a.createdAt - b.createdAt);
        const superAdminFilteredList = rows.filter(
          (row: any) => row.RoleId !== 1
        );
        const filterQA = superAdminFilteredList.filter(
          (row: any) => row.RoleId !== 4
        );

        const cfxTeamArr: any = [];
        filterQA.forEach((row: any) => {
          if (row.CompanyName === 'ThinkSys') {
            return cfxTeamArr.push({
              name: `${row.FirstName} ${row.LastName}`,
              role: row.RoleId,
            });
          }
        });
        setCfxTeam(cfxTeamArr);
        setAllUsers(filterQA);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevValue: any) => ({ ...prevValue, usersList: false }));
    }
  };

  const onclose = () => setShowEditUserModal(false);
  const onCloseDeleteModal = () => setDeleteModal(false);
  const onCloseAddOrganizationModal = () => setShowAddOrganizationModal(false);

  const onDeleteUser = async () => {
    try {
      setLoader((prevValue: any) => ({ ...prevValue, usersList: true }));
      const params = {
        userId: clickedUserDetails.UserId,
      };

      const res = await config.deleteUsersData(params);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      getAllUsers();
      setDeleteModal(false);
    }
  };

  const onSubmitEditUserModal = async (
    UserId: number,
    active: boolean,
    role: number,
    PrevRoleId: number
  ) => {
    try {
      setLoader((prevValue: any) => ({ ...prevValue, usersList: true }));
      const params = {
        userId: UserId,
        PrevRoleId,
        RoleId: +role,
        IsActive: active ? 1 : 0,
      };

      const res = await config.updateUsersData(params);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      getAllUsers();
      setShowEditUserModal(false);
    }
  };

  const addOrganizations = async (
    companyName: string,
    emailAddress: string
  ) => {
    try {
      setLoader((prevValue: any) => ({ ...prevValue, companies: true }));
      const params = { CompanyName: companyName, EmailAddress: emailAddress };
      const result = await config.addOrganizations(params);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevValue: any) => ({ ...prevValue, companies: false }));
      setShowAddOrganizationModal(false);
    }
  };

  const getUploadedDocuments = async () => {
    try {
      setLoader((prevValue: any) => ({ ...prevValue, documentList: true }));
      const result = await config.getUploadedDocuments();
      if (result.docResultObj.length) {
        const sortedDocumentOnDate = result.docResultObj.sort(
          (a: any, b: any) => {
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);

            return bDate.getTime() - aDate.getTime();
          }
        );
        setDocs(sortedDocumentOnDate);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevValue: any) => ({ ...prevValue, documentList: false }));
    }
  };

  const toggleDocuments = () => {
    if (changeArrow === 'asc') {
      const sortedDocs = docs.sort((a: any, b: any) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);

        return aDate.getTime() - bDate.getTime();
      });
      setLoader((prevValue: any) => ({ ...prevValue, documentList: true }));
      setDocs([]);
      setTimeout(() => {
        setLoader((prevValue: any) => ({ ...prevValue, documentList: true }));
        setDocs(sortedDocs);
        setLoader((prevValue: any) => ({ ...prevValue, documentList: false }));
      }, 100);
    } else {
      const sortedDocs = docs.sort((a: any, b: any) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);

        return bDate.getTime() - aDate.getTime();
      });
      setLoader((prevValue: any) => ({ ...prevValue, documentList: true }));
      setDocs([]);
      setTimeout(() => {
        setLoader((prevValue: any) => ({ ...prevValue, documentList: true }));
        setDocs(sortedDocs);
        setLoader((prevValue: any) => ({ ...prevValue, documentList: false }));
      }, 100);
    }
  };

  const returnSearchedResult = (data: any) =>
    searchDocument
      ? data?.filter(
          (doc: any) =>
            Object.values(doc.docName)
              .join(',')
              ?.toLowerCase()
              .includes(searchDocument.toLowerCase()) ||
            doc.company?.toLowerCase().includes(searchDocument.toLowerCase()) ||
            doc.shareto?.toLowerCase().includes(searchDocument.toLowerCase())
        )
      : data;

  useEffect(() => {
    fetchAllOrganizaionDetails();
    getAllUsers();
    getUploadedDocuments();
  }, []);

  useEffect(() => {
    if (flag) {
      toggleDocuments();
    }
    flag = true;
  }, [changeArrow]);

  return (
    <>
      <>
        {user?.RoleId !== 5 &&
        user?.RoleId !== 6 &&
        user?.RoleId !== 7 &&
        user?.RoleId !== 8 &&
        user !== undefined ? (
          <div className="adminDashbord">
            <div className="leftCont">
              <div className="upperStrip">
                <div className="OrgText">All Organization</div>
                {/* {companiesDetails.length > 2 && ( */}
                <div>
                  <span className="mr-10">
                    <button
                      className="btn btn-primary"
                      style={{
                        height: '38px',
                        width: '209px',
                        borderRadius: '3px',
                      }}
                      onClick={() => setShowAddOrganizationModal(true)}
                    >
                      Add New Organizaion
                    </button>
                  </span>
                  <span>
                    <button
                      className="btn btn-primary"
                      style={{
                        height: '38px',
                        width: '98px',
                        borderRadius: '3px',
                      }}
                      onClick={() =>
                        history.push({
                          pathname: Routes.AllOrganizations,
                          state: {
                            allOrgData: companiesDetails,
                          },
                        })
                      }
                    >
                      See All
                    </button>
                  </span>
                </div>

                {/* )} */}
              </div>
              <div className="middleStrip mt-20">
                {loader.companies ? (
                  <div className="flex tripleLoader">
                    <CircularProgress size={'2.9rem'} />
                    <CircularProgress size={'2.9rem'} />
                    <CircularProgress size={'2.9rem'} />
                  </div>
                ) : companiesDetails.length ? (
                  companiesDetails
                    .slice(0, 3)
                    .map(
                      (company: CompaniesDetailsInterface, index: number) => (
                        <CompanyTile
                          company={company}
                          index={index}
                          key={index}
                        />
                      )
                    )
                ) : (
                  <div className="noDataText"> No Records Found </div>
                )}
              </div>
              <div className="bottomStrip mt-20">
                <div>
                  <div className="bottom-upperStrip">
                    <span className="OrgText">All Users</span>
                    <span>
                      <OutlinedInput
                        id="email"
                        name="EmailAddress"
                        className="outlineInputArea bgcolor"
                        type="email"
                        autoComplete="off"
                        value={search ? search : ''}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        }
                      />
                    </span>
                  </div>
                  <div className="mt-10">
                    <TableContainer
                      className="accContainer "
                      sx={{ maxHeight: '315px', overflowY: 'scroll' }}
                    >
                      <Table
                        className="table adminTable adminPanelTable"
                        size="small"
                        aria-label="a dense table"
                        stickyHeader
                      >
                        <TableHead className="admintableHead">
                          <TableRow>
                            <TableCell
                              className="adminTableheading"
                              align="right"
                            >
                              S.No.
                            </TableCell>
                            <TableCell className="adminTableheading">
                              Name
                            </TableCell>
                            <TableCell className="adminTableheading">
                              Organization Name
                            </TableCell>
                            <TableCell className="adminTableheading">
                              Role
                            </TableCell>
                            <TableCell className="adminTableheading">
                              Status
                            </TableCell>
                            <TableCell className="adminTableheading">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loader.usersList ? (
                            <TableRow>
                              {' '}
                              <TableCell></TableCell>
                              <TableCell>Loading...</TableCell>
                            </TableRow>
                          ) : allUsers.length ? (
                            (search
                              ? allUsers?.filter(
                                  (user: any) =>
                                    user.FirstName?.toLowerCase().includes(
                                      search.toLowerCase()
                                    ) ||
                                    user.LastName?.toLowerCase().includes(
                                      search.toLowerCase()
                                    ) ||
                                    user.CompanyName?.toLowerCase().includes(
                                      search.toLowerCase()
                                    )
                                )
                              : allUsers
                            ).map((user: any, index: number) => (
                              <TableRow
                                key={index}
                                className={
                                  index % 2 === 0 ? 'oddRow' : 'evenRow'
                                }
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {user.FirstName} {user.LastName}
                                </TableCell>
                                <TableCell>
                                  <span
                                    className="upperCase clickble"
                                    onClick={() =>
                                      history.push({
                                        pathname: Routes.Organization,
                                        state: {
                                          CompanyName: user.CompanyName,
                                        },
                                      })
                                    }
                                  >
                                    {user.CompanyName}
                                  </span>
                                </TableCell>
                                <TableCell>{ROLES[user.RoleId]}</TableCell>
                                <TableCell
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '205px',
                                    height: '65px',
                                  }}
                                >
                                  <div
                                    className={
                                      user.IsActive === 1
                                        ? 'active'
                                        : 'inactive'
                                    }
                                  >
                                    {user.IsActive ? 'Active' : 'Inactive'}
                                  </div>
                                  {user.IsVerified ? (
                                    <div className="active">Verified</div>
                                  ) : (
                                    <div
                                      className="inactive"
                                      style={{ width: '86px' }}
                                    >
                                      Unverified
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <EditIcon
                                    className="mr-10"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setClickedUserDetails({
                                        UserId: user.Id,
                                        RoleId: user.RoleId,
                                        IsActive: user.IsActive,
                                        Name: `${user.FirstName} ${user.LastName}`,
                                      });
                                      setShowEditUserModal(true);
                                    }}
                                  />
                                  <DeleteIcon
                                    onClick={() => {
                                      setClickedUserDetails({
                                        UserId: user.Id,
                                        Name: `${user.FirstName} ${user.LastName}`,
                                      });
                                      setDeleteModal(true);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                className="loadingSection"
                              >
                                No Records Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className="rightCont">
              <div className="rightContHeader">
                <div className="teamHeading mt-5 ml-5">CFX Team</div>
                {/* <div>
              <AddIcon />
            </div> */}
              </div>
              <div className="teamDetailsBox pt-15">
                {loader.usersList ? (
                  <div className="teamLoadingText noDataText">Loading...</div>
                ) : cfxTeam.length ? (
                  cfxTeam.map((team: any, i: any) => (
                    <Teams key={i} name={team.name} role={team.role} />
                  ))
                ) : (
                  <div className="teamLoadingText noDataText">No Records</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* docs table */}
        <div className="mt-30">
          <div className="bottom-upperStrip">
            <div className="OrgText mt-10">All Documents</div>
            <div className="flex">
              <span className="searchDocumentUpload">
                {' '}
                <OutlinedInput
                  id="email"
                  name="EmailAddress"
                  className="outlineInputArea bgcolor"
                  type="email"
                  autoComplete="off"
                  value={searchDocument ? searchDocument : ''}
                  onChange={handleSearchDocumentChange}
                  placeholder="Search..."
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  }
                />
              </span>
              <span className="ml-20">
                <button
                  onClick={() => {
                    history.push({ pathname: Routes.DocumentUpload });
                  }}
                  className="btn btn-primary "
                  style={{
                    height: '38px',
                    width: '150px',
                    borderRadius: '3px',
                  }}
                >
                  Upload Document
                </button>
              </span>
            </div>
          </div>
          <div style={{ height: '352px' }}>
            <TableContainer
              sx={{ maxHeight: '315px', overflowY: 'scroll' }}
              className="accContainer mt-10 mb-10"
            >
              <Table
                className="table adminTable adminPanelTable"
                size="small"
                aria-label="a dense table"
                stickyHeader
              >
                <TableHead className="admintableHead">
                  <TableRow>
                    <TableCell
                      width="1%"
                      align="center"
                      className="adminTableheading"
                    >
                      Sr. No.
                    </TableCell>
                    <TableCell
                      width="55%"
                      align="left"
                      className="adminTableheading"
                    >
                      Document Name
                    </TableCell>
                    <TableCell
                      width="30%"
                      align="left"
                      className="adminTableheading"
                    >
                      Shared With
                    </TableCell>
                    <TableCell
                      width="30%"
                      align="left"
                      className="adminTableheading"
                    >
                      Shared
                    </TableCell>
                    <TableCell
                      width="15%"
                      align="left"
                      className="adminTableheading"
                    >
                      Organization Name
                    </TableCell>

                    <TableCell
                      width="8%"
                      align="left"
                      className="adminTableheading cursor"
                      onClick={() => {
                        setChangeArrow(changeArrow === 'asc' ? 'desc' : 'asc');
                      }}
                    >
                      Uploaded Date
                      <span className="mt-15">
                        {changeArrow === 'desc' ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell
                      width="8%"
                      align="left"
                      className="adminTableheading "
                    >
                      Uploaded Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader.documentList ? (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell> Loading...</TableCell>
                    </TableRow>
                  ) : docs.length &&
                    searchDocument &&
                    !returnSearchedResult(docs).length ? (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell> No Searched Results</TableCell>
                    </TableRow>
                  ) : docs.length || returnSearchedResult(docs).length ? (
                    returnSearchedResult(docs).map((doc: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell
                          width="1%"
                          align="center"
                          className="tableCell uploadedDoc-serialNo"
                        >
                          {i + 1}
                        </TableCell>
                        <TableCell className="tableCell" width="20%">
                          {Object.values(doc?.docName).map(
                            (docName: any, i: number) => (
                              <TableRow key={i}>
                                <span
                                  className="docName"
                                  onClick={() =>
                                    openDocInNewTab(
                                      Object.values(doc.docLink)[i] as string
                                    )
                                  }
                                >
                                  {docName}
                                </span>
                              </TableRow>
                            )
                          )}
                        </TableCell>
                        <TableCell className="tableCell" width="30%">
                          {doc.shareto}
                        </TableCell>
                        <TableCell
                          className={doc.IsPrivate ? ' active' : 'tableCell'}
                        >
                          {doc.isPrivate ? 'Privately' : 'Publically'}
                        </TableCell>
                        <TableCell width="15%">{doc.company}</TableCell>
                        <TableCell width="8%">
                          {doc.date.split('T')[0]}
                        </TableCell>
                        <TableCell width="8%">
                          {doc.date.split('T')[1].split('.')[0]}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell> No Documents Uploaded yet</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>

      {showEditUserModal && (
        <EditUserModal
          show={showEditUserModal}
          onClose={onclose}
          RoleId={clickedUserDetails.RoleId}
          UserId={clickedUserDetails.UserId}
          IsActive={clickedUserDetails.IsActive}
          onSubmit={onSubmitEditUserModal}
          UserName={clickedUserDetails.Name}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onClose={onCloseDeleteModal}
          metaData={`"${clickedUserDetails.Name}"`}
          deleteFunction={onDeleteUser}
        />
      )}
      {showAddOrganizationModal && (
        <AddOrganizationModal
          show={showAddOrganizationModal}
          onClose={onCloseAddOrganizationModal}
          onSubmit={addOrganizations}
        />
      )}
    </>
  );
};

export default AdminDashboard;
