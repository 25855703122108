import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountService,
  authService,
  httpService,
  userService,
} from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import { handleError } from '../../../utils/config';

const UserCredentialForm = (props: any) => {
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'CSP',
    ActiveDirectoryId: '',
    LoginId: '',
    Password: '',
    ApplicationId: '',
    AccountType: '',
    AccountPlan: '',
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        // const response = await accountService.createAccount(accountDetails);
        // // toasterService.show(response, 'success');
        // history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0}>
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12}>
            <p className="mb-10">
              Your billing/invoice data will be accessed by CloudForestX from
              your CSP/Partner account.
            </p>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ul className={classes.list}>
              <ol className={helperClasses.p0}>
                <li>
                  Write the Azure Active Directory domain, used to manage your
                  Partner Portal users.
                </li>
              </ol>
            </ul>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">
                MICROSOFT AZURE ACTIVE DIRECTORY ID
              </InputLabel>
              <Input
                id="ActiveDir"
                type="password"
                name="ActiveDirectoryId"
                inputRef={register({ required: true })}
                value={accountDetails.ActiveDirectoryId}
                onChange={handleChange}
                // MenuProps={MenuProps}
              />
              <span className={classes.warningMessage}>
                {formState.touched.ActiveDirectoryId &&
                  accountDetails.ActiveDirectoryId.length === 0 &&
                  'Active Directory Id is required'}
              </span>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6} xs={6}></Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ul className={classes.list}>
              <ol start={2} className={helperClasses.p0}>
                <li>
                  Create a Partner Portal that will be used for CloudForestX
                  access. Make sure to login with the user once to set the
                  password.
                </li>
                <li>
                  The user must have Admin Agent and Billing Admin rights to
                  read invoice data.
                </li>
                <li>Enter User's Azure AD login ID</li>
              </ol>
            </ul>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">
                USER'S AZURE AD LOGIN ID
              </InputLabel>
              <Input
                id="loginId"
                type="password"
                name="LoginId"
                inputRef={register({ required: true })}
                value={accountDetails.LoginId}
                onChange={handleChange}
                // MenuProps={MenuProps}
              />
              <span className={classes.warningMessage}>
                {formState.touched.LoginId &&
                  accountDetails.LoginId.length === 0 &&
                  'Login Id is required'}
              </span>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6} xs={6}></Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ul className={classes.list}>
              <ol start={5} className={helperClasses.p0}>
                <li>Enter Users Password</li>
              </ol>
            </ul>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">PASSWORD</InputLabel>
              <Input
                id="password"
                type="password"
                name="Password"
                inputRef={register({ required: true })}
                value={accountDetails.Password}
                onChange={handleChange}
                // MenuProps={MenuProps}
              />
              <span className={classes.warningMessage}>
                {formState.touched.Password &&
                  accountDetails.Password.length === 0 &&
                  'Password is required'}
              </span>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6} xs={6}></Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ul className={classes.list}>
              <ol start={6} className={helperClasses.p0}>
                <li>
                  Create a new Native App registration in the Partner Portal App
                  Management area.
                </li>
                <li>Enter App ID for Native App:</li>
              </ol>
            </ul>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">
                APPLICATION ID
              </InputLabel>
              <Input
                id="appid"
                type="password"
                name="ApplicationId"
                inputRef={register({ required: true })}
                value={accountDetails.ApplicationId}
                onChange={handleChange}
                // MenuProps={MenuProps}
              />
              <span className={classes.warningMessage}>
                {formState.touched.ApplicationId &&
                  accountDetails.ApplicationId.length === 0 &&
                  'Application Id is required'}
              </span>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6} xs={6}></Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ul className={classes.list}>
              <ol start={8} className={helperClasses.p0}>
                <li>Choose your Azure Account type:</li>
              </ol>
            </ul>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">ACCOUNT TYPE</InputLabel>
              <Select
                id="demo-mutiple-name"
                // multiple
                name="AccountType"
                value={accountDetails.AccountType}
                ref={register({ required: true })}
                onChange={handleChange}
                input={<Input />}
                // MenuProps={MenuProps}
              >
                {['Commercial', 'Azure Government', 'Azure Germany'].map(
                  (item: any) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
              <span className={classes.warningMessage}>
                {errors?.AccountType && 'AccountType is required'}
              </span>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6} xs={6}></Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ul className={classes.list}>
              <ol start={9} className={helperClasses.p0}>
                <li>
                  Please choose the Partner Center Billing Version to use:*
                </li>
              </ol>
            </ul>
            <p>
              *Select between establishing a connection with either Classic CSP
              or Azure Plan. Only customers with the selected agreements will be
              allowed into this account. Separate accounts will be required if
              you have customers on both Azure Plan and Classic CSP.
            </p>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <FormControl className={`${classes.formControl} mb-30`}>
              <InputLabel id="demo-mutiple-name-label">ACCOUNT PLAN</InputLabel>
              <Select
                id="demo-mutiple-name"
                // multiple
                name="AccountPlan"
                value={accountDetails.AccountPlan}
                ref={register({ required: true })}
                onChange={handleChange}
                input={<Input />}
                // MenuProps={MenuProps}
              >
                {['Azure Plan', 'Classic CSP'].map((item: any) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <span className={classes.warningMessage}>
                {errors?.AccountPlan && 'AccountType is required'}
              </span>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6} xs={6}></Grid>
          <Grid item md={6} sm={6} xs={6} className={classes.footer}>
            <button className="noBtn" onClick={props.cancel}>
              BACK
            </button>
          </Grid>
          <Grid item md={6} sm={6} xs={6} className={classes.footer}>
            <button type="submit" className="yesBtn">
              UPDATE
            </button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserCredentialForm;
