import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import {
  createStyles,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import '../Modal.css';
import { useForm } from 'react-hook-form';
import { rightSizingService } from '../../../services';
import { useHistory } from 'react-router-dom';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';
import { handleError } from '../../../utils/config';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  vmData: {
    ResourceName: string;
    SubscriptionId: string;
    ResourceGroup: string;
    Id: number;
    IsIgnored: number;
    ScheduleType: string;
    InstanceName: string;
    InstanceId: string;
    AccountId: string;
  };
  onClose?: { (prop: any): void };
  type: number;
}

export const ResetVMModal: React.FC<FixProps> = (props) => {
  const { children, show = false, onClose, vmData, type } = props;

  const [msg, setMsg] = React.useState({
    errMsg: '',
    successMsg: '',
  });
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [customError, setCustomError] = React.useState('');
  const history = useHistory();

  const selectedAccount = useSelector(accountState).selectedAccount;
  const machineType =
    selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
      ? 'VM'
      : selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
      ? 'Instance'
      : 'VM';

  const onSubmit = async () => {
    setButtonStatus(true);
    setLoader(true);
    try {
      setCustomError('');
      if (type === 3) {
        const response = await rightSizingService.CancelVM({
          Id: vmData.Id,
          ScheduleType: vmData.ScheduleType,
        });

        if (response) {
          setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        }
      } else {
        const response = await rightSizingService.ResetVM({
          Id: vmData.Id,
          ScheduleType: vmData.ScheduleType,
        });

        if (response) {
          setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        }
      }
      setButtonStatus(false);
      setTimeout(() => {
        onClose && onClose(true);
      }, 1000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        // alert(err);
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
        setCustomError(e.error.message);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 500);
    }
  };

  const toggleStateVM = async () => {
    setButtonStatus(true);
    setLoader(true);
    try {
      setCustomError('');
      const response = await rightSizingService.ToggleStateVM({
        Id: vmData.Id,
        IsIgnored: +!vmData.IsIgnored,
      });

      if (response) {
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
      }
      setTimeout(() => {
        onClose && onClose(true);
      }, 1000);
    } catch (e: any) {
      setCustomError(e.error.message);
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        // alert(err);
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 500);
    }
  };
  const rescheduleVM = async () => {
    setButtonStatus(true);
    setLoader(true);
    try {
      setCustomError('');
      const response = await rightSizingService.RescheduleVM({
        Id: vmData.Id,
        ScheduleType: vmData.ScheduleType,
      });

      if (response) {
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
      }
      setTimeout(() => {
        onClose && onClose(true);
      }, 1000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        // alert(err);
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
        setCustomError(e.error.message);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 500);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
        className="fixModal"
        {...props}
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">
                {type === 0
                  ? `Cancel Scheduled ${machineType}`
                  : type === 2
                  ? `ReSchedule ${machineType}`
                  : type === 3
                  ? "Cancel Today's Schedule"
                  : type === 4
                  ? 'Move To Recommendation'
                  : `Ignore ${machineType}`}
              </span>
              <span
                className="modalClose"
                onClick={() => {
                  onClose && onClose(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
          <Grid item md={12} className="modalBody pt-20">
            <h6>
              <span className="fw-400">
                {type === 0
                  ? `Do you really want to cancel scheduled ${machineType} `
                  : type === 2
                  ? 'Do you really want to reschedule '
                  : type === 3
                  ? "Do you really want to cancel today's schedule for "
                  : 'Are you sure to move '}
                <b>
                  {vmData.ResourceName && vmData.ResourceName}
                  {vmData.InstanceName && vmData.InstanceName}
                  {type !== 1 && '?'}
                </b>
              </span>
              {type === 1 && (
                <span className="fw-400">
                  {` to ${vmData.IsIgnored === 0 ? 'IGNORED' : 'Recommeded'}
                  ${machineType} list ?`}
                </span>
              )}
            </h6>
          </Grid>
          {customError && (
            <div className="alert-danger p-2 px-5 mx-15 mt-10">
              {customError}
            </div>
          )}
          <div className="mt-0 modalFooter">
            <button
              type="submit"
              className="btn btn-primary ml-10"
              onClick={() => {
                type === 1
                  ? toggleStateVM()
                  : type === 2
                  ? rescheduleVM()
                  : type === 4
                  ? toggleStateVM()
                  : onSubmit();
              }}
              disabled={buttonStatus}
            >
              YES
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                onClose && onClose(false);
              }}
            >
              NO
            </button>
          </div>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
