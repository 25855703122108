export default {
  AWS: {
    getCSDropdownLable: (VMData: any) =>
      VMData && `${VMData.InstanceName || ''} (${VMData.InstanceId})`,
  },
  AZURE: {
    getCSDropdownLable: (VMData: any) =>
      VMData && `${VMData.VMName || ''} (${VMData.ResourceGroup})`,
  },
  default: {
    getCSDropdownLable: (VMData: any) =>
      VMData && `${VMData.VMName || ''} (${VMData.ResourceGroup})`,
  },
};
