import { ReactNode, CSSProperties, ButtonHTMLAttributes } from 'react';
enum ButtonTypeEnum {
  Contained = 'contained',
  Outlined = 'outlined',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
}

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  endIcon?: ReactNode;
  buttonType: 'contained' | 'outlined' | 'warning' | 'info' | 'light' | 'dark';
  startIcon?: ReactNode;
  onClickHandler: (prop?: any) => void;
  style?: CSSProperties;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  onClickHandler,
  endIcon,
  startIcon,
  style,
  buttonType,
  // ...rest // This will capture all other props
}) => {
  const btnTypeHandler = (type: string) => {
    switch (type) {
      case ButtonTypeEnum.Contained:
        return 'btn-primary';
      case ButtonTypeEnum.Outlined:
        return 'btn-outline-primary';
      case ButtonTypeEnum.Warning:
        return 'btn-warning';
      case ButtonTypeEnum.Info:
        return 'btn-info';
      case ButtonTypeEnum.Light:
        return 'btn-light';
      case ButtonTypeEnum.Dark:
        return 'btn-dark';
      default:
        return 'btn-primary';
    }
  };

  return (
    <button
      className={`btn ${btnTypeHandler(buttonType)} btn-with-icon`}
      onClick={onClickHandler}
      style={style}
      // {...rest} // Spread the rest props here
    >
      {startIcon && <span className="leftIconButton">{startIcon}</span>}
      {children}
      {endIcon && <span className="rightIconButton">{endIcon}</span>}
    </button>
  );
};

export default CustomButton;
