import { APIS } from '../../../utils/apiConfig';
import { HttpService } from '../../HttpService/HttpService';

export class AdminDashboardService {
  constructor(private httpService: HttpService) {}

  async getAllOrgDetails(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ALL_ORGANISATION_DETAILS(),
        {}
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async updateUserDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.UPDATE_USERS_DETAILS(),
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async deleteUserDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.DELETE_USERS_DETAILS(),
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
}
