import React, { useEffect, useRef, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import { config as allOrgConfig } from '../../AllOrganizations/config';
import { config as orgDetailsConfig } from '../../OrganizationDetails/config';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../store/userSlice';

interface CustomSwitch {
  status: number;
  metaData: any;
  fetchLatestData: (cloud: string) => void;
}

const CustomSwitch: React.FC<CustomSwitch> = ({
  status,
  metaData,
  fetchLatestData,
}) => {
  const [checked, setChecked] = useState<any>(status ? true : false);
  const [loader, setLoader] = useState<any>(false);

  const dispatch = useDispatch();

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: checked ? green[800] : red[800],
    },
    '& .css-jsexje-MuiSwitch-thumb': {
      backgroundColor: checked ? green[800] : red[800],
    },
    '& .css-1yjjitx-MuiSwitch-track ': {
      backgroundColor: checked ? green[800] : red[800],
    },
  }));

  const submit = async () => {
    try {
      setLoader(true);
      if (metaData.CompanyName) {
        const res = await allOrgConfig.updateOrganisationStatus({
          CompanyName: metaData.CompanyName,
          IsActive: checked ? 1 : 0,
        });
        dispatch(
          userActions.replaceUserState({
            successMessage: res,
          })
        );
        fetchLatestData('-');
      } else {
        const params = {
          CloudProvider: metaData.selectedCloud.toUpperCase(),
          AccountId: metaData.accountId,
          subscriptionId: metaData.subId,
          IsActive: checked ? 1 : 0,
        };
        const res = await orgDetailsConfig.updateAccountDetails(params);
        dispatch(
          userActions.replaceUserState({
            successMessage: res.message,
          })
        );
        fetchLatestData(metaData.selectedCloud);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      submit();
    }
  }, [checked]);

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };

  return (
    <>
      {loader && <LoadingScreen />}
      <CustomSwitch
        className="negativeMargin"
        {...label}
        checked={checked ? true : false}
        onChange={() => setChecked((prevValue: any) => !prevValue)}
      />
    </>
  );
};

export default CustomSwitch;
