import { Grid } from '@material-ui/core';
import React from 'react';
import Modal from 'react-modal';
import './ZoomInModal.css';
import '../../../pages/UptimeMonitoring/DetailMonitoring.css';
import { ZoomInMapOutlined } from '@mui/icons-material';
import GraphLoader from '../../../pages/ServicesReports/GraphLoader';
import MonitoringGraph from '../../UrlMonitoring/MonitoringGraph';

interface deleteModal {
  show: boolean;
  onClose?: { (): void };
  metaData: any;
  // URLValue: any;
}

const ZoomInModal: React.FC<deleteModal> = ({
  show,
  onClose,
  metaData,
  // URLValue,
}) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal zoomInModalWidth"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="">
          <div className="flex flex-end  mt-5 mr-5">
            <ZoomInMapOutlined
              className="zoomOutIcon cursor-pointer"
              sx={{
                fontSize: '25px',
                borderRadius: '10px',
                color: 'gray',
              }}
              onClick={onClose}
            />
          </div>
        </Grid>
        <div className="zoomInModalGraphContainer">
          {!Object.values(metaData).flat().length ? (
            <GraphLoader isLoading={false} />
          ) : (
            <MonitoringGraph
              urlData={metaData}
              grid={{
                left: '2%',
                right: '2%',
                bottom: '0%',
                height: '98%',
                top: '2%',
                containLabel: true,
              }}
              style={{
                width: '100%',
                height: '85%',
                marginTop: '-1rem',
                padding: 0,
              }}
            />
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default ZoomInModal;
