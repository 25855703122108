import React, { useState, useEffect } from 'react';
import './UploadedDocuments.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ERRORS, EXCLUDED_EMAILS } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { handleError } from '../../utils/config';
import { config } from './config';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import ShareDocumentModal from '../../components/Modals/ShareDocumentModal/ShareDocumentModal';
import { userService } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { userState } from '../../store';
import { userActions } from '../../store/userSlice';
/*-----Redirect to 403 page---------- */

import { FEATURES } from '../../utils/constants';

const UploadedDocuments = () => {
  const [loader, setLoader] = useState(false);
  const [docLoader, setDocLoader] = useState(false);
  const [networkError, setNetworkError] = useState('');
  const [sharedDocumentName, setShareDocumentName] = useState({});
  const [docs, setDocs] = useState<any>();
  const [selectedDocsId, setSelectedDocsId] = useState<number[]>([]);
  const [showShareDocModal, setShowShareDocModal] = useState<boolean>(false);
  const [shareToEmails, setShareToEmails] = useState<string[]>([]);
  const [usersEmails, setUsersEmails] = useState<any>({});
  const userDataState = useSelector(userState).userData;

  /*-----Redirect to 403 page---------- */
  const userData = useSelector(userState);
  const userPlans = userData.userData?.plan.features;
  const [plan, setPlans] = React.useState(userPlans);

  const history = useHistory();
  const dispatch = useDispatch();
  const onCloseShareDocModal = () => setShowShareDocModal(false);

  const fetchAllUsersData = async () => {
    try {
      setLoader(true);

      const userData = await userService.getALLUserDetails();

      const mappedUsers: {
        [key: string]: string;
      } = {};
      if (userData.length) {
        userData.forEach((user: any) => {
          if (
            user.EmailAddress !== userDataState?.EmailAddress &&
            user.IsActive &&
            user.RoleId !== 1 &&
            !EXCLUDED_EMAILS.includes(user.EmailAddress)
          ) {
            mappedUsers[
              user.EmailAddress
            ] = `${user.FirstName} ${user.LastName}`;
          }
        });
      }
      setUsersEmails(mappedUsers);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  const getUploadedDocuments = async () => {
    try {
      setDocLoader(true);
      const result = await config.getUploadedDocuments();
      setDocs(result.docResultObj);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setDocLoader(false);
    }
  };

  const openDocInNewTab = (link: string) => {
    window.open(link);
  };

  const customToasterNotification = (
    sharedUsers: string[],
    unSharedUsers: string[]
  ) => {
    return (
      <>
        {sharedUsers.length && unSharedUsers.length ? (
          <div>
            <div>
              <div className="customToasterHeader mb-5">
                Document shared with following users
              </div>
              {sharedUsers.map((user: string, i: number) => (
                <div className="customToasterUsersOuter ml-10">
                  <span>{i + 1}.</span>
                  <span className="customToasterText">{user}</span>
                </div>
              ))}
            </div>
            <div className="mt-20 ml-10">
              <div className="customToasterHeader mb-5">
                Document unshared with following users
              </div>
              {unSharedUsers.map((user: string, i: number) => (
                <div className="customToasterUsersOuter">
                  <span>{i + 1}.</span>
                  <span className="customToasterText">{user}</span>
                </div>
              ))}
            </div>
          </div>
        ) : sharedUsers.length ? (
          <>
            {' '}
            <div>
              <div className="customToasterHeader mb-10">
                Document shared with following users
              </div>
              {sharedUsers.map((user: string, i: number) => (
                <div className="customToasterUsersOuter">
                  <span>{i + 1}. </span>
                  <span className="customToasterText">{user}</span>
                </div>
              ))}
            </div>
          </>
        ) : unSharedUsers.length ? (
          <>
            {' '}
            <div>
              <div className="customToasterHeader mb-10">
                {' '}
                Document unshared with following users
              </div>
              {unSharedUsers.map((user: string, i: number) => (
                <div className="customToasterUsersOuter">
                  <span>{i + 1}. </span>
                  <span className="customToasterText">{user}</span>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </>
    );
  };

  const onSubmit = async ({ selectedEmails, selectedDocuments }: any) => {
    try {
      const recentlyUnsharedUsers = shareToEmails.filter(
        (value) => !selectedEmails.includes(value)
      );
      const recentlySharedUsers = selectedEmails.filter(
        (user: string) => !shareToEmails.includes(user)
      );
      setLoader(true);
      const params = {
        docId: [selectedDocsId],
        shareto: recentlySharedUsers,
        unshare: recentlyUnsharedUsers,
        sharedoc: selectedDocuments,
      };
      const result = await config.shareDoc(params);
      if (result.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: customToasterNotification(
              recentlySharedUsers,
              recentlyUnsharedUsers
            ),
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      setShowShareDocModal(false);
      getUploadedDocuments();
    }
  };

  useEffect(() => {
    if (userDataState) {
      getUploadedDocuments();
      fetchAllUsersData();
    }
  }, [userDataState]);

  return (
    <>
      {loader && <LoadingScreen />}

      <>
        <div className="uploadedDoc-UpperStrip securityHeading">
          Uploaded Documents
        </div>

        <div className="uploadedDoc-bottomStrip mt-15">
          <TableContainer className="accContainer">
            <Table
              className="table adminTable adminPanelTable"
              size="small"
              aria-label="a dense table"
            >
              <TableHead className="admintableHead">
                <TableRow>
                  <TableCell
                    width="1%"
                    align="center"
                    className="adminTableheading"
                  >
                    Sr. No.
                  </TableCell>
                  <TableCell
                    width="30%"
                    align="left"
                    className="adminTableheading"
                  >
                    Document Name
                  </TableCell>
                  <TableCell
                    width="20%"
                    align="left"
                    className="adminTableheading"
                  >
                    Uploaded By
                  </TableCell>
                  <TableCell
                    width="20%"
                    align="left"
                    className="adminTableheading"
                  >
                    Shared By
                  </TableCell>
                  <TableCell
                    width="30%"
                    align="left"
                    className="adminTableheading"
                  >
                    Shared
                  </TableCell>
                  <TableCell
                    width="8%"
                    align="left"
                    className="adminTableheading"
                  >
                    Uploaded Date
                  </TableCell>
                  <TableCell width="10%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docs?.length ? (
                  docs.map((doc: any, i: number) => (
                    <TableRow key={doc.docName + i}>
                      <TableCell
                        align="center"
                        className="tableCell uploadedDoc-serialNo"
                      >
                        {i + 1}
                      </TableCell>
                      <TableCell className="tableCell" width="20%">
                        {Object.values(doc?.docName).map(
                          (docName: any, i: number) => (
                            <TableRow key={i}>
                              <span
                                className="docName"
                                onClick={() =>
                                  openDocInNewTab(
                                    Object.values(doc.docLink)[i] as string
                                  )
                                }
                              >
                                {docName}
                              </span>
                            </TableRow>
                          )
                        )}
                      </TableCell>
                      <TableCell>{doc.uploadBy}</TableCell>
                      <TableCell>{doc.sharedBy}</TableCell>
                      <TableCell
                        className={doc.IsPrivate ? ' active' : 'tableCell'}
                      >
                        {doc.isPrivate ? 'Privately' : 'Publically'}
                      </TableCell>
                      <TableCell>{doc.date.split('T')[0]}</TableCell>
                      <TableCell width="2%">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            const usersListSortedByChecked: {
                              [key: string]: string;
                            } = {};

                            Object.entries(usersEmails).forEach(
                              ([key, value]: any) => {
                                doc.shareto.forEach((user: string) => {
                                  if (user === key)
                                    usersListSortedByChecked[key] = value;
                                });
                              }
                            );
                            Object.entries(usersEmails).forEach(
                              ([key, value]: any) => {
                                doc.shareto.forEach((user: string) => {
                                  if (user !== key)
                                    usersListSortedByChecked[key] = value;
                                });
                              }
                            );

                            setShareDocumentName(doc?.docName);
                            setSelectedDocsId(doc.docId);
                            setShareToEmails(doc.shareto);
                            setShowShareDocModal(true);
                            setUsersEmails(
                              doc.shareto.length === 0
                                ? usersEmails
                                : usersListSortedByChecked
                            );
                          }}
                        >
                          {' '}
                          Share doc
                        </button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : docLoader || loader ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell> Loading...</TableCell>
                  </TableRow>
                ) : docs && docs?.length === 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell> No Documents Uploaded yet</TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>

      {showShareDocModal && (
        <ShareDocumentModal
          show={showShareDocModal}
          onClose={onCloseShareDocModal}
          shareToEmails={shareToEmails}
          usersEmails={usersEmails}
          onSubmit={onSubmit}
          documentsName={sharedDocumentName}
          // sharedDocumentsName={[
          //   'ThinkSys_1696919591664_SampleCSVFile_556kb.csv',
          //   'ThinkSys_1696919592025_SampleCSVFile_556kb.vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          // ]}
        />
      )}
    </>
  );
};

export default UploadedDocuments;
