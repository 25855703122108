import { styled, Tooltip, TooltipProps } from '@mui/material';

const CustomSideNavTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#0078db',
    color: '#fff',
    fontSize: '16px',
    textTransform: 'uppercase',
    padding: '8px 15px',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    marginTop: '18px',
    maxWidth: '100%',
  },
  ['& .MuiTooltip-arrow']: {
    color: '#0078db',
  },
  // Adjusting the positioning of the tooltip when placed on the right-end
  ['&[data-popper-placement*="right"] .MuiTooltip-tooltip']: {
    marginLeft: '4px', // Space between the tooltip and the li element
  },
}));

export default CustomSideNavTooltip;
