import { useState, useRef } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import { ListItemText } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox } from '@mui/material';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { config } from './config';
import { userActions } from '../../../store/userSlice';
import { useDispatch } from 'react-redux';
import { ERRORS } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';

interface RequestedRowInterface {
  row: any;
  index: number;
  features: { [key: string]: number };
  getAllReq: { (): void };
}
const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RequestedRow: React.FC<RequestedRowInterface> = ({
  row,
  index,
  features,
  getAllReq,
}) => {
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [price, setPrice] = useState('');
  const [loader, setLoader] = useState(false);
  const [networkError, setNetworkError] = useState('');
  const dispatch = useDispatch();
  const [validationError, setValidationError] = useState<any>({
    featuresError: false,
    priceError: false,
  });
  const priceRef = useRef<any>(null);
  const FeaturesRef = useRef<any>(null);
  const history = useHistory();

  const handlePriceChange = (e: any) => {
    setPrice(e.target.value);
  };

  const handleFeaturesChange = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((emails: any) => {
      if (emails === undefined) flag = false;
    });
    if (flag) setSelectedFeatures(value);
  };

  const setBillingRequest = async () => {
    try {
      setLoader(true);

      if (!price && !selectedFeatures.length) {
        FeaturesRef.current.focus();
        setValidationError({
          featuresError: true,
          priceError: true,
        });
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please select Features & Price.',
          })
        );
        return;
      } else if (!price) {
        priceRef.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          priceError: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please enter Price.',
          })
        );
        return;
      } else if (!selectedFeatures.length) {
        FeaturesRef.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          featuresError: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please select Features.',
          })
        );
        return;
      }

      const params = {
        CompanyId: row.CompanyId,
        PlanName: row.name,
        PlanPrice: +price,
        PlanFeatures: selectedFeatures.reduce(
          (acc: number[], el: string) => acc.concat(features[el]),
          []
        ),
      };
      const res = await config.setBillingRequest(params);
      if (res.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: res.message,
          })
        );
        getAllReq();
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row.CompanyName}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.CompanyEmail ? row.CompanyEmail : '-----'}</TableCell>
        <TableCell>
          <div
            className={validationError.featuresError ? 'inputErrorBorder' : ''}
          >
            <FormControl fullWidth className="docUpload-inputBoxes">
              <InputLabel
                style={{ fontSize: '16px' }}
                id="demo-multiple-chip-label"
              >
                Select Features
              </InputLabel>
              <Select
                inputRef={FeaturesRef}
                style={{ fontSize: '16px' }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedFeatures}
                onChange={handleFeaturesChange}
                input={<OutlinedInput label="Select Features" />}
                renderValue={(selected: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                    }}
                  >
                    {selected.length <= 2 ? (
                      selected.map((value: any) => (
                        <Chip
                          className="Custom-chip"
                          size="small"
                          key={value}
                          label={value}
                          onDelete={() =>
                            setSelectedFeatures(
                              selectedFeatures.filter(
                                (item: any) => item !== value
                              )
                            )
                          }
                          deleteIcon={
                            <CancelIcon
                              style={{ color: '#00BBDC', fontSize: '20px' }}
                              onMouseDown={(event: any) =>
                                event.stopPropagation()
                              }
                            />
                          }
                        />
                      ))
                    ) : (
                      <div className="countPosition">
                        <div className="countP-fDiv">
                          {selected.map((value: any, i: number) => {
                            if (i >= 2) return;
                            return (
                              <Chip
                                className="Custom-chip"
                                size="small"
                                key={value}
                                label={value}
                                onDelete={() =>
                                  setSelectedFeatures(
                                    selectedFeatures.filter(
                                      (item: any) => item !== value
                                    )
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    style={{
                                      color: '#00BBDC',
                                      fontSize: '20px',
                                    }}
                                    onMouseDown={(event: any) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            );
                          })}
                        </div>
                        <div className="extraFields-DocUpload ">
                          {' '}
                          + {selected.length - 2}
                        </div>
                      </div>
                    )}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem
                  onClick={() => {
                    if (
                      selectedFeatures.length !== Object.keys(features).length
                    )
                      setSelectedFeatures([...Object.keys(features)]);
                    else setSelectedFeatures([]);
                  }}
                >
                  <Checkbox
                    style={{
                      color: '#00BBDC',
                    }}
                    checked={
                      selectedFeatures?.length === Object.keys(features).length
                    }
                  />
                  <ListItemText primary="All" />
                </MenuItem>
                {Object.keys(features).map((names: any, i) => (
                  <MenuItem key={names + i} value={names}>
                    <Checkbox
                      style={{
                        color: '#00BBDC',
                      }}
                      checked={selectedFeatures?.indexOf(names) > -1}
                    />
                    <ListItemText primary={names} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </TableCell>
        <TableCell>
          <div className={validationError.priceError ? 'inputErrorBorder' : ''}>
            <TextField
              inputRef={priceRef}
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              onChange={handlePriceChange}
              className="w-70"
              id="outlined-multiline-static"
              label="Enter price..."
              rows={1}
              type="number"
              value={price}
            />
          </div>
        </TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>
          <button
            className="btn btn-primary"
            onClick={() => setBillingRequest()}
          >
            Submit
          </button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RequestedRow;
