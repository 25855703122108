import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CfxLogo } from '../../assets/images/CFX-logo-new.svg';
import './SupportHeader.css';

// import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import NavMenuIcon from '../../assets/images/menuIcon.png';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

// const useStyles = makeStyles({
//   list: {
//     width: 250,
//   },
//   fullList: {
//     width: 'auto',
//   },
// });

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const SupportHeader: React.FC = () => {
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const url = window.location.href.split('/');

  const urlPath = url[url.length - 1];

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const accordionHandleChange = (panel: string) => (
    event: React.ChangeEvent<Record<string, unknown>>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const list = () => (
    <div
      className="drawerList"
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Accordion
        expanded={expanded === 'panel'}
        onChange={accordionHandleChange('panel')}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <h6>
            <span onClick={() => history.push('/')}>Home</span>
          </h6>
        </AccordionSummary>
      </Accordion>

      <Accordion
        className=""
        expanded={expanded === 'panel0'}
        onChange={accordionHandleChange('panel0')}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <h6>
            <span onClick={() => history.push('/bfi')}>
              Business Focused IT
            </span>
          </h6>
        </AccordionSummary>
      </Accordion>

      <Accordion
        className=""
        expanded={expanded === 'panel1'}
        onChange={accordionHandleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <h6>Solutions</h6>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <ul className="topMenuDropDown">
            <li>
              <a href="/security">Security</a>
            </li>
            <li>
              <a href="/software">Software</a>
            </li>
            <li>
              <a href="/costmanagement">Cost Management</a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="d-none"
        expanded={expanded === 'panel1'}
        onChange={accordionHandleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <h6>Industries</h6>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <ul className="topMenuDropDown">
            <li>
              <a href="/costmanagement">Healthcare</a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={accordionHandleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <h6>Pricing</h6>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <ul className="topMenuDropDown">
            <li>
              <a href="/pricing">Request Pricing</a>
            </li>
            <li className="d-none">
              <a href="/cloudcheckup">Request a Cloud Check Up</a>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel3'}
        onChange={accordionHandleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <h6>
            <span onClick={() => history.push('/login')}>Login</span>
          </h6>
        </AccordionSummary>
      </Accordion>

      <Accordion
        className="d-none"
        expanded={expanded === 'panel4'}
        onChange={accordionHandleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <h6>
            <span onClick={() => history.push('/registration')}>
              Get Started
            </span>
          </h6>
        </AccordionSummary>
      </Accordion>
    </div>
  );

  return (
    <React.Fragment>
      <div className="supportHeader">
        <div className="innerContainer12">
          <CfxLogo />

          <div className="mobileNav">
            <React.Fragment>
              <Button onClick={toggleDrawer('right', true)}>
                <img src={NavMenuIcon} width="20" />
              </Button>
              <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
              >
                {list()}
              </SwipeableDrawer>
            </React.Fragment>
          </div>

          <ul className="topMenu">
            <li className="about">
              <a target="_blank" href="https://cloudforestx.com/">
                <span>
                  <i className="bi bi-house-fill font-18px"></i>
                </span>
              </a>
            </li>
            <li className="">
              <span>Products</span>
              <ul className="topMenuDropDown">
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/cost-overview/"
                  >
                    Cost overview
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/dynamic-resource-management/"
                  >
                    Dynamic Resource Management
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/cost-optimization/"
                  >
                    Cost Optimization
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/alarms-and-notifications/"
                  >
                    Alarms and Notifications
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://cloudforestx.com/security/">
                    Security
                  </a>
                </li>
              </ul>
            </li>
            <li className="">
              <span>Solutions</span>
              <ul className="topMenuDropDown">
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/vulnerability-assessment-pen-testing/"
                  >
                    Vulnerability Assessment & Pen testing (VAPT)
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/ci-cd-and-automation/"
                  >
                    CI/CD and Automation
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/monitoring/"
                  >
                    Monitoring
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://cloudforestx.com/devops-sre-services/"
                  >
                    DevOps SRE Services
                  </a>
                </li>
              </ul>
            </li>

            <li className="about">
              <a target="_blank" href="https://cloudforestx.com/pricing/">
                <span>Pricing</span>
              </a>
            </li>
            <li className="sLogin logSignSection">
              <div className="d-flex loginBtnSection">
                <span
                  className={`signUpSection ${
                    urlPath === 'login' || urlPath === ''
                      ? 'activeLandingBtn '
                      : 'loginBtnRadius'
                  }`}
                >
                  <Link to="/login">Login</Link>
                </span>
                <span
                  className={`signUpSection ${
                    urlPath === 'registration'
                      ? 'activeLandingBtn '
                      : 'signUpBtnRadius'
                  }`}
                >
                  <Link to={'/registration'}>Sign Up</Link>
                </span>
              </div>
            </li>
            <li className="sLogin d-none">
              <span>
                <Link to="/registration">Get Started</Link>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportHeader;
