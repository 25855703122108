import React, { useState } from 'react';
import './AddEmail.css';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';

interface FixProps {
  show: boolean;
  onClose?: { (): void };
  onSubmit: {
    (emails: string): void;
  };
}

const emailRegex = /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)/;
const AddEmail: React.FC<FixProps> = ({ show, onClose, onSubmit }) => {
  const [emails, setEmails] = useState('');
  const [emailValidationError, setEmailValidationError] = useState(false);

  const handleEmailsChange = (e: any) => {
    setEmails(e.target.value);
    if (emailRegex.test(emails)) setEmailValidationError(false);
  };

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Add Emails for URL monitoring</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>
        <Grid item md={12} className="modalBody pt-20 addEmailModalHeight">
          <div
            className={
              emailValidationError ? 'inputErrorBorder mb-10 ' : 'mb-10 '
            }
          >
            <TextField
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              className="w-80 ml-25"
              id="outlined-basic"
              label="Add Email"
              variant="outlined"
              onChange={handleEmailsChange}
              value={emails}
              name="Name"
              autoComplete="off"
            />
          </div>
          {emailValidationError ? (
            <div className="w-40 ml-20">
              <div className="alert-danger ml-5">Invalid Email Address</div>
            </div>
          ) : (
            <></>
          )}
        </Grid>
        <div className="mt-0 modalFooter">
          <button
            type="submit"
            className={'btn btn-primary ml-10 cursor-notAllowed '}
            onClick={() => {
              if (!emailRegex.test(emails)) setEmailValidationError(true);
              else {
                onSubmit(emails);
                setEmailValidationError(false);
              }
            }}
          >
            Save
          </button>

          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddEmail;
