import React, { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import './SetCostLimit.css';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import '../Modal.css';
import { useHistory } from 'react-router-dom';
import { accountService } from '../../../services';
import { handleError } from '../../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';
import { ALL_VALUE } from '../../../pages/Invoice/config';
import { useForm } from 'react-hook-form';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
  iType?: any;
  handleSubmitBtn?: (updatedList: any, updatedSubsList: any) => void;
  successRes?: string;
  errorRes?: any;
  selected?: any;
  selectedAzureId?: string;
  selectedAWSId?: string;
  selectedAccountId?: string;
  sub?: Array<any>;
  user: any;
}

export const SetCostLimitModal: React.FC<FixProps> = (props) => {
  const {
    onClose,
    selected,
    errorRes,
    successRes,
    handleSubmitBtn,
    user,
    selectedAWSId,
    selectedAzureId,
    selectedAccountId,
  } = props;

  const history = useHistory();
  const [buttonStatus, setButtonStatus] = React.useState(true);
  const [networkError, setNetworkError] = React.useState('');
  const [accountData, setAccountData] = useState<any[]>([]);
  const [azureIdsLimit, setAzureIdsLimit] = useState<any[]>([]);
  const [subscriptionsLimit, setSubscriptionsLimit] = useState<any[]>([]);
  const cloudProvider = selected?.CloudProvider.toUpperCase();
  const [updatedList, setUpdatedList] = useState<any>({});
  const [updatedSubsList, setUpdatedSubsList] = useState<any>({});

  useEffect(() => {
    getLimit();
  }, [props]);

  const getLimit = async () => {
    try {
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        if (selectedAWSId === ALL_VALUE) {
          const result = await accountService.getAccountDetails(cloudProvider);
          setAccountData(result);
          const newList = result.reduce((acc: any, item: any) => {
            acc[item.AccountId] = item.AWSLimit;
            return acc;
          }, {});

          setUpdatedList(newList);
        } else {
          const result = await accountService.getAccountDetails(cloudProvider);

          const filteredResult = result.filter(
            (accountData: any) => accountData.AliasName === selectedAWSId
          );

          setUpdatedList({
            [filteredResult[0].AccountId]: filteredResult[0].AWSLimit,
          });

          setAccountData(filteredResult);
        }
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAzureId === ALL_VALUE) {
          const result = await accountService.getAccountDetails(cloudProvider);
          const subscriptions = await accountService.getAllSubscriptionDetails();
          setAzureIdsLimit(result);
          setSubscriptionsLimit(subscriptions);

          const newList = result.reduce((acc: any, item: any) => {
            acc[item.AzureId] = item.AzureLimit;
            return acc;
          }, {});

          const newSubsList = subscriptions.reduce((acc: any, item: any) => {
            acc[item.SubscriptionId] = {
              SubscriptionLimit: item.SubscriptionLimit,
              AzureId: item.AzureId,
              SubscriptionName: item.SubscriptionName,
            };
            return acc;
          }, {});

          setUpdatedList(newList);
          setUpdatedSubsList(newSubsList);
        } else {
          const result = await accountService.getAccountDetails(cloudProvider);
          const subscriptions = await accountService.getAllSubscriptionDetails();
          const selectedAzureIdData = result.filter(
            (accountData: any) => accountData.AliasName === selectedAzureId
          );

          const subscriptionsData = subscriptions.filter(
            (subscriptionData: any) =>
              subscriptionData.AzureId === selectedAzureIdData[0].AzureId
          );

          setAzureIdsLimit(selectedAzureIdData);
          setUpdatedList({
            [selectedAzureIdData[0].AzureId]: selectedAzureIdData[0].AzureLimit,
          });
          setSubscriptionsLimit(subscriptionsData);
          setUpdatedSubsList({
            [subscriptionsData[0].SubscriptionId]: {
              SubscriptionLimit: subscriptionsData[0].SubscriptionLimit,
              AzureId: subscriptionsData[0].AzureId,
              SubscriptionName: subscriptionsData[0].SubscriptionName,
            },
          });
        }
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  const { handleSubmit } = useForm<any>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit(() => {
      handleSubmitBtn?.(updatedList, updatedSubsList);
    })();
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal setCostLimit-fixModal"
      {...props}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Set Cost Limit</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <Grid item md={12} className="modalBody">
          <form noValidate onSubmit={handleFormSubmit} autoComplete="off">
            <TableContainer className="">
              <Table className="tableBody">
                <TableHead className="theadBlue">
                  {cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                    ? accountData.map((data, index) => (
                        <TableRow key={'acc' + index}>
                          <TableCell colSpan={2} className="font-13px border0">
                            {data?.AliasName}
                          </TableCell>
                          <TableCell className="border0" align="right">
                            <span className="font-15px mr-10">$</span>
                            <input
                              type="number"
                              defaultValue={data.AWSLimit}
                              min="0"
                              name="account"
                              className="input m-0"
                              onChange={(e) => {
                                const cost = e.target.value;
                                setButtonStatus(false);
                                setUpdatedList({
                                  ...updatedList,
                                  [data.AccountId]: cost,
                                });
                              }}
                              disabled={user?.RoleId == 3 || user?.RoleId == 4}
                            ></input>
                          </TableCell>
                        </TableRow>
                      ))
                    : azureIdsLimit.map((data, index) => (
                        <TableRow key={'acc' + index}>
                          <TableCell colSpan={2} className="font-13px border0">
                            {data?.AliasName}
                          </TableCell>
                          <TableCell className="border0" align="right">
                            <span className="font-15px mr-10">$</span>
                            <input
                              type="number"
                              defaultValue={data.AzureLimit}
                              min="0"
                              name="account"
                              className="input m-0"
                              onChange={(e) => {
                                const cost = e.target.value;
                                setButtonStatus(false);
                                setUpdatedList({
                                  ...updatedList,
                                  [data.AzureId]: cost,
                                });
                              }}
                              disabled={user?.RoleId == 3 || user?.RoleId == 4}
                            ></input>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableHead>

                {cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE && (
                  <TableBody>
                    <TableRow>
                      <TableCell className="p-0 border0" colSpan={3}>
                        <TableContainer className="table-scroll p-0 mt-10">
                          <Table className="pl-10">
                            <TableHead className="subTableHead">
                              <TableRow>
                                <TableCell className="font-13px">
                                  Azure Id Name
                                </TableCell>
                                <TableCell className="font-13px">
                                  Subscription Name
                                </TableCell>
                                <TableCell className="font-13px">
                                  Subscription ID
                                </TableCell>

                                <TableCell className="font-13px">
                                  Set Limit
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody className="tbody">
                              {selectedAccountId === ALL_VALUE
                                ? subscriptionsLimit?.map((s, i) => (
                                    <TableRow
                                      className="tableRow"
                                      key={'sub' + i}
                                    >
                                      <TableCell className="font-12px">
                                        {azureIdsLimit?.map((data) => {
                                          if (data.AzureId === s.AzureId) {
                                            return data.AliasName;
                                          }
                                        })}
                                      </TableCell>
                                      <TableCell className="font-12px">
                                        {s.SubscriptionName}
                                      </TableCell>
                                      <TableCell className="font-12px">
                                        {s.SubscriptionId}
                                      </TableCell>

                                      <TableCell>
                                        <div className="setLimitInput">
                                          <span className="font-15px mr-5">
                                            $
                                          </span>
                                          <input
                                            type="number"
                                            defaultValue={s.SubscriptionLimit}
                                            min="0"
                                            name={s.SubscriptionId}
                                            onChange={(e) => {
                                              const cost = e.target.value;
                                              setButtonStatus(false);
                                              setUpdatedSubsList({
                                                ...updatedSubsList,
                                                [s.SubscriptionId]: {
                                                  SubscriptionLimit: cost,
                                                  AzureId: s.AzureId,
                                                  SubscriptionName:
                                                    s.SubscriptionName,
                                                },
                                              });
                                            }}
                                            className="input mr-10"
                                            disabled={
                                              user?.RoleId == 3 ||
                                              user?.RoleId == 4
                                            }
                                          />
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : subscriptionsLimit
                                    .filter(
                                      (data) =>
                                        data.SubscriptionName ===
                                        selectedAccountId
                                    )
                                    ?.map((s, i) => (
                                      <TableRow
                                        className="tableRow"
                                        key={'sub' + i}
                                      >
                                        <TableCell className="font-12px">
                                          {azureIdsLimit?.map((data) => {
                                            if (data.AzureId === s.AzureId) {
                                              return data.AliasName;
                                            }
                                          })}
                                        </TableCell>
                                        <TableCell className="font-12px">
                                          {s.SubscriptionName}
                                        </TableCell>
                                        <TableCell className="font-12px">
                                          {s.SubscriptionId}
                                        </TableCell>
                                        <TableCell>
                                          <div className="setLimitInput">
                                            <span className="font-15px mr-5">
                                              $
                                            </span>
                                            <input
                                              type="number"
                                              defaultValue={s.SubscriptionLimit}
                                              min="0"
                                              name={s.SubscriptionId}
                                              onChange={(e) => {
                                                const cost = e.target.value;
                                                setButtonStatus(false);
                                                setUpdatedSubsList({
                                                  ...updatedSubsList,
                                                  [s.SubscriptionId]: {
                                                    SubscriptionLimit: cost,
                                                    AzureId: s.AzureId,
                                                    SubscriptionName:
                                                      s.SubscriptionName,
                                                  },
                                                });
                                              }}
                                              className="input mr-10"
                                              disabled={
                                                user?.RoleId == 3 ||
                                                user?.RoleId == 4
                                              }
                                            />
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </form>

          {errorRes && <div className="regMsg mt-10">{errorRes}</div>}
          {successRes && (
            <div className="alert-success p-2 px-5 mt-10">{successRes}</div>
          )}
          {/* </div> */}
        </Grid>
        <div className="mt-0 modalFooter">
          <input
            type="submit"
            className="btn btn-primary ml-10"
            value="SAVE"
            disabled={buttonStatus}
            onClick={() => handleSubmitBtn?.(updatedList, updatedSubsList)}
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};
