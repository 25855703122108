import { AuthService } from './AuthService/AuthService';
import { HttpService } from './HttpService/HttpService';
import { UserService } from './UserService/UserService';
import { APIService } from './APIService/APIService';
import { RightSizingService } from './RightSizingService/RightSizingService';
import { IdleInstanceService } from './IdleInstanceService/IdleInstanceService';
import { CostSavingService } from './CostSaving/CostSavingService';
import { AccountService } from './AccountService/AccountService';
import { CostForecastingService } from './CostForecastingService/CostForecastingService';
import { InventoryService } from './InventoryService/InventoryService';
import { SubscriptionsService } from './SubscriptionsService/SubscriptionsService';
import { InvoiceService } from './InvoiceService/InvoiceService';
import { SecurityScanService } from './SecurityScanService';
import { TagDetailService } from './TagDetailService/TagDetailService';
import { InventoryReportService } from './ReportService/InventoryReportService';
import { AppDashboardService } from './AppDashboard/app-dashboard.service';
// import { featuresService } from './Features/usersFeatures';
import { SlackNotificationManagementService } from './SlackNotificationManagement';
import { CustomNotificationService } from './CustomNotification/customNotification';
import { UrlMonitoringService } from './UrlMonitoring/urlMonitoring';
import { UptimeService } from './Uptime/UptimeService';
import { BillingService } from './Billing/billing';
import { AdminDashboardService } from './AdminPanel/AdminDashboardService/AdminDashboardService';
import { OrganizationDetailsService } from './AdminPanel/OrganizationDetailsService/OrganizationDetailsService';
import { AllOrganizationsService } from './AdminPanel/AllOrganizations/AllOrganizations.service';
import { DocumentUploadService } from './AdminPanel/DocumentUpload/DocumentUpload.service';
import { UploadedDocumentsService } from './UploadedDocumentsService/UploadedDocumentsService';
import { UploadedDocumentsStaticService } from './UploadedDocumentsStaticService/UploadedDocumentsStaticService';
import { AdminPanelBillingService } from './AdminPanel/Billing/BillingService';
import { SystemHealthService } from './AdminPanel/SystemHealth/SystemHealthService';
import { RUMService } from './RUM/RumService';

const authService = new AuthService();
const apiService = new APIService(authService);
const httpService = new HttpService(apiService);
const idleInstanceService = new IdleInstanceService(httpService);
const userService = new UserService(httpService);
const rightSizingService = new RightSizingService(httpService);
const costSavingService = new CostSavingService(httpService);
const accountService = new AccountService(httpService);
const costForecastingService = new CostForecastingService(httpService);
const inventoryService = new InventoryService(httpService);
const subscriptionsService = new SubscriptionsService(httpService);
const invoiceService = new InvoiceService(httpService);
const securityScanService = new SecurityScanService(httpService);
const tagDetailService = new TagDetailService(httpService);
const inventoryReportService = new InventoryReportService(httpService);
const appDashboardService = new AppDashboardService(httpService);
// const userFeatureService = new featuresService(httpService);
const slackNotificationManagementService = new SlackNotificationManagementService(
  httpService
);
const customNotificationService = new CustomNotificationService(httpService);
const urlMonitoringService = new UrlMonitoringService(httpService);
const rumService = new RUMService(httpService);
const uptimeService = new UptimeService(httpService);
const billingService = new BillingService(httpService);
const uploadedDocumentsService = new UploadedDocumentsService(httpService);
const uploadedDocumentsStaticService = new UploadedDocumentsStaticService(
  httpService
);

//Admin panel
const adminDashboardService = new AdminDashboardService(httpService);
const organisationDetailsService = new OrganizationDetailsService(httpService);
const allOrganizationsService = new AllOrganizationsService(httpService);
const documentUploadService = new DocumentUploadService(httpService);
const adminPanelBilligService = new AdminPanelBillingService(httpService);
const systemHealthService = new SystemHealthService(httpService);

export {
  httpService,
  userService,
  authService,
  apiService,
  // toasterService,
  rightSizingService,
  idleInstanceService,
  costSavingService,
  accountService,
  costForecastingService,
  inventoryService,
  subscriptionsService,
  invoiceService,
  securityScanService,
  tagDetailService,
  inventoryReportService,
  appDashboardService,
  // userFeatureService,
  slackNotificationManagementService,
  customNotificationService,
  urlMonitoringService,
  rumService,
  uptimeService,
  billingService,
  adminDashboardService,
  organisationDetailsService,
  allOrganizationsService,
  documentUploadService,
  uploadedDocumentsService,
  uploadedDocumentsStaticService,
  adminPanelBilligService,
  systemHealthService,
};
