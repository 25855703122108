import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './ServiceWiseSpendingDetails.style';
// import { rowData, serviceData } from './data';
import { costForecastingService } from '../../../services';
import { useHistory } from 'react-router';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { useUserData } from '../../../context/GlobalUserData';
import { handleError } from '../../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#FBFBFB',
      border: 'none',
      fontSize: '1.3rem',
    },
    body: {
      fontSize: '1.3rem',
      border: 'none',
    },
  })
)(TableCell);

const storageKeys = {
  // CF_PIE_SPENDING_DETAIL_SELECTED_SUBSCRIPTION: 'CF_PIE_SPENDING_DETAIL_SELECTED_SUBSCRIPTION',

  CF_SELECTED_SUBSCRIPTION: 'CF_SELECTED_SUBSCRIPTION',
};

const getSelectedSubscription = (): string => {
  return localStorage.getItem(storageKeys.CF_SELECTED_SUBSCRIPTION) || 'all';
};

// export const setSelectedSubscription = (value: string): void => {
//   localStorage.setItem(storageKeys.CF_PIE_SPENDING_DETAIL_SELECTED_SUBSCRIPTION, value);
// };

export const ServiceWiseSpendingDetails = (props: any): any => {
  const classes = useStyles();
  const propsData = props.match.params.service.split('&');
  // const chartType = propsData[0];
  const serviceType = propsData[1].trim();
  // const [open, setOpen] = React.useState(false);
  const [mtdData, setmtdData] = React.useState<any>([]);
  const [serviceData, setServiceData] = React.useState<any>([]);
  const [currency, setCurrency] = React.useState<any>('');
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [isNetworkError, setNetworkError] = React.useState(false);
  // const [filterData, setFilterData] = useState<any>([]);
  const [selected] = useState<any>(getSelectedSubscription);
  const { selectedAccount } = useUserData();
  const queryParams = window.location.search.split('?').pop() || '';
  const params = queryParams.split('&'); // Split the parameters by '&'
  const lastParam = params.pop() || ''; // Get the last parameter
  const [selectedAccountKey, selectedAccountValue] = lastParam.split('=');
  const selectedAccountInfo = useSelector(accountState);
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;

  const getPieChartData = async (chartType: string, service: string) => {
    try {
      setLoader(true);
      const cloudProvider = selectedAccount?.CloudProvider.toUpperCase();
      const result = await costForecastingService.getPieData(
        chartType,
        service,
        selectedAccountKey,
        selectedAccountValue,
        cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
          ? CLOUD_PROVIDER_SERVICES.AWS
          : CLOUD_PROVIDER_SERVICES.AZURE,
        selectedCurrency
      );

      if (result) {
        setCurrency(result[0].currencySymbol);
        const mtd = [
          {
            currency: result[0].currency,
            currencySymbol: result[0].currencySymbol,
            mtdNetCost: result[0].summary[0].MTD_Net_Cost,
          },
        ];
        setmtdData(mtd);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getPieMonthToDailyData = async (chartType: string, service: string) => {
    try {
      const cloudProvider = selectedAccount?.CloudProvider.toUpperCase();

      const result = await costForecastingService.getPieMonthToDailyCostData(
        chartType,
        service,
        selectedAccountKey,
        selectedAccountValue,
        cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
          ? CLOUD_PROVIDER_SERVICES.AWS
          : CLOUD_PROVIDER_SERVICES.AZURE,
        selectedCurrency
      );

      if (result) {
        result.summary.forEach((res: any, index: any) => {
          res.Id = index + 1;
        });
        setServiceData(result.summary);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
    }
  };

  const customTickFormatter = (tickProps: any) => {
    const data = serviceData.filter(
      (service: any) => service.ResourceName === tickProps
    );
    return data && data[0] && data[0].Id ? data[0].Id : tickProps;
  };

  useEffect(() => {
    if (selectedAccount) {
      getPieChartData('pieChart', serviceType);
      getPieMonthToDailyData('pieChart', serviceType);
    }
    // getFilterData();
  }, [selected, selectedAccount, selectedCurrency]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">{label}</div>
          <div className="ttLabel">
            <span className="ttKey">Cost:</span>
            <span className="ttValue">
              {currency +
                payload[0].value
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <Grid container>
      {loader ? <LoadingScreen /> : ''}

      <div className="sContainer w-100 mb-10">
        <div className="inventoryHeading font-26px fw-300">
          <div className="">
            {mtdData.length ? (
              `Summary for ${serviceType}`
            ) : (
              <Skeleton variant="text" width={100} height={22} />
            )}
          </div>
        </div>
        {mtdData.length ? (
          <div className="mtdSummary">
            <div className="monthToDateHeader px-30">MTD Net Cost</div>
            <h5 className="monthToDateEarnings px-30">
              {mtdData.map((row: any, index: any) => (
                <span key={'mtddata' + index}>
                  {row.currencySymbol +
                    row.mtdNetCost
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>
              ))}
            </h5>
          </div>
        ) : (
          <Skeleton variant="rect" width="100%" height={123} />
        )}
      </div>

      <Grid
        item
        container
        className={classes.monthlyBillingContainer + ' monthlyBillingContainer'}
      >
        {mtdData.length ? (
          <Grid container direction="row">
            <Grid item container className="d-block">
              <Paper elevation={2} className={classes.paper}>
                <Grid container direction="column">
                  <Grid item className={classes.monthlySpendingHeader}>
                    {`Monthly ${serviceType}  Bill`}
                  </Grid>
                  <Grid item className={classes.barContainer}>
                    <ResponsiveContainer>
                      <BarChart
                        width={400}
                        height={250}
                        data={serviceData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid
                          strokeDasharray="1"
                          vertical={false}
                          horizontal={false}
                        />
                        <XAxis
                          dataKey="ResourceName"
                          tickFormatter={customTickFormatter}
                        />
                        <XAxis
                          dataKey="ResourceName"
                          axisLine={true}
                          tickLine={false}
                        />
                        <YAxis
                          tickFormatter={(e) =>
                            `${mtdData[0].currencySymbol + e}`
                          }
                          label={{
                            // value: `Cost in ${mtdData[0].currency}`,
                            angle: -90,
                            position: 'center',
                          }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Tooltip /> */}
                        <Legend
                          iconType="circle"
                          payload={[
                            { value: `${serviceType}`, color: '#2083DC' },
                          ]}
                        />
                        <Bar
                          dataKey="Cost"
                          width="40"
                          barSize={30}
                          stackId="a"
                          data={serviceData}
                          fill={'#2083DC'}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item container className={classes.costSpendingDetailsTabular}>
              <Paper className="borderTop">
                <TableContainer>
                  <Table
                    className={` adminTable adminPanelTable ${classes.table}`}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead className="admintableHead">
                      <TableRow>
                        <StyledTableCell
                          className="adminTableheading"
                          align="left"
                        >{`${serviceType}`}</StyledTableCell>
                        {/* {selectedAccount?.CloudProvider.toUpperCase() !==
                        'AWS' ? (
                          <StyledTableCell align="center">
                            Subscription Name
                          </StyledTableCell>
                        ) : null} */}
                        <StyledTableCell
                          className="adminTableheading"
                          align="right"
                        >
                          Month to Daily Cost
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serviceData.map((vm: any, index: any) => (
                        <TableRow
                          key={'service' + index}
                          className={
                            index % 2 === 0 ? classes.highlightedRow : ''
                          }
                        >
                          <StyledTableCell align="left">
                            {vm.ResourceName ? vm.ResourceName : '-'}
                          </StyledTableCell>
                          {/* {selectedAccount?.CloudProvider.toUpperCase() !==
                          'AWS' ? (
                            <StyledTableCell align="center">
                              {vm.SubscriptionName ? vm.SubscriptionName : '-'}
                            </StyledTableCell>
                          ) : null} */}
                          <StyledTableCell align="right">
                            {currency}
                            {vm.Cost || vm.Cost === 0
                              ? vm.Cost.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )
                              : '-'}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Skeleton variant="rect" width={1360} height={604} />
        )}
      </Grid>
    </Grid>
  );
};
