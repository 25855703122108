import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Tooltip as ToolTip,
  Card,
  CardContent,
  Typography,
  TextField,
} from '@material-ui/core';
import {
  invoiceService,
  costForecastingService,
  accountService,
} from '../../services';
import './Invoice.css';
import { useHistory } from 'react-router';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { InvoiceModal } from '../../components';
import { ScheduleInvoiceModal } from '../../components/Modals/ScheduleInvoiceModal/ScheduleInvoiceModal';

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../store/index';
import moment from 'moment';
import {
  ALL_VALUE,
  config,
  formattedTagBillingData,
  monthChosen,
  months,
  order,
  storageKeys,
} from './config';
import {
  CLOUD_PROVIDER_SERVICES,
  PERMISSION_KEY,
  PERMISSION_LEVELS,
} from '../../utils/constants';
import { costFormatter2, handleError } from '../../utils/config';
import { TableContainer } from '@mui/material';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { Autocomplete } from '@material-ui/lab';
const company = localStorage.getItem('CompanyName');

const getInvoiceHistoryRowsPerPage = () => {
  const rowsPerPage =
    localStorage.getItem(storageKeys.INVOICE_HISTORY_ROWS_PER_PAGE) || 5;
  return +rowsPerPage;
};

const setInvoiceHistoryRowsPerPage = (value: string) => {
  localStorage.setItem(storageKeys.INVOICE_HISTORY_ROWS_PER_PAGE, value);
};

const getInvoiceHistoryPageNo = () => {
  const pageNo = localStorage.getItem(storageKeys.INVOICE_HISTORY_PAGE_NO) || 0;
  return +pageNo;
};

const setInvoiceHistoryPageNo = (value: string) => {
  localStorage.setItem(storageKeys.INVOICE_HISTORY_PAGE_NO, value);
};

const getScheduledInvoiceRowsPerPage = () => {
  const rowsPerPage =
    localStorage.getItem(storageKeys.SCHEDULED_INVOICE_ROWS_PER_PAGE) || 5;
  return +rowsPerPage;
};

const setScheduledInvoiceRowsPerPage = (value: string) => {
  localStorage.setItem(storageKeys.SCHEDULED_INVOICE_ROWS_PER_PAGE, value);
};

const getScheduledInvoicePageNo = () => {
  const pageNo =
    localStorage.getItem(storageKeys.SCHEDULED_INVOICE_PAGE_NO) || 0;
  return +pageNo;
};

const setScheduledInvoicePageNo = (value: string) => {
  localStorage.setItem(storageKeys.SCHEDULED_INVOICE_PAGE_NO, value);
};

export const getSelectedSubscription = (): string => {
  return (
    localStorage.getItem(storageKeys.INVOICE_SELECTED_SUBSCRIPTION) || ALL_VALUE
  );
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.INVOICE_SELECTED_SUBSCRIPTION, value);
};

export const Invoice = () => {
  const history = useHistory();

  const user = useSelector(userState).userData;
  const selectedAccountInfo = useSelector(accountState);
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const isAccountActive = selectedAccountInfo.selectedAccount?.Credentials;
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;

  const [loader, setLoader] = React.useState(false);
  const [highCostTableLoading, setHighCostTableLoading] = React.useState(false);
  const [resourceCostLoading, setResourceCostLoading] = useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const [value, setValue] = React.useState(1);

  const [selected] = React.useState<any>(
    selectedAccountInfo.selectedSubscriptionName
  );
  const [invoiceData, setInvoiceData] = React.useState<any>([]);
  const [openScheduleInvoiceModal, setScheduleInvoiceModal] = React.useState(
    false
  );
  const [invoiceModal, setInvoiceModal] = React.useState(false);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [scheduledInvoicesData, setScheduledInvoicesData] = React.useState<any>(
    []
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getInvoiceHistoryRowsPerPage
  );
  const [page, setPage] = React.useState(getInvoiceHistoryPageNo);
  const [scheduledRowsPerPage, setScheduledRowsPerPage] = React.useState(
    getScheduledInvoiceRowsPerPage
  );
  const [scheduledpage, setScheduledPage] = React.useState(
    getScheduledInvoicePageNo
  );
  const [mode, setMode] = React.useState('');
  const [rowDetails, setRowDetails] = React.useState({});

  const [monthToDateEarnings, setmonthToDateEarnings] = React.useState('');
  const [
    previousMonthToDateEarnings,
    setPreviousMonthToDateEarnings,
  ] = React.useState('');
  const [forcastData, setForcastData] = React.useState<any>('');
  const [tags, setTags] = React.useState<any>([]);
  const [highCostTags, setHighCostTags] = React.useState<any>([]);
  const [formatMonthSelected, setFormatMonthSelected] = React.useState<any>(
    monthChosen.current
  );
  const [monthSelected, setMonthSelected] = React.useState<any>('');
  const [selectHighCostTagValue, setSelectHighCostTagValue] = React.useState(
    ALL_VALUE
  );

  const [filteredTags, setFilteredTags] = useState<any>([]);

  const [currency, setCurrency] = React.useState('');
  const date = new Date();
  const [currentMonthName] = React.useState(
    date.toLocaleString('default', { month: 'long' })
  );
  const [highestResourceCost, setHighestResourceCost] = React.useState<any>();
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const cloudProvider = selectedAccountInfo?.selectedAccount?.CloudProvider.toUpperCase();

  const GetMonthName = (monthNumber: any) => {
    return months[monthNumber];
  };

  const [lastMonth] = useState<string>(
    date.getMonth() === 0 ? GetMonthName(11) : GetMonthName(date.getMonth() - 1)
  );

  const actionPermission = user?.Role?.permissions;
  const invoicePermission = actionPermission?.find(
    (obj: any) => obj.Entity.Key === PERMISSION_KEY.Invoice
  );

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    setValue(value);
  };
  const totalForcastData = +forcastData + +monthToDateEarnings;

  const getDataForBarChartAndPieChart = async () => {
    try {
      setLoader(true);
      const fallBackData = {
        barChartData: [
          { name: 'Last Month', Spend: '0.00', month: '' },
          {
            name: 'Month to Date',
            Spend: '0.00',
            month: currentMonthName,
            tagName: 'Tags',
            values: [],
          },
          {
            name: 'Forecast',
            Spend: '0.00',
            Forecast: '0.00',
            month: currentMonthName,
          },
        ],
        currency: '',
        pieChartData: [],
      };

      let params;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        params = {
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      } else {
        params = {
          subscriptionId: selectedSubscriptionId,
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      }

      const result = selectedAccountInfo.isAccountSelected
        ? await costForecastingService.getBarAndPieChartData(params)
        : fallBackData;

      if (result) {
        setUpdatedCurrency(selectedCurrency);
        const { barChartData } = result;

        setCurrency(result.currency);
        setPreviousMonthToDateEarnings(barChartData[0]?.Spend);
        setmonthToDateEarnings(barChartData[1]?.Spend);
        setForcastData(barChartData[2]?.Forecast);
      }
    } catch (e: any) {
      if (e) {
        setNetworkError(e === 'Network Error' ? e : '');
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getTags = async () => {
    try {
      let result;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        result = await invoiceService.getTags(
          selectedAccountId,
          CLOUD_PROVIDER_SERVICES.AWS,
          true
        );
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        result = await invoiceService.getTags(
          selectedAccountId === ALL_VALUE
            ? selectedSubscriptionId
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? selectedAccountId
            : selectedSubscriptionId,
          CLOUD_PROVIDER_SERVICES.AZURE,
          selectedAccountId !== ALL_VALUE &&
            selectedSubscriptionId === ALL_VALUE
            ? true
            : false
        );
      }

      result && setTags(result);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const handleAllAccounts = async () => {
    try {
      const result = await accountService.getAllAccounts();
      setAccountList(result);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  useEffect(() => {
    setSelectHighCostTagValue(ALL_VALUE);
    getScheduledInvoices();
    getInvoiceHistory();
    handleAllAccounts();
    getTags();
  }, [
    selected,
    selectedAccountInfo.isAccountSelected,
    monthSelected,
    selectedAccountId,
    selectedSubscriptionId,
    selectedCurrency,
  ]);

  useEffect(() => {
    getDataForBarChartAndPieChart();
  }, [
    selected,
    selectedAccountInfo.isAccountSelected,
    selectedAccountId,
    selectedSubscriptionId,
    selectedCurrency,
  ]);

  useEffect(() => {
    handleAllTagData();
    resourceWisCostFunc();
  }, [
    selectedAccountId,
    selectedSubscriptionId,
    monthSelected,
    formatMonthSelected,
    selectedAccountInfo,
    selectedCurrency,
  ]);

  if (networkError) {
    throw new Error(networkError);
  }

  const handleMonthChange = async (
    event: React.ChangeEvent<Record<string, unknown>>
  ) => {
    try {
      const month: any = event.target.value || monthChosen.current;

      if (month === currentMonthName) {
        setMonthSelected(currentMonthName);

        setFormatMonthSelected(monthChosen.current);
      } else if (month === lastMonth) {
        setMonthSelected(lastMonth);

        setFormatMonthSelected(monthChosen.previous);
      }
    } catch (e: any) {
      handleError(e, history);
    }
  };

  const handleAllTagData = async () => {
    try {
      let data;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        data = {
          provider: cloudProvider,
          ...(selectedAccountId !== ALL_VALUE
            ? { accountId: selectedAccountId }
            : {}),
          month: formatMonthSelected,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
          // tagKey: key,
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        data = {
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
          month: formatMonthSelected,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
          // tagKey: key,
        };
      }

      setHighCostTableLoading(true);
      const highCostResult = await config.tagBillingCost(data);
      const output = formattedTagBillingData(
        highCostResult,
        formatMonthSelected
      );
      setHighCostTableLoading(false);
      setHighCostTags(output.highResourcesData);
    } catch (e) {
      console.error(e);
      handleError(e, history);
    }
  };

  const handleTagClick = async (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: string | null
  ) => {
    try {
      setLoader(true);
      const key: any = value || ALL_VALUE;
      const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase();

      let data;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        data = {
          provider: cloudProvider,
          ...(selectedAccountId !== ALL_VALUE
            ? { accountId: selectedAccountId }
            : {}),
          month: formatMonthSelected,
          ...(key === 'all' ? {} : { tagKey: key }),
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        data = {
          provider: cloudProvider,
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
          ...(key === 'all' ? {} : { tagKey: key }),
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      }

      setHighCostTableLoading(true);
      const highCostResult = await config.tagBillingCost(data);
      const output = formattedTagBillingData(
        highCostResult,
        formatMonthSelected
      );
      setHighCostTableLoading(false);
      setHighCostTags(output.highResourcesData);
      setSelectHighCostTagValue(key);
    } catch (e) {
      console.error(e);
      handleError(e, history);
    } finally {
      setLoader(false);
    }
  };

  const exportToFile = (fileBuffer: string, fileName: string) => {
    const byteCharacters = atob(fileBuffer);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    // other browers,  IE is easier in this case
    const url = URL.createObjectURL(blob);
    const dummyLink = document.createElement('a');
    dummyLink.href = url;
    dummyLink.download = `${fileName}`;
    document.body.appendChild(dummyLink);
    dummyLink.click();
  };

  const downloadInvoice = async (row: any) => {
    const data = {
      Id: row.Id,
    };
    try {
      setLoader(true);
      const result = await invoiceService.DownloadInvoice(data);
      exportToFile(result.fileBuffer, result.fileName);
      setLoader(false);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      setLoader(false);
    }
  };

  const getInvoiceHistory = async () => {
    try {
      // setLoader(true);
      let result;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        result = await invoiceService.InvoiceHistory(
          selectedAccountId,
          CLOUD_PROVIDER_SERVICES.AWS,
          true
        );
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        result = await invoiceService.InvoiceHistory(
          selectedAccountId === ALL_VALUE
            ? selectedSubscriptionId
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? selectedAccountId
            : selectedSubscriptionId,
          CLOUD_PROVIDER_SERVICES.AZURE,
          selectedAccountId !== ALL_VALUE &&
            selectedSubscriptionId === ALL_VALUE
            ? true
            : false
        );
      }
      result && setInvoiceData(result);

      // setLoader(false);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // setLoader(false);
    }
  };
  const getScheduledInvoices = async () => {
    try {
      let result;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        result = await invoiceService.ScheduledInvoices(
          selectedAccountId,
          CLOUD_PROVIDER_SERVICES.AWS,
          true
        );
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        result = await invoiceService.ScheduledInvoices(
          selectedAccountId === ALL_VALUE
            ? selectedSubscriptionId
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? selectedAccountId
            : selectedSubscriptionId,
          CLOUD_PROVIDER_SERVICES.AZURE,
          selectedAccountId !== ALL_VALUE &&
            selectedSubscriptionId === ALL_VALUE
            ? true
            : false
        );
      }
      // setLoader(true);

      result && setScheduledInvoicesData(result);
      // setLoader(false);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // setLoader(false);
    }
  };

  const closeCheckModal = (update?: any) => {
    setScheduleInvoiceModal(false);
    if (update) {
      getInvoiceHistory();
      getScheduledInvoices();
    }
  };
  const handleClick = () => {
    setInvoiceModal(true);
  };
  const handleClose = (update?: any) => {
    setInvoiceModal(false);
    if (update) {
      getInvoiceHistory();
      getScheduledInvoices();
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setInvoiceHistoryPageNo(String(newPage));
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (invoiceData.length > 0) {
      setInvoiceHistoryRowsPerPage(event.target.value);
      setRowsPerPage(parseInt(event.target.value));
    } else {
      setRowsPerPage(5);
    }
    setInvoiceHistoryPageNo(String(0));
    setPage(0);
  };
  const handleScheduledChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setScheduledInvoicePageNo(String(newPage));
    setScheduledPage(newPage);
  };
  const handleScheduledRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (scheduledInvoicesData.length > 0) {
      setScheduledInvoiceRowsPerPage(event.target.value);
      setScheduledRowsPerPage(parseInt(event.target.value));
    } else {
      setRowsPerPage(5);
    }
    setScheduledInvoicePageNo(String(0));
    setScheduledPage(0);
  };

  const handleScheduleModalClick = (method: string, invoice?: any) => {
    try {
      if (user?.RoleId !== 3 || 4) {
        setScheduleInvoiceModal(true);
        setMode(method);
        setRowDetails(invoice);
      } else {
        alert("You don't have permission to perform this action");
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const resourceWisCostFunc = async () => {
    try {
      setResourceCostLoading(true);
      const year = moment().get('year');
      const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase();
      let prevMonth;
      let currMonth;
      const monthNumber = moment().subtract(1, 'months').get('month') + 1;
      if (formatMonthSelected === monthChosen.current) {
        prevMonth = monthNumber;
        currMonth = moment().get('month') + 1;
      }
      // handling year change conditions
      else if (
        monthNumber === 12 &&
        formatMonthSelected === monthChosen.previous
      ) {
        prevMonth = monthNumber - 1;
        currMonth = monthNumber;
      } else {
        prevMonth = moment().subtract(1, 'months').get('month');
        currMonth = moment().get('month');
      }

      let resourceParams;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        resourceParams = {
          provider: cloudProvider,
          ...(selectedAccountId !== ALL_VALUE
            ? { accountId: selectedAccountId }
            : {}),
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        resourceParams = {
          provider: cloudProvider,
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      }

      let currMonthConst;
      let prevMonthConst;

      // handling year change conditions
      if (prevMonth === 12 && formatMonthSelected === monthChosen.current) {
        const prevYearParams = {
          ...resourceParams,
          year: year - 1,
        };
        const currYearParams = {
          ...resourceParams,
          year,
        };

        prevMonthConst = invoiceService.getResourcesCost({
          ...prevYearParams,
          month: prevMonth,
        });
        currMonthConst = invoiceService.getResourcesCost({
          ...currYearParams,
          month: currMonth,
        });
      }

      // handling year change conditions
      else if (
        lastMonth === 'December' &&
        formatMonthSelected === monthChosen.previous
      ) {
        const params = {
          ...resourceParams,
          year: year - 1,
        };

        prevMonthConst = invoiceService.getResourcesCost({
          ...params,
          month: prevMonth,
        });
        currMonthConst = invoiceService.getResourcesCost({
          ...params,
          month: currMonth,
        });
      } else {
        const currYearParams = {
          ...resourceParams,
          year,
        };

        if (cloudProvider) {
          prevMonthConst = invoiceService.getResourcesCost({
            ...currYearParams,
            month: prevMonth,
          });
          currMonthConst = invoiceService.getResourcesCost({
            ...currYearParams,
            month: currMonth,
          });
        }
      }

      const promises = [];

      promises.push(prevMonthConst);
      promises.push(currMonthConst);
      const bothCost: any = await Promise.all(promises);

      const currentMonthData = bothCost[1];
      let previousMonthData = bothCost[0];
      previousMonthData =
        previousMonthData &&
        previousMonthData.reduce((acc: any, set: any) => {
          acc[set.meter_category || set.ProductCode] = set;
          return acc;
        }, {});
      const diff = currentMonthData
        ?.map((resource: any) => {
          const prevMonthResourceCost = Number(
            (previousMonthData[
              resource.meter_category || resource.ProductCode
            ] &&
              previousMonthData[resource.meter_category || resource.ProductCode]
                .TotalCost) ||
              0
          );
          let percentVal;
          const currentMonthResourceCost = Number(resource.TotalCost);
          if (formatMonthSelected !== monthChosen.current) {
            percentVal =
              (Number(
                +currentMonthResourceCost.toFixed(2) -
                  +prevMonthResourceCost.toFixed(2)
              ) /
                Number(prevMonthResourceCost.toFixed(2))) *
              100;
          } else {
            const approxCost =
              (currentMonthResourceCost / (new Date().getDate() - 1)) * 30;
            percentVal =
              (Number(
                +approxCost.toFixed(2) - +prevMonthResourceCost.toFixed(2)
              ) /
                Number(prevMonthResourceCost.toFixed(2))) *
              100;
          }
          return {
            resource: resource.meter_category || resource.ProductCode,
            diff: currentMonthResourceCost - prevMonthResourceCost,
            previousMonthData: prevMonthResourceCost,
            currentMonthData: currentMonthResourceCost,
            perChange: currentMonthResourceCost > 0 ? percentVal.toFixed(2) : 0,
            approxCost:
              (currentMonthResourceCost / (new Date().getDate() - 1)) * 30,
            changes:
              formatMonthSelected === monthChosen.current
                ? (currentMonthResourceCost / (new Date().getDate() - 1)) * 30 -
                  prevMonthResourceCost
                : currentMonthResourceCost - prevMonthResourceCost,
          };
        })
        .filter(
          (item: any) =>
            item.currentMonthData >= 0.01 || item.previousMonthData >= 0.01
        )
        .sort((a: any, b: any) => b.currentMonthData - a.currentMonthData);

      const highestDiff = diff;

      setHighestResourceCost(highestDiff);
    } catch (e: any) {
      if (e) {
        setNetworkError(e === 'Network Error' ? e : '');
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setResourceCostLoading(false);
    }
  };

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  useEffect(() => {
    // Filter tags based on inputValue
    const filtered = tags.filter((tag: string) => tag.trim().toLowerCase());
    setFilteredTags(filtered);
  }, [tags]);

  return (
    <Fragment>
      {loader ? <LoadingScreen /> : ''}

      <div>
        <SelectedAccount onAccountChange={onAccountChange} />
        <div className="invoiceTagTableHeader">
          <Grid>
            <Card className="invoiceCard">
              <CardContent className="text-white">
                <Typography variant="h5" component="div">
                  Billing Period
                </Typography>
                <Typography variant="h4" className="mt-10 text-white">
                  <div className="invoiceMonthSelect mb-0">
                    <FormControl variant="outlined" className="w-50 mt-0 p-0">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={monthSelected ? monthSelected : currentMonthName}
                        onChange={handleMonthChange}
                        label="Subscription"
                        defaultValue={formatMonthSelected}
                        displayEmpty
                        className="p-0"
                      >
                        <MenuItem value={currentMonthName}>
                          {currentMonthName}
                        </MenuItem>
                        <MenuItem value={lastMonth}>{lastMonth}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card className="invoiceCard">
              <CardContent className="text-white ">
                <Typography variant="h6" component="div">
                  Spend (MTD)
                </Typography>
                <Typography variant="h4" className="mt-10 text-white">
                  {monthToDateEarnings
                    ? formatMonthSelected === monthChosen.current
                      ? costFormatter2(updatedCurrency, monthToDateEarnings)
                      : costFormatter2(
                          updatedCurrency,
                          previousMonthToDateEarnings
                        )
                    : '0.00'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {formatMonthSelected === monthChosen.current ? (
            <Grid>
              <Card className="invoiceCard">
                <CardContent className="text-white">
                  <Typography variant="h5" component="div">
                    Forecast
                  </Typography>
                  <Typography variant="h4" className="mt-10 text-white">
                    {totalForcastData
                      ? costFormatter2(updatedCurrency, totalForcastData)
                      : '0.00'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </div>

        <>
          {selectedAccountInfo.selectedAccount ? (
            <>
              <Grid className="row mt-30">
                <div className="mr-10 w-50">
                  <div className="rsTableHeader inventoryHeader mb-20">
                    <div className="font-17px fw-600">
                      Biggest Increase Tags
                    </div>
                    <div>
                      <div className="subscriptionDD ml-10 autoCompleteBox">
                        <label className="subscriptionLabel">Tags</label>
                        <FormControl
                          variant="outlined"
                          className="w-100 mt-0 p-0"
                        >
                          <InputLabel
                            shrink
                            id="demo-simple-select-outlined-label"
                          >
                            Tags
                          </InputLabel>

                          <Autocomplete
                            id="demo-simple-select-outlined"
                            // className={
                            //   selectHighCostTagValue === ALL_VALUE
                            //     ? 'autoCompleteInput'
                            //     : ''
                            // }
                            value={
                              selectHighCostTagValue === ALL_VALUE
                                ? 'All'
                                : selectHighCostTagValue
                            }
                            onChange={handleTagClick}
                            options={filteredTags}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tags"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: 'off',
                                }}
                              />
                            )}
                          />
                          {/* <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectHighCostTagValue}
                            onChange={(e) => {
                              handleTagClick(e);
                            }}
                            label="Subscription"
                            displayEmpty
                            defaultValue="all"
                            className="p-0"
                          >
                            <MenuItem value="all">
                              {tags.length === 0 ? 'No Tags Available' : 'All'}
                            </MenuItem>
                            {tags.map((Key: any, index: any) => (
                              <MenuItem value={Key} key={'subs' + index}>
                                {Key}
                              </MenuItem>
                            ))}
                          </Select> */}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <TableContainer
                    className="accContainer"
                    sx={{ maxHeight: '222px', overflowY: 'scroll' }}
                  >
                    <Table
                      stickyHeader
                      className="table adminTable adminPanelTable"
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="admintableHead">
                        <TableRow>
                          <TableCell
                            align="left"
                            className="tableColumnWidth adminTableheading"
                          >
                            Key
                          </TableCell>
                          <TableCell
                            align="left"
                            className="tableColumnWidth adminTableheading"
                          >
                            Value
                          </TableCell>
                          <TableCell
                            align="left"
                            className="tableColumnWidth adminTableheading"
                          >
                            Previous Month Cost
                          </TableCell>
                          <TableCell
                            align="left"
                            className="tableColumnWidth adminTableheading"
                          >
                            Current Month Cost
                          </TableCell>
                          <TableCell
                            align="left"
                            className="tableColumnWidth adminTableheading"
                          >
                            (%)Change
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {highCostTableLoading ? (
                          <TableRow>
                            <TableCell colSpan={20} align="center">
                              Loading...
                            </TableCell>
                          </TableRow>
                        ) : highCostTags && highCostTags.length !== 0 ? (
                          highCostTags.map((tag: any, index: number) => (
                            <TableRow key={index}>
                              <Tooltip
                                title={tag.tagKey}
                                className="cursor-pointer"
                                placement="bottom-start"
                                arrow
                              >
                                <TableCell
                                  align="left"
                                  className="tableColumnWidth"
                                >
                                  {tag.tagKey}
                                </TableCell>
                              </Tooltip>
                              <Tooltip
                                title={tag.tagValue}
                                className="cursor-pointer"
                                placement="bottom-start"
                                arrow
                              >
                                <TableCell
                                  align="left"
                                  className="tableColumnWidth tagDetailCellLink"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/${company}/billing/tagDetail/${tag.tagValue.replace(
                                        /[^a-zA-Z0-9]/g,
                                        ''
                                      )}`,
                                      state: {
                                        selectedAccountId,
                                        selectedSubscriptionId,
                                        currency,
                                        tagKey: tag.tagKey,
                                        tagValue: tag.tagValue,
                                        billingPeriod: formatMonthSelected,
                                      },
                                    });
                                  }}
                                >
                                  {tag.tagValue}
                                </TableCell>
                              </Tooltip>
                              <Tooltip
                                title={tag?.previousMonthCost}
                                className="cursor-pointer"
                                placement="bottom-start"
                                arrow
                              >
                                <TableCell scope="left">
                                  {costFormatter2(
                                    selectedCurrency,
                                    tag?.previousMonthCost
                                  )}
                                </TableCell>
                              </Tooltip>
                              <TableCell
                                scope="left"
                                className="nowrap tableContent"
                              >
                                <Tooltip
                                  title={tag?.currentMonthCost}
                                  className="cursor-pointer"
                                  placement="bottom-start"
                                  arrow
                                >
                                  <span>
                                    {costFormatter2(
                                      selectedCurrency,
                                      tag?.currentMonthCost
                                    )}
                                  </span>
                                </Tooltip>

                                {formatMonthSelected === monthChosen.current ? (
                                  <span>
                                    <span className="font-12px"> Appx</span>
                                    <Tooltip
                                      title={tag?.approxCost}
                                      className="cursor-pointer"
                                      placement="bottom-start"
                                      arrow
                                    >
                                      <span>
                                        (
                                        {costFormatter2(
                                          selectedCurrency,
                                          tag?.approxCost
                                        )}
                                        )
                                      </span>
                                    </Tooltip>
                                  </span>
                                ) : null}
                              </TableCell>
                              {tag.order === order.high ? (
                                <TableCell
                                  scope="left"
                                  className="tableColumnWidth tableContent"
                                >
                                  <Tooltip
                                    title={
                                      tag?.approxCost - tag?.previousMonthCost
                                    }
                                    className="cursor-pointer"
                                    placement="bottom-start"
                                    arrow
                                  >
                                    <span>
                                      {costFormatter2(
                                        selectedCurrency,
                                        Math.abs(
                                          tag?.approxCost -
                                            tag?.previousMonthCost
                                        )
                                      )}
                                    </span>
                                  </Tooltip>
                                  {'  '}
                                  <span>
                                    ({Math.abs(tag.percentChange).toFixed(2)}
                                    %)
                                  </span>
                                  {/* <span className="highPriorityText">
                                    <img className="arrowLogo" src={topArrow} />
                                    High
                                  </span> */}
                                </TableCell>
                              ) : (
                                <TableCell
                                  scope="left"
                                  className="tableColumnWidth tableContent"
                                >
                                  {' '}
                                  <Tooltip
                                    title={
                                      tag?.approxCost - tag?.previousMonthCost
                                    }
                                    className="cursor-pointer"
                                    placement="bottom-start"
                                    arrow
                                  >
                                    <span>
                                      {costFormatter2(
                                        selectedCurrency,
                                        tag?.approxCost - tag?.previousMonthCost
                                      )}
                                    </span>
                                  </Tooltip>
                                  {'  '}
                                  <span>
                                    {'  '} ({tag.percentChange.toFixed(2)}%){' '}
                                  </span>
                                  {/* <span className="lowPriorityText">
                                    <img
                                      className="arrowLogo"
                                      src={bottomArrow}
                                    />
                                    Low
                                  </span> */}
                                </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={20} align="center">
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="mr-10 w-50 ">
                  <div className="rsTableHeader inventoryHeader mb-20 pb-17">
                    <div className="font-17px fw-600">
                      Resource wise cost change{' '}
                    </div>
                  </div>
                  <TableContainer
                    sx={{ maxHeight: '222px', overflowY: 'scroll' }}
                    className="accContainer mt-15"
                  >
                    <Table
                      stickyHeader
                      className="table adminTable adminPanelTable"
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="admintableHead">
                        <TableRow>
                          <TableCell
                            align="left"
                            className="tableColumnWidth adminTableheading"
                          >
                            Resource Name
                          </TableCell>
                          <TableCell
                            className="tableColumnWidth adminTableheading"
                            align="left"
                          >
                            Previous Month Cost
                          </TableCell>
                          <TableCell
                            className="tableColumnWidth adminTableheading"
                            align="left"
                          >
                            Current Month Cost
                          </TableCell>
                          <TableCell
                            className="tableColumnWidth adminTableheading"
                            align="left"
                          >
                            (%) Change
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {resourceCostLoading ? (
                          <TableRow>
                            <TableCell colSpan={20} align="center">
                              Loading...
                            </TableCell>
                          </TableRow>
                        ) : highestResourceCost &&
                          highestResourceCost.length ? (
                          highestResourceCost?.map(
                            (resource: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell
                                  align="left"
                                  className="tableColumnWidth "
                                >
                                  {resource.resource}
                                </TableCell>
                                <Tooltip
                                  title={resource.previousMonthData}
                                  className="cursor-pointer"
                                  placement="bottom-start"
                                  arrow
                                >
                                  <TableCell scope="left">
                                    {costFormatter2(
                                      selectedCurrency,
                                      resource.previousMonthData
                                    )}
                                  </TableCell>
                                </Tooltip>
                                <TableCell
                                  className="tableColumnWidth tableContent"
                                  scope="left"
                                >
                                  <span>
                                    <Tooltip
                                      title={resource.currentMonthData}
                                      className="cursor-pointer"
                                      placement="bottom-start"
                                      arrow
                                    >
                                      <span>
                                        {costFormatter2(
                                          selectedCurrency,
                                          resource.currentMonthData
                                        )}
                                      </span>
                                    </Tooltip>
                                    {formatMonthSelected ===
                                    monthChosen.current ? (
                                      <span>
                                        <span className="font-12px"> Appx</span>
                                        <Tooltip
                                          title={resource?.approxCost}
                                          className="cursor-pointer"
                                          placement="bottom-start"
                                          arrow
                                        >
                                          <span>
                                            (
                                            {costFormatter2(
                                              selectedCurrency,
                                              resource?.approxCost
                                            )}
                                            )
                                          </span>
                                        </Tooltip>
                                      </span>
                                    ) : null}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className="tableColumnWidth tableContent"
                                  scope="left"
                                >
                                  <Tooltip
                                    title={resource.changes}
                                    className="cursor-pointer"
                                    placement="bottom-start"
                                    arrow
                                  >
                                    <span>
                                      {costFormatter2(
                                        selectedCurrency,
                                        resource.changes
                                      )}
                                    </span>
                                  </Tooltip>{' '}
                                  &nbsp;
                                  <span>{`(${resource.perChange} %)`} </span>
                                  {/* {resource.perChange > 0 ? (
                                    <span className="highPriorityText">
                                      <img
                                        className="arrowLogo"
                                        src={topArrow}
                                      />
                                      High
                                    </span>
                                  ) : (
                                    <span className="lowPriorityText">
                                      <img
                                        className="arrowLogo"
                                        src={bottomArrow}
                                      />
                                      Low
                                    </span>
                                  )} */}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        ) : (
                          <TableRow>
                            <TableCell colSpan={20} align="center">
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </>
          ) : null}
        </>
      </div>

      <div className="mt-30">
        {loader ? <LoadingScreen /> : ''}

        <div>
          <div className="rsTableHeader">
            <Grid item md={6} sm={6} xs={6}>
              <Tabs
                selectionFollowsFocus
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                className="tabInvoiceHeading"
              >
                <Tab label="Scheduled" value={1} />
                <Tab label="History" value={2} />
              </Tabs>
            </Grid>
            <ProtectedComponent
              level={PERMISSION_LEVELS.WRITE}
              permission={invoicePermission}
            >
              <Grid item md={6} sm={6} xs={6} className="text-right">
                <ToolTip
                  title={
                    selectedAccountInfo.isAccountSelected
                      ? isAccountActive
                        ? 'Create Invoice'
                        : 'Selected Account is not Active'
                      : 'No Account has been selected'
                  }
                  arrow
                >
                  <span>
                    <button
                      className="btn btn-outline-primary mr-10"
                      onClick={handleClick}
                      disabled={!isAccountActive}
                    >
                      Create
                    </button>
                  </span>
                </ToolTip>

                <ToolTip
                  title={
                    selectedAccountInfo.isAccountSelected
                      ? isAccountActive
                        ? 'Schedule Invoice'
                        : 'Selected Account is not Active'
                      : 'No Account has been selected'
                  }
                  arrow
                >
                  <span>
                    <button
                      className="btn btn-outline-primary mr-10"
                      onClick={() => handleScheduleModalClick('schedule')}
                      disabled={!isAccountActive}
                    >
                      Schedule
                    </button>
                  </span>
                </ToolTip>
              </Grid>
            </ProtectedComponent>
          </div>

          {value === 1 ? (
            <>
              {selectedAccountInfo.selectedAccount ? (
                <Grid>
                  <TableContainer className="accContainer">
                    <Table
                      className="table adminTable adminPanelTable"
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="admintableHead">
                        <TableRow>
                          <TableCell
                            align="left"
                            className="invoiceName adminTableheading"
                          >
                            Name
                          </TableCell>
                          {selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase() ===
                          CLOUD_PROVIDER_SERVICES.AZURE ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Subscription Name
                            </TableCell>
                          ) : (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Account Name
                            </TableCell>
                          )}
                          <TableCell className="adminTableheading" align="left">
                            Billing Type
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Schedule Type
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Report Type
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            File Format
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Start Date
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            End Date
                          </TableCell>
                          <ProtectedComponent
                            level={PERMISSION_LEVELS.WRITE}
                            permission={invoicePermission}
                          >
                            <TableCell
                              className="adminTableheading"
                              align="right"
                            >
                              Actions
                            </TableCell>
                          </ProtectedComponent>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {scheduledInvoicesData.length == 0 ? (
                          <TableRow>
                            <TableCell colSpan={20} align="center">
                              No records found
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {scheduledInvoicesData &&
                          scheduledInvoicesData
                            .slice(
                              scheduledpage * scheduledRowsPerPage,
                              scheduledpage * scheduledRowsPerPage +
                                scheduledRowsPerPage
                            )
                            ?.map((invoice: any) =>
                              scheduledInvoicesData &&
                              scheduledInvoicesData.length > 0 ? (
                                <TableRow key={invoice.Id}>
                                  <TableCell
                                    align="left"
                                    className="invoiceName"
                                  >
                                    {invoice.InvoiceName.split('.')[0]}
                                  </TableCell>
                                  {selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase() ===
                                  CLOUD_PROVIDER_SERVICES.AZURE ? (
                                    <TableCell scope="left">
                                      {invoice.SubscriptionName}
                                    </TableCell>
                                  ) : (
                                    <TableCell scope="left">
                                      {
                                        accountList?.find(
                                          (account: any) =>
                                            account.AccountId ===
                                            invoice.AccountId
                                        )?.Name
                                      }
                                    </TableCell>
                                  )}
                                  <TableCell scope="left">
                                    {invoice.BillingType}
                                  </TableCell>
                                  <TableCell scope="left">
                                    {invoice.ScheduleType}
                                  </TableCell>
                                  <TableCell scope="left">
                                    {invoice.ReportType}
                                  </TableCell>
                                  <TableCell align="left">
                                    {invoice.FileFormat}
                                  </TableCell>
                                  <TableCell align="left" width="80">
                                    {invoice.StartDate}
                                  </TableCell>
                                  <TableCell align="left" width="80">
                                    {invoice.ScheduleType !== 'Once' ? (
                                      invoice.EndDate
                                    ) : (
                                      <div className="pl-20 font-15px">-</div>
                                    )}
                                  </TableCell>
                                  <ProtectedComponent
                                    level={PERMISSION_LEVELS.WRITE}
                                    permission={invoicePermission}
                                  >
                                    <TableCell align="right">
                                      <div className="d-flex float-r">
                                        <Tooltip title="Edit Invoice" arrow>
                                          <span
                                            className={
                                              'mr-10 scheduleBtn d-block'
                                            }
                                            onClick={() =>
                                              handleScheduleModalClick(
                                                'edit',
                                                invoice
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-pencil-square"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                              <path
                                                fillRule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                              />
                                            </svg>
                                          </span>
                                        </Tooltip>
                                        <Tooltip title="Remove Invoice" arrow>
                                          <span
                                            className={'scheduleBtn d-block'}
                                            onClick={() =>
                                              handleScheduleModalClick(
                                                'remove',
                                                invoice
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#DE0D26"
                                              className="bi bi-x-circle"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  </ProtectedComponent>
                                </TableRow>
                              ) : (
                                ''
                              )
                            )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {scheduledInvoicesData &&
                  scheduledInvoicesData.length <= 0 ? null : (
                    <TablePagination
                      rowsPerPageOptions={[5, 20, 30]}
                      component="div"
                      count={scheduledInvoicesData.length}
                      rowsPerPage={scheduledRowsPerPage}
                      page={scheduledpage}
                      onChangePage={handleScheduledChangePage}
                      onChangeRowsPerPage={handleScheduledRowsPerPage}
                      className="tablePaginaion"
                    />
                  )}
                </Grid>
              ) : null}
            </>
          ) : (
            <>
              {selectedAccountInfo.selectedAccount?.CloudProvider ? (
                <Grid>
                  <TableContainer className="accContainer">
                    <Table
                      className="table adminTable adminPanelTable"
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="admintableHead">
                        <TableRow>
                          <TableCell className="adminTableheading" align="left">
                            Name
                          </TableCell>
                          {selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase() ===
                          CLOUD_PROVIDER_SERVICES.AZURE ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Subscription Name
                            </TableCell>
                          ) : (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Account Name
                            </TableCell>
                          )}
                          <TableCell className="adminTableheading" align="left">
                            Report Type
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Billing Type
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Start Date
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            End Date
                          </TableCell>
                          <TableCell
                            className="adminTableheading"
                            align="right"
                          >
                            Download
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData.length == 0 ? (
                          <TableRow>
                            <TableCell colSpan={20} align="center">
                              No records found
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {invoiceData &&
                          invoiceData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((invoice: any) =>
                              invoiceData && invoiceData.length > 0 ? (
                                <TableRow key={invoice.Id}>
                                  <TableCell align="left">
                                    {invoice.InvoiceName.split('.')[0]}
                                  </TableCell>
                                  {selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase() ===
                                  CLOUD_PROVIDER_SERVICES.AZURE ? (
                                    <TableCell scope="left">
                                      {invoice.SubscriptionName}
                                    </TableCell>
                                  ) : (
                                    <TableCell scope="left">
                                      {
                                        accountList?.find(
                                          (account: any) =>
                                            account.AccountId ===
                                            invoice.AccountId
                                        ).Name
                                      }
                                    </TableCell>
                                  )}
                                  <TableCell align="left">
                                    {invoice.ReportType}
                                  </TableCell>
                                  <TableCell align="left">
                                    {invoice.BillingType}
                                  </TableCell>
                                  <TableCell align="left" width="80">
                                    {invoice.StartDate}
                                  </TableCell>
                                  <TableCell align="left" width="80">
                                    {invoice.EndDate != null ? (
                                      invoice.EndDate
                                    ) : (
                                      <div className="pl-20 font-15px">-</div>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <div className="d-flex float-r">
                                      <Tooltip title="Download XLSX" arrow>
                                        <span
                                          className={
                                            invoice.FileFormat === 'XLSX'
                                              ? 'mr-10 scheduleBtn d-block'
                                              : 'd-none'
                                          }
                                          onClick={() =>
                                            downloadInvoice(invoice)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#16824a"
                                            className="bi bi-file-earmark-spreadsheet"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                                          </svg>
                                        </span>
                                      </Tooltip>

                                      <Tooltip title="Download PDF" arrow>
                                        <span
                                          className={
                                            invoice.FileFormat === 'PDF'
                                              ? 'mr-10 scheduleBtn d-block'
                                              : 'd-none'
                                          }
                                          onClick={() =>
                                            downloadInvoice(invoice)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#d11516"
                                            className="bi bi-file-earmark-pdf"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                            <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ''
                              )
                            )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {invoiceData && invoiceData.length <= 0 ? null : (
                    <TablePagination
                      rowsPerPageOptions={[5, 20, 30]}
                      component="div"
                      count={invoiceData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      className="tablePaginaion"
                    />
                  )}
                </Grid>
              ) : (
                <div className="text-center mt-40 noRecordsFound">
                  No Account has been Selected.
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {openScheduleInvoiceModal && (
        <ScheduleInvoiceModal
          show={openScheduleInvoiceModal}
          onClose={closeCheckModal}
          mode={mode}
          rowDetails={rowDetails}
          accountList={accountList}
          getScheduledInvoices={getScheduledInvoices}
        />
      )}
      {invoiceModal && (
        <InvoiceModal
          show={invoiceModal}
          accountList={accountList}
          onClose={handleClose}
        />
      )}
    </Fragment>
  );
};

export default React.memo(Invoice);
