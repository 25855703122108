import { CLOUD_PROVIDER_SERVICES } from './constants';

export const APIS = {
  /**
   * Accounts apis
   */
  ACCOUNT_DETAILS: (account: string) =>
    `/accounts/getAllAccounts?CloudProvider=${account}`,
  GET_ACCOUNT_LIST: () => '/accounts/getAllSubscription',
  /**
   * Inventories apis
   */
  GET_BY_RESOURCE_NAMES: () => '/inventory/search',
  GET_RECENT_CREATED_RESOURCES: () => '/inventory/recentlycreated',
  /**
   * Billing simplified or invoice apis
   */
  GET_BILLING_BY_TAGS: (params: any) =>
    `${
      params.provider === CLOUD_PROVIDER_SERVICES.AWS
        ? '/invoice/awsBillingCostByTags'
        : '/invoice/azureBillingCostByTags'
    }`,
  GET_RESOURCES_COST: (params: any) =>
    `${
      params.provider === CLOUD_PROVIDER_SERVICES.AWS
        ? '/invoice/awsResources/cost'
        : '/invoice/azureResources/cost'
    }`,

  /**
   * Security report apis
   */
  GET_INVENTORY_REPORT: (params: any) =>
    `${
      params.provider.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS
        ? `aws/reports/${params.Id}`
        : `azure/reports/${params.Id}`
    }`,

  GET_SERVICES_REPORTS: (params: any) =>
    `costforecasting/servicesCost${
      params.cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
        ? params.selectedAccounts
          ? `?selectedAccounts=${params.selectedAccounts}`
          : ''
        : params.AzureId
        ? `?AzureId=${params.AzureId}`
        : `?SubscriptionId=${params.SubscriptionId || 'all'}`
    }`,
  GET_RESOURCES_SERVICES_REPORTS: (params: any) =>
    `costforecasting/servicesResourseCost?ChartType=pieChart&ServiceType=${
      params.serviceType
    }&${
      params.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS
        ? `subscriptionId=${params.SubscriptionId}`
        : params.AzureId
        ? `azureId=${params.AzureId}`
        : `subscriptionId=${params.SubscriptionId || 'all'}`
    }`,
  GET_SERVICE_WISE_COST: () => '/costforecasting/servicesCost?',
  GET_SERVICE_WISE_REPORT_CSV: () => 'costforecasting/exportsServicesCost',

  /**
   * User List apis
   */

  GET_COMPANY_LIST: () => '/users/companies',

  DELETE_USER: () => '/users/delete',

  /**
   * App Dashboard apis
   */

  GET_DASHBOARD_RIGHTSIZING: () => '/dashboard/rightsizing',
  GET_DASHBOARD_IDLE_RESOURCES: () => '/dashboard/idle',
  GET_DASHBOARD_SPEND_BY_SERVICE: () => '/dashboard/spendByService',
  GET_DASHBOARD_SPEND_FORECAST_RESOURCES: () => '/dashboard/spendForecast',
  GET_DASHBOARD_SPEND_AMOUNT: () => '/dashboard/sependAmount',
  GET_DASHBOARD_PER_CHANGE: () => '/dashboard/percentageChange',
  GET_DASHBOARD_Accounts: () => '/dashboard/getDashboardAccounts',
  GET_DASHBOARD_NOTIFICATION: () => '/notifications/getall',
  // GET_USERS_FEATURESS: () => '/features/create',
  GET_RDS_RIGHTSIZING_DATA: (accountId: string) =>
    `/rightsizing/resizeRDSInstance?accountId=${accountId}`,

  /**
   * Custom Notification apis
   */

  GET_SLACK_CREDS: () => '/customnotification/slack/get',
  SET_SLACK_CREDS: () => '/customnotification/slack/create',
  POST_CUSTOM_NOTIFICATION: () => '/customnotification/notification/update',
  COMPANY_NOTIFICATION: () => '/customnotification/companynotification/update',
  FETCH_ON_SLACK_CHANNELS_OF_SERVICE: () =>
    'customnotification/slacknotification/get',
  FETCH_SERVICE_NOTIFICATION_STATUS: () =>
    'customnotification/companynotification/get',
  UPDATE_SLACK_CREDS: () => 'customnotification/slack/update',
  SET_GCHAT_DATA: () => 'customnotification/gchat/create',
  GET_GCHAT_DATA: () => 'customnotification/gchat/get',
  UPDATE_GCHAT_DATA: () => 'customnotification/gchat/update',
  DELETE_GCHAT_DATA: () => 'customnotification/gchat/delete',

  /**
   * Cost Forecast apis
   */

  GET_RESOURCE_LIST: () => '/costforecasting/getResourseList',
  GET_RESOURCE_DETAIL: () => '/costforecasting/getResourseCost',
  SET_RESOURCE_COST: () => '/costforecasting/saveResourseCost',
  GET_HIGHEST_CONSUMED_RESOURCES: () => '/costforecasting/mostUsesResourse',

  //  Uptime Monitoring

  ADD_URL: () => '/monitoring/addUrl',
  GET_URLs: (pageNo: number, Type: string) =>
    `/monitoring/getCompanyUrls?page=${pageNo}&MonitoringType=${Type}`,
  DELETE_URL: () => '/monitoring/removeUrl',
  EDIT_URL: () => '/monitoring/editUrlDetails',
  TOGGLE_MONITORING: () => 'monitoring/toggleMonitoring',
  GET_MONITORING_DETAIL: () => 'monitoring/getMonitoringData',
  GET_MONITORING_EMAILS: () => '/monitoring/getCompanyEmails',
  ADD_EMAIL: () => '/monitoring/addEmail',
  GET_NON_REGISTERED_EMAIL: () => '/monitoring/getCompanyNonRegesteredEmails',
  DELETE_NON_REGISTERED_EMAIL: () =>
    '/monitoring/deleteCompanyNonRegesteredEmails',
  GET_SSL_DAYS: () => '/customnotification/SSL/get',
  SET_SSL_DAYS: () => '/customnotification/SSL/create',
  DELETE_SSL_DAYS: () => '/customnotification/SSL/delete',
  UPLOAD_URL_CSV: () => '/monitoring/uploadUrlCsv',
  DOWNLOAD_URL_CSV: () => '/monitoring/downloadCsvFormat',
  DOWNLOAD_MONITORING_REPORT: () => '/monitoring/getMonitoringReport',
  GET_URL_NOTIFICATION_LOGS: () => '/monitoring/getNotificationLogs',
  /**
   * Url Monitoring apis
   */

  GET_URL_MONITORING: () => '/monitoring/getMonitoringDetail',
  ADD_URL_MAINTENANCE: () => '/monitoring/addMaintenanceMode',
  EDIT_URL_MAINTENANCE: () => '/monitoring/editMaintenanceMode',
  GET_ENVIRONMENT_LABELS: () => '/monitoring/getLabels',

  // Real User Monitoring

  GET_RUM_DATA: () => '/rum/clients',
  ADD_RUM_SITE: () => '/rum/copyscript',
  GET_PLATFORM_BROSWER_DATA: () => '/rum/platforms-session-load',
  GET_COUNTRY_WISE_SESSION_DATA: () => '/rum/countryWiseSessions',
  GET_COUNTRY_WISE_VIEWS: () => '/rum/countryWiseViews',
  GET_SESSIONS_VIEW: () => '/rum/sessionview',
  DELETE_RUM_CLIENT: () => '/rum/deletesite',
  GET_SCRIPT: () => '/rum/getscript',
  GET_APDEX_DETAIL: () => '/rum/apdex',
  GET_PAGE_WISE_DETAIL: () => '/rum/PageWiseDetails',
  /**
   * Billing apis
   */

  NEW_SUBSCRIPTION: () => '/stripe/subscribe',
  CHANGE_SUBSCRIPTION: () => '/stripe/changeSubscription',
  ADD_CARD: () => '/stripe/addCard',
  DELETE_CARD: () => '/stripe/removeCard',
  SET_AS_DEFAULT: () => '/stripe/setDefaultPaymentMethod',
  GET_CARD: () => '/stripe/customerCardDetails',
  GET_PLAN_DETAILS: () => '/stripe/allPlans',
  CANCEL_SUBSCRIPTION: () => '/stripe/cancelSubscriptionAtPeriodEnd',
  COMPANY_DETAILS: () => 'company/getCompany',
  RETRY_PAYMENT: () => '/stripe/payIncompleteSubscription',
  REQUEST_QUOTE: () => '/stripe/requestPlan',

  // uploaded document
  GET_UPLOADED_DOCUMENTS: () => '/users/alldocs',
  GET_UPLOADED_DOCUMENTS_STATIC: (token: string) =>
    `/users/viewDocx?token=${token}`,
  SHARE_DOC: () => '/users/sharedoc',

  // Admin Panel
  GET_ALL_ORGANISATION_DETAILS: () => '/company/allorganisation',
  GET_ORGANISATION_USERS: (company: string) =>
    `/users/companyUsers?company=${company}`,
  GET_ACCOUNTS_DETAILS: (company: string) =>
    `/accounts/acccountlist?company=${company}`,
  UPDATE_USERS_DETAILS: () => '/users/updateUser',
  DELETE_USERS_DETAILS: () => '/users/deleteUser',
  UPDATE_ORGANISATON_STATUS: () => '/company/updateCompanyStatus',
  DELETE_ORGANISATON: () => '/company/deleteCompany',
  UPDATE_ACCOUNT_DETAILS: () => '/accounts/updateAccountStatus',
  DELETE_ACCOUNT_DETAILS: () => '/accounts/deleteAccountDetails',
  UPLOAD_DOCUMENTS: (companyName: string, friendlyName: string) =>
    `/users/upload?company=${companyName}&friendlyName=${friendlyName}`,
  UPLOAD_USERS_INFO: () => '/users/docusers',
  ADD_ORGANIZATIONS: () => '/company/addcompany',
  GET_ALL_USERS_FOR_SUPER_ADMIN: () => '/users/admin-dashboard/getAllUsers',
  GET_ALL_FEATURES: () => '/features/getAll',
  GET_ACTIVE_SUB: () => '/stripe/getActiveSubscriptions',
  GET_ALL_BILLING_REQUEST: () => '/stripe/getAllRequest',
  SET_BILLING_REQUEST: () => '/stripe/createPrice',
  GET_ORG_BILLING_HISTORY: (companyName: string) =>
    `/subscription/allsubshistory?company=${companyName}`,
  GET_SYSTEM_HEALth_DATA: (accountId: string, cloud: string) =>
    `/accounts/healthdata?account=${accountId}&cloud=${cloud}`,
  GET_ACTIVE_ACCOUNTS: (companyName: string) =>
    `/accounts/accountlist?company=${companyName}`,
  GET_JOB_FAILUR_DETAILS: (
    services: string,
    account: string,
    jobdate: string,
    cloud: string
  ) =>
    `/accounts/healthdetails?services=${services}&account=${account}&jobdate=${jobdate}&cloud=${cloud}`,
};
