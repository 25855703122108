import React, { useEffect, useState } from 'react';
import {
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TableContainer,
  TableRow,
  Tooltip,
  Chip,
  TextField,
} from '@material-ui/core';
import { Checkbox } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './NotificationManagement.css';
import { ReactComponent as SlackIcon } from '../../assets/ICONS/slack.svg';
import { ReactComponent as GmailIcon } from '../../assets/ICONS/gmail.svg';
import { useDispatch } from 'react-redux';
import { config } from './config';
import { handleError } from '../../utils/config';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import { userActions } from '../../store/userSlice';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  IconButton,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteOutline } from '@mui/icons-material';
import { userService } from '../../services';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal';
import { FormControl } from '@mui/material';
import SlackCredsModal from '../../components/Modals/SlackCreds/SlackCredsModal';
import EditSlackCreds from '../../components/Modals/SlackCreds/EditSlackCreds';
import { FEATURES_NAMES } from '../../components/Modals/NotificationManagementModal/config';
import AddEmail from '../../components/Modals/AddEmailMonitoring/AddEmail';
import GchatCredsModal from '../../components/Modals/GchatCreds/GchatCredsModal';
import EditGchatCreds from '../../components/Modals/GchatCreds/EditGchatCreds';
import { GchatParamsInterface } from '../../utils/interfaces';

const NotificationManagement = () => {
  const [slackCreds, setSlackCreds] = useState([]);
  const [gchatCreds, setGchatCreds] = useState([]);
  const [slackCredsModal, setSlackCredsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editSlackModal, setEditSlackModal] = useState(false);
  const [GchatModal, setGchatModal] = useState(false);
  const [editGchatModal, setEditGchatModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [editedData, setEditedData] = useState({});
  const [loader, setLoader] = useState(false);
  const [networkError, setNetworkError] = React.useState(false);
  const [enabled, setEnabled] = useState('');
  const [userDetails, setUsersDetails] = useState([]);
  const [idleMail, setIdleMail] = useState<any>([]);
  const [rightsizingMail, setRightsizingMail] = useState<any>([]);
  const [invoiceMail, setInvoiceMail] = useState<any>([]);
  const [cpuMonitoringMail, setCPUMonitoringMail] = useState<any>([]);
  const [securityScanMail, setSecurityScanMail] = useState<any>([]);
  const [costExceededMail, setCostExceededMail] = useState<any>([]);
  const [uptimeMonitoringMail, setUptimeMonitoringMail] = useState<any>([]);
  const [idleSlack, setIdleSlack] = useState<any>([]);
  const [rightsizingSlack, setRightsizingSlack] = useState<any>([]);
  const [invoiceSlack, setInvoiceSlack] = useState<any>([]);
  const [cpuMonitoringSlack, setCPUMonitoringSlack] = useState<any>([]);
  const [securityScanSlack, setSecurityScanSlack] = useState<any>([]);
  const [costExceededSlack, setCostExceededSlack] = useState<any>([]);
  const [uptimeMonitoringSlack, setUptimeMonitoringSlack] = useState<any>([]);
  const [slackChannelName, setSlackChannelName] = useState<string[]>([]);
  const [features, setFeatures] = useState<string[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [usersEmail, setUsersEmail] = useState<string[]>([]);
  const [
    selectedNonRegisteredEmail,
    setSelectedNonRegisteredEmail,
  ] = useState<string>('');
  const [nonRegisteredEmails, setNonRegisteredEmails] = useState<string[]>([]);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [deleteState, setDeleteState] = useState<string>('');
  const [IdToBeDeleted, setIdToBeDeleted] = useState(0);
  const [gchatDataToBeEdited, setGchatDataToBeEdited] = useState({
    id: 0,
    channel_name: '',
    webhook_url: '',
  });
  const [SSLDays, setSSLDays] = useState('');
  const [defaultSSLSetting, setDefaultSSLSetting] = useState(false);

  const onCloseDeleteModal = () => setDeleteModal(false);
  const onCloseSlackModal = () => setSlackCredsModal(false);
  const onCloseGchatModal = () => setGchatModal(false);
  const onCloseEditGchatModal = () => setEditGchatModal(false);
  const onCloseEditSlackModal = () => setEditSlackModal(false);
  const onCloseAddEmailModal = () => setShowAddEmail(false);

  const GlobalDelete: any = {
    slackChannels: {
      metaData: selectedChannel + ' ' + 'channel',
      deleteFunction: () => deleteSlackChannel(),
    },
    gchatChannels: {
      metaData: selectedChannel + ' ' + 'channel',
      deleteFunction: () => deleteGchatChannel(IdToBeDeleted),
    },
    nonRegisteredEmails: {
      metaData: selectedNonRegisteredEmail + ' ' + 'email',
      deleteFunction: () => deleteNonRegisteredEmail(),
    },
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const fetchSlackData = async () => {
    try {
      setLoader(true);
      const slackData = await config.fetchSlackData();
      setSlackCreds(slackData);
      const channelsName: string[] = [];
      slackData.forEach((el: any) => channelsName.push(el.channel_name));
      setEnabled('1');

      setSlackChannelName(channelsName);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const fetchSlackChannelOnByService = async () => {
    try {
      setLoader(true);
      const result = await config.fetchSlackChannelOnServices();
      if (Object.keys(result).length) {
        setIdleSlack(result.IDLE ? result.IDLE : []);
        setRightsizingSlack(result.RIGHTSIZING ? result.RIGHTSIZING : []);
        setInvoiceSlack(result.INVOICE ? result.INVOICE : []);
        setCPUMonitoringSlack(
          result['CPU MONITORING'] ? result['CPU MONITORING'] : []
        );
        setSecurityScanSlack(
          result['SECURITY SCAN'] ? result['SECURITY SCAN'] : []
        );
        setCostExceededSlack(
          result['COST EXCEEDED'] ? result['COST EXCEEDED'] : []
        );
        setUptimeMonitoringSlack(
          result['UPTIME MONITORING'] ? result['UPTIME MONITORING'] : []
        );
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const deleteSlackChannel = async () => {
    try {
      setLoader(true);
      const deletedChannel: any = slackCreds.filter(
        (channel: any) => channel.channel_name === selectedChannel
      );
      const result = await config.setSlackData({
        channels: [
          {
            channel_name: deletedChannel[0].channel_name,
            webhook_url: deletedChannel[0].webhook_url,
            is_enabled: 0,
          },
        ],
      });

      if (result.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Slack channel deleted successfully',
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setEnabled('');
      setLoader(false);
      onCloseDeleteModal();
      fetchSlackData();
    }
  };

  const deleteNonRegisteredEmail = async () => {
    try {
      setLoader(true);

      const result = await config.deleteNonRegisteredEmail(
        selectedNonRegisteredEmail
      );

      if (result.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setEnabled('');
      setLoader(false);
      onCloseDeleteModal();
      GetNonRegisteredEmails();
    }
  };

  //Gchat
  const fetchGchatData = async () => {
    try {
      setLoader(true);
      const data = await config.getGchatData();
      setGchatCreds(data);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const submitGchatCreds = async (data: any) => {
    try {
      setLoader(true);
      const gchatData = {
        channels: [...data],
      };

      const result = await config.setGchatData(gchatData);
      if (result.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Gchat channel added succesfully.',
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      fetchGchatData();
      setLoader(false);
      setGchatModal(false);
    }
  };

  const updateGchatCreds = async (dataToBeEdited: GchatParamsInterface) => {
    try {
      setLoader(true);
      const res = await config.updateGchatData({
        id: dataToBeEdited.id,
        channel_name: dataToBeEdited.channel_name,
        webhook_url: dataToBeEdited.webhook_url,
      });
      if (res.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: res.message,
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      fetchGchatData();
      setEditGchatModal(false);
    }
  };

  const deleteGchatChannel = async (id: number) => {
    try {
      setLoader(true);
      const data = await config.deleteGchatData(id);
      if (data.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: data.message,
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      fetchGchatData();
      setLoader(false);
      setDeleteModal(false);
    }
  };

  ///table==============

  const fetchAllUsersData = async () => {
    try {
      setLoader(true);
      const userData = await userService.getALLUserDetails();

      if (userData.length) {
        userData.forEach((user: any) => {
          setUserIds((preValue) => [...preValue, user.Id]);
          setUsersEmail((prevValue) => [...prevValue, user.EmailAddress]);
          setUsersDetails(userData);
          if (user.notification.IDLE.Mail === 1)
            setIdleMail((prev: any) => [...prev, user.EmailAddress]);
          if (user.notification.RIGHTSIZING.Mail === 1)
            setRightsizingMail((prev: any) => [...prev, user.EmailAddress]);
          if (user.notification['CPU MONITORING'].Mail === 1)
            setCPUMonitoringMail((prev: any) => [...prev, user.EmailAddress]);
          if (user.notification.INVOICE.Mail === 1)
            setInvoiceMail((prev: any) => [...prev, user.EmailAddress]);
          if (user.notification['SECURITY SCAN'].Mail === 1)
            setSecurityScanMail((prev: any) => [...prev, user.EmailAddress]);
          if (user.notification['COST EXCEEDED'].Mail === 1)
            setCostExceededMail((prev: any) => [...prev, user.EmailAddress]);
          if (user.notification['UPTIME MONITORING'].Mail === 1)
            setUptimeMonitoringMail((prev: any) => [
              ...prev,
              user.EmailAddress,
            ]);
        });
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const submit = async () => {
    try {
      setLoader(true);
      const userEmailAddressToID = (Mail: string[]) => {
        const UsersIds: any = [];
        Mail.forEach((el: any) => {
          userDetails.forEach((userRow: any) => {
            if (el === userRow.EmailAddress) UsersIds.push(userRow.Id);
          });
        });
        return UsersIds;
      };

      const slackChannelNameToID = (SlackChannels: string[]) => {
        const SlackIds: any = [];
        SlackChannels.forEach((el: any) => {
          slackCreds.forEach((slackRow: any) => {
            if (el === slackRow.channel_name) SlackIds.push(slackRow.id);
          });
        });
        return SlackIds;
      };
      const data = {
        [FEATURES_NAMES.IDLE]: {
          Users: userEmailAddressToID(idleMail),
          SlackChannels: slackChannelNameToID(idleSlack),
        },
        [FEATURES_NAMES.RIGHTSIZING]: {
          Users: userEmailAddressToID(rightsizingMail),
          SlackChannels: slackChannelNameToID(rightsizingSlack),
        },
        [FEATURES_NAMES.INVOICE]: {
          Users: userEmailAddressToID(invoiceMail),
          SlackChannels: slackChannelNameToID(invoiceSlack),
        },
        [FEATURES_NAMES.SECURITY_SCAN]: {
          Users: userEmailAddressToID(securityScanMail),
          SlackChannels: slackChannelNameToID(securityScanSlack),
        },
        [FEATURES_NAMES.CPU_MONITORING]: {
          Users: userEmailAddressToID(cpuMonitoringMail),
          SlackChannels: slackChannelNameToID(cpuMonitoringSlack),
        },
        [FEATURES_NAMES.COST_EXCEEDED]: {
          Users: userEmailAddressToID(costExceededMail),
          SlackChannels: slackChannelNameToID(costExceededSlack),
        },
        [FEATURES_NAMES.UPTIME_MONITORING]: {
          Users: userEmailAddressToID(uptimeMonitoringMail),
          SlackChannels: slackChannelNameToID(uptimeMonitoringSlack),
        },
      };

      const result = await config.updateUserNotifications(data);
      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const companyNotification = async () => {
    try {
      const data = {
        IDLE: features.includes('Idle') ? 1 : 0,
        RIGHTSIZING: features.includes('Rightsizing') ? 1 : 0,
        'CPU MONITORING': features.includes('CPU Monitoring') ? 1 : 0,
        INVOICE: features.includes('Invoice') ? 1 : 0,
        'SECURITY SCAN': features.includes('Security Scan') ? 1 : 0,
        'COST EXCEEDED': features.includes('Cost Exceeded') ? 1 : 0,
        'UPTIME MONITORING': features.includes('Uptime Monitoring') ? 1 : 0,
      };
      await config.companyNotification(data);
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const fetchServiceViceCompanyNotifications = async () => {
    try {
      const result = await config.getServiceNotificationStatus();
      if (result.IDLE === 1) setFeatures((prev: any) => [...prev, 'Idle']);
      if (result.RIGHTSIZING === 1)
        setFeatures((prev: any) => [...prev, 'Rightsizing']);
      if (result['COST EXCEEDED'] === 1)
        setFeatures((prev: any) => [...prev, 'Cost Exceeded']);
      if (result.INVOICE === 1)
        setFeatures((prev: any) => [...prev, 'Invoice']);
      if (result['SECURITY SCAN'] === 1)
        setFeatures((prev: any) => [...prev, 'Security Scan']);
      if (result['CPU MONITORING'] === 1)
        setFeatures((prev: any) => [...prev, 'CPU Monitoring']);
      if (result['UPTIME MONITORING'] === 1)
        setFeatures((prev: any) => [...prev, 'Uptime Monitoring']);
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    GetNonRegisteredEmails();
    fetchAllUsersData();
    fetchSlackData();
    fetchSlackChannelOnByService();
    fetchServiceViceCompanyNotifications();
    fetchGchatData();
    getInputFieldsData();
  }, []);

  const ITEM_HEIGHT = 58;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeIdleMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setIdleMail(value);
  };
  const handleChangeRightsizingMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setRightsizingMail(value);
  };
  const handleChangeInvoiceMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setInvoiceMail(value);
  };
  const handleChangeCPUMonitoringMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setCPUMonitoringMail(value);
  };
  const handleChangeSecurityScanMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setSecurityScanMail(value);
  };
  const handleChangeCostExceededMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setCostExceededMail(value);
  };
  const handleChangeUptimeMonitoringMail = (event: any) => {
    const {
      target: { value },
    } = event;

    setUptimeMonitoringMail(value);
  };
  const handleChangeIdleSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setIdleSlack(value);
  };
  const handleChangeRightsizingSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setRightsizingSlack(value);
  };
  const handleChangeInvoiceSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setInvoiceSlack(value);
  };
  const handleChangeCPUMonitoringSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setCPUMonitoringSlack(value);
  };
  const handleChangeSecurityScanSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setSecurityScanSlack(value);
  };
  const handleChangeCostExceededSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setCostExceededSlack(value);
  };
  const handleChangeUptimeMonitoringSlack = (event: any) => {
    const {
      target: { value },
    } = event;

    setUptimeMonitoringSlack(value);
  };

  const submitSlackCreds = async (data: any) => {
    try {
      setLoader(true);
      const slackData = {
        channels: [...data],
      };

      const result = await config.setSlackData(slackData);
      if (result.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Slack channel added succesfully.',
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      fetchSlackData();
      setLoader(false);
      setSlackCredsModal(false);
      //   setShowInput('');
    }
  };

  const updateSlackCreds = async (data: any) => {
    try {
      setLoader(true);
      const slackData = {
        channels: [data],
      };
      const result = await config.updateSlackCreds(slackData);
      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Channel name successfully updated',
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      fetchSlackData();
      setLoader(false);
      setEditSlackModal(false);
    }
  };

  const UptimeMonitoringAddEmails = async (email: string) => {
    try {
      setLoader(true);
      const params = {
        EmailAddress: email,
      };
      const res = await config.uptimeMonitoringAddEmails(params);
      if (res) {
        dispatch(
          userActions.replaceUserState({
            successMessage: res.message,
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      GetNonRegisteredEmails();
      setShowAddEmail(false);
      setLoader(false);
    }
  };
  const GetNonRegisteredEmails = async () => {
    try {
      const res = await config.getUptimeMonitoringNonRegisteredEmails();
      if (res.length) {
        setNonRegisteredEmails(res);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setShowAddEmail(false);
      setLoader(false);
    }
  };

  //ssl recommendation section

  const handleSSLFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSSLDays(e.target.value);
  };

  const getInputFieldsData = async () => {
    try {
      setLoader(true);
      const res = await config.getSSLDays();

      if (res.message) {
        setDefaultSSLSetting(true);
      } else {
        setDefaultSSLSetting(false);
        setSSLDays(res.RecommendedDays);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const onSubmitSSLInputs = async () => {
    try {
      if (
        SSLDays === '' ||
        +SSLDays <= 0 ||
        +SSLDays !== Math.floor(+SSLDays)
      ) {
        return;
      }
      setLoader(true);

      const submitRes = await config.setSSLDays({
        RecommendedDays: SSLDays!,
      });

      if (submitRes.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: submitRes.message,
          })
        );
      }
    } catch (error) {
    } finally {
      if (+SSLDays > 0 && +SSLDays === Math.floor(+SSLDays)) {
        getInputFieldsData();
      } else {
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'SSL day/days must be a positive integer',
          })
        );
      }
      setLoader(false);
    }
  };

  const handleSSLRestore = async () => {
    try {
      setLoader(true);
      const res = await config.deleteSSLDays();
      if (res.message) setSSLDays('');
      dispatch(
        userActions.replaceUserState({
          successMessage: res.message,
        })
      );
    } catch (error) {
    } finally {
      getInputFieldsData();
      setLoader(false);
    }
  };

  const handleFeatureChange = async (e: any) => {
    if (features.indexOf(e.target.value) > -1) {
      setFeatures((prev: any) =>
        prev.filter((el: string) => el !== e.target.value)
      );
    } else setFeatures((prev: any) => [...prev, e.target.value]);
  };

  useEffect(() => {
    if (idleMail.length === 0 && idleSlack.length === 0) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'Idle')
      );
    } else {
      if (!features.includes('Idle'))
        setFeatures((prevValue: any) => [...prevValue, 'Idle']);
    }

    if (rightsizingMail.length === 0 && rightsizingSlack.length === 0) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'Rightsizing')
      );
    } else {
      if (!features.includes('Rightsizing'))
        setFeatures((prevValue: any) => [...prevValue, 'Rightsizing']);
    }

    if (invoiceMail.length === 0 && invoiceSlack.length === 0) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'Invoice')
      );
    } else {
      if (!features.includes('Invoice'))
        setFeatures((prevValue: any) => [...prevValue, 'Invoice']);
    }

    if (cpuMonitoringMail.length === 0 && cpuMonitoringSlack.length === 0) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'CPU Monitoring')
      );
    } else {
      if (!features.includes('CPU Monitoring'))
        setFeatures((prevValue: any) => [...prevValue, 'CPU Monitoring']);
    }

    if (securityScanMail.length === 0 && securityScanSlack.length === 0) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'Security Scan')
      );
    } else {
      if (!features.includes('Security Scan'))
        setFeatures((prevValue: any) => [...prevValue, 'Security Scan']);
    }

    if (costExceededMail.length === 0 && costExceededSlack.length === 0) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'Cost Exceeded')
      );
    } else {
      if (!features.includes('Cost Exceeded'))
        setFeatures((prevValue: any) => [...prevValue, 'Cost Exceeded']);
    }

    if (
      uptimeMonitoringMail.length === 0 &&
      uptimeMonitoringSlack.length === 0
    ) {
      setFeatures((prevValue: any) =>
        prevValue.filter((el: string) => el !== 'Uptime Monitoring')
      );
    } else {
      if (!features.includes('Uptime Monitoring'))
        setFeatures((prevValue: any) => [...prevValue, 'Uptime Monitoring']);
    }
  }, [
    uptimeMonitoringSlack,
    costExceededSlack,
    securityScanSlack,
    cpuMonitoringSlack,
    invoiceSlack,
    rightsizingSlack,
    idleSlack,
    uptimeMonitoringMail,
    costExceededMail,
    securityScanMail,
    cpuMonitoringMail,
    invoiceMail,
    rightsizingMail,
    idleMail,
  ]);

  return (
    <>
      {loader && <LoadingScreen />}
      <div className="sContainer mb-10">
        <Grid item md={12} sm={12} xs={12}>
          <span className="NMSHeading"> Manage Alerts and Notifications</span>
          <hr />
        </Grid>
      </div>
      <div className="align-right">
        <span className="mr-10">
          <button
            className="btn btn-primary"
            onClick={() => setShowAddEmail(true)}
          >
            + Add Emails
          </button>
        </span>
        <span className="mr-10">
          <button
            className="btn btn-primary"
            onClick={() => setSlackCredsModal(true)}
          >
            + Add Slack Channel
          </button>
        </span>
        <span>
          <button
            className="btn btn-primary"
            onClick={() => setGchatModal(true)}
          >
            + Add Gchat Channel
          </button>
        </span>
      </div>

      <Accordion className="mt-15">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="outerSlackTextBox ">
            <div className="flex">
              <div className="flex borderRight mr-5 pr-10">
                <SlackIcon width={20} height={20} className="mr-5 mt-5" />
                <div className="slack mt-5">Slack</div>
              </div>
              <div className="companyName mt-5">
                {slackCreds.length}{' '}
                {slackCreds.length > 1 ? 'Channels' : 'Channel'}{' '}
              </div>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {' '}
          <div className="companyNameOuterCont">
            {slackCreds.length
              ? slackCreds.map((slackChannel: any, i: number) => (
                  <div
                    className="slackChannelOuterCont "
                    key={slackChannel.channel_name + i}
                  >
                    <div className="slackChannelName">
                      {slackChannel.channel_name}{' '}
                    </div>
                    <div>
                      <IconButton
                        color="inherit"
                        className="black"
                        onClick={() => {
                          setEditedData(slackChannel), setEditSlackModal(true);
                        }}
                      >
                        <EditOutlinedIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        color="inherit"
                        className="red"
                        onClick={() => {
                          setDeleteState('slackChannels');
                          setDeleteModal(true),
                            setSelectedChannel(slackChannel.channel_name);
                        }}
                      >
                        <DeleteOutline fontSize="large" />
                      </IconButton>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-15">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="outerSlackTextBox ">
            <div className="flex">
              <div className="flex borderRight mr-5 pr-10">
                <GmailIcon width={20} height={20} className="mr-5 mt-5" />
                <div className="slack mt-5">Spaces</div>
              </div>
              <div className="companyName mt-5">
                {gchatCreds.length}{' '}
                {gchatCreds.length > 1 ? 'Channels' : 'Channel'}{' '}
              </div>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {' '}
          <div className="companyNameOuterCont">
            {gchatCreds.length
              ? gchatCreds.map((gchatChannel: any, i: number) => (
                  <div
                    className="slackChannelOuterCont "
                    key={gchatChannel.channel_name + i}
                  >
                    <div className="slackChannelName">
                      {gchatChannel.channel_name}{' '}
                    </div>
                    <div>
                      <IconButton
                        color="inherit"
                        className="black"
                        onClick={() => {
                          setGchatDataToBeEdited(gchatChannel),
                            setEditGchatModal(true);
                        }}
                      >
                        <EditOutlinedIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        color="inherit"
                        className="red"
                        onClick={() => {
                          setIdToBeDeleted(gchatChannel.id);
                          setDeleteState('gchatChannels');
                          setDeleteModal(true),
                            setSelectedChannel(gchatChannel.channel_name);
                        }}
                      >
                        <DeleteOutline fontSize="large" />
                      </IconButton>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-10">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <div className="outerSlackTextBox ">
            <div className="flex">
              <div className="flex borderRight mr-5 pr-10">
                <GmailIcon width={20} height={20} className="mr-5 mt-5" />
                <div className="slack mt-5">Email</div>
              </div>
              <div className="companyName mt-5">
                {nonRegisteredEmails.length}{' '}
                {nonRegisteredEmails.length > 1
                  ? 'Non-Registered Emails'
                  : 'Non-Registered Email'}{' '}
              </div>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className="accordionContentBox">
          <div className="companyNameOuterCont">
            {nonRegisteredEmails.length
              ? nonRegisteredEmails.map((email: any, i: number) => (
                  <div className="slackChannelOuterCont ml-10" key={email + i}>
                    <div className="slackChannelName">{email} </div>

                    <div>
                      <IconButton
                        color="inherit"
                        className="red"
                        onClick={() => {
                          setDeleteState('nonRegisteredEmails');
                          setDeleteModal(true);
                          setSelectedNonRegisteredEmail(email);
                        }}
                      >
                        <DeleteOutline fontSize="large" />
                      </IconButton>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </AccordionDetails>
      </Accordion>

      <Grid>
        <div className="sContainer mt-25 mb-15">
          <Grid item md={12} sm={12} xs={12}>
            <span className="NMSHeading"> SSL Recommendation</span>

            {defaultSSLSetting && (
              <span className="ml-10 sslRecommBox">
                (Currently, SSL expiration notifications will be triggered on
                30th day, 22nd day, and 15th day, on or before the 7th day.)
              </span>
            )}
            <hr />
          </Grid>
        </div>
        {/* <div
          className={
            defaultSSLSetting
              ? 'inventoryHeader mb-15 sslRecommBox'
              : ' inventoryHeader mb-15 '
          }
        >
          <span
            className="inventoryHeading font-26px fw-300"
            // style={{ cursor: 'pointer' }}
          >
            SSL Recommendations
          </span>
          {defaultSSLSetting && (
            <span>
              (Currently, SSL expiration notifications will be triggered on 30th
              day, 22nd day, and 15th day, on or before the 7th day.)
            </span>
          )}
        </div> */}

        <div className="flex mt-10">
          <div className="w-85 mr-10">
            <TextField
              // disabled
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              name="SSLDays"
              className="w-100"
              id="outlined-disabled"
              label="SSL Days"
              value={SSLDays}
              onChange={handleSSLFieldChange}
              type="number"
              variant="outlined"
            />

            {/* </FormControl> */}
          </div>
          <div>
            {defaultSSLSetting ? null : (
              <button
                className="btn btn-primary mt-10"
                onClick={() => handleSSLRestore()}
              >
                Reset Default SSL Setting
              </button>
            )}

            {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="SSLDays"
                      onChange={handleSwitchChange}
                      checked={defaultSettings.SSLDays}
                    />
                  }
                  label="Restore default settings"
                />
              </FormGroup> */}
          </div>
        </div>
        <div className="mt-0 modalFooter flex flex-end">
          <button
            className="btn btn-primary"
            onClick={() => onSubmitSSLInputs()}
          >
            Submit
          </button>
          {/* <input type="submit" className="btn btn-primary ml-10" value="SUBMIT" /> */}
        </div>
      </Grid>

      <div className="notificationBox">
        <TableContainer className="accContainer">
          <Table
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="a dense table"
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell width="3%" className="adminTableheading">
                  <Checkbox
                    onChange={() => {
                      if (features.length === 7) setFeatures([]);
                      else
                        setFeatures([
                          'Idle',
                          'Rightsizing',
                          'Invoice',
                          'Cost Exceeded',
                          'Security Scan',
                          'CPU Monitoring',
                          'Uptime Monitoring',
                        ]);
                    }}
                    value="all"
                    checked={features.length === 7}
                  />
                </TableCell>
                <TableCell width="90%" className="adminTableheading">
                  Features
                </TableCell>
                <TableCell align="left" className="adminTableheading">
                  Users Email
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    enabled
                      ? 'adminTableheading'
                      : 'adminTableheading disabledFont'
                  }
                >
                  Slack
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleFeatureChange}
                    // onClick={() =>
                    //   handleFeatureClick('Idle', idleMail, idleSlack)
                    // }
                    value="Idle"
                    checked={features.indexOf('Idle') > -1}
                  />
                </TableCell>
                <TableCell
                // className="resourceNames"
                >
                  Idle
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {idleMail.length
                            ? idleMail.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={idleMail}
                        onChange={handleChangeIdleMail}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  size="small"
                                  key={value}
                                  label={value}
                                  onDelete={() =>
                                    setIdleMail(
                                      idleMail.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          size="small"
                                          key={value}
                                          label={value}
                                          onDelete={() =>
                                            setIdleMail(
                                              idleMail.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem
                          key={'all' + 1}
                          value={'all'}
                          onClick={() => handleAllConditions('idle')}
                        >
                          <Checkbox
                            checked={idleMail.length === usersEmail.length}
                          />
                          <ListItemText primary={'all'} />
                        </MenuItem> */}
                        {usersEmail.map((email, i) => (
                          <MenuItem key={email + i} value={email}>
                            <Checkbox checked={idleMail?.indexOf(email) > -1} />
                            <ListItemText primary={email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {idleSlack.length
                            ? idleSlack.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={idleSlack}
                        onChange={handleChangeIdleSlack}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setIdleSlack(
                                      idleSlack.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          size="small"
                                          key={value}
                                          label={value}
                                          onDelete={() =>
                                            setIdleSlack(
                                              idleSlack.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {slackChannelName.map((channel, i) => (
                          <MenuItem key={channel + i} value={channel}>
                            <Checkbox
                              checked={idleSlack?.indexOf(channel) > -1}
                            />
                            <ListItemText primary={channel} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleFeatureChange}
                    value="Rightsizing"
                    checked={features.indexOf('Rightsizing') > -1}
                  />
                </TableCell>
                <TableCell
                // className="resourceNames"
                >
                  Rightsizing
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {rightsizingMail.length
                            ? rightsizingMail.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={rightsizingMail}
                        onChange={handleChangeRightsizingMail}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setRightsizingMail(
                                      rightsizingMail.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setRightsizingMail(
                                              rightsizingMail.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {usersEmail.map((email) => (
                          <MenuItem key={email} value={email}>
                            <Checkbox
                              checked={rightsizingMail.indexOf(email) > -1}
                            />
                            <ListItemText primary={email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {rightsizingSlack.length
                            ? rightsizingSlack.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={rightsizingSlack}
                        onChange={handleChangeRightsizingSlack}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setRightsizingSlack(
                                      rightsizingSlack.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          size="small"
                                          key={value}
                                          label={value}
                                          onDelete={() =>
                                            setRightsizingSlack(
                                              rightsizingSlack.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {slackChannelName.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={rightsizingSlack.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleFeatureChange}
                    value="Invoice"
                    checked={features.indexOf('Invoice') > -1}
                  />
                </TableCell>
                <TableCell
                // className="resourceNames"
                >
                  Invoice
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {invoiceMail.length
                            ? invoiceMail.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={invoiceMail}
                        onChange={handleChangeInvoiceMail}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setInvoiceMail(
                                      invoiceMail.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setInvoiceMail(
                                              invoiceMail.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {usersEmail.map((email) => (
                          <MenuItem key={email} value={email}>
                            <Checkbox
                              checked={invoiceMail.indexOf(email) > -1}
                            />
                            <ListItemText primary={email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {invoiceSlack.length
                            ? invoiceSlack.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={invoiceSlack}
                        onChange={handleChangeInvoiceSlack}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setInvoiceSlack(
                                      invoiceSlack.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          size="small"
                                          key={value}
                                          label={value}
                                          onDelete={() =>
                                            setInvoiceSlack(
                                              invoiceSlack.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {slackChannelName.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={invoiceSlack.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleFeatureChange}
                    value="CPU Monitoring"
                    checked={features.indexOf('CPU Monitoring') > -1}
                  />
                </TableCell>
                <TableCell
                // className="resourceNames"
                >
                  CPU Monitoring
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {cpuMonitoringMail.length
                            ? cpuMonitoringMail.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={cpuMonitoringMail}
                        onChange={handleChangeCPUMonitoringMail}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setCPUMonitoringMail(
                                      cpuMonitoringMail.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setCPUMonitoringMail(
                                              cpuMonitoringMail.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {usersEmail.map((email) => (
                          <MenuItem key={email} value={email}>
                            <Checkbox
                              checked={cpuMonitoringMail.indexOf(email) > -1}
                            />
                            <ListItemText primary={email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {cpuMonitoringSlack.length
                            ? cpuMonitoringSlack.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={cpuMonitoringSlack}
                        onChange={handleChangeCPUMonitoringSlack}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setCPUMonitoringSlack(
                                      cpuMonitoringSlack.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          size="small"
                                          key={value}
                                          label={value}
                                          onDelete={() =>
                                            setCPUMonitoringSlack(
                                              cpuMonitoringSlack.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {slackChannelName.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={cpuMonitoringSlack.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleFeatureChange}
                    value="Security Scan"
                    checked={features.indexOf('Security Scan') > -1}
                  />
                </TableCell>
                <TableCell
                // className="resourceNames"
                >
                  Security Scan
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      arrow
                      title={
                        <div className="tooltipFont">
                          {securityScanMail.length
                            ? securityScanMail.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={securityScanMail}
                        onChange={handleChangeSecurityScanMail}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setSecurityScanMail(
                                      securityScanMail.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setSecurityScanMail(
                                              securityScanMail.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {usersEmail.map((email) => (
                          <MenuItem key={email} value={email}>
                            <Checkbox
                              checked={securityScanMail.indexOf(email) > -1}
                            />
                            <ListItemText primary={email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      title={
                        <div className="tooltipFont">
                          {securityScanSlack.length
                            ? securityScanSlack.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={securityScanSlack}
                        onChange={handleChangeSecurityScanSlack}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setSecurityScanSlack(
                                      securityScanSlack.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setSecurityScanSlack(
                                              securityScanSlack.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {slackChannelName.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={securityScanSlack.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleFeatureChange}
                    value="Cost Exceeded"
                    checked={features.indexOf('Cost Exceeded') > -1}
                  />
                </TableCell>
                <TableCell
                // className="resourceNames"
                >
                  Cost Exceeded
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      title={
                        <div className="tooltipFont">
                          {costExceededMail.length
                            ? costExceededMail.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={costExceededMail}
                        onChange={handleChangeCostExceededMail}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setCostExceededMail(
                                      costExceededMail.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setCostExceededMail(
                                              costExceededMail.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {usersEmail.map((email) => (
                          <MenuItem key={email} value={email}>
                            <Checkbox
                              checked={costExceededMail.indexOf(email) > -1}
                            />
                            <ListItemText primary={email} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Tooltip
                      title={
                        <div className="tooltipFont">
                          {costExceededSlack.length
                            ? costExceededSlack.join(', ')
                            : 'Please select'}
                        </div>
                      }
                    >
                      <Select
                        className="w-75"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={costExceededSlack}
                        onChange={handleChangeCostExceededSlack}
                        input={<OutlinedInput />}
                        renderValue={(selected: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.length <= 2 ? (
                              selected.map((value: any) => (
                                <Chip
                                  onDelete={() =>
                                    setCostExceededSlack(
                                      costExceededSlack.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  size="small"
                                  key={value}
                                  label={value}
                                />
                              ))
                            ) : (
                              <div className="selectOuter">
                                <div>
                                  {selected.map((value: any, i: number) => {
                                    if (i >= 2) return;
                                    return (
                                      <div className="mb-5">
                                        <Chip
                                          onDelete={() =>
                                            setCostExceededSlack(
                                              costExceededSlack.filter(
                                                (item: any) => item !== value
                                              )
                                            )
                                          }
                                          deleteIcon={
                                            <CancelIcon
                                              onMouseDown={(event: any) =>
                                                event.stopPropagation()
                                              }
                                            />
                                          }
                                          size="small"
                                          key={value}
                                          label={value}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="extraFields">
                                  {' '}
                                  + {selected.length - 2}
                                </div>
                              </div>
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {slackChannelName.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={costExceededSlack.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="mt-25 submitBtn">
        <div>
          <button
            type="button"
            className="btn btn-primary ml-10"
            onClick={() => {
              submit(), companyNotification();
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onClose={onCloseDeleteModal}
          metaData={GlobalDelete[deleteState].metaData}
          deleteFunction={GlobalDelete[deleteState].deleteFunction}
        />
      )}
      {slackCredsModal && (
        <SlackCredsModal
          onSubmit={submitSlackCreds}
          show={slackCredsModal}
          onClose={onCloseSlackModal}
        />
      )}
      {GchatModal && (
        <GchatCredsModal
          onSubmit={submitGchatCreds}
          show={GchatModal}
          onClose={onCloseGchatModal}
        />
      )}
      {editSlackModal && (
        <EditSlackCreds
          onUpdate={updateSlackCreds}
          show={editSlackModal}
          onClose={onCloseEditSlackModal}
          editedData={editedData}
        />
      )}
      {editGchatModal && (
        <EditGchatCreds
          onUpdate={updateGchatCreds}
          show={editGchatModal}
          onClose={onCloseEditGchatModal}
          editedData={gchatDataToBeEdited}
        />
      )}
      {showAddEmail && (
        <AddEmail
          show={showAddEmail}
          onClose={onCloseAddEmailModal}
          onSubmit={UptimeMonitoringAddEmails}
        />
      )}
    </>
  );
};

export default React.memo(NotificationManagement);
