export const formattedRightSizingData = (res: any) =>
  Object.values(res).reduce(
    (acc: any, dataPoints: any) => {
      dataPoints.map((eachAccount: any) => {
        acc[0].rightsizing.push(...eachAccount[0]['rightsizing']);
        acc[1].previousGeneration.push(...eachAccount[1]['previousGeneration']);
      });
      return acc;
    },
    [{ rightsizing: [] }, { previousGeneration: [] }]
  );
