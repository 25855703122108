import { HttpService } from '../HttpService/HttpService';
import { APIS } from '../../utils/apiConfig';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

export class InvoiceService {
  constructor(private httpService: HttpService) {}

  async CreateInvoice(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/invoice/createInvoice',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async InvoiceHistory(
    subscriptionId: any,
    cloud: string,
    filterDataOnAzureId: boolean
  ): Promise<any> {
    try {
      let response: any;

      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get('/invoice/invoiceHistory', {
          params: {
            ...(subscriptionId !== 'all'
              ? { selectedAccounts: subscriptionId }
              : {}),
          },
        });
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        response = await this.httpService.get('/invoice/invoiceHistory', {
          params: {
            ...(!filterDataOnAzureId
              ? { SubscriptionId: subscriptionId }
              : { AzureId: subscriptionId }),
          },
        });
      }
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }

    // params =
    //   params.SubscriptionId === 'all'
    //     ? {}
    //     : { SubscriptionId: params.SubscriptionId };
    // try {
    //   const response = await this.httpService.get('/invoice/invoiceHistory', {
    //     params,
    //   });
    //   const { data } = response;
    //   if (data.sc == 1) {
    //     return data.result;
    //   }
    // } catch (e) {
    //   throw e;
    // }
  }

  async DownloadInvoice(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/invoice/downloadInvoice',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async ScheduledInvoices(
    subscriptionId: any,
    cloud: string,
    filterDataOnAzureId: boolean
  ): Promise<any> {
    try {
      let response: any;

      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get('/invoice/scheduledInvoices', {
          params: {
            ...(subscriptionId !== 'all'
              ? { selectedAccounts: subscriptionId }
              : {}),
          },
        });
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        response = await this.httpService.get('/invoice/scheduledInvoices', {
          params: {
            ...(!filterDataOnAzureId
              ? { subscriptionId }
              : { AzureId: subscriptionId }),
          },
        });
      }

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async ScheduleInvoice(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/invoice/createScheduleInvoice',
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async EditScheduledInvoice(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/invoice/editScheduledInvoice',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async RemoveScheduledInvoice(params: any): Promise<any> {
    try {
      const response = await this.httpService.delete(
        '/invoice/removeScheduledInvoice',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async billingCostByTag(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.GET_BILLING_BY_TAGS(params),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getResourcesCost(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.GET_RESOURCES_COST(params),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getTags(
    subscriptionId: any,
    cloud: string,
    filterDataOnAzureId: boolean
  ): Promise<any> {
    try {
      let response: any;

      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get('/awsTagging/getAllTags', {
          params: {
            ...(subscriptionId !== 'all'
              ? { selectedAccounts: subscriptionId }
              : {}),
          },
        });
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        response = await this.httpService.get('/azureTagging/getAllTags', {
          params: {
            ...(!filterDataOnAzureId
              ? { subscriptionId }
              : { azureId: subscriptionId }),
          },
        });
      }

      const { data }: any = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
