import React, { ReactNode, Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Tooltip,
  Tabs,
  Tab,
} from '@material-ui/core';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationModal } from '../../components';
import { emitter } from '../../Socket';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { rightSizingService } from '../../services';
import {
  RightSizingProps,
  RightSizingArrayProps,
} from '../../schemas/rightsizing.schema';
import { ConfigureCheckModal } from '../../components/Modals/ConfigureCheckModal/ConfigureCheckModal';
import './RightSizing.css';
import { useHistory } from 'react-router';
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons';
import { RequestFixModal } from '../../components';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { listener } from '../../Socket';

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../store/index';
// import { Link } from 'react-router-dom';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import {
  CLOUD_PROVIDER_SERVICES,
  PERMISSION_KEY,
  PERMISSION_LEVELS,
} from '../../utils/constants';
import { costFormatter2, handleError } from '../../utils/config';
const storageKeys = {
  RIGHT_SIZING_ROWS_PER_PAGE: 'Right_Sizing_ROWS_PER_PAGE',
  PREV_GEN_ROWS_PER_PAGE: 'PREV_GEN_ROWS_PER_PAGE,',
  RIGHT_SIZING_PAGE_NO: 'RIGHT_SIZING_PAGE_NO',
  PREV_GEN_PAGE_NO: 'PREV_GEN_PAGE_NO',
  RIGHTSIZING_SELECTED_SUBSCRIPTION: 'RIGHTSIZING_SELECTED_SUBSCRIPTION',
  RIGHTSIZING_SELECTED_AZUREID: 'RIGHTSIZING_SELECTED_AZUREID',
};
import { formattedRightSizingData } from './config';

/*-----Redirect to 403 page---------- */

import { FEATURES } from '../../utils/constants';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { ALL_VALUE } from '../Invoice/config';
import { formatDate } from '../../components/UrlMonitoring/config';

const getRightSizingRowsPerPage = () => {
  const rowsPerPage =
    localStorage.getItem(storageKeys.RIGHT_SIZING_ROWS_PER_PAGE) || 30;
  return +rowsPerPage;
};

const _setRightSizingRowsPerPage = (value: string) => {
  localStorage.setItem(storageKeys.RIGHT_SIZING_ROWS_PER_PAGE, value);
};

const getPrevGenRowsPerPage = () => {
  const rowsPerPage =
    localStorage.getItem(storageKeys.PREV_GEN_ROWS_PER_PAGE) || 30;
  return +rowsPerPage;
};

const _setPrevGenRowsPerPage = (value: string) => {
  localStorage.setItem(storageKeys.PREV_GEN_ROWS_PER_PAGE, value);
};

const getRightSizingPageNo = () => {
  const pageNo = localStorage.getItem(storageKeys.RIGHT_SIZING_PAGE_NO) || 0;
  return +pageNo;
};

const setRightSizingPageNo = (value: string) => {
  localStorage.setItem(storageKeys.RIGHT_SIZING_PAGE_NO, value);
};

const getPrevGenPageNo = () => {
  const pageNo = localStorage.getItem(storageKeys.PREV_GEN_PAGE_NO) || 0;
  return +pageNo;
};

const setPrevGenPageNo = (value: string) => {
  localStorage.setItem(storageKeys.PREV_GEN_PAGE_NO, value);
};

export const getSelectedSubscription = (): string => {
  return (
    localStorage.getItem(storageKeys.RIGHTSIZING_SELECTED_SUBSCRIPTION) || 'all'
  );
};
export const getSelectedAzureId = (): string => {
  return (
    localStorage.getItem(storageKeys.RIGHTSIZING_SELECTED_AZUREID) || 'all'
  );
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.RIGHTSIZING_SELECTED_SUBSCRIPTION, value);
};
export const setLocalSelectedAzureId = (value: string): void => {
  localStorage.setItem(storageKeys.RIGHTSIZING_SELECTED_AZUREID, value);
};

interface NotificationState {
  children?: ReactNode;
  nState?: any;
}

export const RightSizing: React.FC<NotificationState> = () => {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [isRightSizingList, setIsRightSizingList] = useState(false);
  // const { user, selectedAccount } = useUserData();

  const user = useSelector(userState).userData;
  const actionPermission = user?.Role?.permissions;
  const rightSizingPermission = actionPermission?.find(
    (obj: any) => obj.Entity.Key === PERMISSION_KEY.RightSizing
  );
  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = selectedAccountInfo.selectedAccount;
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider;
  const [allSizingData, setAllSizingData] = React.useState<
    RightSizingArrayProps[] | any[]
  >([{ rightsizing: [] }, { previousGeneration: [] }]);
  const [openConfigureCheckModal, setConfigureCheckModal] = React.useState(
    false
  );
  const [isRowDisabled, setRowDisable] = React.useState(true);
  const [rightSizingPage, setRightSizingPage] = React.useState(
    getRightSizingPageNo
  );
  const [rightSizingRowsPerPage, setRightSizingRowsPerPage] = React.useState(
    getRightSizingRowsPerPage
  );
  const [prevGenPage, setPrevGenPage] = React.useState(getPrevGenPageNo);
  const [prevGenRowsPerPage, setPrevGenRowsPerPage] = React.useState(
    getPrevGenRowsPerPage
  );
  const [selectedInstance, setSelectedInstance] = React.useState({
    id: '',
    resGrp: '',
    subscriptionId: '',
    instanceId: '',
  });
  const [openRequestFixModal, setOpenRequestFixModal] = React.useState(false);
  const [openRequestFixModalPG, setOpenRequestFixModalPG] = React.useState(
    false
  );
  const [openRequestFixModalRM, setOpenRequestFixModalRM] = React.useState(
    false
  );
  const [fixModalData, setFixModalData] = React.useState([]);
  const [openNotificationModal, setOpenNotificationModal] = React.useState(
    false
  );
  const [networkError, setNetworkError] = React.useState('');
  const [instanceType, setInstanceType] = React.useState('');
  const [resizeDetails, setResizeDetails] = React.useState({
    Id: '',
    RecommendedSize: '',
    ResourceGroup: '',
    SubscriptionId: '',
    VMName: '',
    Type: '',
  });
  const [resizeSuccessMsg, setResizeSuccessMsg] = React.useState('');
  const [resizeErrorMsg, setResizeErrorMsg] = React.useState('');
  const [reservedResourcesData, setReservedResourcesData] = React.useState([]);
  const [isReservedResourceList, setIsReservedResourceList] = useState(false);
  const [isFocused, setIsFocused] = useState<any>(null);
  const [date, setDate] = useState<any>(moment());
  const [isDatePickerVisable, setIsDatePickerVisible] = useState(false);

  const [value, setValue] = React.useState(
    window.location.pathname.split('/')[3]
      ? window.location.pathname.split('/')[3]
      : 'rightsizing'
  );
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );

  /*-----Redirect to 403 page---------- */
  const [pageLoad, setPagDone] = React.useState(false);
  const userData = useSelector(userState);
  const userPlans = userData.userData?.plan.features;

  const handleRightSizingChangePage = (event: unknown, newPage: number) => {
    setRightSizingPageNo(String(newPage));
    setRightSizingPage(newPage);
  };
  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: string
  ) => {
    const company = localStorage.getItem('CompanyName');
    setValue(value);
    history.push(`/${company}/rightsizing/${value}`);
  };
  const handleRightSizingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    _setRightSizingRowsPerPage(event.target.value);
    setRightSizingRowsPerPage(+event.target.value);
    setRightSizingPageNo(String(0));
    setRightSizingPage(0);
  };

  const handlePrevGenChangePage = (event: unknown, newPage: number) => {
    setPrevGenPageNo(String(newPage));
    setPrevGenPage(newPage);
  };

  const handlePrevGenChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    _setPrevGenRowsPerPage(event.target.value);
    setPrevGenRowsPerPage(+event.target.value);
    setPrevGenPageNo(String(0));
    setPrevGenPage(0);
  };

  const getRightSizingData = async () => {
    try {
      setLoader(true);
      const params = {
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };

      const rows = await rightSizingService.getRightSizingDetails(params);

      if (rows) {
        setIsRightSizingList(true);
        const data: any = formattedRightSizingData(rows);
        setUpdatedCurrency(selectedCurrency);
        setAllSizingData(data);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getReservedResources = async () => {
    try {
      setLoader(true);
      const params = {
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };

      const rows = await rightSizingService.getReservedResourcesList(params);

      if (rows) setIsReservedResourceList(true);
      setUpdatedCurrency(selectedCurrency);
      setReservedResourcesData(rows);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const closeCheckModal = () => {
    setConfigureCheckModal(false);
    getRightSizingData();
  };

  const handleConfigureChange = (row: RightSizingProps) => {
    // if (user?.RoleId !== 3) {
    if (row) {
      setSelectedInstance({
        ...selectedInstance,
        id: row.Instance,
        resGrp: row.ResourceGroup,
        subscriptionId: row.SubscriptionId,
        instanceId: row.InstanceId,
      });
      setConfigureCheckModal(true);
      // }
    } else {
      alert("You don't have permission to perform this action");
    }
  };
  const handleRow = async (e: any, row: RightSizingProps) => {
    try {
      if (user?.RoleId !== 3 || 4) {
        setLoader(true);
        const obj =
          selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
            ? {
                Id: row.Id,
                IsDisabled: Number(!row.IsDisabled),
                Service: !row.DBInstanceClass ? 'EC2' : 'RDS',
                ResourceGroup: row.ResourceGroup,
                SubscriptionId: row.SubscriptionId,
              }
            : {
                ResourceName: row.Instance,
                IsDisabled: Number(!row.IsDisabled),
                ResourceGroup: row.ResourceGroup,
                SubscriptionId: row.SubscriptionId,
              };
        const response = await rightSizingService.toggleInstanceState(obj);
        if (response) {
          setRowDisable(!isRowDisabled);
          getRightSizingData();
        }
      } else {
        alert("You don't have permission to perform this action.");
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const requestFixModal = async (e: any, type: any, row: any) => {
    e.stopPropagation();
    try {
      // if (user?.RoleId !== 3) {
      const requestFixType: { [key: string]: string } = {
        rightsizing: 'RightSizing',
        previousGeneration: 'PreviousGeneration',
      };
      const data =
        selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
          ? {
              RequestFixType: requestFixType[type],
              Id: row.Id,
              Service: !row.DBInstanceClass ? 'EC2' : 'RDS',
            }
          : {
              RequestFixType: requestFixType[type],
              ResourceName: row.Instance,
              ResourceGroup: row.ResourceGroup,
              SubscriptionId: row.SubscriptionId,
            };
      const result = await rightSizingService.rightSizingRequestFix(data);

      setFixModalData(result);
      if (type == 'rightsizing') {
        setOpenRequestFixModal(true);
      } else {
        setOpenRequestFixModalPG(true);
      }
      setResizeDetails({
        Id: row.Id,
        SubscriptionId:
          selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
            ? row.SubscriptionId
            : '',
        VMName:
          selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
            ? row.Instance
            : '',
        ResourceGroup:
          selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
            ? row.ResourceGroup
            : '',
        RecommendedSize: row.Recommended,
        Type: type == 'rightsizing' ? 'rightSizing' : 'previousGeneration',
      });
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const requestFixModalRM = async (e: any, row: any) => {
    e.stopPropagation();
    try {
      const data = {
        InstanceType: row.InstanceType,
        Location: row.Location,
        SubscriptionId: row.SubscriptionId,
      };
      const result = await rightSizingService.reserveVM(data);

      setFixModalData(result);

      setOpenRequestFixModalRM(true);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const closeResizeInstanceModal = () => {
    getRightSizingData();
    setResizeSuccessMsg('');
    setResizeErrorMsg('');
    setOpenRequestFixModal(false);
    setOpenRequestFixModalPG(false);
    setOpenRequestFixModalRM(false);
  };

  const resizeInstanceModal = async () => {
    setLoader(true);
    try {
      const result = await rightSizingService.resizeInstance(resizeDetails);
      setResizeErrorMsg('');
      setResizeSuccessMsg(result.message);
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        setResizeSuccessMsg('');
        setResizeErrorMsg(e.error.message);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (resizeSuccessMsg) {
        closeResizeInstanceModal();
      }
    }, 1000);
  }, [resizeSuccessMsg]);

  useEffect(() => {
    setLoader(true);
    setPagDone(false);
    setTimeout(() => {
      const name = userPlans?.includes(FEATURES.RIGHTSIZING);
      if (name) {
        setPagDone(true);
        setLoader(false);
      }
    }, 1000);
  }, [userPlans]);

  const closeModal = () => {
    setOpenNotificationModal(false);
  };

  useEffect(() => {
    if (value === 'reserved') getReservedResources();
    else getRightSizingData();
  }, [
    selectedAccountId,
    selectedSubscriptionId,
    selectedAccount,
    selectedCurrency,
  ]);

  useEffect(() => {
    if (selectedAccount) {
      selectedAccount.CloudProvider.toUpperCase() === 'AZURE'
        ? emitter('azureId', selectedAccount?.AzureId)
        : selectedAccount.CloudProvider.toUpperCase() === 'AWS'
        ? emitter('azureId', selectedAccount?.AccountId)
        : emitter('azureId', selectedAccount?.AccountId);
    }
  }, [resizeSuccessMsg]);

  listener('VM Resized', () => {
    if (value !== 'reserved') getRightSizingData();
  });

  if (networkError) {
    throw new Error(networkError);
  }

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  const fetchPreviousRightsizedData = async (selectedDate: any) => {
    try {
      setLoader(true);
      const endDate: any = moment(selectedDate).add(1, 'days');
      const params = {
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        startDate: formatDate(selectedDate?._d),
        endDate: formatDate(endDate?._d),
      };

      const rows = await rightSizingService.getPrevoiusRightSizingDetails(
        params
      );

      setAllSizingData([
        { rightsizing: rows || [] },
        { previousGeneration: [] },
      ]);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };
  //Date Picker section

  const onDateChange = (selectedDate: any) => {
    if (selectedDate) {
      fetchPreviousRightsizedData(selectedDate);
      setDate(selectedDate);
    }
  };

  const currDate: any = moment();

  return (
    <Fragment>
      {loader ? <LoadingScreen /> : ''}

      <>
        <div className="rsTableHeader flex flex-justifySpaceBetween">
          <Grid item md={12} sm={12} xs={12}>
            <Tabs
              selectionFollowsFocus
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
              className="tabRightsizingHeading"
            >
              <Tab label="Rightsizing" value="rightsizing" />

              <Tab
                label="Previous Generation"
                value="previousGeneration"
                className="prevGenTab"
              />
              <Tab label="Reserved Resources List" value="reserved" />
            </Tabs>
          </Grid>

          {/* id selection */}

          <span className="mt-10 ml-10">
            <SelectedAccount onAccountChange={onAccountChange} />
          </span>
        </div>
        {value === 'rightsizing' ? (
          <Grid className="flex flex-justifySpaceBetween ">
            {' '}
            <span className="inventoryHeading font-22px fw-300">
              {formatDate(date?._d) === formatDate(currDate?._d)
                ? null
                : `Recommendations - ${formatDate(date?._d)}`}
            </span>
            {value === 'rightsizing' ? (
              <>
                <span>
                  {isDatePickerVisable ? (
                    <>
                      {formatDate(date?._d) !== formatDate(currDate?._d) ? (
                        <div
                          className="btn btn-primary mr-10"
                          onClick={() => {
                            getRightSizingData();
                            setDate(moment());
                            setIsDatePickerVisible(false);
                          }}
                        >
                          View Today's Recommendations
                        </div>
                      ) : null}

                      <span className="w-15 mr-05  inventoryHeading font-15px fw-500">
                        Select Date:-{' '}
                      </span>
                      <span>
                        {' '}
                        <SingleDatePicker
                          numberOfMonths={1}
                          anchorDirection="right"
                          isDayBlocked={(day: any) =>
                            // new Date().getHours() < 12
                            //   ? day >= moment().add(1, 'days')
                            day >= moment().subtract(1, 'day')
                          }
                          isOutsideRange={() => false}
                          hideKeyboardShortcutsPanel={false}
                          date={date}
                          onDateChange={onDateChange}
                          focused={isFocused}
                          onFocusChange={(focusedInput: any) => {
                            setIsFocused(focusedInput.focused);
                          }}
                          displayFormat="YYYY/MM/DD"
                          id="your_unique_id"
                        />
                      </span>
                    </>
                  ) : (
                    <div
                      className="btn btn-primary mb-10"
                      onClick={() => setIsDatePickerVisible(true)}
                    >
                      View Previous Recommendations
                    </div>
                  )}
                </span>
              </>
            ) : null}
          </Grid>
        ) : null}
        {pageLoad ? (
          <>
            {allSizingData.map((data: RightSizingArrayProps, index: any) => {
              if (Object.keys(data)[0] === value) {
                return (
                  <div key={'szdata' + index}>
                    {
                      <Grid item md={12}>
                        <TableContainer className="accContainer">
                          <Table
                            className="table adminTable adminPanelTable"
                            size="small"
                            aria-label="a dense table"
                            stickyHeader
                          >
                            <TableHead className="admintableHead">
                              <TableRow>
                                <TableCell
                                  className="adminTableheading"
                                  style={{ minWidth: '30px' }}
                                ></TableCell>
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AZURE' ? (
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    VM Name
                                  </TableCell>
                                ) : selectedAccount?.CloudProvider.toUpperCase() ===
                                  'AWS' ? (
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    Resource Name
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    vm Name
                                  </TableCell>
                                )}
                                <TableCell
                                  className=" adminTableheading"
                                  style={{ minWidth: '30px' }}
                                >
                                  Resource Type
                                </TableCell>
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AZURE' ? null : selectedAccount?.CloudProvider.toUpperCase() ===
                                  'AWS' ? (
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    Resource Id
                                  </TableCell>
                                ) : null}
                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Current Monthly Cost
                                </TableCell>
                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Recommended Monthly Cost
                                </TableCell>
                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Possible Saving/Expense
                                </TableCell>
                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Platform/Engine
                                </TableCell>

                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  {selectedAccount?.CloudProvider.toUpperCase() ===
                                  'AZURE'
                                    ? 'VM Type'
                                    : selectedAccount?.CloudProvider.toUpperCase() ===
                                      'AWS'
                                    ? 'Instance Type'
                                    : 'VM Type'}
                                  <br />
                                  <span className="fw-400 font-11px">
                                    (Size)
                                  </span>
                                </TableCell>
                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Recommended Class
                                </TableCell>
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AZURE' ? (
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    Resource Group
                                  </TableCell>
                                ) : null}
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AZURE' ? (
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    Subscription Name
                                  </TableCell>
                                ) : null}
                                {Object.keys(data)[0] !==
                                'previousGeneration' ? (
                                  <React.Fragment>
                                    <TableCell
                                      className=" adminTableheading"
                                      align="center"
                                    >
                                      Avg CPU
                                      <br />
                                      <span className="fw-400 font-11px">
                                        (%)
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className=" adminTableheading"
                                      align="center"
                                    >
                                      Avg Network In
                                      <br />
                                      <span className="fw-400 font-11px">
                                        (In Bytes)
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className=" adminTableheading"
                                      align="center"
                                    >
                                      Avg Network Out
                                      <br />
                                      <span className="fw-400 font-11px">
                                        (In Bytes)
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className=" adminTableheading"
                                      align="center"
                                    >
                                      Peak CPU
                                      <br />
                                      <span className="fw-400 font-11px">
                                        (%)
                                      </span>
                                    </TableCell>
                                  </React.Fragment>
                                ) : null}
                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Status
                                </TableCell>

                                <TableCell
                                  className=" adminTableheading"
                                  align="center"
                                >
                                  Availability Zone
                                </TableCell>
                                <ProtectedComponent
                                  level={PERMISSION_LEVELS.WRITE}
                                  permission={rightSizingPermission}
                                >
                                  <TableCell
                                    className=" adminTableheading"
                                    align="center"
                                  >
                                    Actions
                                  </TableCell>
                                </ProtectedComponent>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {Object.entries(data).map(
                                ([key, sizingData], i) =>
                                  sizingData && sizingData.length ? (
                                    sizingData
                                      .slice(
                                        key === 'rightsizing'
                                          ? rightSizingPage *
                                              rightSizingRowsPerPage
                                          : prevGenPage * prevGenRowsPerPage,
                                        key === 'rightsizing'
                                          ? rightSizingPage *
                                              rightSizingRowsPerPage +
                                              rightSizingRowsPerPage
                                          : prevGenPage * prevGenRowsPerPage +
                                              prevGenRowsPerPage
                                      )
                                      ?.map((row: RightSizingProps) => {
                                        const instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/ec2?region=${row.Region}#InstanceDetails:instanceId=${row.InstanceId}`;
                                        const instanceAzureURL = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${row.SubscriptionId}/resourceGroups/${row.ResourceGroup}/providers/Microsoft.Compute/virtualMachines/${row.Instance}/overview`;

                                        return (
                                          <TableRow
                                            key={row.Id}
                                            className={`accDetailRow ${
                                              row.IsDisabled
                                                ? 'disabledRow'
                                                : ''
                                            }`}
                                          >
                                            <TableCell
                                              // component="th"
                                              key={i}
                                              scope="row"
                                              style={{ minWidth: '30px' }}
                                            >
                                              {row.Type === 0 ? (
                                                <FontAwesomeIcon
                                                  icon={faLongArrowAltDown}
                                                  color="green"
                                                />
                                              ) : row.Type === 1 ? (
                                                <FontAwesomeIcon
                                                  icon={faLongArrowAltUp}
                                                  color="red"
                                                />
                                              ) : null}
                                            </TableCell>
                                            <TableCell
                                              // component="th"
                                              align="center"
                                              scope="row"
                                            >
                                              {selectedAccount?.CloudProvider.toUpperCase() ===
                                              'AZURE' ? (
                                                <>
                                                  {row.Instance ? (
                                                    <a
                                                      href={instanceAzureURL}
                                                      target="_blank"
                                                      className="inventoryTableCellLink decore"
                                                    >
                                                      {row.Instance}
                                                    </a>
                                                  ) : (
                                                    '-'
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <a
                                                    href={instanceURLS}
                                                    target="_blank"
                                                    className="inventoryTableCellLink decore"
                                                  >
                                                    {row.Instance ||
                                                      row.DBInstanceIdentifier ||
                                                      '-'}
                                                  </a>
                                                </>
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              scope="row"
                                            >
                                              {selectedAccount?.CloudProvider.toUpperCase() ===
                                              'AZURE'
                                                ? 'VM'
                                                : 'EC2'}
                                            </TableCell>
                                            {selectedAccount?.CloudProvider.toUpperCase() ===
                                            'AZURE' ? null : (
                                              <TableCell
                                                align="center"
                                                // component="th"
                                                scope="row"
                                              >
                                                {row.InstanceId ||
                                                  row.DBInstanceId ||
                                                  '-'}
                                              </TableCell>
                                            )}
                                            <TableCell align="center">
                                              {(row.CurrentHourlyCost &&
                                                costFormatter2(
                                                  updatedCurrency,
                                                  row.CurrentHourlyCost
                                                )) ||
                                                costFormatter2(
                                                  updatedCurrency,
                                                  row.CurrentDBClassMonthlyCost
                                                ) ||
                                                '-'}
                                            </TableCell>
                                            <TableCell align="center">
                                              {(row.ProjectedHourlyCost &&
                                                costFormatter2(
                                                  updatedCurrency,
                                                  row.ProjectedHourlyCost
                                                )) ||
                                                costFormatter2(
                                                  updatedCurrency,
                                                  row.RecommendedDBClassMonthlyCost
                                                ) ||
                                                '-'}
                                            </TableCell>
                                            {row.CurrencyCode ? (
                                              <TableCell align="center">
                                                {row.Type === 0 ? (
                                                  <FontAwesomeIcon
                                                    icon={faLongArrowAltDown}
                                                    color="green"
                                                  />
                                                ) : row.Type === 1 ? (
                                                  <FontAwesomeIcon
                                                    icon={faLongArrowAltUp}
                                                    color="red"
                                                  />
                                                ) : null}{' '}
                                                {costFormatter2(
                                                  updatedCurrency,
                                                  row.PossibleHourlySavings ||
                                                    row.PosibleSavings
                                                )}
                                              </TableCell>
                                            ) : (
                                              <TableCell align="center">
                                                {costFormatter2(
                                                  updatedCurrency,
                                                  row.PosibleSavings
                                                )}
                                              </TableCell>
                                            )}

                                            <TableCell align="center">
                                              {row.Platform ||
                                                row.Engine ||
                                                '-'}
                                            </TableCell>
                                            <TableCell align="center">
                                              {row.InstanceType ||
                                                row.DBInstanceClass ||
                                                '-'}
                                            </TableCell>
                                            <TableCell align="center">
                                              {row.Recommended ||
                                                row.RecommendedDBInstanceClass ||
                                                '-'}
                                            </TableCell>
                                            {selectedAccount?.CloudProvider.toUpperCase() ===
                                            'AZURE' ? (
                                              <TableCell align="center">
                                                {row.ResourceGroup
                                                  ? row.ResourceGroup
                                                  : '-'}
                                              </TableCell>
                                            ) : null}
                                            {selectedAccount?.CloudProvider.toUpperCase() ===
                                            'AZURE' ? (
                                              <TableCell align="center">
                                                {row.SubscriptionName
                                                  ? row.SubscriptionName
                                                  : '-'}
                                              </TableCell>
                                            ) : null}
                                            {Object.keys(data)[0] !==
                                            'previousGeneration' ? (
                                              <React.Fragment>
                                                <TableCell align="center">
                                                  {Number(
                                                    row.AvgCPU || row.avg || 0
                                                  ).toFixed(2) || '-'}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {row.AvgNetworkIn ||
                                                  row.AvgNetworkIn == '0'
                                                    ? Number(row.AvgNetworkIn)
                                                        .toFixed(2)
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ','
                                                        )
                                                    : '-'}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {row.AvgNetworkOut ||
                                                  row.AvgNetworkOut == '0'
                                                    ? Number(row.AvgNetworkOut)
                                                        .toFixed(2)
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ','
                                                        )
                                                    : '-'}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {Number(
                                                    row.PeakCPU ||
                                                      row.Maximum ||
                                                      0
                                                  ).toFixed(2)}
                                                </TableCell>
                                              </React.Fragment>
                                            ) : null}
                                            <TableCell align="center">
                                              {row.Status ? row.Status : '-'}
                                            </TableCell>

                                            <TableCell align="center">
                                              {row.AvailabilityZone || '-'}
                                            </TableCell>
                                            <ProtectedComponent
                                              level={PERMISSION_LEVELS.WRITE}
                                              permission={rightSizingPermission}
                                            >
                                              {row.CurrencyCode ? (
                                                <TableCell align="right">
                                                  <div
                                                    className={'d-flex float-r'}
                                                  >
                                                    {row.Type == 1 && (
                                                      <Tooltip
                                                        title={`${
                                                          row.IsDisabled
                                                            ? ''
                                                            : 'Configure'
                                                        }`}
                                                        arrow
                                                      >
                                                        <span>
                                                          <FontAwesomeIcon
                                                            data-for="fix"
                                                            icon={faCog}
                                                            className={`actionIcons ${
                                                              row.IsDisabled
                                                                ? 'disabledIcons'
                                                                : null
                                                            }`}
                                                            onClick={() =>
                                                              !row.IsDisabled &&
                                                              handleConfigureChange(
                                                                row
                                                              )
                                                            }
                                                          />
                                                        </span>
                                                      </Tooltip>
                                                    )}

                                                    <Tooltip
                                                      title="Request Fix"
                                                      arrow
                                                      disableHoverListener={
                                                        row.IsDisabled
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      <span
                                                        className={`configIconRS ${
                                                          row.IsDisabled
                                                            ? ''
                                                            : 'actionIcons'
                                                        }`}
                                                        onClick={
                                                          row.IsDisabled
                                                            ? undefined
                                                            : (e) =>
                                                                requestFixModal(
                                                                  e,
                                                                  Object.keys(
                                                                    data
                                                                  )[0],
                                                                  row
                                                                )
                                                        }
                                                      ></span>
                                                    </Tooltip>

                                                    {Object.keys(data)[0] ===
                                                    'rightsizing' ? (
                                                      <Tooltip
                                                        title={`${
                                                          row.IsDisabled
                                                            ? 'Revive'
                                                            : 'Ignore'
                                                        }`}
                                                        arrow
                                                      >
                                                        <span>
                                                          {row.IsDisabled ? (
                                                            <VerifiedUserIcon
                                                              className="actionIcons"
                                                              onClick={(e) =>
                                                                handleRow(
                                                                  e,
                                                                  row
                                                                )
                                                              }
                                                            />
                                                          ) : (
                                                            <VerifiedUserOutlinedIcon
                                                              className="actionIcons"
                                                              onClick={(e) =>
                                                                handleRow(
                                                                  e,
                                                                  row
                                                                )
                                                              }
                                                            />
                                                          )}
                                                        </span>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                </TableCell>
                                              ) : (
                                                <TableCell align="center">
                                                  -
                                                </TableCell>
                                              )}
                                            </ProtectedComponent>
                                          </TableRow>
                                        );
                                      })
                                  ) : !isRightSizingList || loader ? (
                                    <TableRow key={key + 'No Record'}>
                                      <TableCell
                                        className="{helperClasses.styleCenter}"
                                        colSpan={20}
                                      >
                                        Loading...
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <TableRow key={key + 'No Record'}>
                                      <TableCell
                                        className="{helperClasses.styleCenter}"
                                        colSpan={20}
                                      >
                                        No records found
                                      </TableCell>
                                    </TableRow>
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {Object.entries(data).map(([key, sizingData], index) =>
                          sizingData && sizingData.length <= 0 ? null : (
                            <TablePagination
                              key={'page' + key + index}
                              rowsPerPageOptions={[10, 20, 30]}
                              component="div"
                              count={sizingData.length}
                              rowsPerPage={
                                key === 'rightsizing'
                                  ? rightSizingRowsPerPage
                                  : prevGenRowsPerPage
                              }
                              page={
                                key === 'rightsizing'
                                  ? rightSizingPage
                                  : prevGenPage
                              }
                              onChangePage={
                                key === 'rightsizing'
                                  ? handleRightSizingChangePage
                                  : handlePrevGenChangePage
                              }
                              onChangeRowsPerPage={
                                key === 'rightsizing'
                                  ? handleRightSizingChangeRowsPerPage
                                  : handlePrevGenChangeRowsPerPage
                              }
                              className="tablePaginaion"
                            />
                          )
                        )}
                      </Grid>
                    }
                  </div>
                );
              }
            })}

            {value === 'reserved' && (
              <>
                {selectedAccount?.CloudProvider ? (
                  <TableContainer className="accContainer">
                    <Table
                      className="table adminTable adminPanelTable"
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="admintableHead">
                        <TableRow>
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Virtual Machine
                            </TableCell>
                          ) : selectedAccount?.CloudProvider.toUpperCase() ===
                            'AWS' ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Instance Size
                            </TableCell>
                          ) : (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Virtual Machine
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Reservation Type
                            </TableCell>
                          ) : null}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Subscription Name
                            </TableCell>
                          ) : null}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AWS' ? (
                            <TableCell
                              className="adminTableheading"
                              align="left"
                            >
                              Platform
                            </TableCell>
                          ) : null}
                          <TableCell className="adminTableheading" align="left">
                            Location
                          </TableCell>
                          <TableCell
                            className="adminTableheading"
                            align="center"
                          >
                            Quantity
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Triennial Monthly Savings
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Triennial Savings
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Yearly Monthly Savings
                          </TableCell>
                          <TableCell className="adminTableheading" align="left">
                            Yearly Savings
                          </TableCell>
                          <ProtectedComponent
                            level={PERMISSION_LEVELS.WRITE}
                            permission={rightSizingPermission}
                          >
                            <TableCell
                              className="adminTableheading"
                              align="right"
                            >
                              Actions
                            </TableCell>
                          </ProtectedComponent>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservedResourcesData.length ? (
                          reservedResourcesData.map((row: any) => {
                            return (
                              <TableRow key={row.Id}>
                                <TableCell align="left">
                                  {row.InstanceType}
                                </TableCell>
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AZURE' ? (
                                  <TableCell align="left">
                                    {row.ReservationType}
                                  </TableCell>
                                ) : null}
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AZURE' ? (
                                  <TableCell align="left">
                                    {row.SubscriptionName}
                                  </TableCell>
                                ) : null}
                                {selectedAccount?.CloudProvider.toUpperCase() ===
                                'AWS' ? (
                                  <TableCell align="left">
                                    {row.Platform}
                                  </TableCell>
                                ) : null}
                                <TableCell align="left">
                                  {row.Location}
                                </TableCell>
                                <TableCell align="center">
                                  {row.Quantity}
                                </TableCell>
                                <TableCell align="left">
                                  {costFormatter2(
                                    updatedCurrency,
                                    row.TriennialMonthlySavings
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {costFormatter2(
                                    updatedCurrency,
                                    row.TriennialSavings
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {costFormatter2(
                                    updatedCurrency,
                                    row.YearlyMonthlySavings
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {costFormatter2(
                                    updatedCurrency,
                                    row.YearlySavings
                                  )}
                                </TableCell>

                                <ProtectedComponent
                                  level={PERMISSION_LEVELS.WRITE}
                                  permission={rightSizingPermission}
                                >
                                  <TableCell align="right">
                                    <div className="d-flex float-r">
                                      <Tooltip title="Steps" arrow>
                                        <span
                                          className="configIconRS actionIcons"
                                          onClick={(e) =>
                                            requestFixModalRM(e, row)
                                          }
                                        ></span>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </ProtectedComponent>
                              </TableRow>
                            );
                          })
                        ) : !isReservedResourceList || loader ? (
                          <TableRow key="No Record">
                            <TableCell
                              className="{helperClasses.styleCenter}"
                              colSpan={20}
                            >
                              Loading...
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key="No Record">
                            <TableCell
                              className="{helperClasses.styleCenter}"
                              colSpan={20}
                            >
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </>
            )}
          </>
        ) : (
          <></>
        )}

        {openConfigureCheckModal && (
          <ConfigureCheckModal
            show={openConfigureCheckModal}
            onClose={closeCheckModal}
            selectedInstance={selectedInstance}
          />
        )}
        {openRequestFixModal && (
          <RequestFixModal
            show={openRequestFixModal}
            onClose={closeResizeInstanceModal}
            onSubmit={resizeInstanceModal}
            data={fixModalData}
            actionPermission={actionPermission}
            successMsg={resizeSuccessMsg}
            errorMsg={resizeErrorMsg}
            type={'rightsizing'}
          />
        )}
        {openRequestFixModalPG && (
          <RequestFixModal
            show={openRequestFixModalPG}
            onClose={closeResizeInstanceModal}
            onSubmit={resizeInstanceModal}
            data={fixModalData}
            actionPermission={actionPermission}
            successMsg={resizeSuccessMsg}
            errorMsg={resizeErrorMsg}
            type={'previousGeneration'}
          />
        )}
        {openRequestFixModalRM && (
          <RequestFixModal
            show={openRequestFixModalRM}
            onClose={closeResizeInstanceModal}
            data={fixModalData}
            actionPermission={actionPermission}
            type={'reserveResources'}
          />
        )}
        {openNotificationModal && (
          <NotificationModal
            show={openNotificationModal}
            onClose={closeModal}
            iType={instanceType}
            selected={selectedSubscriptionId}
          />
        )}
      </>
    </Fragment>
  );
};

export default React.memo(RightSizing);
