import { idleInstanceService } from '../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

export const config: any = {
  [CLOUD_PROVIDER_SERVICES.AWS]: {
    requestFix: (params: any) => idleInstanceService.requestFix(params),
    getIdleInstanceList: (params: any) =>
      idleInstanceService.getIdleinstanceList(params),
    getIdleInstanceDetails: (params: any) =>
      idleInstanceService.getIdleinstanceDetails(params),
    getUnusedResourceDetails: (type: string, selected: string) =>
      idleInstanceService.getunusedResourceDetails(type, selected),
    toggleInstanceState: (params: any) =>
      idleInstanceService.toggleInstanceState(params),
  },
  [CLOUD_PROVIDER_SERVICES.AZURE]: {
    requestFix: (params: any) => idleInstanceService.requestFix(params),
    getIdleInstanceList: (selected: any) =>
      idleInstanceService.getIdleinstanceList(selected),
    getIdleInstanceDetails: (params: any) =>
      idleInstanceService.getIdleinstanceDetails(params),
    getUnusedResourceDetails: (type: string, selected: string) =>
      idleInstanceService.getunusedResourceDetails(type, selected),
    toggleInstanceState: (params: any) =>
      idleInstanceService.toggleInstanceState(params),
  },
};

export const IDLE_RESOURCES_FULL_FORM: { [key: string]: string } = {
  VM: 'Virtual Machine',
  MDisk: 'Managed Disk',
  RG: 'Resource Groups',
  Load: 'Load Balancers',
  App: 'App service plans',
  Container: 'Container Instances',
  Redis: 'Redis cache',
  SBus: 'Service bus',
  TM: 'Traffic manager',
  Sql: 'SQL Server',
  CD: 'COSMOS DB',
  Storage: 'Storage Accounts',
  IP: 'Public IP',
  //AWS service shorthands
  EC2: 'EC2',
  S3: 'S3',
  EFS: 'EFS',
  FSx: 'FSx',
  SG: 'S3 Glacier',
  AWSRG: 'Resource Group',
  AWSLoad: 'Load Balancer',
  Cache: 'ElastiCache',
  ECS: 'Elastic Container Service Instance',
  SQS: 'Simple Queue Service',
  BeanStalk: 'Elastic BeanStalk',
  Workspace: 'WorkSpaces',
  EBS: 'EBS',
  ELasticIP: 'Elastic IP addresses',
  RDS: 'RDS',
  EMR: 'EMR',
  SNS: 'SNS',
  GA: 'Global Accelerator',
  DynamoDB: 'DynamoDB',
  Lambda: 'Lambda',
};

export const HANDLE_SEARCH: { [key: string]: any } = {
  VM: {
    Id: 'VirtualMachineId',
    Name: 'VirtualMachineName',
  },
  MDisk: {
    Id: 'DiskUniqueId',
    Name: 'DiskName',
  },
  RG: {
    Id: 'SubscriptionId',
    Name: 'ResourceGroupName',
  },
  Load: {
    Id: 'ResourceGuid',
    Name: 'LoadBalancerName',
  },
  App: {
    Id: 'SubscriptionName',
    Name: 'AppName',
  },
  Container: {
    Id: 'SubscriptionName',
    Name: 'ContainerInstanceName',
  },
  Redis: {
    Id: '',
    Name: '',
  },
  SBus: {
    Id: 'SubscriptionName',
    Name: 'ServiceBusName',
  },
  TM: {
    Id: 'SubscriptionName',
    Name: 'TrafficManagerName',
  },
  Sql: {
    Id: '',
    Name: '',
  },
  CD: {
    Id: 'SubscriptionName',
    Name: 'CosmosDBName',
  },
  Storage: {
    Id: 'SubscriptionName',
    Name: 'StorageName',
  },
  IP: {
    Id: 'ResourceGuid',
    Name: 'PublicIPName',
  },
  EC2: {
    Id: 'InstanceId',
    Name: 'InstanceName',
  },
  S3: {
    Id: 'Region',
    Name: 'StorageName',
  },
  EFS: {
    Id: 'EFSId',
    Name: 'EFSName',
  },
  FSx: {
    Id: '',
    Name: '',
  },
  SG: {
    Id: '',
    Name: '',
  },
  AWSRG: {
    Id: 'ResourceGroupArn',
    Name: 'ResourceGroupName',
  },
  AWSLoad: {
    Id: 'LoadBalancerArn',
    Name: 'LoadBalancerName',
  },
  Cache: {
    Id: 'CacheClusterId',
    Name: 'CacheNodeType',
  },
  ECS: {
    Id: '',
    Name: '',
  },
  SQS: {
    Id: 'ARN',
    Name: 'SqsName',
  },
  BeanStalk: {
    Id: 'AppArn',
    Name: 'AppName',
  },
  Workspace: {
    Id: 'WorkspaceId',
    Name: 'IpAddress',
  },
  EBS: {
    Id: 'VolumeId',
    Name: 'VolumeType',
  },
  ELasticIP: {
    Id: 'IPAddress',
    Name: 'ElasticIPName',
  },
  RDS: {
    Id: 'DBInstanceArn',
    Name: 'DBName',
  },
  EMR: {
    Id: '',
    Name: '',
  },
  SNS: {
    Id: 'ARN',
    Name: 'SnsName',
  },
  GA: {
    Id: '',
    Name: '',
  },
  DynamoDB: {
    Id: 'DynamoDBId',
    Name: 'DynamoDBName',
  },
};

export const LONGER_DETAIL_FIELD: string[] = [
  'LoadBalancerArn',
  'TargetGroupNames',
  'ResourceGroupArn',
  'Description',
  'DynamoDBId',
  'DynamoDBArn',
  'ARN',
  'FunctionArn',
];

export const SERVICE_ID_NAME: string[] = [
  //AWS
  'WorkspaceId',
  'ResourceGroupName',
  'StorageName',
  'VolumeId',
  'SnsName',
  'DynamoDBName',
  'DBName',
  'SqsName',
  'InstanceId',
  'VirtualMachineId',
  'ElasticIPName',
  'IPAddress',
  'LoadBalancerName',
  'AppArn',
  'CacheClusterId',
  'FunctionName',
  //AZURE
  'CosmosDBName',
  'TrafficManagerName',
  'ServiceBusName',
  'AppName',
  'ResourceGroupName',
  'DiskUniqueId',
  'VirtualMachineId',
  'StorageName',
  'PublicIPName',
];

export const IGNORED_RESOURCE_COLUMN_FIELD: string[] = [
  'Currency_Symbol',
  'IsDisabled',
  'Subscription_Id',
  'Id',
  'Unit',
  'Currency_Code',
  'OrgId',
  'AccountId',
  'Tags',
  'RDSRegion',
  'SQSQueueUrl',
  'Network_Interface_Id',
  'CreatedBy',
  'UpdatedBy',
  'IpAddress',
  'createdAt',
  'updatedAt',
  'UnusedCreatedOn',
  'Type',
  'IsActive',
  'CreatedOn',
  'CurrencyCode',
  'StorageOwner',
  'PredictedMonthlyCost',
  'LastModified',
  'DeleteOnTermination',
  'SubscriptionsPending',
  'SubscriptionsConfirmed',
  'SubscriptionsDeleted',
  'QueueUrl',
];

export const FIELD: { [key: string]: string } = {
  'Virtual Machines': 'Virtual_Machine_Name',
  'Storage Accounts': 'Storage_Name',
  'Managed Disks': 'Disk_Name',
  // 'Managed Disks': 'MDisk',
  'Public IP': 'Public_IP_Name',
  'Load Balancers': 'Load_Balancer_Name',
  'Resource Groups': 'Resource_Group_Name',
  'Global Accelerator': 'Global_Accelerator_Name',
  'App Service Plans': 'App_Name',
  'Container Instances': 'Container_Instance_Name',
  'Redis Cache': 'Redis',
  'Service Bus': 'Service_Bus_Name',
  'Traffic Manager': 'Traffic_Manager_Name',
  'COSMOS DB': 'Cosmos_DB_Name',
  WorkSpaces: 'Workspace_Name',
  'SQL Server': 'SQL_Server_Name',
  'HDInSight Cluster': 'HDCluster_Name',
};

export const TYPE: { [key: string]: string } = {
  'Virtual Machines': 'Virtual_Machine_Id',
  'Storage Accounts': 'Id',
};
