import { appDashboardService } from '../../services';

export const config: any = {
  fetchDashboardRightsizingData: (currency: string) =>
    appDashboardService.getRightsizingData(currency),
  fetchDashboardIdleResourcesData: (currency: string) =>
    appDashboardService.getIdleResourcesData(currency),
  fetchDashboardSpendByServiceData: (currency: string) =>
    appDashboardService.getSpendByServiceData(currency),
  fetchDashboardSpendForecastData: (currency: string) =>
    appDashboardService.getSpendForecastData(currency),
  fetchDashboardAccounts: () => appDashboardService.getAccountsData(),
  fetchDashboardSpendAmount: (currency: string) =>
    appDashboardService.getSpendAmountData(currency),
  fetchDashboardPerChange: () => appDashboardService.getPerChangeData(),
  fetchDashboardNotification: () =>
    appDashboardService.getDashboardNotification(),
};

// export const barColors: string[] = ['#FF9900', '#007FFF'];
export const barColors: string[] = ['#E54454', '#2083DC', '#2083DC'];
export const defaultColor = ['#AAAAAA'];

export const piColorsArray: string[] = [
  '#006400',
  '#008000',
  '#228B22',
  '#3CB371',
  '#90EE90',
  '#98FB98',
  '#ADFF2F',
  '#B0C4DE',
  '#B0E0E6',
  '#B2DFEE',
  '#B3EE3A',
  '#B4EEB4',
  '#B8D1D1',
  '#B8F8B8',
  '#C1CDC1',
  '#C5E3BF',
  '#C9C9C9',
  '#CAFF70',
  '#CCFFCC',
  '#D0F0C0',
  '#D2F8B0',
  '#D3D3D3',
  '#D8F8D8',
  '#E0FFFF',
];

export const piRedColorsArr = [
  '#D00043',
  '#FF6633',
  '#10E5B1',
  '#00B3E6',
  '#3F8473',
  '#FFB399',
  '#7D1D85',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#999999',
  '#80B300',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#6666FF',
  '#809900',
  '#B34D4D',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
];

// export const useStyles = makeStyles((theme: Theme) => ({
//   indicator: {
//     height: '0.5em',
//     width: '2em',
//     borderWidth: '0.2em',
//     borderStyle: 'solid',
//     borderColor: theme.palette.secondary.main,
//     borderRadius: '1em 1em 0 0',
//     transform: 'rotate(45deg)',
//     backgroundColor: theme.palette.secondary.main,
//   },
//   container: {
//     position: 'relative',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//   },
// }));
