import { APIS } from '../../utils/apiConfig';
import { HttpService } from '../HttpService/HttpService';

export class UploadedDocumentsStaticService {
  constructor(private httpService: HttpService) {}

  async getStaticUploadedDocuments(token: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_UPLOADED_DOCUMENTS_STATIC(token),
        {}
      );
      const { data } = response;
      //   if (data.sc == 1) {
      //     const { result } = data;
      //     return result;
      //   }
      return data;
    } catch (e) {
      throw e;
    }
  }
}
