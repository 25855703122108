import { APIS } from '../../utils/apiConfig';
import { GlobalQueryParams } from '../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { HttpService } from '../HttpService/HttpService';

const handleResponse = (response: any) => {
  const { data } = response;
  if (data.sc == 1) {
    return data.result;
  }
};

export class InventoryReportService {
  constructor(private httpService: HttpService) {}

  async getInventoryReportXlsx(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_INVENTORY_REPORT(params),
        {
          params: {
            accountId:
              params.provider.CloudProvider.toUpperCase() ===
              CLOUD_PROVIDER_SERVICES.AWS
                ? params.provider.AccountId
                : params.provider.SubscriptionId,
          },
        }
      );
      return handleResponse(response);
    } catch (e) {
      throw e;
    }
  }
  async getServicesReport(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_SERVICES_REPORTS(params),
        {}
      );
      return handleResponse(response);
    } catch (e) {
      throw e;
    }
  }
  async getServiceResourceCost(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_RESOURCES_SERVICES_REPORTS(params),
        {}
      );
      return handleResponse(response);
    } catch (e) {
      throw e;
    }
  }
  async getServiceWiseCost(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, APIS.GET_SERVICE_WISE_COST()),
        {}
      );

      return handleResponse(response);
    } catch (e) {
      throw e;
    }
  }

  async getServiceReportCsv(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_SERVICE_WISE_REPORT_CSV(),
        {
          params,
        }
      );

      return handleResponse(response);
    } catch (e) {
      throw e;
    }
  }
}
