import { costForecastingService } from '../../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';

export const config: any = {
  [CLOUD_PROVIDER_SERVICES.AWS]: {
    getResourceList: () => costForecastingService.getResourceList(),
    getResourceDetail: (params: any) =>
      costForecastingService.getResourceDetail(params),
    setResourceCost: (params: any) =>
      costForecastingService.setResourceCost(params),
  },
  [CLOUD_PROVIDER_SERVICES.AZURE]: {
    getResourceList: () => costForecastingService.getResourceList(),
    getResourceDetail: (params: any) =>
      costForecastingService.getResourceDetail(params),
    setResourceCost: (params: any) =>
      costForecastingService.setResourceCost(params),
  },
};
