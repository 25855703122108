import React from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import JSONViewer from '../../viewer/json';

export const JSONViewerModal = (props: any) => {
  const { title, payload, styles, onClose } = props;
  return (
    <React.Fragment>
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
        className="fixModal"
        {...props}
      >
        <Grid container spacing={0}>
          <Grid item lg={12} className="modalHeader" style={styles.header || {}}>
            <div>
              <span className="modalTitle">{title}</span>
              <span
                className="modalClose"
                onClick={() => {
                    onClose && onClose();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
          <Grid item md={12}>
            <JSONViewer json={payload} />
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
