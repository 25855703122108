import React, { useState, useEffect, useRef } from 'react';
import { accountState } from '../../../store';
import { useSelector } from 'react-redux';
import { Autocomplete, Checkbox } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TableContainer } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CLOUD_PROVIDER_SERVICES, ERRORS } from '../../../utils/constants';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ALL_VALUE } from '../../../pages/Invoice/config';
import TextField from '@mui/material/TextField';
import { SelectedAccount } from '../../../components/SelectAccount/SelectAccount';
import { config } from './config';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../../assets/images/confirm-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/images/cancel-icon.svg';
import { ReactComponent as AlertIcon } from '../../../assets/images/caution-icon.svg';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import './SystemHealth.css';

const ICONS: {
  [key: string]: React.ReactNode;
} = {
  error: <CancelIcon width={20} height={20} />,
  warn: <AlertIcon width={20} height={20} />,
  success: <CheckIcon width={20} height={20} />,
};
const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let accountCloudRelation: {
  [key: string]: string;
} = {};

const SystemHealth = () => {
  const history = useHistory();
  const [subCompanies, setSubCompanies] = useState<string[]>([]);
  const [activeAccountIds, setActiveAccountIds] = useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [filteredSubCompany, setFilteredSubCompany] = useState<any[]>([]);
  const [jobFailureDetails, setJobFailurDetails] = useState<any[]>([]);
  const [systemHealthData, setSystemHealthData] = useState<any>({});
  const [clicked, setClicked] = useState<any>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [jobFailurLoader, setJobFailurLoader] = useState<boolean>(false);
  const [networkError, setNetworkError] = useState('');

  // const SelectAccountRef = useRef<any>(null);

  const handleCompanyChange = (e: any, value: any) => {
    setSelectedCompany(value);
    fetchAccounts(value);
    setSelectedAccount('');
    setSystemHealthData({});
    setClicked(false);
    setJobFailurDetails([]);
  };

  const handleSelectedAccountChange = (e: any) => {
    setSelectedAccount(e.target.value);
    setClicked(false);
    setJobFailurDetails([]);
  };

  const fetchSubscribedCompanies = async () => {
    try {
      const result = await config.getSubscribedCompanies();
      if (result.length) {
        const activeCompanies: string[] = [];
        result.forEach((company: any) => {
          if (company.IsActive) activeCompanies.push(company.name);
        });
        setSubCompanies(activeCompanies);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
    }
  };
  const fetchSystemHealthData = async () => {
    try {
      setLoader(true);
      const result = await config.getSystemHealthData(
        selectedAccount,
        accountCloudRelation[selectedAccount]
      );
      setSystemHealthData(result || []);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const fetchAccounts = async (value: any) => {
    try {
      const result = await config.getActiveAccounts(value);
      accountCloudRelation = result.reduce((acc: any, current: any) => {
        if (current.AccountId)
          acc[current.AccountId] = CLOUD_PROVIDER_SERVICES.AWS;
        else acc[current.SubscriptionId] = CLOUD_PROVIDER_SERVICES.AZURE;
        return acc;
      }, {});

      setActiveAccountIds(result);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const fetchJobDetails = async (
    services: string,
    account: string,
    jobdate: string
  ) => {
    try {
      setClicked(true);
      setJobFailurLoader(true);

      const result = await config.getJobFailurDetails(
        services,
        account,
        jobdate,
        accountCloudRelation[selectedAccount]
      );
      setJobFailurDetails(result || []);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setJobFailurLoader(false);
    }
  };

  useEffect(() => {
    // Filter company based on inputValue
    const filtered = subCompanies?.filter((tag: string) =>
      tag.trim().toLowerCase()
    );
    setFilteredSubCompany(filtered);
  }, [subCompanies]);

  useEffect(() => {
    fetchSubscribedCompanies();
  }, []);
  useEffect(() => {
    if (selectedAccount) fetchSystemHealthData();
  }, [selectedAccount]);
  return (
    <>
      {loader && <LoadingScreen />}
      <div className="sContainer mb-15">
        <div className="inventoryHeading font-26px fw-300 pl-5">
          System Health
        </div>
        <div className="flex-end flex w-50 DocUpload-selectBox incFontSize">
          <Autocomplete
            id="demo-simple-select-outlined"
            value={selectedCompany}
            className="w-40"
            onChange={handleCompanyChange}
            options={filteredSubCompany}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Company"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'off',
                }}
              />
            )}
          />
          {selectedCompany && (
            <FormControl className="w-40 ml-10">
              <InputLabel
                style={{ fontSize: '16px' }}
                id="demo-multiple-name-label"
              >
                Select Account
              </InputLabel>
              <Select
                style={{ fontSize: '16px' }}
                inputProps={{ style: { fontSize: 16 } }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectedAccount}
                onChange={handleSelectedAccountChange}
                input={<OutlinedInput label="Select Account" />}
                MenuProps={MenuProps}
              >
                {activeAccountIds.length ? (
                  activeAccountIds.map((key: any, i: number) => (
                    <MenuItem
                      key={key.AccountId || key.SubscriptionId + i}
                      value={key.AccountId || key.SubscriptionId}
                    >
                      <b>
                        {
                          accountCloudRelation[
                            key.AccountId || key.SubscriptionId
                          ]
                        }{' '}
                      </b>
                      :- {key.Name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Records</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
      {Object.keys(systemHealthData).length ? (
        <TableContainer
          // sx={{ maxHeight: '315px', overflowY: 'scroll' }}
          className="accContainer mt-10 mb-10"
        >
          <Table
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="a dense table"
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell
                  width="1%"
                  align="center"
                  className="adminTableheading"
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  width="1%"
                  align="center"
                  className="adminTableheading"
                >
                  SERVICE
                </TableCell>
                {Object.keys(systemHealthData?.inventories[0]).map(
                  (date: string, index: number) => (
                    <TableCell
                      key={index + date}
                      align="center"
                      className="adminTableheading"
                    >
                      {new Date(date)
                        .toDateString()
                        .split(' ')[1]
                        .toUpperCase()}
                      -{new Date(date).toDateString().split(' ')[2]}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(systemHealthData).map(
                ([key, value]: any, index: number) => (
                  <TableRow key={index + 123}>
                    <TableCell>{++index}</TableCell>
                    <TableCell>{key.toUpperCase()}</TableCell>
                    {Object.values(value[0]).map((entry: any, i: number) => (
                      <TableCell align="center">
                        <span
                          className={entry !== 'success' ? 'cursor' : ''}
                          onClick={() => {
                            if (entry !== 'success')
                              fetchJobDetails(
                                key,
                                selectedAccount,
                                Object.keys(systemHealthData?.inventories[0])[i]
                              );
                          }}
                        >
                          {ICONS[entry]}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !selectedCompany || !selectedAccount ? (
        <div className="noDataText">
          Please select a subscription or accountId
        </div>
      ) : (
        <div className="noDataText">No record found</div>
      )}

      {jobFailurLoader ? (
        <div className="noDataText mt-60">Loading...</div>
      ) : jobFailureDetails.length ? (
        <TableContainer
          sx={{ maxHeight: '315px', overflowY: 'scroll' }}
          className="accContainer mt-40 mb-10"
        >
          <Table
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell
                  width="1%"
                  align="center"
                  className="adminTableheading"
                >
                  Sr. No.
                </TableCell>

                {Object.keys(jobFailureDetails[0]).map(
                  (key: string, index: number) => (
                    <TableCell
                      key={index + key}
                      align="center"
                      className="adminTableheading"
                    >
                      {key.split('_').join(' ').toUpperCase()}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobFailureDetails?.map((row: any, index: number) => (
                <TableRow key={index} className={row.status ? '' : 'redRow'}>
                  <TableCell>{++index}</TableCell>
                  {Object.values(row).map((detail: any, i: number) => (
                    <TableCell align="center" key={i}>
                      {typeof detail === 'boolean'
                        ? detail
                          ? 'True'
                          : 'False'
                        : detail || '---'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !jobFailureDetails.length && clicked ? (
        <div className="noDataText mt-60">
          The job remains incomplete due to an unresolved issue.{' '}
        </div>
      ) : null}
    </>
  );
};

export default SystemHealth;
