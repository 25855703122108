import { RightSizingInput } from '../../pages/RightsizingAndIdleInput/RightsizingAndIdleInput';
import { APIS } from '../../utils/apiConfig';
import { GlobalQueryParams } from '../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { HttpService } from '../HttpService/HttpService';

export class RightSizingService {
  constructor(private httpService: HttpService) {}

  async getRightSizingDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        GlobalQueryParams(params, '/rightsizing/getAll?'),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getPrevoiusRightSizingDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(
          params,
          '/rightsizing/previousRightsizingRecommendations?'
        ),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getPrevoiusSchedulingDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(
          params,
          '/scheduling/previousSchedulingRecommendations?'
        ),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getRDSRightsizingData(accountId: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_RDS_RIGHTSIZING_DATA(accountId),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async configureRightSizingDetails(params: any) {
    try {
      const response = await this.httpService.patch(
        '/rightsizing/update',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async toggleInstanceState(params: any) {
    try {
      const response = await this.httpService.post(
        '/rightsizing/toggleState',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async rightSizingRequestFix(params: any) {
    try {
      const response = await this.httpService.post(
        '/rightsizing/requestFix',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async resizeInstance(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/rightsizing/resizeInstance',
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getScheduleVMDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/scheduling/get?'),
        {}
      );

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getIgnoredVMDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/scheduling/getIgnoredVM?'),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getScheduledVMDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/scheduling/getScheduledVM?'),
        {}
      );

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getCustomScheduleVMList(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/scheduling/getVirtualMachines?')
      );

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async ScheduleVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/scheduling/scheduleVM',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async CustomScheduleVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/scheduling/customVM',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async ResetVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/scheduling/resetVM',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async CancelVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/scheduling/cancelVM',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async ToggleStateVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/scheduling/toggleState',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async RescheduleVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/scheduling/startVM',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  // Reserved Resources /reserved/getAllReserved
  async getReservedResourcesList(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        GlobalQueryParams(params, '/reserved/getAllReserved?'),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async reserveVM(params: any): Promise<any> {
    try {
      const response = await this.httpService.get('/reserved/reserveVM', {
        params,
      });
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async setRightsizingDays(params: RightSizingInput): Promise<any> {
    try {
      const response = await this.httpService.post('/setting/put', params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getRightsizingDays(
    accountId: string,
    cloudProvider: string
  ): Promise<any> {
    try {
      const route =
        cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
          ? `/setting/get?accountid=${accountId}`
          : `/setting/get?subscriptionId=${accountId}`;

      const response = await this.httpService.get(route);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
