import React from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './EditUserModal.css';

interface EditUserInterface {
  show: boolean;
  onClose: { (): void };
  onSubmit: {
    (UserId: number, active: boolean, role: number, PrevRoleId: number): void;
  };
  RoleId: number;
  UserId: number;
  IsActive: number;
  UserName: string;
}

const EditUserModal: React.FC<EditUserInterface> = ({
  show,
  onClose,
  RoleId,
  UserId,
  IsActive,
  onSubmit,
  UserName,
}) => {
  const PrevRoleId = RoleId;
  const [role, setRole] = React.useState<number>(RoleId);
  const [active, setActive] = React.useState<any>(IsActive ? true : false);
  const handleChange = (event: any) => {
    const value = event.target.value;
    setRole(value);
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      {' '}
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Edit {UserName} Status</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>
        <div className="editOuterBox mt-25">
          <div>
            <div className="mr-30 mb-5">Select Role Type</div>
            <div className="EditUserSelect">
              <Select value={role} onChange={handleChange} name="RoleId">
                <MenuItem value="2">Admin</MenuItem>
                <MenuItem value="3">Employee</MenuItem>
              </Select>
            </div>
          </div>
          <div className="mt-20">
            <span className="mr-60">Active/Inactive</span>
            <span>
              {' '}
              <Switch
                {...label}
                onChange={() => setActive(!active)}
                checked={active}
              />
            </span>
          </div>
        </div>
      </Grid>
      <div className="mt-15 mb-25 ">
        <button
          type="button"
          className="discardButton mr-15 "
          onClick={onClose}
        >
          Discard
        </button>
        <button
          type="button"
          className="acceptButton"
          onClick={() => onSubmit(UserId, active, role, PrevRoleId)}
        >
          Update
        </button>
      </div>
    </Modal>
  );
};

export default EditUserModal;
