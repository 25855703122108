import { CLOUD_PROVIDER_SERVICES } from '../constants';

export const formatData = (data: any, cloudProvider: string) => {
  const result: any = [];

  Object.entries(data)?.map(([key, value]: any) => {
    const obj: any = {};
    obj.Details = value;
    if (obj.Details.length) {
      obj.Count = obj.Details?.length;
      obj.TotalCost = obj.Details?.reduce(
        (acc: number, curr: any) => acc + +curr.Cost,
        0
      );
      obj.Currency =
        cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
          ? obj.Details[0].Currency_Symbol
          : obj.Details[0].CurrencySymbol;
      obj.ResourceName = key;
      result.push(obj);
    }
  });
  return result;
};
