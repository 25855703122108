import React, { useEffect } from 'react';
import SupportHeader from '../../components/Header/SupportHeader';
import { Footer } from '../../components';
import { Link } from 'react-router-dom';
import './404.css';

export const Error404: React.FC = () => {
  const company = localStorage.getItem('CompanyName');
  if (
    localStorage.getItem('CompanyName') == null ||
    window.location.pathname.split('/')[1] != company
  ) {
    localStorage.removeItem('auth-state');
    localStorage.removeItem('CompanyName');
    localStorage.removeItem('RoleId');
    localStorage.removeItem('RemainingDays');
    window.location.href = '/login';
  }

  return (
    <>
      {localStorage.getItem('CompanyName') != null ? (
        <>
          <SupportHeader />
          <div className="container-404 py-35 my-35">
            <div className="item-404">404</div>
            <div className="error-text p-10">Oops, Page not found!</div>
            <Link className="home-link m-30" to={{ pathname: '/' }}>
              Go to Home
            </Link>
          </div>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Error404;
