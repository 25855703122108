import React from 'react';
import './BarChartLoader.css'; // Import your CSS file for styling

const BarChartLoader = () => {
  return (
    <div className="loader-container">
      <div className="dot dot1" />
      <div className="dot dot2" />
      <div className="dot dot3" />
    </div>
  );
};

export default BarChartLoader;
