import React, { useEffect, useState } from 'react';
import './OrganizationDetails.css';
// import { ReactComponent as TSIcon } from '../../../assets/companiesLogo/thinksysLogo.svg';
import {
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableContainer } from '@mui/material';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { ReactComponent as AWSLogo } from '../../../assets/ICONS/logos_aws.svg';
import { ReactComponent as AzureLogo } from '../../../assets/ICONS/logos_microsoft-azure.svg';
import { ReactComponent as GCPLogo } from '../../../assets/ICONS/logos_google-cloud.svg';
// import { , OrgTeam } from './data';
import { ReactComponent as EditIcon } from '../../../assets/ICONS/material-symbols_edit-outline.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/ICONS/ic_round-delete-outline.svg';
import CustomSwitch from '../AdminDashboard/components/CustomSwitch';
import Teams from '../AdminDashboard/components/Teams';
import { ReactComponent as AddIcon } from '../../../assets/ICONS/material-symbols_add-circle-rounded.svg';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { config } from './config';
import { CLOUD_PROVIDER_SERVICES, ERRORS } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { handleError } from '../../../utils/config';
// import { ReactComponent as GEFENIcon } from '../../../assets/companiesLogo/Gefen_300 1.svg';
import TSIcon from '../../../assets/images/tsLogo.png';
import GEFENIcon from '../../../assets/images/Gefen_3001.png';
import DeleteModal from '../../../components/Modals/DeleteModal/DeleteModal';
import { userActions } from '../../../store/userSlice';
import { useDispatch } from 'react-redux';

const OrganizationDetails: React.FC = (props: any) => {
  const [selectedCloud, setSelectedCloud] = useState(0);
  const [loader, setLoader] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [orgTeam, setOrgTeam] = useState<any>([]);
  const [orgDetails, setOrgDetails] = useState<any>({});
  const [cloudCount, setCloudCount] = useState<any>({
    AWS: 0,
    Azure: {
      sub: 0,
      accounts: 0,
    },
  });
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [clickedAccountDetails, setClickedAccountDetails] = useState<any>();
  const dispatch = useDispatch();

  const history = useHistory();
  const companyName = props.location.state.CompanyName;
  const onCloseDeleteModal = () => setDeleteModal(false);

  const fetchCompanyUsers = async () => {
    try {
      setLoader(true);
      const res = await config.getUsers(companyName);
      if (res.length) setOrgTeam(res);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const fetchAccountDetails = async (cloud: string) => {
    try {
      setLoader(true);
      const res = await config.getAccountDetails(companyName);

      setOrgDetails(res);
      if (cloud.toUpperCase() === CLOUD_PROVIDER_SERVICES.AWS)
        setAccounts(res.AWS);
      else setAccounts(res.Azure[0].subscriptions);
      setCloudCount({
        AWS: res.AWS.length,
        AZURE: {
          sub: res.Azure[0].totalSubscriptions.toString(),
          accounts: res.Azure[0].accounts.toString(),
        },
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const deleteAccount = async () => {
    try {
      setLoader(true);
      const res = await config.deleteAccountDetails({
        Id: clickedAccountDetails.Id,
        CloudProvider: clickedAccountDetails.CloudProvider,
        Company: companyName,
      });
      dispatch(
        userActions.replaceUserState({
          successMessage: res.message,
        })
      );
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      fetchAccountDetails(clickedAccountDetails.CloudProvider);
      setDeleteModal(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCompanyUsers();
    fetchAccountDetails('AWS');
  }, []);
  return (
    <>
      {loader && <LoadingScreen />}
      <div className="organizationDetails">
        <div className="OrgDetails-uppterStrip">
          <div>
            {companyName === 'Gefen' ? (
              <img src={GEFENIcon} alt="" />
            ) : companyName === 'thinksys' ? (
              <img src={TSIcon} alt="" />
            ) : (
              ''
            )}
          </div>
          {/* <div className="OrgDetails-us-searchBar">
            <OutlinedInput
              id="email"
              name="EmailAddress"
              className="outlineInputArea bgcolor"
              type="email"
              autoComplete="off"
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
            />
          </div> */}
        </div>
        <div className="OrgDetails-bottomStrip">
          <div className="OrgDetails-leftPanel">
            <div className="OrgDetails-bs-firstPart">
              <div
                className={
                  selectedCloud === 0
                    ? 'OrgDetails-bs-firstPart-div-selected cursor'
                    : 'OrgDetails-bs-firstPart-div cursor'
                }
                onClick={() => {
                  setSelectedCloud(0);
                  setAccounts(orgDetails?.AWS);
                }}
              >
                <div
                  // className="mt-10 ml-20"
                  className={
                    selectedCloud === 0
                      ? 'mt-10 ml-20 invertedColor'
                      : 'mt-10 ml-20'
                  }
                >
                  <AWSLogo width={60} height={60} />
                </div>
                <div className="mt-35">
                  <span className="accountText mt-10 ml-20">
                    Total Accounts :{' '}
                  </span>
                  <span className="numberText">
                    {cloudCount.AWS.toString().padStart(2, '0')}
                  </span>
                </div>
                <div></div>
              </div>

              <div
                className={
                  selectedCloud === 1
                    ? 'OrgDetails-bs-firstPart-div-selected cursor'
                    : 'OrgDetails-bs-firstPart-div cursor'
                }
                onClick={() => {
                  setSelectedCloud(1);
                  setAccounts(orgDetails?.Azure[0].subscriptions);
                }}
              >
                <div
                  className={
                    selectedCloud === 1
                      ? 'mt-20 ml-20 invertedColor'
                      : 'mt-20 ml-20'
                  }
                >
                  <AzureLogo width={40} height={40} />
                </div>
                <div className="mt-40">
                  <span className="accountText mt-10 ml-20">
                    Total Active Directory :{' '}
                  </span>
                  <span className="numberText">
                    {cloudCount?.AZURE?.accounts.toString().padStart(2, '0')}
                  </span>
                </div>
                <div className="mt-5">
                  <span className="accountText mt-10 ml-20">
                    Total Subscriptions :{' '}
                  </span>
                  <span className="numberText">
                    {cloudCount?.AZURE?.sub.toString().padStart(2, '0')}
                  </span>
                </div>
              </div>

              <div
                className="OrgDetails-bs-firstPart-div cursor-disabled"
                // onClick={() => setSelectedCloud(2)}
              >
                <div className="mt-15 ml-20">
                  <GCPLogo width={50} height={50} />
                </div>
                <div className="accountText mt-30 ml-20">Comming Soon...</div>
              </div>
            </div>
            <div className="OrgDetails-bs-secondPart mt-20">
              <div className="OrgText">
                {' '}
                {selectedCloud === 0
                  ? 'All AWS Accounts'
                  : selectedCloud === 1
                  ? 'All Azure Accounts'
                  : 'All GCP Accounts'}{' '}
              </div>
              <div className="mt-15">
                {' '}
                <TableContainer
                  className="accContainer"
                  sx={{ maxHeight: '340px', overflowY: 'scroll' }}
                >
                  <Table
                    className="table adminTable OrgTable"
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                  >
                    <TableHead className="admintableHead">
                      <TableRow>
                        <TableCell
                          className="adminTableheading adminSrNoSection"
                          align="right"
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          className="adminTableheading accountName"
                          width={'30%'}
                        >
                          CFX Name
                        </TableCell>
                        <TableCell className="adminTableheading">
                          {selectedCloud === 0 || selectedCloud === 2
                            ? 'Account ID'
                            : 'Active Directory ID'}
                        </TableCell>
                        {/* {selectedCloud === 1 && (
                        <TableCell>Subscription ID</TableCell>
                      )} */}
                        <TableCell className="adminTableheading">
                          Created Date
                        </TableCell>
                        <TableCell className="adminTableheading">
                          Last Updated
                        </TableCell>
                        <TableCell className="adminTableheading">
                          Status
                        </TableCell>
                        <TableCell className="adminTableheading">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accounts.length ? (
                        accounts.map((row: any, i: number) => (
                          <TableRow
                            key={row.Name + i}
                            className={i % 2 === 0 ? 'oddRow' : 'evenRow'}
                          >
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.Name}</TableCell>
                            <TableCell>
                              {selectedCloud === 0
                                ? row.AccountId
                                : row.AzureId}
                            </TableCell>
                            {/* {selectedCloud === 1 && (
                            <TableCell>{row.subId}</TableCell>
                          )} */}
                            <TableCell>{row.createdAt.split('T')[0]}</TableCell>
                            <TableCell>{row.updatedAt.split('T')[0]}</TableCell>
                            <TableCell>
                              <div
                                className={row.IsActive ? 'active' : 'inactive'}
                              >
                                {row.IsActive ? 'Active' : 'Inactive'}
                              </div>
                            </TableCell>
                            <TableCell>
                              <span className="mr-10">
                                <CustomSwitch
                                  status={row.IsActive}
                                  metaData={{
                                    accountId: row.AccountId,
                                    subId: row.SubscriptionId,
                                    selectedCloud:
                                      selectedCloud === 0 ? 'AWS' : 'Azure',
                                  }}
                                  fetchLatestData={fetchAccountDetails}
                                />
                              </span>

                              <DeleteIcon
                                onClick={() => {
                                  setClickedAccountDetails({
                                    Id: row.Id,
                                    CloudProvider:
                                      selectedCloud === 0 ? 'AWS' : 'AZURE',
                                    accountName: row.Name,
                                  });
                                  setDeleteModal(true);
                                }}
                                className="cursor"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" className="loadingSection">
                            {loader ? 'Loading...' : 'No Record Found'}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="OrgDetails-bs-thirdPart"></div>
          </div>
          <div className="OrgDetails-rightPanel">
            <div className="rightContHeader">
              <div className="teamHeading mt-5 ml-5 upperCase">
                {' '}
                {companyName} Team
              </div>
              {/* <div>
                <AddIcon />
              </div> */}
            </div>

            <div className="Org-teamDetailsBox pt-15">
              {orgTeam.length ? (
                orgTeam.map((row: any, i: number) => (
                  <Teams
                    key={i}
                    name={row.FirstName + ' ' + row.LastName}
                    role={row.RoleId}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onClose={onCloseDeleteModal}
          metaData={`"${clickedAccountDetails.accountName}"`}
          deleteFunction={deleteAccount}
        />
      )}
    </>
  );
};

export default OrganizationDetails;
