import {
  adminDashboardService,
  documentUploadService,
  organisationDetailsService,
  uploadedDocumentsService,
} from '../../../services';

export const config: any = {
  getUploadedDocuments: () => uploadedDocumentsService.getUploadedDocuments(),

  getSubscribedCompanies: () => adminDashboardService.getAllOrgDetails(),
  getCompanyUsers: (company: string) =>
    organisationDetailsService.getUsers(company),
  uploadDocument: (params: any) => documentUploadService.uploadDocs(params),
  updateUsersInfo: (params: any) =>
    documentUploadService.uploadUsersInfo(params),
  addOrganizations: (params: any) =>
    documentUploadService.addOrganization(params),
};
