export const summaryTableHeadersStatic = [
  'Currency',
  'MTD Net Cost',
  'Average Net Cost',
  'Highest Service',
  'Highest Net Cost',
  'Lowest Net Cost',
  'Highest Change in Daily Cost',
];

export const summaryTableDataStatic = [
  '$1580.45',
  '$105.36',
  '$1343.29 for Compute',
  '$107.52 04/04/2021',
  '$101.42 04/15/2021',
  '-$3.44 from 04/04/2021 to 04/15/2021',
];

export const dailyCostDataHeaders = [
  'Date',
  'Daily Cost',
  'Month-To-Date Cost',
];

export const dailyCostData = [
  {
    id: 1,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 2,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 3,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 4,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 5,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 6,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 7,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 8,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 9,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 10,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 11,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 12,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 13,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
  {
    id: 14,
    date: '04/01/2021',
    dailyCost: '$105.91',
    monthToDateCost: '$105.91',
    serviceWiseData: [
      {
        serviceId: 1,
        serviceName: 'AppServices',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 2,
        serviceName: 'Virtual Network',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
      {
        serviceId: 3,
        serviceName: 'Compute',
        dailyCost: '$0.01',
        monthToDateCost: '$15.92',
      },
    ],
  },
];

export const dateWiseTotalCostStatic = [
  {
    name: 'April 01',
    Cost: 110,
  },
  {
    name: 'April 02',
    Cost: 105,
  },
  {
    name: 'April 03',
    Cost: 108,
  },
  {
    name: 'April 04',
    Cost: 100,
  },
  {
    name: 'April 05',
    Cost: 101,
  },
  {
    name: 'April 06',
    Cost: 107,
  },
  {
    name: 'April 07',
    Cost: 108,
  },
  {
    name: 'April 08',
    Cost: 95,
  },
  {
    name: 'April 09',
    Cost: 120,
  },
  {
    name: 'April 10',
    Cost: 110,
  },
  {
    name: 'April 11',
    Cost: 120,
  },
  {
    name: 'April 11',
    Cost: 120,
  },
  {
    name: 'April 11',
    Cost: 120,
  },
  {
    name: 'April 04',
    Cost: 100,
  },
  {
    name: 'April 05',
    Cost: 101,
  },
  {
    name: 'April 06',
    Cost: 107,
  },
  {
    name: 'April 07',
    Cost: 108,
  },
  {
    name: 'April 08',
    Cost: 95,
  },
  {
    name: 'April 09',
    Cost: 120,
  },
  {
    name: 'April 10',
    Cost: 110,
  },
  {
    name: 'April 11',
    Cost: 120,
  },
  {
    name: 'April 12',
    Cost: 120,
  },
  {
    name: 'April 13',
    Cost: 120,
  },
  {
    name: 'April 14',
    Cost: 100,
  },
  {
    name: 'April 15',
    Cost: 101,
  },
  {
    name: 'April 16',
    Cost: 107,
  },
  {
    name: 'April 17',
    Cost: 108,
  },
  {
    name: 'April 18',
    Cost: 95,
  },
  {
    name: 'April 19',
    Cost: 120,
  },
  {
    name: 'April 20',
    Cost: 110,
  },
  {
    name: 'April 21',
    Cost: 120,
  },
  {
    name: 'April 22',
    Cost: 120,
  },
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
