import React, { useEffect } from 'react';
import { Grid, Tooltip } from '@material-ui/core';

import './InventoryReport.css';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { config, Reports } from './config';
import { ResourcesNames } from './config';
import { useHistory } from 'react-router-dom';
import {
  CLOUD_PROVIDER_SERVICES,
  ERRORS,
  HTTP_STATUS_CODE,
  REPORT_MESSAGE,
} from '../../utils/constants';
import { userActions } from '../../store/userSlice';
import { userState, accountState } from '../../store/index';
import xlsx from '../../assets/images/xlsx.svg';
import { exportToFile } from '../../utils/formateXLSX';
import { handleError } from '../../utils/config';

/*-----Redirect to 403 page---------- */

import { FEATURES } from '../../utils/constants';
import { accountService } from '../../services';

const storageKeys = {
  REPORTS_SELECTED_SUBSCRIPTION: 'REPORTS_SELECTED_SUBSCRIPTION',
  REPORTS_SELECTED_AZUREID: 'REPORTS_SELECTED_AZUREID',
  REPORTS_SELECTED_ACCOUNTID: 'REPORTS_SELECTED_ACCOUNTID',
};

export const getLocalSelectedSubscription = (): string =>
  localStorage.getItem(storageKeys.REPORTS_SELECTED_SUBSCRIPTION) || 'all';
export const getSelectedAzureId = (): string =>
  localStorage.getItem(storageKeys.REPORTS_SELECTED_AZUREID) || 'all';
export const getSelectedAccountId = (): string =>
  localStorage.getItem(storageKeys.REPORTS_SELECTED_ACCOUNTID) || 'all';

export const setLocalSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.REPORTS_SELECTED_SUBSCRIPTION, value);
};

export const setLocalSelectedAccountId = (value: string): void => {
  localStorage.setItem(storageKeys.REPORTS_SELECTED_ACCOUNTID, value);
};

export const setLocalSelectedAzureId = (value: string): void => {
  localStorage.setItem(storageKeys.REPORTS_SELECTED_AZUREID, value);
};

export const InventoryReport: React.FC = () => {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [serviceLoader, setServiceLoader] = React.useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(userState);
  const selectedAccount = useSelector(accountState);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [pageLoad, setPagDone] = React.useState(false);
  const [selectedAzureId, setSelectedAzureId] = React.useState<any>('all');
  const [azureIdsData, setAzureIdsData] = React.useState<any>([]);
  const [selectedAzureSub, setSelectedAzureSub] = React.useState<any>('all');
  const [selectedAccountId, setSelectedAccountId] = React.useState<any>('all');
  const [subscriptions, setSubscriptions] = React.useState<any>([]);
  const [awsIdData, setAWSIdData] = React.useState<any>([]);
  const [servicesData, setServicesData] = React.useState<any>([]);
  const [currency, setCurrency] = React.useState<any>('');

  const cloudProvider = selectedAccount.selectedAccount?.CloudProvider.toUpperCase();
  const userPlans = userData.userData?.plan.features;

  useEffect(() => {
    setLoader(true);
    setPagDone(false);
    setTimeout(() => {
      const name = userPlans?.includes(FEATURES.IDLE_RESOURCES);
      if (name) {
        setPagDone(true);
        setLoader(false);
      }
    }, 1000);
  }, [userPlans]);

  const handleDummyXlsx = async (Id: number, resourceName: string) => {
    try {
      setLoader(true);
      if (cloudProvider) {
        const params = {
          provider: selectedAccount.selectedAccount,
          Id,
        };
        const result = await config[cloudProvider].fetchInventoryReportXLSX(
          params
        );
        if (result.status === HTTP_STATUS_CODE.NOT_FOUND) {
          dispatch(
            userActions.replaceUserState({
              userData: userData.userData,
              isUserSelected: userData.isUserSelected,
              successMessage: result.message,
            })
          );
        } else {
          dispatch(
            userActions.replaceUserState({
              userData: userData.userData,
              isUserSelected: userData.isUserSelected,
              successMessage: resourceName + ' ' + REPORT_MESSAGE.SUCCESS,
            })
          );
          exportToFile(result.fileBuffer, result.fileName);
        }
      }
    } catch (e: any) {
      dispatch(
        userActions.replaceUserState({
          userData: userData.userData,
          isUserSelected: userData.isUserSelected,
          errorMessage: resourceName + ' ' + REPORT_MESSAGE.FAILED,
        })
      );
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
    }
  };

  const getServiceReports = async () => {
    setServicesData([]);
    try {
      setServiceLoader(true);
      let params;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAzureId === 'all' && selectedAzureSub === 'all') {
          params = {
            cloudProvider,
            SubscriptionId: 'all',
          };
        } else if (selectedAzureId !== 'all' && selectedAzureSub === 'all') {
          params = {
            cloudProvider,
            AzureId: azureIdsData.filter(
              (subscription: any) => subscription.AliasName === selectedAzureId
            )[0].AzureId,
          };
        } else if (selectedAzureId !== 'all' && selectedAzureSub !== 'all') {
          params = {
            cloudProvider,
            SubscriptionId: subscriptions.filter(
              (subscription: any) => subscription.Name === selectedAzureSub
            )[0].SubscriptionId,
          };
        }
      } else {
        if (selectedAccountId === 'all') {
          params = {
            cloudProvider,
          };
        } else {
          params = {
            cloudProvider,
            selectedAccounts: awsIdData?.filter(
              (account: any) => account.AliasName === selectedAccountId
            )[0].AccountId,
          };
        }
      }

      const result = await config.getServiceReports(params);

      const data = result?.serviceData?.sort(
        (a: any, b: any) => b.value - a.value
      );
      setCurrency(result.currency);
      setServicesData(data);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setServiceLoader(false);
    }
  };

  useEffect(() => {
    getServiceReports();
  }, [selectedAccountId, selectedAzureId, selectedAzureSub]);

  //ID selections
  const getIdsData = async () => {
    try {
      setLoader(true);
      if (cloudProvider) {
        const result = await accountService.getAccountDetails(
          cloudProvider.toUpperCase()
        );
        if (cloudProvider.toUpperCase() === CLOUD_PROVIDER_SERVICES.AWS)
          setAWSIdData(result);
        else {
          setAzureIdsData(result);
        }
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getAzureIdSubscriptions = async () => {
    try {
      setLoader(true);
      const getSub = azureIdsData.filter(
        (subscription: any) => subscription.AliasName === selectedAzureId
      );
      if (cloudProvider) {
        const result = await accountService.getSubscriptionDetails(
          getSub[0].Id,
          cloudProvider.toUpperCase()
        );
        setSubscriptions(result[0].subscriptions);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const handleAzureIdChange = (event: any) => {
    setSelectedAzureSub('all');
    setLocalSelectedSubscription('all');
    setLocalSelectedAzureId(event.target.value);
    setSelectedAzureId(event.target.value);
  };
  const handleSubscriptionChange = (event: any) => {
    setLocalSelectedSubscription(event.target.value);
    setSelectedAzureSub(event.target.value);
  };
  const handleAWSIdChange = (event: any) => {
    setSelectedAccountId(event.target.value);
    setLocalSelectedAccountId(event.target.value);
  };

  useEffect(() => {
    getAzureIdSubscriptions();
  }, [selectedAzureId]);

  useEffect(() => {
    getIdsData();
  }, [cloudProvider]);
  //ID selection ends

  if (isNetworkError) {
    throw new Error(ERRORS.NETWORK_ERROR);
  }
  return (
    <>
      {loader ? <LoadingScreen /> : <></>}

      {pageLoad ? (
        cloudProvider === CLOUD_PROVIDER_SERVICES.AWS ? (
          <>
            <div className="sContainer">
              <Grid item md={12} sm={12} xs={12}>
                <span className="inventoryReportsHeading">
                  Inventory Reports
                </span>
              </Grid>
            </div>
            {selectedAccount.selectedAccount &&
            !selectedAccount.selectedAccount.Credentials ? (
              <div className="noDataText mt-40">No Data Found</div>
            ) : selectedAccount.selectedAccount?.Credentials ? (
              <>
                <Grid container spacing={2} className="mb-15">
                  {Object.keys(Reports).map((resource: any, i: number) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                      <div
                        className="inventoryReportBox"
                        onClick={() =>
                          handleDummyXlsx(
                            Reports[resource],
                            ResourcesNames[resource]
                          )
                        }
                      >
                        <span className="inventoryText font-20px fw-300 ml-15">
                          {ResourcesNames[resource]}
                        </span>
                        <Tooltip title="Download XLSX" arrow>
                          <span className="inventoryXlsxIcon inventoryCount">
                            <img src={xlsx} />
                          </span>
                        </Tooltip>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : null}{' '}
          </>
        ) : null
      ) : (
        <></>
      )}
    </>
  );
};
export default React.memo(InventoryReport);
