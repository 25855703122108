import React from 'react'; // Import the custom tooltip
import { Link } from 'react-router-dom';
import { generateTooltipText } from './config';
import { useSelector } from 'react-redux';
import { userState } from '../../store';
import CustomSideNavTooltip from './CustomTooltip';

interface SidebarItemProps {
  featureKey: keyof ReturnType<typeof generateTooltipText>;
  feature?: string;
  route: string;
  isActive: boolean;
  icon: React.ReactNode;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  featureKey,
  feature,
  route,
  isActive,
  icon,
}) => {
  const userData = useSelector(userState);

  const user = userData.userData;

  const enabledFeatures = user?.plan.features;

  const isAdmin = user?.RoleId === 1;

  const isFeatureEnabled = isAdmin || enabledFeatures?.includes(feature);

  const tooltipText = generateTooltipText();

  const tooltipContent = isFeatureEnabled
    ? tooltipText[featureKey].default
    : tooltipText[featureKey].disabled;

  const linkStyle = isFeatureEnabled
    ? {}
    : {
        color: '#bfb6b6',
        cursor: 'not-allowed',
        textDecoration: 'none',
      };

  return (
    <div className={!isFeatureEnabled && !isAdmin ? 'leftNavDeactive' : ''}>
      <CustomSideNavTooltip title={tooltipContent} placement="right" arrow>
        <li className={isActive ? 'activeTab' : ''}>
          <Link
            to={route}
            style={linkStyle}
            onClick={(e) => !isFeatureEnabled && e.preventDefault()} // Prevent click if the feature is disabled
          >
            {icon}
          </Link>
        </li>
      </CustomSideNavTooltip>
    </div>
  );
};

export default SidebarItem;
