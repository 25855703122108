import { adminPanelBilligService } from '../../../services';

export const config: any = {
  getAllFeatures: () => adminPanelBilligService.getAllFeatures(),
  getActiveSub: () => adminPanelBilligService.getActiveSub(),
  getAllBillingRequests: () => adminPanelBilligService.getAllBillingRequests(),
  setBillingRequest: (params: any) =>
    adminPanelBilligService.setBillingRequest(params),
  getOrgBillingHistory: (params: string) =>
    adminPanelBilligService.getOrgBillingHistory(params),
};
