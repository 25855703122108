import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutesFun } from '../../schemas/index';
import azureIcon from '../../assets/images/azure-icon.png';
import awsIcon from '../../assets/images/aws-icon.png';
import { useSelector } from 'react-redux';
import { userState, accountState } from '../../store/index';
import './Navigation.css';
import { FEATURES } from '../../utils/constants';
import { ReactComponent as SecurityImg } from '../../assets/navigationSvg/security.svg';
import { ReactComponent as ReportImg } from '../../assets/navigationSvg/reports.svg';
import { ReactComponent as BillingImg } from '../../assets/navigationSvg/billing.svg';
import { ReactComponent as SavingImg } from '../../assets/navigationSvg/piggyBank.svg';
import { ReactComponent as ForcastImg } from '../../assets/navigationSvg/barChart.svg';
import { ReactComponent as ScheduleImg } from '../../assets/navigationSvg/calander.svg';
import { ReactComponent as UptimeMonitoring } from '../../assets/navigationSvg/UptimeMonitoring.svg';
import { ReactComponent as AdminDashboard } from '../../assets/navigationSvg/AdminDashboard.svg';
import { ReactComponent as Organizations } from '../../assets/navigationSvg/Organizations.svg';
import { ReactComponent as IdleImg } from '../../assets/navigationSvg/idle.svg';
import { ReactComponent as RightSizingImg } from '../../assets/navigationSvg/rightSizing.svg';
import { ReactComponent as Billing } from '../../assets/navigationSvg/adminPanel-billing.svg';
import { ReactComponent as SystemHealth } from '../../assets/navigationSvg/monitoring-system-icon.svg';
import { ReactComponent as DocSvg } from '../../assets/ICONS/docSvg.svg';
import { ReactComponent as RUMDashboard } from '../../assets/navigationSvg/RUM.svg';
import { InventoryIcon } from './config';
import SidebarItem from './SideBarItem';

export const Navigation: React.FC = () => {
  const location = useLocation();
  const Routes = RoutesFun();
  const [currentPage, setCurrentPage] = React.useState('');
  const userData = useSelector(userState);
  const user = userData.userData;
  const selectedAccount = useSelector(accountState).selectedAccount;
  const isAccountSelected = useSelector(accountState).isAccountSelected;

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  return (
    <React.Fragment>
      {/* for admin role */}
      {user?.RoleId === 1 ? (
        <ul>
          <SidebarItem
            featureKey="AdminDashboard"
            isActive={currentPage === Routes.AdminDashboard}
            icon={<AdminDashboard width={20} height={20} />}
            route={Routes.AdminDashboard}
          />
          <SidebarItem
            featureKey="AllOrganizations"
            isActive={currentPage === Routes.AllOrganizations}
            icon={<Organizations width={20} height={20} />}
            route={Routes.AllOrganizations}
          />
          <SidebarItem
            featureKey="AdminPanelBilling"
            isActive={currentPage === Routes.AdminPanelBilling}
            icon={<Billing width={20} height={20} />}
            route={Routes.AdminPanelBilling}
          />
          <SidebarItem
            featureKey="SystemHealth"
            isActive={currentPage === Routes.SystemHealth}
            icon={<SystemHealth width={20} height={20} />}
            route={Routes.SystemHealth}
          />
        </ul>
      ) : (
        // for user roles
        <ul>
          <li className={`platformIcon ${!isAccountSelected ? 'd-none' : ''}`}>
            {selectedAccount?.CloudProvider.toUpperCase() === 'AZURE' ? (
              <img src={azureIcon} alt="" width="40" />
            ) : selectedAccount?.CloudProvider.toUpperCase() === 'AWS' ? (
              <img src={awsIcon} alt="" width="40" />
            ) : (
              <img src={azureIcon} alt="" width="40" />
            )}
          </li>
          <SidebarItem
            featureKey="Inventory"
            feature={FEATURES.INVENTORY}
            route={Routes.Inventories}
            isActive={
              currentPage.split('/')[2] === Routes.Inventories.split('/')[2]
            }
            icon={InventoryIcon}
          />

          <SidebarItem
            featureKey="IdleResources"
            feature={FEATURES.IDLE_RESOURCES}
            route={Routes.IdleInstance}
            isActive={currentPage === Routes.IdleInstance}
            icon={<IdleImg />}
          />

          <SidebarItem
            featureKey="RightSizing"
            feature={FEATURES.RIGHTSIZING}
            route={Routes.RightSizing}
            isActive={
              currentPage.split('/')[2] === Routes.RightSizing.split('/')[2]
            }
            icon={<RightSizingImg />}
          />

          <SidebarItem
            featureKey="ScheduleInstances"
            feature={FEATURES.SCHEDULE_INSTANCES}
            route={Routes.ScheduleVM}
            isActive={currentPage === Routes.ScheduleVM}
            icon={<ScheduleImg width={20} height={20} />}
          />

          <SidebarItem
            featureKey="CostForecasting"
            feature={FEATURES.COST_FORECASTING}
            route={Routes.CostForecasting}
            isActive={
              currentPage.split('/')[2] === Routes.CostForecasting.split('/')[2]
            }
            icon={<ForcastImg width={20} height={20} />}
          />

          <SidebarItem
            featureKey="CostSaving"
            feature={FEATURES.COST_SAVING}
            route={Routes.CostSaving}
            isActive={currentPage === Routes.CostSaving}
            icon={<SavingImg width={20} height={20} />}
          />

          <SidebarItem
            featureKey="BillingSimplified"
            feature={FEATURES.BILLING_SIMPLIFIED}
            route={Routes.Invoice}
            isActive={currentPage === Routes.Invoice}
            icon={<BillingImg width={20} height={20} />}
          />

          <SidebarItem
            featureKey="SecurityScan"
            feature={FEATURES.SECURITY_SCAN}
            route={Routes.SecurityScan}
            isActive={currentPage === Routes.SecurityScan}
            icon={<SecurityImg width={20} height={20} />}
          />

          <SidebarItem
            featureKey="ServiceReports"
            feature={FEATURES.COST_FORECASTING}
            route={Routes.ServicesReports}
            isActive={currentPage === Routes.ServicesReports}
            icon={<ReportImg width={20} height={20} />}
          />

          <SidebarItem
            featureKey="UptimeMonitoring"
            feature={FEATURES.UPTIME_MONITORING}
            route={Routes.UptimeMonitoring}
            isActive={currentPage === Routes.UptimeMonitoring}
            icon={<UptimeMonitoring width={20} height={20} />}
          />

          {/* RUM monitoring */}

          <SidebarItem
            featureKey="RealUserMonitoring"
            feature={FEATURES.UPTIME_MONITORING}
            route={Routes.RealUserMonitoring}
            isActive={currentPage === Routes.RealUserMonitoring}
            icon={<RUMDashboard width={20} height={20} />}
          />

          {/* //upload docs */}

          <SidebarItem
            featureKey="UploadedDocuments"
            feature={FEATURES.UPLOADED_DOCUMENTS}
            route={Routes.UploadedDocuments}
            isActive={currentPage === Routes.UploadedDocuments}
            icon={<DocSvg width={20} height={20} />}
          />
        </ul>
      )}
    </React.Fragment>
  );
};
