import { inventoryReportService } from '../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

export const config: any = {
  [CLOUD_PROVIDER_SERVICES.AWS]: {
    fetchInventoryReportXLSX: (params: any) =>
      inventoryReportService.getInventoryReportXlsx(params),
  },
  getServiceReports: (params: any) =>
    inventoryReportService.getServicesReport(params),
  getServiceResourceCost: (params: any) =>
    inventoryReportService.getServiceResourceCost(params),
};

export const Reports: any = {
  EC2: 1,
  EC2NetworkInterfaces: 2,
  CollectionStatus: 3,
  EC2LoadBalancer: 4,
  RDS_SNAPSHOT: 5,
  RDS_INSTANCES: 6,
  S3: 7,
};

export const ResourcesNames: any = {
  EC2: 'EC2 Instance Details',
  EC2NetworkInterfaces: 'EC2 Network Interfaces',
  EC2LoadBalancer: 'Load Balancer',
  CollectionStatus: 'Collection Status',
  RDS_SNAPSHOT: 'RDS Snapshots',
  RDS_INSTANCES: 'RDS Instances',
  S3: 'S3',
};
