import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import '../Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faAddressCard,
  faBuilding,
  faPhoneAlt,
  faEnvelope,
  faLock,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { idleInstanceService } from '../../../services';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import MuiPhoneNumber from 'material-ui-phone-number';
import { userService } from '../../../services';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
// import { useUserData } from '../../../context/GlobalUserData';

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../../store/index';
import { handleError } from '../../../utils/config';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose: { (): void };
  user?: any;
}

interface InviteUser {
  FirstName: string;
  LastName: string;
  CompanyName: string;
  ContactNo: number;
  showPassword: boolean;
  EmailAddress: string;
  Password: string;
  IsActive: number;
  TnC: boolean;
  PrivacyPolicy: boolean;
}

export const InviteModal: React.FC<FixProps> = (props) => {
  const { show = false, onClose } = props;

  const user = useSelector(userState).userData;
  // const { user, selectedAccount } = useUserData();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();
  const [userData, setUserData] = React.useState({
    FirstName: '',
    LastName: '',
    CompanyName: '',
    ContactNo: '',
    showPassword: false,
    Password: '',
    EmailAddress: '',
    IsActive: 0,
    TnC: false,
    PrivacyPolicy: false,
  });
  const { register, handleSubmit, control, errors } = useForm<InviteUser>({
    mode: 'onChange',
  });
  const [networkError, setNetworkError] = React.useState(false);

  const handleFieldChange = (target: any) => {
    if (target.name == 'TnC' || target.name == 'PrivacyPolicy') {
      setUserData({
        ...userData,
        [target.name]: target.checked,
      });
    } else {
      setUserData({ ...userData, [target.name]: target.value });
    }
  };

  const handleClickShowPassword = () => {
    setUserData({
      ...userData,
      showPassword: !userData.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (data: any) => {
    const { user } = props;
    setLoader(true);
    try {
      // const userDomain = user.EmailAddress.substring(user.EmailAddress.lastIndexOf('@') +1);
      // const domain = data.EmailAddress.substring(data.EmailAddress.lastIndexOf('@') +1);
      if (
        user.RoleId === 2 &&
        user.CompanyName.toLowerCase() !== data.CompanyName.toLowerCase()
      ) {
        setErrorMsg('You are not authorized to add user for different company');
        return;
      }
      setUserData({ ...userData, IsActive: 1 });
      const result = await userService.inviteUser(data);

      if (result) {
        setErrorMsg('');
        setSuccessMsg('New user added. Contact support for activation.');
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
        setErrorMsg(e.error.message.replace('ContactNo', 'Phone Number'));
      }
    } finally {
      setLoader(false);
    }
  };

  if (networkError) {
    throw new Error('Network Error');
  }

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 99 } }}
        className="fixModal"
        {...props}
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">Invite User</span>
              <span className="modalClose" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>

          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className="d-block w-100"
          >
            <Grid item md={12} className="modalBody pt-30">
              <Box className="">
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  className="positionRelative"
                >
                  <Grid item>
                    <FormControl variant="outlined" className="bEmail mb-25 ">
                      <InputLabel
                        htmlFor="business-email"
                        className="inputLegend"
                      >
                        BUSINESS EMAIL
                      </InputLabel>
                      <OutlinedInput
                        id="email"
                        name="EmailAddress"
                        className="outlineInputArea"
                        inputProps={{
                          autoComplete: 'new-password',
                        }}
                        type="email"
                        onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                          pattern: /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/,
                        })}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                          </InputAdornment>
                        }
                      />
                      {errors.EmailAddress && (
                        <div className="regMsg">Enter valid email id</div>
                      )}
                    </FormControl>
                    <FormControl variant="outlined" className="bPassword mb-25">
                      <InputLabel htmlFor="password" className="inputLegend">
                        PASSWORD
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        name="Password"
                        className="outlineInputArea"
                        inputProps={{
                          autoComplete: 'new-password',
                        }}
                        onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                          maxLength: 16,
                          minLength: 8,
                          pattern: /^\S*$/,
                        })}
                        type={userData.showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <span
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {userData.showPassword ? (
                                <FontAwesomeIcon icon={faUnlock} />
                              ) : (
                                <FontAwesomeIcon icon={faLock} />
                              )}
                            </span>
                          </InputAdornment>
                        }
                      />
                      {errors.Password && (
                        <div className="regMsg">
                          Password length should be between 8 to 16 Character
                          without blank space.
                        </div>
                      )}
                    </FormControl>
                    <FormControl variant="outlined" className="bEmail mb-25">
                      <InputLabel htmlFor="first-name" className="inputLegend">
                        ENTER FIRST NAME
                      </InputLabel>
                      <OutlinedInput
                        id="first-name"
                        name="FirstName"
                        className="outlineInputArea"
                        onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                          pattern: /^\S*$/,
                        })}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <FontAwesomeIcon icon={faAddressCard} />
                            </span>
                          </InputAdornment>
                        }
                      />
                      {errors.FirstName && (
                        <div className="regMsg">Enter valid first name.</div>
                      )}
                    </FormControl>
                    <FormControl variant="outlined" className="bEmail mb-25">
                      <InputLabel htmlFor="last-name" className="inputLegend">
                        ENTER LAST NAME
                      </InputLabel>
                      <OutlinedInput
                        id="last-name"
                        name="LastName"
                        className="outlineInputArea"
                        onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                          pattern: /^\S*$/,
                        })}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <FontAwesomeIcon icon={faAddressCard} />
                            </span>
                          </InputAdornment>
                        }
                      />
                      {errors.LastName && (
                        <div className="regMsg">Enter valid last name.</div>
                      )}
                    </FormControl>
                    <FormControl variant="outlined" className="bEmail mb-25">
                      <InputLabel
                        htmlFor="company-name"
                        className="inputLegend"
                      >
                        ENTER COMPANY NAME
                      </InputLabel>
                      <OutlinedInput
                        id="company-name"
                        name="CompanyName"
                        className="outlineInputArea"
                        defaultValue={
                          user && user.RoleId === 2 ? user.CompanyName : ''
                        }
                        onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                          pattern: /\S/,
                        })}
                        readOnly={user && user.RoleId === 2}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <FontAwesomeIcon icon={faBuilding} />
                            </span>
                          </InputAdornment>
                        }
                      />
                      {errors.CompanyName && (
                        <div className="regMsg">Enter valid company name.</div>
                      )}
                    </FormControl>
                    <Controller
                      className="bEmail my-10"
                      id="phone-number"
                      name="ContactNo"
                      defaultValue={''}
                      control={control}
                      variant="outlined"
                      label="ENTER PHONE NUMBER"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span>
                              <FontAwesomeIcon icon={faPhoneAlt} />
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      rules={{ minLength: 10 }}
                      helperText={
                        errors.ContactNo && (
                          <div className="regMsg mx-0">Enter Valid number.</div>
                        )
                      }
                      //   render={({onChange,name,value})=><MuiPhoneNumber
                      //   defaultCountry={'us'}
                      //   onChange={onChange}
                      //   name={name}
                      //   value={value}
                      //   countryCodeEditable={false}
                      // />}
                      as={
                        <MuiPhoneNumber
                          defaultCountry={'us'}
                          onChange={handleFieldChange}
                          countryCodeEditable={false}
                        />
                      }
                    >
                      {errors.ContactNo && (
                        <div className="regMsg">
                          Enter 10 digit Valid numbers.
                        </div>
                      )}
                    </Controller>
                    <div
                      className={
                        successMsg
                          ? 'alert-success p-5 px-20 mr-10 font-14px responseMsg ' +
                            successMsg
                          : ''
                      }
                    >
                      {successMsg}
                    </div>

                    <div
                      className={
                        errorMsg
                          ? 'alert-danger p-5 px-10 my-10 font-12px responseMsg ' +
                            errorMsg
                          : ''
                      }
                    >
                      {errorMsg}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <div className="mt-0 modalFooter">
              <input
                type="submit"
                className="btn btn-primary ml-10"
                value="SUBMIT"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
