import { APIS } from '../../../utils/apiConfig';
import { HttpService } from '../../HttpService/HttpService';

export class DocumentUploadService {
  constructor(private httpService: HttpService) {}
  async uploadDocs(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.UPLOAD_DOCUMENTS(params.companyName, params.friendlyName),
        params.files
      );
      const { data } = response;
      //   if (data.sc == 1) {
      //     const { result } = data;
      //     return result;
      //   }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async uploadUsersInfo(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.UPLOAD_USERS_INFO(),
        params
      );
      const { data } = response;
      //   if (data.sc == 1) {
      //     const { result } = data;
      //     return result;
      //   }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async addOrganization(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.ADD_ORGANIZATIONS(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
  async getAllUsersForSuperAdmin(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ALL_USERS_FOR_SUPER_ADMIN()
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
      return data;
    } catch (e) {
      throw e;
    }
  }
}
