import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from '@material-ui/core';
import '../Modal.css';
import { accountService, idleInstanceService } from '../../../services';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { userService } from '../../../services';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { handleError } from '../../../utils/config';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose: { (): void };
  user?: any;
  subscriptionDetail?: any;
}

interface AddSubscription {
  Name: string;
  CloudProvider: string;
  Location: string;
  PayeeOrganization: string;
  Attributes: string;
  ServiceType: string;
  AzureId: string;
  AccountId: string;
  AccessKeyId: string;
  SecretAccessKeyId: string;
  ApplicationId: string;
  ClientSecret: string;
  SubscriptionId: string;
  AzureAccountType: string;
  Type: string;
}

export const SubscriptionModal: React.FC<FixProps> = (props) => {
  const { children, show = false, onClose, subscriptionDetail } = props;

  const cloudProvider: string = subscriptionDetail[0].CloudProvider;

  const [validEmails, setValidEmails] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [buttonState, setButtonState] = React.useState(false);
  const history = useHistory();
  const [subscriptionData, setSubscriptionData] = React.useState({
    Name: '',
    CloudProvider: subscriptionDetail[0].CloudProvider,
    Location: subscriptionDetail[0].Location,
    PayeeOrganization: subscriptionDetail[0].PayeeOrganization,
    Attributes: subscriptionDetail[0].Attributes,
    ServiceType: subscriptionDetail[0].ServiceType
      ? subscriptionDetail[0].ServiceType
      : 'Subscription',
    AccountId: subscriptionDetail[0].AccountId,
    AzureId: subscriptionDetail[0].AzureId,
    AccessKeyId: '',
    SecretAccessKeyId: '',
    ApplicationId: '',
    ClientSecret: '',
    SubscriptionId: '',
    AzureAccountType: subscriptionDetail[0].AzureAccountType,
    Type:
      subscriptionDetail[0].CloudProvider === 'AWS'
        ? 'IAMUser'
        : 'Subscription',
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    errors,
    getValues,
  } = useForm<AddSubscription>({
    mode: 'onChange',
  });

  const [networkError, setNetworkError] = React.useState(false);
  const values = getValues();

  const handleFieldChange = (target: any) => {
    setSubscriptionData((prevSubscriptionData) => {
      return { ...prevSubscriptionData, [target.name]: target.value };
    });
  };

  const onSubmit = async () => {
    setLoader(true);
    try {
      setErrorMsg('');
      setButtonState(true);

      const result = await accountService.createAccount(subscriptionData);

      setSuccessMsg(result);

      if (result) {
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } catch (e: any) {
      setSuccessMsg('');
      setButtonState(false);
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      if (e.error.message == 'The provided credentials is invalid!') {
        setButtonState(true);
        setSuccessMsg('Subscription added successfully!');
        setTimeout(() => {
          onClose();
        }, 2000);
      }
      if (
        e &&
        e.error &&
        e.error.message &&
        e.error.message != 'The provided credentials is invalid!'
      ) {
        const err = `${e.error.message.replace(
          'Subscription name',
          'CFX Subscription name'
        )}`;
        setErrorMsg(err);
        // setErrorMsg(e.error.message);
      }
    } finally {
      setLoader(false);
    }
  };

  if (networkError) {
    throw new Error('Network Error');
  }

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 99 } }}
        className="fixModal"
        {...props}
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">Add Subscription</span>
              <span className="modalClose" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>

          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className={'d-block w-100'}
          >
            <Grid item md={12} className="modalBody pt-20">
              <Box className="registrationLogin">
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  className="positionRelative"
                >
                  <Grid item className="w100">
                    <FormControl variant="outlined" className="bEmail mb-25">
                      <InputLabel
                        shrink
                        htmlFor="account-id"
                        className="inputLegend"
                      >
                        {subscriptionData.CloudProvider === 'AWS'
                          ? 'AWS Id'
                          : 'Azure Id'}
                      </InputLabel>
                      <OutlinedInput
                        id="account-id"
                        name="AccountId"
                        className="outlineInputArea"
                        autoComplete="off"
                        type="text"
                        value={
                          subscriptionData.CloudProvider === 'AWS'
                            ? subscriptionData.AccountId
                            : subscriptionData.AzureId
                        }
                        // onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                        })}
                        readOnly
                      />
                      {formState.touched.AccountId &&
                        values.AccountId === '' && (
                          <div className="regMsg">Account Id is required</div>
                        )}
                    </FormControl>
                    <FormControl variant="outlined" className="bEmail mb-25">
                      <InputLabel htmlFor="account-id" className="inputLegend">
                        CFX Subscription Name
                      </InputLabel>
                      <OutlinedInput
                        id="account-id"
                        name="Name"
                        label="CFX Subscription Name"
                        className="outlineInputArea"
                        autoComplete="off"
                        type="text"
                        onChange={(e) => handleFieldChange(e.target)}
                        inputRef={register({
                          required: true,
                          pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                        })}
                      />
                      {(formState.touched.Name && values.Name === '' && (
                        <div className="regMsg">
                          CFX Subscription Name is required
                        </div>
                      )) ||
                        (errors.Name && (
                          <div className="regMsg">
                            Enter Valid CFX Subscription Name
                          </div>
                        ))}
                      {/* {formState.touched.Name && values.Name === '' && (
                          <div className="regMsg">
                            CFX Subscription Name is required
                          </div>
                        )} */}
                    </FormControl>
                    {cloudProvider === 'AWS' && (
                      <FormControl variant="outlined" className="bEmail mb-25">
                        <InputLabel
                          htmlFor="access-key"
                          className="inputLegend"
                        >
                          Access Key
                        </InputLabel>
                        <OutlinedInput
                          id="access-key"
                          name="AccessKeyId"
                          className="outlineInputArea"
                          autoComplete="off"
                          onChange={(e) => handleFieldChange(e.target)}
                          inputRef={register({
                            required: true,
                            pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                          })}
                          type="text"
                        />
                        {(formState.touched.AccessKeyId &&
                          values.AccessKeyId === '' && (
                            <div className="regMsg">Access key is required</div>
                          )) ||
                          (errors.AccessKeyId && (
                            <div className="regMsg">Enter Valid Access key</div>
                          ))}
                      </FormControl>
                    )}

                    {(cloudProvider === 'Azure' ||
                      cloudProvider === 'AZURE') && (
                      <FormControl variant="outlined" className="bEmail mb-25">
                        <InputLabel
                          htmlFor="application-id"
                          className="inputLegend"
                        >
                          Application Id
                        </InputLabel>
                        <OutlinedInput
                          id="application-id"
                          name="ApplicationId"
                          className="outlineInputArea"
                          autoComplete="off"
                          onChange={(e) => handleFieldChange(e.target)}
                          inputRef={register({
                            required: true,
                            pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                          })}
                          type="text"
                        />
                        {(formState.touched.ApplicationId &&
                          values.ApplicationId === '' && (
                            <div className="regMsg">
                              Application Id is required
                            </div>
                          )) ||
                          (errors.ApplicationId && (
                            <div className="regMsg">
                              Enter Valid Application Id
                            </div>
                          ))}
                      </FormControl>
                    )}

                    {cloudProvider === 'AWS' && (
                      <FormControl variant="outlined" className="bEmail mb-25">
                        <InputLabel
                          htmlFor="secret-key"
                          className="inputLegend"
                        >
                          Secret Value
                        </InputLabel>
                        <OutlinedInput
                          id="secret-key"
                          name="SecretAccessKeyId"
                          className="outlineInputArea"
                          onChange={(e) => handleFieldChange(e.target)}
                          inputRef={register({
                            required: true,
                            pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                          })}
                        />

                        {(formState.touched.SecretAccessKeyId &&
                          values.SecretAccessKeyId === '' && (
                            <div className="regMsg">Secret Key is required</div>
                          )) ||
                          (errors.SecretAccessKeyId && (
                            <div className="regMsg">Enter Valid Secret Key</div>
                          ))}
                      </FormControl>
                    )}

                    {(cloudProvider === 'Azure' ||
                      cloudProvider === 'AZURE') && (
                      <FormControl variant="outlined" className="bEmail mb-25">
                        <InputLabel
                          htmlFor="secret-value"
                          className="inputLegend"
                        >
                          Secret Value
                        </InputLabel>
                        <OutlinedInput
                          id="secret-value"
                          name="ClientSecret"
                          className="outlineInputArea"
                          onChange={(e) => handleFieldChange(e.target)}
                          inputRef={register({
                            required: true,
                            pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                          })}
                        />

                        {(formState.touched.ClientSecret &&
                          values.ClientSecret === '' && (
                            <div className="regMsg">
                              Secret Value is required
                            </div>
                          )) ||
                          (errors.ClientSecret && (
                            <div className="regMsg">
                              Enter Valid Secret Value
                            </div>
                          ))}
                      </FormControl>
                    )}
                    {(cloudProvider === 'Azure' ||
                      cloudProvider === 'AZURE') && (
                      <FormControl variant="outlined" className="bEmail">
                        <InputLabel
                          htmlFor="subscription-id"
                          className="inputLegend"
                        >
                          Subscription Id
                        </InputLabel>
                        <OutlinedInput
                          id="subscription-id"
                          name="SubscriptionId"
                          className="outlineInputArea"
                          onChange={(e) => handleFieldChange(e.target)}
                          inputRef={register({
                            required: true,
                            pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                          })}
                        />
                        {(formState.touched.SubscriptionId &&
                          values.SubscriptionId === '' && (
                            <div className="regMsg">
                              Subscription Id is required
                            </div>
                          )) ||
                          (errors.SubscriptionId && (
                            <div className="regMsg">
                              Enter Valid Subscription Id
                            </div>
                          ))}
                      </FormControl>
                    )}

                    <div
                      className={
                        successMsg
                          ? 'alert-success p-5 px-20 mr-10 font-14px responseMsg ' +
                            successMsg
                          : ''
                      }
                    >
                      {successMsg}
                    </div>

                    <div
                      className={
                        errorMsg
                          ? 'alert-danger p-5 px-10 my-10 font-12px responseMsg ' +
                            errorMsg
                          : ''
                      }
                    >
                      {errorMsg}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <div className="mt-0 modalFooter">
              <input
                type="submit"
                className="btn btn-primary ml-10"
                value="SUBMIT"
                disabled={buttonState ? true : false}
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
