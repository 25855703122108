import { formattedDataInterface, meta } from '../../schemas/dashboard.schema';

export const formatingRightsizing = (data: meta[]) =>
  Object.keys(data).reduce((acc: formattedDataInterface[], row: string) => {
    const obj: formattedDataInterface = {};
    obj.name = row;
    obj.upscale = data[(row as unknown) as number].upscale;
    obj.downscale = data[(row as unknown) as number].downscale;
    obj.upscaleMonthlyCost = parseFloat(
      data[(row as unknown) as number].upscaleMonthlyCost
    )?.toFixed(2);
    obj.downscaleMonthlyCost = parseFloat(
      data[(row as unknown) as number].downscaleMonthlyCost
    )?.toFixed(2);

    acc.push(obj);
    return acc;
  }, []);

// const costMap = {
//   [INSTANCE_TYPE_SHORT.VM]: 'PredictedMonthlyCost',
//   [INSTANCE_TYPE_SHORT.Storage]: 'PredictedMonthlyCost',
//   [INSTANCE_TYPE_SHORT.MDisk]: 'DiskMonthlyCost',

//   [INSTANCE_TYPE_SHORT.Load]: (value: any) =>
//     value.LoadBalancerPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.Container]: (value: any) =>
//     value.ContainerPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.Redis]: (value: any) => value.RedisPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.SBus]: (value: any) =>
//     value.ServiceBusPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.TM]: (value: any) =>
//     value.TrafficManagerPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.Sql]: (value: any) =>
//     value.SQLServerPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.CD]: (value: any) => value.CosmosDBPerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.Workspace]: (value: any) =>
//     value.WorkspacePerDayCost * 30 || 0,
//   [INSTANCE_TYPE_SHORT.HDCluster]: (value: any) =>
//     value.HDInsightPerDayCost * 30 || 0,
// };

// type ResourceData = {
//   [key: string]: any[];
// };

// export const formatingAzureIdleResourcesData = async (data: ResourceData) => {
//   for (const [key, value] of Object.entries(data)) {
//     const costKey = costMap[key];
//     if (costKey) {
//       for (const item of value) {
//         item.MonthlyCost =
//           typeof costKey === 'function' ? costKey(item) : item[costKey] || 0;
//       }
//     }
//   }
// };

export const formatingIdleResourcesData = (data: any) => {
  return Object.keys(data).reduce((acc: any, row: string) => {
    if (!acc[row]) {
      acc[row] = {};
    }
    if (!acc[row]['data']) {
      acc[row]['data'] = [];
    }
    if (!acc[row]['totalCost']) {
      acc[row]['totalCost'] = 0;
    }
    if (!acc[row]['totalCount']) {
      acc[row]['totalCount'] = 0;
    }
    Object.keys(data[row]).forEach((resourceName: string) => {
      const resourceData: any = {};
      resourceData.name = resourceName;
      // let cost = 0;
      // let totalCount = 0;
      // Object.values(data[row][resourceName]).forEach((value: any) => {
      //   // if (value.MonthlyCost || value.PredictedMonthlyCost) {
      //   cost += Number(value.MTDCost);
      //   // || Number(value.PredictedMonthlyCost);
      //   // }
      //   totalCount += +value.NumberOfResources;
      // });
      resourceData.count = data[row][resourceName].MTDCost;
      acc[row]['totalCost'] += data[row][resourceName].MTDCost;
      acc[row]['totalCount'] += data[row][resourceName].NumberOfResources;
      // data[row][resourceName].length;
      if (resourceData.count.toFixed(2) > 0) {
        acc[row]['data'].push(resourceData);
      }
    });
    return acc;
  }, {});
};

export const formattedForecastData = (forecastRawData: any) => {
  const azure = [
    {
      name: 'Last Month',
      AZURE: Number(forecastRawData?.azureForecast?.prevMonCost).toFixed(2),
    },
    {
      name: 'MTD Cost',
      AZURE: Number(forecastRawData?.azureForecast?.curMonCost).toFixed(2),
    },
    {
      name: 'Forecast',
      AZURE: Number(forecastRawData?.azureForecast?.forecast).toFixed(2),
    },
  ];

  const aws = [
    {
      name: 'Last Month',
      AWS: Number(forecastRawData?.awsForecast?.prevMonCost).toFixed(2),
    },
    {
      name: 'MTD Cost',
      AWS: Number(forecastRawData?.awsForecast?.curMonCost).toFixed(2),
    },
    {
      name: 'Forecast',
      AWS: Number(forecastRawData?.awsForecast?.forecast).toFixed(2),
    },
  ];

  return { aws, azure };
};
