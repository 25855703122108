import { Grid, Skeleton, Box } from '@mui/material';
import CustomTooltip from '../../MasterComponent/CustomTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ApdexLoadtimeSkeleton = () => {
  return (
    <Grid container spacing={3}>
      {/* Left Section - Apdex Score */}
      <Grid item xs={12} md={5}>
        <div
          className="section-title descriptionCardTitle"
          style={{
            textAlign: 'left',
            marginBottom: '10px',
            paddingBottom: '0px',
            width: '8rem',
          }}
        >
          Apdex
          <CustomTooltip
            title={{
              html: `
                                <p>Apdex (Application Performance Index) measures the satisfaction of your site’s visitors by evaluating their load times.</p> 
                      
                                  
                                  <p>It’s a performance index that ranges from 0 to 1, with higher values indicating better user experience.</p>
                      `,
            }}
          >
            <HelpOutlineIcon />
          </CustomTooltip>
        </div>
        <Box
          height="75%"
          alignContent="center"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box mr={2}>
            <Skeleton variant="circular" width={150} height={150} />{' '}
            {/* Apdex Score Circle */}
          </Box>
          <Box>
            <Box display="flex" justifyContent="space-around" mb={1}>
              <Skeleton variant="text" width={250} height={30} />{' '}
              {/* Satisfied Text */}
              {/* Satisfied Percentage */}
            </Box>
            <Box display="flex" justifyContent="space-around" mb={1}>
              <Skeleton variant="text" width={250} height={30} />{' '}
              {/* Tolerating Text */}
              {/* Tolerating Percentage */}
            </Box>
            <Box display="flex" justifyContent="space-around">
              <Skeleton variant="text" width={250} height={30} />{' '}
              {/* Frustrated Text */}
              {/* Frustrated Percentage */}
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
      {/* Right Section - Load time distribution by pageviews */}
      <Grid item xs={12} md={7}>
        <div
          className="section-title descriptionCardTitle"
          style={{
            textAlign: 'left',
            marginBottom: '10px',
            paddingBottom: '0px',
            width: '34rem',
          }}
        >
          Load time distribution by pageviews
          <CustomTooltip title="This period displays the distribution of pageviews alongside the corresponding load times.">
            <HelpOutlineIcon />
          </CustomTooltip>
        </div>
        <Box display="flex" justifyContent="space-around" mb={1}>
          <Skeleton variant="text" width={100} height={25} />{' '}
          {/* Satisfied Label */}
          <Skeleton variant="text" width={100} height={25} />{' '}
          {/* Tolerating Label */}
          <Skeleton variant="text" width={100} height={25} />{' '}
          {/* Frustrated Label */}
        </Box>
        <Box display="flex" justifyContent="space-around" mb={2}>
          <Skeleton variant="text" width={80} height={50} />{' '}
          {/* Satisfied Number */}
          <Skeleton variant="text" width={80} height={50} />{' '}
          {/* Tolerating Number */}
          <Skeleton variant="text" width={80} height={50} />{' '}
          {/* Frustrated Number */}
        </Box>
        <Skeleton variant="rectangular" width="100%" height={150} />{' '}
        {/* Bar Chart Placeholder */}
      </Grid>
    </Grid>
  );
};

export default ApdexLoadtimeSkeleton;
