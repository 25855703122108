import React, { useEffect, useState } from 'react';
import { Grid, Paper, Tooltip as ToolTip } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Sector,
} from 'recharts';
import { costForecastingService } from '../../../services';
import useStyles from './BarGraphAndPieChart.style';
import { useHistory, Link } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { piColorsArr } from './piChartColors';
import './BarGraphAndPieChart.css';
import { SetCostLimitModal } from '../../../components/Modals/SetCostLimitModal/SetCostLimitModal';

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../../store/index';
import {
  CLOUD_PROVIDER_SERVICES,
  PERMISSION_KEY,
  PERMISSION_LEVELS,
} from '../../../utils/constants';
import ProtectedComponent from '../../../components/ProtectedComponent/ProtectedComponent';
import { costFormatter2, handleError } from '../../../utils/config';
import { SetResourceWiseCostModal } from '../../../components/Modals/SetResourceCost/SetResourceWiseCost';
import { config } from './config';
import { ALL_VALUE } from '../../Invoice/config';
import { SelectedAccount } from '../../../components/SelectAccount/SelectAccount';

const barColors = ['#E54454', '#2083DC', '#2083DC'];
const forecastColors = ['#FFFFFF', '#FFFFFF', '#B7D9F4'];
const pieColors = piColorsArr;
const defaultPieData = [{ name: 'No Data Found', value: 1 }];
const defaultColor = ['#AAAAAA'];

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
    name,
  } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        values={value}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius - 50}
        outerRadius={outerRadius + 5}
        fill={fill}
        cursor={`${name === 'No Data Found' ? 'default' : 'pointer'}`}
      />
    </g>
  );
};

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const BarGraphAndPieChart: React.FC = () => {
  const company = localStorage.getItem('CompanyName');
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(userState).userData;
  const selectedAccountInfo = useSelector(accountState);
  const selectedCurrency = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const IdsData = selectedAccountInfo.selectedSubData;
  const selectedAccount = selectedAccountInfo.selectedAccount;
  const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider;
  const isAccountSelected = selectedAccountInfo.isAccountSelected;
  const isAccountActive = selectedAccount?.Credentials;

  const [loader, setLoader] = React.useState(false);
  const [errorRes, setErrorRes] = React.useState('');
  const [successRes, setSuccessRes] = React.useState('');
  const [barData, setBarChartData] = useState([]);
  const [pieData, setPieChartData] = useState([]);
  const [showlimit, setShowLimit] = useState(false);
  const [showResourceWiselimit, setShowResourceWiseLimit] = useState(false);
  const [resourceList, setResourceList] = useState();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [monthToDateEarnings, setmonthToDateEarnings] = React.useState('');
  // const [currency, setCurrency] = React.useState('');
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const [filterData, setFilterData] = useState<any>([]);

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonthName = date
    .toLocaleString('default', { month: 'long' })
    .toLocaleUpperCase();

  const actionPermission = user?.Role?.permissions;
  const costForcastingPermission = actionPermission?.find(
    (obj: any) => obj.Entity.Key === PERMISSION_KEY.CostForcasting
  );

  useEffect(() => {
    if (showlimit) {
      setErrorRes(errorRes || '');
      setSuccessRes(successRes || '');
    } else {
      setErrorRes('');
      setSuccessRes('');
    }
  });

  const handleCostSubmit = async (updatedList: any, updatedSubsList: any) => {
    let data;
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
      const hasEmptyLimit = Object.values(updatedList).some(
        (value: any) => value === ''
      );

      if (hasEmptyLimit) {
        setErrorRes('Limit cannot be Empty');
        return;
      }
      setErrorRes('');

      data = Object.entries(updatedList).map(([accountId, limit]) => ({
        AccountId: accountId,
        Company: selectedAccount?.Company,
        Limit: limit,
      }));
    } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      const hasEmptyLimit = Object.values(updatedList).some(
        (value: any) => value === ''
      );

      const hasEmptySubsLimit = Object.keys(updatedSubsList).some(
        (key) => updatedSubsList[key].SubscriptionLimit === ''
      );

      if (hasEmptyLimit || hasEmptySubsLimit) {
        setErrorRes('Limit cannot be Empty');
        return;
      }
      const updatedData: any = Object.entries(updatedSubsList).reduce(
        (result: any, [subscriptionId, subscription]: any) => {
          const {
            AzureId,
            SubscriptionLimit,
            SubscriptionName,
          }: any = subscription;
          const azureLimit = updatedList[AzureId];

          if (!result[AzureId]) {
            result[AzureId] = {
              AzureId,
              Limit: Number(azureLimit).toFixed(2),
              Subscriptions: [],
              Company: selectedAccount?.Company,
            };
          }

          result[AzureId].Subscriptions.push({
            SubscriptionId: subscriptionId,
            Limit: Number(SubscriptionLimit)?.toFixed(2),
            SubscriptionName,
          });

          return result;
        },
        []
      );

      data = Object.values(updatedData);
    }

    try {
      const response = await costForecastingService.setLimit({
        limits: data,
      });
      if (response) {
        setErrorRes('');
        setSuccessRes(response.message);
      }
      setTimeout(() => {
        onClose && onClose();
      }, 2000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;

        setErrorRes(err);
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setSuccessRes('');
      }, 1000);
    }
  };

  const renderColorfulLegendText = (value: any, { payload }: any) => {
    const conditionalUrl = () => {
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        if (selectedAccountId !== ALL_VALUE) {
          return `/${company}/costforecasting/service-wise-spending-details/pieChart&${payload.name}?selectedAccount=${selectedAccountId}`;
        }
        return `/${company}/costforecasting/service-wise-spending-details/pieChart&${payload.name}?selectedAccount=${selectedAccountId}`;
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (
          selectedAccountId === ALL_VALUE &&
          selectedSubscriptionId === ALL_VALUE
        ) {
          return `/${company}/costforecasting/service-wise-spending-details/pieChart&${payload.name}?subscriptionId=${selectedAccountId}`;
        } else if (
          selectedAccountId !== ALL_VALUE &&
          selectedSubscriptionId === ALL_VALUE
        ) {
          return `/${company}/costforecasting/service-wise-spending-details/pieChart&${payload.name}?azureId=${selectedAccountId}`;
        } else if (
          selectedAccountId !== ALL_VALUE &&
          selectedSubscriptionId !== ALL_VALUE
        ) {
          return `/${company}/costforecasting/service-wise-spending-details/pieChart&${payload.name}?subscriptionId=${selectedAccountId}`;
        }
      }
    };

    const url: any = conditionalUrl();

    return (
      <React.Fragment>
        <Link
          to={pieData.length ? url : '#'}
          style={{
            textDecoration: 'none',
            color: payload.fill,
            pointerEvents: pieData.length ? 'auto' : 'none',
          }}
        >
          {value}
        </Link>
        {pieData.length > 0 ? (
          <span>{costFormatter2(updatedCurrency, payload.value)}</span>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };

  const getDataForBarChartAndPieChart = async () => {
    try {
      setLoader(true);
      const fallBackData = {
        barChartData: [
          { name: 'Last Month', Spend: '0.00', month: '' },
          { name: 'Month to Date', Spend: '0.00', month: currentMonthName },
          {
            name: 'Forecast',
            Spend: '0.00',
            Forecast: '0.00',
            month: currentMonthName,
          },
        ],
        currency: '',
        pieChartData: [],
      };

      let params;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        params = {
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      } else {
        params = {
          subscriptionId: selectedSubscriptionId,
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      }

      const result = selectedAccountInfo.isAccountSelected
        ? await costForecastingService.getBarAndPieChartData(params)
        : fallBackData;

      if (result) {
        setUpdatedCurrency(selectedCurrency);
        const { barChartData, pieChartData } = result;
        const sortedPieChartData = (pieChartData || []).sort(
          (data1: any, data2: any) => data2.value - data1.value
        );
        // setCurrency(result.currency);
        setmonthToDateEarnings(barChartData[1]?.Spend);
        setBarChartData(barChartData);
        setPieChartData(sortedPieChartData);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const onClickBar = (data: any): any => {
    const d = new Date();
    const n = d.getMonth();
    const company = localStorage.getItem('CompanyName');
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
      if (selectedAccountId !== ALL_VALUE) {
        if (data.payload.month != monthList[n]) {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=prev&ChartType=barChart&name=${data.name}?selectedAccount=${selectedAccountId}`
          );
        } else {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=current&ChartType=barChart&name=${data.name}?selectedAccount=${selectedAccountId}`,
            { data: data.name }
          );
        }
      } else {
        if (data.payload.month != monthList[n]) {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=prev&ChartType=barChart&name=${data.name}?selectedAccount=${selectedAccountId}`
          );
        } else {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=current&ChartType=barChart&name=${data.name}?selectedAccount=${selectedAccountId}`,
            { data: data.name }
          );
        }
      }
    } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (
        selectedAccountId === ALL_VALUE &&
        selectedSubscriptionId === ALL_VALUE
      ) {
        if (data.payload.month != monthList[n]) {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=prev&ChartType=barChart&name=${data.name}?subscriptionId=${selectedAccountId}`
          );
        } else {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=current&ChartType=barChart&name=${data.name}?subscriptionId=${selectedAccountId}`,
            { data: data.name }
          );
        }
      } else if (
        selectedAccountId !== ALL_VALUE &&
        selectedSubscriptionId === ALL_VALUE
      ) {
        if (data.payload.month != monthList[n]) {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=prev&ChartType=barChart&name=${data.name}?azureId=${selectedAccountId}`
          );
        } else {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=current&ChartType=barChart&name=${data.name}?azureId=${selectedAccountId}`,
            { data: data.name }
          );
        }
      } else if (
        selectedAccountId !== ALL_VALUE &&
        selectedSubscriptionId !== ALL_VALUE
      ) {
        if (data.payload.month != monthList[n]) {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=prev&ChartType=barChart&name=${data.name}?subscriptionId=${selectedSubscriptionId}`
          );
        } else {
          history.push(
            `/${company}/costforecasting/spending-details/${data.month}?Status=current&ChartType=barChart&name=${data.name}?subscriptionId=${selectedSubscriptionId}`,
            { data: data.name }
          );
        }
      }
    }
  };

  const onClickPie = (data: any): any => {
    if (pieData.length) {
      const company = localStorage.getItem('CompanyName');

      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        if (selectedAccountId !== ALL_VALUE) {
          history.push(
            `/${company}/costforecasting/service-wise-spending-details/pieChart&${data.name}?selectedAccount=${selectedAccountId}`,
            { data: data.name }
          );
        }
        history.push(
          `/${company}/costforecasting/service-wise-spending-details/pieChart&${data.name}?selectedAccount=${selectedAccountId}`,
          { data: data.name }
        );
      } else if (
        selectedAccount?.CloudProvider.toUpperCase() ===
        CLOUD_PROVIDER_SERVICES.AZURE
      ) {
        if (
          selectedAccountId === ALL_VALUE &&
          selectedSubscriptionId === ALL_VALUE
        ) {
          history.push(
            `/${company}/costforecasting/service-wise-spending-details/pieChart&${data.name}?subscriptionId=${selectedAccountId}`,
            { data: data.name }
          );
        } else if (
          selectedAccountId !== ALL_VALUE &&
          selectedSubscriptionId === ALL_VALUE
        ) {
          history.push(
            `/${company}/costforecasting/service-wise-spending-details/pieChart&${data.name}?azureId=${selectedAccountId}`,
            { data: data.name }
          );
        } else if (
          selectedAccountId !== ALL_VALUE &&
          selectedSubscriptionId !== ALL_VALUE
        ) {
          history.push(
            `/${company}/costforecasting/service-wise-spending-details/pieChart&${data.name}?subscriptionId=${selectedSubscriptionId}`,
            { data: data.name }
          );
        }
      }
    }
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">{label}</div>
          {label !== 'Forecast' && (
            <div className="ttLabel">
              <span className="ttKey">{payload[0].dataKey}</span>
              <span className="ttValue">
                {costFormatter2(updatedCurrency, payload[0].value)}
              </span>
            </div>
          )}
          {label !== 'Forecast' && payload[1] && (
            <div className="ttLabel">
              <span className="ttKey">{payload[1].dataKey}</span>
              <span className="ttValue">
                {costFormatter2(updatedCurrency, payload[1].value)}
              </span>
            </div>
          )}
          {label === 'Forecast' && (
            <div>
              <div className="ttLabel">
                <span className="ttKey">Total</span>
                <span className="ttValue">
                  {costFormatter2(updatedCurrency, payload[1]?.payload.total)}
                </span>
              </div>
              <div className="ttLabel">
                <span className="ttKey">Difference</span>
                <span className="ttValue">
                  {costFormatter2(
                    updatedCurrency,
                    payload[1]?.payload?.Forecast
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const CustomTooltipPie = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">{payload[0].name}</div>
          <div className="ttLabel">
            <span className="ttKey">{payload[0].dataKey}</span>
            <span className="ttValue">
              {costFormatter2(updatedCurrency, payload[0].value)}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  const onClose = () => {
    setShowLimit(false);
  };

  const onResourceWiseModalClose = () => {
    setShowResourceWiseLimit(false);
  };

  const getResourceList = async () => {
    try {
      const result = await config[cloudProvider as string].getResourceList();
      setResourceList(result);
      return result;
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  //////////// account selection section starts//////////////

  useEffect(() => {
    getResourceList();
  }, [selectedSubscriptionId, selectedAccount]);

  useEffect(() => {
    getDataForBarChartAndPieChart();
  }, [
    selectedSubscriptionId,
    selectedAccount,
    selectedAccountId,
    selectedCurrency,
  ]);

  //////////// account selection section ends//////////////

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        {loader ? <LoadingScreen /> : ''}
        <Grid
          container
          direction="column"
          spacing={0}
          // className={classes.borderTop}
        >
          <Grid item>
            <div className="sContainer">
              <div className="mtdSummary">
                <div className="monthToDateHeader">
                  {`MTD ${currentMonthName} ${currentYear}`}
                </div>
                <h5 className="monthToDateEarnings">
                  <span>
                    {monthToDateEarnings
                      ? costFormatter2(updatedCurrency, monthToDateEarnings)
                      : '0.00'}
                  </span>
                </h5>
              </div>
              <div className="sContainerRight">
                <ProtectedComponent
                  level={PERMISSION_LEVELS.WRITE}
                  permission={costForcastingPermission}
                >
                  <ToolTip
                    title={
                      !isAccountSelected
                        ? 'No Account has been selected'
                        : !isAccountActive
                        ? selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE'
                          ? "You don't have any active subscrition"
                          : 'Selected AWS Account is not active'
                        : ''
                    }
                    arrow
                  >
                    <span className="setlimit">
                      <button
                        className="btn btn-outline-primary p-5 pr-20 pl-20 mr-10"
                        onClick={() => setShowResourceWiseLimit(true)}
                        disabled={!isAccountActive}
                      >
                        SET RESOURCE WISE LIMIT
                      </button>

                      <button
                        className="btn btn-outline-primary p-5 pr-20 pl-20 "
                        onClick={() => setShowLimit(true)}
                        disabled={!isAccountActive}
                      >
                        SET LIMIT
                      </button>
                    </span>
                  </ToolTip>
                </ProtectedComponent>

                <div className="mt-10">
                  <SelectedAccount onAccountChange={onAccountChange} />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item className="chartsContainer">
            <Grid container direction="row" spacing={2}>
              <Grid
                item
                className={classes.chartBox}
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Paper elevation={3} className={classes.paper}>
                  <Grid container direction="column" spacing={0}>
                    <Grid item className={classes.chartTitle}>
                      Spend Summary
                    </Grid>
                    <Grid item className={classes.barContainer}>
                      <ResponsiveContainer>
                        <BarChart
                          width={500}
                          height={500}
                          data={barData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="1" vertical={false} />
                          <XAxis dataKey="name" />
                          <YAxis
                            tickFormatter={(e) =>
                              `${costFormatter2(updatedCurrency, e)}`
                            }
                          />
                          <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ fill: '#f5f5f5' }}
                          />

                          <Bar
                            dataKey="Spend"
                            width="40"
                            barSize={40}
                            stackId="a"
                            onClick={onClickBar}
                          >
                            {barData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={barColors[index]}
                                enableBackground="2"
                                cursor="pointer"
                              />
                            ))}
                          </Bar>

                          <Bar
                            dataKey="Forecast"
                            width="40"
                            barSize={40}
                            stackId="a"
                            onClick={onClickBar}
                          >
                            {barData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={forecastColors[index]}
                                enableBackground="2"
                                cursor="pointer"
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid
                item
                className={classes.chartBox}
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Paper elevation={3} className={classes.paper}>
                  <Grid container direction="column" spacing={0}>
                    <Grid item className={classes.chartTitle}>
                      Month to Date Spend by Service
                    </Grid>
                    <Grid item className={classes.barContainer}>
                      <ResponsiveContainer>
                        <PieChart
                          width={500}
                          height={300}
                          className={pieData.length > 0 ? '' : 'defaultPieData'}
                        >
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            dataKey="value"
                            data={pieData.length > 0 ? pieData : defaultPieData}
                            cx={'50%'}
                            cy={'50%'}
                            innerRadius={50}
                            outerRadius={100}
                            fill="#aaaaaa"
                            onClick={
                              pieData.length > 0 ? onClickPie : undefined
                            }
                            onMouseEnter={(e: any, index: any) => {
                              setActiveIndex(index);
                            }}
                            onMouseOut={() => {
                              setActiveIndex(-1);
                            }}
                          >
                            {pieData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  pieData.length > 0
                                    ? pieColors[index % pieData.length]
                                    : defaultColor[index]
                                }
                                cursor="pointer"
                              />
                            ))}
                          </Pie>
                          {pieData.length > 0 ? (
                            <Tooltip
                              content={<CustomTooltipPie />}
                              cursor={{ fill: '#f5f5f5' }}
                            />
                          ) : (
                            ''
                          )}
                          <Legend
                            align="center"
                            verticalAlign="bottom"
                            iconType="square"
                            iconSize={16}
                            layout="vertical"
                            wrapperStyle={{
                              padding:
                                pieData.length > 0
                                  ? '2rem 0'
                                  : '2rem 0 2rem 12rem',
                            }}
                            formatter={renderColorfulLegendText}
                            // content={renderLegend}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showlimit && (
        <SetCostLimitModal
          selected={selectedAccount}
          selectedAccountId={selectedAccountInfo.selectedSubscriptionName}
          selectedAzureId={selectedAccountInfo.selectedAccountName}
          selectedAWSId={selectedAccountInfo.selectedAccountName}
          handleSubmitBtn={handleCostSubmit}
          errorRes={errorRes}
          successRes={successRes}
          sub={IdsData}
          onClose={onClose}
          // currency={currency}
          user={user}
        />
      )}
      {showResourceWiselimit && (
        <SetResourceWiseCostModal
          selected={selectedAccount}
          selectedAccountId={selectedAccountId}
          accountSelected={selectedSubscriptionId}
          sub={filterData}
          resourceList={resourceList}
          selectedAzureId={selectedAccountId}
          selectedAWSId={selectedAccountInfo.selectedAccountName}
          accountIdData={IdsData}
          onClose={onResourceWiseModalClose}
          // currency={currency}
          user={user}
        />
      )}
    </React.Fragment>
  );
};
