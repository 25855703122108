import { APIS } from '../../utils/apiConfig';
import { HttpService } from '../HttpService/HttpService';

export class AppDashboardService {
  constructor(private httpService: HttpService) {}

  async getRightsizingData(selectedCurrency: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_RIGHTSIZING(),
        { params: selectedCurrency }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getIdleResourcesData(selectedCurrency: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_IDLE_RESOURCES(),
        { params: selectedCurrency }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getSpendByServiceData(selectedCurrency: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_SPEND_BY_SERVICE(),
        { params: selectedCurrency }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getSpendForecastData(selectedCurrency: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_SPEND_FORECAST_RESOURCES(),
        { params: selectedCurrency }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getAccountsData(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_Accounts(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getSpendAmountData(selectedCurrency: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_SPEND_AMOUNT(),
        { params: selectedCurrency }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getPerChangeData(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_PER_CHANGE(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getDashboardNotification(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_DASHBOARD_NOTIFICATION(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
