import { History } from 'history';
import {
  ACCOUNT_SELECTION_URL_FIELDS,
  CLOUD_PROVIDER_SERVICES,
  JWT,
  currencies,
} from './constants';
import { ALL_VALUE } from '../pages/Invoice/config';

export const handleError = (e: any, history: History) => {
  if (
    typeof e.error?.message.message === 'string' ||
    typeof e.error?.message === 'string'
  ) {
    if (
      e.error.message == JWT.SESSIONEXPIRED ||
      e.error.message.message == JWT.JWTEXPIRED
    ) {
      console.log('e.message:', e.error.message.message);
      // alert(LOGIN_SESSION.EXPIRED);
      localStorage.removeItem('auth-state');
      // localStorage.removeItem('auth-state');
      history?.push('/login');
    }
  }
};

export const dataResponse = (response: any) => {
  const { data } = response;

  if (data.sc == 1) {
    return data.result;
  }
};

export const capitalizeFirstLetter = (str: any) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

// functions for filtering Aws Account IDs
export const filterAwsAccountId = (awsIdData: any, selectedAwsId: string) => {
  return awsIdData.filter(
    (account: any) => account.AliasName === selectedAwsId
  )[0].AccountId;
};

// functions for filtering Azure IDs and subscriptions.
export const filterAzureId = (azureIdData: any, selectedAzureId: string) => {
  return azureIdData.filter(
    (accounts: any) => accounts.AliasName === selectedAzureId
  )[0].AzureId;
};

export const filterSubscription = (
  subscriptionData: any,
  selectedSubscription: string
) => {
  return subscriptionData.filter(
    (subscriptions: any) => subscriptions.Name === selectedSubscription
  )[0].SubscriptionId;
};

export const costFormatter = (currency: string, value: string | number) => {
  const formatter = new Intl.NumberFormat(
    currency === 'USD' ? 'en-US' : 'en-IN',
    {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  const formattedValue = formatter.format(+value);
  return isNaN(+value) ? value : formattedValue;
};

export const costFormatter2 = (currency: string, value: string | number) => {
  const formatter = new Intl.NumberFormat(currencies[currency].currency, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedValue = formatter.format(+value);

  return isNaN(+value) ? value : formattedValue;
};

export const formatNumber = (num: number) => {
  // Define an array of suffixes to use for different magnitudes.
  const suffixes = ['', 'K', 'M', 'B'];

  // Calculate the magnitude of the number by taking the base-10 logarithm
  // and dividing by 3, then rounding down to the nearest integer.
  const magnitude = Math.floor(Math.log10(num) / 3);

  if (magnitude <= 0) {
    // If magnitude is zero or negative, return the number as is without any suffix.
    return num.toFixed(2);
  }

  // Scale the number by dividing by 10 raised to the power of 3 times the magnitude.
  const scaledNum = num / Math.pow(10, magnitude * 3);

  // Get the appropriate suffix from the suffixes array based on the magnitude.
  const suffix = suffixes[magnitude];

  // Return the scaled number with two decimal places and the suffix appended.
  return `${scaledNum.toFixed(2)}${suffix}`;
};

export const currencySymbolHandler = (currencyCode: string) => {
  return currencies[currencyCode].symbol;
};

// funtion for checking special characters
export const hasSpecialCharacters = (input: string): boolean => {
  const pattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+/;
  return pattern.test(input);
};

export const GlobalQueryParams = (params: any, baseRoute: string) => {
  const [fixedData, remainingData] = Object.entries(params).reduce(
    (acc: any, [key, value]: any) => {
      (ACCOUNT_SELECTION_URL_FIELDS.includes(key) ? acc[0] : acc[1])[
        key
      ] = value;
      return acc;
    },
    [{}, {}]
  );

  const urlParams = new URLSearchParams(remainingData);

  const selectedCurrencyURL: string = fixedData.selectedCurrency
    ? `selectedCurrency=${fixedData.selectedCurrency}`
    : '';

  const cloudSelectionURL =
    Object.keys(fixedData).length > 0
      ? fixedData.cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
        ? fixedData.accountId === ALL_VALUE
          ? selectedCurrencyURL
          : 'accountId=' +
            fixedData.accountId +
            (selectedCurrencyURL && `&${selectedCurrencyURL}`)
        : fixedData.accountId === ALL_VALUE &&
          fixedData.subscriptionId === ALL_VALUE
        ? selectedCurrencyURL
        : fixedData.accountId !== ALL_VALUE &&
          fixedData.subscriptionId === ALL_VALUE
        ? 'azureId=' +
          fixedData.accountId +
          (selectedCurrencyURL && `&${selectedCurrencyURL}`)
        : fixedData.accountId !== ALL_VALUE &&
          fixedData.subscriptionId !== ALL_VALUE
        ? 'subscriptionId=' +
          fixedData.subscriptionId +
          (selectedCurrencyURL && `&${selectedCurrencyURL}`)
        : selectedCurrencyURL
      : '?';

  return (
    `${baseRoute}${cloudSelectionURL}` +
    (Object.keys(remainingData).length ? `&${urlParams}` : urlParams)
  );
};

// export const calculatePercentageChange = (
//   newValue: number,
//   previousValue: number
// ): number => {
//   if (previousValue <= 0) {
//     return 0;
//   }
//   return ((newValue - previousValue) / previousValue) * 100;
// };
