import React from 'react';
import { Card, CardContent, Box } from '@mui/material';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomTooltip from '../MasterComponent/CustomTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const MetricCard = ({
  title,
  value,
  subTitle,
  subValue,
  tooltip,
  // change,
  // changeType,
  unit,
  mainUnit,
}: any) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <div className="flex align-baseline">
          <p className="card-title">{title}</p>

          <CustomTooltip title={tooltip}>
            <HelpOutlineIcon
              style={{
                marginLeft: '0.3rem',
                marginTop: '0.25rem',
                fontSize: '16px',
                cursor: 'pointer',
                color: '#666',
              }}
            />
          </CustomTooltip>
        </div>
        <p className="card-value font-35px">
          {value}
          {mainUnit && <span className="card-value font-30px">{mainUnit}</span>}
        </p>
        <Box
          margin="1rem 0 0.5rem 0"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <p className="fw-600">{subTitle} ago</p>
          {/* <p className="fw-600">Change</p> */}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p className="card-bottom fw-400">
            {subValue}
            {unit}
          </p>
          {/* <p
            className="card-bottom fw-400"
            style={{
              color:
                change === 0
                  ? ''
                  : changeType === 'Up'
                  ? 'green'
                  : changeType === 'Down'
                  ? 'red'
                  : '',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {change === 0 ? (
              ''
            ) : changeType === 'Up' ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
            {change || (0.0).toFixed(2)} %
          </p> */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MetricCard;
