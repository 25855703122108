import React, { Fragment, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Tabs, Tab, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { accountService, userService } from '../../../services';
import { DashboardHeader } from '../../../components/Header/DashboardHeader';
import useStyles from './AccountDetails.style';
import { Radio } from '@material-ui/core';
import { AccountDetailsProps } from '../../../schemas/account.schema';
import { RoutesFun } from '../../../schemas';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { Navigation } from '../../../components/Navigation/Navigation';

import { SubscriptionModal } from '../../../components/Modals/AddSubscription/AddSubscription';
import './AccountDetails.css';
import { useSelector, useDispatch } from 'react-redux';
import { accountState } from '../../../store/index';
import { accountActions } from '../../../store/accountSlice';
import { AccountRow } from './AccountRow';
import { TablePaginationFooter } from '../../../components/Pagination/TablePaginationFooter';
import { handleError } from '../../../utils/config';
import { ALL_VALUE } from '../../Invoice/config';

const accountPageStorageKeys = {
  ACCOUNTS_ROWS_PER_PAGE: 'ACCOUNTS_ROWS_PER_PAGE',
  ACCOUNTS_PAGE_NO: 'ACCOUNTS_PAGE_NO',
};

const getAccountsRowsPerPage = () => {
  const rowsPerPage =
    localStorage.getItem(accountPageStorageKeys.ACCOUNTS_ROWS_PER_PAGE) || 5;
  return +rowsPerPage;
};

const setAccountsRowsPerPage = (value: string) => {
  localStorage.setItem(accountPageStorageKeys.ACCOUNTS_ROWS_PER_PAGE, value);
};

const getAccountsPageNo = () => {
  const pageNo =
    localStorage.getItem(accountPageStorageKeys.ACCOUNTS_PAGE_NO) || 0;
  return +pageNo;
};

const setAccountsPageNo = (value: string) => {
  localStorage.setItem(accountPageStorageKeys.ACCOUNTS_PAGE_NO, value);
};

export const AccountDetailsPage2: React.FC = () => {
  const Routes = RoutesFun();
  const dispatch = useDispatch();
  const accountData = useSelector(accountState);
  const selectedAccount = accountData.selectedAccount;
  const [user, setUser] = useState<any>();
  const isAccountSelected = accountData.isAccountSelected;
  const classes = useStyles();
  const [loader, setLoader] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [subscriptionBtn, setSubscriptionBtn] = React.useState(true);

  let cloudProviderInitial: string = 'NULL';
  let valueInitial: number = 0;
  let initialPage: number = 0;

  if (user?.CloudProvider) {
    cloudProviderInitial = user?.CloudProvider.toUpperCase();

    valueInitial =
      cloudProviderInitial === 'AWS'
        ? 0
        : cloudProviderInitial === 'AZURE'
        ? 1
        : cloudProviderInitial === 'GOOGLE'
        ? 2
        : 0;

    if (!isAccountSelected) {
      initialPage = 0;
    } else {
      initialPage = getAccountsPageNo();
    }
  }

  const userDataHandler = async () => {
    try {
      const userData = await userService.getUserDetails();
      if (userData) {
        setUser(userData);
      }
      return userData;
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const [cloudProvider, setcloudProvider] = React.useState<string>(
    cloudProviderInitial
  );
  const [value, setValue] = React.useState(valueInitial);
  const [data, setData] = React.useState<AccountDetailsProps[]>([]);
  const [filteredData, setFilteredData] = React.useState<AccountDetailsProps[]>(
    data
  );

  useEffect(() => {
    userDataHandler();
  }, []);

  //----------------------- table-------------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(getAccountsRowsPerPage);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setAccountsPageNo(String(newPage));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (data.length > 0) {
      setAccountsRowsPerPage(event.target.value);
      setRowsPerPage(parseInt(event.target.value, 10));
    } else {
      setRowsPerPage(5);
    }

    setAccountsPageNo(String(0));
    setPage(0);
  };
  //--------------------------------------------------------------

  useEffect(() => {
    setcloudProvider(cloudProviderInitial);
    setValue(valueInitial);
    setPage(initialPage);
  }, [cloudProviderInitial, valueInitial]);

  const [awsData, setAwsData] = React.useState<AccountDetailsProps[]>([]);
  const [azureData, setAzureData] = React.useState<AccountDetailsProps[]>([]);
  const [googleData, setGoogleData] = React.useState<AccountDetailsProps[]>([]);

  const [errMsg, setErrMsg] = React.useState('');
  const history = useHistory();

  const [msg, setMsg] = React.useState(false);

  const [isNetworkError, setNetworkError] = React.useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = React.useState(
    false
  );
  const [selectedAccountData, setSelectedAccountData] = React.useState<any>([]);

  const handleSubscriptionModal = () => {
    setOpenSubscriptionModal(true);
  };

  const onClose = () => {
    setOpenSubscriptionModal(false);
    getAllAccounts();
  };

  const getCloudProvider = (value: number): string => {
    let cloudProvider: string = '';
    if (value === 0) {
      cloudProvider = 'AWS';
    }
    if (value === 1) {
      cloudProvider = 'AZURE';
    }
    if (value === 2) {
      cloudProvider = 'GOOGLE';
    }
    return cloudProvider;
  };

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    const cloudProvider: string = getCloudProvider(value);
    setcloudProvider(cloudProvider);
    setValue(value);
    fetchData(value);
    setSearchInput('');

    // setSubscriptionFetchedData([]);
    setAccountsPageNo(String(0));
    setPage(0);
  };

  useEffect(() => {
    selectAccount(cloudProvider, filteredData[0]?.Id);
  }, [cloudProvider, filteredData[0]?.Id]);

  const fetchData = (value: number) => {
    switch (value) {
      case 0:
        setData(awsData);
        setFilteredData(awsData);
        break;
      case 1:
        setData(azureData);
        setFilteredData(azureData);
        break;
      case 2:
        setData(googleData);
        setFilteredData(googleData);
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    history.push(Routes.CreateAccount);
  };

  const getAllAccounts = async (from?: string) => {
    try {
      const result = await accountService.getAccountDetails(cloudProvider);
      setFilteredData(result);

      switch (value) {
        case 0:
          setAwsData(result);
          break;
        case 1:
          setAzureData(result);
          break;
        case 2:
          setGoogleData(result);
          break;
        default:
          break;
      }

      if (
        result.length % rowsPerPage === 0 &&
        page !== 0 &&
        from === 'delete'
      ) {
        setPage((prevPage) => {
          setAccountsPageNo(String(prevPage - 1));
          return prevPage - 1;
        });
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const openEditPage = (row: any) => {
    // const cloudProvider: string = getCloudProvider(value);
    const company = localStorage.getItem('CompanyName');

    history.push({
      pathname: `/${company}/accounts/edit-account/${cloudProvider}/${row.Id}`,
    });
  };

  const deleteAccountDetails = async (row: AccountDetailsProps) => {
    try {
      setLoader(true);
      const obj: { Id: number; CloudProvider: string } = {
        Id: row.Id,
        CloudProvider: cloudProvider,
      };

      await accountService.deleteAccount(obj);
      let accountData;

      if (row.CloudProvider === selectedAccount?.CloudProvider) {
        accountData = { CloudProvider: 'AWS' };
        dispatch(
          accountActions.replaceAccountState({
            selectedAccount: accountData,
            isAccountSelected: false,
          })
        );
      } else {
        window.location.reload();
      }

      dispatch(
        accountActions.replaceAccountState({
          ...selectedAccount,
          selectedAccountName: ALL_VALUE,
          selectedSubscriptionName: ALL_VALUE,
          selectedAccountId: ALL_VALUE,
          selectedSubscriptionId: ALL_VALUE,
          selectedSubData: [],
        })
      );
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        setErrMsg(e.error.message);
        setMsg(true);
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      getAllAccounts();
    }
  };

  const handleFieldChange = (target: any) => {
    setSearchInput(target.value);
  };

  const searchAccounts = () => {
    if (searchInput === '') {
      fetchData(value);
    } else {
      globalSearch(searchInput);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      searchAccounts();
    }
  };

  const globalSearch = (searchText: string) => {
    if (searchText) {
      const filteredData = data.filter((value: any) => {
        return (
          value.AliasName.charAt(0).toLowerCase() ===
            searchText.toLowerCase() ||
          value.AliasName.toLowerCase().includes(searchText.toLowerCase()) ||
          value.ServiceType.charAt(0).toLowerCase() ===
            searchText.toLowerCase() ||
          value.ServiceType.toLowerCase().includes(searchText.toLowerCase()) ||
          value.AzureId.charAt(0).toLowerCase() === searchText.toLowerCase() ||
          value.AzureId.toLowerCase().includes(searchText.toLowerCase()) ||
          value.createdAt.toLowerCase().includes(searchText.toLowerCase()) ||
          value.updatedAt.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredData(filteredData);

      if (filteredData.length <= 0) {
        setRowsPerPage(5);
      }
    }
  };

  const selectAccount = async (selectedCloudProvider: any, Id: any) => {
    try {
      setLoader(true);
      if (selectedCloudProvider) {
        const params = {
          Id,
          CloudProvider: selectedCloudProvider,
        };
        await accountService.setAccount(params);

        const accountData = await accountService.getSelectedAccount();

        dispatch(
          accountActions.replaceAccountState({
            selectedAccount: accountData,
            isAccountSelected: true,
            selectedAccountName: ALL_VALUE,
            selectedSubscriptionName: ALL_VALUE,
            selectedAccountId: ALL_VALUE,
            selectedSubscriptionId: ALL_VALUE,
            selectedSubData: [],
          })
        );
      }
      setSelectedAccountData([accountData]);
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        setErrMsg(e.error.message);
        setMsg(true);
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   if (filteredData) {
  //     selectAccount(cloudProvider, filteredData[0]?.Id);
  //   }
  // }, [filteredData]);

  useEffect(() => {
    cloudProvider !== 'NULL' && getAllAccounts();
  }, [value, cloudProvider, selectedAccount]);

  useEffect(() => {
    {
      selectedAccount && setSelectedAccountData([selectedAccount]);
    }
  }, [value, cloudProvider, selectedAccount]);

  useEffect(() => {
    if (cloudProvider == 'AZURE') {
      setSubscriptionBtn(true);
    } else {
      setSubscriptionBtn(false);
    }
  }, [cloudProvider]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <Fragment>
      {loader ? <LoadingScreen /> : ''}
      <DashboardHeader />
      <div className="innerContainer">
        <div className="splitLayout">
          <div className="leftNav">
            <Navigation />
          </div>

          {
            <div className="rightContent">
              <div className="topSearchRefBar p-static">
                <div className="addAccountBtns">
                  <button
                    className="btn btn-primary px-15 accountBtn"
                    onClick={handleClick}
                  >
                    <span className="addIcon">+</span>
                    <span className="ml-5">NEW ACCOUNT</span>
                  </button>

                  {subscriptionBtn &&
                  selectedAccount &&
                  selectedAccount.CloudProvider.toUpperCase() === 'AZURE' ? (
                    <button
                      className="btn btn-outline-primary px-15 accountBtn ml-20 "
                      onClick={handleSubscriptionModal}
                    >
                      <span className="addIcon">+</span>
                      <span className="ml-5">SUBSCRIPTION</span>
                    </button>
                  ) : null}
                </div>

                <div className="searchBar pr-0 pl-0 w-70 accSearch">
                  <TextField
                    label="SEARCH ACCOUNT"
                    id="filled-size-small"
                    // inputRef={searchRef}
                    // defaultValue={uData}
                    variant="outlined"
                    size="small"
                    // onBlur={(e) => searchValue(e.target)}
                    onChange={(e) => handleFieldChange(e.target)}
                    className="w-100"
                    onKeyDown={handleKeyDown}
                  />
                  <input
                    type="submit"
                    value="Search"
                    className="btn-primary btn ml-10"
                    onClick={searchAccounts}
                  />
                </div>
              </div>

              <Grid container spacing={0}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.headerGrid}
                >
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    className="tabCont"
                  >
                    <Tab
                      label={
                        <div className="flex">
                          <div>
                            <Radio
                              checked={cloudProvider == 'AWS' ? true : false}
                              value="a"
                              color="primary"
                              name="radio-button-demo"
                            />
                          </div>

                          <div className="mt-5 ml-10">AWS</div>
                        </div>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <div className="flex">
                          <div>
                            <Radio
                              checked={cloudProvider == 'AZURE' ? true : false}
                              // onClick={() =>
                              //   selectAccount('AZURE', filteredData[0].Id)
                              // }
                              value="a"
                              color="primary"
                              name="radio-button-demo"
                            />
                          </div>

                          <div className="mt-5 ml-10">AZURE</div>
                        </div>
                      }
                      value={1}
                    />
                    <Tab
                      label={
                        <div className="flex">
                          <div>
                            <Radio
                              disabled
                              // onClick={() =>
                              //   selectedAccount?.CloudProvider == 'GOOGLE'
                              //     ? undefined
                              //     : selectAccount('GOOGLE')
                              // }
                              value="a"
                              color="primary"
                              name="radio-button-demo"
                            />
                          </div>

                          <div className="mt-5 ml-10">GOOGLE</div>
                        </div>
                      }
                      value={2}
                    />
                  </Tabs>
                </Grid>

                <TableContainer className="accContainer " component={Paper}>
                  <Table
                    aria-label="collapsible table"
                    className="table adminTable adminPanelTable"
                  >
                    <TableHead className="admintableHead">
                      <TableRow>
                        <TableCell
                          align="left"
                          className="rowRadio"
                        ></TableCell>
                        <TableCell align="left" className="adminTableheading">
                          CFX Name
                        </TableCell>
                        {value === 0 ? (
                          <TableCell align="left" className="adminTableheading">
                            IAM User Id
                          </TableCell>
                        ) : null}
                        {value == 1 ? (
                          <TableCell align="left" className="adminTableheading">
                            Azure Id
                          </TableCell>
                        ) : value == 0 ? (
                          <TableCell align="left" className="adminTableheading">
                            AWS Id
                          </TableCell>
                        ) : (
                          <TableCell align="left" className="adminTableheading">
                            GOOGLE Id
                          </TableCell>
                        )}
                        {value == 1 ? (
                          <TableCell align="left" className="adminTableheading">
                            Type
                          </TableCell>
                        ) : null}
                        <TableCell align="left" className="adminTableheading">
                          Created Date
                        </TableCell>
                        <TableCell align="left" className="adminTableheading">
                          Last Updated
                        </TableCell>
                        <TableCell align="left" className="adminTableheading">
                          Status
                        </TableCell>
                        <TableCell align="left" className="adminTableheading">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? filteredData.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : filteredData
                      ).map((row: AccountDetailsProps, i: number) => (
                        <AccountRow
                          key={'data' + i}
                          row={row}
                          // selectAccount={selectAccount}
                          value={value}
                          cloudProvider={cloudProvider}
                          openEditPage={openEditPage}
                          deleteAccountDetails={deleteAccountDetails}
                        />
                      ))}
                      {!loader &&
                      filteredData &&
                      filteredData.length <= 0 &&
                      emptyRows >= 0 ? (
                        <TableRow className="tableContent">
                          <TableCell colSpan={12}>
                            <>
                              {value == 0 || value == 1
                                ? ' No result found'
                                : ' Coming Soon'}
                            </>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>

                    {filteredData.length > 5 ? (
                      <TablePaginationFooter
                        filteredData={filteredData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    ) : null}
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          }
        </div>
      </div>
      )
      {openSubscriptionModal ? (
        <SubscriptionModal
          show={openSubscriptionModal}
          onClose={onClose}
          subscriptionDetail={selectedAccountData}
        />
      ) : null}
    </Fragment>
  );
};
