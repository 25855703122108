import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import './IdleInstances.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IdleInstanceRequirementModal } from '../../components';
// import { IdleInstanceProps } from '../../schemas/idleInstance.schema';
import { useHistory } from 'react-router';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import { accountState } from '../../store/index';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { config } from './config';
import { costFormatter2, handleError } from '../../utils/config';
import { ReactComponent as QuestionMark } from '../../assets/images/questionMark.svg';
import { ALL_VALUE } from '../Invoice/config';

/*-----Redirect to 403 page---------- */
import { useSelector } from 'react-redux';
import { userState } from '../../store/index';
import { FEATURES } from '../../utils/constants';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';

const storageKeys = {
  IR_SELECTED_SUBSCRIPTION: 'IR_SELECTED_SUBSCRIPTION',
  IR_TYPE_SELECTED_SUBSCRIPTION: 'IR_TYPE_SELECTED_SUBSCRIPTION',
};

export const getSelectedSubscription = (): string => {
  return (
    localStorage.getItem(storageKeys.IR_SELECTED_SUBSCRIPTION) || ALL_VALUE
  );
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.IR_SELECTED_SUBSCRIPTION, value);
};

export const setSelectedResource = (value: string): void => {
  localStorage.setItem(storageKeys.IR_TYPE_SELECTED_SUBSCRIPTION, value);
};

export const IdleInstance: React.FC = () => {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = useSelector(accountState).selectedAccount;
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');

  /*-----Redirect to 403 page---------- */
  const [pageLoad, setPagDone] = React.useState(false);
  const userData = useSelector(userState);
  const user = userData.userData?.plan.features;

  const [
    openIdleInstanceRequirementModal,
    setOpenIdleInstanceRequirementModal,
  ] = React.useState(false);

  const [idleInstanceList, setIdleInstanceList] = React.useState({});

  const [isNetworkError, setNetworkError] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );

  const cloudProvider:
    | string
    | undefined = selectedAccount?.CloudProvider.toUpperCase();
  const company = localStorage.getItem('CompanyName');

  const getIdleInstanceList = async () => {
    try {
      setLoader(true);
      const params = {
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };
      const rows = await config[cloudProvider as string].getIdleInstanceList(
        params
      );

      if (rows) {
        setUpdatedCurrency(selectedCurrency);
        setIdleInstanceList(rows);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const closeModal = () => {
    setOpenIdleInstanceRequirementModal(false);
  };

  const isImageExits = (imageName: string) => {
    try {
      require(`../../assets/images/IdleServices/${imageName}.png`);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    getIdleInstanceList();
  }, [
    selectedAccountId,
    selectedSubscriptionId,
    selectedAccount,
    selectedCurrency,
  ]);

  useEffect(() => {
    // setLoader(true);
    setPagDone(false);
    setTimeout(() => {
      const name = user?.includes(FEATURES.IDLE_RESOURCES);
      if (name) {
        setPagDone(true);
        // setLoader(false);
      }
    }, 1000);
  }, [user]);

  const totalIdleResourcePrice: any = Object.values(idleInstanceList).reduce(
    (total: any, current: any) => {
      total += Number(current.MTDCost);
      return total;
    },
    0
  );

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  //Pagination section//

  const recordsPerPage = 12;

  const lastIndex = currentPage * recordsPerPage;

  const firstIndex = lastIndex - recordsPerPage;

  const records = Object.entries(idleInstanceList).slice(firstIndex, lastIndex);

  const nPages = Math.ceil(
    Object.values(idleInstanceList).length / recordsPerPage
  );
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // account selection
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  return (
    <Fragment>
      {loader ? <LoadingScreen /> : ''}
      {pageLoad ? (
        <>
          <div className="sContainer mb-15">
            <div className="tabResourceHeading p-0 d-flex">
              <div>Idle Resources</div>

              <div className="mr-20">
                <span
                  className="instanceInfo mt-2"
                  onClick={() => setOpenIdleInstanceRequirementModal(true)}
                >
                  <QuestionMark width={15} />
                </span>
              </div>
              <div> | &nbsp;</div>
              <div>
                Total Cost of idle resources :{' '}
                <span className="tabResourceHeading fw-700">
                  {costFormatter2(updatedCurrency, totalIdleResourcePrice)}
                </span>
              </div>
            </div>
            <div>
              <SelectedAccount onAccountChange={onAccountChange} />
            </div>
          </div>

          {!loader && !Object.values(idleInstanceList).length && !records ? (
            <div className="noRecordsFound">
              No Idle Resources Found. Please Visit Later
            </div>
          ) : (
            <>
              <Grid className="idleCardContainer" container spacing={2}>
                {records &&
                  records?.map(([key, value]: any) => (
                    <Grid item xs={12} sm={4} md={3} key={key}>
                      <div
                        onClick={() => {
                          history.push({
                            pathname:
                              '/' + company + '/idle-resource/' + value.Type,
                            state: {
                              SubscriptionId: selectedSubscriptionId,
                              ServiceType: value.Type,
                              AccountId: selectedAccountId,
                            },
                          });
                        }}
                        className="idleBox"
                      >
                        <div style={{ width: '12vw' }}>
                          <div className="font-18px">{key}</div>
                          <div className="pt-10">
                            <p>Total Resources: {value.NumberOfResources}</p>
                            <p>
                              MTD Cost:{' '}
                              <span className="fw-700">
                                {costFormatter2(updatedCurrency, value.MTDCost)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          {isImageExits(value.Type) ? (
                            <img
                              className="idleImg"
                              src={
                                // eslint-disable-next-line @typescript-eslint/no-var-requires
                                require(`../../assets/images/IdleServices/${value.Type}.png`)
                                  .default
                              }
                              alt={`${value.Type}`}
                            />
                          ) : (
                            <img className="idleImg" alt={`${value.Type}`} />
                          )}
                        </div>
                      </div>
                    </Grid>
                  ))}
              </Grid>
              {!loader && Object.values(idleInstanceList).length ? (
                <div className="paginationStack">
                  <div>
                    <ul className="paginationList">
                      <li>
                        <Button
                          onClick={prePage}
                          variant="contained"
                          className="navButton"
                          color="primary"
                        >
                          <ChevronLeftIcon style={{ color: '#fff' }} />
                        </Button>
                      </li>
                      {pageNumbers.map((n, i) => (
                        <li
                          className={`pageItem ${
                            currentPage === n ? 'activePagination' : ''
                          }`}
                          key={i}
                        >
                          <a onClick={() => handlePageChange(n)}>{n}</a>
                        </li>
                      ))}
                      <li>
                        <Button
                          onClick={nextPage}
                          variant="contained"
                          className="navButton"
                          color="primary"
                        >
                          <ChevronRightIcon style={{ color: '#fff' }} />
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </>
          )}
          {openIdleInstanceRequirementModal && (
            <IdleInstanceRequirementModal
              show={openIdleInstanceRequirementModal}
              onClose={closeModal}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default React.memo(IdleInstance);
