import React from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';

interface FixProps {
  show: boolean;
  onClose?: { (): void };
  onSubmit: {
    (): void;
  };
}

const SessionExpiryModal: React.FC<FixProps> = ({ show, onSubmit }) => {
  return (
    <Modal
      isOpen={show}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Session Expired</span>
          </div>
        </Grid>
        <Grid item md={12} className="modalBody pt-20 addEmailModalHeight">
          Session Expired!!!
        </Grid>
        <div className="mt-0 modalFooter">
          <button
            type="submit"
            className={'btn btn-primary ml-10 cursor-notAllowed '}
            onClick={() => onSubmit()}
          >
            Ok
          </button>
        </div>
      </Grid>
    </Modal>
  );
};

export default SessionExpiryModal;
