import moment from 'moment';
import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import '../Modal.css';
import { useForm, Controller } from 'react-hook-form';
import { invoiceService } from '../../../services';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
// import { useUserDataActions } from '../../../context/GlobalUserData';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../../store/userSlice';
import { userState, accountState } from '../../../store/index';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
// import { useUserData } from '../../../context/GlobalUserData';

interface AddSubscription {
  SubscriptionId: string;
  AccountId: string;
  ReportType: string;
}

const storageKeys = {
  RIGHT_SIZING_ROWS_PER_PAGE: 'Right_Sizing_ROWS_PER_PAGE',
  PREV_GEN_ROWS_PER_PAGE: 'PREV_GEN_ROWS_PER_PAGE,',
  RIGHT_SIZING_PAGE_NO: 'RIGHT_SIZING_PAGE_NO',
  PREV_GEN_PAGE_NO: 'PREV_GEN_PAGE_NO',
  RIGHTSIZING_SELECTED_SUBSCRIPTION: 'RIGHTSIZING_SELECTED_SUBSCRIPTION',
};
export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.RIGHTSIZING_SELECTED_SUBSCRIPTION, value);
};

export const InvoiceModal = (props: any) => {
  const { onClose, accountList } = props;
  const [loader, setLoader] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const [selected, setSelected] = React.useState('');
  // 'Billing Period'
  const [reportType, setReportType] = React.useState('');
  const [exportType, setExportType] = React.useState('PDF');
  const [downloadCheck, setDownloadCheck] = React.useState(false);
  const [emailCheck, setEmailCheck] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState('');
  const [startDate, setStartDate] = React.useState<any>(
    moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState<any>(
    moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD')
  );
  const [invoiceData, setInvoiceData] = React.useState<any>([]);
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [customError, setCustomError] = React.useState('');

  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  // const { setSuccessMessage } = useUserDataActions();
  // const { user, selectedAccount } = useUserData();

  const dispatch = useDispatch();
  const userData = useSelector(userState);
  const accountData = useSelector(accountState);
  const selectedAccount = accountData.selectedAccount;
  const [billingType, setBillingType] = React.useState(
    selectedAccount?.CloudProvider === 'AWS' ? 'Monthly' : 'Billing Period'
  );
  const history = useHistory();

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const {
    register,
    handleSubmit,
    formState,
    errors,
    getValues,
    control,
  } = useForm<AddSubscription>({
    mode: 'onChange',
  });

  if (networkError) {
    throw new Error(networkError);
  }

  const handleSubscriptionChange = (event: any) => {
    setSelected(event.target.value);
  };
  const handleBillingTypeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBillingType((event.target as HTMLInputElement).value);
    setSelectedDate(new Date());
  };
  const handleExportTypeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExportType((event.target as HTMLInputElement).value);
  };
  const handleReportChange = (event: any) => {
    setReportType(event.target.value);
  };
  const handleDownloadCheckbox = (e: any) => {
    setDownloadCheck(e.target.checked);
  };
  const handleEmailCheckbox = (e: any) => {
    setEmailCheck(e.target.checked);
  };
  const handleStartDateText = (e: any) => {
    setStartDate(moment(e).format('YYYY-MM-DD'));
  };
  const handleEndDateText = (e: any) => {
    setEndDate(moment(e).format('YYYY-MM-DD'));
  };

  const exportToFile = (fileBuffer: string, fileName: string) => {
    const byteCharacters = atob(fileBuffer);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    const url = URL.createObjectURL(blob);
    const dummyLink = document.createElement('a');
    dummyLink.href = url;
    dummyLink.download = `${fileName}`;
    document.body.appendChild(dummyLink);
    dummyLink.click();
  };

  const checkSource = () => {
    if (downloadCheck) {
      if (emailCheck) return 'Both';
      else return 'Download';
    } else if (emailCheck) return 'Mail';
  };

  const OnSubmit = async (e: any) => {
    setCustomError('');
    setButtonStatus(true);

    const data = {
      ...(selectedAccount?.CloudProvider.toUpperCase() ===
      CLOUD_PROVIDER_SERVICES.AZURE
        ? { SubscriptionId: e.SubscriptionId }
        : { AccountId: e.AccountId }),
      ReportType: e.ReportType,
      BillingType: billingType,
      FileFormat: exportType,
      InvoiceSource: checkSource(),

      ...(billingType === 'Custom'
        ? { StartDate: startDate, EndDate: endDate }
        : { BillingMonth: moment(selectedDate).format('YYYY-MM') }),
    };
    try {
      setLoader(true);
      const result = await invoiceService.CreateInvoice(data);

      if (result?.message) {
        dispatch(
          userActions.replaceUserState({
            userData: userData.userData,
            isUserSelected: userData.isUserSelected,
            successMessage: result?.message,
          })
        );
        // setSuccessMessage(result?.message);
      } else {
        dispatch(
          userActions.replaceUserState({
            userData: userData.userData,
            isUserSelected: userData.isUserSelected,
            successMessage: result,
          })
        );

        // setSuccessMessage(result);
      }
      onClose(true);
      setLoader(false);
      exportToFile(result.fileBuffer, result.fileName);
    } catch (e: any) {
      setButtonStatus(false);
      if (e && e.error && e.error.message) {
        handleError(e, history);
        setCustomError(
          e.error.message
            .replace('SubscriptionId', 'Subscription Name')
            .replace('InvoiceSource', 'Invoice Option')
        );
      }

      setLoader(false);
      setNetworkError(e === 'Network Error' ? e : '');
    }
    // finally {
    //   setButtonStatus(false);
    // }
  };

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
        className="fixModal"
        {...props}
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">Create Invoice</span>
              <span
                className="modalClose"
                onClick={() => {
                  onClose && onClose(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
          <Grid item md={12}>
            {/* <a download="a.xlsx" href='data:application/xlsx:base64,''>he</a>  */}
            <form
              noValidate
              onSubmit={handleSubmit(OnSubmit)}
              autoComplete="off"
              className="w-100"
            >
              <Grid item md={12} className="modalBody pt-20">
                {selectedAccount?.CloudProvider.toUpperCase() ===
                CLOUD_PROVIDER_SERVICES.AZURE ? (
                  <FormControl
                    className={
                      'w-100 vmSelect ' +
                      (accountList.length === 0 ? 'disabled' : '')
                    }
                    variant="outlined"
                  >
                    <InputLabel id="demo-controlled-open-select-label">
                      Select Subscription
                    </InputLabel>
                    <Controller
                      name="SubscriptionId"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={accountList.length === 0 ? 'norec' : ''}
                      render={({ onChange, value, name }) => (
                        <Select
                          label="Subscription Name"
                          onChange={onChange}
                          value={value}
                          name={name}
                        >
                          <MenuItem value="norec" key="h" disabled>
                            No Records Found
                          </MenuItem>
                          {accountList.map((sName: any, i: any) => (
                            <MenuItem key={i} value={sName.SubscriptionId}>
                              {sName.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />

                    {errors.SubscriptionId && (
                      <div className="regMsg mt-5">
                        Subscription Name is Required
                      </div>
                    )}
                  </FormControl>
                ) : (
                  <FormControl
                    className={
                      'w-100 vmSelect ' +
                      (accountList.length === 0 ? 'disabled' : '')
                    }
                    variant="outlined"
                  >
                    <InputLabel id="demo-controlled-open-select-label">
                      Select Account
                    </InputLabel>
                    <Controller
                      name="AccountId"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={accountList.length === 0 ? 'norec' : ''}
                      render={({ onChange, value, name }) => (
                        <Select
                          label="Account Name"
                          onChange={onChange}
                          value={value}
                          name={name}
                        >
                          <MenuItem value="norec" key="h" disabled>
                            No Records Found
                          </MenuItem>
                          {accountList.map((sName: any, i: any) => (
                            <MenuItem key={i} value={sName.AccountId}>
                              {sName.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />

                    {errors.AccountId && (
                      <div className="regMsg mt-5">
                        Account Name is Required
                      </div>
                    )}
                  </FormControl>
                )}
                <FormControl
                  className="w-100 vmSelect"
                  variant="outlined"
                  style={{ margin: '18px 0 0 0' }}
                >
                  <InputLabel id="demo-controlled-open-select-label">
                    Report Format
                  </InputLabel>
                  <Controller
                    name="ReportType"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={reportType ? reportType : ''}
                    render={({ onChange, value, name }) => (
                      <Select
                        label="Report Format"
                        onChange={onChange}
                        value={value}
                        name={name}
                      >
                        <MenuItem value="Summary">Summary</MenuItem>
                        <MenuItem value="Detailed">Detailed</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.ReportType && (
                    <div className="regMsg mt-5">Report Format is Required</div>
                  )}
                </FormControl>
                <FormControl className="export mt-10">
                  <FormLabel
                    component="legend"
                    className="font-13px fw-500 exportLabel"
                  >
                    Billing Type :
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="BillingType"
                    name="BillingType"
                    defaultValue="top"
                    value={billingType}
                    className="exportRadio"
                    onChange={handleBillingTypeValueChange}
                  >
                    {selectedAccount?.CloudProvider === 'AWS' ? null : (
                      <FormControlLabel
                        value="Billing Period"
                        control={<Radio color="primary" />}
                        label="Billing Period"
                        className="w-40 mr-15"
                      />
                    )}
                    <FormControlLabel
                      value="Monthly"
                      control={<Radio color="primary" />}
                      label="Monthly"
                      className="w-30 mr-15"
                    />
                    <FormControlLabel
                      value="Custom"
                      control={<Radio color="primary" />}
                      label="Custom"
                      className="w-30 mr-15"
                    />
                  </RadioGroup>
                </FormControl>
                {billingType !== 'Custom' && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid className="text-right w-100 mt-5">
                      <DatePicker
                        autoOk={true}
                        className="w-100"
                        // format='MM/yyyy'
                        // variant='dialog'
                        inputVariant="outlined"
                        variant="inline"
                        openTo="month"
                        views={['year', 'month']}
                        label="Select Month"
                        // helperText='Start from year selection'
                        value={selectedDate}
                        onChange={handleDateChange}
                        maxDate={moment(new Date())}
                        minDate={moment(new Date()).subtract(12, 'months')}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}

                {billingType === 'Custom' && (
                  <div className="cDateTime mt-5">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid className="text-right w-100 mr-5">
                        <DatePicker
                          autoOk={true}
                          className="w-100"
                          format="dd/MM/yyyy"
                          // variant='dialog'
                          inputVariant="outlined"
                          variant="inline"
                          // openTo='month'
                          // views={['year', 'month']}
                          minDateMessage="End Month must be Greater than Start Month"
                          label="Start Date"
                          // helperText='Start from year selection'
                          onChange={handleStartDateText}
                          value={startDate}
                          // maxDate= {moment(new Date())}
                          maxDate={moment(new Date())
                            .subtract(1, 'd')
                            .format('YYYY-MM-DD')}
                          minDate={moment(new Date()).subtract(12, 'months')}
                          // minDate= {moment(selectedDate).add(1,'months')}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid className="text-right w-100">
                        <DatePicker
                          autoOk={true}
                          className="w-100"
                          format="dd/MM/yyyy"
                          // variant='dialog'
                          inputVariant="outlined"
                          variant="inline"
                          // openTo='month'
                          // views={['year', 'month']}
                          minDateMessage="End Date must be Greater than Start Date"
                          label="End Date"
                          // helperText='Start from year selection'
                          onChange={handleEndDateText}
                          value={endDate}
                          // maxDate= {moment(new Date())}
                          maxDate={moment(new Date())
                            .subtract(1, 'd')
                            .format('YYYY-MM-DD')}
                          minDate={moment(startDate)}
                          // minDate= {moment(startDate).add(1,'days')}
                          // minDate= {moment(selectedDate).add(1,'months')}
                        />
                      </Grid>
                      {/* <TextField
                        id="datetime-local-end"
                        type="date"
                        variant="outlined"
                        label="End Date"
                        name="ScheduledEndDateTime"
                        onChange={handleEndDateText}
                        value={endDate}
                        // defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: moment(new Date()).format('YYYY-MM-DD'),
                        }}

                        onKeyDown={(e) => e.preventDefault()}
                        
                      /> */}
                    </MuiPickersUtilsProvider>
                  </div>
                )}
                {/* <div className="DateInput"> */}
                <div className=" w-100">
                  <FormControl className="export">
                    <FormLabel
                      component="legend"
                      className="font-13px fw-500 exportLabel"
                    >
                      Export Type :
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      className="exportRadio"
                      value={exportType}
                      onChange={handleExportTypeValueChange}
                    >
                      <FormControlLabel
                        value="PDF"
                        control={<Radio color="primary" />}
                        label="PDF"
                        className="w-38"
                      />
                      <FormControlLabel
                        value="XLSX"
                        control={<Radio color="primary" />}
                        label="XLSX"
                        className="w-38"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div>
                  <FormControl className="export">
                    <FormLabel className="font-13px fw-500 exportLabel">
                      Invoice Option* :
                    </FormLabel>
                    <FormControlLabel
                      value="Download"
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e: any) => handleDownloadCheckbox(e)}
                        />
                      }
                      label="DOWNLOAD"
                      className="invoiceOption"
                    />
                    <FormControlLabel
                      value="Mail"
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e: any) => handleEmailCheckbox(e)}
                        />
                      }
                      label="EMAIL"
                      className="invoiceOption"
                    />
                  </FormControl>
                </div>
                {customError && (
                  <div className="alert-danger p-2 px-5 mt-10">
                    {customError}
                  </div>
                )}

                <div
                  className={
                    successMsg
                      ? 'alert-success p-5 px-20 mr-10 font-14px responseMsg ' +
                        successMsg
                      : ''
                  }
                >
                  {successMsg}
                </div>
              </Grid>
              <div className="mt-0 modalFooter">
                <button
                  type="submit"
                  className="btn btn-primary ml-10"
                  disabled={
                    buttonStatus ||
                    startDate > endDate ||
                    (accountList.length === 0 ? true : false)
                  }
                >
                  CREATE INVOICE
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    onClose && onClose(false);
                  }}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
