const ProtectedComponent = (props: any) => {
  if (props?.permission) {
    const allowed = props.permission?.Level >= props.level;
    if (typeof props.children === 'function') return props.children(allowed);
    if (allowed) return props.children;
  }
  return null;
};

export default ProtectedComponent;
