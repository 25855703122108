import React, { useEffect, useState, useRef } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { accountState } from '../../store';
import { Grid, TextField } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';

import './ServicesReports.css';
import moment, { Moment } from 'moment'; // Import Moment type from moment library
import * as echarts from 'echarts';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { CLOUD_PROVIDER_SERVICES, ERRORS } from '../../utils/constants';
import { costForecastingService } from '../../services';
import { costFormatter2, handleError } from '../../utils/config';
import { awsRegions, azureLocations, config, customColors } from './config';
import { formatDate } from '../../components/UrlMonitoring/config';
import { ALL_VALUE } from '../Invoice/config';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { exportToFile } from '../../utils/formateXLSX';
import GraphLoader from './GraphLoader';
import { Autocomplete } from '@material-ui/lab';
import CustomButton from '../../components/MasterComponent/Button/CustomButton';

const ServicesReports = () => {
  const history = useHistory();
  const selectedAccountInfo = useSelector(accountState);
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const cloudProvider = selectedAccountInfo?.selectedAccount?.CloudProvider.toUpperCase();
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const [startDate, setStartDate] = useState<any>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<any>(moment().endOf('day'));
  const [isFocused, setIsFocused] = useState<any>(null);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [selectedRegion, setSelectedRegion] = useState<any>('all');
  const [selectedService, setSelectedService] = useState<any>(ALL_VALUE);
  const [selectedGroupBy, setSelectedGroupBy] = useState<any>('day');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const [selectedAccountId, setSelectedAccountId] = React.useState<any>(
    selectedAccountInfo.selectedAccountId
  );
  const [servicesData, setServicesData] = useState<any>({});
  const [isTableLoad, setIsTableLoad] = useState(false);
  const [tableDateColumns, setTableDateColumns] = useState<string[] | null>([]);
  const [servicesList, setServiceList] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<
    Record<string, 'asc' | 'desc' | null>
  >({});
  const [barChartData, setBarChartData] = useState<any>({});

  const getServiceList = async () => {
    try {
      const result = await costForecastingService.getResourceList();
      if (result) {
        const resources = result.map((resource: any) => resource.resoursename);
        setServiceList(resources);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  // BarChart Data function
  interface BarChartProps {
    data: any;
  }

  const generateColors = (count: number) => customColors.slice(0, count);

  const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const chart = echarts.init(chartRef.current!);

      const legend = Object.keys(data);
      const dates = Object.keys(data[legend[0]]).filter(
        (key) => key !== 'totalCost'
      );
      const colors = generateColors(legend.length);

      const series = legend.map((service, index) => ({
        name: service,
        type: 'bar',
        stack: 'total',
        data: dates.map((date) => data[service][date]?.cost || 0),
        itemStyle: {
          color: colors[index], // Use color directly from the generated colors array
        },
      }));

      const options: echarts.EChartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          position: function (point: any) {
            return [point[0] + 10, '10%']; // Adjust the position here
          },
          // Enable the scrollable option for the tooltip
          confine: true,
          enterable: true,
          formatter: (params: any) => {
            const header = `<div class="echarts-tooltip-title">${params[0].axisValue}</div>`;
            const items = params
              .map((param: any) => {
                const colorSpan = `<span class="echarts-tooltip-color" style="background-color: ${param.color}"></span>`;
                return `${colorSpan} <span class="echarts-tooltip-name">${
                  param.seriesName
                } :</span> <span class="echarts-tooltip-value">${costFormatter2(
                  updatedCurrency,
                  param.value
                )}</span>`;
              })
              .join('<br/>');

            return `<div class="echarts-tooltip">${header}${items}</div>`;
          },
        },
        legend: {
          data: legend,
          padding: [20, 0, 0, 0],
          show: selectedService === ALL_VALUE ? true : false,
          bottom: selectedService === ALL_VALUE ? 0 : '-3%',
          left: '10%',
          width: '80%',
          // right: 'auto',
          // align: 'auto',
          // bottom: '80%',
          // orient: 'horizontal',
          // type: 'scroll',
          // right: -100,

          // height: 100,
          // width: 300,

          // left: '1%',
          // top: '20%',
        },

        grid: {
          // left: '3%',
          // right: '20%',
          bottom: selectedService === ALL_VALUE ? '22%' : '10%',
          // containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            show: true,
            // Enable the scrollable option for the dataZoom
          },
        ],
        xAxis: {
          type: 'category',
          data: dates,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '${value}', // Format yAxis values with $ sign
          },
        },
        series: series,
      };

      chart.setOption(options);

      const tooltipElement: any = chart
        .getDom()
        .querySelector('.echarts-tooltip');
      if (tooltipElement) {
        tooltipElement.style.maxHeight = '100px'; // Set the desired maximum height
        tooltipElement.style.overflowY = 'auto';
      }

      return () => {
        chart.dispose();
      };
    }, [data]);

    return <div ref={chartRef} style={{ width: '100%', height: '600px' }} />;
  };

  useEffect(() => {
    !servicesList.length && getServiceList();
  }, [selectedAccountInfo]);

  const getServiceCostReport = async () => {
    try {
      setIsTableLoad(true);
      // const globalQueryData = {
      //   cloudProvider,
      //   accountId: selectedAccountId,
      //   subscriptionId: selectedSubscriptionId,
      //   ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      // };
      // const data = {
      //   startDate: formatDate(startDate?._d),
      //   endDate: formatDate(endDate?._d),
      //   groupby: selectedGroupBy,
      //   ...(selectedRegion !== ALL_VALUE &&
      //     (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
      //       ? { region: encodeURIComponent(selectedRegion) }
      //       : { location: encodeURIComponent(selectedRegion) })),
      //   ...(selectedService !== ALL_VALUE && {
      //     [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
      //       ? 'service'
      //       : 'category']: selectedService,
      //   }),
      // };
      const data = {
        startDate: formatDate(startDate?._d),
        endDate: formatDate(endDate?._d),
        groupby: selectedGroupBy,
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        ...(selectedRegion !== ALL_VALUE &&
          (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
            ? { region: encodeURIComponent(selectedRegion) }
            : { location: encodeURIComponent(selectedRegion) })),
        ...(selectedService !== ALL_VALUE && {
          [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
            ? 'service'
            : 'category']: selectedService,
        }),
      };
      // if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
      //   serviceCostParams = {
      //     ...(selectedAccountId !== ALL_VALUE
      //       ? {
      //           ...data,
      //           selectedAccounts: selectedAccountId,
      //         }
      //       : data),
      //   };
      // } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      //   serviceCostParams = {
      //     ...(selectedAccountId !== ALL_VALUE &&
      //     selectedSubscriptionId === ALL_VALUE
      //       ? {
      //           ...data,
      //           AzureId: selectedAccountId,
      //         }
      //       : {
      //           ...data,
      //           SubscriptionId: selectedSubscriptionId,
      //         }),
      //   };
      // }
      const result = await config.getServiceWiseCost(data);

      if (result) {
        setUpdatedCurrency(selectedCurrency);
        const serviceNames: any = Object.keys(result);
        setServicesData(result);

        const newData: any = {};

        for (const key in result) {
          if (key !== 'Total Cost' && result[key].totalCost) {
            newData[key] = result[key];
          }
        }

        setBarChartData(newData);

        const dateColumns: any = Object.keys(result[serviceNames[0]]).filter(
          (date) => date !== 'totalCost'
        );

        setTableDateColumns(dateColumns);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setIsTableLoad(false);
    }
  };

  const handleSort = (dateColumn: string) => {
    const newSortDirections: Record<string, 'asc' | 'desc' | null> = {};

    // Set sorting direction for the clicked column
    newSortDirections[dateColumn] =
      sortDirections[dateColumn] === 'asc' ? 'desc' : 'asc';

    // Reset sorting direction for other columns
    Object.keys(sortDirections).forEach((column) => {
      if (column !== dateColumn) {
        newSortDirections[column] = null;
      }
    });

    setSortDirections(newSortDirections);

    // Sort servicesData based on the selected date column and sorting direction
    const servicesArray = Object.entries(servicesData).map(
      ([serviceName, dateCosts]: any) => {
        return {
          serviceName,
          ...dateCosts,
        };
      }
    );
    // Sort servicesArray based on the selected date column and sorting direction
    const sortedServicesArray = [...servicesArray].sort(
      (serviceA, serviceB) => {
        const costA =
          dateColumn === 'totalCost'
            ? serviceA[dateColumn]?.cost
            : serviceA[dateColumn]?.cost || 0;
        const costB =
          dateColumn === 'totalCost'
            ? serviceB[dateColumn]?.cost
            : serviceB[dateColumn]?.cost || 0;

        if (dateColumn === 'totalCost') {
          return newSortDirections[dateColumn] === 'asc'
            ? costA - costB
            : costB - costA;
        } else {
          return newSortDirections[dateColumn] === 'asc'
            ? costA - costB
            : costB - costA;
        }
      }
    );

    // Transform sortedServicesArray back into an object with service names as keys
    const sortedServicesData = sortedServicesArray.reduce((result, service) => {
      const { serviceName, ...dateCosts } = service;
      result[serviceName] = dateCosts;
      return result;
    }, {});

    setServicesData(sortedServicesData);

    const newData: any = {};
    if (sortedServicesData) {
      for (const key in sortedServicesData) {
        if (key !== 'Total Cost' && sortedServicesData[key].totalCost) {
          newData[key] = sortedServicesData[key];
        }
      }
    }
    setBarChartData(newData);
  };

  //CSV download handler

  const downloadCsvHandler = async () => {
    try {
      let serviceCostParams;

      const data = {
        startDate: formatDate(startDate?._d),
        endDate: formatDate(endDate?._d),
        groupby: selectedGroupBy,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        ...(selectedRegion !== ALL_VALUE && { region: selectedRegion }),
        ...(selectedService !== ALL_VALUE && {
          [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
            ? 'service'
            : 'category']: selectedService,
        }),
      };
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        serviceCostParams = {
          ...(selectedAccountId !== ALL_VALUE
            ? {
                ...data,
                selectedAccounts: selectedAccountId,
              }
            : data),
        };
        const result = await config.getServiceReportCsv(serviceCostParams);
        exportToFile(result.fileBuffer, result.fileName);
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        serviceCostParams = {
          ...(selectedAccountId !== ALL_VALUE &&
          selectedSubscriptionId === ALL_VALUE
            ? {
                ...data,
                AzureId: selectedAccountId,
              }
            : {
                ...data,
                SubscriptionId: selectedSubscriptionId,
              }),
        };
        const result = await config.getServiceReportCsv(serviceCostParams);
        exportToFile(result.fileBuffer, result.fileName);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  useEffect(() => {
    getServiceCostReport();
  }, [
    selectedSubscriptionId,
    selectedAccountInfo,
    selectedAccountId,
    selectedRegion,
    selectedGroupBy,
    endDate,
    selectedService,
    selectedCurrency,
  ]);

  const handleSelectedRegion = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: string | null
  ) => {
    const regionSelected = value || ALL_VALUE;
    setSelectedRegion(regionSelected);
  };
  const handleSelectedService = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: string | null
  ) => {
    const serviceSelected = value || ALL_VALUE;
    setSelectedService(serviceSelected);
  };
  const handleSelectedGroupBy = (event: any) => {
    const groupBySelected = event.target.value;
    setSelectedGroupBy(groupBySelected);
  };

  if (isNetworkError) {
    throw new Error(ERRORS.NETWORK_ERROR);
  }

  //Date Picker section

  const onDateChange = ({ startDate, endDate }: any) => {
    if (startDate && endDate) {
      if (endDate.isBefore(startDate)) {
        // Swap start and end dates if end date is before start date
        setStartDate(endDate);
        setEndDate(startDate);
      } else {
        setStartDate(startDate);
        setEndDate(endDate);
      }
    } else {
      // Handle single date selection (start date or end date)
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const minDate: Moment = moment().subtract(12, 'months');
  const initialMonth: Moment = moment().subtract(1, 'months');
  const maxDate: Moment = moment();

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  const colSpan: any = tableDateColumns && tableDateColumns?.length + 2;

  return (
    <>
      {/* {loader ? <LoadingScreen /> : <></>} */}
      {/* id selection */}
      <div className="rsTableHeader">
        <Grid className="inventoryReportsHeading  mt-5">Service Report</Grid>
        <Grid className="flex">
          <SelectedAccount onAccountChange={onAccountChange} />
        </Grid>
      </div>
      <Grid className="flex multipleBox">
        <Grid>
          {' '}
          <DateRangePicker
            isDayBlocked={(day: any) => day > new Date()}
            isOutsideRange={() => false} // Disable the default isOutsideRange behavior
            initialVisibleMonth={() => initialMonth}
            hideKeyboardShortcutsPanel={false}
            minDate={minDate}
            maxDate={maxDate}
            startDateId="your_unique_start_date_id"
            startDate={startDate}
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={onDateChange}
            focusedInput={isFocused}
            onFocusChange={(focusedInput: any) => setIsFocused(focusedInput)}
            displayFormat="YYYY/MM/DD"
            minimumNights={0}
          />
        </Grid>

        <div className="subscriptionDD ">
          <label className="subscriptionLabel">
            {cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
              ? 'Region'
              : 'Location'}
          </label>
          <FormControl variant="outlined" className="w-100 mt-0 p-0">
            <InputLabel shrink id="demo-simple-select-outlined-label">
              {cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                ? 'Region'
                : 'Location'}
            </InputLabel>

            <Autocomplete
              id="demo-simple-select-outlined"
              className="p-0"
              value={selectedRegion === ALL_VALUE ? 'All' : selectedRegion}
              onChange={handleSelectedRegion}
              options={
                cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                  ? awsRegions
                  : azureLocations
              }
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                      ? 'Region'
                      : 'Location'
                  }
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="subscriptionDD ">
          <label className="subscriptionLabel">Services</label>
          <FormControl variant="outlined" className="w-100 mt-0 p-0">
            <InputLabel shrink id="demo-simple-select-outlined-label">
              Services
            </InputLabel>

            <Autocomplete
              id="demo-simple-select-outlined"
              className="p-0"
              value={selectedService === ALL_VALUE ? 'All' : selectedService}
              onChange={handleSelectedService}
              options={servicesList}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Services"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="subscriptionDD ">
          <label className="subscriptionLabel"> GroupBy</label>
          <FormControl variant="outlined" className="w-100 mt-0 p-0">
            <InputLabel shrink id="demo-simple-select-outlined-label">
              GroupBy
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedGroupBy}
              onChange={handleSelectedGroupBy}
              label="Subscription"
              displayEmpty
              className="p-0"
            >
              <MenuItem value="day" key="day">
                Days
              </MenuItem>
              <MenuItem value="month" key="month">
                Months
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <div style={{ width: '100%', height: '100%' }}>
        {isTableLoad ? (
          <GraphLoader isLoading={isTableLoad} />
        ) : tableDateColumns &&
          barChartData &&
          Object.keys(barChartData)?.length > 0 &&
          tableDateColumns?.length > 0 ? (
          Object.keys(barChartData).length > 0 && (
            <BarChart data={barChartData} />
          )
        ) : Object.keys(barChartData).length === 0 &&
          !isTableLoad &&
          selectedAccountInfo ? (
          <GraphLoader isLoading={isTableLoad} />
        ) : null}
      </div>

      {/* {id selection ends} */}
      <div className="mt-10">
        <CustomButton
          buttonType="outlined"
          endIcon={<DownloadIcon />}
          onClickHandler={downloadCsvHandler}
          style={{ float: 'right', margin: '1rem 0' }}
        >
          Download Excel
        </CustomButton>
        <Paper className="table-container mt-20">
          <Table
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell
                  className="sticky-cell adminTableheading"
                  key="empty"
                  style={{ zIndex: '100', minWidth: '170px' }}
                >
                  Services
                </TableCell>
                <TableCell
                  className="sticky-cell adminTableheading"
                  key="totalCost"
                  align="center"
                  style={{ zIndex: '1000', cursor: 'pointer' }}
                  onClick={() => handleSort('totalCost')}
                >
                  Total Cost
                  {sortDirections['totalCost'] === 'asc' ? (
                    ' ▲'
                  ) : sortDirections['totalCost'] === 'desc' ? (
                    ' ▼'
                  ) : (
                    <span style={{ color: 'gray', fontSize: '16px' }}> ▼</span>
                  )}
                </TableCell>
                {isTableLoad
                  ? null
                  : tableDateColumns &&
                    tableDateColumns?.length > 0 &&
                    tableDateColumns?.map((date) => (
                      <TableCell
                        className="adminTableheading"
                        key={date}
                        align="center"
                        onClick={() => handleSort(date)}
                        style={{ cursor: 'pointer' }}
                      >
                        {date.replace('_', ' ')}
                        {sortDirections[date] === 'asc' ? (
                          ' ▲'
                        ) : sortDirections[date] === 'desc' ? (
                          ' ▼'
                        ) : (
                          <span style={{ color: 'gray', fontSize: '16px' }}>
                            {' '}
                            ▼
                          </span>
                        )}
                      </TableCell>
                    ))}
              </TableRow>
              <TableRow>
                {/* Second row of sticky data */}
                <TableCell
                  className="sticky-cell adminTableheading"
                  key="empty2"
                  style={{ zIndex: 1000, position: 'sticky', top: '53px' }}
                >
                  Total Cost
                </TableCell>
                <TableCell
                  className="sticky-cell adminTableheading"
                  key="totalCost2"
                  align="center"
                  style={{ zIndex: 1000, position: 'sticky', top: '53px' }}
                >
                  {costFormatter2(
                    updatedCurrency,
                    servicesData['Total Cost']?.totalCost?.cost
                  )}
                </TableCell>
                {tableDateColumns?.map((date) => (
                  <TableCell
                    className="adminTableheading"
                    key={date}
                    align="center"
                    style={{ zIndex: 10, position: 'sticky', top: '53px' }}
                  >
                    {costFormatter2(
                      updatedCurrency,
                      servicesData['Total Cost']?.[date]?.cost
                    ) || '-'}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isTableLoad ? (
                <TableRow key="load">
                  <TableCell colSpan={colSpan} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : tableDateColumns &&
                servicesData &&
                Object.keys(servicesData)?.length > 0 &&
                tableDateColumns?.length > 0 ? (
                Object.entries(servicesData)
                  .filter(([key]) => key !== 'Total Cost')
                  ?.map(([key, value]: any) => (
                    <TableRow key={key}>
                      <TableCell
                        className="sticky-cell"
                        component="th"
                        scope="row"
                        style={{
                          zIndex: 10,
                          minWidth: '170px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {key}
                      </TableCell>
                      <TableCell
                        className="sticky-cell"
                        component="th"
                        align="center"
                        style={{ zIndex: 10 }}
                      >
                        {costFormatter2(
                          updatedCurrency,
                          value?.totalCost?.cost
                        )}
                      </TableCell>
                      {tableDateColumns?.map((date) => (
                        <TableCell key={date} align="center">
                          {(value[date]?.cost &&
                            costFormatter2(
                              updatedCurrency,
                              value[date]?.cost
                            )) ||
                            '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              ) : Object.keys(servicesData).length === 0 &&
                !isTableLoad &&
                selectedAccountInfo ? (
                <TableRow key="load">
                  <TableCell colSpan={colSpan} align="center">
                    No Records
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </>
  );
};

export default ServicesReports;
