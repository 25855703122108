import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { costForecastingService } from '../../../services';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './SpendingDetails.style';
import { dailyCostDataHeaders } from './data';
import { useHistory } from 'react-router';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';
import { costFormatter2, handleError } from '../../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';
import { SummaryData } from '../../../schemas/costforecasting.schema';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      border: 'none',
      fontSize: '1.3rem',
    },
    body: {
      fontSize: '1.3rem',
      border: 'none',
      padding: '10px 16px',
      '&:nth-child(1)': {
        width: '30px',
        paddingRight: '0px',
      },
    },
  })
)(TableCell);

// const htmlFormatter = (str: string) => {
//   if (str?.split(' ').length < 2) {
//     return <div>{str}</div>;
//   } else if (str?.split(' ').length > 1) {
//     const firstLine = str.split(' ')[0];
//     const secondLine = str.split(' ').slice(1).join(' ').split('_').join(' ');
//     return (
//       <React.Fragment>
//         <div>{firstLine}</div>
//         <div>{secondLine}</div>
//       </React.Fragment>
//     );
//   }
// };

interface DateWiseTotalCostData {
  Id: number;
  Day: number;
  Date: number;
  Month: number;
  Year: number;
  Daily_Cost: number;
  TotalCost: number;
  Month_To_Date_Cost: number;
  Flag: string;
}

const storageKeys = {
  CF_SELECTED_SUBSCRIPTION: 'CF_SELECTED_SUBSCRIPTION',
};

const getSelectedSubscription = (): string => {
  return localStorage.getItem(storageKeys.CF_SELECTED_SUBSCRIPTION) || 'all';
};

const lastDate: any = [];

export const SpendingDetails: React.FC = () => {
  const classes = useStyles();
  const selectedAccountInfo = useSelector(accountState);
  // const [open, setOpen] = React.useState(false);
  const [data] = React.useState([0]);
  const [loader, setLoader] = React.useState(false);
  const [getdailyCostData, setDailyCostData] = React.useState([]);
  // const [summaryData, setSummaryData] = React.useState([]);
  // const [currency, setCurrency] = React.useState<any>('');
  const [summaryTableData, setSummaryTableData] = React.useState<SummaryData>();
  const [dateWiseTotalCostData, setDateWiseTotalCostData] = React.useState([]);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const history = useHistory();
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const monthname = window.location.search.split('&')[0].split('=')[1];
  const name = window.location.search.split('&')[2].split('=')[1].split('?')[0];

  const queryParams = window.location.search.split('?').pop() || '';
  const params = queryParams.split('&'); // Split the parameters by '&'
  const lastParam = params.pop() || ''; // Get the last parameter
  const [selectedAccountKey, selectedAccountValue] = lastParam.split('=');

  const [selected] = useState<any>(getSelectedSubscription);

  const getDateWiseTotalCostData = async (ChartType: string, month: string) => {
    // const currentDate = new Date();
    try {
      setLoader(true);
      let result;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        result = await costForecastingService.getDateWiseTotalCostData(
          ChartType,
          month,
          selectedAccountKey,
          selectedAccountValue,
          CLOUD_PROVIDER_SERVICES.AWS,
          selectedCurrency
        );
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        result = await costForecastingService.getDateWiseTotalCostData(
          ChartType,
          month,
          selectedAccountKey,
          selectedAccountValue,
          CLOUD_PROVIDER_SERVICES.AZURE,
          selectedCurrency
        );
      }

      if (result) {
        // setCurrency(result.currency);
        const dateWiseTotalCostDynamic = result.summary.map(
          (e: DateWiseTotalCostData) => {
            if (!e.Flag) {
              lastDate.push(e.Date);
            }
            return {
              name: `${months[e.Month - 1]} ${e.Day}`,
              Cost: `${e.Daily_Cost}`,
              Flag: `${e.Flag}`,
            };
          }
        );
        const getdailyCostData = result.summary.map(
          (e: DateWiseTotalCostData) => {
            return {
              Date: `${e.Date}`,
              Daily_Cost: `${e.Daily_Cost}`,
              Month_To_Date_Cost: `${e.Month_To_Date_Cost}`,
            };
          }
        );
        setUpdatedCurrency(selectedCurrency);
        setDateWiseTotalCostData(dateWiseTotalCostDynamic);
        setDailyCostData(getdailyCostData);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getSummaryData = async (ChartType: string, month: string) => {
    try {
      setLoader(true);
      let result: any;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        result = await costForecastingService.getSummaryData(
          ChartType,
          month,
          selectedAccountKey,
          selectedAccountValue,
          CLOUD_PROVIDER_SERVICES.AWS,
          selectedCurrency
        );
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        result = await costForecastingService.getSummaryData(
          ChartType,
          month,
          selectedAccountKey,
          selectedAccountValue,
          CLOUD_PROVIDER_SERVICES.AZURE,
          selectedCurrency
        );
      }
      if (result) {
        setUpdatedCurrency(selectedCurrency);
        // setSummaryData(result[0].summary);

        const summaryData: SummaryData = {
          MTD_Net_Cost: 0,
          Average_Net_Cost: 0,
          Highest_Change_in_Daily_Cost: '',
          Highest_Net_Cost: '',
          Highest_Net_Cost_Date: '',
          Highest_Service: '',
          Highest_Service_Cost: '',
          Lowest_Net_Cost: '',
          Lowest_Net_Cost_Date: '',
        };

        for (const item of result[0].summary) {
          Object.assign(summaryData, item);
        }

        // const summaryTitle: any = [];
        // const summaryValue: any = [];

        // result[0].summary.forEach((e: any) => {
        //   Object.keys(e).forEach((key) => {
        //     summaryTitle.push(key.split('_').join(' '));
        //   });
        //   Object.values(e).map((value: any) =>
        //     summaryValue.push(result[0].currency + value)
        //   );
        // });

        // setSummaryTableHeaders(summaryTitle);
        setSummaryTableData(summaryData);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">Monthly Bill</div>
          <div className="ttLabel">
            <span className="ttKey pb-5">Month:</span>
            <span className="ttValue pb-5">{`${label}`}</span>
          </div>
          <div className="ttLabel">
            <span className="ttKey pt-5">Cost:</span>
            <span className="ttValue pt-5">
              {costFormatter2(updatedCurrency, payload[0].value)}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    getDateWiseTotalCostData(
      'barChart',
      name === 'Forecast' ? 'forecast' : monthname
    );
    getSummaryData('barChart', monthname);
    // getFilterData();
  }, [selected, selectedAccountInfo.selectedAccount, selectedCurrency]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <Grid container spacing={0}>
      {loader ? <LoadingScreen /> : null}
      <div className="sContainer w-100 mb-10">
        <div className="inventoryHeading font-26px fw-300">
          <div className="">
            {summaryTableData && Object.keys(summaryTableData).length ? (
              'Spend Summary'
            ) : (
              <Skeleton variant="text" width={100} height={22} />
            )}
          </div>
        </div>
      </div>

      <Grid item container direction="column" spacing={0}>
        {summaryTableData && Object.keys(summaryTableData).length ? (
          <TableContainer className="accContainer">
            <Table
              className={classes.table + ' table adminTable adminPanelTable'}
              size="small"
              aria-label="customized table"
            >
              <TableHead className=" admintableHead">
                <TableRow>
                  {/* {summaryTableHeaders.map((heading: any, i) => (
                    <StyledTableCell
                      className="nowrap adminTableheading"
                      key={'head' + i}
                    >
                      {monthname === 'prev' && heading === 'MTD Net Cost'
                        ? 'Last Month Spend'
                        : heading}
                    </StyledTableCell>
                  ))} */}
                  <StyledTableCell className="nowrap adminTableheading">
                    {monthname === 'prev' ? 'Last Month Spend' : 'MTD Net Cost'}
                  </StyledTableCell>
                  <StyledTableCell className="nowrap adminTableheading">
                    Average Net Cost
                  </StyledTableCell>
                  <StyledTableCell className="nowrap adminTableheading">
                    Highest Service
                  </StyledTableCell>
                  <StyledTableCell className="nowrap adminTableheading">
                    Highest Net Cost
                  </StyledTableCell>
                  <StyledTableCell className="nowrap adminTableheading">
                    Lowest Net Cost
                  </StyledTableCell>
                  <StyledTableCell className="nowrap adminTableheading">
                    Heighest Change in Daily Cost
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell>
                    {costFormatter2(
                      updatedCurrency,
                      summaryTableData.MTD_Net_Cost
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {costFormatter2(
                      updatedCurrency,
                      summaryTableData.Average_Net_Cost
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {costFormatter2(
                      updatedCurrency,
                      summaryTableData.Highest_Service_Cost
                    )}
                    {<br />}
                    for {summaryTableData.Highest_Service}
                  </StyledTableCell>
                  <StyledTableCell>
                    {costFormatter2(
                      updatedCurrency,
                      summaryTableData.Highest_Net_Cost
                    )}
                    {<br />}
                    {summaryTableData.Highest_Net_Cost_Date}
                  </StyledTableCell>
                  <StyledTableCell>
                    {costFormatter2(
                      updatedCurrency,
                      summaryTableData.Lowest_Net_Cost
                    )}{' '}
                    {<br />}
                    {summaryTableData.Lowest_Net_Cost_Date}
                  </StyledTableCell>
                  <StyledTableCell>
                    {costFormatter2(
                      updatedCurrency,
                      summaryTableData.Highest_Change_in_Daily_Cost
                    )}
                    {<br />}
                    from Highest Net Cost to Lowest Net Cost
                  </StyledTableCell>
                  {/* {summaryTableData.map((value, i) => (
                    <StyledTableCell key={'data' + i}>
                      {htmlFormatter(value || value === 0 ? value : '-')}
                    </StyledTableCell>
                  ))} */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Skeleton variant="rect" width={1400} height={123} />
        )}
      </Grid>
      <Grid
        item
        container
        spacing={0}
        className={classes.monthlyBillingContainer}
      >
        {data.length ? (
          <Grid container direction="row">
            <Grid spacing={0} item container>
              <Paper elevation={2} className={classes.paper}>
                <Grid container direction="column" spacing={0}>
                  <Grid item className={classes.monthlySpendingHeader}>
                    {cloudProvider === 'AZURE'
                      ? 'Monthly Microsoft Azure Bill'
                      : cloudProvider === 'AWS'
                      ? 'Monthly AWS Bill'
                      : ''}
                  </Grid>
                  <Grid item className={classes.barContainer}>
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={250}
                        data={dateWiseTotalCostData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid
                          strokeDasharray="1"
                          vertical={false}
                          horizontal={false}
                        />
                        <XAxis dataKey="name" />
                        <YAxis
                          tickFormatter={(e) =>
                            `${costFormatter2(updatedCurrency, e)}`
                          }
                          label={{
                            angle: -90,
                            position: 'center',
                          }}
                        />
                        <Tooltip
                          content={<CustomTooltip />}
                          cursor={{ fill: '#f1f1f1' }}
                        />
                        <Legend
                          iconType="circle"
                          payload={[{ value: 'COST', color: '#2083DC' }]}
                        />
                        <Bar dataKey="Cost" stackId="a" fill={'#2083DC'}>
                          {dateWiseTotalCostData.map(
                            (entry: any, index: any) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={`${
                                    entry.Flag && entry.Flag === 'F'
                                      ? '#B7D9F4'
                                      : '#2083DC'
                                  }`}
                                />
                              );
                            }
                          )}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              spacing={0}
              item
              container
              className={classes.costSpendingDetailsTabular}
            >
              <Paper elevation={2} className={classes.paper}>
                <TableContainer className="accContainer">
                  <Table
                    className={`${classes.table} adminTable adminPanelTable`}
                    size="small"
                  >
                    <TableHead className="admintableHead">
                      <TableRow>
                        <StyledTableCell
                          className="adminTableheading"
                          align="left"
                        ></StyledTableCell>
                        {dailyCostDataHeaders.map((heading, i) => (
                          <StyledTableCell
                            className="adminTableheading"
                            align="left"
                            key={'costhead' + i}
                          >
                            {monthname === 'prev' &&
                            heading === 'Month-To-Date Cost'
                              ? 'Last-Month-Spend'
                              : heading}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getdailyCostData.map((row, index) => (
                        <Row
                          key={'cost' + index}
                          row={row}
                          selectedAccountKey={selectedAccountKey}
                          selectedAccountValue={selectedAccountValue}
                          cloudProvider={cloudProvider}
                          even={index % 2 === 0}
                          currency={updatedCurrency}
                          // selected={selected}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Skeleton variant="rect" width={1360} height={604} />
        )}
      </Grid>
    </Grid>
  );
};

const Row = (props: any) => {
  const {
    row,
    even,
    currency,
    // selected,
    selectedAccountKey,
    selectedAccountValue,
    cloudProvider,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [loader, setLoader] = React.useState(false);
  const [serviceWiseData, setServiceWiseData] = React.useState([]);
  const [isNetworkError, setNetworkError] = React.useState(false);
  // const selectedAccountInfo = useSelector(accountState);
  // const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  // const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const history = useHistory();

  const pullDateWiseData = async (
    date: any,
    selectedAccountKey: any,
    selectedAccountValue: any
  ) => {
    try {
      setLoader(true);
      let result;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        result = await costForecastingService.getDateWiseData(
          date,
          selectedAccountKey,
          selectedAccountValue,
          CLOUD_PROVIDER_SERVICES.AWS,
          currency
        );
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        result = await costForecastingService.getDateWiseData(
          date,
          selectedAccountKey,
          selectedAccountValue,
          CLOUD_PROVIDER_SERVICES.AZURE,
          currency
        );
      }

      if (result) {
        // setUpdatedCurrency(selectedCurrency);
        setServiceWiseData(result.summary);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };
  const openDayWiseData = (date: any) => {
    setOpen(!open);
    pullDateWiseData(date, selectedAccountKey, selectedAccountValue);
  };

  const date: any = new Date(row.Date);
  const lastDayData: any = new Date(lastDate[lastDate.length - 1]);
  const dateCopy = new Date(lastDayData.getTime());
  dateCopy.setDate(dateCopy.getDate() + 1);

  const today: any = dateCopy;
  today.setHours(0, 0, 0, 0);

  const dateDiff: any = date - today;

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : null}
      {dateDiff >= 0 ? null : (
        <TableRow className={even ? classes.highlightedRow : ''} key={row.Date}>
          <StyledTableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ fontSize: '2rem' }}
              onClick={() => openDayWiseData(row.Date)}
            >
              {open ? (
                <ArrowDropDownIcon fontSize="large" />
              ) : (
                <ArrowRightIcon fontSize="large" />
              )}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell
            align="left"
            // component="th"
            scope="row"
          >
            {row.Date}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.Daily_Cost || row.Daily_Cost === 0
              ? costFormatter2(currency, row.Daily_Cost)
              : '-'}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.Month_To_Date_Cost || row.Month_To_Date_Cost === 0
              ? costFormatter2(currency, row.Month_To_Date_Cost)
              : '-'}
          </StyledTableCell>
        </TableRow>
      )}
      <TableRow className={even ? classes.highlightedRow : ''}>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4}
        >
          <Collapse
            in={open}
            timeout={80}
            unmountOnExit
            className={classes.collapsibleContainer}
          >
            <Box className={classes.spendSummaryOverflow} margin={1}>
              <Table
                size="small"
                className={`${classes.table} ${classes.borderTop} adminTable adminPanelTable `}
                stickyHeader
              >
                <TableHead className="admintableHead">
                  <TableRow>
                    <StyledTableCell className="adminTableheading" align="left">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </StyledTableCell>
                    <StyledTableCell className="adminTableheading" align="left">
                      Services
                    </StyledTableCell>
                    <StyledTableCell className="adminTableheading" align="left">
                      Daily Cost
                    </StyledTableCell>
                    <StyledTableCell className="adminTableheading" align="left">
                      Month-To-Date Cost
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceWiseData.length == 0
                    ? null
                    : serviceWiseData.map((service: any, index: number) => (
                        <TableRow key={'service' + index}>
                          <StyledTableCell align="left"></StyledTableCell>
                          <StyledTableCell align="left">
                            {service.Services ? service.Services : '-'}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {service.Daily_Cost || service.Daily_Cost === 0
                              ? costFormatter2(currency, service.Daily_Cost)
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {/* {service.Month_To_Date_Cost ||
                            service.Month_To_Date_Cost === 0
                              ? currency
                              : ''} */}

                            {service.Month_To_Date_Cost ||
                            service.Month_To_Date_Cost === 0
                              ? costFormatter2(
                                  currency,
                                  service.Month_To_Date_Cost
                                )
                              : '-'}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};
