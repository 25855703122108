import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { sortAndDeduplicateDiagnostics } from 'typescript';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderTop: {
      borderTop: '2px solid #1582dc',
    },
    summary: {
      fontSize: '1.4rem',
      paddingLeft: '1rem',
    },
    paper: {
      width: '100%',
      borderTop: '2px solid #1582dc',
      padding: '2rem 0',
      // height: '80%',
    },
    barContainer: {
      width: '100%',
      height: '250px',
    },
    table: {
      minWidth: 700,
      tableLayout: 'fixed',
    },
    monthlyBillingContainer: {
      width: '100%',
      // background: '#F7F5F6',
      padding: '0.2rem',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: '1rem',
      '&::-webkit-scrollbar': {
        width: '1rem',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    monthlySpendingHeader: {
      padding: '3.2rem',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    parentRow: {
      '&:nth-child(even)': {
        backgroundColor: '#E6F4FE',
      },
    },
    highlightedRow: {
      backgroundColor: '#E6F4FE',
    },
    defaultColorRow: {
      backgroundColor: '#FFF',
    },
    costSpendingDetailsTabular: {
      marginTop: '4rem',
    },
    collapsibleContainer: {
      backgroundColor: '#F7F5F6',
      padding: '2.5rem',
    },
  })
);
export default useStyles;
