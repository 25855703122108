import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CFLogo, { CFLogoB } from '../../components/cfLogo/cfLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import './NetworkError.css';

const NetworkError = ({ isNetworkError }: any) => {
  useEffect(() => {
    if (isNetworkError) {
      const id = setInterval(() => {
        if (navigator.onLine) {
          window.location.reload();
        }
      }, 1000);
      return () => clearInterval(id);
    }
  }, []);

  const onReload = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div className="supportHeader border0">
        <div className="innerContainer logo m-0">
          <CFLogoB />
        </div>
      </div>
      <div className="innerContainer12">
        <div className="container-404 py-45 textContainer">
          {isNetworkError ? (
            <div className="error-text p-10 textStyle">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-wifi-off"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z" />
                </svg>
              </div>
              Check your Internet Connection. <br />
              Trying to Reconnect...
            </div>
          ) : (
            <React.Fragment>
              <div className="error-text p-10 textStyle">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    className="bi bi-cloud-slash"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.112 5.112a3.125 3.125 0 0 0-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11l-1-1H3.781C2.231 12 1 10.785 1 9.318c0-1.365 1.064-2.513 2.46-2.666l.446-.05v-.447c0-.075.006-.152.018-.231l-.812-.812zm2.55-1.45-.725-.725A5.512 5.512 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 0 1-1.516 2.711l-.733-.733C14.498 11.378 15 10.626 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3c-.875 0-1.678.26-2.339.661z"
                    />
                    <path d="m13.646 14.354-12-12 .708-.708 12 12-.707.707z" />
                  </svg>
                </div>
                Oops Something went wrong. <br />
                Try Reloading the page.
              </div>
              <Button className="mt-5" variant="outlined" onClick={onReload}>
                Reload
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="footerContainer d-none">
        <div className="innerContainer12">
          <Grid item md={12} className="footer">
            <Box className="px-20 py-30">
              <Grid container>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <div className="mt-5">
                    <CFLogo />
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  lg={9}
                  md={9}
                  sm={9}
                  xs={12}
                  className="fAddress"
                >
                  <Grid container item lg={6} md={6} sm={6} xs={12}>
                    <div className="d-flex w-100 mw-300">
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                      <div className="ml-10">
                        <h6>United States</h6>
                        <div>
                          440 N Wolfe Road, Suite #22 Sunnyvale, CA 94085
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-5 w-100 mw-300">
                      <FontAwesomeIcon icon={faMobileAlt} />
                      <div className="ml-10">+1 - 408 - 675 - 9150</div>
                    </div>
                  </Grid>

                  <Grid container item lg={6} md={6} sm={6} xs={12}>
                    <div className="d-flex w-100 mw-300">
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                      <div className="ml-10">
                        <h6>India</h6>
                        <div>
                          7th Floor, Discovery Tower, A-17 Block A, Sector 62,
                          Noida, UP 201309
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-5 w-100 mw-300">
                      <FontAwesomeIcon icon={faMobileAlt} />
                      <div className="ml-5">
                        +91 - 120 - 2975579 / 80 / 81 / 82 / 83 / 84
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NetworkError;
