import React from 'react';
import { makeStyles } from '@material-ui/core';
import './Footer.css';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import cfxImg from '../../assets/images/CFx-logoCopy.png';

const useStyles = makeStyles((theme) => ({
  customIcon: {
    color: '#00d1bf', // Replace with your desired color
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer className="footerBody">
      {/* <div className="row"> */}
      {/* <div className="col-lg-5">
            <div className="row"> */}
      <div className="footerInnerCont">
        <div className="col-lg-8 ">
          <img className="footer-brand img-fluid" src={cfxImg} />
          <p className="mt-25 mb-5 ">
            CloudForestX is a game-changer tool for organizations seeking to
            minimize their cloud expenses. It provides unparalleled assistance
            in understanding your cloud costs and maximizing your current usage.
            Save drastically on your upcoming bills today!
          </p>
        </div>
        <div className="Footer-container">
          <div className="col-lg-4">
            <h3>Company</h3>
            <ul className="footer-nav">
              <li>
                <a href="https://cloudforestx.com/contactus/">Contact Us</a>
              </li>
              <li>
                <a href="https://cloudforestx.com/pricing/">Pricing</a>
              </li>
            </ul>
          </div>
          {/* </div>
          </div> */}
          {/* <div className="col-lg-7">
            <div className="row"> */}
          <div className="col-lg-4">
            <h3>Products</h3>
            <ul className="footer-nav">
              <li>
                <a href="https://cloudforestx.com/cost-overview/">
                  Cost Overview{' '}
                </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/dynamic-resource-management/">
                  Dynamic Resource Management{' '}
                </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/cost-optimization/">
                  Cost Optimization{' '}
                </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/alarms-and-notifications/">
                  Alarms and Notifications{' '}
                </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/security/">Security</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <h3>Solutions</h3>
            <ul className="footer-nav">
              <li>
                <a href="#">VAPT </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/ci-cd-and-automation/">
                  CI/CD and Automation{' '}
                </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/migration/">Migration </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/monitoring/">Monitoring </a>
              </li>
              <li>
                <a href="https://cloudforestx.com/devops-sre-services/">
                  DevOps SRE Services
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <h3>Contact Us</h3>
            <ul className="footer-nav">
              <li className="d-flex">
                <LocationOnIcon className={`${classes.customIcon}  mr-5`} />
                <p>440 N Wolfe Road, Suite #22 Sunnyvale, CA 94085</p>
              </li>
              <li className="d-flex mt-10">
                <CallIcon className={`${classes.customIcon}  mr-5`} />
                <p>+1 - 408 - 675 - 9150</p>
              </li>
            </ul>
          </div>
          {/* </div>
          </div> */}
          {/* </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
