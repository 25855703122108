import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { FormControl } from '@material-ui/core';
import '../Modal.css';
import TextField from '@mui/material/TextField';
import { userService } from '../../../services';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import './ForgetPasswordModal.css';
import { ReactComponent as CrossIcon } from '../../../assets/images/Corss.svg';
import fastForward from '../../../assets/images/fastForward.png';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
}

export const ForgetPasswordModal: React.FC<FixProps> = (props) => {
  const { show = false, onClose } = props;

  // const { register, handleSubmit, errors } = useForm<ForgetPassword>({
  //   mode: 'onChange',
  // });
  const [fpEmail, setfpEmail] = React.useState('');

  const [regMsg, setRegMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [networkError, setNetworkError] = React.useState('');
  const [loader, setLoader] = React.useState(false);

  const [InvalidEmailAddress, setInvalidEmailAddress] = React.useState(false);
  const history = useHistory();

  const emailRegex = /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/;
  const onSubmit = async () => {
    setInvalidEmailAddress(false);
    if (!emailRegex.test(fpEmail)) {
      setInvalidEmailAddress(true);
      return;
    }
    try {
      const data = {
        EmailAddress: fpEmail,
      };
      setLoader(true);
      const result = await userService.forgetPassword(data);
      if (result && result.error && result.error.message) {
        const err = `${result.error.message}`;
        setRegMsg(err);
      }
      if (result.message) {
        setSuccessMsg(result.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setLoader(false);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');

      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        if (e && e.error && e.error.message) {
          handleError(e, history);
        }
        setRegMsg(err);
      }
      setLoader(false);
    }
  };

  const handleFieldChange = (e: any) => {
    setfpEmail(e.target.value);
    setRegMsg('');
  };

  const regMsgElement = () => {
    return <div className="regMsg">{regMsg}</div>;
  };

  const successMsgElement = () => {
    return <div className="alert-success p-3 font-12px">{successMsg}</div>;
  };

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{
          overlay: {
            background: 'rgba(0, 0, 0, 0.50)',
            zIndex: 9999,
            backdropFilter: 'blur(10px)',
          },
        }}
        className="fixModal borderRadius FP-Modal"
        {...props}
      >
        <div className="FP-OuterCont soraFont">
          <div className="FP-CrossOuterCont">
            <CrossIcon className="mt-15" onClick={onClose} />
          </div>
          <div className="FP-BoxBody">
            <div className="FP-Title">Forgot Password</div>
            <div className="FP-InfoText ">
              Enter your email address. We will send you a link to reset your
              password.
            </div>

            <div className="FP-EmailBox">
              <div className="EmailText mb-5">Email</div>
              <FormControl variant="outlined" className="w-100">
                <TextField
                  inputProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                  type="email"
                  onChange={(e) => handleFieldChange(e)}
                  id="email"
                  name="EmailAddress"
                  label=""
                  variant="standard"
                  autoComplete="off"
                  sx={{ width: '100%' }}
                />
                {InvalidEmailAddress && (
                  <div className="regMsg">Enter valid email id.</div>
                )}{' '}
                {regMsg ? regMsgElement() : ''}
                {successMsg ? successMsgElement() : ''}
              </FormControl>
            </div>

            <div className="FP-Button" onClick={() => onSubmit()}>
              <span className="FP-ResetPasswordText">Reset Password</span>
              <span>
                <img className="ml-10" src={fastForward} alt="forward" />
              </span>
            </div>
          </div>
        </div>

        {/* <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">FORGET PASSWORD</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <Grid item md={12} className="modalBody">
          <p className="mb-20">
            Enter your email address. We will send you a link to reset your
            password.
          </p>
          <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl variant="outlined" className="bEmail mb-15">
              <InputLabel htmlFor="business-email" className="inputLegend">
                EMAIL
              </InputLabel>
              <OutlinedInput
                id="email"
                name="EmailAddress"
                className="outlineInputArea"
                label="EMAIL"
                type="email"
                onChange={(e) => handleFieldChange(e.target)}
                inputRef={register({
                  required: true,
                  pattern: /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/,
                })}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                  </InputAdornment>
                }
              />
              {errors.EmailAddress && (
                <div className="regMsg">Enter valid email id.</div>
              )}
            </FormControl>

            {regMsg ? regMsgElement() : ''}
            {successMsg ? successMsgElement() : ''}

            <div className="registrationLoginBtn">
              <input
                type="submit"
                className="formLoginBtn"
                value="SEND EMAIL"
                disabled={btnDisable}
              />
              {/* {btnDisable ? (
                <input
                  type="submit"
                  className="formLoginBtn"
                  value="SEND EMAIL"
                  disabled
                />
              ) : (
                <input
                  type="submit"
                  className="formLoginBtn"
                  value="SEND EMAIL"
                />
              )} 
            </div>
          </form>
        </Grid>
      </Grid> */}
      </Modal>
    </>
  );
};
