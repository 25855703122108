import { Box } from '@material-ui/core';
import SearchSelectOption from '../../components/MasterComponent/SelectOptions/SearchSelectOption';
import './filters.css';
import { RUMFilters } from './interfaces';

export interface FiltersProps {
  filters: string[][];
  lables: (keyof RUMFilters)[];
  filterState: RUMFilters;
  filterChangeHandler: (input: RUMFilters) => void;
}

const lables = ['Pages', 'Platform', 'Browser', 'Country'];
export const Filter: React.FC<FiltersProps> = (props) => {
  const { filters, lables, filterState, filterChangeHandler } = props;
  return (
    <Box style={{ display: 'flex' }}>
      {filters.map((filter, index) => (
        <SearchSelectOption
          className="rum-filter"
          labelName={lables[index]}
          selectOptions={filter}
          selectedValue={filterState[lables[index]] as string}
          onChangeSelectHandler={(e, value) => {
            filterChangeHandler({
              [lables[index].toLowerCase()]: value,
            });
          }}
        />
      ))}
    </Box>
  );
};
