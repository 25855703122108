import Modal from 'react-modal';
import React, { useRef, useState } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';

import './SlackCredsModal.css';
import { DeleteOutline } from '@mui/icons-material';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
interface SlackCredsModalInterface {
  onSubmit: { (data: any): void };
  show: boolean;
  onClose?: { (): void };
}

const SlackCredsModal: React.FC<SlackCredsModalInterface> = ({
  onSubmit,
  show,
  onClose,
}) => {
  const [slackCreds, setSlackCreds] = useState([
    {
      channel_name: '',
      webhook_url: '',
      is_enabled: 0,
    },
  ]);
  const [loader, setLoader] = useState(false);

  const focusInput = useRef<HTMLInputElement>(null);

  const handleFieldChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list: any = [...slackCreds];
    list[index][name as keyof typeof list[typeof index]] = value;
    list[index]['is_enabled'] = 1;
    setSlackCreds(list);
  };

  const addSlackInput = () => {
    setSlackCreds((prevValue: any) => [
      ...prevValue,
      { channel_name: '', webhook_url: '', is_enabled: 0 },
    ]);
  };

  const deleteSlackCreds = (i: number) => {
    setSlackCreds((preValue: any) => {
      const newList = [...preValue];
      newList.splice(i, 1);
      return newList;
    });
    // setSlackCreds((preValue) => {
    //   const list = [...preValue];
    //   const filteredList = list.filter(
    //     (e) => e.channel_name !== channel.channel_name
    //   );
    //   return filteredList;
    // });
  };
  return (
    <>
      {loader && <LoadingScreen />}
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{
          overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 },
        }}
        className="fixModal"
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">Enter your slack creds</span>
              <span className="modalClose" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
          <div className="slackCredsOuterCont">
            {slackCreds.map((channel, i) => (
              <div className="slackCredInnerCont" key={'slack' + i}>
                <div className=" numbering">{i + 1}.</div>
                <div className="w-90">
                  <form
                    noValidate
                    autoComplete="off"
                    className="d-block w-100 bgColor"
                  >
                    <Grid item md={12} className="  modalBody">
                      <Grid
                        container
                        alignItems="flex-end"
                        className="positionRelative bgColor "
                        spacing={3}
                      >
                        <Grid item className="w-100">
                          <FormControl
                            variant="outlined"
                            className="slackInputBox  "
                          >
                            <InputLabel className="inputLegend">
                              ENTER CHANNEL NAME
                            </InputLabel>
                            <OutlinedInput
                              name="channel_name"
                              required
                              value={channel.channel_name}
                              ref={focusInput}
                              className="outlineInputArea"
                              onChange={(e) => handleFieldChange(e, i)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item className="w-100">
                          <FormControl
                            variant="outlined"
                            className="slackInputBox  "
                          >
                            <InputLabel className="inputLegend">
                              ENTER WEBHOOK URL
                            </InputLabel>
                            <OutlinedInput
                              name="webhook_url"
                              required
                              className="outlineInputArea"
                              onChange={(e) => handleFieldChange(e, i)}
                              value={channel.webhook_url}
                            />{' '}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </div>

                {slackCreds.length > 1 && (
                  <IconButton
                    color="inherit"
                    className="red w-10 h-10 mt-40"
                    onClick={() => {
                      deleteSlackCreds(i);
                    }}
                  >
                    <DeleteOutline fontSize="large" />
                  </IconButton>
                )}
              </div>
            ))}
            <div className="addMoreBtnOuterCont pb-10 pr-10 w-5">
              <button
                className="btn btn-outline-primary mt-10 ml-10 addMoreBtn"
                onClick={() => addSlackInput()}
              >
                + Add More{' '}
              </button>
            </div>
          </div>
        </Grid>

        <div className=" modalFooter">
          <button
            type="button"
            className="btn btn-primary ml-10 "
            onClick={() => {
              onSubmit(slackCreds);
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SlackCredsModal;
