import { customNotificationService } from '../../../services';

export const config: any = {
  updateUserNotifications: (params: any) =>
    customNotificationService.updateUserNotification(params),
};
export const FEATURES_NAMES = {
  IDLE: 'IDLE',
  RIGHTSIZING: 'RIGHTSIZING',
  COST_EXCEEDED: 'COST EXCEEDED',
  SECURITY_SCAN: 'SECURITY SCAN',
  INVOICE: 'INVOICE',
  CPU_MONITORING: 'CPU MONITORING',
  UPTIME_MONITORING: 'UPTIME MONITORING',
};
