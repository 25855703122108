import React, { useState, useRef, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import './UptimeMonitoringModal.css';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import { ListItemText } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  ListI,
  submitUrlInterface,
} from '../../../pages/UptimeMonitoring/URLMonitoring';
import { useDispatch, useSelector } from 'react-redux';
import { userState } from '../../../store';
import { userActions } from '../../../store/userSlice';
// import { MONITORING_TYPES, validateJSON } from './config';

import {
  MonitoringIntervalMarks,
  monitoringIntervalObj,
  monitoringIntervalReverse,
  MenuProps,
  MONITORING_TYPES,
  validateJSON,
  timeoutMarks,
  statusCodes,
  statusRange,
  statusConvert,
  regionsData,
  NotificationIntervalMarks,
  monitoringNotificationIntervalRev,
  monitoringNotificationIntervalObj,
  filterArray,
  ESCALATION_TYPE,
  filterByWebUrl,
  filterByRegion,
} from './config';
import { Tooltip } from '@mui/material';
import SelectAddItem from '../../MasterComponent/Select/SelectAddItem';
import { URLMonitoringConfigInterface } from '../../../pages/UptimeMonitoring/URLAccordionSection';

interface UptimeMonitoringModalInterface {
  show: boolean;
  onClose?: { (): void };
  uptimeMonitoringDetails: URLMonitoringConfigInterface;
  emails: string[];
  slackGroups: string[];
  onSubmit: { (params: submitUrlInterface): void };
  webhookUrlMapping: {
    [key: string]: string;
  };
  Email_Id: {
    [key: string]: string;
  };
  GchatChannel_Id: {
    [key: string]: string;
  };
  SlackChannel_Id: {
    [key: string]: string;
  };
  list: ListI;
  gchats: string[];
  environmentLabels: string[];
  selectedLabel: string;
  selectedEnvironmentHandler: (environment: string) => void;
}

type ValidationErrorState = {
  friendlyName: boolean;
  webUrl: boolean;
  selectedEmails: boolean;
  selectedSlackGroup: boolean;
  monitoringType: boolean;
  monitoringInt: boolean;
  body: boolean;
  Method: boolean;
  reqHeader: boolean;
  hostName: boolean;
  port: boolean;
  refreshTokenAPI: boolean;
  selectedGchats: boolean;
  selectedRegions: boolean;
};

const UptimeMonitoringModal: React.FC<UptimeMonitoringModalInterface> = ({
  show,
  onClose,
  uptimeMonitoringDetails,
  emails,
  slackGroups,
  gchats,
  onSubmit,
  webhookUrlMapping,
  Email_Id,
  GchatChannel_Id,
  SlackChannel_Id,
  list,
  environmentLabels,
  selectedEnvironmentHandler,
  selectedLabel,
}) => {
  const [monitoringType, setMonitoringType] = useState<string>(
    uptimeMonitoringDetails.monitoringType
  );
  const [escalationType, setEscalationType] = useState<string>(
    uptimeMonitoringDetails.escalationType
  );
  const [escalationMetaData, setEscalationMetaData] = useState<string[]>([]);
  const [notificationMetaData, setNotificationMetaData] = useState<string[]>(
    []
  );
  const [EscalationThreshold, setEscalationThreshold] = useState<number | null>(
    uptimeMonitoringDetails.EscalationThreshold
  );
  const [selectedEscalation, setSelectedEscalation] = useState<string[]>(
    uptimeMonitoringDetails.Escalations
  );
  const [friendlyName, setFriendlyName] = useState(
    uptimeMonitoringDetails.friendlyName
  );
  const [webUrl, setWebUrl] = useState(uptimeMonitoringDetails.webUrl);
  const [monitoringInterval, setMonitoringInterval] = useState(
    monitoringIntervalReverse[uptimeMonitoringDetails.monitoringInterval]
  );
  const [monitoringTimeout, setMonitoringTimeout] = useState(
    uptimeMonitoringDetails.monitoringTimeout / 1000
  );

  const [
    monitoringNotificationInterval,
    setMonitoringNotificationInterval,
  ] = useState(
    monitoringNotificationIntervalRev[
      uptimeMonitoringDetails.monitoringNotificationInterval / (1000 * 60)
    ]
  );

  const [checkSSL, setCheckSSL] = useState(uptimeMonitoringDetails.IsSSL);
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    uptimeMonitoringDetails.selectedEmail
  );
  const [selectedRegions, setSelectedRegions] = useState<string[]>(
    uptimeMonitoringDetails.Region
  );

  const convertedStatusCodes = uptimeMonitoringDetails.acceptedStatuses?.reduce(
    (acc: string[], curr: number) => {
      if (Object.keys(statusConvert).includes(curr.toString())) {
        acc.push(statusConvert[curr]);
      } else acc.push(curr.toString());
      return acc;
    },
    []
  );

  const [selectedStatusCodes, setSelectedStatusCodes] = useState<string[]>(
    convertedStatusCodes
  );
  const [selectedSlackGroup, setSelectedSlackGroup] = useState<string[]>(
    uptimeMonitoringDetails.selectedSlackGroups
  );
  const [selectedGchats, setSelectedGchats] = useState<string[]>(
    uptimeMonitoringDetails.selectedGchats
  );
  const [switchChecked, setSwitchChecked] = useState<boolean | undefined>(
    uptimeMonitoringDetails.IsMonitoring
  );

  const [body, setBody] = useState(
    JSON.stringify(uptimeMonitoringDetails.Body, null, 2) === '{}' ||
      JSON.stringify(uptimeMonitoringDetails.Body, null, 2) === null
      ? ''
      : JSON.stringify(uptimeMonitoringDetails.Body, null, 2)
  );
  const [method, setMethod] = useState(uptimeMonitoringDetails.Method);
  const [reqHeader, setreqHeader] = useState(
    JSON.stringify(uptimeMonitoringDetails.Headers, null, 2) === '{}' ||
      JSON.stringify(uptimeMonitoringDetails.Headers, null, 2) === null
      ? ''
      : JSON.stringify(uptimeMonitoringDetails.Headers, null, 2)
  );
  const [port, setPort] = useState<string>(uptimeMonitoringDetails.Port);
  const [host, setHost] = useState(uptimeMonitoringDetails.webUrl);
  // const [refreshTokenAPI, setRefreshTokenAPI] = useState(
  //   uptimeMonitoringDetails.refreshTokenAPI
  // );

  const [validationError, setValidationError] = useState<ValidationErrorState>({
    friendlyName: false,
    webUrl: false,
    selectedEmails: false,
    selectedSlackGroup: false,
    monitoringType: false,
    monitoringInt: false,
    body: false,
    Method: false,
    reqHeader: false,
    hostName: false,
    port: false,
    refreshTokenAPI: false,
    selectedGchats: false,
    selectedRegions: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    HeadersErrorMessage: '',
    BodyErrorMessage: '',
  });

  const focusOnError = useRef<any>(null);
  const focusOnFriendlyName = useRef<any>(null);
  const monitoringTypeRef = useRef<any>(null);
  const methodRef = useRef<any>(null);
  const reqHeaderRef = useRef<any>(null);
  const selectEmailsRef = useRef<any>(null);
  const selectGchatRef = useRef<any>(null);
  const selectSlackRef = useRef<any>(null);

  const userData = useSelector(userState).userData;
  const planType = userData?.plan.name;
  // const [selectedLabel, setSelectedLabel] = useState<string>(
  //   uptimeMonitoringDetails.environment
  // );

  const dispatch = useDispatch();

  // const selectedEnvironmentHandler = (
  //   updatedData: string[],
  //   changedValue: string
  // ) => {
  //   setSelectedLabel(changedValue);
  // };
  const handleMonitoringTypeChange = (e: any) => {
    setValidationError((preValue: any) => ({
      ...preValue,
      monitoringType: false,
    }));
    setMonitoringType(e.target.value);
  };

  useMemo(() => {
    setNotificationMetaData(() => {
      if (escalationType === ESCALATION_TYPE.Slack) {
        return filterArray(slackGroups, selectedEscalation);
      } else if (escalationType === ESCALATION_TYPE.Gchat) {
        return filterArray(gchats, selectedEscalation);
      }
      if (escalationType === ESCALATION_TYPE.Email) {
        return filterArray(emails, selectedEscalation);
      } else return emails;
    });
    setEscalationMetaData(() => {
      if (escalationType === ESCALATION_TYPE.Slack) {
        return filterArray(slackGroups, selectedSlackGroup);
      } else if (escalationType === ESCALATION_TYPE.Gchat) {
        return filterArray(gchats, selectedGchats);
      } else {
        return filterArray(emails, selectedEmails);
      }
    });
  }, [
    escalationType,
    selectedSlackGroup,
    selectedGchats,
    selectedEmails,
    selectedEscalation,
  ]);

  const handleEscalationTypeChange = (e: any) => {
    setSelectedEscalation([]);
    const value = e.target.value;

    setEscalationMetaData(() => {
      if (value === ESCALATION_TYPE.Slack) {
        return filterArray(slackGroups, selectedSlackGroup);
      } else if (value === ESCALATION_TYPE.Gchat) {
        return filterArray(gchats, selectedGchats);
      } else {
        return filterArray(emails, selectedEmails);
      }
    });
    setEscalationType(e.target.value);
  };

  const handleChangeSelectedEscalation = (e: any) => {
    const escalationList = e.target.value;
    setSelectedEscalation(escalationList);
  };

  const handleChangeEscalationThreshold = (e: any) => {
    setEscalationThreshold(
      e.target.value === '' ? null : Number(e.target.value)
    );
  };

  const handleFriendlyNameChange = (e: any) => {
    setValidationError((preValue: any) => ({
      ...preValue,
      friendlyName: false,
    }));
    setFriendlyName(e.target.value);
  };
  const handleWebUrlChange = (e: any) => {
    setValidationError((preValue: any) => ({
      ...preValue,
      webUrl: false,
    }));
    setWebUrl(e.target.value);
  };

  const handleIntervalSlider = (e: any) => {
    setMonitoringInterval(e.target.value);
  };
  const handleTimeoutSlider = (e: any) => {
    setMonitoringTimeout(e.target.value);
  };
  const handleMonitoringNotificationIntervalSlider = (e: any) => {
    setMonitoringNotificationInterval(e.target.value);
  };

  const handleSwitch = (e: any) => {
    setSwitchChecked(e.target.checked);
  };

  const handleCheckSSL = (e: any) => {
    setCheckSSL(e.target.checked);
  };

  const handleBody = (e: any) => {
    setValidationError((preValue: any) => ({
      ...preValue,
      body: false,
    }));
    setBody(e.target.value);
  };
  const handleMethodChange = (e: any) => {
    setValidationError((preValue: any) => ({
      ...preValue,
      Method: false,
    }));
    setMethod(e.target.value);
  };
  const handlereqHeaderChange = (e: any) => {
    setValidationError((preValue: any) => ({
      ...preValue,
      reqHeader: false,
    }));
    setreqHeader(e.target.value);
  };

  const handlePortChange = (e: any) => {
    setPort(e.target.value);
    if (+e.target.value > 65535 || Number.isNaN(+e.target.value)) {
      setValidationError((preValue: any) => ({
        ...preValue,
        port: true,
      }));
    } else
      setValidationError((preValue: any) => ({
        ...preValue,
        port: false,
      }));
  };
  // const handleRefreshTokenAPIChange = (e: any) => {
  //   setValidationError((preValue: any) => ({
  //     ...preValue,
  //     refreshTokenAPI: false,
  //   }));
  //   setRefreshTokenAPI(e.target.value);
  // };
  const handleHostChange = (e: any) => {
    setHost(e.target.value);
    const validateIPaddress = (ipaddress: string) => {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ipaddress
        )
      ) {
        return true;
      }
      return false;
    };
    if (!validateIPaddress(e.target.value))
      setValidationError((preValue: any) => ({
        ...preValue,
        hostName: true,
      }));
    else
      setValidationError((preValue: any) => ({
        ...preValue,
        hostName: false,
      }));
  };

  const handleChangeSelectedEmails = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((emails: any) => {
      if (emails === undefined) flag = false;
    });
    if (flag) setSelectedEmails(value);
    setValidationError((preValue: any) => ({
      ...preValue,
      selectedSlackGroup: false,
      selectedEmails: false,
      selectedGchats: false,
    }));
  };

  const handleChangeSelectedGchat = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((emails: any) => {
      if (emails === undefined) flag = false;
    });
    if (flag) setSelectedGchats(value);
    setValidationError((preValue: any) => ({
      ...preValue,
      selectedSlackGroup: false,
      selectedEmails: false,
      selectedGchats: false,
    }));
  };

  const handleChangeSelectedCodes = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((statusCode: any) => {
      if (statusCode === undefined) flag = false;
    });
    if (flag) setSelectedStatusCodes(value);
  };

  const handleChangeSelectedSlackGroups = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((emails: any) => {
      if (emails === undefined) flag = false;
    });
    if (flag) setSelectedSlackGroup(value);
    setValidationError((preValue: any) => ({
      ...preValue,
      selectedSlackGroup: false,
      selectedEmails: false,
      selectedGchats: false,
    }));
  };
  const handleChangeSelectedRegions = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((region: any) => {
      if (region === undefined) flag = false;
    });
    if (flag) setSelectedRegions(value);
    setValidationError((preValue: any) => ({
      ...preValue,
      selectedRegions: false,
    }));
  };

  function valuetext(value: number) {
    return `${value}s`;
  }

  function valueLabelFormat(value: number) {
    return (
      MonitoringIntervalMarks.findIndex((mark) => mark.value === value) + 1
    );
  }

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const submit = () => {
    const Notifications: {
      Type: string;
      Value: string;
      FriendlyName: string;
      id: string;
    }[] = [];

    if (selectedEmails.length) {
      selectedEmails.forEach((email: string) =>
        Notifications.push({
          Type: ESCALATION_TYPE.Email,
          Value: email,
          FriendlyName: '',
          id: Email_Id[email],
        })
      );
    }

    if (selectedSlackGroup.length) {
      selectedSlackGroup.forEach((slackGroup: string) =>
        Notifications.push({
          Type: ESCALATION_TYPE.Slack,
          Value: webhookUrlMapping[slackGroup],
          FriendlyName: slackGroup,
          id: SlackChannel_Id[slackGroup],
        })
      );
    }

    if (selectedGchats.length) {
      selectedGchats.forEach((gchat: string) =>
        Notifications.push({
          Type: ESCALATION_TYPE.Gchat,
          Value: gchat,
          FriendlyName: '',
          id: GchatChannel_Id[gchat],
        })
      );
    }

    if (selectedEscalation.length) {
      selectedEscalation.forEach((el: string) =>
        Notifications.push({
          Type: `Escalation${escalationType}`,
          Value: el,
          FriendlyName: '',
          id:
            escalationType === ESCALATION_TYPE.Slack
              ? SlackChannel_Id[el]
              : escalationType === ESCALATION_TYPE.Gchat
              ? GchatChannel_Id[el]
              : Email_Id[el],
        })
      );
    }

    let jsonBody = {};
    let parsedreqHeader: any = {};

    //checking monitoring interval for basic plan
    if (planType === 'Basic' && monitoringInterval < 100) {
      setValidationError((prevValue: any) => ({
        ...prevValue,
        monitoringInt: true,
      }));
      dispatch(
        userActions.replaceUserState({
          errorMessage:
            'Please Upgrade your plan to decrease monitoring time interval',
        })
      );
      return;
    }

    //checking url remaining on the basis of plan
    const remaningUrlsForBasicPlan = 50 - list.webURLs.length;
    const remaningUrlsForGrowthPlan = 200 - list.webURLs.length;

    if (planType === 'Basic' && remaningUrlsForBasicPlan === 0) {
      dispatch(
        userActions.replaceUserState({
          errorMessage: 'Please Upgrade your plan to add more URLs',
        })
      );
      return;
    } else if (remaningUrlsForGrowthPlan === 0) {
      dispatch(
        userActions.replaceUserState({
          errorMessage: 'URLs limit should not exceed more then 200',
        })
      );
      return;
    }

    const filteredWebUrlData =
      monitoringType === MONITORING_TYPES.HTTPs ||
      monitoringType === MONITORING_TYPES.HTTP
        ? filterByWebUrl(list.url, webUrl)
        : filterByWebUrl(list.port, host);

    const remainingRegions = Object.keys(uptimeMonitoringDetails).includes(
      'IsMonitoring'
    )
      ? selectedRegions.filter(
          (region) => !uptimeMonitoringDetails.Region.includes(region)
        )
      : selectedRegions;

    if (remainingRegions.length) {
      const filteredRegionData = filterByRegion(
        filteredWebUrlData,
        remainingRegions
      );

      if (filteredRegionData.length > 0) {
        let regions = '';
        const greaterThenOneRegionList = filteredRegionData.length > 1;
        filteredRegionData.forEach((value, index) => {
          regions += `${value.Region}${
            greaterThenOneRegionList && index !== filteredRegionData.length - 1
              ? ', '
              : ''
          }`;
        });

        setValidationError((preValue: any) => ({
          ...preValue,
          selectedRegions: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: `${regions} ${
              greaterThenOneRegionList ? 'have' : 'has'
            } already been added for monitoring of ${
              monitoringType === MONITORING_TYPES.HTTPs ||
              monitoringType === MONITORING_TYPES.HTTP
                ? 'url'
                : 'hostname address'
            } ${filteredWebUrlData[0].WebUrl} `,
          })
        );

        return;
      }
    }
    //   }
    // }

    if (
      monitoringType === MONITORING_TYPES.HTTPs ||
      monitoringType === MONITORING_TYPES.HTTP
    ) {
      //checking on monitoring type
      if (monitoringType === '') {
        monitoringTypeRef.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          monitoringType: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please choose monitoring type',
          })
        );
        return;
      }

      if (method === '') {
        setValidationError((prevValue: any) => ({
          ...prevValue,
          Method: true,
        }));
        return;
      }

      if (selectedRegions.length === 0) {
        setValidationError((prevValue: any) => ({
          ...prevValue,
          selectedRegions: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please choose atleast one region',
          })
        );
        return;
      }
      if (
        monitoringType === '' &&
        webUrl === '' &&
        friendlyName === '' &&
        method === ''
      ) {
        //checking validation Errors
        setValidationError((prevValue: any) => ({
          ...prevValue,
          friendlyName: true,
          webUrl: true,
          monitoringType: true,
          monitoringInt: false,
          body: false,
          Method: true,
          reqHeader: false,
        }));
        return;
      }

      if (monitoringType !== '' && webUrl === '' && friendlyName === '') {
        setValidationError((prevValue: any) => ({
          ...prevValue,
          friendlyName: true,
          webUrl: true,
          monitoringType: false,
          monitoringInt: false,
          body: false,
          Method: false,
          reqHeader: false,
        }));
        return;
      }
      if (monitoringType !== '' && webUrl !== '' && friendlyName === '') {
        setValidationError((prevValue: any) => ({
          ...prevValue,
          friendlyName: true,
          webUrl: false,
          monitoringType: false,
          monitoringInt: false,
          body: false,
          Method: false,
          reqHeader: false,
        }));
        return;
      }

      //url check on basis of http
      if (webUrl.split(':')[0] !== monitoringType) {
        setValidationError((preValue: any) => ({
          ...preValue,
          webUrl: true,
        }));
        focusOnError.current.focus();
        dispatch(
          userActions.replaceUserState({
            errorMessage: `Please enter a ${monitoringType} only URL`,
          })
        );
        return;
      }

      //checking duplicate url
      // if (!Object.keys(uptimeMonitoringDetails).includes('IsMonitoring')) {
      //   const duplicateUrl = urlList.filter(
      //     (url: string) => url.trim() === webUrl.trim()
      //   );

      //   if (duplicateUrl.length) {
      //     focusOnError.current.focus();
      //     setValidationError((preValue: any) => ({
      //       ...preValue,
      //       webUrl: true,
      //     }));
      //     dispatch(
      //       userActions.replaceUserState({
      //         errorMessage: webUrl + ', is existing URL',
      //       })
      //     );
      //     return;
      //   }
      // }

      const duplicateFriendlyName = list.urlHostName.filter((fname: string) => {
        if (
          friendlyName.toLocaleLowerCase().trim() !==
          uptimeMonitoringDetails.friendlyName.toLocaleLowerCase().trim()
        ) {
          return (
            fname.toLocaleLowerCase().trim() ===
            friendlyName.toLocaleLowerCase().trim()
          );
        }
      });

      if (duplicateFriendlyName.length) {
        focusOnFriendlyName.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          friendlyName: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: `Friendly Name ${friendlyName}, is already exist`,
          })
        );
        return;
      }

      // url check
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      if (webUrl.length === 0) {
        focusOnError.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          webUrl: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please enter a valid URL',
          })
        );
        return;
      } else if (!urlRegex.test(webUrl.trim())) {
        focusOnError.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          webUrl: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: `${webUrl}, is a invalid URL`,
          })
        );

        return;
      }

      //url check on basis of http
      if (webUrl.split(':')[0] !== monitoringType) {
        dispatch(
          userActions.replaceUserState({
            errorMessage: `Please enter a ${monitoringType} only URL`,
          })
        );
        return;
      }

      //Validating req header of api

      if (reqHeader.length) {
        const { isValid, parsedJSON, error } = validateJSON(reqHeader);
        if (!isValid) {
          setValidationError((prevValue: any) => ({
            ...prevValue,
            reqHeader: true,
          }));
          setErrorMessage((preValue: any) => ({
            ...preValue,
            HeadersErrorMessage: error,
          }));
          return;
        } else parsedreqHeader = parsedJSON;
      }

      //Validating Body of api
      if (body.length) {
        const { isValid, parsedJSON, error } = validateJSON(body);

        if (!isValid) {
          setValidationError((prevValue: any) => ({
            ...prevValue,
            body: true,
          }));
          setErrorMessage((preValue: any) => ({
            ...preValue,
            BodyErrorMessage: error,
          }));
          return;
        } else jsonBody = parsedJSON;
      }

      // email & slack groups check
      if (
        selectedEmails.length === 0 &&
        selectedSlackGroup.length === 0 &&
        selectedGchats.length === 0
      ) {
        selectEmailsRef.current.focus();
        selectGchatRef.current.focus();
        selectSlackRef.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          selectedEmails: true,
          selectedSlackGroup: true,
          selectedGchats: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage:
              'Please select Emails, Gchat or Slack groups for receiving url monitoring notifications',
          })
        );
        return;
      }

      //checking slider on planType
      if (planType === 'Basic' && monitoringInterval < 100) {
        setValidationError((preValue: any) => ({
          ...preValue,
          monitoringInt: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: 'Please choose monitoring interval to 5 minutes',
          })
        );
        return;
      }
    } else if (monitoringType === MONITORING_TYPES.PORT) {
      //Port validation check
      //port check
      if (!friendlyName) {
        setValidationError((prevValue: any) => ({
          ...prevValue,
          friendlyName: true,
        }));
        return;
      }

      const duplicateFriendlyName = list.portHostName.filter(
        (fname: string) => {
          if (
            friendlyName.toLocaleLowerCase().trim() !==
            uptimeMonitoringDetails.friendlyName.toLocaleLowerCase().trim()
          ) {
            return (
              fname.toLocaleLowerCase().trim() ===
              friendlyName.toLocaleLowerCase().trim()
            );
          }
        }
      );

      if (duplicateFriendlyName.length) {
        focusOnFriendlyName.current.focus();
        setValidationError((preValue: any) => ({
          ...preValue,
          friendlyName: true,
        }));
        dispatch(
          userActions.replaceUserState({
            errorMessage: `Friendly Name ${friendlyName}, is already exist`,
          })
        );
        return;
      }

      if (!port) {
        setValidationError((prevValue: any) => ({
          ...prevValue,
          port: true,
        }));
        return;
      }

      //hostname check
      if (host === '') {
        setValidationError({ ...validationError, hostName: true });
        return;
      }
    }

    setValidationError((prevValue: any) => ({
      ...prevValue,
      friendlyName: false,
      port: false,
      hostName: false,
    }));

    // email & slack groups check
    if (
      selectedEmails.length === 0 &&
      selectedSlackGroup.length === 0 &&
      selectedGchats.length === 0
    ) {
      selectEmailsRef.current.focus();
      selectGchatRef.current.focus();
      selectSlackRef.current.focus();
      setValidationError((preValue: any) => ({
        ...preValue,
        selectedEmails: true,
        selectedSlackGroup: true,
        selectedGchats: true,
      }));
      dispatch(
        userActions.replaceUserState({
          errorMessage:
            'Please select Emails, Gchat or Slack groups for receiving url monitoring notifications',
        })
      );
      return;
    }

    if (selectedRegions.length === 0) {
      setValidationError((prevValue: any) => ({
        ...prevValue,
        selectedRegions: true,
      }));
      dispatch(
        userActions.replaceUserState({
          errorMessage: 'Please choose atleast one region',
        })
      );
      return;
    }

    const statuses = selectedStatusCodes.reduce((acc: any[], curr: string) => {
      if (!curr.includes('-')) acc.push(+curr);
      else acc.push(statusRange[curr]);
      return acc;
    }, []);

    // const flatSelectedLabel = selectedLabel?.flat(Infinity);

    const params: submitUrlInterface = {
      CompanyName: userData?.CompanyName,
      FriendlyName: uptimeMonitoringDetails.friendlyName || friendlyName,
      ...(friendlyName !== uptimeMonitoringDetails.friendlyName &&
      uptimeMonitoringDetails.friendlyName
        ? { newFriendlyName: friendlyName }
        : {}),
      MonitoringLabels: selectedLabel ? [selectedLabel] : null,
      Region: [...new Set(selectedRegions)],
      Type: monitoringType,
      Frequency: +monitoringIntervalObj[monitoringInterval],
      Notifications,
      UrlId: uptimeMonitoringDetails.UrlId,
      acceptedStatuses: statuses,
      IsMonitoring: switchChecked,
      EscalationThreshold:
        selectedEscalation.length === 0 ? null : EscalationThreshold,
      NotificationInterval:
        +monitoringNotificationIntervalObj[monitoringNotificationInterval] *
        60 *
        1000,

      //check for port and http monitoring
      ...(monitoringType === MONITORING_TYPES.PORT
        ? {
            WebUrl: host.trim(),
            Port: port,
          }
        : {
            Timeout: monitoringTimeout * 1000,
            Body: jsonBody,
            Method: method,
            Headers: parsedreqHeader?.Authorization?.split('').includes('-')
              ? {}
              : parsedreqHeader,
            IsSSL: checkSSL,
            WebUrl: webUrl.trim(),
            // refreshTokenAPI: refreshTokenAPI,
          }),
    };

    onSubmit(params);
  };

  useEffect(() => {
    if (planType === 'Basic' && monitoringInterval < 100) {
      setValidationError((prevValue: any) => ({
        ...prevValue,
        monitoringInt: true,
      }));
    } else if (planType === 'Basic' && monitoringInterval === 100) {
      setValidationError((prevValue: any) => ({
        ...prevValue,
        monitoringInt: false,
      }));
    }
  }, [monitoringInterval]);

  return (
    <>
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{
          overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 },
        }}
        className="fixModal UptimeMonitoringModal"
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">
                {Object.keys(uptimeMonitoringDetails).includes('IsMonitoring')
                  ? 'EDIT URL'
                  : 'Add URL'}
              </span>
              <span className="modalClose" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
        </Grid>

        <div className="monitoringOuterBox">
          <div className="leftPanel-Monitoring pl-15 pt-20">
            {/* Monitoring type */}
            <span
              className={
                validationError.monitoringType
                  ? 'inputErrorBorder mt-20'
                  : 'mt-20'
              }
            >
              <FormControl className="w-45">
                <InputLabel
                  style={{ fontSize: '16px' }}
                  id="demo-multiple-name-label"
                >
                  Monitor Type
                </InputLabel>
                <Select
                  // readOnly={Object.keys(uptimeMonitoringDetails).includes(
                  //   'IsMonitoring'
                  // )}
                  style={{ fontSize: '16px' }}
                  inputProps={{ style: { fontSize: 16 } }}
                  inputRef={monitoringTypeRef}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  defaultValue={monitoringType}
                  onChange={handleMonitoringTypeChange}
                  input={<OutlinedInput label="Monitor Type" />}
                  MenuProps={MenuProps}
                >
                  {Object.entries(MONITORING_TYPES).map(([key, value]) => (
                    <MenuItem key={value} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>

            {/* HTTP Method */}
            {monitoringType === MONITORING_TYPES.PORT ? (
              <></>
            ) : (
              <span
                className={
                  validationError.Method ? 'inputErrorBorder mt-20' : 'mt-20'
                }
              >
                <FormControl className="w-45 ml-40">
                  <InputLabel
                    style={{ fontSize: '16px' }}
                    id="demo-multiple-name-label"
                  >
                    Method
                  </InputLabel>
                  <Select
                    style={{ fontSize: '16px' }}
                    inputProps={{ style: { fontSize: 16 } }}
                    inputRef={methodRef}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    defaultValue={method}
                    onChange={handleMethodChange}
                    input={<OutlinedInput label="Monitor Type" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="GET" value="GET">
                      GET
                    </MenuItem>
                    <MenuItem key="POST" value="POST">
                      POST
                    </MenuItem>
                    <MenuItem key="PUT" value="PUT">
                      PUT
                    </MenuItem>
                    <MenuItem key="PATCH" value="PATCH">
                      PATCH
                    </MenuItem>
                    <MenuItem key="DELETE" value="DELETE">
                      DELETE
                    </MenuItem>
                  </Select>
                </FormControl>
              </span>
            )}

            {/* Friendly name */}
            <div
              className={
                validationError.friendlyName
                  ? 'inputErrorBorder w-100'
                  : 'w-100'
              }
            >
              {' '}
              <TextField
                inputProps={{ style: { fontSize: 16 } }}
                InputLabelProps={{ style: { fontSize: 16 } }}
                onChange={handleFriendlyNameChange}
                className={
                  monitoringType === MONITORING_TYPES.PORT
                    ? 'w-100 mt-25'
                    : 'w-100 mt-15'
                }
                label="Friendly Name"
                name="friendlyName"
                rows={2}
                defaultValue={friendlyName}
                inputRef={focusOnFriendlyName}
                autoComplete="off"
              />
            </div>
            <div
              className={
                monitoringType === MONITORING_TYPES.PORT ? 'mt-25' : 'mt-15'
              }
            >
              <SelectAddItem
                menuItems={environmentLabels}
                // sendSelectItemList={selectedEnvironmentHandler}
                sendSelectedItem={selectedEnvironmentHandler}
                itemSelected={selectedLabel}
                // selectedValue={selectedLabel}
                itemIdentifier="Environment"
              />
            </div>

            {/* PORT/WebURL */}
            {monitoringType === MONITORING_TYPES.PORT ? (
              <>
                <div
                  className={
                    validationError.port ? 'inputErrorBorder w-100 ' : 'w-100 '
                  }
                >
                  {' '}
                  <TextField
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    inputRef={focusOnError}
                    onChange={handlePortChange}
                    className="w-100 mt-25"
                    label="Enter Port"
                    name="port"
                    rows={2}
                    defaultValue={port}
                    autoComplete="off"
                  />
                </div>
                {validationError.port ? (
                  <div className="errorMessage-URLMonitoring w-100 mt-5">
                    please enter a valid TCP/UDP PORT between (0-65536)
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className="w-100 ">
                {' '}
                <TextField
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  inputRef={focusOnError}
                  onChange={handleWebUrlChange}
                  className="w-100 mt-15"
                  label="Enter URL"
                  name="url"
                  rows={2}
                  defaultValue={webUrl}
                  autoComplete="off"
                />
              </div>
            )}

            {/* Host Name */}
            {monitoringType === MONITORING_TYPES.PORT ? (
              <>
                <div
                  className={
                    validationError.hostName
                      ? 'inputErrorBorder w-100 mb-10 hostNameInput'
                      : 'w-100 mb-10 hostNameInput'
                  }
                >
                  {' '}
                  <TextField
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    inputRef={focusOnError}
                    onChange={handleHostChange}
                    className="w-100 mt-25"
                    label="Enter Hostname address"
                    name="hostname"
                    rows={2}
                    defaultValue={host}
                    autoComplete="off"
                    helperText="Example: 192.168.0.1"
                  />
                </div>
                {validationError.hostName ? (
                  <div className="errorMessage-URLMonitoring w-100 mt-5">
                    Please enter a valid Hostname/IP address
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {monitoringType === MONITORING_TYPES.PORT ? (
              <></>
            ) : (
              <>
                {/* req headers */}
                <div
                  className={
                    validationError.reqHeader
                      ? 'inputErrorBorder w-100  mb-5'
                      : 'w-100  mb-5'
                  }
                >
                  {' '}
                  <TextField
                    inputProps={{
                      style: { fontSize: 16, lineHeight: 1.5, height: '47px' },
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    inputRef={reqHeaderRef}
                    onChange={handlereqHeaderChange}
                    className="w-100 mt-10"
                    label="Request header"
                    name="url"
                    rows={3}
                    defaultValue={reqHeader}
                    autoComplete="off"
                    multiline
                  />
                </div>
                {validationError.reqHeader ? (
                  <div className="errorMessage-URLMonitoring w-100">
                    {errorMessage.HeadersErrorMessage}
                  </div>
                ) : (
                  <></>
                )}
                {/* json editor */}
                <div
                  className={
                    validationError.body ? 'inputErrorBorder mb-5' : 'mb-5'
                  }
                >
                  {' '}
                  <TextField
                    inputProps={{ style: { fontSize: 16, lineHeight: 1.5 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    className="w-100  mt-10"
                    id="outlined-textarea"
                    label="Body"
                    rows={2}
                    onChange={handleBody}
                    multiline
                    value={body}
                  />{' '}
                </div>
                {validationError.body ? (
                  <div className="errorMessage-URLMonitoring">
                    {errorMessage.BodyErrorMessage}
                  </div>
                ) : (
                  <></>
                )}{' '}
              </>
            )}

            <div className="flex w-100">
              {/* Escalation Type */}
              <div className={'mt-15 w-30'}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{ fontSize: '14px' }}
                    id="demo-multiple-name-label"
                  >
                    Escalation Type
                  </InputLabel>
                  <Select
                    style={{ fontSize: '14px' }}
                    inputProps={{ style: { fontSize: 16 } }}
                    // inputRef={methodRef}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={escalationType}
                    onChange={handleEscalationTypeChange}
                    input={<OutlinedInput label="Escalation Type" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="Slack" value="Slack">
                      Slack
                    </MenuItem>
                    <MenuItem key="Gchat" value="Gchat">
                      Gchat
                    </MenuItem>
                    <MenuItem key="Email" value="Email">
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* Escalation Selection */}
              {escalationType ? (
                <div
                  className={
                    selectedEscalation.length
                      ? 'mt-15 ml-15 w-70 escalationSection'
                      : 'mt-15 ml-15 w-70'
                  }
                >
                  <FormControl fullWidth>
                    <InputLabel
                      style={{ fontSize: '14px' }}
                      id="demo-multiple-chip-label"
                    >
                      Select {escalationType} for escalation
                    </InputLabel>
                    <Select
                      style={{ fontSize: '14px' }}
                      // inputRef={selectEmailsRef}
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectedEscalation}
                      onChange={handleChangeSelectedEscalation}
                      input={
                        <OutlinedInput
                          label={`Select ${escalationType} for escalation`}
                        />
                      }
                      renderValue={(selected: any) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                          }}
                        >
                          {selected?.length <= 2 ? (
                            selected.map((value: any) => (
                              <Chip
                                className="Custom-chip"
                                size="small"
                                key={value}
                                label={value}
                                onDelete={() =>
                                  setSelectedEscalation(
                                    selectedEscalation.filter(
                                      (item: any) => item !== value
                                    )
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    style={{
                                      color: '#1583DC',
                                      fontSize: '20px',
                                    }}
                                    onMouseDown={(event: any) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            ))
                          ) : (
                            <div className="countPosition">
                              <div className="countP-fDiv">
                                {selected.map((value: any, i: number) => {
                                  if (i >= 2) return;
                                  return (
                                    <Chip
                                      className="Custom-chip"
                                      size="small"
                                      key={value}
                                      label={value}
                                      onDelete={() =>
                                        setEscalationMetaData(
                                          escalationMetaData.filter(
                                            (item: any) => item !== value
                                          )
                                        )
                                      }
                                      deleteIcon={
                                        <CancelIcon
                                          style={{
                                            color: '#1583DC',
                                            fontSize: '20px',
                                          }}
                                          onMouseDown={(event: any) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                })}
                              </div>
                              <Tooltip
                                title={
                                  <div style={{ whiteSpace: 'pre-line' }}>
                                    {selected?.slice(2).join('\n')}
                                  </div>
                                }
                              >
                                <div className="extraFields-UptimeMonitoringModal ">
                                  {' '}
                                  + {selected?.length - 2}
                                </div>
                              </Tooltip>
                            </div>
                          )}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {escalationMetaData?.length ? (
                        escalationMetaData.map((names: any, i) => (
                          <MenuItem key={names + i} value={names}>
                            <Checkbox
                              style={{
                                color: '#1583DC',
                              }}
                              checked={selectedEscalation?.indexOf(names) > -1}
                            />
                            <ListItemText primary={names} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem> No Records </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              ) : null}
            </div>
          </div>

          <div className="rightPanel-Monitoring">
            <div className="flex">
              {/* select slack notification */}
              <div
                className={
                  validationError.selectedSlackGroup
                    ? 'inputErrorBorder mt-20 mr-15 w-48 '
                    : 'mt-20 mr-15 w-48'
                }
              >
                <FormControl fullWidth>
                  <InputLabel
                    style={{ fontSize: '14px' }}
                    id="demo-multiple-chip-label"
                  >
                    Select slack for notification
                  </InputLabel>
                  <Select
                    inputRef={selectSlackRef}
                    style={{ fontSize: '14px' }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedSlackGroup}
                    onChange={handleChangeSelectedSlackGroups}
                    input={
                      <OutlinedInput
                        label={'Select slack groups for notification'}
                      />
                    }
                    renderValue={(selected: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                        }}
                      >
                        {selected?.length <= 2 ? (
                          selected.map((value: any) => (
                            <Chip
                              className="Custom-chip"
                              size="small"
                              key={value}
                              label={value}
                              onDelete={() =>
                                setSelectedSlackGroup(
                                  selectedSlackGroup.filter(
                                    (item: any) => item !== value
                                  )
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  style={{ color: '#1583DC', fontSize: '20px' }}
                                  onMouseDown={(event: any) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))
                        ) : (
                          <div className="countPosition">
                            <div className="countP-fDiv">
                              {selected.map((value: any, i: number) => {
                                if (i >= 2) return;
                                return (
                                  <Chip
                                    className="Custom-chip"
                                    size="small"
                                    key={value}
                                    label={value}
                                    onDelete={() =>
                                      setSelectedSlackGroup(
                                        selectedSlackGroup.filter(
                                          (item: any) => item !== value
                                        )
                                      )
                                    }
                                    deleteIcon={
                                      <CancelIcon
                                        style={{
                                          color: '#1583DC',
                                          fontSize: '20px',
                                        }}
                                        onMouseDown={(event: any) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              })}
                            </div>
                            <Tooltip
                              title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {selected?.slice(2).join('\n')}
                                </div>
                              }
                            >
                              <div className="extraFields-UptimeMonitoringModal">
                                {' '}
                                + {selected?.length - 2}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {notificationMetaData?.length || slackGroups?.length ? (
                      (escalationType === ESCALATION_TYPE.Slack
                        ? notificationMetaData
                        : slackGroups
                      ).map((names: any, i) => (
                        <MenuItem key={names + i} value={names}>
                          <Checkbox
                            style={{
                              color: '#1583DC',
                            }}
                            checked={selectedSlackGroup?.indexOf(names) > -1}
                          />
                          <ListItemText primary={names} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem> No Records </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              {/* select Gchat notification */}

              <div
                className={
                  validationError.selectedGchats
                    ? 'inputErrorBorder mt-20 mr-15 w-48'
                    : 'mt-20 mr-15 w-48'
                }
              >
                <FormControl fullWidth>
                  <InputLabel
                    style={{ fontSize: '14px' }}
                    id="demo-multiple-chip-label"
                  >
                    Select Gchat for notification
                  </InputLabel>
                  <Select
                    style={{ fontSize: '14px' }}
                    inputRef={selectGchatRef}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedGchats}
                    onChange={handleChangeSelectedGchat}
                    input={
                      <OutlinedInput label={' Select Gchat for notification'} />
                    }
                    renderValue={(selected: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                        }}
                      >
                        {selected?.length <= 2 ? (
                          selected.map((value: any) => (
                            <Chip
                              className="Custom-chip"
                              size="small"
                              key={value}
                              label={value}
                              onDelete={() =>
                                setSelectedGchats(
                                  selectedGchats.filter(
                                    (item: any) => item !== value
                                  )
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  style={{ color: '#1583DC', fontSize: '20px' }}
                                  onMouseDown={(event: any) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))
                        ) : (
                          <div className="countPosition">
                            <div className="countP-fDiv">
                              {selected.map((value: any, i: number) => {
                                if (i >= 2) return;
                                return (
                                  <Chip
                                    className="Custom-chip"
                                    size="small"
                                    key={value}
                                    label={value}
                                    onDelete={() =>
                                      setSelectedGchats(
                                        selectedGchats.filter(
                                          (item: any) => item !== value
                                        )
                                      )
                                    }
                                    deleteIcon={
                                      <CancelIcon
                                        style={{
                                          color: '#1583DC',
                                          fontSize: '20px',
                                        }}
                                        onMouseDown={(event: any) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              })}
                            </div>
                            <Tooltip
                              title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {selected?.slice(2).join('\n')}
                                </div>
                              }
                            >
                              <div className="extraFields-UptimeMonitoringModal ">
                                {' '}
                                + {selected?.length - 2}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {notificationMetaData?.length || gchats?.length ? (
                      (escalationType === ESCALATION_TYPE.Gchat
                        ? notificationMetaData
                        : gchats
                      ).map((names: any, i) => (
                        <MenuItem key={names + i} value={names}>
                          <Checkbox
                            style={{
                              color: '#1583DC',
                            }}
                            checked={selectedGchats?.indexOf(names) > -1}
                          />
                          <ListItemText primary={names} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem> No Records </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>

            {/* select email notification */}
            <div
              className={
                validationError.selectedEmails
                  ? 'inputErrorBorder mt-20 mr-15 '
                  : 'mt-20 mr-15 '
              }
            >
              <FormControl fullWidth>
                <InputLabel
                  style={{ fontSize: '14px' }}
                  id="demo-multiple-chip-label"
                >
                  Select emails for notification
                </InputLabel>
                <Select
                  style={{ fontSize: '14px' }}
                  inputRef={selectEmailsRef}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectedEmails}
                  onChange={handleChangeSelectedEmails}
                  input={
                    <OutlinedInput label={' Select emails for notification'} />
                  }
                  renderValue={(selected: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}
                    >
                      {selected?.length <= 2 ? (
                        selected.map((value: any) => (
                          <Chip
                            className="Custom-chip"
                            size="small"
                            key={value}
                            label={value}
                            onDelete={() =>
                              setSelectedEmails(
                                selectedEmails.filter(
                                  (item: any) => item !== value
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                style={{ color: '#1583DC', fontSize: '20px' }}
                                onMouseDown={(event: any) =>
                                  event.stopPropagation()
                                }
                              />
                            }
                          />
                        ))
                      ) : (
                        <div className="countPosition">
                          <div className="countP-fDiv">
                            {selected.map((value: any, i: number) => {
                              if (i >= 2) return;
                              return (
                                <Chip
                                  className="Custom-chip"
                                  size="small"
                                  key={value}
                                  label={value}
                                  onDelete={() =>
                                    setSelectedEmails(
                                      selectedEmails.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      style={{
                                        color: '#1583DC',
                                        fontSize: '20px',
                                      }}
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                          <Tooltip
                            title={
                              <div style={{ whiteSpace: 'pre-line' }}>
                                {selected?.slice(2).join('\n')}
                              </div>
                            }
                          >
                            <div className="extraFields-UptimeMonitoringModal ">
                              {' '}
                              + {selected?.length - 2}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {notificationMetaData?.length || emails?.length ? (
                    (escalationType === ESCALATION_TYPE.Email
                      ? notificationMetaData
                      : emails
                    ).map((names: any, i) => (
                      <MenuItem key={names + i} value={names}>
                        <Checkbox
                          style={{
                            color: '#1583DC',
                          }}
                          checked={selectedEmails?.indexOf(names) > -1}
                        />
                        <ListItemText primary={names} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem> No Records </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>

            {/* Region Selection */}

            <div
              className={
                validationError.selectedRegions
                  ? 'inputErrorBorder mt-25 mr-15'
                  : 'mt-25 mr-15'
              }
            >
              <FormControl fullWidth>
                <InputLabel
                  style={{ fontSize: '14px' }}
                  id="demo-multiple-chip-label"
                >
                  Select Regions
                </InputLabel>
                <Select
                  style={{ fontSize: '14px' }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectedRegions}
                  onChange={handleChangeSelectedRegions}
                  input={<OutlinedInput label="Select Regions" />}
                  renderValue={(selected: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}
                    >
                      {selected?.length <= 2 ? (
                        selected.map((value: any) => (
                          <Chip
                            className="Custom-chip"
                            size="small"
                            key={value}
                            label={value}
                            onDelete={() =>
                              setSelectedRegions(
                                selectedRegions.filter(
                                  (item: any) => item !== value
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                style={{ color: '#1583DC', fontSize: '20px' }}
                                onMouseDown={(event: any) =>
                                  event.stopPropagation()
                                }
                              />
                            }
                          />
                        ))
                      ) : (
                        <div className="countPosition">
                          <div className="countP-fDiv">
                            {selected.map((value: any, i: number) => {
                              if (i >= 2) return;
                              return (
                                <Chip
                                  className="Custom-chip"
                                  size="small"
                                  key={value}
                                  label={value}
                                  onDelete={() =>
                                    setSelectedRegions(
                                      selectedRegions.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      style={{
                                        color: '#1583DC',
                                        fontSize: '20px',
                                      }}
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                          <Tooltip
                            title={
                              <div style={{ whiteSpace: 'pre-line' }}>
                                {selected?.slice(2).join('\n')}
                              </div>
                            }
                          >
                            <div className="extraFields-UptimeMonitoringModal">
                              {' '}
                              + {selected?.length - 2}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(regionsData)?.length ? (
                    Object.keys(regionsData).map((names: any, i) => (
                      <MenuItem key={names + i} value={names}>
                        <Checkbox
                          style={{
                            color: '#1583DC',
                          }}
                          checked={selectedRegions?.indexOf(names) > -1}
                        />
                        <ListItemText primary={names} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem> No Records </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>

            {/* Monitoring Interval */}
            <div className="mt-20  w-100 monitoringInterval">
              {' '}
              <div className="mt-5 mr-15"> Monitoring Interval</div>
              <div
                className={
                  validationError.monitoringInt
                    ? 'monitoringIntSliderError'
                    : ''
                }
              >
                <Slider
                  aria-label="Restricted values"
                  defaultValue={monitoringInterval}
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  // valueLabelDisplay="auto"
                  marks={MonitoringIntervalMarks}
                  onChange={handleIntervalSlider}
                />
              </div>
              <div className="mt-5 ml-15">
                {(() => {
                  const interval = +monitoringIntervalObj[monitoringInterval];
                  if (interval === 1) {
                    return 'every minute';
                  } else if (interval >= 60) {
                    const hours = interval / 60;
                    return `every ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
                  } else {
                    return `every ${interval} minutes`;
                  }
                })()}
              </div>
            </div>
            {/* Monitoring timeout */}
            {monitoringType === MONITORING_TYPES.PORT ? (
              <></>
            ) : (
              <>
                <div className="  w-100 monitoringInterval">
                  {' '}
                  <div className="mt-3 mr-15"> Monitor Timeout</div>
                  <div>
                    <Slider
                      aria-label="Restricted values"
                      defaultValue={monitoringTimeout}
                      valueLabelFormat={valueLabelFormat}
                      getAriaValueText={valuetext}
                      step={1}
                      // valueLabelDisplay="auto"
                      marks={timeoutMarks}
                      onChange={handleTimeoutSlider}
                      min={1}
                      max={60}
                    />
                  </div>
                  <div className="mt-5 ml-15">
                    every {monitoringTimeout}{' '}
                    {monitoringTimeout === 1 ? 'second' : 'seconds'}
                  </div>
                </div>
              </>
            )}

            {/* Notification Time interval */}

            <div className="  w-100 monitoringInterval">
              {' '}
              <div className="mt-3 mr-15"> Notification Interval</div>
              <div>
                <Slider
                  aria-label="Restricted values"
                  defaultValue={monitoringNotificationInterval}
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  // valueLabelDisplay="auto"
                  marks={NotificationIntervalMarks}
                  onChange={handleMonitoringNotificationIntervalSlider}
                />
              </div>
              {/*2, 5, 15, 30, 60 */}
              <div className="mt-5 ml-15">
                {(() => {
                  const interval = +monitoringNotificationIntervalObj[
                    monitoringNotificationInterval
                  ];
                  if (interval >= 60) {
                    const hours = interval / 60;
                    return `every ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
                  } else {
                    return `every ${interval} minutes`;
                  }
                })()}
              </div>
            </div>

            <div className=" flex">
              {monitoringType === MONITORING_TYPES.HTTPs && (
                <div className="mr-60">
                  {/* SSL Monitoring */}
                  <span>Enable SSL certificate monitoring</span>
                  <span className="ml-20">
                    <Checkbox
                      checked={checkSSL}
                      onChange={handleCheckSSL}
                      {...label}
                    />
                  </span>
                </div>
              )}
              <div>
                {/* Monitoring Check */}
                <span>Enable Monitoring</span>
                <span className="ml-20">
                  <Checkbox
                    checked={switchChecked}
                    onChange={handleSwitch}
                    {...label}
                  />
                </span>
              </div>
            </div>
            {/* accepted status codes */}
            <div className="flex ">
              {escalationType ? (
                <div>
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    onChange={handleChangeEscalationThreshold}
                    className="w-90 mt-15 mr-15"
                    label="Escalation Threshold"
                    name="EscalationThreshold"
                    type="number"
                    rows={1}
                    defaultValue={EscalationThreshold}
                    autoComplete="off"
                  />
                </div>
              ) : null}
              <div
                className={
                  selectedStatusCodes.length
                    ? 'mt-15 w-55 escalationSection'
                    : 'mt-15 w-55'
                }
              >
                <FormControl fullWidth>
                  <InputLabel
                    style={{ fontSize: '14px' }}
                    id="demo-multiple-chip-label"
                  >
                    Accepted Status Codes
                  </InputLabel>
                  <Select
                    style={{ fontSize: '14px' }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedStatusCodes}
                    onChange={handleChangeSelectedCodes}
                    input={
                      <OutlinedInput
                        label={' Select emails for notification'}
                      />
                    }
                    renderValue={(selected: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                        }}
                      >
                        {selected?.length <= 2 ? (
                          selected.map((value: any) => {
                            return (
                              <Chip
                                className="Custom-chip"
                                size="small"
                                key={value}
                                label={value}
                                onDelete={() =>
                                  setSelectedStatusCodes(
                                    selectedStatusCodes?.filter(
                                      (item: any) => item !== value
                                    )
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    style={{
                                      color: '#1583DC',
                                      fontSize: '20px',
                                    }}
                                    onMouseDown={(event: any) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            );
                          })
                        ) : (
                          <div className="countPosition">
                            <div className="countP-fDiv">
                              {selected.map((value: any, i: number) => {
                                if (i >= 2) return;
                                return (
                                  <Chip
                                    className="Custom-chip"
                                    size="small"
                                    key={value}
                                    label={value}
                                    onDelete={() =>
                                      setSelectedStatusCodes(
                                        selectedStatusCodes?.filter(
                                          (item: any) => item !== value
                                        )
                                      )
                                    }
                                    deleteIcon={
                                      <CancelIcon
                                        style={{
                                          color: '#1583DC',
                                          fontSize: '20px',
                                        }}
                                        onMouseDown={(event: any) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              })}
                            </div>
                            <Tooltip
                              title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {selected?.slice(2).join('\n')}
                                </div>
                              }
                            >
                              <div className="extraFields-UptimeMonitoringModal ">
                                {' '}
                                + {selected?.length - 2}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {statusCodes.map((names: any, i: number) => (
                      <MenuItem key={names + i} value={names}>
                        <Checkbox
                          style={{
                            color: '#1583DC',
                          }}
                          checked={selectedStatusCodes?.indexOf(names) > -1}
                        />
                        <ListItemText primary={names} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            {/* Refresh token URL */}

            {/* {monitoringType === MONITORING_TYPES.HTTPs ? (
              <div
                className={validationError.port ? 'inputErrorBorder' : ''}
                style={{ width: '97%' }}
              >
                {' '}
                <TextField
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  onChange={handleRefreshTokenAPIChange}
                  className="w-100 mt-10"
                  label="Enter Refresh Token URL"
                  name="refresh token url"
                  defaultValue={refreshTokenAPI}
                  autoComplete="off"
                />
              </div>
            ) : (
              <></>
            )} */}
            {/* Mointoring Check */}
            {/* <div className="mt-25">
              <FormControlLabel
                control={
                  <Switch
                    checked={switchChecked}
                    onChange={handleSwitch}
                    name="Monitoring"
                  />
                }
                label="Monitoring"
              />
            </div> */}
          </div>
        </div>

        <div className=" modalFooter">
          <button
            type="button"
            className="btn btn-primary ml-10 "
            onClick={() => submit()}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default UptimeMonitoringModal;
