import { securityScanService } from '../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

export const config: any = {
  [CLOUD_PROVIDER_SERVICES.AWS]: {
    FetchScanReport: (params: any) =>
      securityScanService.FetchAWSScanReport(params),
  },
  [CLOUD_PROVIDER_SERVICES.AZURE]: {
    FetchScanReport: (params: any) =>
      securityScanService.FetchAzureScanReport(params),
  },
};
