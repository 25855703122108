import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
    },
    outerContainer: {
      height: '100vh',
      boxSizing: 'border-box',
    },
    innerContainer: {
      maxWidth: '90%',
      width: '100%',
      margin: '0 auto',
      boxSizing: 'border-box',
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      background: '#0078db',
      zIndex: 9999,
    },
    toolBar: {
      minHeight: 'auto',
      margin: '0',
      padding: '0',
    },
    tabItem: {
      display: 'block',
      '& span': {
        marginLeft: '7px',
        marginRight: '7px',
      },
    },
    azureImage: {
      textAlign: 'right',
      paddingRight: '20px',
      '& img': {
        margin: '10px',
        width: '80px',
      },
    },
    userDisplayName: {
      textTransform: 'uppercase',
      padding: '15px',
      display: 'flex',
    },
    userDropDown: {
      padding: '15px 12px',
      height: '52px',
      boxSizing: 'border-box',
      boxShadow: '0px 0px 10px #444',
      background: '#1986e0',
      '& .userDisplayDD': {
        display: 'none',
        background: '#fff',
        width: '200px',
        position: 'fixed',
        right: '0',
        top: '50px',
        color: '#000',
        boxShadow: '0 5px 10px #ccc',
        cursor: 'default',
        '& ul': {
          listStyle: 'none',
          margin: '0',
          padding: '0',
          '& li': {
            borderBottom: '1px solid #333',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            '& span:nth-child(2)': {
              width: '30px',
              textAlign: 'center',
              fontSize: '14px',
              color: '#0078db',
            },
          },
        },
      },
      '&:hover .userDisplayDD': {
        display: 'block',
      },
    },
    cLogo: {
      flexGrow: 1,
      padding: '8px 20px',
    },
    accountDisplayName: {
      padding: '15px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .fa-exchange-alt': {
        fontSize: '15px',
        padding: '0 10px',
        width: 'auto',
      },
    },
    anchorOriginTopRight: {
      top: '50px',
      right: '0px',
    },
    notificationAnchorTR: {
      top: '0px',
      right: '35px',
    },
  })
);
