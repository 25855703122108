import React from 'react';
import './viewer.css';

export const JSONViewer = ({ json }: any) => {
  //const { json, styles } = props;
  const formattedJSON = JSON.stringify(json, null, 2);
  const lines = formattedJSON.split(/\r?\n/);
  return (
    <pre className="jsonViewer">
      <div className="lineNumberContainer">
        {
          lines.map((line: string, i: number) => (<div className='lineNumber'>{i + 1}</div>))
        }
      </div>
      <div className="jsonContainer">
        {
          lines.map((line: string) => (
            <div>
              <div className="line">{line}</div>
            </div>
          ))
        }
      </div>
    </pre>
  );
};

export default JSONViewer;
