import './Billing.css';
import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableContainer } from '@mui/material';
import RequestedRow from './RequestedRow';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { config } from './config';
import { ERRORS } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ActiveFeaturesModal from '../../../components/Modals/ActiveFeatures/ActiveFeaturesModal';

const AdminPanelBilling = () => {
  const [requestsData, setRequestsData] = useState([]);
  const [activeSubData, setActiveSubData] = useState([]);
  const [networkError, setNetworkError] = useState('');
  const [features, setFeatures] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [showActiveFeaturesModal, setShowActiveFeaturesModal] = useState(false);
  const [aciveFeatures, setAciveFeatures] = useState([]);
  const history = useHistory();
  const Routes = RoutesFun();
  const closeActiveFeaturesModal = () => setShowActiveFeaturesModal(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //api calls
  const getAllFeatures = async () => {
    try {
      setLoader(true);
      const res = await config.getAllFeatures();
      const modifyFeature: any = {};
      if (res.length) {
        res.forEach((el: any) => (modifyFeature[el.name] = el.id));
        setFeatures(modifyFeature);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getAllRequest = async () => {
    try {
      setLoader(true);
      const res = await config.getAllBillingRequests();
      setRequestsData(res);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };
  const getActiveSub = async () => {
    try {
      setLoader(true);
      const res = await config.getActiveSub();
      setActiveSubData(res);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getActiveSub();
    getAllRequest();
    getAllFeatures();
  }, []);

  return (
    <>
      <div className="OrgText">Plan Activation</div>{' '}
      <TableContainer
        className="accContainer mt-20"
        sx={{ maxHeight: '400px', overflowY: 'scroll' }}
      >
        <Table
          className="table adminTable OrgTable"
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead className="admintableHead">
            <TableRow>
              <TableCell className="adminTableheading" align="right" width="2%">
                S.No.
              </TableCell>
              <TableCell className="adminTableheading" width={'10%'}>
                Organization Name
              </TableCell>
              <TableCell className="adminTableheading" width={'10%'}>
                Plan Type
              </TableCell>
              <TableCell className="adminTableheading" width={'20%'}>
                Organization Email Address
              </TableCell>
              <TableCell className="adminTableheading" width={'30%'}>
                Services
              </TableCell>
              <TableCell className="adminTableheading" width={'20%'}>
                Monthly plan amount($)
              </TableCell>
              <TableCell className="adminTableheading" width={'5%'}>
                Status
              </TableCell>
              <TableCell className="adminTableheading" width={'5%'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Loading...</TableCell>
              </TableRow>
            ) : requestsData.length ? (
              requestsData?.map((row: any, index: number) => (
                <RequestedRow
                  key={row.id + index}
                  row={row}
                  index={index}
                  features={features}
                  getAllReq={getAllRequest}
                />
              ))
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>No Requests</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="OrgText mt-40">Active Subscriptions</div>{' '}
      <TableContainer
        className="accContainer mt-20"
        sx={{ maxHeight: '400px', overflowY: 'scroll' }}
      >
        <Table
          className="table adminTable OrgTable"
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead className="admintableHead">
            <TableRow>
              <TableCell className="adminTableheading" align="right" width="2%">
                S.No.
              </TableCell>
              <TableCell className="adminTableheading">
                Organization Name
              </TableCell>
              <TableCell className="adminTableheading">Plan Type</TableCell>
              <TableCell className="adminTableheading">Start Date</TableCell>
              <TableCell className="adminTableheading">End Date</TableCell>
              <TableCell className="adminTableheading">Billing Date</TableCell>
              <TableCell className="adminTableheading">
                Monthly plan amount($)
              </TableCell>
              <TableCell className="adminTableheading">Status</TableCell>
              <TableCell className="adminTableheading">
                Subscription Mode
              </TableCell>
              <TableCell className="adminTableheading">Services</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Loading...</TableCell>
              </TableRow>
            ) : activeSubData.length ? (
              activeSubData?.map((row: any, index: number) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <span
                      className="upperCase clickble"
                      onClick={() =>
                        history.push({
                          pathname: Routes.AdminPanelOrganizationBillingDetails,
                          state: {
                            CompanyName: row.CompanyName,
                          },
                        })
                      }
                    >
                      {row.CompanyName}
                    </span>
                  </TableCell>
                  <TableCell>{row.PlanName}</TableCell>
                  <TableCell>{row.StartDate}</TableCell>
                  <TableCell>{row.EndDate}</TableCell>
                  <TableCell>{row.BillingDate}</TableCell>
                  <TableCell>
                    $ {(row.PaymentAmount / 100).toFixed(2)}
                  </TableCell>
                  <TableCell>{row.Status.split('_').join(' ')}</TableCell>
                  <TableCell>{row.SubscriptionMode}</TableCell>
                  <TableCell>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setAciveFeatures(row?.features);
                        setShowActiveFeaturesModal(true);
                      }}
                    >
                      See all active features
                    </button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>No Active Subscriptions</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showActiveFeaturesModal && (
        <ActiveFeaturesModal
          onClose={closeActiveFeaturesModal}
          show={showActiveFeaturesModal}
          features={aciveFeatures}
        />
      )}
    </>
  );
};

export default AdminPanelBilling;
