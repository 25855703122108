import {
  addSiteParams,
  getPlatformBrowserDataParams,
  getRumDataParams,
  getScriptParams,
} from '../../pages/RUM/config';
import { RUMFilters } from '../../pages/RUM/interfaces';
import { APIS } from '../../utils/apiConfig';
import { GlobalQueryParams } from '../../utils/config';
import { HttpService } from '../HttpService/HttpService';

export class RUMService {
  constructor(private httpService: HttpService) {}
  async getRUMData(params: RUMFilters): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_RUM_DATA(), {
        params: { ...params },
      });
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async addRUMSite(params: addSiteParams): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.ADD_RUM_SITE(), params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }
  async getPlatformBrowserData(
    params: getPlatformBrowserDataParams
  ): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_PLATFORM_BROSWER_DATA(),
        { params: { ...params } }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }
  async getCountryWiseSessionData(params: RUMFilters): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_COUNTRY_WISE_SESSION_DATA(),
        { params: { ...params } }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }

  async getCountryWiseViews(params: getRumDataParams): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_COUNTRY_WISE_VIEWS(),
        { params: { ...params } }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSessionViews(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_SESSIONS_VIEW(), {
        params: { ...params },
      });
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }

  async deleteRUMClient(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, APIS.DELETE_RUM_CLIENT()),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }

  async getScript(params: getScriptParams): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, APIS.GET_SCRIPT()),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }

  async getApdexDetail(params: RUMFilters): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, APIS.GET_APDEX_DETAIL()),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }

  async getPageWiseDetail(params: RUMFilters): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, APIS.GET_PAGE_WISE_DETAIL()),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (error) {
      throw error;
    }
  }
}
