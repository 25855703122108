import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { RUMColors } from './TopEntityCard';

interface BarChartProps {
  height: number;
  width: number | string;
  XAxisTitles: string[];
  data: { name: string; value: number }[];
  //   YAxisMax: number;
}
const CustomBarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
  const { height, width, data } = props;
  return (
    <div>
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={data} margin={{ top: 15 }}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="name"
            fontSizeAdjust="5px"
            padding={{ left: 1, right: 0 }}
          />
          <YAxis />
          <Tooltip label="Active Sessions" />
          {/* <Legend /> */}
          <Bar dataKey="value" fill="#2083DC" barSize={30}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={RUMColors[index % RUMColors.length]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
