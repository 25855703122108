import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { sortAndDeduplicateDiagnostics } from 'typescript';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderTop: {
      borderTop: '2px solid #1582dc',
    },
    summary: {
      fontSize: '1.4rem',
      paddingLeft: '1rem',
    },
    paper: {
      width: '100%',
      borderTop: '2px solid #1582dc',
      // padding: '2rem 0',
    },
    barContainer: {
      width: '100%',
      height: '250px',
      '& .recharts-legend-wrapper': {
        margin: 'auto !important',
        left: '0 !important',
        right: '0 !important',
      },
      '& .recharts-legend-item-text': {
        width: 'auto',
      },
    },
    table: {
      minWidth: 420,
    },
    monthlyBillingContainer: {
      width: '100%',
      padding: '0.2rem',
      // height: '644px',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: '2rem',
      '& svg *': {
        fontSize: '12px',
      },
      '&::-webkit-scrollbar': {
        width: '1rem',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    monthlySpendingHeader: {
      padding: '3.2rem',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    parentRow: {
      '&:nth-child(even)': {
        backgroundColor: '#E6F4FE',
      },
    },
    highlightedRow: {
      backgroundColor: '#f5f5f5',
    },
    defaultColorRow: {
      backgroundColor: '#FFF',
    },
    costSpendingDetailsTabular: {
      marginTop: '4rem',
    },
    collapsibleContainer: {
      backgroundColor: '#FFF',
      marginBottom: '2.5rem',
      '& .MuiBox-root': {
        margin: 0,
      },
    },
    spendSummaryOverflow: {
      maxHeight: '200px',
      overflowY: 'scroll',
    },
  })
);
export default useStyles;
