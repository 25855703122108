import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountService,
  authService,
  httpService,
  userService,
} from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import { handleError } from '../../../utils/config';

const Retail = (props: any) => {
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'Retail',
    EnrollmentNumber: '',
    AccessKey: '',
    AccountType: '',
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        // const response = await accountService.createAccount(accountDetails);
        // // toasterService.show(response, 'success');
        // history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <p className="mb-10">
                With CloudForestX, you can provide access to specific
                Subscriptions without granting complete access to the Enterprise
                Agreement or Cloud Solution Provider invoice data, as it builds
                the cost reports for the Subscription. For any additional
                inventory reporting, you can later add credentials for the
                Subscription to this account.
              </p>
              <p>Select Azure Payer Account:</p>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              Select Azure Payee Subscription ID:
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button className="noBtn" onClick={props.cancel}>
                BACK
              </button>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button type="submit" className="yesBtn">
                UPDATE
              </button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Retail;
