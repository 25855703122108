import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountService,
  authService,
  httpService,
  userService,
} from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import { handleError } from '../../../utils/config';

const EAForm = (props: any) => {
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'EA',
    EnrollmentNumber: '',
    AccessKey: '',
    AccountType: '',
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        // const response = await accountService.createAccount(accountDetails);
        // // toasterService.show(response, 'success');
        // history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <p className="mb-10">
                Your cost data is accessed by CloudForestX if you have an
                Enterprise Agreement with Microsoft. For instructions on how
                cost data is accessed through the EA Portal, visit our
                <b>support page.</b>
              </p>
              <ul className={classes.list}>
                <ol className={helperClasses.p0}>
                  <li>
                    Login to your
                    <b>EA Portal (for example https://ea.azure.com)</b>, to
                    provide CloudForestX access to your data.
                  </li>
                  <li>
                    From the left menu, choose <b>'Manage'</b> and find the
                    <b>Enrollment Number.</b>
                  </li>
                  <li>
                    Submit the <b>Enrollment Number</b> here:
                  </li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  Enrollment Number
                </InputLabel>
                <Input
                  id="enrollmentno."
                  type="password"
                  name="EnrollmentNumber"
                  inputRef={register({ required: true })}
                  value={accountDetails.EnrollmentNumber}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.EnrollmentNumber &&
                    accountDetails.EnrollmentNumber.length === 0 &&
                    'EnrollmentNumber is required'}
                </span>
              </FormControl>
              <span></span>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={4} className={helperClasses.p0}>
                  <li>
                    From the left menu, opt <b>'Reports'</b> and click
                    <b>Download Usage</b> at the top.
                  </li>
                  <li>
                    Opt for the <b>'API Access Key'</b> from the top menu and
                    pick the key you want to use.
                  </li>
                  <li>Provide the selected access key here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">ACCESS KEY</InputLabel>
                <Input
                  id="demo-mutiple-name"
                  type="password"
                  name="AccessKey"
                  inputRef={register({ required: true })}
                  value={accountDetails.AccessKey}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.AccessKey &&
                    accountDetails.AccessKey.length === 0 &&
                    'Access Key is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={7} className={helperClasses.p0}>
                  <li>Choose your Azure Account type:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  ACCOUNT TYPE
                </InputLabel>
                <Select
                  id="demo-mutiple-name"
                  // multiple
                  name="AccountType"
                  value={accountDetails.accountType}
                  ref={register({ required: true })}
                  onChange={handleChange}
                  input={<Input />}
                  // MenuProps={MenuProps}
                >
                  {['Commercial', 'Azure Government', 'Azure Germany'].map(
                    (item: any) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
                <span className={classes.warningMessage}>
                  {errors?.AccountType && 'AccountType is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={6}></Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button className="noBtn" onClick={props.cancel}>
                BACK
              </button>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button type="submit" className="yesBtn">
                UPDATE
              </button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EAForm;
