import dotenv from 'dotenv';
dotenv.config();

export const JWT = {
  SESSIONEXPIRED: 'session expired',
  JWTEXPIRED: 'jwt expired',
};

export const CLOUD_PROVIDER_SERVICES = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  ALL: 'all',
};

export const HTTP_STATUS_CODE = {
  CONFLICT: 409,
  PASSED: 200,
  NOT_FOUND: 404,
};

export const CURRENCY_SYMBOL: { [key: string]: string } = {
  USD: '$',
  INR: '₹',
};

export const REFRESH_TOKEN_FREQUENCY = 259200000; //3300000

export const LOCAL_STORAGE_VARIABLES = {
  LAST_TOKEN_REFRESH: 'last-token-refresh',
  AUTH_STATE: 'auth-state',
};
export const SESSION_STORAGE_VARIABLES = {
  IR_SELECTED_SUBSCRIPTION: 'IR_SELECTED_SUBSCRIPTION',
  IR_TYPE_SELECTED_SUBSCRIPTION: 'IR_TYPE_SELECTED_SUBSCRIPTION',
};

export const SECURITY_SCAN_PRACTICE_STATUSES = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
};

export const SCAN_RESULT_STATUSES = {
  PENDING: 0,
  SUCCEEDED: 1,
  FAILED: 2,
};

export const TRIAL_PERIOD_MESSAGE = {
  TRIAL_PERIOD_OVER:
    "Your Trial Period is over. Please contact support team at 'support@cloudforestx.com'.",
};

export const ERRORS = {
  NETWORK_ERROR: 'Network Error',
};

export const LOGIN_SESSION = {
  EXPIRED:
    'Your Login Session is expired. You will be Redirected to Login screen.',
};

export const REPORT_MESSAGE = {
  SUCCESS: 'Report downloaded successfully',
  FAILED: 'Report download Failed',
};

export const EDIT_TYPE = {
  EDIT_PROFILE: 'editProfile',
};

export const PLAN_TYPE: any = {
  BASIC: 'BASIC',
  GROWTH: 'GROWTH',
  'GROWTH+': 'GROWTH+',
};

export const services = [
  'Storage Accounts',
  'Load Balancers',
  'Managed Disks',
  'Traffic Manager',
  'App Service Plans',
  'Container Instances',
  'Virtual Machines',
  'Service Bus',
  'COSMOS DB',
  'Public IP',
  'EC2',
  'EBS',
  'RDS',
  'S3',
  'AWS Load Balancers',
  'SNS',
  'DynamoDB',
  'Elastic BeanStalk',
  'S3 Glacier',
  'EMR',
  'Fsx',
  'EFS',
  'AWS Load BalancersV1',
  'AWS Load BalancersV2',
  'ElastiCache',
  'AWS Resource Groups',
  'SQL Server',
  'WorkSpaces',
  'Redis Cache',
  'HDInSight Cluster',
  'Resource Groups',
  'FSx',
  'Elastic Container Service Instance',
  'Simple Queue Service',
  'Elastic IP addresses',
  'Global Accelerator',
];

export const PERMISSION_LEVELS = {
  READ: 1,
  WRITE: 2,
};
export const FEATURES = {
  IDLE_RESOURCES: 'IDLE RESOURCES',
  RIGHTSIZING: 'RIGHTSIZING',
  SCHEDULE_INSTANCES: 'SCHEDULE INSTANCES',
  COST_SAVING: 'COST SAVING',
  INVENTORY: 'INVENTORY',
  COST_FORECASTING: 'COST FORECASTING',
  BILLING_SIMPLIFIED: 'BILLING SIMPLIFIED',
  SECURITY_SCAN: 'SECURITY SCAN',
  UPTIME_MONITORING: 'UPTIME MONITORING',
  UPLOADED_DOCUMENTS: 'UPLOADED_DOCUMENTS',
  SERVICE_REPORTS: 'SERVICE REPORTS',
};

export const PERMISSION_KEY: { [key: string]: string } = {
  IdleInstance: 'idle_instance',
  RightSizing: 'right_sizing',
  Tags: 'tags',
  Scheduling: 'scheduling',
  CostForcasting: 'cost_forcasting',
  Invoice: 'invoice',
  Billing: 'Billing',
};

export const STATUS: any = {
  Failed: 'Failed',
  requires_source: 'Failed',
  past_due: 'Failed',
  Success: 'Success',
  succeeded: 'Success',
  active: 'Success',
  Info: 'Info',
  incomplete_expired: 'Info',
  canceled: 'Info',
  'In Progress': 'In Progress',
  incomplete: 'In Progress',
};

// export const INSTANCE_TYPE_SHORT = {
//   VM: 'Virtual Machines',
//   Storage: 'Storage Accounts',
//   MDisk: 'Managed Disks',
//   Sql: 'SQL Server',
//   IP: 'Public IP',
//   Workspace: 'Workspaces',
//   App: 'App Service Plans',
//   Container: 'Container Instances',
//   Redis: 'Redis Cache',
//   HDCluster: 'HDInSight Cluster',
//   SBus: 'Service Bus',
//   TM: 'Traffic Manager',
//   RG: 'Resource Groups',
//   CD: 'COSMOS DB',
//   Load: 'Load Balancers',
//   EC2: 'EC2',
//   S3: 'S3',
//   EFS: 'EFS',
//   FSx: 'FSx',
//   SG: 'S3 Glacier',
//   Cache: 'Elastic Cache',
//   ECS: 'Elastic Container Service Instance',
//   SQS: 'Simple Queue Service',
//   BeanStalk: 'Elastic BeanStalk',
//   EBS: 'EBS',
//   ELasticIP: 'Elastic IP addresses',
//   DynamoDB: 'DynamoDB',
//   RDS: 'RDS',
//   EMR: 'EMR',
//   SNS: 'SNS',
//   GA: 'Global Accelerator',
//   AWSLoad: 'AWS Load Balancers',
//   AWSRG: 'AWS Resource Groups',
//   Lambda: 'Lambda',
// };

export const INSTANCE_TYPE_SHORT: Record<string, string> = {
  'Virtual Machines': 'VM',
  'Storage Accounts': 'Storage',
  'Managed Disks': 'MDisk',
  'SQL Server': 'Sql',
  'Public IP': 'IP',
  Workspaces: 'Workspace',
  'App Service Plans': 'App',
  'Container Instances': 'Container',
  'Redis Cache': 'Redis',
  'HDInSight Cluster': 'HDCluster',
  'Service Bus': 'SBus',
  'Traffic Manager': 'TM',
  'Resource Groups': 'RG',
  'COSMOS DB': 'CD',
  'Load Balancers': 'Load',
  EC2: 'EC2',
  S3: 'S3',
  EFS: 'EFS',
  FSx: 'FSx',
  'S3 Glacier': 'SG',
  'Elastic Cache': 'Cache',
  'Elastic Container Service Instance': 'ECS',
  'Simple Queue Service': 'SQS',
  'Elastic BeanStalk': 'BeanStalk',
  EBS: 'EBS',
  'Elastic IP addresses': 'ELasticIP',
  DynamoDB: 'DynamoDB',
  RDS: 'RDS',
  EMR: 'EMR',
  SNS: 'SNS',
  'Global Accelerator': 'GA',
  'AWS Load Balancers': 'AWSLoad',
  'AWS Resource Groups': 'AWSRG',
  Lambda: 'Lambda',
};

export const DOCUMENT_UPLOADE_RESPONSES = {
  LOGIN_SUCCESSFULLY: 'login successfully',
  UPLOADED_DOCUMENT_LIST: 'Uploaded document list',
  TOKEN_EXPIRED: 'Token expired',
  INVALID_TOKEN: 'Invalid token',
};

export const EXCLUDED_EMAILS = ['jobs@cloudforestx.com'];

export const TOKEN_TIMING = {
  REFRESH_TOKEN_API_HITTING_TIME: 1000 * 60 * 5, //15min //in ms
  TOKEN_EXPIRY_TIME: 1000 * 60 * 60, //1h //in ms
};

export const currencies: any = {
  USD: {
    name: 'United States Dollars',
    symbol: '$',
    shortHand: 'USD',
    currency: 'en-US',
  },
  INR: {
    name: 'Indian Rupees',
    symbol: '₹',
    shortHand: 'INR',
    currency: 'en-IN',
  },
  CAD: {
    name: 'Canadian Dollars',
    symbol: 'C$',
    shortHand: 'CAD',
    currency: 'en-CA',
  },
  GBP: {
    name: 'British Pounds Sterling',
    symbol: '£',
    shortHand: 'GBP',
    currency: 'en-GB',
  },
  CNY: {
    name: 'Chinese Yuan',
    symbol: '¥',
    shortHand: 'CNY',
    currency: 'zh-CN',
  },
  AUD: {
    name: 'Australian Dollars',
    symbol: 'A$',
    shortHand: 'AUD',
    currency: 'en-AU',
  },
  BRL: {
    name: 'Brazilian Real',
    symbol: 'R$',
    shortHand: 'BRL',
    currency: 'pt-BR',
  },
  DKK: {
    name: 'Danish Kroner',
    symbol: 'kr',
    shortHand: 'DKK',
    currency: 'da-DK',
  },
  EUR: { name: 'Euros', symbol: '€', shortHand: 'EUR', currency: 'de-DE' },
  HKD: {
    name: 'Hong Kong Dollars',
    symbol: 'HK$',
    shortHand: 'HKD',
    currency: 'zh-HK',
  },
  JPY: {
    name: 'Japanese Yen',
    symbol: '¥',
    shortHand: 'JPY',
    currency: 'ja-JP',
  },
  KRW: { name: 'Korean Won', symbol: '₩', shortHand: 'KRW', currency: 'ko-KR' },
  NZD: {
    name: 'New Zealand Dollars',
    symbol: 'NZ$',
    shortHand: 'NZD',
    currency: 'en-NZ',
  },
  NOK: {
    name: 'Norwegian Kroner',
    symbol: 'kr',
    shortHand: 'NOK',
    currency: 'nb-NO',
  },
  CHF: {
    name: 'Swiss Francs',
    symbol: 'CHF',
    shortHand: 'CHF',
    currency: 'fr-CH',
  },
  SGD: {
    name: 'Singapore Dollars',
    symbol: 'S$',
    shortHand: 'SGD',
    currency: 'en-SG',
  },
  MXN: {
    name: 'Mexican Pesos',
    symbol: 'MX$',
    shortHand: 'MXN',
    currency: 'es-MX',
  },
};

export const ACCOUNT_SELECTION_URL_FIELDS = [
  'subscriptionId',
  'accountId',
  'cloudProvider',
  'selectedCurrency',
];

export enum NOTIFICATION_PROVIDER {
  EMAIL = 'Email',
  SLACK = 'Slack',
  GCHAT = 'Gchat',
  ESCALATION_EMAIL = 'EscalationEmail',
  ESCALATION_SLACK = 'EscalationSlack',
  ESCALATION_GCHAT = 'EscalationGchat',
}

export const PRIMARY_REGION: string = 'us-east-1';
