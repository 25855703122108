import React, { useState, useEffect } from 'react';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { Grid, TextField } from '@material-ui/core';
import vm from '../../assets/images/vm.png';
import storage from '../../assets/images/storage.png';
import sql from '../../assets/images/sql-server.png';
import loadbalance from '../../assets/images/load-balance.png';
import cd from '../../assets/images/cosmos-db.png';
import publicIP from '../../assets/images/public-ip.png';
import workplace from '../../assets/images/workplace.png';
import manageDisk from '../../assets/images/manage-disk.png';
import appService from '../../assets/images/app-service.png';
import containerService from '../../assets/images/container-service.png';
import redis from '../../assets/images/redis.png';
import resourceGroup from '../../assets/images/resource-group.png';
import serviceBus from '../../assets/images/service-bus.png';
import trafficManager from '../../assets/images/traffic-manager.png';
import hdInsight from '../../assets/images/hd-insight.png';
import { inventoryService } from '../../services';
import { useHistory } from 'react-router';
import './Inventories.css';

import { useSelector } from 'react-redux';
import { accountState } from '../../store/index';
import { costFormatter2, handleError } from '../../utils/config';
import { Autocomplete } from '@material-ui/lab';
import {
  config,
  DataItem,
  AwsSearchField,
  AzureSearchField,
  resourceNameColumn,
} from './config';
import SearchedResults from '../../components/SearchedResult/SearchedResults';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { ALL_VALUE } from '../Invoice/config';

const images: { [key: string]: any } = {
  VM: vm,
  Storage: storage,
  Sql: sql,
  Load: loadbalance,
  CD: cd,
  IP: publicIP,
  Workspace: workplace,
  MDisk: manageDisk,
  App: appService,
  Container: containerService,
  Redis: redis,
  RG: resourceGroup,
  SBus: serviceBus,
  TM: trafficManager,
  HDCluster: hdInsight,
};

const storageKeys = {
  SELECTED_INVENTORY_SUBSCRIPTION: 'SELECTED_INVENTORY_SUBSCRIPTION',
};

export const getSelectedSubscription = (): string => {
  return (
    localStorage.getItem(storageKeys.SELECTED_INVENTORY_SUBSCRIPTION) || 'all'
  );
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.SELECTED_INVENTORY_SUBSCRIPTION, value);
};

export const Inventories: React.FC = () => {
  const history = useHistory();

  const [loader, setLoader] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<boolean>(false);
  const [isRecentResourceLoad, setIsRecentResourceLoad] = useState<boolean>(
    false
  );
  const [searchingData, setSearchingData] = useState<any>();
  const [inventoryData, setInventoryData] = useState<any>([]);
  const [availableInventory, setAvailableInventory] = useState<any>([]);

  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = selectedAccountInfo.selectedAccount;
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  const cloudProvider = selectedAccount?.CloudProvider;
  const isAccountSelected = useSelector(accountState).isAccountSelected;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [recentResourceList, setRecentResourceList] = useState({});

  const isAccountActive = selectedAccount?.Credentials;

  const [isNetworkError, setNetworkError] = React.useState(false);
  const [searchedResults, setSearchedResults] = React.useState<Array<any>>([]);
  const [value, setValue] = React.useState(null);
  const [searchedDataLength, setSearchedDataLength] = useState<string[]>([]);
  const [allSearchFieldKey, setAllSearchFieldKey] = useState<string[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const company = localStorage.getItem('CompanyName');
  type DataType = typeof searchingData[keyof typeof searchingData][0];

  const getRecentCreatedResource = async () => {
    try {
      setIsRecentResourceLoad(true);
      const cloudProvider: any = selectedAccount?.CloudProvider;
      let data;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        data = {
          ...(selectedAccountId !== ALL_VALUE
            ? { accountId: selectedAccountId }
            : {}),
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        data = {
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
        };
      }

      const result = await config.recentCreatedResource(data);
      if (result) {
        setIsRecentResourceLoad(false);
        setRecentResourceList(result);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    }
  };

  const getSearchedData = async () => {
    try {
      setLoadingText(true);
      const cloudProvider: any = selectedAccount?.CloudProvider;
      let data;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        data = {
          ...(selectedAccountId !== ALL_VALUE
            ? { accountId: selectedAccountId }
            : {}),
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        data = {
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
        };
      }
      const result = await config[cloudProvider]?.searchData(data);
      setLoadingText(false);
      setSearchingData(result);
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    }
  };

  const getInventoryList = async () => {
    try {
      setLoader(true);
      const data: any = [];
      let params;
      if (cloudProvider?.toUpperCase() === CLOUD_PROVIDER_SERVICES.AWS) {
        params = {
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      } else {
        params = {
          subscriptionId: selectedSubscriptionId,
          accountId: selectedAccountId,
          cloudProvider,
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      }

      const result = await inventoryService.getInventoryList(params);
      const company = localStorage.getItem('CompanyName');

      result.forEach((res: any) => {
        const values: any = {};

        if (Object.keys(res).length) {
          values[res.Resources] = [
            `/${company}/inventories/${res.ShortHand?.toLowerCase()}`,
            images[res.ShortHand],
          ];
        }

        const exists =
          data.filter(function (o: any) {
            return o.hasOwnProperty(res.Resources);
          }).length > 0;
        if (!exists) data.push(values);
      });

      setAvailableInventory(data);
      setUpdatedCurrency(selectedCurrency);
      setInventoryData(result);
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getInventoryList();
    getSearchedData();
    getRecentCreatedResource();
  }, [selectedSubscriptionId, selectedAccountId, selectedCurrency]);

  useEffect(() => {
    if (selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      setAllSearchFieldKey(Object.keys(AzureSearchField));
    } else if (selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
      setAllSearchFieldKey(Object.keys(AwsSearchField));
    }

    getSearchedData();
  }, [isAccountActive, isAccountSelected]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  const searchData = (searchTerm: string): Array<DataItem> => {
    let result: Array<DataItem> = [];
    searchTerm = searchTerm || '';
    const searchTermLowerCase = searchTerm?.toLowerCase();
    if (selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      for (const [key, value] of Object.entries(AzureSearchField)) {
        if (searchingData && searchingData[key]) {
          const searchData = searchingData[key]?.filter(
            (dataItem: DataType) => {
              return (dataItem[value] || '')
                .toLowerCase()
                .includes(searchTermLowerCase);
            }
          );

          result = [...result, ...searchData];
        }
      }
    } else if (selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
      for (const [key, value] of Object.entries(AwsSearchField)) {
        if (searchingData && searchingData[key]) {
          const searchData = searchingData[key]?.filter(
            (dataItem: DataType) => {
              return (dataItem[value] || '')
                .toLowerCase()
                .includes(searchTermLowerCase);
            }
          );

          result = [...result, ...searchData];
        }
      }
    }
    return [...result];
  };

  const formatSearchedData = (value: any) => {
    return value.reduce((acc: any, item: any) => {
      let itemType;
      if (selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        itemType =
          Object.keys(AzureSearchField).find(
            (key) =>
              key !== 'Services' &&
              item &&
              item.hasOwnProperty(AzureSearchField[key])
          ) || '';
      } else if (
        selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS
      ) {
        itemType =
          Object.keys(AwsSearchField).find(
            (key) =>
              key !== 'Services' &&
              item &&
              item.hasOwnProperty(AwsSearchField[key])
          ) || '';
      }
      if (itemType) {
        if (!acc[itemType]) {
          acc[itemType] = [];
        }
        acc[itemType].push(item);
      }
      return acc;
    }, {});
  };

  const handleOptionSelected = (event: any, value: any) => {
    if (value && value.id === -1) {
      // Handle "See All" option selected
      setValue(null);
    } else {
      if (value?.Services) {
        const newData = formatSearchedData(value?.value);
        setValue(newData);
        setSearchedResults([newData]);
      } else if (typeof value === 'object') {
        const newData = formatSearchedData([value]);
        setValue(newData);
        setSearchedResults([newData]);
      }
    }
  };
  const isImageExits = (imageName: string) => {
    try {
      require(`../../assets/images/IdleServices/${imageName}.png`);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    setSearchedDataLength(
      searchedResults.length > 0 ? Object.keys(searchedResults[0]) : []
    );
  }, [value, searchedResults]);

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}

      <div className="inventoryHeader mb-15">
        <span
          className="inventoryHeading font-26px fw-300"
          style={{ cursor: 'pointer' }}
          onClick={() => setSearchedResults([])}
        >
          Inventories
        </span>
        <div className="inventoryFlexContent mr-10">
          <Autocomplete
            className="mr-5 ml-5 w-50 searchLabel"
            options={[
              {
                Services: 'See All',
                'See All': searchData(searchTerm).length,
                value: searchData(searchTerm),
              },
              ...searchData(searchTerm).filter((option) => {
                return allSearchFieldKey.some((fieldKey) => {
                  const searchFieldKey =
                    selectedAccount?.CloudProvider ===
                    CLOUD_PROVIDER_SERVICES.AWS
                      ? AwsSearchField[fieldKey]
                      : AzureSearchField[fieldKey];
                  return (
                    option.hasOwnProperty(searchFieldKey) &&
                    option[searchFieldKey] !== null
                  );
                });
              }),
            ]}
            groupBy={(option) => {
              const result: Array<string> = [];
              allSearchFieldKey.forEach((fieldKey) => {
                const searchFieldKey =
                  selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                    ? AwsSearchField[fieldKey]
                    : AzureSearchField[fieldKey];
                if (option.hasOwnProperty(searchFieldKey)) {
                  result.push(fieldKey);
                }
              });
              return result.join(', ');
            }}
            getOptionLabel={(option) => {
              const result: Array<string> = [];
              allSearchFieldKey.forEach((fieldKey) => {
                const searchFieldKey =
                  selectedAccount?.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                    ? AwsSearchField[fieldKey]
                    : AzureSearchField[fieldKey];
                if (
                  option.hasOwnProperty(searchFieldKey) &&
                  option[searchFieldKey]
                ) {
                  result.push(
                    `${fieldKey}: ${
                      (option as Record<string, any>)[searchFieldKey]
                    }`
                  );
                }
              });
              return result.join(', ');
            }}
            onChange={handleOptionSelected}
            renderOption={(option) => (
              <>
                {loadingText ? (
                  <p>loading...</p>
                ) : (
                  <span>
                    {allSearchFieldKey.map((fieldKey: string) => {
                      const searchFieldKey =
                        selectedAccount?.CloudProvider ===
                        CLOUD_PROVIDER_SERVICES.AWS
                          ? AwsSearchField[fieldKey]
                          : AzureSearchField[fieldKey];
                      if (option.hasOwnProperty(searchFieldKey)) {
                        return `${searchFieldKey
                          .replace(/(_|-)/g, ' ')
                          .trim()}: ${option[searchFieldKey]} `;
                      }
                      return null;
                    })}
                  </span>
                )}
              </>
            )}
            renderInput={(params) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                className="inventorySearch"
              >
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  onBlur={() => setSearchTerm('')}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </div>
            )}
            classes={{
              groupLabel: 'MuiAutocomplete-groupLabel',
              option: 'MuiAutocomplete-option',
            }}
          />
          <SelectedAccount onAccountChange={onAccountChange} />
        </div>
      </div>

      {!loader && !inventoryData && !availableInventory.length ? (
        <div className="noRecordsFound">
          No Inventory Found.
          {inventoryData.length > 0 ? '' : 'Please Visit Later.'}
        </div>
      ) : searchedDataLength.length > 0 ? (
        <SearchedResults searchedResult={value} />
      ) : (
        <>
          <Grid className="inventoriesCardContainer" container spacing={2}>
            {inventoryData
              .filter((data: any) => data.Count !== 0)
              .map((data: any, i: any) => (
                <Grid item xs={12} sm={4} md={3} key={i}>
                  <div
                    onClick={() => {
                      history.push({
                        pathname:
                          '/' + company + '/inventories/' + data.ShortHand,
                        state: {
                          selectedSubscriptionId,
                          selectedAccountId,
                          InventoryType: data.Resources,
                          cloudProvider,
                          totalResourcesCount: data.Count,
                        },
                      });
                    }}
                    className="idleBox"
                  >
                    <div style={{ width: '12vw' }}>
                      <div className="font-18px">{data.Resources}</div>
                      <div className="pt-10">
                        <p>Total Resources: {data.Count}</p>
                        <p>
                          MTD Cost:{' '}
                          <span className="fw-700">
                            {/* {currency} */}
                            {costFormatter2(updatedCurrency, data.Cost)}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      {isImageExits(data.ShortHand) ? (
                        <img
                          className="idleImg"
                          src={
                            // eslint-disable-next-line @typescript-eslint/no-var-requires
                            require(`../../assets/images/IdleServices/${data.ShortHand}.png`)
                              .default
                          }
                          alt={`${data.ShortHand}`}
                        />
                      ) : (
                        <img className="idleImg" alt={`${data.ShortHand}`} />
                      )}
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>

          <div className="sContainer mt-20 mb-10">
            <Grid item md={12} sm={12} xs={12}>
              <span className="inventoryReportsHeading">
                Recently created Instances/Resources
              </span>
            </Grid>
          </div>
          <div>
            <Grid item md={12} sm={12} xs={12}>
              <TableContainer
                style={{ maxHeight: '50vh', overflow: 'auto' }}
                className="accContainer"
              >
                <Table
                  className="table adminTable adminPanelTable"
                  size="small"
                  stickyHeader
                  aria-label="a dense table"
                >
                  <TableHead className="admintableHead">
                    <TableRow>
                      <TableCell className="adminTableheading">
                        Instances Name
                      </TableCell>
                      <TableCell className="adminTableheading">
                        Resource Type
                      </TableCell>
                      <TableCell className="adminTableheading">
                        Created Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(recentResourceList).map(
                      ([resourceName, value]: any, index1: number) =>
                        value.length > 0 &&
                        !isRecentResourceLoad && (
                          <>
                            <TableRow
                              className="bg-gray"
                              key={index1 + resourceName}
                            >
                              <TableCell className="fw-700">
                                Service Name: {resourceName}
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>

                            {value.map((obj: any, index2: any) => (
                              <TableRow key={index2 + obj.createdAt}>
                                <TableCell>
                                  {obj[resourceNameColumn[resourceName]]}
                                </TableCell>
                                <TableCell>{resourceName}</TableCell>
                                <TableCell>{obj.createdAt}</TableCell>
                              </TableRow>
                            ))}
                          </>
                        )
                    )}
                    {isRecentResourceLoad ? (
                      <TableRow>
                        <TableCell className="inventoryTableCell" colSpan={17}>
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : Object.entries(recentResourceList).every(
                        ([, value]: any) => value.length === 0
                      ) ? (
                      <TableRow>
                        <TableCell className="inventoryTableCell" colSpan={17}>
                          No records found
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default React.memo(Inventories);
