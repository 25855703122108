import {
  appDashboardService,
  organisationDetailsService,
} from '../../../services';

export const config: any = {
  fetchDashboardAccounts: () => appDashboardService.getAccountsData(),
  getUsers: (company: string) => organisationDetailsService.getUsers(company),
  getAccountDetails: (company: string) =>
    organisationDetailsService.getAccountDetails(company),
  updateAccountDetails: (params: any) =>
    organisationDetailsService.updateAccountDetails(params),
  deleteAccountDetails: (params: any) =>
    organisationDetailsService.deleteAccountDetails(params),
};
