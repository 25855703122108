import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas/routes.schema';
import costOptimization from '../../../assets/images/cost-optimization.png';

export const AzsCostOptimization: React.FC = () => {
  const history = useHistory();
  const Routes= RoutesFun();
  return (
    <>
      <div className="home-tile cf-row">
        <div className="innerContainer12 flex-container">
          <div className="mb-5 contentArea">
            <div className='mt-10'>
              <h4>
                <span className="red">
                  CloudForestX &amp; Azure / AWS Cost Optimization
                </span>
                <p className='mt-0'>Maximizing Cloud Potential &amp; Fueling Innovation.</p>
                {/* <span>for Making Money</span> */}
              </h4>
              <p className="mt-20">
                CloudForestX leverages its years of Microsoft Azure / AWS expertise
                and knowledge of Cloud Computing to help organizations analyze
                their Azure / AWS usage, services, and cost options, making sure that
                they are paying only for what they need – drastically reducing
                the Azure / AWS monthly spend.
              </p>
              <p className="mt-20 mb-20">
                Our well-designed approach, which includes end-to-end
                inspection, assessment, migration, management, and optimization,
                helps you gain complete visibility into your Cloud spend so that
                you can understand and forecast your bill, optimize workload
                costs, and control your spending.
              </p>
              <Button
                variant="outlined"
                className="red red-border mt-10 mb-20"
                onClick={() => history.push(Routes.RegistrationLogin)}
              >
                Start Optimizing Your Azure / AWS Spend Today!
              </Button>
            </div>
          </div>
          <div className="contentImage">
            <img
              className="flex-img img-width"
              src={costOptimization}
              width="100%"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AzsCostOptimization;
