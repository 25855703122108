import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    pagination: {
      border:'none',
      '& .MuiTablePagination-caption': {
        fontSize: '1.3rem'
      },
      '& .MuiTablePagination-actions svg':{
        fontSize:'2rem',
        position:'relative',
        top:'2px'
      },
      '& .MuiTablePagination-toolbar': {
        minHeight: '30px',
        fontSize: '1.3rem'
      },
      '& .MuiTablePagination-input':{
        border: '1px solid #ccc',
        borderRadius: '3px',
        '& svg':{
          top:'calc(50% - 9px)'
        }
      }
    },
  })
);

export default useStyles;
