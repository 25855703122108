import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas';
import { accountService } from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { useDispatch } from 'react-redux';
import { accountActions } from '../../../store/accountSlice';
import { handleError } from '../../../utils/config';

const Subscription = (props: any) => {
  const Routes = RoutesFun();
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();

  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'Subscription',
    Type: 'Account',
    AzureId: '',
    ApplicationId: '',
    ClientSecret: '',
    SubscriptionId: '',
    AzureAccountType: '',
  });

  const [showClientSecret, setShowClientSecret] = React.useState<any>(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [errmsgDisplay, seterrmsgDisplay] = React.useState('d-none');
  const [successMsg, setSuccessMsg] = React.useState<any>([]);
  const [successmsgDisplay, setsuccessmsgDisplay] = React.useState('d-none');
  const [buttonDisabled, setButtonDisabled] = React.useState<any>(false);
  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const onSubmit = async () => {
    try {
      setLoader(true);
      if (accountDetails) {
        // const accountData = { CloudProvider: 'AZURE' };
        // dispatch(
        //   accountActions.replaceAccountState({
        //     selectedAccount: accountData,
        //     isAccountSelected: true,
        //   })
        // );
        const response = await accountService.createAccount(accountDetails);
        setErrorMsg('');
        setSuccessMsg(response);
        seterrmsgDisplay('d-none');
        setsuccessmsgDisplay('d-block');
        setButtonDisabled(true);
        setTimeout(() => {
          history.push(Routes.AccountDetails);
        }, 2000);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      const company = localStorage.getItem('CompanyName');
      setButtonDisabled(false);
      if (e && e.error && e.error.message) {
        if (e.error.message !== 'The provided credentials is invalid!') {
          setErrorMsg(e.error.message);
        }
        seterrmsgDisplay('d-block');
        setsuccessmsgDisplay('d-none');
        handleError(e, history);
        if (e.error.message == 'The provided credentials is invalid!') {
          history.push('/' + company + '/accounts');
        }
      }
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleClickShowClientSecret = () => {
    setShowClientSecret(!showClientSecret);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div className="mt-10">
      {loader ? <LoadingScreen /> : ''}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <p className="mb-10">
                Your resources are accessed by CloudForestX. If you have an
                Azure Active Directory setup with Microsoft
              </p>
              <ul className={classes.list}>
                <ol className={helperClasses.p0}>
                  <li>
                    Login to the Azure portal and choose Active Directory in the
                    main toolbar. Then click Properties.
                  </li>
                  <li>Enter the Azure Active Directory ID (Tenent Id) here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                className="mt-15 mb-20 configureFormElement w-70"
              >
                <InputLabel
                  className=""
                  id="demo-mutiple-name-label"
                  htmlFor="type"
                >
                  MICROSOFT AZURE ACTIVE DIRECTORY ID
                </InputLabel>
                <OutlinedInput
                  id="directoryId"
                  type="text"
                  name="AzureId"
                  inputRef={register({
                    required: true,
                    pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                  })}
                  defaultValue={accountDetails.AzureId}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {(formState.touched.AzureId &&
                    accountDetails.AzureId.length === 0 &&
                    'Directory Id is required') ||
                    (errors.AzureId && 'Enter valid Directory Id')}
                </span>
              </FormControl>
              <span></span>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={3} className={helperClasses.p0}>
                  <li>
                    Select App registrations in the Azure Active Directory
                    blade's <b>Manage Section.</b>
                  </li>
                  <li>
                    Press the <b>New Registration</b> button.
                  </li>
                  <li>And enter the following values:</li>
                </ol>
              </ul>
              <p className="my-10">
                <ul className="ml-30">
                  <li>Provide a name for your application.</li>
                  <li>
                    Under supported account types, leave the default setting:
                    accounts in this organizational directory only
                  </li>
                  <li>Under Redirect URI (optional)</li>
                </ul>
              </p>
              <ul className={classes.list}>
                <ol start={6} className={helperClasses.p0}>
                  <li>
                    Click on <b>Register</b>.
                  </li>
                  <li>Enter Application ID here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                className="mt-15 mb-20 configureFormElement w-70"
              >
                <InputLabel id="demo-mutiple-name-label">
                  APPLICATION ID
                </InputLabel>
                <OutlinedInput
                  id="applicationId"
                  type="text"
                  name="ApplicationId"
                  autoComplete="new-password"
                  inputRef={register({
                    required: true,
                    pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                  })}
                  defaultValue={accountDetails.ApplicationId}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {(formState.touched.ApplicationId &&
                    accountDetails.ApplicationId.length === 0 &&
                    'Application Id is required') ||
                    (errors.ApplicationId && 'Enter valid Application Id')}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={8} className={helperClasses.p0}>
                  <li>
                    Press Certificates &amp; Secrets in the application blade's
                    Manage Section.
                  </li>
                  <li>
                    Find and Click <b>New Client</b> secret, under client
                    secrets.
                  </li>
                  <li>
                    Create a name for the client secret, select its expiration
                    and click <b>Add.</b>
                  </li>
                  <li>
                    Enter the <b>Client Secret</b> value here:
                  </li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                className="mt-15 mb-20 configureFormElement w-70"
              >
                <InputLabel id="demo-mutiple-name-label">
                  CLIENT SECRET VALUE
                </InputLabel>
                <OutlinedInput
                  id="client-secret"
                  type={showClientSecret ? 'text' : 'password'}
                  name="ClientSecret"
                  autoComplete="new-password"
                  inputRef={register({
                    required: true,
                    pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                  })}
                  defaultValue={accountDetails.ClientSecret}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowClientSecret}
                      >
                        {showClientSecret ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <span className={classes.warningMessage}>
                  {(formState.touched.ClientSecret &&
                    accountDetails.ClientSecret.length === 0 &&
                    'Client Secret is required') ||
                    (errors.ClientSecret && 'Enter valid Client Secret')}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={12} className={helperClasses.p0}>
                  <li>
                    Click All Services and select Subscriptions from the left
                    navbar.
                  </li>
                  <li>
                    Choose the subscription you want to monitor from the list.
                  </li>
                  <li>Enter Azure Subscription ID:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                className="mt-15 mb-20 configureFormElement w-70"
              >
                <InputLabel id="demo-mutiple-name-label">
                  MICROSOFT AZURE SUBSCRIPTION ID
                </InputLabel>
                <OutlinedInput
                  id="demo-mutiple-name"
                  type="text"
                  name="SubscriptionId"
                  inputRef={register({
                    required: true,
                    pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                  })}
                  defaultValue={accountDetails.SubscriptionId}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {(formState.touched.SubscriptionId &&
                    accountDetails.SubscriptionId.length === 0 &&
                    'SubscriptionId is required') ||
                    (errors.SubscriptionId && 'Enter valid SubscriptionId')}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={15} className={helperClasses.p0}>
                  <li>
                    Choose Access Control (IAM) and enter a new Role Assignment.
                  </li>
                  {/* <li>Opt Reader from the Role drop-down list.</li>
                  <li>Enter the application name that you created from the Select text field and click Save.</li> */}
                  <li>Choose your Azure Account type:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                variant="outlined"
                className="mt-15 mb-20 configureFormElement w-70"
              >
                <InputLabel id="demo-mutiple-name-label">
                  ACCOUNT TYPE
                </InputLabel>
                <Select
                  id="demo-mutiple-name"
                  // multiple
                  name="AzureAccountType"
                  value={accountDetails.AzureAccountType}
                  // ref={register({ required: true })}
                  onChange={handleChange}
                  // input={<Input />}
                  // MenuProps={MenuProps}
                >
                  {['Academic', 'Commercial', 'Enterprise', 'Government'].map(
                    (item: any) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
                <span className={classes.warningMessage}>
                  {errors?.AzureAccountType && 'Account Type is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className="accountsMsg m-5 mx-10"
            >
              <div
                className={
                  errorMsg.length
                    ? 'accountsMsg alert-danger p-8 px-20 mt-5 mb-5 w-100 font-14px' +
                      errmsgDisplay
                    : ''
                }
              >
                {errorMsg}
              </div>
              <div
                className={
                  successMsg.length
                    ? 'accountsMsg alert-success p-8 px-20 mt-5 mb-5 w-100 font-14px' +
                      successmsgDisplay
                    : ''
                }
              >
                {successMsg}
              </div>
            </Grid>
          </Grid>
          <Grid container className="configureAccountBtns">
            <button
              className={`${
                buttonDisabled ? classes.disabledButton : ''
              } btn btn-secondary`}
              onClick={props.cancel}
              disabled={buttonDisabled}
            >
              BACK
            </button>

            <button
              type="submit"
              className={`${
                buttonDisabled ? classes.disabledButton : ''
              } btn btn-primary`}
              disabled={buttonDisabled}
            >
              UPDATE
            </button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Subscription;
