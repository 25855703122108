import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableContainer, Tooltip } from '@mui/material';
import { ERRORS } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { config } from './config';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const OrgBillingDetails: React.FC = (props: any) => {
  const companyName = props.location.state.CompanyName;
  const [loader, setLoader] = useState(true);

  const [networkError, setNetworkError] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);

  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);
  const history = useHistory();

  const fetchBillingHistory = async () => {
    try {
      setLoader(true);
      const res = await config.getOrgBillingHistory(companyName);
      if (res.length) {
        let totalAmount = 0;
        res.forEach((el: any) => {
          if (el.InvoiceDetails) totalAmount += el.InvoiceDetails?.AmountPaid;
        });

        setTotalAmountPaid(totalAmount);
      }
      setBillingHistory(res);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchBillingHistory();
  }, []);

  return (
    <>
      {loader && <LoadingScreen />}
      <div className="bottom-upperStrip">
        <div className="OrgText">{companyName}</div>
        <div className="OrgText">
          Total amount paid: ${(totalAmountPaid / 100).toFixed(2)}
        </div>
      </div>{' '}
      <TableContainer
        className="accContainer mt-20"
        sx={{ maxHeight: '570px', overflowY: 'scroll' }}
      >
        <Table
          className="table adminTable OrgTable"
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead className="admintableHead">
            <TableRow>
              <TableCell className="adminTableheading" align="right" width="2%">
                S.No.
              </TableCell>
              <TableCell className="adminTableheading">
                SubscriptionId
              </TableCell>
              <TableCell className="adminTableheading">Plan Name</TableCell>
              <TableCell className="adminTableheading">Start Date</TableCell>
              <TableCell className="adminTableheading">End Date</TableCell>
              <TableCell className="adminTableheading">Billing Date</TableCell>
              <TableCell className="adminTableheading">
                Subscription Cost($)
              </TableCell>
              <TableCell className="adminTableheading">
                Starting Balance($)
              </TableCell>
              <TableCell className="adminTableheading">
                Payable Amount($)
                <span className="mt-10" style={{ cursor: 'pointer' }}>
                  <Tooltip title="If Payable Amount is -ve then it represents the amount of previous subscription was higher than the amount required for current subscription so the remaining amount of previous subscription will be adjusted to the remaining wallet balance">
                    <InfoOutlinedIcon className="ml-5" />
                  </Tooltip>
                </span>
              </TableCell>
              <TableCell className="adminTableheading">
                Amount Paid($)
              </TableCell>
              <TableCell className="adminTableheading">
                Remaining Balance($)
              </TableCell>
              <TableCell className="adminTableheading">Status</TableCell>
              <TableCell className="adminTableheading">
                Subscription Mode
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <TableCell>Loading...</TableCell>
            ) : billingHistory.length ? (
              billingHistory?.map((row: any, index: number) => (
                <TableRow key={row.SubscriptionId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.SubscriptionId}</TableCell>
                  <TableCell>{row.PlanName}</TableCell>
                  <TableCell>{row.StartDate}</TableCell>
                  <TableCell>{row.EndDate}</TableCell>
                  <TableCell>{row.BillingDate}</TableCell>
                  <TableCell>
                    {' '}
                    {row?.PaymentAmount
                      ? '$' + (row?.PaymentAmount / 100).toFixed(2)
                      : '$0'}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row?.InvoiceDetails?.StartingBalance
                      ? '$' +
                        (row?.InvoiceDetails?.StartingBalance / 100).toFixed(2)
                      : '$0'}
                  </TableCell>
                  <TableCell>
                    {row?.InvoiceDetails?.TotalAmount < 0 ? (
                      <>
                        -$
                        {(
                          Math.abs(row?.InvoiceDetails?.TotalAmount) / 100
                        ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        ${(row?.InvoiceDetails?.TotalAmount / 100).toFixed(2)}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row?.InvoiceDetails?.AmountPaid
                      ? '$' + (row?.InvoiceDetails?.AmountPaid / 100).toFixed(2)
                      : '$0'}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row?.InvoiceDetails?.RemainingBalance
                      ? '$' +
                        (row?.InvoiceDetails?.RemainingBalance / 100).toFixed(2)
                      : '$0'}
                  </TableCell>
                  <TableCell>{row.Status.split('_').join(' ')}</TableCell>
                  <TableCell>{row.SubscriptionMode}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell>No Billing History</TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrgBillingDetails;
