import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import '../Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { idleInstanceService } from '../../../services';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// import { useUserData } from '../../../context/GlobalUserData';
import { useDispatch, useSelector } from 'react-redux';
import { userState, accountState } from '../../../store/index';
import { handleError } from '../../../utils/config';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
  iType: any;
  selected?: any;
}

interface Notification {
  EmailAddresses: string;
  Type: string;
  SendType: string;
  SubscriptionId?: any;
}

export const NotificationModal: React.FC<FixProps> = (props) => {
  const { children, show = false, onClose, iType, selected } = props;
  const [errorRes, setErrorRes] = React.useState('');
  const [successRes, setSuccessRes] = React.useState('');
  const history = useHistory();
  // const { user, selectedAccount } = useUserData();

  const selectedAccount = useSelector(accountState).selectedAccount;

  const [emailDefaultValue, setemailDefaultValue] = React.useState('');
  const [notificationValue, setNotificationValue] = React.useState({
    EmailAddresses: '',
    Type: iType,
    SendType: 'Send when we discover new issues',
    IAMUserId: '',

    ...(selected !== 'all' ? { SubscriptionId: selected } : {}),
  });
  const { register, handleSubmit, errors } = useForm<Notification>({
    mode: 'onChange',
  });

  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');

  const handleEmailChange = (target: any) => {
    if (target.name == 'EmailAddresses') {
      setemailDefaultValue(target.value);
      if (target.value == '') {
        setErrorRes('');
      }
    }

    if (selectedAccount?.CloudProvider.toUpperCase() === 'AWS') {
      setNotificationValue({
        ...notificationValue,
        [target.name]: target.value,
        IAMUserId: selectedAccount?.IAMUserId,
      });
    } else {
      setNotificationValue({
        ...notificationValue,
        [target.name]: target.value,
      });
    }
  };

  const emailNotification = async (e: any) => {
    setButtonStatus(true);

    try {
      const rows = await idleInstanceService.setEmailNotification(
        notificationValue
      );
      if (rows) {
        setErrorRes('');
        setSuccessRes(rows.message);
        setTimeout(() => {
          e.target.reset();
          setSuccessRes('');
        }, 3000);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        setSuccessRes('');
        // setErrorRes(e.error.message);

        handleError(e, history);
      }
    } finally {
      setButtonStatus(false);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
      {...props}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Notification</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <form
          noValidate
          onSubmit={handleSubmit(() => emailNotification(event))}
          autoComplete="off"
          className="w-100"
        >
          <Grid item md={12} className="modalBody">
            <FormControl component="fieldset" className="w-100">
              <RadioGroup
                aria-label="gender"
                name="SendType"
                value={notificationValue.SendType}
                onChange={(e) => handleEmailChange(e.target)}
                className="sendStatus"
              >
                <FormControlLabel
                  value="Send when we discover new issues"
                  control={<Radio />}
                  label="Send when we discover new issues"
                />
                <FormControlLabel
                  value="Send always"
                  control={<Radio />}
                  label="Send always"
                  className="ml-20"
                />
              </RadioGroup>
            </FormControl>

            <div className="legend">
              <span className="legendTitle">Email</span>
              <label className="w-100">
                Added emails will recieve an email with this check result
                detail. <br />
                Separate multiple emails with comma
              </label>
              <TextField
                id="emailId"
                label=""
                name="EmailAddresses"
                placeholder="(i.e. bob@cloudforestx.com, john@cloudforestx.com)"
                className="w-100"
                onChange={(e) => handleEmailChange(e.target)}
                inputRef={register({ required: true })}
              />
              {errors.EmailAddresses && (
                <div className="regMsg mt-10">Enter valid email address.</div>
              )}

              {errorRes && <div className="regMsg mt-10">{errorRes}</div>}
              {successRes && (
                <div className="alert-success p-2 px-5 mt-10">{successRes}</div>
              )}
            </div>
          </Grid>
          <div className="mt-0 modalFooter">
            <input
              type="submit"
              className="btn btn-primary ml-10"
              value="SAVE"
              disabled={buttonStatus}
            />
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Grid>
    </Modal>
  );
};
