import React, { useState, useEffect } from 'react';
import {
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { TableContainer } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../assets/ICONS/ic_round-delete-outline.svg';
import { RoutesFun } from '../../../schemas';
import { useHistory } from 'react-router-dom';
import { config as adminDashboardConfig } from '../AdminDashboard/config';
import { config as allOrgConfig } from './config';
import { ERRORS } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import CustomSwitch from '../AdminDashboard/components/CustomSwitch';
import './AllOrganizations.css';
import DeleteModal from '../../../components/Modals/DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/userSlice';

const AllOrganizations: React.FC = (props: any) => {
  const history = useHistory();
  const Routes = RoutesFun();
  const [loader, setLoader] = useState(false);
  const [companiesDetails, setCompaniesDetails] = useState([]);
  const [networkError, setNetworkError] = useState('');
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>();

  const dispatch = useDispatch();
  const onCloseDeleteModal = () => setDeleteModal(false);

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const fetchAllOrganizaionDetails = async () => {
    try {
      setLoader(true);
      const res = await adminDashboardConfig.fetchCompaniesData();
      res.sort((a: any, b: any) => a.name.localeCompare(b.name));
      if (res) setCompaniesDetails(res);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const deleteOrganization = async () => {
    try {
      const res = await allOrgConfig.deleteOrganization({
        CompanyName: clickedData.CompanyName,
      });
      if (res.sc) {
        setCompaniesDetails((preValue: any) =>
          preValue.filter(
            (company: any) => company.name !== clickedData.CompanyName
          )
        );

        dispatch(
          userActions.replaceUserState({
            successMessage: res,
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setDeleteModal(false);
    }
  };

  const returnSearchResult = (data: any) =>
    search
      ? data?.filter((org: any) =>
          org.name?.toLowerCase().includes(search.toLowerCase())
        )
      : data;

  useEffect(() => {
    fetchAllOrganizaionDetails();
  }, []);

  return (
    <>
      <div className="organizationDetails">
        <div className="OrgDetails-uppterStrip">
          <div className="OrgText">All Organization </div>
          <div className="OrgDetails-us-searchBar">
            <OutlinedInput
              id="email"
              name="EmailAddress"
              className="outlineInputArea bgcolor"
              type="email"
              autoComplete="off"
              value={search ? search : ''}
              onChange={handleSearchChange}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
            />
          </div>
        </div>
        <TableContainer
          sx={{ maxHeight: '555px', overflowY: 'scroll' }}
          className="accContainer"
        >
          <Table
            className="table adminTable OrgTable"
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell
                  className="adminTableheading"
                  align="right"
                  width="8%"
                >
                  S.No.
                </TableCell>
                <TableCell className="adminTableheading" width={'30%'}>
                  Organization Name
                </TableCell>
                <TableCell className="adminTableheading" width={'30%'}>
                  Accounts/Subscriptions
                </TableCell>
                <TableCell className="adminTableheading" width={'20%'}>
                  Status
                </TableCell>
                <TableCell className="adminTableheading" width={'25%'}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center" className="loadingSection">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : companiesDetails?.length &&
                search &&
                !returnSearchResult(companiesDetails).length ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center" className="loadingSection">
                    No Searched Records
                  </TableCell>
                </TableRow>
              ) : companiesDetails?.length ||
                returnSearchResult(companiesDetails).length ? (
                returnSearchResult(companiesDetails).map(
                  (organization: any, i: number) => (
                    <TableRow
                      key={i}
                      className={i % 2 === 0 ? 'oddRow' : 'evenRow'}
                    >
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>
                        <span
                          className="upperCase clickble"
                          onClick={() =>
                            history.push({
                              pathname: Routes.Organization,
                              state: {
                                CompanyName: organization.name,
                              },
                            })
                          }
                        >
                          {organization.name}
                        </span>
                      </TableCell>
                      <TableCell>
                        {organization.cloudProviders.join(', ')}
                      </TableCell>
                      <TableCell>
                        <div
                          className={
                            organization.IsActive === 1 ? 'active' : 'inactive'
                          }
                        >
                          {organization.IsActive ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                      <TableCell>
                        <span>
                          <CustomSwitch
                            status={organization.IsActive}
                            metaData={{
                              CompanyName: organization.name,
                            }}
                            fetchLatestData={fetchAllOrganizaionDetails}
                          />
                        </span>

                        <DeleteIcon
                          onClick={() => {
                            setDeleteModal(true);
                            setClickedData({
                              CompanyName: organization.name,
                            });
                          }}
                          className="ml-10 cursor"
                        />
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell align="center" className="loadingSection">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onClose={onCloseDeleteModal}
          metaData={`"${clickedData.CompanyName}"`}
          deleteFunction={deleteOrganization}
        />
      )}
    </>
  );
};

export default AllOrganizations;
