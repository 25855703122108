import { Grid } from '@mui/material';
import React from 'react';
import './TitleHeader.css';

interface ITitleHeader {
  extraContent?: any;
}

const TitleHeader: React.FC<ITitleHeader> = ({ children, extraContent }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className="TitleHeader"
      alignContent="center"
      position="absolute"
      marginTop="2rem"
      width="90.3%"
      zIndex={5}
    >
      <div className="inventoryHeading font-26px fw-300">{children}</div>
      {extraContent && extraContent}
    </Grid>
  );
};

export default TitleHeader;
