import React, { ReactNode, useEffect } from 'react';
import {
  Grid,
  Typography,
  Toolbar,
  AppBar,
  Tooltip,
  Snackbar,
  IconButton,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RoutesFun } from '../../schemas';
import { useStyles } from './DashboardHeader.style';
import { userService } from '../../services';
import CFLogo from '../cfLogo/cfLogo';
import { InviteModal } from '../Modals/InviteUser/InviteUser';
import { emitter, listener } from '../../Socket';
import {
  NotificationBody,
  Notifications,
} from '../Notifications/Notifications';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-213427208-1');
ReactGA.pageview(window.location.pathname + window.location.search);
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../store/userSlice';
import { userState, accountState } from '../../store/index';

import {
  CLOUD_PROVIDER_SERVICES,
  TOKEN_TIMING,
  currencies,
} from '../../utils/constants';
import { handleError } from '../../utils/config';
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ALL_VALUE } from '../../pages/Invoice/config';
import axios from 'axios';
import config from '../../config/GlobalConfig';
import SessionExpiryModal from '../Modals/SessionExpiryModal/SessionExpiryModal';
import { accountActions } from '../../store/accountSlice';
interface notificationState {
  children?: ReactNode;
  changeStatus?: any;
}

const statusMapper: any = {
  'In Progress': 'warning',
  Failed: 'error',
  Success: 'success',
  Info: 'info',
};

export const DashboardHeader: React.FC<notificationState> = () => {
  const Routes = RoutesFun();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(userState);
  const user = userData.userData;
  const successMessage = userData.successMessage;
  const errorMessage = userData.errorMessage;
  const selectedAccount = useSelector(accountState).selectedAccount;
  const accountData = useSelector(accountState);
  const location = useLocation();
  const pathname = location.pathname;
  const [networkError, setNetworkError] = React.useState('');
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [sessionExpiryModal, setSessionExpiryModal] = React.useState(false);
  /************************ Render New Notifications State Start ******************************/

  const [newNotification, setNewNotification] = React.useState<any>(0);
  const [renderNewNotification, setRenderNewNotification] = React.useState(
    true
  );
  const [
    newNotificationDetails,
    setNewNotificationDetails,
  ] = React.useState<any>(null);
  const [newNotificationList, setNewNotificationList] = React.useState<any>([]);

  /************************ Render Notifications State Props Start *****************************/

  const [showNotification, setShowNotification] = React.useState(false);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = React.useState(false);
  const [notificationList, setNotificationList] = React.useState([]);
  const [chunkNo, setChunkNo] = React.useState(0);
  const [totalNotifications, setTotalNotifications] = React.useState<any>(0);

  /************************ Render Notifications State Props End ******************************/

  const onLogout = async () => {
    try {
      await userService.logout();
      localStorage.removeItem('auth-state');
      localStorage.removeItem('CompanyName');
      localStorage.removeItem('RoleId');
      localStorage.removeItem('RemainingDays');
      localStorage.setItem('token_expiry_time', '0');
      history.push('/login');
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e.sc === 0) {
        handleError(e, history);
      }
      localStorage.removeItem('CompanyName');
      localStorage.removeItem('auth-state');
      localStorage.removeItem('RoleId');
      localStorage.removeItem('RemainingDays');
      history.push('/login');
    }
  };

  if (user?.IsComplete !== 0 && localStorage.length === 0) {
    localStorage.removeItem('auth-state');
    history.push('/login');
  }

  const closeModal = () => {
    setOpenInviteModal(false);
  };

  const notificationDD = () => {
    setRenderNewNotification(false);
    setShowNotification(true);
    setNewNotification(0);
    localStorage.setItem(
      `VIEWED_NOTIFICATIONS${
        selectedAccount?.AzureId || selectedAccount?.AccountId
      }`,
      totalNotifications
    );
  };

  /************************ Render Notifications Functional Props Start ***********************/

  const countAllNotifications = async () => {
    try {
      const cloudProvider = selectedAccount?.CloudProvider.toUpperCase();
      const LastViewed =
        localStorage.getItem(
          `LAST_VIEWED${
            cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
              ? selectedAccount?.AccountId
              : selectedAccount?.AzureId
          }`
        ) || '';

      if (user) {
        let accountId;
        if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS && selectedAccount) {
          accountId = selectedAccount?.AccountId;
        } else if (
          cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE &&
          selectedAccount
        ) {
          accountId = selectedAccount?.AzureId;
        }
        let result;
        if (selectedAccount) {
          result = await userService.getNotificationDetails(accountId, {
            Offset: 0,
            Limit: 1,
            Date: '',
            LastViewed,
            GetCounts: true,
            CloudProvider: selectedAccount?.CloudProvider.toUpperCase(),
            CustomerId: user?.stripe_customer_id,
          });
        }
        const { notificationCount, newNotificationCount } = result;
        setTotalNotifications(notificationCount);
        setNewNotification(newNotificationCount);
      }
    } catch (e: any) {
      // console.error(e);
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const fetchNotifications = async () => {
    setIsNextPageLoading(true);
    try {
      setChunkNo((prevNum) => prevNum + 1);
      const Offset = 20 * chunkNo;
      const cloudProvider = selectedAccount?.CloudProvider.toUpperCase();

      if (user) {
        let accountId;
        if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
          accountId = selectedAccount?.AccountId;
        } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
          accountId = selectedAccount?.AzureId;
        }

        const result = await userService.getNotificationDetails(accountId, {
          Date: '',
          Offset,
          Limit: 20,
          GetCounts: '',
          CloudProvider: selectedAccount?.CloudProvider.toUpperCase(),
          CustomerId: user?.stripe_customer_id,
        });
        if (!Offset && result?.length) {
          localStorage.setItem(
            `LAST_VIEWED${
              cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                ? selectedAccount?.AccountId
                : selectedAccount?.AzureId
            }`,
            result[0].createdAt
          );
        }
        result &&
          setNotificationList((prevNotifications): any => [
            ...prevNotifications,
            ...result,
          ]);
      }
    } catch (e: any) {
      console.error(e);
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setIsNextPageLoading(false);
    }
  };

  const itemCount = hasNextPage
    ? notificationList.length + 1
    : notificationList.length;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < notificationList.length;
  const loadMoreItems = isNextPageLoading ? () => null : fetchNotifications;
  const getItemSize = (index: number) => {
    const notification: any = notificationList[index];
    const noOfLines = Math.floor(notification?.Description.length / 45);
    if (isNaN(noOfLines)) return 0;
    return notification?.UpdatedBy ? 65 + noOfLines * 20 : 65 + noOfLines * 20;
  };

  const resetNotificationState = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setNotificationList([]);
    setChunkNo(0);
  };

  /************************ Render Notifications Functional Props End ***********************/

  const closeNotificationDD = () => {
    setShowNotification(false);
    resetNotificationState();
  };

  const closeNewNotification = () => {
    setRenderNewNotification(false);
    if (newNotificationList.length) {
      setTimeout(() => {
        setNewNotificationDetails(newNotificationList[0]);
        setNewNotificationList((prevList: any) => {
          return prevList.slice(1);
        });
        setRenderNewNotification(true);
      }, 1000);
    } else {
      setRenderNewNotification(false);
      setNewNotificationDetails(null);
    }
  };

  const closeSuccessMessage = () => {
    dispatch(
      userActions.replaceUserState({
        userData: user,
        isUserSelected: true,
        successMessage: '',
      })
    );
    // setSuccessMessage('');
  };

  const closeErrorMessage = () => {
    dispatch(
      userActions.replaceUserState({
        userData: user,
        isUserSelected: true,
        errorMessage: '',
      })
    );
    // setSuccessMessage('');
  };

  useEffect(() => {
    listener('New Notification', (notification: any) => {
      if (!newNotificationDetails) {
        setNewNotificationDetails(notification);
      } else {
        setNewNotificationList((prevList: any) => {
          return [...prevList, notification];
        });
      }
      closeNotificationDD();
      setRenderNewNotification(true);
      setNewNotification((prevValue: number) => prevValue + 1);
    });
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      selectedAccount.CloudProvider.toUpperCase() === 'AZURE'
        ? emitter('azureId', selectedAccount?.AzureId)
        : selectedAccount.CloudProvider.toUpperCase() === 'AWS'
        ? emitter('azureId', selectedAccount?.AccountId)
        : emitter('azureId', selectedAccount?.AccountId);
    }
    countAllNotifications();
    fetchNotifications();
  }, [selectedAccount?.AzureId, selectedAccount?.AccountId, user]);

  useEffect(() => {
    if (!isNextPageLoading) {
      setHasNextPage(notificationList.length < totalNotifications);
    }
  }, [isNextPageLoading]);

  useEffect(() => {
    resetNotificationState();
  }, [totalNotifications]);

  if (networkError) {
    throw new Error(networkError);
  }

  const RefreshLink = () => {
    const history = useHistory();

    useEffect(() => {
      const unlisten = history.listen(() => {
        if (history.location.pathname === Routes.Dashboard) {
          // window.location.reload();
        }
      });
      return () => {
        unlisten();
      };
    }, [history]);

    return (
      <>
        <Typography variant="h6" className="cLogo d-flex align-center">
          {user?.RoleId === 5 ||
          user?.RoleId === 6 ||
          user?.RoleId === 7 ||
          user?.RoleId === 8 ? (
            <CFLogo />
          ) : user?.RoleId === 1 ? (
            <Link to={Routes.AdminDashboard}>
              <CFLogo />
            </Link>
          ) : (
            <Link to={Routes.Dashboard}>
              <CFLogo />
            </Link>
          )}
          {user?.RoleId === 1 ? (
            <p className="headerName"> | Admin Panel</p>
          ) : user?.RoleId === 5 ? (
            <p className="headerName"> | Document Upload</p>
          ) : (
            <p className="headerName">
              {' '}
              {user?.CompanyName ? `| ${user?.CompanyName}` : ''}
            </p>
          )}
        </Typography>
      </>
    );
  };

  let flag = true;
  const resetTimer = async () => {
    localStorage.setItem('last_activity_time', Date.now().toString());

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const expiryTime: number = +localStorage.getItem('token_expiry_time')!;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (expiryTime - +localStorage.getItem('last_activity_time')! <= 0) {
      localStorage.removeItem('auth-state');
      localStorage.removeItem('CompanyName');
      localStorage.removeItem('RoleId');
      localStorage.removeItem('RemainingDays');
      setSessionExpiryModal(true);
      return;
    }

    if (
      expiryTime &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      expiryTime - +localStorage.getItem('last_activity_time')! <=
        TOKEN_TIMING.REFRESH_TOKEN_API_HITTING_TIME &&
      flag &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      expiryTime - +localStorage.getItem('last_activity_time')! > 0
    ) {
      flag = false;
      const res: any = await axios.get(
        `${config.apiBaseUrl}api/users/refreshToken`,
        {
          headers: {
            Authorization: localStorage.getItem('auth-state'),
          },
        }
      );

      localStorage.setItem(
        'token_expiry_time',
        (Date.now() + TOKEN_TIMING.TOKEN_EXPIRY_TIME).toString()
      );

      localStorage.setItem('auth-state', res.data.result.token.toString());
      setTimeout(() => {
        flag = true;
      }, 1000 * 60);
    }
  };

  document.onmouseover = resetTimer;
  document.onclick = resetTimer;
  document.onscroll = resetTimer;
  document.onkeypress = resetTimer;
  document.onkeyup = resetTimer;

  const handleSelectedCurrency = (event: any) => {
    const currency = event.target.value;
    dispatch(accountActions.replaceDefaultCurrency(currency));
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <AppBar position="static" className="appBar">
          <Toolbar className="toolBar">
            {/* <Typography variant="h6" className="cLogo">
              <Link to={Routes.Dashboard}>
                <CFLogo />
              </Link>
            </Typography> */}
            {RefreshLink()}
            {user?.RoleId == 1 || user?.RoleId === 5 ? (
              ''
            ) : user && user?.SubscriptionStatus === 'trialing' ? (
              <>
                Free Trial |{' '}
                {user?.RemainingDays === 0 ? (
                  <>Expiring Today</>
                ) : user?.RemainingDays === 1 ? (
                  `${user?.RemainingDays} Day Remaining`
                ) : (
                  `${user?.RemainingDays} Days Remaining`
                )}{' '}
              </>
            ) : user && user?.RemainingDays === null ? (
              user?.RoleId === 2 ? (
                <div className="planPeriod mr-20">
                  <Button
                    variant="outlined"
                    style={{ color: 'gold', fontWeight: 700 }}
                    onClick={() => history.push(Routes.Billing)}
                  >
                    Subscribe
                  </Button>
                </div>
              ) : null
            ) : (
              <>
                {user ? user?.PlanType + ' ' + 'plan' + ' | ' : ' '}
                {
                  user?.SubscriptionMode === 'Auto Renewal' ? (
                    <>
                      {user?.SubscriptionStatus === 'incomplete' ||
                      user?.SubscriptionStatus === 'past_due' ? (
                        <div className="d-flex paymentProcessing">
                          <AccessTimeIcon />
                          Payment processing...
                        </div>
                      ) : (
                        <>
                          {
                            user && user?.RemainingDays === 0
                              ? 'Renewing today.'
                              : user && user?.RemainingDays === 1
                              ? 'Renew in ' + user?.RemainingDays + ' day'
                              : 'Renew in ' + user?.RemainingDays + ' days'
                            // : user
                            // ? 'Renew in ' + user?.RemainingDays + ' days'
                            //     : ''
                          }
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {user?.SubscriptionStatus === 'incomplete' ||
                      user?.SubscriptionStatus === 'past_due' ? (
                        <div className="d-flex paymentProcessing">
                          <AccessTimeIcon />
                          Payment processing...
                        </div>
                      ) : (
                        <>
                          {user && user?.RemainingDays === 0
                            ? 'Expiring today'
                            : user?.RemainingDays === 1
                            ? user && user?.RemainingDays + ' day left'
                            : user && user?.RemainingDays + ' days left'}
                        </>
                      )}
                    </>
                  )

                  // : `${user?.RemainingDays} days left`
                }
              </>
            )}
            {pathname === '/trial-plan-confirmation' || user?.RoleId === 5 ? (
              <></>
            ) : (
              user?.RoleId !== 1 && (
                <Tooltip
                  title={
                    accountData.selectedAccountName !== ALL_VALUE ||
                    accountData.selectedSubscriptionName !== ALL_VALUE
                      ? `${
                          accountData.selectedAccountName !== ALL_VALUE &&
                          accountData.selectedSubscriptionName === ALL_VALUE
                            ? accountData.selectedAccountName
                            : ''
                        } ${
                          accountData.selectedAccountName !== ALL_VALUE &&
                          accountData.selectedSubscriptionName !== ALL_VALUE
                            ? accountData.selectedSubscriptionName
                            : ''
                        }`
                      : selectedAccount?.Name
                      ? selectedAccount?.CloudProvider.toUpperCase()
                      : 'Select Account'
                  }
                  arrow
                  className="disableIcon"
                >
                  <div
                    className={
                      'accountDisplayName ' + classes.accountDisplayName
                    }
                    onClick={() => history.push(Routes.AccountDetails)}
                  >
                    {selectedAccount?.Name ? (
                      <>
                        <span className="mr-10">
                          {selectedAccount?.CloudProvider.toUpperCase()}
                        </span>

                        <span>
                          {accountData.selectedAccountName !== ALL_VALUE &&
                            accountData.selectedSubscriptionName ===
                              ALL_VALUE &&
                            accountData.selectedAccountName}

                          {accountData.selectedAccountName !== ALL_VALUE &&
                            accountData.selectedSubscriptionName !==
                              ALL_VALUE &&
                            accountData.selectedSubscriptionName}
                        </span>
                      </>
                    ) : (
                      'Select Account'
                    )}

                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </div>
                </Tooltip>
              )
            )}
            <div className="currencyDropbox">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={selectedCurrency}
                defaultValue={accountData.defaultCurrency}
                renderValue={(selected: any) => (
                  <span className="fw-700 font-16px">
                    {currencies[selected]?.symbol}
                  </span>
                )}
                onChange={handleSelectedCurrency}
              >
                {Object.entries(currencies).map(([key, value]: any) => {
                  return (
                    <MenuItem
                      className="currencySection"
                      key={`currency-${key}`}
                      value={key}
                    >
                      <span className="fw-700 font-16px">{value.symbol}</span>
                      <span>{value.name}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className={classes.userDisplayName + ' dUserName'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-circle mr-10"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
              </svg>
              <span>
                {user
                  ? !user?.FirstName && !user?.LastName
                    ? 'Guest'
                    : `${user?.FirstName} ${user?.LastName}`
                  : null}
              </span>
            </div>
            {user?.RoleId === 5 ? (
              <></>
            ) : (
              user?.RoleId !== 1 && (
                <div className="notification">
                  <Tooltip title="Notification" arrow>
                    <span
                      className={showNotification ? 'nOpen' : ''}
                      onClick={notificationDD}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        className="bi bi-bell-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                      </svg>
                      {newNotification > 0 ? (
                        <>
                          {user ? (
                            <span className="notificationCount">
                              {newNotification > 99 ? '99+' : newNotification}
                            </span>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  </Tooltip>
                  {showNotification && (
                    <Snackbar
                      classes={{
                        anchorOriginTopRight: classes.notificationAnchorTR,
                      }}
                      open={showNotification}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={closeNotificationDD}
                    >
                      <div className="notificationBubble">
                        <div className="notificationList">
                          <div className="notificationHead">
                            <h6>Notifications</h6>
                            <span
                              className="nClose"
                              onClick={closeNotificationDD}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="#333"
                                className="bi bi-x"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </span>
                          </div>
                          <div>
                            {!user ? (
                              <p className="noNotificationFound">
                                No Notification Found
                              </p>
                            ) : totalNotifications === 0 ? (
                              <p className="noNotificationFound">
                                No Notification Found
                              </p>
                            ) : (
                              <Notifications
                                className="notificationBody"
                                totalNotifications={totalNotifications}
                                isItemLoaded={isItemLoaded}
                                notificationList={notificationList}
                                loadMoreItems={loadMoreItems}
                                itemCount={itemCount}
                                getItemSize={getItemSize}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Snackbar>
                  )}
                </div>
              )
            )}
            <div className="userDropDown">
              <FontAwesomeIcon icon={faAngleDown} />
              <div className="userDisplayDD">
                <div
                  className={
                    'accountDisplayName mUserName ' + classes.userDisplayName
                  }
                >
                  <span>
                    {user ? `${user?.FirstName} ${user?.LastName}` : ''}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#0078db"
                    className="bi bi-person-circle mr-10"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg>
                </div>

                <div
                  className={'accountDisplayName ' + classes.accountDisplayName}
                  onClick={() => history.push(Routes.AccountDetails)}
                >
                  <span className="selectedAccountName">Selected Account</span>
                  <span className="font-13px">
                    {'selectedAccount?.Name' || 'Select Account'}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </span>
                </div>
                {user?.RoleId === 1 || user?.RoleId === 5 ? (
                  <ul>
                    <li onClick={onLogout} className="p-15">
                      <span>Logout</span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-power"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.5 1v7h1V1h-1z" />
                          <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                        </svg>
                      </span>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <Link to={Routes.UserProfile}>
                        <span>Edit Profile</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                          </svg>
                        </span>
                      </Link>
                    </li>
                    {user?.RoleId == 3 || user?.RoleId == 4 ? null : (
                      <>
                        <li className="cursor-pointer ">
                          <Link to={Routes.NotificationManagement}>
                            <span>Manage Notifications</span>
                            <span>
                              <NotificationsNoneSharpIcon
                                style={{ fontSize: '22px' }}
                              />
                            </span>
                          </Link>
                        </li>
                        <li className="cursor-pointer ">
                          <Link to={Routes.Billing}>
                            <span>Manage Billings</span>
                            <AttachMoneySharpIcon
                              style={{
                                fontSize: '22px',
                                color: '#0078db',
                                marginRight: '2px',
                              }}
                            />
                          </Link>
                        </li>
                      </>
                    )}
                    {user?.RoleId !== 3 && user?.RoleId !== 4 ? (
                      <li>
                        <Link to={Routes.UsersList}>
                          <span>Users List</span>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              className="bi bi-people"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                            </svg>
                          </span>
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}

                    <li>
                      <Link to={Routes.AccountDetails}>
                        <span>Account</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-hdd-stack"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z" />
                            <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM14 3a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                            <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                          </svg>
                        </span>
                      </Link>
                    </li>
                    {user?.RoleId !== 3 && user?.RoleId !== 4 ? (
                      <li
                        onClick={() => setOpenInviteModal(true)}
                        className="cursor-pointer p-15"
                      >
                        <span>Invite User</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-person-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            <path d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                          </svg>
                        </span>
                      </li>
                    ) : (
                      ''
                    )}
                    {user?.RoleId !== 3 && user?.RoleId !== 4 ? (
                      <li
                        onClick={() => history.push(Routes.RightsizingAndIdle)}
                        className="cursor-pointer p-15"
                      >
                        <span>Rightsizing & Idle Input</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-person-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            <path d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                          </svg>
                        </span>
                      </li>
                    ) : (
                      ''
                    )}
                    <li onClick={onLogout} className="p-15">
                      <span>Logout</span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-power"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.5 1v7h1V1h-1z" />
                          <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                        </svg>
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Grid>

      {successMessage && (
        <Snackbar
          classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(successMessage)}
          autoHideDuration={5000}
          onClose={closeSuccessMessage}
        >
          <Alert
            variant="filled"
            className="successMessage"
            icon={false}
            severity={statusMapper['Success']}
            onClose={closeSuccessMessage}
          >
            <span className="successMessage">{successMessage}</span>
          </Alert>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar
          classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(errorMessage)}
          autoHideDuration={5000}
          onClose={closeErrorMessage}
        >
          <Alert
            variant="filled"
            className="errorMessage"
            icon={false}
            severity={statusMapper['Failed']}
            onClose={closeErrorMessage}
          >
            <span className="successMessage">{errorMessage}</span>
          </Alert>
        </Snackbar>
      )}

      {renderNewNotification &&
        newNotificationDetails &&
        newNotificationDetails?.UpdatedBy === user?.Id && (
          <Snackbar
            classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={renderNewNotification && Boolean(newNotificationDetails)}
            autoHideDuration={5000}
            onClose={closeNewNotification}
          >
            <Alert
              variant="standard"
              severity={statusMapper[newNotificationDetails?.Status]}
              action={
                <IconButton color="inherit" onClick={closeNewNotification}>
                  <HighlightOffIcon fontSize="large" />
                </IconButton>
              }
            >
              <NotificationBody
                details={newNotificationDetails}
                onClick={notificationDD}
              />
            </Alert>
          </Snackbar>
        )}

      {openInviteModal ? (
        <InviteModal show={openInviteModal} onClose={closeModal} user={user} />
      ) : null}

      {sessionExpiryModal && (
        <SessionExpiryModal
          show={sessionExpiryModal}
          onSubmit={() => {
            localStorage.removeItem('auth-state');
            localStorage.removeItem('CompanyName');
            localStorage.removeItem('RoleId');
            localStorage.removeItem('RemainingDays');
            localStorage.setItem('token_expiry_time', '0');
            history.push('/login');
          }}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(DashboardHeader);
