import { io } from 'socket.io-client';
import config from './config/GlobalConfig';

const socket = io(config.apiBaseUrl || '', {
  withCredentials: true,
});


export const emitter = (event: string, payload: any) => {
  socket.emit(event, payload);
};

export const listener = (event: string, callback: any) => {
  console.log('callback:', callback);
  console.log('event:', event);
  socket.on(event, callback);
};






