const PAYLOAD_TYPES = {
  ACCESS_KEYS: 'access_keys',
  POLICY: 'policy',
  PASSWORD: 'password',
  EBS_ATTACHMENTS: 'ebs_attachments',
  VPC: 'vpc',
  EBS_ENCRYPTION: 'ebs_encryption',
  SECURITY_GROUP: 'security_group',
  EC2: 'ec2',
  EC2_TRANSIT_GATEWAY: 'ec2_transit_gateway',
  VPN_TUNNEL: 'vpn_tunnel',
  EBS_SNAPSHOT: 'ebs_snapshot',
  EC2_PORT: 'ec2_port',
  ACL: 'acl',
  AD: 'ad',
  SUBSCRIPTION: 'subscription',
  VM: 'vm',
  PG: 'pg',
  S3_BUCKET: 'S3_Bucket',
  RDS: 'rds',
  DynamoDB: 'DynamoDB',
  ECR: 'ECR',
  SQS: 'SQS',
  SNS: 'SNS',
  ELB: 'ELB',
  EKS: 'eks',
  EMR: 'EMR',
  LAMBDA: 'LAMBDA',
};

export const AZURE_PRACTICES = {
  AZUREPRAC1: 'AZUREPRAC1',
  AZUREPRAC2: 'AZUREPRAC2',
  AZUREPRAC3: 'AZUREPRAC3',
  AZUREPRAC4: 'AZUREPRAC4',
  AZUREPRAC5: 'AZUREPRAC5',
  AZUREPRAC6: 'AZUREPRAC6',
  AZUREPRAC7: 'AZUREPRAC7',
  AZUREPRAC8: 'AZUREPRAC8',
  AZUREPRAC9: 'AZUREPRAC9',
  AZUREPRAC10: 'AZUREPRAC10',
  AZUREPRAC11: 'AZUREPRAC11',
  AZUREPRAC12: 'AZUREPRAC12',
  AZUREPRAC13: 'AZUREPRAC13',
  AZUREPRAC14: 'AZUREPRAC14',
};

export const labels = {
  [PAYLOAD_TYPES.ACCESS_KEYS]: {
    userName: 'User Name',
    userId: 'User ID',
    arn: 'ARN',
    accessKeys: 'Access Key',
  },
  [PAYLOAD_TYPES.POLICY]: {
    policyName: 'Policy Name',
    policyId: 'Policy ID',
    arn: 'ARN',
  },
  [PAYLOAD_TYPES.PASSWORD]: {
    minimumPasswordLength: 'Password Min Length',
    requireSymbols: 'Require Symbols',
    requireNumbers: 'Require Numbers',
    requireUppercaseCharacters: 'Require Uppercase Characters',
    requireLowercaseCharacters: 'Require Lowercase Characters',
  },
  [PAYLOAD_TYPES.EBS_ATTACHMENTS]: {
    SnapshotId: 'Snapshot Id',
    VolumeId: 'Volume Id',
    VolumeType: 'Volume Type',
    MultiAttachEnabled: 'Multi Attach Enabled',
  },
  [PAYLOAD_TYPES.VPC]: {
    VpcId: 'VPC Id',
    State: 'State',
  },
  [PAYLOAD_TYPES.EBS_ENCRYPTION]: {
    ebsEncryptionByDefault: 'Ebs Encryption',
  },
  [PAYLOAD_TYPES.SECURITY_GROUP]: {
    GroupName: 'Group Name',
    GroupId: 'Group Id',
    VpcId: 'VPC Id',
  },
  [PAYLOAD_TYPES.EC2]: {
    InstanceId: 'Instance Id',
    InstanceType: 'Instance Type',
    ImageId: 'Image Id',
  },
  [PAYLOAD_TYPES.EC2_TRANSIT_GATEWAY]: {
    TransitGatewayId: 'Transit Gateway Id',
    TransitGatewayArn: 'Transit Gateway Arn',
  },
  [PAYLOAD_TYPES.VPN_TUNNEL]: {
    VpnConnectionId: 'VPN Connection Id',
    VpnGatewayId: 'VPN Gateway Id',
    CustomerGatewayId: 'Customer Gateway Id',
  },
  [PAYLOAD_TYPES.EBS_SNAPSHOT]: {
    SnapshotId: 'Snapshot Id',
    VolumeId: 'Volume Id',
    VolumeSize: 'Volume Size',
  },
  [PAYLOAD_TYPES.EC2_PORT]: {
    SubnetId: 'Subnet Id',
    VpcId: 'VPC Id',
  },
  [PAYLOAD_TYPES.ACL]: {
    NetworkAclId: 'Network ACL Id',
    VpcId: 'VPC Id',
  },
  [PAYLOAD_TYPES.S3_BUCKET]: {
    Name: 'Name',
    CreationDate: 'Creation Date',
  },
  [PAYLOAD_TYPES.RDS]: {
    DBInstanceIdentifier: 'DB Identifier',
    DBInstanceClass: 'DB Class',
    Engine: 'Database Engine',
    DBInstanceStatus: 'Database Status',
  },
  [PAYLOAD_TYPES.DynamoDB]: {
    TableArn: 'Table Arn',
    TableId: 'Table Id',
    TableName: 'Table Name',
  },
  [PAYLOAD_TYPES.ECR]: {
    registryId: 'Registry Id',
    repositoryArn: 'Repository Arn',
    repositoryName: 'Repository Name',
  },
  [PAYLOAD_TYPES.SQS]: {
    QueueArn: 'Arn',
    ApproximateNumberOfMessages: 'Approx number of messages',
    ApproximateNumberOfMessagesNotVisible:
      'Approx number of messages not visible',
    ApproximateNumberOfMessagesDelayed: 'Approx number of messages delayed',
    CreatedTimestamp: 'Created on',
  },
  [PAYLOAD_TYPES.SNS]: {
    TopicArn: 'Arn',
    DisplayName: 'Name',
    ContentBasedDeduplication: 'Content based deduplication',
  },
  [PAYLOAD_TYPES.ELB]: {
    DNSName: 'DNS Name',
    LoadBalancerArn: 'Arn',
    LoadBalancerName: 'Load Balancer Name',
  },
  [PAYLOAD_TYPES.EKS]: {
    DNSName: 'DNS Name',
    LoadBalancerArn: 'Arn',
    LoadBalancerName: 'Load Balancer Name',
  },
  [PAYLOAD_TYPES.EMR]: {
    Name: 'Name',
    MasterPublicDnsName: 'Master Public Dns Name',
    MasterPublicIP: 'Master Public IP',
  },
  [PAYLOAD_TYPES.LAMBDA]: {
    FunctionName: 'Name',
    FunctionArn: 'Arn',
    Runtime: 'Runtime',
    LastModified: 'Last Modified',
  },
};

export const azureLabels = {
  [AZURE_PRACTICES.AZUREPRAC1]: {
    displayName: 'Display Name',
    userId: 'User ID',
    userPrincipalName: 'User Principal Name',
  },
  [AZURE_PRACTICES.AZUREPRAC2]: {
    name: 'Name',
    type: 'Type',
    location: 'Location',
    vmId: 'VM ID',
  },
  [AZURE_PRACTICES.AZUREPRAC3]: {
    name: 'Name',
    type: 'Type',
    location: 'Location',
    vmId: 'VM ID',
  },

  //TODO: to be configured
  [AZURE_PRACTICES.AZUREPRAC4]: {
    name: 'Name',
    type: 'Type',
    location: 'Location',
    vmId: 'VM ID',
  },
  [AZURE_PRACTICES.AZUREPRAC5]: {
    name: 'Name',
    type: 'Type',
    location: 'Location',
    resourcegroups: 'Resource Groups',
  },
  [AZURE_PRACTICES.AZUREPRAC6]: {
    name: 'Name',
    type: 'Type',
    location: 'Location',
    resourcegroups: 'Resource Groups',
  },
  [AZURE_PRACTICES.AZUREPRAC7]: {
    id: 'ID',
    name: 'Name',
    type: 'Type',
    eTag: 'eTag',
    category: 'Category',
    amount: 'Amount',
  },
  [AZURE_PRACTICES.AZUREPRAC8]: {
    displayName: 'Display Name',
    policyDefinitionId: 'Policy Defination ID',
    scope: 'Scope',
  },
  [AZURE_PRACTICES.AZUREPRAC9]: {
    serverName: 'Server Name',
    congigName: 'Congig Name',
    description: 'Description',
    resourcegroups: 'Resource groups',
    value: 'value',
  },
  [AZURE_PRACTICES.AZUREPRAC10]: {
    serverName: 'Server Name',
    congigName: 'Congig Name',
    type: 'Type',
    description: 'Description',
    resourcegroups: 'Resource groups',
    value: 'value',
  },
  [AZURE_PRACTICES.AZUREPRAC11]: {
    serverName: 'Server Name',
    serverType: 'Server Type',
    version: 'Version',
    geoRedundantBackup: 'Geo Redundant Backup',
    resourcegroups: 'Resource groups',
  },
  [AZURE_PRACTICES.AZUREPRAC12]: {
    serverName: 'Server Name',
    serverType: 'Server Type',
    version: 'Version',
    storageAutogrow: 'Storage Autogrow',
    resourcegroups: 'Resource groups',
  },
  [AZURE_PRACTICES.AZUREPRAC13]: {
    serverName: 'Server Name',
    serverType: 'Server Type',
    version: 'Version',
    sslEnforcement: 'ssl Enforcement',
    resourcegroups: 'Resource groups',
  },
  [AZURE_PRACTICES.AZUREPRAC14]: {
    serverName: 'Server Name',
    serverType: 'Server Type',
    version: 'Version',
    storageAutogrow: 'Storage Autogrow',
    resourcegroups: 'Resource groups',
  },
};

export const formatData = (data: any) => {
  const { practices, result } = data;
  const formattedData = practices.reduce((acc: any, practice: any) => {
    const pracResult = result[practice.Code];
    if (pracResult) {
      if (!acc[practice.Resource]) {
        acc[practice.Resource] = {
          total: 0,
          passed: 0,
          practices: {
            [practice.Code]: {},
          },
        };
      }
      if (pracResult[0].status === 1) {
        acc[practice.Resource].passed += 1;
      }
      acc[practice.Resource].total += 1;
      acc[practice.Resource].practices[practice.Code] = pracResult.reduce(
        (acc: [], result: any) => {
          if (result.payload.length) {
            if (Array.isArray(result.payload)) {
              return acc.concat(
                result.payload.map((entry: any) => ({
                  ...result,
                  payload: entry,
                }))
              );
            }
          } else {
            return acc.concat({
              ...result,
              raw: true,
            });
          }
          return acc.concat(result);
        },
        []
      );
    }
    return acc;
  }, {});
  return formattedData;
};
