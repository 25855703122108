import { ALL_VALUE } from '../../pages/Invoice/config';
import { APIS } from '../../utils/apiConfig';
import { GlobalQueryParams, dataResponse } from '../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { HttpService } from '../HttpService/HttpService';

export class CostForecastingService {
  constructor(private httpService: HttpService) {}

  async getBarAndPieChartData(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/costforecasting/getBarAndPieChartData?'),
        {}
        // '/costforecasting/getBarAndPieChartData',
        // {
        //   params: {
        //     ...(subscriptionId !== 'all'
        //       ? { selectedAccounts: subscriptionId }
        //       : {}),
        //   },
        // }
      );

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getSummaryTableData(ChartType: string, month: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        '/costforecasting/getSpendingSummary?ChartType=' +
          ChartType +
          '&Status=' +
          month
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getDateWiseTotalCostData(
    ChartType: string,
    month: string,
    selectedAccountKey: string,
    selectedAccountValue: string,
    cloud: string,
    selectedCurrency: string
  ): Promise<any> {
    try {
      let response: any;
      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get(
          '/costforecasting/getDateWiseTotalCost?ChartType=' +
            ChartType +
            (selectedCurrency !== 'USD'
              ? '&selectedCurrency=' + selectedCurrency
              : '') +
            '&Status=' +
            month,
          {
            params: {
              ...(selectedAccountValue !== ALL_VALUE
                ? { accountId: selectedAccountValue }
                : {}),
            },
          }
        );
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAccountKey === 'azureId') {
          response = await this.httpService.get(
            '/costforecasting/getDateWiseTotalCost?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&Status=' +
              month,
            {
              params: {
                ...{ azureId: selectedAccountValue },
              },
            }
          );
        } else {
          response = await this.httpService.get(
            '/costforecasting/getDateWiseTotalCost?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&Status=' +
              month,
            {
              params: {
                ...(selectedAccountValue !== ALL_VALUE
                  ? { subscriptionId: selectedAccountValue }
                  : {}),
              },
            }
          );
        }
      }

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getPieMonthToDailyCostData(
    ChartType: string,
    service: string,
    selectedAccountKey: string,
    selectedAccountValue: string,
    cloud: string,
    selectedCurrency: string
  ): Promise<any> {
    try {
      let response: any;
      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get(
          '/costforecasting/getDateWiseTotalCost?ChartType=' +
            ChartType +
            (selectedCurrency !== 'USD'
              ? '&selectedCurrency=' + selectedCurrency
              : '') +
            '&ServiceType=' +
            service,
          {
            params: {
              ...(selectedAccountValue !== ALL_VALUE
                ? { accountId: selectedAccountValue }
                : {}),
            },
          }
        );
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAccountKey === 'azureId') {
          response = await this.httpService.get(
            '/costforecasting/getDateWiseTotalCost?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&ServiceType=' +
              service,
            {
              params: {
                ...{ azureId: selectedAccountValue },
              },
            }
          );
        } else {
          response = await this.httpService.get(
            '/costforecasting/getDateWiseTotalCost?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&ServiceType=' +
              service,
            {
              params: {
                ...(selectedAccountValue !== ALL_VALUE
                  ? { subscriptionId: selectedAccountValue }
                  : {}),
              },
            }
          );
        }
      }

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getSummaryData(
    ChartType: string,
    month: string,
    selectedAccountKey: any,
    selectedAccountValue: any,
    cloud: string,
    selectedCurrency: string
  ): Promise<any> {
    try {
      let response: any;
      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get(
          '/costforecasting/getSpendingSummary?ChartType=' +
            ChartType +
            (selectedCurrency !== 'USD'
              ? '&selectedCurrency=' + selectedCurrency
              : '') +
            '&Status=' +
            month,
          {
            params: {
              ...(selectedAccountValue !== ALL_VALUE
                ? { accountId: selectedAccountValue }
                : {}),
            },
          }
        );
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAccountKey === 'azureId') {
          response = await this.httpService.get(
            '/costforecasting/getSpendingSummary?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&Status=' +
              month,
            {
              params: {
                ...{ azureId: selectedAccountValue },
              },
            }
          );
        } else {
          response = await this.httpService.get(
            '/costforecasting/getSpendingSummary?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&Status=' +
              month,
            {
              params: {
                ...(selectedAccountValue !== ALL_VALUE
                  ? { subscriptionId: selectedAccountValue }
                  : {}),
              },
            }
          );
        }
      }
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getPieData(
    ChartType: string,
    service: string,
    selectedAccountKey: string,
    selectedAccountValue: string,
    cloud: string,
    selectedCurrency: string
  ): Promise<any> {
    try {
      let response: any;
      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get(
          '/costforecasting/getSpendingSummary?ChartType=' +
            ChartType +
            (selectedCurrency !== 'USD'
              ? '&selectedCurrency=' + selectedCurrency
              : '') +
            '&ServiceType=' +
            service,
          {
            params: {
              ...(selectedAccountValue !== ALL_VALUE
                ? { accountId: selectedAccountValue }
                : {}),
            },
          }
        );
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAccountKey === 'azureId') {
          response = await this.httpService.get(
            '/costforecasting/getSpendingSummary?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&ServiceType=' +
              service,
            {
              params: {
                ...{ azureId: selectedAccountValue },
              },
            }
          );
        } else {
          response = await this.httpService.get(
            '/costforecasting/getSpendingSummary?ChartType=' +
              ChartType +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : '') +
              '&ServiceType=' +
              service,
            {
              params: {
                ...(selectedAccountValue !== ALL_VALUE
                  ? { subscriptionId: selectedAccountValue }
                  : {}),
              },
            }
          );
        }
      }
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }

  async getDateWiseData(
    date: any,
    selectedAccountKey: string,
    selectedAccountValue: string,
    cloud: string,
    selectedCurrency: string
  ): Promise<any> {
    try {
      let response;
      if (cloud === CLOUD_PROVIDER_SERVICES.AWS) {
        response = await this.httpService.get(
          '/costforecasting/getDateWiseServicesCost?date=' +
            date +
            (selectedCurrency !== 'USD'
              ? '&selectedCurrency=' + selectedCurrency
              : ''),
          {
            params: {
              ...(selectedAccountValue !== ALL_VALUE
                ? { AccountId: selectedAccountValue }
                : {}),
            },
          }
        );
      } else if (cloud === CLOUD_PROVIDER_SERVICES.AZURE) {
        if (selectedAccountKey === 'azureId') {
          response = await this.httpService.get(
            '/costforecasting/getDateWiseServicesCost?date=' +
              date +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : ''),
            {
              params: {
                ...{ AzureId: selectedAccountValue },
              },
            }
          );
        } else {
          response = await this.httpService.get(
            '/costforecasting/getDateWiseServicesCost?date=' +
              date +
              (selectedCurrency !== 'USD'
                ? '&selectedCurrency=' + selectedCurrency
                : ''),
            {
              params: {
                ...(selectedAccountValue !== ALL_VALUE
                  ? { SubscriptionId: selectedAccountValue }
                  : { SubscriptionId: ALL_VALUE }),
              },
            }
          );
        }
      }

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async setLimit(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/costforecasting/setLimit',
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getLimit(AccountId: string, Company: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        '/costforecasting/getLimit?AccountId=' +
          AccountId +
          '&Company=' +
          Company
      );
      return dataResponse(response);
    } catch (e) {
      throw e;
    }
  }

  async getResourceList(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_RESOURCE_LIST());
      return dataResponse(response);
    } catch (e) {
      throw e;
    }
  }

  async getResourceDetail(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.GET_RESOURCE_DETAIL(),
        params
      );
      return dataResponse(response);
    } catch (e) {
      throw e;
    }
  }

  async setResourceCost(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.SET_RESOURCE_COST(),
        params
      );
      return dataResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async getHighestConsumedResources(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_HIGHEST_CONSUMED_RESOURCES(),
        { params }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
