import React from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    letterSpacing: '0.4px',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '18px 22px',
    maxWidth: '320px',
    fontSize: '1.3rem',
  },
  arrow: {
    color: '#333',
  },
  // Additional styles for custom HTML content inside the tooltip
  title: {
    '& h5': {
      margin: '10px 0 4px',
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#ffeb3b', // Color for the heading
    },
    '& p': {
      margin: '0 0 10px',
      fontSize: '0.875rem',
      color: '#fff', // Paragraph text color
    },
  },
}));

interface CustomTooltipProps extends TooltipProps {
  title: string | { html: string };
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  title,
  ...props
}) => {
  const classes = useStyles();

  const isHTMLContent = typeof title === 'object' && 'html' in title;

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      arrow
      placement={isHTMLContent ? 'right' : 'bottom'}
      {...props}
      title={
        isHTMLContent ? (
          // Render styled HTML content using dangerouslySetInnerHTML
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: (title as { html: string }).html,
            }}
          />
        ) : (
          <span>{title as string}</span>
        )
      }
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
