// import { CsvParamsI } from '../../components/Modals/UptimeMonitoringModal/config';
import {
  FetchMonitoring,
  MonitoringXLSReport,
  NotificationLogsProps,
} from '../../pages/UptimeMonitoring/config';
import { APIS } from '../../utils/apiConfig';
import { GlobalQueryParams } from '../../utils/config';
import { HttpService } from '../HttpService/HttpService';

export class UptimeService {
  constructor(private httpService: HttpService) {}

  async addURL(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.ADD_URL(), params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getURLs(pageNo: number, Type: string): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_URLs(pageNo, Type));
      const { data } = response;
      if (data.sc == 1) {
        return data;
      }
    } catch (e) {
      throw e;
    }
  }
  async deleteURL(params: any): Promise<any> {
    try {
      const response = await this.httpService.delete(APIS.DELETE_URL(), params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async editURL(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(APIS.EDIT_URL(), params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async toggleMonitoring(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.TOGGLE_MONITORING(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async fetchMonitoringDetail(params: FetchMonitoring): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.GET_MONITORING_DETAIL(),
        params
      );

      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async fetchEnvironmentLabels(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ENVIRONMENT_LABELS()
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async uploadCsvFileHandler(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.UPLOAD_URL_CSV(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async downloadCsvTemplate(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.DOWNLOAD_URL_CSV());
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async downloadMonitoringReport(params: MonitoringXLSReport): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.DOWNLOAD_MONITORING_REPORT(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getUrlNotificationLogs(params: NotificationLogsProps): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, APIS.GET_URL_NOTIFICATION_LOGS()),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
