import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
// import { useForm } from 'react-hook-form';
// import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { accountState } from '../../store';
import { ALL_VALUE } from '../Invoice/config';
// import Switch from '@mui/material/Switch';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { config } from './config';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/userSlice';
// import Alert from '@mui/material/Alert';
// import Stack from '@mui/material/Stack';

// interface RightSizingInputInterface {
//   show: boolean;
//   onClose: () => void;
// }

export type RightSizingInput = {
  upscaleThreshold: string;
  downscaleThreshold: string;
  upscaleDays: string;
  downscaleDays: string;
  accountid?: string;
};

const RightsizingAndIdleInput = () => {
  const selectedAccountInfo = useSelector(accountState);
  const dispatch = useDispatch();
  // const { register, handleSubmit, errors } = useForm<RightSizingInput>({
  //   mode: 'onChange',
  // });
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const [inputData, setInputData] = React.useState<RightSizingInput>({
    upscaleDays: '',
    upscaleThreshold: '',
    downscaleDays: '',
    downscaleThreshold: '',
  });
  const cloudProvider = selectedAccountInfo?.selectedAccount?.CloudProvider.toUpperCase();
  const [loader, setLoader] = useState(false);

  const onSubmitRightsizingInputs = async () => {
    try {
      setLoader(true);

      const rightSizingData = await config.setRightsizingdays({
        upscaleThreshold: inputData.upscaleThreshold,
        upscaleDays: inputData.upscaleDays,
        downscaleThreshold: inputData.downscaleThreshold,
        downscaleDays: inputData.downscaleDays,
        ...(cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
          ? { accountid: selectedAccountId }
          : { subscriptionId: selectedSubscriptionId }),
      });

      if (rightSizingData.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: rightSizingData.message,
          })
        );
      }
    } catch (error) {
    } finally {
      getInputFieldsData();
      setLoader(false);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  //   const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setDefaultSettings((preValue) => ({
  //       ...preValue,
  //       [e.target.name]: e.target.checked,
  //     }));
  //   };

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  const getInputFieldsData = async () => {
    try {
      setLoader(true);

      const accountId =
        cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
          ? selectedAccountId
          : selectedSubscriptionId;
      const rightSizingdata = await config.getRightsizingdays(
        accountId,
        cloudProvider!
      );

      if (Object.keys(rightSizingdata).length)
        setInputData((prev) => ({
          ...prev,
          upscaleDays: rightSizingdata.UpscaleDays,
          downscaleDays: rightSizingdata.DownscaleDays,
          upscaleThreshold: rightSizingdata.UpscaleThreshold,
          downscaleThreshold: rightSizingdata.DownscaleThreshold,
        }));
      else {
        setInputData((prev) => ({
          ...prev,
          upscaleDays: '',
          downscaleDays: '',
          upscaleThreshold: '',
          downscaleThreshold: '',
        }));
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getInputFieldsData();
  }, [selectedAccountId, selectedSubscriptionId]);

  return (
    <>
      {loader ? <LoadingScreen /> : null}
      <div className="inventoryHeader mb-15">
        <span className="inventoryHeading font-26px fw-300">
          Rightsizing Recommendations
        </span>

        <div className=" mr-10">
          <SelectedAccount onAccountChange={onAccountChange} />
        </div>
      </div>

      {selectedAccountId === ALL_VALUE ? (
        <div className="noDataText">
          Please select a subscription or accountId
        </div>
      ) : selectedSubscriptionId === ALL_VALUE &&
        cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE ? (
        <div className="noDataText">
          Please select a subscription or accountId
        </div>
      ) : (
        <>
          <Grid item md={12} className="pt-30">
            <div className="flex">
              <div className="w-100 mr-10">
                {' '}
                <TextField
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  // disabled
                  name="upscaleDays"
                  className="w-45 mb-25 mr-100"
                  id="outlined-disabled"
                  label="Upscale Days"
                  value={inputData.upscaleDays}
                  onChange={handleFieldChange}
                  type="text"
                />
                <TextField
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  // disabled
                  name="downscaleDays"
                  className="w-45 mb-25 ml-20"
                  id="outlined-disabled"
                  label="Downscale Days"
                  value={inputData.downscaleDays}
                  onChange={handleFieldChange}
                  type="text"
                />
              </div>
              <div>
                {' '}
                {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleSwitchChange}
                      name="RightSizingDays"
                      checked={defaultSettings.RightSizingDays}
                    />
                  }
                  label="Restore default settings"
                />
              </FormGroup> */}
              </div>
            </div>

            <div className="flex">
              <div className="w-100 mr-10">
                {' '}
                <TextField
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  // disabled
                  name="upscaleThreshold"
                  className="w-45 mb-25 mr-100"
                  id="outlined-disabled"
                  label="Upscale Threshold"
                  value={inputData.upscaleThreshold}
                  onChange={handleFieldChange}
                  type="text"
                />
                <TextField
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                  // disabled
                  name="downscaleThreshold"
                  className="w-45 mb-25 ml-20"
                  id="outlined-disabled"
                  label="Downscale Threshold"
                  value={inputData.downscaleThreshold}
                  onChange={handleFieldChange}
                  type="text"
                />
              </div>
            </div>
            <div className="mt-0 modalFooter flex flex-end">
              <button
                className="btn btn-primary"
                onClick={() => onSubmitRightsizingInputs()}
              >
                Submit
              </button>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default RightsizingAndIdleInput;
