import React from 'react';
import { Skeleton, Box, Card, CardContent } from '@mui/material';

const MetricSkeleton = () => {
  return (
    <Card variant="outlined">
      <CardContent>
        {/* Header - Active Sessions (Now) */}
        <Box>
          <Skeleton variant="text" width={140} height={30} />{' '}
          {/*  "Active Sessions (Now)" */}
        </Box>

        {/* Main Number */}
        <Box height={55}>
          <Skeleton variant="text" width={70} height={60} />{' '}
        </Box>

        {/* Text "24 hours ago" and "0" */}
        <Box>
          <Skeleton variant="text" width={80} height={20} />{' '}
          {/* "24 hours ago" */}
          <Skeleton
            variant="text"
            width={30}
            height={20}
            // style={{ marginTop: '8px' }}
          />{' '}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MetricSkeleton;
