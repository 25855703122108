import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from 'react-modal';
import Checkbox from '@mui/material/Checkbox';
import './BuyNow.css';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { ReactComponent as CrossIcon } from '../../../assets/images/cross-icon.svg';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface alertModal {
  show: boolean;
  onClose?: { (): void };
  metaData: string;
  buyNowFun?: (isAutoRenew: boolean) => void;
  buttonMsg: string;
  headerMsg: string;
  AutoRenewal: boolean;
  BuyNow: boolean;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const BuyNow: React.FC<alertModal> = ({
  show,
  onClose,
  metaData,
  buyNowFun,
  buttonMsg,
  headerMsg,
  AutoRenewal,
  BuyNow,
}) => {
  const [checked, setChecked] = useState<boolean>(AutoRenewal);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">
              {' '}
              {BuyNow ? 'Buy Now' : 'Update Plan'}
            </span>
            <span className="modalClose" onClick={onClose}>
              <CrossIcon width={20} height={20} />
            </span>
          </div>
        </Grid>
        <div style={{ margin: 'auto' }}>
          {BuyNow ? (
            <div className="alertMessage ml-20"> {metaData} </div>
          ) : (
            <div></div>
          )}
          {/* <div className="autoRenewSection">
            <div className="fw-500 p-5 ">Auto Renew Subscription</div>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Off</Typography>
              <AntSwitch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'ant design' }}
              />
              <Typography>On</Typography>
            </Stack>
          </div> */}
        </div>
        <div className="mt-0 modalFooter">
          <button
            type="button"
            className="btn btn-primary ml-10 "
            onClick={() => {
              buyNowFun?.(checked), onClose;
            }}
          >
            {buttonMsg}
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};

export default BuyNow;
