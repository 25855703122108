import {
  customNotificationService,
  slackNotificationManagementService,
  urlMonitoringService,
} from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import { GchatParamsInterface } from '../../utils/interfaces';
export const config: any = {
  updateUserNotifications: (params: any) =>
    customNotificationService.updateUserNotification(params),
  companyNotification: (params: any) =>
    customNotificationService.companyNotificaion(params),
  fetchSlackData: () => slackNotificationManagementService.FetchSlackCreds(),
  fetchSlackChannelOnServices: () =>
    customNotificationService.fetchSlackChannelOnService(),
  setSlackData: (slackCreds: any) =>
    slackNotificationManagementService.setSlackCreds(slackCreds),
  getServiceNotificationStatus: () =>
    customNotificationService.getServiceNotificationStatus(),
  updateSlackCreds: (data: any) =>
    customNotificationService.updateSlackCreds(data),
  uptimeMonitoringAddEmails: (email: any) =>
    customNotificationService.AddEmail(email),
  getUptimeMonitoringNonRegisteredEmails: () =>
    customNotificationService.getNonRegisteredEmail(),
  deleteNonRegisteredEmail: (Email: string) =>
    customNotificationService.deleteNonRegisteredEmail(Email),
  setGchatData: (params: any) =>
    customNotificationService.setGchatCredsData(params),
  getGchatData: () => customNotificationService.getGchatCredsData(),
  updateGchatData: (params: GchatParamsInterface) =>
    customNotificationService.updateGchatCredsData(params),
  deleteGchatData: (id: number) =>
    customNotificationService.deleteGchatCredsData(id),
  setSSLDays: (params: { RecommendedDays: string }) =>
    urlMonitoringService.setSSLDays(params),
  getSSLDays: () => urlMonitoringService.getSSLDays(),
  deleteSSLDays: () => urlMonitoringService.deleteSSLDays(),
};
