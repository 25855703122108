import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { ReactComponent as EditIcon } from '../../../assets/ICONS/material-symbols_edit-outline.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/ICONS/ic_round-delete-outline.svg';
interface Team {
  name: string;
  role: number;
}

export const ROLES = ['', 'Super Admin', 'Admin', 'Employee', 'QA'];
const Teams: React.FC<Team> = ({ name, role }) => {
  const [showDiv, setShowDiv] = useState(false);
  return (
    <div
      className="avatarBox ml-10"
      onMouseEnter={() => setShowDiv(true)}
      onMouseLeave={() => setShowDiv(false)}
    >
      <div>
        <Avatar
          name={name}
          size="50"
          round={true}
          src="https://www.example.com/profile-pic.jpg"
        />
      </div>
      <div className="mt-5 nameBox">
        <div className="nameText">{name}</div>
        <div className="roleText mt-5">{ROLES[role]}</div>
      </div>
      {/* <div>
        {showDiv && (
          <div className="mt-20 editBox">
            <EditIcon
              width={25}
              height={25}
              className="mr-5"
              style={{ cursor: 'pointer' }}
            />
            <DeleteIcon width={25} height={25} style={{ cursor: 'pointer' }} />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Teams;
