import { LoginProps, ForgetPassword } from '../../schemas/user.schema';
import { HttpService } from '../HttpService/HttpService';
import { authService } from '..';
import 'react-toastify/dist/ReactToastify.css';
import {
  RegistrationEmailPasswordProps,
  RegistrationProps,
  ChangePassword,
} from '../../schemas/registration.schema';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { APIS } from '../../utils/apiConfig';
import { dataResponse } from '../../utils/config';

export class UserService {
  constructor(private httpService: HttpService) {}

  async getData(): Promise<any> {
    try {
      await this.httpService.get('/users/create');
    } catch (e) {
      throw e;
    }
  }

  async postEmailPasswordData(
    params: RegistrationEmailPasswordProps
  ): Promise<any> {
    try {
      const response = await this.httpService.post('/users/create', params);
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        authService.removeAuth();
        authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async patchRegistrationData(params: RegistrationProps): Promise<any> {
    try {
      const response = await this.httpService.patch('/users/update', params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async postRequestPricingData(params: RegistrationProps): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/requestpricing/create',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async updatePassword(params: ChangePassword): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/users/changePassword',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async resetPassword(params: ChangePassword): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/users/resetPassword',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async login(params: LoginProps): Promise<any> {
    try {
      const response = await this.httpService.post('/users/login', params);
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        localStorage.removeItem('LOGIN_ACCESS');
        localStorage.setItem(
          'token_expiry_time',
          (Date.now() + result.token_expiry_time).toString()
        );
        authService.removeAuth();
        authService.setAuth(result.token);
        return data.result;
      }
    } catch (e) {
      if (typeof e == 'string' && e.includes('timeout')) {
        const data = { type: 'error', message: 'Request Timeout' };
        return data;
      }
      throw e;
    }
  }

  async forgetPassword(params: ForgetPassword): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/users/forgotPassword',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getALLUserDetails(): Promise<any> {
    try {
      const response = await this.httpService.get('/users/get/allUsers');
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getAllInActiveUserDetails(): Promise<any> {
    try {
      const response = await this.httpService.get('/users/getAllUsers');
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async patchUserDetailData(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        '/users/updateUserStatus',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getUserDetails(): Promise<any> {
    try {
      const response = await this.httpService.get('/users/get');
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getNotificationDetails(sID: any, params: any): Promise<any> {
    try {
      let response;
      if (params?.CloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        const queryString =
          '/notifications/get?' +
          'AccountId' +
          '=' +
          sID +
          '&Offset=' +
          params.Offset +
          '&Limit=' +
          params.Limit +
          '&LastViewed=' +
          params.LastViewed +
          '&GetCounts=' +
          params.GetCounts +
          '&Date=' +
          params.Date +
          '&CustomerId=' +
          params.CustomerId;
        response = await this.httpService.get(queryString);
      } else if (params?.CloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        const queryString =
          '/notifications/get?' +
          'AzureId' +
          '=' +
          sID +
          '&Offset=' +
          params.Offset +
          '&Limit=' +
          params.Limit +
          '&LastViewed=' +
          params.LastViewed +
          '&GetCounts=' +
          params.GetCounts +
          '&Date=' +
          params.Date +
          '&CustomerId=' +
          params.CustomerId;
        response = await this.httpService.get(queryString);
      }

      if (response) {
        const { data } = response;
        if (data.sc == 1) {
          const { result } = data;
          // authService.setAuth(result.token);
          return result;
        }
      }
    } catch (e) {
      throw e;
    }
  }

  async logout(): Promise<any> {
    try {
      const response = await this.httpService.delete('/users/logout', {
        data: 'logout',
      });
      const { data } = response;
      if (data.sc == 1) {
        return data;
      }
    } catch (e) {
      console.log('Errorlogout');
      throw e;
    }
  }

  async inviteUser(params: RegistrationEmailPasswordProps): Promise<any> {
    try {
      const response = await this.httpService.post('/users/invite', params);
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async listCompanies(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_COMPANY_LIST());
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async deleteUser(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.DELETE_USER(), params);
      return dataResponse(response);
    } catch (e) {
      throw e;
    }
  }
}
