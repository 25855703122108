import moment from 'moment';
import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import '../Modal.css';
import { Controller, useForm } from 'react-hook-form';
import { invoiceService } from '../../../services';
import { useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';
import { handleError } from '../../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (prop: any): void };
  mode?: string;
  rowDetails?: any;
  getScheduledInvoices: { (): void };
  accountList: any[];
}

interface SCHEDULEINVOICESCHEMA {
  AccountId: string;
  SubscriptionId: string;
  ReportType: string;
  BillingType: string;
  ScheduleType: string;
  StartDate: string;
  EndDate: string;
  FileFormat: string;
}
interface EDITSCHEDULEINVOICESCHEMA {
  Id: string;
  AccountId: string;
  SubscriptionId: string;
  ReportType: string;
  BillingType: string;
  ScheduleType: string;
  StartDate: string;
  EndDate: string;
  FileFormat: string;
}

export const ScheduleInvoiceModal: React.FC<FixProps> = (props) => {
  const {
    onClose,
    accountList,
    mode,
    rowDetails,
    getScheduledInvoices,
  } = props;
  const [msg, setMsg] = React.useState({
    errMsg: '',
    successMsg: '',
  });

  const history = useHistory();
  const accountData = useSelector(accountState);
  const selectedAccount = accountData.selectedAccount;
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const [customError, setCustomError] = React.useState('');
  const { handleSubmit, control, errors } = useForm<SCHEDULEINVOICESCHEMA>({
    mode: 'onChange',
  });

  const onSubmit = async (data: SCHEDULEINVOICESCHEMA, e: any) => {
    const sReportType = e.target.ReportType.value;
    const sBillingType = e.target.BillingType.value;
    const sScheduleType = e.target.ScheduleType.value;
    const sFileFormat = e.target.FileFormat.value;

    setButtonStatus(true);
    setLoader(true);
    try {
      {
      }
      selectedAccount?.CloudProvider.toUpperCase() ===
      CLOUD_PROVIDER_SERVICES.AWS
        ? (data.AccountId = e.target.AccountId.value)
        : (data.SubscriptionId = e.target.SubscriptionId.value);
      data.ReportType = sReportType;
      data.BillingType = sBillingType;
      data.ScheduleType = sScheduleType;
      data.StartDate = moment(selectedDate).format('YYYY-MM');
      data.EndDate = moment(selectedEndDate).format('YYYY-MM');
      data.FileFormat = sFileFormat;

      const response = await invoiceService.ScheduleInvoice({
        ...data,
      });

      setCustomError('');

      if (response) {
        getScheduledInvoices();
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        e.target.reset();
      }
      setTimeout(() => {
        onClose && onClose(true);
      }, 2000);
    } catch (e: any) {
      setLoader(false);
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
        setCustomError(e.error.message);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 1000);
    }
  };

  const onEditInvoiceSubmit = async (
    data: EDITSCHEDULEINVOICESCHEMA,
    e: any
  ) => {
    const sReportType = e.target.ReportType.value;
    const sBillingType = e.target.BillingType.value;
    const sScheduleType = e.target.ScheduleType.value;
    const sFileFormat = e.target.FileFormat.value;

    setButtonStatus(true);
    setLoader(true);
    try {
      selectedAccount?.CloudProvider.toUpperCase() ===
      CLOUD_PROVIDER_SERVICES.AWS
        ? (data.AccountId = rowDetails.AccountId)
        : (data.SubscriptionId = rowDetails.SubscriptionId);
      data.Id = rowDetails?.Id;
      data.ReportType = sReportType;
      data.BillingType = sBillingType;
      data.ScheduleType = sScheduleType;
      data.StartDate = moment(selectedDate).format('YYYY-MM');
      data.EndDate = moment(selectedEndDate).format('YYYY-MM');
      data.FileFormat = sFileFormat;
      const response = await invoiceService.EditScheduledInvoice({
        ...data,
      });

      setCustomError('');

      if (response) {
        getScheduledInvoices();
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        e.target.reset();
      }
      setTimeout(() => {
        onClose && onClose(true);
      }, 2000);
    } catch (e: any) {
      setLoader(false);
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
        setCustomError(e.error.message);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 1000);
    }
  };

  const RemoveScheduledInvoice = async () => {
    setButtonStatus(true);
    try {
      setLoader(true);
      const result = await invoiceService.RemoveScheduledInvoice({
        Id: rowDetails?.Id,
      });
      if (result) {
        getScheduledInvoices();
        setTimeout(() => {
          onClose && onClose(true);
        }, 2000);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 1000);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }
  const [loader, setLoader] = React.useState(false);
  const [scheduleType, setScheduleType] = React.useState(
    rowDetails?.ScheduleType
  );

  const handleScheduleChange = (e: any) => {
    setScheduleType(e.target.value);
  };

  const [selectedDate, setSelectedDate] = React.useState<any>(
    rowDetails?.StartDate ? moment(rowDetails?.StartDate) : new Date()
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState<any>(
    rowDetails?.EndDate
      ? rowDetails?.EndDate
      : new Date().setMonth(new Date().getMonth() + 1)
  );

  const handleStartDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const handleEndDateChange = (date: any) => {
    setSelectedEndDate(date);
  };
  useEffect(() => {
    if (scheduleType === 'Once') {
      setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
    } else {
      setSelectedEndDate(
        rowDetails?.EndDate
          ? rowDetails?.EndDate
          : new Date().setMonth(new Date().getMonth() + 1)
      );
    }
  }, [scheduleType]);

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
        className="fixModal"
        {...props}
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">
                {mode === 'edit'
                  ? 'Edit Scheduled Invoice'
                  : mode === 'remove'
                  ? 'Remove Scheduled Invoice'
                  : 'Schedule Invoice'}
              </span>
              <span
                className="modalClose"
                onClick={() => {
                  onClose && onClose(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>

          <form
            noValidate
            onSubmit={
              mode === 'edit'
                ? handleSubmit(onEditInvoiceSubmit)
                : mode === 'remove'
                ? handleSubmit(RemoveScheduledInvoice)
                : handleSubmit(onSubmit)
            }
            autoComplete="off"
            className="w-100"
          >
            {mode !== 'remove' ? (
              <Grid item md={12} className="modalBody pt-20">
                {mode !== 'edit' ? (
                  selectedAccount?.CloudProvider.toUpperCase() ===
                  CLOUD_PROVIDER_SERVICES.AZURE ? (
                    <FormControl
                      className={
                        'w-100 vmSelect ' +
                        (accountList.length === 0 ? 'disabled' : '')
                      }
                      variant="outlined"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        Subscription Name
                      </InputLabel>
                      <Controller
                        name="SubscriptionId"
                        control={control}
                        defaultValue={
                          rowDetails
                            ? accountList.find(
                                (account) =>
                                  account.SubscriptionId ===
                                  rowDetails.SubscriptionId
                              ).Name
                            : ''
                        }
                        rules={{ required: true }}
                        render={({ onChange, value, name }) => (
                          <Select
                            label="Subscription Name"
                            onChange={onChange}
                            value={value}
                            name={name}
                          >
                            <MenuItem value="norec" key="h" disabled>
                              No Records Found
                            </MenuItem>
                            {accountList.map((sName: any, i: any) => (
                              <MenuItem key={i} value={sName.SubscriptionId}>
                                {sName.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />

                      {errors.SubscriptionId && (
                        <div className="regMsg mt-5">
                          Subscription Name is Required
                        </div>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl
                      className={
                        'w-100 vmSelect ' +
                        (accountList.length === 0 ? 'disabled' : '')
                      }
                      variant="outlined"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        Select Account
                      </InputLabel>
                      <Controller
                        name="AccountId"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          rowDetails
                            ? accountList.find(
                                (account) =>
                                  account.AccountId === rowDetails.AccountId
                              ).Name
                            : ''
                        }
                        render={({ onChange, value, name }) => (
                          <Select
                            label="Account Name"
                            onChange={onChange}
                            value={value}
                            name={name}
                          >
                            <MenuItem value="norec" key="h" disabled>
                              No Records Found
                            </MenuItem>
                            {accountList.map((sName: any, i: any) => (
                              <MenuItem key={i} value={sName.AccountId}>
                                {sName.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />

                      {errors.AccountId && (
                        <div className="regMsg mt-5">
                          Account Name is Required
                        </div>
                      )}
                    </FormControl>
                  )
                ) : null}

                <FormControl
                  className="w-100 vmSelect mt-20"
                  variant="outlined"
                >
                  <InputLabel id="demo-controlled-open-select-label">
                    Report Format
                  </InputLabel>
                  <Controller
                    name="ReportType"
                    control={control}
                    defaultValue={rowDetails ? rowDetails.ReportType : ''}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                      <Select
                        label="Report Format"
                        onChange={onChange}
                        value={value}
                        name={name}
                      >
                        <MenuItem value="Summary">Summary</MenuItem>
                        <MenuItem value="Detailed">Detailed</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.ReportType && (
                    <div className="regMsg mt-5">Report Format is Required</div>
                  )}
                </FormControl>

                <div className="mt-20 d-flex">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid className="w-90">
                      <DatePicker
                        autoOk={true}
                        className="w-95"
                        inputVariant="outlined"
                        variant="inline"
                        openTo="month"
                        views={['year', 'month']}
                        label="Start Month"
                        value={selectedDate}
                        onChange={handleStartDateChange}
                        // maxDate= {moment(new Date())}
                        minDate={moment(new Date()).subtract(
                          new Date().getDate() - 1,
                          'd'
                        )}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid className="text-right w-100">
                      <DatePicker
                        autoOk={true}
                        readOnly={scheduleType === 'Once'}
                        className={
                          'w-95 ' + (scheduleType === 'Once' ? 'disabled' : '')
                        }
                        inputVariant="outlined"
                        variant="inline"
                        openTo="month"
                        views={['year', 'month']}
                        minDateMessage="End Month must be Greater than Start Month"
                        label="End Month"
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        minDate={
                          scheduleType !== 'Once'
                            ? moment(selectedDate).add(1, 'months')
                            : ''
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>

                <FormControl className="export mt-10">
                  <FormLabel
                    component="legend"
                    className="font-13px fw-500 exportLabel"
                  >
                    Billing Type:
                  </FormLabel>
                  <RadioGroup
                    defaultValue={
                      rowDetails?.BillingType
                        ? rowDetails?.BillingType
                        : 'Monthly'
                    }
                    aria-label="BillingType"
                    name="BillingType"
                    className="exportRadio"
                  >
                    {selectedAccount?.CloudProvider.toUpperCase() ===
                    CLOUD_PROVIDER_SERVICES.AWS ? null : (
                      <FormControlLabel
                        value="Billing Period"
                        control={<Radio color="primary" />}
                        label="Billing Period"
                        className="w-50"
                      />
                    )}
                    <FormControlLabel
                      value="Monthly"
                      control={<Radio color="primary" />}
                      label="Monthly"
                      className="w-50"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl className="export">
                  <FormLabel
                    component="legend"
                    className="font-13px fw-500 exportLabel"
                  >
                    Export Type:
                  </FormLabel>
                  <RadioGroup
                    defaultValue={
                      rowDetails?.FileFormat ? rowDetails?.FileFormat : 'PDF'
                    }
                    aria-label="FileFormat"
                    name="FileFormat"
                    className="exportRadio"
                  >
                    <FormControlLabel
                      value="PDF"
                      control={<Radio color="primary" />}
                      label="PDF"
                      className="w-50"
                    />
                    <FormControlLabel
                      value="XLSX"
                      control={<Radio color="primary" />}
                      label="XLSX"
                      className="w-50"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl className="export">
                  <FormLabel
                    component="legend"
                    className="font-13px fw-500 exportLabel"
                  >
                    Schedule Occurance:
                  </FormLabel>
                  <RadioGroup
                    defaultValue={
                      rowDetails?.ScheduleType
                        ? rowDetails?.ScheduleType
                        : 'Monthly'
                    }
                    onChange={handleScheduleChange}
                    aria-label="ScheduleType"
                    name="ScheduleType"
                    className="exportRadio"
                  >
                    <FormControlLabel
                      value="Monthly"
                      control={<Radio color="primary" />}
                      label="Monthly"
                      className="w-50"
                    />
                    <FormControlLabel
                      value="Once"
                      control={<Radio color="primary" />}
                      label="Once"
                      className="w-50"
                    />
                  </RadioGroup>
                </FormControl>

                {customError && (
                  <div className="alert-danger p-2 px-5 mt-10">
                    {customError}
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item md={12} className="modalBody pt-20">
                <h6>
                  <span className="fw-400">
                    Do you really want to remove
                    <b>&nbsp;{rowDetails.InvoiceName}</b>
                  </span>
                  <span className="fw-400">
                    &nbsp; from Scheduled Invoice ?
                  </span>
                </h6>
              </Grid>
            )}

            <div className="mt-0 modalFooter">
              <button
                type="submit"
                className="btn btn-primary ml-10"
                disabled={
                  buttonStatus ||
                  selectedDate > selectedEndDate ||
                  accountList.length === 0
                }
              >
                {mode === 'edit'
                  ? 'EDIT INVOICE'
                  : mode === 'remove'
                  ? 'REMOVE'
                  : 'SCHEDULE'}
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => {
                  onClose && onClose(false);
                }}
              >
                CANCEL
              </button>
            </div>
          </form>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
