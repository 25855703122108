import moment from 'moment';
import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import '../Modal.css';
import { Controller, useForm } from 'react-hook-form';
import { accountService, rightSizingService } from '../../../services';
import { useHistory } from 'react-router-dom';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';

import AdapterDateFns from '@date-io/date-fns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import SnoozeIcon from '@material-ui/icons/Snooze';
import AlarmIcon from '@material-ui/icons/AddAlarm';

import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';
import config from './config';
import { JWT } from '../../../utils/constants';
import { handleError } from '../../../utils/config';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  scheduleVM: {
    InstanceName: string;
    VMName: string;
    VMIdleEndTime: string;
    VMIdleStartTime: string;
    Id: number;
  };
  customSchedule: any;
  customModal: boolean;
  onClose?: { (prop: any): void };
  user?: any;
}

interface SCHEDULETIMESCHEMA {
  Id: number;
  VMName: string;
  ScheduledStartTime: string;
  ScheduledEndTime: string;
}

interface CUSTOMSCHEDULESCHEMA {
  VMName: string;
  AccountId: string;
  InstanceName: string;
  InstanceId: string;
  ResourceGroup: string;
  SubscriptionId: string;
  StartTime: string;
  EndTime: string;
  Repeat: boolean;
  Type?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

export const ScheduleVMModal: React.FC<FixProps> = (props) => {
  const classes = useStyles();
  const {
    children,
    show = false,
    onClose,
    scheduleVM,
    customSchedule,
    customModal,
    user,
  } = props;

  const selectedAccount = useSelector(accountState).selectedAccount;

  const cloudProvider =
    selectedAccount?.CloudProvider.toUpperCase() || 'default';
  const {
    VMName,
    InstanceName,
    VMIdleEndTime,
    VMIdleStartTime,
    Id,
  } = scheduleVM;

  const [msg, setMsg] = React.useState({
    errMsg: '',
    successMsg: '',
  });

  const history = useHistory();
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedVM, setSelectedVM] = React.useState('');
  const [repeatCheck, setRepeatCheck] = React.useState(false);
  const [repeatValue, setRepeatValue] = React.useState('Monthly');
  const [loader, setLoader] = React.useState(false);
  const [customError, setCustomError] = React.useState('');
  const [sVMError, setsVMError] = React.useState('');
  const [startTimeError, setStartTimeError] = React.useState(false);
  const [endTimeError, setEndTimeError] = React.useState(false);
  const [startDate, setStartDate] = React.useState<any>(
    new Date().getTime() + 180000
  );
  const [endDate, setEndDate] = React.useState<any>(
    new Date().getTime() + 86400000 - 120000
  );

  const [scheduleTimer, setScheduleTimer] = React.useState<any>('');

  useEffect(() => {
    if (customModal) {
      const timer = setInterval(() => {
        if (startDate - new Date().getTime() < 120000) {
          setStartTimeError(true);
        }
      }, 1000);
      setScheduleTimer(timer);
    }
    return clearInterval(scheduleTimer);
  }, [startDate]);

  const [clearedDate, handleClearedDateChange] = React.useState(null);
  const [selectedDate, handleDateChange] = React.useState<Date | null>(
    new Date()
  );

  let actualStartTime = new Date(VMIdleStartTime).getTime();
  let actualEndTime = new Date(VMIdleEndTime).getTime();
  const currentTime = new Date().getTime();
  const VMstartTime = new Date(VMIdleStartTime).getTime();
  if (currentTime > VMstartTime) {
    const startTimeHour = VMIdleStartTime.split('.')[0].split('T')[1];
    const startTimeDate = moment(currentTime).utc().format('YYYY-MM-DD');
    const endTimeHour = VMIdleEndTime.split('.')[0].split('T')[1];
    const endTimeDate = moment(currentTime)
      .add(1, 'day')
      .utc()
      .format('YYYY-MM-DD');
    actualStartTime = new Date(
      startTimeDate + 'T' + startTimeHour + '.000Z'
    ).getTime();
    actualEndTime = new Date(
      endTimeDate + 'T' + endTimeHour + '.000Z'
    ).getTime();
  }
  const [startTime, setStartTime] = React.useState(actualStartTime);
  const [endTime, setEndTime] = React.useState(actualEndTime);

  const dateValue = (e: any) => {
    // handleDateChange(e.value);
  };
  const { handleSubmit } = useForm<SCHEDULETIMESCHEMA>({
    mode: 'onChange',
  });
  const {
    handleSubmit: handleSubmitC,
    errors,
    control,
  } = useForm<CUSTOMSCHEDULESCHEMA>({
    mode: 'onChange',
  });

  const onSubmit = async (data: SCHEDULETIMESCHEMA, e: any) => {
    setMsg({ ['errMsg']: '', ['successMsg']: '' });
    const actualStartTime24hr = moment
      .utc(VMIdleStartTime.split('.')[0].split('T').join(' '))
      .local()
      .format('HH:mm')
      .split(':');
    const actualEndTime24hr = moment
      .utc(VMIdleEndTime.split('.')[0].split('T').join(' '))
      .local()
      .format('HH:mm')
      .split(':');

    const changedStartTime12hr = moment(startTime).format('hh:mm A');
    const changedStartTime24hr = moment(startTime).format('HH:mm').split(':');

    const changedEndTime24hr = moment(endTime).format('HH:mm').split(':');

    if (
      changedEndTime24hr[0] < changedStartTime24hr[0] ||
      (changedEndTime24hr[0] == changedStartTime24hr[0] &&
        changedEndTime24hr[1] < changedStartTime24hr[1])
    ) {
      setsVMError(
        'End Time can not be prior to ' + changedStartTime12hr + ' time'
      );
      return;
    }

    if (
      actualStartTime24hr[0] > changedEndTime24hr[0] ||
      (actualStartTime24hr[0] == changedEndTime24hr[0] &&
        actualStartTime24hr[1] > changedEndTime24hr[1])
    ) {
      setsVMError(
        'End Time can not be priorz ' +
          moment
            .utc(VMIdleStartTime.split('.')[0].split('T').join(' '))
            .local()
            .format('hh:mm A') +
          ' time'
      );
      return;
    }

    if (
      actualStartTime24hr[0] > changedStartTime24hr[0] ||
      (actualStartTime24hr[0] == changedStartTime24hr[0] &&
        actualStartTime24hr[1] > changedStartTime24hr[1])
    ) {
      setsVMError(
        'Start Time can not be prior ' +
          moment
            .utc(VMIdleStartTime.split('.')[0].split('T').join(' '))
            .local()
            .format('hh:mm A') +
          ' time'
      );
      return;
    }

    if (
      actualEndTime24hr[0] < changedEndTime24hr[0] ||
      (actualEndTime24hr[0] == changedEndTime24hr[0] &&
        actualEndTime24hr[1] < changedEndTime24hr[1])
    ) {
      setsVMError(
        'End Time can not be post ' +
          moment
            .utc(VMIdleEndTime.split('.')[0].split('T').join(' '))
            .local()
            .format(' hh:mm A') +
          ' time'
      );
      return;
    }

    if (
      actualEndTime24hr[0] < changedStartTime24hr[0] ||
      (actualEndTime24hr[0] == changedStartTime24hr[0] &&
        actualEndTime24hr[1] < changedStartTime24hr[1])
    ) {
      setsVMError(
        'Start Time can not be post to ' +
          moment
            .utc(VMIdleEndTime.split('.')[0].split('T').join(' '))
            .local()
            .format(' hh:mm a') +
          ' time'
      );
      return;
    }

    setsVMError('');
    setLoader(true);
    try {
      data.Id = Id;

      data.ScheduledStartTime = moment(startTime)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      data.ScheduledEndTime = moment(endTime)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      const response = await rightSizingService.ScheduleVM({
        ...data,
      });

      if (response) {
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        e.target.reset();
      }
      setTimeout(() => {
        onClose && onClose(true);
      }, 1000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 500);
    }
  };

  const onCustomSubmit = async (data: CUSTOMSCHEDULESCHEMA, e: any) => {
    clearInterval(scheduleTimer);
    const sDateTime = moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss');
    const eDateTime = moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss');

    const sTimeCheck = moment(startDate).format('YYYY-MM-DDTHH:mm');
    const eTimeCheck = moment(endDate).format('YYYY-MM-DDTHH:mm');

    if (sTimeCheck.split('T')[0] === eTimeCheck.split('T')[0]) {
      const sTime = sTimeCheck.split('T')[1].split(':').join('');
      const eTime = eTimeCheck.split('T')[1].split(':').join('');
      if (+eTime - +sTime <= 300) {
        setCustomError(
          " 'End Time' should be 3 hours greater than 'Start Time'."
        );
        return false;
      }
    }
    if (sTimeCheck.split('T')[0] === moment().format('YYYY-MM-DD')) {
      if (
        sTimeCheck.split('T')[1].split(':').join('') < moment().format('HHmm')
      ) {
        setStartTimeError(true);
        return false;
      }
    }

    setLoader(true);
    try {
      if (selectedAccount?.CloudProvider.toUpperCase() === 'AWS') {
        const selectedVm = e.target.VMName.value.split(' ');
        data.AccountId = selectedVm[0];
        data.InstanceId = selectedVm[1];
        const InstanceName = selectedVm.slice(2).join(' ');
        data.InstanceName = InstanceName;
        data.VMName = '';
      } else if (selectedAccount?.CloudProvider.toUpperCase() === 'AZURE') {
        data.SubscriptionId = e.target.VMName.value.split(' ')[2];
        data.VMName = e.target.VMName.value.split(' ')[0];
        data.ResourceGroup = e.target.VMName.value.split(' ')[1];
      }

      data.StartTime = sDateTime;
      data.EndTime = eDateTime;
      data.Repeat = e.target.Repeat.checked;
      if (data.Repeat) {
        data.Type = e.target.Type.value;
      }

      const response = await rightSizingService.CustomScheduleVM({
        ...data,
      });
      // const response = { message: 'hello' };

      if (response) {
        setCustomError('');
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        e.target.reset();
      }
      setTimeout(() => {
        onClose && onClose(true);
      }, 1000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        setCustomError(err);
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setLoader(false);
      }, 500);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  const handleRepeatValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatValue((event.target as HTMLInputElement).value);
  };

  const handleVMChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedVM(event.target.value as string);
    setCustomError('');
  };

  const dateChange2 = (event: any) => {
    setStartDate(event.getTime());

    setRepeatValue('Monthly');
    setRepeatCheck(false);
    if (event.getTime() < new Date().getTime() + 120000) {
      setStartTimeError(true);
    } else {
      setStartTimeError(false);
    }
    setCustomError('');
  };
  const endDateChange = (event: any) => {
    // // setEndDate(new Date(event.target.value).getTime());
    // setRepeatValue('Monthly');
    // setRepeatCheck(false);
    // setCustomError('');
  };
  const endDateChange2 = (event: any) => {
    setEndDate(event.getTime());
    setRepeatValue('Monthly');
    setRepeatCheck(false);
    setCustomError('');
  };

  const handleStartTime = (event: any) => {
    setStartTime(event.getTime());
  };
  const handleEndTime = (event: any) => {
    setEndTime(event.getTime());
  };
  useEffect(() => {
    if (endDate < startDate) {
      setEndTimeError(true);
    } else {
      setEndTimeError(false);
    }
  }, [endDate, startDate]);

  const handleRepeatCheckbox = (e: any) => {
    setRepeatCheck(e.target.checked);
    setRepeatValue('Monthly');
  };

  return (
    <React.Fragment>
      {loader ? <LoadingScreen /> : ''}
      <Modal
        isOpen={true}
        onRequestClose={() => {
          clearInterval(scheduleTimer);
          onClose && onClose(false);
        }}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
        className="fixModal"
        {...props}
      >
        {customModal === true ? (
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12} className="modalHeader">
              <div>
                <span className="modalTitle">CUSTOM SCHEDULE</span>
                <span
                  className="modalClose"
                  onClick={() => {
                    clearInterval(scheduleTimer);
                    onClose && onClose(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </span>
              </div>
            </Grid>
            <form
              noValidate
              onSubmit={handleSubmitC(onCustomSubmit)}
              autoComplete="off"
              className="w-100"
            >
              <Grid item md={12} className="modalBody pt-10">
                <Grid container spacing={2} className="mt-0 customVMschedule">
                  <Grid item md={12} sm={12} xs={12} className="">
                    <FormControl
                      className={
                        'w-100 vmSelect ' +
                        (customSchedule.length === 0 ? 'disabled' : '')
                      }
                      variant="outlined"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        {selectedAccount?.CloudProvider.toUpperCase() ===
                        'AZURE'
                          ? 'Virtual Machine (Resource Group)'
                          : 'Instance Name (Instance Id)'}
                      </InputLabel>
                      <Controller
                        name="VMName"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                          customSchedule.length === 0 ? 'norec' : ''
                        }
                        render={({ onChange, value, name }) => (
                          <Select
                            label="Virtual Machine (Resource Group)"
                            readOnly={customSchedule.length === 0}
                            onChange={onChange}
                            name={name}
                            value={value}
                          >
                            <MenuItem value="norec" key="h" disabled>
                              No Records Found
                            </MenuItem>
                            {customSchedule.map((VMData: any, i: any) => (
                              <MenuItem
                                value={
                                  selectedAccount?.CloudProvider.toUpperCase() ===
                                  'AZURE'
                                    ? VMData.VMName +
                                      ' ' +
                                      VMData.ResourceGroup +
                                      ' ' +
                                      VMData.SubscriptionId
                                    : VMData.AccountId +
                                      ' ' +
                                      VMData.InstanceId +
                                      ' ' +
                                      VMData.InstanceName
                                }
                                key={i}
                              >
                                {config[
                                  cloudProvider as keyof typeof config
                                ].getCSDropdownLable(VMData)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />

                      {errors.VMName && (
                        <div className="regMsg mt-5">
                          Virtual Machine (Resource Group) is Required
                        </div>
                      )}
                    </FormControl>

                    <div className="cDateTime">
                      <MuiPickersUtilsProvider utils={AdapterDateFns}>
                        <DateTimePicker
                          TextFieldComponent={(props) => (
                            <TextField {...props} variant="outlined" />
                          )}
                          label="Start Date Time"
                          value={startDate}
                          format="dd/MM/yyyy, hh:mm a"
                          onChange={dateChange2}
                          minDate={moment(new Date())
                            .add(120, 'seconds')
                            .format('YYYY-MM-DD')}
                          helperText={
                            startTimeError
                              ? 'Start Time must be 2 minutes more than the present Time'
                              : ''
                          }

                          // minTime={'03:00'}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={AdapterDateFns}>
                        <DateTimePicker
                          // renderInput={(props) => <TextField {...props} />}

                          TextFieldComponent={(props) => (
                            <TextField {...props} variant="outlined" />
                          )}
                          label="End Date Time"
                          value={endDate}
                          format="dd/MM/yyyy, hh:mm a"
                          onChange={endDateChange2}
                          minDate={moment(new Date()).format('YYYY-MM-DD')}
                          helperText={
                            endTimeError
                              ? 'End Time must be greater than Start Time'
                              : ''
                          }

                          // minTime={'03:00'}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div className="cDate">
                      <span
                        className={
                          (endDate - startDate) / 86400000 >= 31 ||
                          (endDate - startDate) / 86400000 > 30.9992
                            ? 'cDateLabel disabled'
                            : 'cDateLabel'
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              className="px-5 py-15 "
                              name="Repeat"
                              checked={repeatCheck}
                              onChange={(e: any) => handleRepeatCheckbox(e)}
                              inputProps={{
                                disabled:
                                  (endDate - startDate) / 86400000 >= 31 ||
                                  (endDate - startDate) / 86400000 > 30.9992,
                              }}
                            />
                          }
                          label="Repeat"
                        />
                      </span>
                      {repeatCheck === true ? (
                        <div className="cDateInput px-5">
                          <RadioGroup
                            row
                            aria-label="Type"
                            name="Type"
                            value={repeatValue}
                            className="w-100"
                            onChange={handleRepeatValueChange}
                          >
                            <FormControlLabel
                              value="Daily"
                              control={
                                <Radio
                                  color="primary"
                                  inputProps={{
                                    disabled:
                                      (endDate - startDate) / 86400000 >= 1 ||
                                      (endDate - startDate) / 86400000 > 0.9992,
                                  }}
                                />
                              }
                              label="Daily"
                              // disabled={((endDate -startDate) /86400000) > 1}
                              className={
                                (endDate - startDate) / 86400000 >= 1 ||
                                (endDate - startDate) / 86400000 > 0.9992
                                  ? 'disabled'
                                  : ''
                              }
                            />
                            <FormControlLabel
                              value="Weekly"
                              control={
                                <Radio
                                  color="primary"
                                  inputProps={{
                                    disabled:
                                      (endDate - startDate) / 86400000 >= 7 ||
                                      (endDate - startDate) / 86400000 > 6.9992,
                                  }}
                                />
                              }
                              label="Weekly"
                              // disabled={((endDate -startDate) /86400000) > 7}
                              className={
                                (endDate - startDate) / 86400000 >= 7 ||
                                (endDate - startDate) / 86400000 > 6.9992
                                  ? 'disabled'
                                  : ''
                              }
                            />
                            <FormControlLabel
                              value="BiWeekly"
                              control={
                                <Radio
                                  color="primary"
                                  inputProps={{
                                    disabled:
                                      (endDate - startDate) / 86400000 >= 14 ||
                                      (endDate - startDate) / 86400000 >
                                        13.9992,
                                  }}
                                />
                              }
                              label="Bi-Weekly"
                              // disabled={((endDate -startDate) /86400000) > 14}
                              className={
                                (endDate - startDate) / 86400000 >= 14 ||
                                (endDate - startDate) / 86400000 > 13.9992
                                  ? 'disabled'
                                  : ''
                              }
                            />
                            <FormControlLabel
                              value="Monthly"
                              control={<Radio color="primary" />}
                              label="Monthly"
                            />
                          </RadioGroup>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>
                </Grid>
                {customError && (
                  <div className="alert-danger p-2 px-5 mt-10">
                    {customError}
                  </div>
                )}
              </Grid>
              <div className="mt-0 modalFooter">
                <button
                  type="submit"
                  className="btn btn-primary ml-10"
                  disabled={
                    buttonStatus ||
                    startTimeError ||
                    endTimeError ||
                    customSchedule.length === 0
                  }
                >
                  SCHEDULE
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    clearInterval(scheduleTimer);
                    onClose && onClose(false);
                  }}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </Grid>
        ) : (
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12} className="modalHeader">
              <div>
                <span className="modalTitle">
                  {selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
                    ? 'SCHEDULE VM'
                    : 'SCHEDULE INSTANCE'}
                </span>
                <span
                  className="modalClose"
                  onClick={() => {
                    onClose && onClose(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </span>
              </div>
            </Grid>
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="w-100"
            >
              <Grid item md={12} className="modalBody pt-20">
                <h6>
                  <span className="fw-400">
                    {selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
                      ? 'VM Name : '
                      : 'Instance Name : '}
                  </span>
                  {VMName && <b>({VMName})</b>}
                  {InstanceName && <b>({InstanceName})</b>}
                </h6>

                <Grid container spacing={2} className="mt-20">
                  <Grid item md={6} sm={6} xs={6} className="w-100">
                    <MuiPickersUtilsProvider utils={AdapterDateFns}>
                      <TimePicker
                        // renderInput={(props) => <TextField {...props} />}

                        TextFieldComponent={(props: any) => (
                          <TextField {...props} variant="outlined" />
                        )}
                        label="Start Time"
                        value={startTime}
                        format="hh:mm a"
                        onChange={handleStartTime}
                        className={
                          classes.textField + ' font-15px m-0 w-100 inputTime'
                        }
                        // minDate={ moment(new Date()).format('YYYY-MM-DD')}

                        // minTime={'03:00'}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={6} sm={6} xs={6} className="w-100">
                    <MuiPickersUtilsProvider utils={AdapterDateFns}>
                      <TimePicker
                        // renderInput={(props) => <TextField {...props} />}

                        TextFieldComponent={(props: any) => (
                          <TextField {...props} variant="outlined" />
                        )}
                        label="End Time"
                        value={endTime}
                        className={
                          classes.textField + ' font-15px m-0 w-100 inputTime'
                        }
                        format="hh:mm a"
                        onChange={handleEndTime}

                        // minDate={ moment(new Date()).format('YYYY-MM-DD')}

                        // minTime={'03:00'}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="mt-20 d-none">
                  <Grid item md={6} sm={6} xs={6} className="">
                    <TextField
                      id="time"
                      label="Start Time"
                      type="time"
                      variant="outlined"
                      name="VMIdleStartTime"
                      // defaultValue={moment(VMIdleStartTime.split('.')[0].split('T').join(' ')).local().format('YYYY-MM-DD HH:mm').split(' ')[1]}
                      defaultValue={
                        moment
                          .utc(
                            VMIdleStartTime.split('.')[0].split('T').join(' ')
                          )
                          .local()
                          .format('YYYY-MM-DD HH:mm')
                          .split(' ')[1]
                      }
                      className={
                        classes.textField + ' font-15px m-0 w-100 inputTime'
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 60,
                        min: VMIdleStartTime.split(' ')[0],
                        max: VMIdleEndTime.split(' ')[0],
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} className="">
                    <TextField
                      id="time"
                      label="End Time"
                      type="time"
                      variant="outlined"
                      name="VMIdleEndTime"
                      // defaultValue={moment(VMIdleEndTime.split('.')[0].split('T').join(' ')).local().format('YYYY-MM-DD HH:mm').split(' ')[1]}
                      defaultValue={
                        moment
                          .utc(VMIdleEndTime.split('.')[0].split('T').join(' '))
                          .local()
                          .format('YYYY-MM-DD HH:mm')
                          .split(' ')[1]
                      }
                      className={
                        classes.textField + ' font-15px m-0 w-100 inputTime'
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 60,
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </Grid>
                </Grid>

                {sVMError && (
                  <div className="alert-danger p-2 px-5 mt-10">{sVMError}</div>
                )}
                {msg.errMsg && (
                  <div className="alert-danger p-2 px-5 mt-10">
                    {msg.errMsg}
                  </div>
                )}
              </Grid>
              <div className="mt-10 modalFooter">
                <button
                  type="submit"
                  className="btn btn-primary ml-10"
                  disabled={buttonStatus}
                >
                  SCHEDULE
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    onClose && onClose(false);
                  }}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </Grid>
        )}
      </Modal>
    </React.Fragment>
  );
};
