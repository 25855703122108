import { CSSProperties, memo, useCallback, useEffect, useRef } from 'react';
import { URLData } from '../../pages/UptimeMonitoring/DetailMonitoring';
import * as echarts from 'echarts';
import { ECharts, XAXisComponentOption, YAXisComponentOption } from 'echarts';

interface EChartsGrid {
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
  top?: string | number;
  height?: string | number;
  containLabel?: boolean;
}

interface MonitoringGraphInterface {
  urlData: URLData;
  grid: EChartsGrid;
  style: CSSProperties;
}

const MonitoringGraph: React.FC<MonitoringGraphInterface> = ({
  urlData,
  grid,
  style,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<ECharts | null>(null);
  // Initialize ECharts instance

  const initializeChart = useCallback(() => {
    if (chartRef.current && !chartInstanceRef.current) {
      chartInstanceRef.current = echarts.init(chartRef.current);
    }
  }, [urlData]);

  const disposeChart = useCallback(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.dispose();
      chartInstanceRef.current = null;
    }
  }, [urlData]);

  useEffect(() => {
    initializeChart();
    const sortedData: any[] = Object.values(urlData)
      ?.flat()
      .sort((a: any, b: any) => {
        const [datePart1, timePart1] = a?.DateTime.split(' ');
        const [datePart2, timePart2] = b?.DateTime.split(' ');

        const [day1, month1, year1] = datePart1.split('-').map(Number);
        const [day2, month2, year2] = datePart2.split('-').map(Number);

        const [hours1, minutes1] = timePart1.split(':').map(Number);
        const [hours2, minutes2] = timePart2.split(':').map(Number);

        const secondDate = new Date(year2, month2 - 1, day2, hours2, minutes2);
        const firstDate = new Date(year1, month1 - 1, day1, hours1, minutes1);

        const dateA = firstDate.getTime();
        const dateB = secondDate.getTime();

        return dateA - dateB;
      });

    const responseTimeData = sortedData.map((item: any) => item.ResponseTime);

    const xAxisData = sortedData.map((item: any) => `${item.DateTime}`);

    // Determine downtimeIntervals
    const downtimeIntervals: any = [];
    let downtimeStart: any = null;
    sortedData.forEach((item, index) => {
      if (
        item.Value === false &&
        item.Reason !== 'Maintenance' &&
        downtimeStart === null
      ) {
        downtimeStart = item.DateTime;
      } else if (
        (item.Value === true || item.Reason === 'Maintenance') &&
        downtimeStart !== null
      ) {
        downtimeIntervals.push({
          start: downtimeStart,
          end: sortedData[index - 1].DateTime,
          reason: 'Down',
        });
        downtimeStart = null;
      }
    });
    if (downtimeStart !== null) {
      downtimeIntervals.push({
        start: downtimeStart,
        end: sortedData[sortedData.length - 1].DateTime,
        reason: 'Down',
      });
    }

    // Determine monitoringIntervals
    const monitoringIntervals: any = [];
    let monitoringStart: any = null;
    sortedData.forEach((item, index) => {
      if (item.Reason === 'Maintenance' && monitoringStart === null) {
        monitoringStart = item.DateTime;
      } else if (
        (item.Reason !== 'Maintenance' || index === sortedData.length - 1) &&
        monitoringStart !== null
      ) {
        monitoringIntervals.push({
          start: monitoringStart,
          end: sortedData[index].DateTime,
          reason: 'Under Maintenance',
        });
        monitoringStart = null;
      }
    });
    if (monitoringStart !== null) {
      monitoringIntervals.push({
        start: monitoringStart,
        end: sortedData[sortedData.length]?.DateTime,
        reason: 'Under Maintenance',
      });
    }

    // Update responseTimeData to include null values during intervals
    downtimeIntervals.forEach((interval: any) => {
      for (let i = 0; i < xAxisData.length; i++) {
        if (xAxisData[i] >= interval.start && xAxisData[i] <= interval.end) {
          responseTimeData[i] = null;
        }
      }
    });

    monitoringIntervals.forEach((interval: any) => {
      for (let i = 0; i < xAxisData.length; i++) {
        if (xAxisData[i] >= interval.start && xAxisData[i] <= interval.end) {
          responseTimeData[i] = null;
        }
      }
    });

    // Define options for the chart
    const options: any = {
      grid: grid,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: true,
        },

        // Enable the scrollable option for the tooltip
        confine: true,
        enterable: true,
        formatter: function (params: any) {
          if (params.length === 0) {
            return;
          }

          const param = params[0];

          const intervalData = downtimeIntervals
            .concat(monitoringIntervals)
            .find(
              (interval: any) =>
                param.axisValue >= interval.start &&
                param.axisValue <= interval.end
            );

          if (intervalData) {
            return `<div style="color: red">${intervalData.reason}</div>`;
          }

          let tooltip = `<strong>${param.axisValue}</strong><br/>`;
          params.forEach((param: any) => {
            const value =
              param.value === undefined ? 'No data' : `${param.value} ms`;
            tooltip += `
              <div style="display:flex;align-items:center;justify-content:space-between;padding: 0px 10px">
                <div style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#55f355;"></div>
                <div style="width:50%;text-align:left;">${param.seriesName}</div>
                <div style=" margin-left: 1rem">:</div>
                <div style="font-weight:bold; margin-left: 0.5rem;float:right;width:5rem;text-align:right;">${value}</div>
              </div>`;
          });
          return tooltip;
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: false,
      } as XAXisComponentOption,
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} ms',
        },
      } as YAXisComponentOption,
      dataZoom: [
        {
          type: 'inside',
          show: true,
          start: 0,
          end: 100,
          // Enable the scrollable option for the dataZoom
        },
      ],
      series: [
        {
          name: 'Response Time',
          type: 'line',
          smooth: true,
          areaStyle: {
            color: 'rgba(0, 255, 0, 0.1)',
          },
          lineStyle: {
            color: '#00FF00',
          },
          data: responseTimeData,
          markArea: {
            silent: false,
            label: {
              show: false,
              color: 'red', // Customize the tooltip font color
              formatter: function (params: any) {
                return params.data.reason;
              },
            },
            itemStyle: {
              color: 'rgba(255, 0, 0, 0.3)',
            },
            emphasis: {
              itemStyle: {
                color: 'rgba(255, 0, 0, 0.5)',
              },
            },
            data: [
              ...downtimeIntervals.map((interval: any) => [
                {
                  xAxis: interval.start,
                  itemStyle: { color: 'rgba(255, 0, 0, 0.3)' },
                  label: { formatter: 'Down' },
                  emphasis: {
                    itemStyle: { color: 'rgba(255, 0, 0, 0.5)' },
                  },
                },
                {
                  xAxis: interval.end,
                },
              ]),
              ...monitoringIntervals.map((interval: any) => [
                {
                  xAxis: interval.start,
                  itemStyle: { color: 'rgba(217, 147, 54, 1)' },
                  label: { formatter: 'Under Maintenance' },
                  emphasis: {
                    itemStyle: { color: 'rgb(194, 131, 48)' },
                  },
                },
                {
                  xAxis: interval.end,
                },
              ]),
            ],
          },
        },
      ],
    };

    // Set options to the chart
    //   chartInstanceRef.current.setOption(options, {
    //     notMerge: false,
    //     lazyUpdate: true,
    //   });

    //   // Dispose the chart when component unmounts
    //   return () => {
    //     chartInstanceRef.current && chartInstanceRef.current.dispose();
    //   };
    // }, [urlData]);

    if (chartInstanceRef.current) {
      chartInstanceRef.current.setOption(options, {
        notMerge: false,
        lazyUpdate: true,
      });
    }

    return () => {
      disposeChart();
    };
  }, [urlData, initializeChart, disposeChart]);

  // const renderChart = useCallback(() => {
  return <div ref={chartRef} style={style} />;

  // }, [urlData]);

  // return renderChart();
};

export default memo(MonitoringGraph);
