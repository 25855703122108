import React, { memo, useEffect, useMemo, useState } from 'react';
import './URLMonitoring.css';
import {
  // DEFAULT_LABELS,
  config,
  returnEnvironmentSelectResult,
  returnSearchResults,
} from './config';
import { MONITORING_TYPES } from '../../components/Modals/UptimeMonitoringModal/config';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { ERRORS, PRIMARY_REGION } from '../../utils/constants';
import { handleError } from '../../utils/config';
import { useHistory } from 'react-router-dom';
import { userService } from '../../services';
import { config as NMSConfig } from '../NotificationManagement/config';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/userSlice';
import URLAccordionSection from './URLAccordionSection';
import QuickStats from './QuickStats';
import DetailMonitoring from './DetailMonitoring';
import { RoutesFun } from '../../schemas';
import { SelectChangeEvent } from '@mui/material';
import { ALL_VALUE } from '../Invoice/config';

interface QuickStatsGrid {
  up: number;
  down: number;
  maintenance: number;
  pause: number;
}

export interface submitUrlInterface {
  CompanyName: string | undefined;
  FriendlyName: string;
  MonitoringLabels: string[] | null;
  WebUrl: string;
  Region: string[];
  Type: string;
  IsSSL?: boolean;
  Timeout?: number;
  Frequency: number;
  Notifications: {
    Type: string;
    Value: string;
    FriendlyName: string;
  }[];
  IsMonitoring?: boolean;
  UrlId?: number;
  Body?: any;
  Method?: string;
  Headers?: any;
  Port?: string;
  newFriendlyName?: string;
  acceptedStatuses: number[] | null;
  EscalationThreshold?: any;
  NotificationInterval?: number;
}

export interface UrlMaintenanceInterface {
  Id: number;
  startDate: string;
  endDate: string;
  isMaintenanceEnabled: boolean;
  isEditMode: boolean;
}

export type ListI = {
  url: Record<string, any>;
  port: Record<string, any>;
  webURLs: string[];
  urlHostName: string[];
  portHostName: string[];
};

const URLMonitoring: React.FC<any> = (props) => {
  const Routes = RoutesFun();
  const [loader, setLoader] = useState(false);
  const [graphLoader, setGraphLoader] = useState(false);
  const [
    showUptimeDetailMonitorigModal,
    setShowUptimeDetailMonitorigModal,
  ] = useState<boolean>(false);
  const [
    showUptimeAccMonitoringModal,
    setShowUptimeAccMonitoringModal,
  ] = useState(false);
  const [orgEmails, setOrgEmails] = useState<string[]>([]);
  const [orgSlackGroups, setOrgSlackGroups] = useState<string[]>([]);
  const [orgGchats, setOrgGchats] = useState<string[]>([]);
  const [webhookUrlMapping, setWebhookUrlMapping] = useState({});
  const [gchat_IdMapping, setGchat_IdMapping] = useState({});
  const [email_IdMapping, setEmail_IdMapping] = useState({});
  const [slackChannel_IdMapping, setSlackChannel_IdMapping] = useState({});
  const [clickedData, setClickedData] = useState<any>(null);
  const [quickStatsGrid, setQuickStatsGrid] = useState<QuickStatsGrid>({
    up: 0,
    down: 0,
    maintenance: 0,
    pause: 0,
  });
  const [toggledMonitoringItem, setToggledMonitoringItem] = useState<
    string | null
  >(null);

  const dispatch = useDispatch();

  const [
    accordionFriendlyNameSearch,
    setAccordionFriendlyNameSearch,
  ] = useState<string>('');

  // const [tablePaginationData, setTablePaginationData] = useState<any>();

  const [URLData, setURLData] = useState<any>({});
  const [selectedEnvData, setSelectedEnvData] = useState<any>({});
  const [isEnvLableSelected, setIsEnvLabelSelected] = useState<boolean>(false);
  const [networkError, setNetworkError] = useState('');
  const history = useHistory();
  const [toggleMonitoringLoader, setToggleMonitoringLoader] = useState<boolean>(
    false
  );
  const [isCsvUploaded, setIsCsvUploaded] = useState<boolean>(false);

  const [list, setList] = useState<ListI>({
    url: {},
    port: {},
    webURLs: [],
    portHostName: [],
    urlHostName: [],
  });
  const [openUrlMaintenanceModal, setOpenUrlMaintenanceModal] = useState(false);
  const [openCsvUploadModal, setOpenCsvUploadModal] = useState(false);
  const [tabValue, setTabValue] = useState<string>(MONITORING_TYPES.HTTPs);
  const [isTabChanged, setIsTabChanged] = useState<boolean>(false);
  const [tabChangeLoader, setTabChangeLoader] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string>(PRIMARY_REGION);
  const [environmentLabels, setEnvironmentLabels] = useState<string[]>([]);
  const [environmentLabel, setEnvironmentLabel] = useState<string>('');
  // setURLData(useIntervalFetch(fetchURLData, 5000));

  const handleEnvironmentChange = (event: SelectChangeEvent<string>) => {
    const label = event.target.value;
    setEnvironmentLabel(label);

    if (label === '') {
      setIsEnvLabelSelected(false);
    } else {
      setIsEnvLabelSelected(true);
      const updatedData = returnEnvironmentSelectResult(URLData, label);
      setSelectedEnvData(updatedData);
    }
    // label !== ''
    //   ? Object.fromEntries(
    //       Object.entries(URLData).filter(([, value]: any) => {
    //         const firstMonitoringLabels = value?.[0]?.MonitoringLabels;
    //         return (
    //           firstMonitoringLabels && firstMonitoringLabels.includes(label)
    //         );
    //       })
    //     )
    //   : URLData;
  };

  const URLUpdatedData = useMemo(() => {
    return isEnvLableSelected ? selectedEnvData : URLData;
  }, [isEnvLableSelected, selectedEnvData, URLData]);

  const loaderHandler = (boolean: boolean) => {
    setLoader(boolean);
  };
  const csvUploadStatusHandler = (boolean: boolean) => {
    setIsCsvUploaded(boolean);
  };

  useMemo(() => {
    setClickedData(props.location.state);
    if (props.location.state) {
      setSelectedRegion(
        props.location.state.regionSelected
          ? props.location.state.regionSelected
          : PRIMARY_REGION
      );
      const clickedObject: any = Object.values(
        props.location.state.clickedData
      ).flat()[0];

      const configCondition: any = {
        http: 'https',
        https: 'https',
        'tcp-port': 'tcp-port',
      };

      if (configCondition[clickedObject.Type] !== tabValue) {
        history.push(Routes.UptimeMonitoring);
      }
    }
  }, [props.location.state, toggleMonitoringLoader, tabValue]);

  const closeMaintenanceModal = () => setOpenUrlMaintenanceModal(false);
  const onCloseCsvUploadModal = () => setOpenCsvUploadModal(false);
  const onCloseUptimeDetailMonitoringModal = () =>
    setShowUptimeDetailMonitorigModal(false);
  const onCloseUptimeAccMonitoringModal = () =>
    setShowUptimeAccMonitoringModal(false);

  const handleTabChange = (tab: string) => {
    setTabValue(tab);
    setIsTabChanged(true);
    setAccordionFriendlyNameSearch('');
    setEnvironmentLabel('');
    setIsEnvLabelSelected(false);
  };

  const handleUrlMaintenanceModal = () => {
    setOpenUrlMaintenanceModal(true);
  };

  const handleUrlDetailMonitoringModal = () => {
    setShowUptimeDetailMonitorigModal(true);
  };
  const handleUrlAccMonitoringModal = () => {
    setShowUptimeAccMonitoringModal(true);
  };
  const handleCsvUploadModal = () => {
    setOpenCsvUploadModal(true);
  };

  const onSubmitUrl = async (params: submitUrlInterface) => {
    try {
      setLoader(true);
      if (params.UrlId) await config.editURL(params);
      else await config.addURL(params);
    } catch (e: any) {
    } finally {
      fetchURLData();
      handleEnvironmentLabels();
      fetchOrgEmailsAndSlackGroups();
      setShowUptimeDetailMonitorigModal(false);
      setShowUptimeAccMonitoringModal(false);
      setLoader(false);
    }
  };

  const fetchOrgEmailsAndSlackGroups = async () => {
    try {
      setGraphLoader(true);
      const orgGchatsData = await config.getOrgGchat();
      const slackChannels = await config.fetchSlackData();

      const usersEmails = await userService.getALLUserDetails();

      const nonRegisteredEmails = await NMSConfig.getUptimeMonitoringNonRegisteredEmails();

      const mappingChannel_Webhook: {
        [key: string]: string;
      } = {};
      const mappingGchatChannel_Id: {
        [key: string]: number;
      } = {};
      const mappingEmail_Id: {
        [key: string]: number;
      } = {};
      const mappingSlackChannel_Id: {
        [key: string]: number;
      } = {};

      const orgSlackChannelsNames: string[] = [];
      const orgEmails: string[] = [];
      const orgGchatChannelNames: string[] = [];

      if (slackChannels?.length) {
        slackChannels.map((channel: any) => {
          orgSlackChannelsNames.push(channel.channel_name);
          mappingChannel_Webhook[channel.channel_name] = channel.webhook_url;
          mappingSlackChannel_Id[channel.channel_name] = channel.id;
        });
      }
      if (usersEmails?.length) {
        usersEmails.map((email: any) => {
          orgEmails.push(email.EmailAddress);
          mappingEmail_Id[email.EmailAddress] = email.Id;
        });
      }
      if (orgGchatsData?.length) {
        orgGchatsData.map((Gchat: any) => {
          orgGchatChannelNames.push(Gchat.channel_name);
          mappingGchatChannel_Id[Gchat.channel_name] = Gchat.id;
        });
      }

      setOrgEmails([...orgEmails, ...nonRegisteredEmails]);
      setOrgSlackGroups(orgSlackChannelsNames);
      setOrgGchats(orgGchatChannelNames);

      setWebhookUrlMapping(mappingChannel_Webhook);
      setSlackChannel_IdMapping(mappingSlackChannel_Id);
      setEmail_IdMapping(mappingEmail_Id);
      setGchat_IdMapping(mappingGchatChannel_Id);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setGraphLoader(false);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  const fetchURLData = async () => {
    try {
      if (!isTabChanged) setLoader(true);
      setTabChangeLoader(true);
      const { result } = await config.getURLs(1, tabValue);

      const filteredResult = Object.entries(result).reduce(
        (
          acc: {
            url: any;
            port: any;
            webURLs: string[];
            urlHostName: string[];
            portHostName: string[];
          },
          [key, value]: any
        ) => {
          if (
            value[0].Type === MONITORING_TYPES.HTTPs ||
            value[0].Type === MONITORING_TYPES.HTTP
          ) {
            acc.url[key] = value;
            acc.urlHostName.push(value[0].FriendlyName);
          } else {
            acc.port[key] = value;
            acc.portHostName.push(value[0].FriendlyName);
          }
          acc.webURLs.push(value[0].WebUrl);
          return acc;
        },
        {
          url: {},
          port: {},
          webURLs: [],
          portHostName: [],
          urlHostName: [],
        }
      );

      setList(filteredResult);
      setURLData(result);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      setTabChangeLoader(false);
      // setTimeout(fetchURLData, 10000);
    }
  };

  const fetchIntervalURLData = async () => {
    try {
      // if (!isTabChanged) setLoader(true);
      // setTabChangeLoader(true);
      const { result } = await config.getURLs(1, tabValue);
      const filteredResult = Object.entries(result).reduce(
        (
          acc: {
            url: any;
            port: any;
            webURLs: string[];
            urlHostName: string[];
            portHostName: string[];
          },
          [key, value]: any
        ) => {
          if (
            value[0].Type === MONITORING_TYPES.HTTPs ||
            value[0].Type === MONITORING_TYPES.HTTP
          ) {
            acc.url[key] = value;
            acc.urlHostName.push(value[0].FriendlyName);
          } else {
            acc.port[key] = value;
            acc.portHostName.push(value[0].FriendlyName);
          }
          acc.webURLs.push(value[0].WebUrl);
          return acc;
        },
        {
          url: {},
          port: {},
          webURLs: [],
          portHostName: [],
          urlHostName: [],
        }
      );

      setList(filteredResult);

      result && setURLData(result);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  useEffect(() => {
    const intervalFunc = setInterval(fetchIntervalURLData, 120000);

    return () => clearInterval(intervalFunc);
  }, [tabValue]);

  const handleUrlMaintenanceSubmition = async (
    props: UrlMaintenanceInterface
  ) => {
    try {
      const {
        Id,
        endDate,
        isEditMode,
        isMaintenanceEnabled,
        startDate,
      } = props;
      setLoader(true);
      const params = {
        Id,
        Maintenance: !isMaintenanceEnabled ? null : `${startDate}-${endDate}`,
      };

      const result = isEditMode
        ? await config.editUrlMaintenance(params)
        : await config.addUrlMaintenance(params);
      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
      }
    } catch (e: any) {
      dispatch(
        userActions.replaceUserState({
          errorMessage: e.error.message,
        })
      );
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
      fetchURLData();
      setOpenUrlMaintenanceModal(false);
    }
  };

  useEffect(() => {
    fetchOrgEmailsAndSlackGroups();
    fetchURLData();
    handleEnvironmentLabels();
  }, [clickedData]);

  useEffect(() => {
    if (isTabChanged) fetchURLData();
  }, [tabValue]);

  useEffect(() => {
    isCsvUploaded && fetchURLData();
  }, [isCsvUploaded]);

  useMemo(() => {
    const { up, down, maintenance, pause } = Object.values(
      returnSearchResults(URLUpdatedData, accordionFriendlyNameSearch, tabValue)
    )
      .flat()
      .reduce(
        (acc: QuickStatsGrid, item: any) => {
          if (
            item.LastActive &&
            item.IsMonitoring &&
            (item.Maintenance
              ? new Date().getTime() < +item.Maintenance.split('-')[1] &&
                new Date().getTime() > +item.Maintenance.split('-')[0]
              : true)
          ) {
            acc.up++;
          } else if (
            !item.LastActive &&
            item.IsMonitoring &&
            (item.Maintenance
              ? new Date().getTime() < +item.Maintenance.split('-')[1] &&
                new Date().getTime() > +item.Maintenance.split('-')[0]
              : true)
          ) {
            acc.down++;
          }
          if (
            item.Maintenance &&
            item.Maintenance.split('-')[0] < Date.now() &&
            item.Maintenance.split('-')[1] > Date.now()
          ) {
            acc.maintenance++;
          }

          if (!item.IsMonitoring) {
            acc.pause++;
          }

          return acc;
        },
        { up: 0, down: 0, maintenance: 0, pause: 0 }
      );
    setQuickStatsGrid({ up, down, maintenance, pause });
  }, [URLUpdatedData, accordionFriendlyNameSearch]);

  const handleAccordionFriendlyNameSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAccordionFriendlyNameSearch(event.target.value.trim());
  };

  const handleEnvironmentLabels = async () => {
    try {
      const result = await config.environmentLabels();
      result && setEnvironmentLabels(result);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const monitoringStatusHandler = async (key: string) => {
    try {
      if (!URLUpdatedData[key]) {
        return;
      }

      setToggleMonitoringLoader(true);

      const params = {
        FriendlyName: key,
        IsMonitoring:
          URLUpdatedData[key] && !URLUpdatedData[key][0].IsMonitoring,
        monitoringType: URLUpdatedData[key][0].Type,
      };
      setToggledMonitoringItem(key);

      const result = await config.toggleMonitoring(params);

      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );

        setURLData((prevURLData: any) => ({
          ...prevURLData,
          [key]: prevURLData[key].map((item: any) => ({
            ...item,
            IsMonitoring: params.IsMonitoring,
          })),
        }));
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setToggleMonitoringLoader(false);
    }
  };

  const toggleAllURLsState = async (value: boolean) => {
    try {
      const params = {
        IsMonitoring: value,
        // monitoringType: tabValue,
        action: ALL_VALUE,
        type: tabValue,
      };

      const result = await config.toggleMonitoring(params);
      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );

        const urlData = Object.entries(URLData).reduce(
          (acc: any, [key, val]: any) => {
            val.forEach((row: any) => {
              row.IsMonitoring = value;
            });
            acc[key] = val;
            return acc;
          },
          {}
        );

        setURLData(urlData);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {loader && <LoadingScreen />}
      <div className="URLcontainer">
        {/* Section 1 */}

        <URLAccordionSection
          toggleAllURLsState={toggleAllURLsState}
          URLData={URLUpdatedData}
          parentLoader={loader}
          loaderHandler={loaderHandler}
          tabChangeLoader={tabChangeLoader}
          monitoringStatusHandler={monitoringStatusHandler}
          onSubmitUrl={onSubmitUrl}
          handleUrlMaintenanceSubmition={handleUrlMaintenanceSubmition}
          handleUrlMaintenanceModal={handleUrlMaintenanceModal}
          handleUrlMonitoringModal={handleUrlAccMonitoringModal}
          onCloseUptimeMonitoringModal={onCloseUptimeAccMonitoringModal}
          onCloseCsvUploadModal={onCloseCsvUploadModal}
          openCsvUploadModal={openCsvUploadModal}
          handleCsvUploadModal={handleCsvUploadModal}
          csvUploadStatusHandler={csvUploadStatusHandler}
          closeMaintenanceModal={closeMaintenanceModal}
          handleAccordionFriendlyNameSearch={handleAccordionFriendlyNameSearch}
          handleTabValue={handleTabChange}
          tabValue={tabValue}
          accordionFriendlyNameSearch={accordionFriendlyNameSearch}
          showUptimeMonitorigModal={showUptimeAccMonitoringModal}
          openUrlMaintenanceModal={openUrlMaintenanceModal}
          toggleMonitoringLoader={toggleMonitoringLoader}
          toggledMonitoringItem={toggledMonitoringItem}
          webhookUrlMapping={webhookUrlMapping}
          gchat_IdMapping={gchat_IdMapping}
          email_IdMapping={email_IdMapping}
          slackChannel_IdMapping={slackChannel_IdMapping}
          orgEmails={orgEmails}
          orgSlackGroups={orgSlackGroups}
          orgGchats={orgGchats}
          list={list}
          clickedData={clickedData}
          selectedRegion={selectedRegion}
          environmentLabels={environmentLabels}
          environmentLabel={environmentLabel}
          handleEnvironmentChange={handleEnvironmentChange}
        />
        {/* Section 2 */}
        <div className="secondURLSection" style={{ position: 'relative' }}>
          {clickedData ? (
            <DetailMonitoring
              URLData={clickedData}
              monitoringStatusData={URLUpdatedData}
              showUptimeDetailMonitorigModal={showUptimeDetailMonitorigModal}
              onCloseUptimeDetailMonitoringModal={
                onCloseUptimeDetailMonitoringModal
              }
              handleUrlDetailMonitoringModal={handleUrlDetailMonitoringModal}
              monitoringStatusHandler={monitoringStatusHandler}
              toggledMonitoringItem={toggledMonitoringItem}
              toggleMonitoringLoader={toggleMonitoringLoader}
              orgEmails={orgEmails}
              orgSlackGroups={orgSlackGroups}
              orgGchats={orgGchats}
              email_IdMapping={email_IdMapping}
              gchat_IdMapping={gchat_IdMapping}
              graphLoader={graphLoader}
              list={list}
              onSubmitUrl={onSubmitUrl}
              setLoader={setLoader}
              loader={loader}
              slackChannel_IdMapping={slackChannel_IdMapping}
              webhookUrlMapping={webhookUrlMapping}
              setSelectedRegion={setSelectedRegion}
              selectedRegion={selectedRegion}
              environmentLabels={environmentLabels}
            />
          ) : (
            <QuickStats
              QuickStatsGrid={quickStatsGrid}
              URLData={URLUpdatedData}
              accordionFriendlyNameSearch={accordionFriendlyNameSearch}
              loader={loader}
              tabChangeLoader={tabChangeLoader}
              tabValue={tabValue}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(URLMonitoring);
