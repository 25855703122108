import React, { useEffect, useState } from 'react';
import { CustomModal } from '../CustomModal/CustomModal';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  addMonths,
  endOfToday,
  startOfDay,
  endOfDay,
  isBefore,
} from 'date-fns';
import { TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AdapterDateFns from '@date-io/date-fns';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import { ICON_TYPE, STATUS_TYPE, config } from './config';
import {
  NotificationLogsProps,
  globalLocalTimeFormatter,
} from '../../../pages/UptimeMonitoring/config';
import { CommonApiData } from '../../../utils/interfaces';
import { ReactComponent as SlackIcon } from '../../../assets/ICONS/slack.svg';
import { ReactComponent as GmailIcon } from '../../../assets/ICONS/gmail.svg';
import { ReactComponent as GchatIcon } from '../../../assets/ICONS/gchat.svg';

interface NotificationLogModalProps {
  showNotificationLogModal: boolean;
  closeNotificationLogModalHandler: { (): void };
  urlId: number;
}
interface NotificationLogData extends CommonApiData {
  URLId: number;
  CompanyId: number;
  NotificationType: string;
  Channel: string;
  Receiver: string[];
  Date: string;
  Time: string;
}

const NotificationLogModal: React.FC<NotificationLogModalProps> = ({
  closeNotificationLogModalHandler,
  showNotificationLogModal,
  urlId,
}) => {
  const history = useHistory();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [logsTableData, setLogsTableData] = useState<NotificationLogData[]>([]);
  const [isTableLoad, setIsTableLoad] = useState<boolean>(false);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    let active = true;

    const fetchNotificationLogs = async (date: Date | null) => {
      try {
        if (date) {
          setIsTableLoad(true);
          const startDate = startOfDay(date).getTime();
          const endDate = endOfDay(date).getTime();
          // setStartTime(start);
          // setEndTime(end);
          const params: NotificationLogsProps = {
            urlId,
            startDate,
            endDate,
          };
          const data = await config.getUrlNotificationLogs(params);
          if (active) {
            const reversedData = data.reverse();
            setLogsTableData(reversedData);
          }
        }
      } catch (e: any) {
        // setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

        if (e && e.error && e.error.message) {
          handleError(e, history);
        }
      } finally {
        setIsTableLoad(false);
      }
    };
    fetchNotificationLogs(selectedDate);

    return () => {
      active = false;
    };
  }, [selectedDate]);

  const minDate = addMonths(endOfToday(), -3);
  const maxDate = endOfToday();

  const iconHandler = (icon: string) => {
    switch (icon) {
      case ICON_TYPE.GChat:
        return <GchatIcon width={20} height={18} />;
      case ICON_TYPE.Slack:
        return <SlackIcon width={20} height={18} />;
      case ICON_TYPE.Mail:
        return <GmailIcon width={20} height={18} />;
      default:
        return '';
    }
  };

  const backgroundStatusColourHandler = (status: string) => {
    switch (status) {
      case STATUS_TYPE.Downtime:
        return '#fdd0d0';
      case STATUS_TYPE.Uptime:
        return '#EDFFED';
      case STATUS_TYPE['SSL Expiry']:
        return '#f1ca96';
      default:
        return '';
    }
  };

  return (
    <CustomModal
      headerMsg="URL Notification Log"
      show={showNotificationLogModal}
      onClose={closeNotificationLogModalHandler}
      style={{
        width: 'min-content',
        maxWidth: 'min-content',
        // maxHeight: '60%',
      }}
    >
      <div className="w-100 notificationLogContainer centerItem mt-20 mb-25 cDateTime">
        <MuiPickersUtilsProvider utils={AdapterDateFns}>
          <DatePicker
            label="Select a Date"
            value={selectedDate}
            onChange={handleDateChange}
            TextFieldComponent={(props) => (
              <TextField
                {...props}
                variant="outlined"
                className="dateInputBox"
              />
            )}
            format="dd/MM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={(date: any) => isBefore(date, minDate)}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="notificationLogTable">
        <TableContainer className="accContainer notificationLogTableContainer URLMonitoringTable">
          <Table
            stickyHeader
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="sticky table"
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell>Medium</TableCell>
                <TableCell>Notification Type</TableCell>
                <TableCell>Receiver</TableCell>
                <TableCell>Date Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isTableLoad ? (
                <>
                  <TableCell>Loading...</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              ) : logsTableData && logsTableData.length ? (
                logsTableData.map((data, i: number) =>
                  data.Receiver.map((email, newIndex) => (
                    <TableRow
                      style={{
                        background: `${backgroundStatusColourHandler(
                          data.NotificationType
                        )}`,
                      }}
                      key={i + newIndex}
                    >
                      <TableCell>
                        <div className="d-flex">
                          <div className="mr-5" style={{ marginTop: '.2rem' }}>
                            {iconHandler(data.Channel)}
                          </div>
                          <div>{data.Channel}</div>
                        </div>
                      </TableCell>
                      <TableCell>{data.NotificationType}</TableCell>
                      <TableCell className="tableColumnWidth">
                        {email}
                      </TableCell>
                      <TableCell>
                        {globalLocalTimeFormatter(data.Date, data.Time)}
                      </TableCell>
                    </TableRow>
                  ))
                )
              ) : (
                <>
                  <TableCell>No data found</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </CustomModal>
  );
};

export default NotificationLogModal;
