import { APIS } from '../../utils/apiConfig';
import { GlobalQueryParams } from '../../utils/config';
import { HttpService } from '../HttpService/HttpService';

export class InventoryService {
  constructor(private httpService: HttpService) {}

  async getInventoryList(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/inventory/get?'),
        {}
      );

      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getFilterData(): Promise<any> {
    try {
      const response = await this.httpService.get('/accounts/getSubscription');
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async ec2InstanceTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/ec2-instance-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/ec2-instance-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/ec2-instance-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async awsResourceGroupTags(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/resource-group-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/resource-group-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/resource-group-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async s3BucketTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post('/awsTagging/s3-tag', params);
          break;
        case 'update':
          response = await this.httpService.post('/awsTagging/s3-tag', params);
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/s3-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async azureTaggingService(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/azureTagging/azure-tagging',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/azureTagging/azure-tagging',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/azureTagging/azure-tagging',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async awsTaggingService(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/aws-tagging',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/aws-tagging',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/aws-tagging',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async loadBalancerTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/elastic-load-balancer-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/elastic-load-balancer-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/elastic-load-balancer-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async elasticBeanstalkTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/elastic-beanstalk-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/elastic-beanstalk-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/elastic-beanstalk-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async sqsTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post('/awsTagging/sqs-tag', params);
          break;
        case 'update':
          response = await this.httpService.post('/awsTagging/sqs-tag', params);
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/sqs-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async efsTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post('/awsTagging/efs-tag', params);
          break;
        case 'update':
          response = await this.httpService.post('/awsTagging/efs-tag', params);
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/efs-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async workspaceTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/workspaces-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/workspaces-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/workspaces-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async awsTagging(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/aws-tagging',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/aws-tagging',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/aws-tagging',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async azureTagging(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/azureTagging/azure-tagging',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/azureTagging/azure-tagging',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/azureTagging/azure-tagging',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async elasticIPTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/elastic-ip-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/elastic-ip-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/elastic-ip-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async ebsTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post('/awsTagging/ebs-tag', params);
          break;
        case 'update':
          response = await this.httpService.post('/awsTagging/ebs-tag', params);
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/ebs-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async dynamoDBTag(params: any, type: string): Promise<any> {
    try {
      let response;
      switch (type) {
        case 'create':
          response = await this.httpService.post(
            '/awsTagging/dynamodb-tag',
            params
          );
          break;
        case 'update':
          response = await this.httpService.post(
            '/awsTagging/dynamodb-tag',
            params
          );
          break;
        case 'delete':
          response = await this.httpService.delete(
            '/awsTagging/dynamodb-tag',
            params
          );
          break;
      }
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getInventoryResourceDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(
          params,
          '/inventory/getInventoryResourceDetails?limit=10&'
        ),
        {}
      );

      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getAllResourceDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_BY_RESOURCE_NAMES(),
        { params }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getRecentCreatedResource(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_RECENT_CREATED_RESOURCES(),
        { params }
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
