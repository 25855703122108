import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { ReactComponent as CrossIcon } from '../../../assets/images/cross-icon.svg';
import moment from 'moment';
import './UptimeMonitoringModal';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AdapterDateFns from '@date-io/date-fns';
import { Switch, Typography } from '@mui/material';
import { UrlMaintenanceInterface } from '../../../pages/UptimeMonitoring/URLMonitoring';
interface UrlMaintenanceModal {
  show: boolean;
  onClose?: { (): void };
  urlData?: any;
  isAddMaintenanceUrl: boolean;
  handleUrlMaintenanceSubmition?: (
    props: UrlMaintenanceInterface
  ) => Promise<void>;
}

const UrlMaintenanceModal: React.FC<UrlMaintenanceModal> = (props) => {
  const {
    show,
    urlData,
    onClose,
    handleUrlMaintenanceSubmition,
    isAddMaintenanceUrl,
  } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [startDate, setStartDate] = React.useState<any>(
    isAddMaintenanceUrl ? null : +urlData.Maintenance.split('-')[0]
  );
  const [endDate, setEndDate] = React.useState<any>(
    !isAddMaintenanceUrl ? +urlData.Maintenance.split('-')[1] : null
  );
  const [endTimeError, setEndTimeError] = React.useState(false);
  const [isMaintenanceEnabled, setIsMaintenanceEnabled] = useState(true);
  const [startTimeError, setStartTimeError] = React.useState(false);
  const [startExceedTimeError, setStartExceedTimeError] = React.useState(false);

  const dateChange = (event: any) => {
    if (event.getTime() < new Date().getTime() + 120000) {
      setStartDate(new Date().getTime() + 120000);
      setStartTimeError(true);
    } else {
      setStartTimeError(false);
    }

    if (event.getTime() >= endDate && endDate) {
      setStartExceedTimeError(true);
    }
    setStartExceedTimeError(false);
    setStartDate(event.getTime());

    // Reset end date if it has the same time as start date
    if (endDate && event && event.getTime() === endDate) {
      setEndDate(null);
    }
  };
  const endDateChange = (event: any) => {
    // Validate if end date is greater than start date
    if (event && startDate && event.getTime() <= startDate) {
      setEndTimeError(true);
    } else {
      setEndTimeError(false);
    }
    setEndDate(event.getTime());
  };

  const handleMaintenanceCheckboxChange = () => {
    setIsEditMode(!isEditMode);
  };

  const maintenanceTime = urlData.Maintenance;

  const formattedStartDate =
    !isAddMaintenanceUrl &&
    moment(+maintenanceTime.split('-')[0]).format('DD-MM-YYYY');
  const formattedStartTime =
    !isAddMaintenanceUrl &&
    moment(+maintenanceTime.split('-')[0]).format('HH:mm:ss');
  const formattedEndDate =
    !isAddMaintenanceUrl &&
    moment(+maintenanceTime.split('-')[1]).format('DD-MM-YYYY');
  const formattedEndTime =
    !isAddMaintenanceUrl &&
    moment(+maintenanceTime.split('-')[1]).format('HH:mm:ss');

  const handleMaintenanceSwitchChange = (event: any) => {
    setIsMaintenanceEnabled(event.target.checked);
  };

  const MaintananceDurationSection = () => {
    return (
      <>
        <p className="fw-500 mt-10 font-16px">Maintenance Duration</p>
        <div className="row urlMaintenanceInput">
          <div className="cDateTime">
            <MuiPickersUtilsProvider utils={AdapterDateFns}>
              <DateTimePicker
                TextFieldComponent={(props) => (
                  <TextField {...props} variant="outlined" />
                )}
                label="Start Date Time"
                value={startDate}
                format="dd/MM/yyyy, hh:mm a"
                onChange={dateChange}
                minDate={moment(new Date())
                  .add(120, 'seconds')
                  .format('YYYY-MM-DD')}
                helperText={
                  startTimeError
                    ? 'Start time must be 2 minutes more than the present Time'
                    : startExceedTimeError
                    ? 'Start time cannot be more than end time'
                    : ''
                }
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={AdapterDateFns}>
              <DateTimePicker
                TextFieldComponent={(props) => (
                  <TextField {...props} variant="outlined" />
                )}
                label="End Date Time"
                value={endDate}
                format="dd/MM/yyyy, hh:mm a"
                onChange={endDateChange}
                minDate={moment(new Date()).format('YYYY-MM-DD')}
                helperText={
                  endTimeError ? 'End Time must be greater than Start Time' : ''
                }
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </>
    );
  };

  const urlMaintenanceCondition = () => {
    if (!isAddMaintenanceUrl) {
      if (!isMaintenanceEnabled) return true;

      if (startTimeError || startExceedTimeError || endTimeError) return false;

      return startDate !== +urlData.Maintenance.split('-')[0] ||
        endDate !== +urlData.Maintenance.split('-')[1]
        ? true
        : false;
    }

    if (startTimeError || startExceedTimeError || endTimeError) return false;

    return true;
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">
              {isAddMaintenanceUrl ? 'Add' : 'Edit'} URL Maintenance
            </span>
            <span className="modalClose" onClick={onClose}>
              <CrossIcon width={20} height={20} />
            </span>
          </div>
        </Grid>
        <Box
          padding="12px"
          borderRadius={2}
          style={{ width: '90%', margin: 'auto' }}
          display="flex"
          flexDirection="column"
        >
          {isAddMaintenanceUrl ? (
            <MaintananceDurationSection />
          ) : (
            <div className="urlMaintenanceInput">
              <p className="fw-500 mt-10 mb-5 font-16px">Under Maintenance</p>
              <Box
                bgcolor="background.paper"
                boxShadow={1}
                borderRadius={4}
                p={3}
              >
                <Typography
                  variant="h5"
                  letterSpacing={1}
                  textAlign="center"
                  fontWeight={600}
                  color="black"
                >
                  This URL is under maintenance till {formattedStartDate}{' '}
                  {formattedStartTime} to {formattedEndDate} {formattedEndTime}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isMaintenanceEnabled}
                        onChange={handleMaintenanceSwitchChange}
                        color="primary"
                      />
                    }
                    label="Enable Maintenance"
                  />
                </Box>
                <Box className="editMaintenanceCheckbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isEditMode}
                        onChange={handleMaintenanceCheckboxChange}
                        color="primary"
                      />
                    }
                    label="Edit Maintenance"
                  />
                </Box>
              </Box>
              {isEditMode && <MaintananceDurationSection />}
            </div>
          )}
        </Box>
        <div className="mt-0 modalFooter">
          {
            <button
              type="button"
              disabled={
                startDate && endDate && urlMaintenanceCondition() ? false : true
              }
              className="btn btn-primary ml-10"
              onClick={() => {
                handleUrlMaintenanceSubmition?.({
                  endDate,
                  startDate,
                  Id: urlData.Id,
                  isEditMode,
                  isMaintenanceEnabled,
                }),
                  onClose;
              }}
            >
              Save
            </button>
          }
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};

export default UrlMaintenanceModal;
