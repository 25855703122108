import React, { useEffect, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Tooltip } from '@material-ui/core';
import './AccountDetails.css';
import {
  AccountDetailsProps,
  AccountProps,
} from '../../../schemas/account.schema';
import { accountService } from '../../../services';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';

interface FixProps {
  children?: ReactNode;
  row: AccountDetailsProps;
  // selectAccount: { (row: AccountProps): void };
  openEditPage: { (row: AccountProps): void };
  deleteAccountDetails: { (row: AccountDetailsProps): void };
  value: number;
  cloudProvider: string;
}

export const AccountRow: React.FC<FixProps> = (props) => {
  const {
    row,
    // selectAccount,
    openEditPage,
    deleteAccountDetails,
    value,
    cloudProvider,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [subscriptionFetchedData, setSubscriptionFetchedData] = React.useState(
    []
  );
  const accountData = useSelector(accountState);
  const isAccountSelected = accountData.isAccountSelected;

  // -------------------------------------------------------------------------

  const getSubscriptions = async (id: any) => {
    try {
      if (id) {
        const result = await accountService.getSubscriptionDetails(
          id,
          cloudProvider
        );
        if (result[0].subscriptions)
          setSubscriptionFetchedData(result[0].subscriptions);
        if (result[0].iamUsers) setSubscriptionFetchedData(result[0].iamUsers);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getSubscriptions(row.Id);
  }, [cloudProvider, open, isAccountSelected]);

  useEffect(() => {
    setOpen(false);
  }, [cloudProvider]);
  //------------------------------------------------------------------
  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        // className={open ? 'tableRow tableRowOpen' : 'tableRow'}
        // className={
        //   // row.Id === selectedAccount?.Id
        //   'tableRow ' + classes.selectedRow
        //   // : 'tableRow ' + classes.styleRow
        // }
        className="tableContent"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
      >
        <TableCell
        // className="rowRadio"
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          align="left"
          // className="rowName"
        >
          <Tooltip title={row.AliasName} arrow aria-label={row.AliasName}>
            {row.AliasName ? <>{row.AliasName}</> : <>-</>}
          </Tooltip>
        </TableCell>
        {value == 0 ? (
          <TableCell
            align="left"
            // className=" rowId"
          >
            {row.IAMUserId ? row.IAMUserId : '-'}
          </TableCell>
        ) : null}
        <TableCell
          align="left"
          // className="rowId"
        >
          {value == 0
            ? row.AccountId
              ? row.AccountId
              : '-'
            : value == 1
            ? row.AzureId
              ? row.AzureId
              : '-'
            : '-'}
        </TableCell>
        {value == 1 ? (
          <TableCell
            align="left"
            // className="rowServiceType"
          >
            {row.ServiceType ? row.ServiceType : '-'}
          </TableCell>
        ) : null}
        <TableCell
          align="left"
          // className="rowCurrentUpdated"
        >
          {row.createdAt ? row.createdAt : '-'}
        </TableCell>
        <TableCell
          align="left"
          // className="rowCurrentUpdated"
        >
          {row.updatedAt ? row.updatedAt : '-'}
        </TableCell>
        <TableCell
          align="left"
          // className="rowStatus"
        >
          {row.Credentials === true ? (
            <Tooltip title={'Account Verified'} arrow className="disableIcon">
              <span className="p-relative text-center d-inline-block w-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="20"
                  fill="#155724"
                  className="bi bi-cloud-check"
                  viewBox="0 0 20 13"
                >
                  <path d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                </svg>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                cloudProvider === 'AZURE'
                  ? 'Invalid Request: This may happen if there are no active subscriptions for the tenant. Check to make sure you have the correct tenant ID. Check with your subscription administrator.'
                  : 'Invalid Client TokenId: The security token included in the request is invalid.'
              }
              arrow
              className="disableIcon"
            >
              <span className="p-relative text-center d-inline-block w-100 statusIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="red"
                  className="bi bi-x-circle-fill ml-5"
                  viewBox="0 0 25 25"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell
          align="left"
          // className="rowAction"
        >
          <span
            className="mr-5 cursor-pointer"
            onClick={() => openEditPage(row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
          </span>
        </TableCell>
      </TableRow>
      <TableRow
      // className="collapseTable"
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '8px 0 8px 0' }}>
              <Table
                className="table adminTable adminPanelTable"
                size="small"
                aria-label="subscription"
              >
                <TableHead className="admintableHead">
                  <TableRow
                    // className="tableRow collapseTableHeader"
                    style={{ backgroundColor: '#f9f9f9' }}
                  >
                    <TableCell
                      // className="rowName"
                      className="adminTableheading"
                    >
                      CFx Name
                    </TableCell>
                    <TableCell
                      // className="rowName"
                      className="adminTableheading"
                    >
                      {value === 0
                        ? 'Account Name'
                        : value === 1
                        ? 'Azure Name'
                        : 'Account Name'}
                    </TableCell>
                    <TableCell
                      // className="rowId"
                      className="adminTableheading"
                    >
                      {value === 0
                        ? 'IAM User Id'
                        : value === 1
                        ? 'Subscription Id'
                        : 'Subscription Id'}
                    </TableCell>
                    {value === 1 ? (
                      <TableCell
                        align="left"
                        // className="rowServiceType"
                        className="adminTableheading"
                      >
                        Type
                      </TableCell>
                    ) : null}
                    <TableCell
                      align="left"
                      // className="rowCurrentUpdated"
                      className="adminTableheading"
                    >
                      Created Date
                    </TableCell>
                    <TableCell
                      align="left"
                      // className="rowCurrentUpdated"
                      className="adminTableheading"
                    >
                      Last Updated
                    </TableCell>
                    <TableCell
                      align="left"
                      // className="rowStatus"
                      className="adminTableheading"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="left"
                      // className="rowAction"
                      className="adminTableheading"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionFetchedData.map((subsRow: any, i: number) => (
                    <TableRow
                      key={'subs' + i}
                      // className="tableRow"
                    >
                      <TableCell
                        // component="th"
                        scope="row"
                        // className="rowName"
                      >
                        <Tooltip
                          title={subsRow.AliasName}
                          arrow
                          aria-label={subsRow.AliasName}
                        >
                          <>{subsRow.AliasName ? subsRow.AliasName : <>-</>}</>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        // component="th"
                        scope="row"
                        // className="rowName"
                      >
                        <Tooltip title={subsRow.Name} arrow>
                          <div>{subsRow.Name ? subsRow.Name : <>- </>}</div>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        // component="th"
                        scope="row"
                        // className="rowId"
                      >
                        <Tooltip
                          title={
                            subsRow.SubscriptionId
                              ? subsRow.SubscriptionId
                              : subsRow.IAMUserId
                              ? subsRow.IAMUserId
                              : '-'
                          }
                          arrow
                        >
                          <div className="rowId text-ellipsis">
                            {subsRow.SubscriptionId
                              ? subsRow.SubscriptionId
                              : subsRow.IAMUserId
                              ? subsRow.IAMUserId
                              : '-'}
                          </div>
                        </Tooltip>
                      </TableCell>
                      {value === 1 ? (
                        <TableCell
                          align="left"
                          // className="rowServiceType"
                        >
                          {subsRow.ServiceType ? subsRow.ServiceType : '-'}
                        </TableCell>
                      ) : null}
                      <TableCell
                        align="left"
                        // className="rowCurrentUpdated"
                      >
                        {subsRow.CreatedAt ? subsRow.CreatedAt : '-'}
                      </TableCell>
                      <TableCell
                        align="left"
                        // className="rowCurrentUpdated"
                      >
                        {subsRow.UpdatedAt ? subsRow.UpdatedAt : '-'}
                      </TableCell>
                      <TableCell
                        align="left"
                        // className="rowStatus"
                      >
                        {subsRow.Credentials === true ? (
                          <Tooltip
                            title={'Account Verified'}
                            arrow
                            className="disableIcon"
                          >
                            <span className="p-relative text-center d-inline-block w-100 statusIcon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="#155724"
                                className="bi bi-cloud-check ml-5"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                              </svg>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              cloudProvider === 'AZURE'
                                ? 'Invalid Request: This may happen if there are no active subscriptions for the tenant. Check to make sure you have the correct tenant ID. Check with your subscription administrator.'
                                : 'Invalid Client TokenId: The security token included in the request is invalid.'
                            }
                            arrow
                            className="disableIcon"
                          >
                            <span className="p-relative text-center d-inline-block w-100 statusIcon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="red"
                                className="bi bi-x-circle-fill ml-5"
                                viewBox="0 0 25 25"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        // className="rowAction"
                      >
                        <span>
                          <span
                            className="mr-5 cursor-pointer"
                            onClick={() => openEditPage(subsRow)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                          </span>
                          <span
                            className="ml-5 cursor-pointer"
                            onClick={() => {
                              setOpen(false);
                              deleteAccountDetails(subsRow);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                          </span>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
