import { createSlice } from '@reduxjs/toolkit';
import { UserProps } from '../schemas/user.schema';

interface userStateProps {
  userData: UserProps | undefined;
  isUserSelected: boolean;
  successMessage: string;
  errorMessage: string;
}

const initialUserState: userStateProps = {
  userData: undefined,
  isUserSelected: false,
  successMessage: '',
  errorMessage: '',
};
const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    replaceUserState(state, action) {
      state.userData = action.payload.userData ?? state.userData;
      state.isUserSelected =
        action.payload.isUserSelected ?? state.isUserSelected;
      state.successMessage =
        action.payload.successMessage ?? state.successMessage;
      state.errorMessage = action.payload.errorMessage ?? state.errorMessage;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
