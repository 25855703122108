import React, { Fragment, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  // FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { authService, userService } from '../../services';
import './Registration.css';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import SupportHeader from '../../components/Header/SupportHeader';
import { handleError } from '../../utils/config';
import { LOCAL_STORAGE_VARIABLES, TOKEN_TIMING } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { fetchUserData } from '../../store/thunks';
import { RoutesFun } from '../../schemas';
import { ReactComponent as Intersect } from '../../assets/images/Intersect.svg';
import TextField from '@mui/material/TextField';
import fastForward from '../../assets/images/fastForward.png';
import { Footer } from '../../components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

interface REGISTRATIONDATA {
  FirstName: string;
  LastName: string;
  CompanyName: string;
  showPassword: boolean;
  EmailAddress: string;
  Password: string;
  IsActive: number;
  EditType: string;
  TnC: boolean;
  tnc: boolean;
  PrivacyPolicy: boolean;
  privacypolicy: boolean;
  Designation: string;
}

export const RegistrationLogin: React.FC = () => {
  const Routes = RoutesFun();
  const [registrationData, setregistrationData] = React.useState({
    FirstName: '',
    LastName: '',
    CompanyName: '',
    ContactNo: '',
    showPassword: false,
    Password: '',
    EmailAddress: '',
    IsActive: 0,
    EditType: 'registration',
    tnc: false,
    privacypolicy: false,
    Designation: '',
  });
  const history = useHistory();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [formSubmitMsg, setFormSubmitMsg] = React.useState(false);

  const [show, setShow] = React.useState(false);
  const [counter, setCounter] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [errorD, setErrorD] = React.useState(false);
  const [regMsg, setRegMsg] = React.useState('');
  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setregistrationData({
      ...registrationData,
      showPassword: !registrationData.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState,
    errors,
  } = useForm<REGISTRATIONDATA>({
    mode: 'onChange',
  });

  const [loginData, setIsLoginData] = React.useState({
    EmailAddress: '',
    Password: '',
  });

  const handleFieldChange = (target: any) => {
    if (target.name === 'Designation') {
      setErrorD(false);
    }
    if (target.name == 'TnC' || target.name == 'PrivacyPolicy') {
      setregistrationData({
        ...registrationData,
        [target.name]: target.checked,
      });
    }
    if (target.name == 'CompanyName' && target.value == 'Others') {
      setShow(true);
      return;
    }
    if (target.name == 'CompanyName' && target.value == 'thinksys') {
      setShow(false);
      setregistrationData({ ...registrationData, [target.name]: target.value });

      return;
    }

    setregistrationData({ ...registrationData, [target.name]: target.value });

    setIsLoginData({ ...loginData, [target.name]: target.value });
  };

  const onEmailPasswordSubmit = async (data: REGISTRATIONDATA) => {
    try {
      setLoader(true);
      if (registrationData.Designation === '') {
        setErrorD(true);
        return;
      }
      const result = await userService.postEmailPasswordData({
        ...data,
        Designation: registrationData.Designation,
      });
      if (result.sessionUrl) {
        const currDate = new Date().toISOString();
        localStorage.removeItem('LOGIN_ACCESS');
        localStorage.setItem(
          LOCAL_STORAGE_VARIABLES.LAST_TOKEN_REFRESH,
          currDate
        );
        authService.removeAuth();
        authService.setAuth(result.userData.token);
        localStorage.setItem(
          'token_expiry_time',
          (Date.now() + TOKEN_TIMING.TOKEN_EXPIRY_TIME).toString()
        );
        localStorage.setItem('CompanyName', result.CompanyName);
        localStorage.setItem('RoleId', result.userData.RoleId);
        localStorage.setItem('RemainingDays', result.userData.RemainingDays);
        localStorage.setItem('emailId', result.userData.EmailAddress);
        const auth = authService.checkAuth();
        if (result && auth) {
          dispatch(fetchUserData());

          // window.location.href = result.sessionUrl;
          window.location.href = Routes.TrialPlanConfirmation;
        }
      } else {
        setFormSubmitMsg(true);
        localStorage.removeItem('auth-state');
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
        const err = `${e.error.message.replace('ContactNo', 'Phone Number')}`;
        setRegMsg(err);
      }
    } finally {
      setLoader(false);
    }
  };

  const regMsgElement = () => {
    return <div className="regMsg my-10">{regMsg}</div>;
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  const registrationFirstForm = () => {
    return (
      <form
        noValidate
        onSubmit={handleSubmit(onEmailPasswordSubmit)}
        autoComplete="off"
      >
        <Box className="registrationLogin">
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            className="positionRelative"
          >
            <Grid item className="w100">
              <div className=" fullNameSection">
                <FormControl variant="outlined" className=" mb-25">
                  <TextField
                    id="standard-basic"
                    label="First Name"
                    variant="standard"
                    name="FirstName"
                    focused
                    onChange={(e: any) => handleFieldChange(e.target)}
                    inputRef={register({
                      required: false,
                      // pattern: /^\S*$/,
                    })}
                  />
                  {/* {errors.FirstName && (
                    <div className="regMsg">
                      Enter valid First name without spaces.
                    </div>
                  )} */}
                </FormControl>
                <FormControl variant="outlined" className=" mb-25">
                  <TextField
                    id="standard-basic"
                    label="Last Name"
                    variant="standard"
                    name="LastName"
                    focused
                    onChange={(e: any) => handleFieldChange(e.target)}
                    inputRef={register({
                      required: false,
                      // pattern: /^\S*$/,
                    })}
                  />
                  {/* {errors.LastName && (
                    <div className="regMsg">
                      Enter valid Last name without spaces
                    </div>
                  )} */}
                </FormControl>
              </div>

              <Grid item className="w100">
                <div className=" fullNameSection">
                  <FormControl
                    variant="outlined"
                    className="fullWidthInput mb-25"
                  >
                    <TextField
                      id="standard-basic"
                      label={
                        <span>
                          Business Email<span className="asterisk">*</span>
                        </span>
                      }
                      variant="standard"
                      name="EmailAddress"
                      autoComplete="off"
                      focused
                      type="email"
                      onChange={(e: any) => handleFieldChange(e.target)}
                      inputRef={register({
                        required: true,
                        pattern: /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/,
                      })}
                    />

                    {errors.EmailAddress && (
                      <div className="regMsg">Enter valid email id</div>
                    )}
                  </FormControl>
                  <div className="selectTag w-50">
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Designation<span className="asterisk">*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={registrationData.Designation}
                        label="Age"
                        name="Designation"
                        onChange={(e: any) => handleFieldChange(e.target)}
                      >
                        <MenuItem value="CTO">CTO</MenuItem>
                        <MenuItem value="Devops Engineer">
                          Devops engineer
                        </MenuItem>
                        <MenuItem value="Finops Team">Finops team</MenuItem>
                        <MenuItem value="Technical Lead">
                          Technical lead
                        </MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      {errorD && (
                        <div className="regMsg">Select Designation</div>
                      )}
                    </FormControl>
                  </div>
                </div>
              </Grid>

              <FormControl
                variant="outlined"
                className="fullWidthInput mb-25 passwordInput"
              >
                <TextField
                  id="standard-basic"
                  label={
                    <span>
                      Password<span className="asterisk">*</span>
                    </span>
                  }
                  variant="standard"
                  name="Password"
                  autoComplete="off"
                  focused
                  onChange={(e: any) => handleFieldChange(e.target)}
                  inputRef={register({
                    required: true,
                    maxLength: 16,
                    minLength: 8,
                    pattern: /^\S*$/,
                  })}
                  type={registrationData.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {registrationData.showPassword ? (
                            <FontAwesomeIcon icon={faUnlock} />
                          ) : (
                            <FontAwesomeIcon icon={faLock} />
                          )}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
                {((formState.touched.Password &&
                  loginData.Password.length === 0) ||
                  errors.Password) && (
                  <div className="regMsg">
                    Password length should be between 8 to 16 Character without
                    blank space.
                  </div>
                )}
              </FormControl>

              <FormControl variant="outlined" className="fullWidthInput mb-25">
                <TextField
                  id="standard-basic"
                  label={
                    <span>
                      Company Name<span className="asterisk">*</span>
                    </span>
                  }
                  variant="standard"
                  name="CompanyName"
                  onChange={(e: any) => handleFieldChange(e.target)}
                  inputRef={register({
                    required: true,
                    pattern: /\S/,
                  })}
                  focused
                />
                {errors.CompanyName && (
                  <div className="regMsg">Enter valid company name.</div>
                )}
              </FormControl>

              {/* <Controller
                className="bEmail my-10"
                as={
                  <MuiPhoneNumber
                    defaultCountry={'us'}
                    onChange={handleFieldChange}
                    countryCodeEditable={false}
                  />
                }
                id="phone-number"
                name="ContactNo"
                control={control}
                variant="outlined"
                label="ENTER PHONE NUMBER"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </span>
                    </InputAdornment>
                  ),
                }}
                rules={{ minLength: 10 }}
                helperText={
                  errors.ContactNo && (
                    <div className="regMsg mx-0">Enter Valid number.</div>
                  )
                }
              >
                {errors.ContactNo && (
                  <div className="regMsg">Enter 10 digit Valid numbers.</div>
                )}
              </Controller> */}
              <FormControlLabel
                className="d-block text-left m-0"
                control={
                  <Checkbox
                    onChange={(e) => handleFieldChange(e.target)}
                    name="TnC"
                    color="primary"
                    className="p-5 m-0"
                    inputRef={register({ required: true })}
                  />
                }
                label={
                  <span className="termsAndConditionText p-5">
                    I agree to the
                    <a href="/login">
                      <Typography variant="caption" color="primary">
                        <u className="termsAndConditionText">
                          {' '}
                          Terms and condition and Privacy Policy.
                        </u>
                      </Typography>
                    </a>
                  </span>
                }
              />
              {errors.TnC && (
                <div className="regMsg mb-15">
                  Terms and condition and Privacy Policy must be checked.
                </div>
              )}

              <FormControl variant="outlined" className="bEmail my-10 d-none">
                <InputLabel htmlFor="edit-type" className="inputLegend">
                  Edit Type
                </InputLabel>
                <OutlinedInput
                  id="edit-type"
                  name="EditType"
                  className="outlineInputArea"
                  onChange={(e) => handleFieldChange(e.target)}
                  inputRef={register({
                    required: true,
                    pattern: /^\S*$/,
                  })}
                  defaultValue={'registration'}
                />
              </FormControl>

              <div
                className={
                  formSubmitMsg
                    ? 'alert-success p-5 px-20 mr-10 font-14px responseMsg ' +
                      formSubmitMsg
                    : ''
                }
              >
                {formSubmitMsg}
              </div>

              <div
                className={
                  errorMsg
                    ? 'alert-danger p-5 px-10 my-10 font-12px responseMsg ' +
                      errorMsg
                    : ''
                }
              >
                {errorMsg}
              </div>

              {regMsg ? regMsgElement() : ''}

              <div className="registrationLoginBtn mt-10">
                <div className="inputContainer">
                  <input type="submit" className="formLoginBtn" value="" />
                  <span className="inputText" style={{ left: '43%' }}>
                    Try Now
                  </span>
                  <img
                    className="fastForwardIcon"
                    src={fastForward}
                    alt="forward"
                  />
                </div>
              </div>
              {/* <div className="registrationLoginBtn">
                <input type="submit" className="formLoginBtn" value="Try Now" /> */}
              {/* </div> */}
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  };

  useEffect(() => {
    formSubmitMsg &&
      counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1);
        if (counter === 1) {
          history.push(Routes.Login);
        }
      }, 1000);
  }, [counter, formSubmitMsg]);

  return (
    <Fragment>
      <div className="outerContiner scrollContainer soraFont">
        <SupportHeader />
        <div className="mainBgEllipse1" />
        <div className="mainBgEllipse2" />
        <div className="mainBgEllipse3" />
        {loader ? <LoadingScreen /> : ''}
        <div className="registrationBG">
          <div className="registrationContainer">
            <div className="leftLoginSection">
              <div className="ellipse" />

              <div className="testimonialCard">
                <div className="testimonial">
                  <p>
                    "CloudForestX has been a lifesaver for our team. We were
                    able to reduce our cloud bills by 20% without having to
                    compromise on service quality. It's an amazing tool."
                  </p>
                </div>
                <div className="profile">
                  {/* <img
                    className="profile-image"
                    src={sampleAvatar}
                    alt="Profile"
                  /> */}
                  <div className="profile-info">
                    <h3 className="testimonialName">Rajiv Jain</h3>
                    <p className="testimonialPost">CEO, ThinkSys</p>
                  </div>
                </div>
              </div>

              <Intersect className="intersect" />

              <div className="ellipse2" />
              <div className="ellipse3" />
              <div className="ellipse4" />
            </div>

            <div className="rightImage">
              <Grid container spacing={0} className="rightbody w-100">
                <Grid item md={12} className="loginReg">
                  {formSubmitMsg && (
                    <div>
                      <h3 className="d-middle-center text-center fw-500 text-capitalize pl-20 pr-20 text-dBlue">
                        Congratulation Registration Completed Successfully!
                      </h3>
                      <h6 className="fw-500 text-center mt-20 pl-20 pr-20">
                        You will be redirected to Login screen in
                        <br />
                        <span className="text-dBlue mt-10 d-inline-block">
                          <span className="font-25px">{counter}</span>
                          seconds
                        </span>
                      </h6>
                    </div>
                  )}

                  {!formSubmitMsg ? (
                    <div className="trialText">
                      <h5>
                        TRY 7 DAYS{' '}
                        <span style={{ color: '#00D2BF' }}>FREE</span> TRIAL
                      </h5>
                      <p>Please enter your details</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formSubmitMsg ? '' : registrationFirstForm()}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default React.memo(RegistrationLogin);
