import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles({
  footerRow: {
    fontSize: '1.3rem',
    '& .MuiTablePagination-root': { fontSize: '1.3rem' },
  },
  footerFont: {
    fontSize: '1.3rem',
  },
});
