import { tagDetailService } from '../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

export const config: any = {
  [CLOUD_PROVIDER_SERVICES.AWS]: {
    tagDetail: (params: any) =>
      tagDetailService.AwsTagDetailsByResource(params),
  },
  [CLOUD_PROVIDER_SERVICES.AZURE]: {
    tagDetail: (params: any) =>
      tagDetailService.AzureTagDetailsByResource(params),
  },
};

export const IGNORED_RESOURCE_COLUMN_FIELD: string[] = [
  'Currency_Symbol',
  'IsDisabled',
  'Subscription_Id',
  'Id',
  'Unit',
  'Currency_Code',
  'OrgId',
  'AccountId',
  'InstanceStorageAccountType',
  'CurrencySymbol',
  'DeleteOnTermination',
  'InstanceStatus',
  'UpperThreshold',
  'IsRecommendationAccepted',
  'OSType',
  'Savings',
  'LastEndTime',
  'LastEndTime',
  'ThresholdUpdatedTime',
  'IsResized',
  'VirtualizationType',
  'Hypervisor',
  'AutoScalingGroup',
  'InstanceLifecycle',
  'UpscaleTime',
  'InstancePricePerHour',
  'InstanceStatusTime',
  'InstanceMemory',
  'Days',
  'InstanceDiskId',
  'InstanceNetworkInterfaceId',
  'createdAt',
  'updatedAt',
  'CpuCores',
  'ThreadsPerCore',
  'AmiId',
  'Tenancy',
  'AvailabilityZone',
  'PrivateIp',
  'PublicIp',
  'PrivateDns',
  'SubnetId',
  'VpcId',
  'PublicDns',
  'MonthlyCost',
  'UnusedCreatedOn',
  'ResourceType',
  'VMSkuImage',
  'OSVersion',
  'ResourceId',
  'VMSkuImagePrice',
  'VMAverageUsage',
  'Encrypted',
  'Type',
  'StoragePricePerHour',
  'StorageOwner',
  'resource_name',
  'VMStatusTime',
  'VmPricePerHour',
  'CreatedBy',
  'UpdatedBy',
  'IpAddress',
  'ContainerPerDayCost',
];

export const searchConfig: {
  [key: string]: { placeholder: string; field: string };
} = {
  'Virtual Machines': {
    placeholder: 'Search by Virtual Machine Name',
    field: 'Virtual_Machine_Name',
  },
  'Storage Accounts': {
    placeholder: 'Search by Storage Name',
    field: 'Storage_Name',
  },
  'Managed Disks': {
    placeholder: 'Search by Disk Name',
    field: 'Disk_Name',
  },
  'SQL Server': {
    placeholder: 'Search by SQL Server Name',
    field: 'SQL_Server_Name',
  },
  'Public IP': {
    placeholder: 'Search by Public IP Name',
    field: 'Public_IP_Name',
  },
  WorkSpaces: {
    placeholder: 'Search by Workspace Name',
    field: 'Workspace_Name',
  },
  'App Service Plans': {
    placeholder: 'Search by App Name',
    field: 'App_Name',
  },
  'Container Instances': {
    placeholder: 'Search by Container Instance Name',
    field: 'Container_Instance_Name',
  },
  'Redis Cache': {
    placeholder: 'Search by Redis Cache Name',
    field: 'Redis_Cache_Name',
  },
  'HDInSight Cluster': {
    placeholder: 'Search by HD Cluster Name',
    field: 'HD_Cluster_Name',
  },
  'Service Bus': {
    placeholder: 'Search by Service Bus Name',
    field: 'Service_Bus_Name',
  },
  'Traffic Manager': {
    placeholder: 'Search by Traffic Manager Name',
    field: 'Traffic_Manager_Name',
  },
  'Resource Groups': {
    placeholder: 'Search by Resource Group Name',
    field: 'Resource_Group_Name',
  },
  'COSMOS DB': {
    placeholder: 'Search by Cosmos DB Name',
    field: 'Cosmos_DB_Name',
  },
  'Load Balancers': {
    placeholder: 'Search by Load Balancer Name',
    field: 'Load_Balancer_Name',
  },
  EC2: {
    placeholder: 'Search by Instance Name',
    field: 'Instance_Name',
  },
  S3: {
    placeholder: 'Search by S3 Bucket Name',
    field: 'S3_Bucket_Name',
  },
  EFS: {
    placeholder: 'Search by EFS Name',
    field: 'EFS_Name',
  },
  FSx: {
    placeholder: 'Search by FSx Name',
    field: 'FSx_Name',
  },
  'S3 Glacier': {
    placeholder: 'Search by S3 Glacier Name',
    field: 'S3_Glacier_Name',
  },
  ElastiCache: {
    placeholder: 'Search by CacheSubnetGroupName',
    field: 'CacheSubnetGroupName',
  },
  'Elastic Container Service Instance': {
    placeholder: 'Search by Container Instance',
    field: 'Container_Instance',
  },
  'Simple Queue Service': {
    placeholder: 'Search by SQS Name',
    field: 'SQS_Name',
  },
  'Elastic BeanStalk': {
    placeholder: 'Search by App Name',
    field: 'App_Name',
  },
  EBS: {
    placeholder: 'Search by Volume Id',
    field: 'Volume_Id',
  },
  'Elastic IP addresses': {
    placeholder: 'Search by Elastic IP Name',
    field: 'Elastic_IP_Name',
  },
  DynamoDB: {
    placeholder: 'Search by DynamoDB Name',
    field: 'DynamoDB_Name',
  },
  RDS: {
    placeholder: 'Search by RDS Name',
    field: 'RDS_Name',
  },
  EMR: {
    placeholder: 'Search by Cluster Id',
    field: 'Cluster_Id',
  },
  SNS: {
    placeholder: 'Search by SNS Name',
    field: 'SNS_Name',
  },
  'Global Accelerator': {
    placeholder: 'Search by Global Accelerator Name',
    field: 'Global_Accelerator_Name',
  },
  'AWS Load Balancers': {
    placeholder: 'Search by Load Balancer Name',
    field: 'Load_Balancer_Name',
  },
  'AWS Resource Groups': {
    placeholder: 'Search by Resource Group Name',
    field: 'Resource_Group_Name',
  },
};
