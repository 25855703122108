import React from 'react';

export const AzsIntroduction: React.FC = () => {
  return (
    <>
      <div className="cf-row">
        <div className="innerContainer12">
          <p className="mt-20 px-20">
            As organizations are rapidly moving their maximum resources to the
            cloud, cloud costs have become an integral, as well as a prominent
            part of IT budgets. This has created a need to spend money wisely
            while ensuring maximum benefits and quality resource management.
            This is where CloudForestX's Azure / AWS Cost Optimization steps in.
          </p>

          <p className="mt-30 px-20">
            CloudForestX is the easiest way to manage, optimize, and lower
            cloud costs for small, medium, and enterprise businesses
            globally. CloudForestX has a unique automated platform that
            optimizes the cloud spend per each cloud provider. This allows
            companies to save <b>25%</b> or more on their cloud spend.
          </p>

          <p className="mt-30 mb-20 px-20">
            So, leverage the advantages of Microsoft Azure / AWS, the most popular
            Cloud Computing Platform with CloudForestX, and unpack an enormous
            amount of potential for your organization. Effectively manage your
            cloud spending and focus on aspects that matter most to your
            business.
          </p>
        </div>
      </div>
    </>
  );
};

export default AzsIntroduction;
