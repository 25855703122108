import React from 'react';
import { CustomModal } from '../../components';
import { Typography } from '@material-ui/core';
import './AddSiteModal.css';
import { useCopyText } from '../../hooks/useCopyText';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Tooltip } from '@mui/material';
interface scriptModalProp {
  show: boolean;
  onClose?: { (): void };
  submitFunc?: any;
  buttonMsg?: string;
  headerMsg: string;
  scriptTag: string;
}
export const ScriptModal: React.FC<scriptModalProp> = (props) => {
  const { show, onClose, headerMsg, scriptTag } = props;
  const { copy, copyToClipboard, error } = useCopyText();

  return (
    <CustomModal
      show={show}
      onClose={onClose}
      headerMsg={headerMsg}
      isSubmitBtnDisabled={true}
    >
      <div className="addModalContent">
        <div>
          <Typography
            style={{ fontWeight: 600, marginBottom: '1rem' }}
            variant="subtitle1"
          >
            To enable data retrieval from your website, please include the
            following script just before the closing {'</body>'} tag.
            Alternatively, you can place this script in a common file, such as
            the header or footer, to ensure it loads on every page of your site.
          </Typography>
        </div>
        <Tooltip title={copy ? 'Copied!' : 'Click to Copy!'}>
          <div className="addScript" onClick={() => copyToClipboard(scriptTag)}>
            <Typography component="pre" variant="h6">
              {scriptTag}
            </Typography>
            {copy ? <CheckCircleOutlineIcon /> : <ContentCopyIcon />}
          </div>
        </Tooltip>
        {error && <div className="regMsg"> Copy text failed!</div>}
      </div>
    </CustomModal>
  );
};
