import { APIS } from '../../utils/apiConfig';
import { PLAN_TYPE } from '../../utils/constants';
import { HttpService } from '../HttpService/HttpService';

const handleResponse = (response: any) => {
  const { data } = response;

  if (data.sc == 1 || data.statusCode == 200) {
    const { result } = data;
    return result;
  }
};

export class BillingService {
  constructor(private httpService: HttpService) {}
  async createCheckout(
    stripe_customer_id: string,
    stripe_price_id: string,
    plan_name: string,
    PlanSubscriptionId: any,
    cancel_at_period_end: boolean,
    SubscriptionStatus: string
  ): Promise<any> {
    try {
      const isPlanAvailable = Object.values(PLAN_TYPE).includes(
        plan_name?.toUpperCase()
      );
      let response;
      if (
        isPlanAvailable &&
        SubscriptionStatus &&
        SubscriptionStatus !== 'trialing'
      ) {
        response = await this.httpService.post(APIS.CHANGE_SUBSCRIPTION(), {
          stripe_customer_id,
          stripe_price_id,
          PlanSubscriptionId,
          cancel_at_period_end,
        });
      } else {
        response = await this.httpService.post(APIS.NEW_SUBSCRIPTION(), {
          stripe_customer_id,
          stripe_price_id,
          cancel_at_period_end,
        });
      }

      return handleResponse(response);
    } catch (e) {
      throw e;
    }
  }
  async addCard(id: string): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.ADD_CARD(), {
        stripe_customer_id: id,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }
  async getCard(id: string): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.GET_CARD(), {
        stripe_customer_id: id,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async setAsDefault(params: {
    stripe_customer_id: string;
    PaymentMethodId: string;
  }): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.SET_AS_DEFAULT(),
        params
      );
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async deleteCard(PaymentMethodId: string): Promise<any> {
    try {
      const response = await this.httpService.delete(APIS.DELETE_CARD(), {
        PaymentMethodId,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }
  async getPlans(CompanyName: string): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.GET_PLAN_DETAILS(), {
        CompanyName,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }
  async cancleSubscription(PlanSubscriptionId: string): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.CANCEL_SUBSCRIPTION(), {
        PlanSubscriptionId,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async companyDetails(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.COMPANY_DETAILS(), {});
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async retryPayment(
    stripe_customer_id: string,
    PlanSubscriptionId: string
  ): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.RETRY_PAYMENT(), {
        stripe_customer_id,
        PlanSubscriptionId,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async requestQuote(CompanyName: string, PlanName: string): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.REQUEST_QUOTE(), {
        CompanyName,
        PlanName,
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  }
}
