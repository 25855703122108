import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import homePageImage1 from '../../../../assets/images/cfx-0.png';
import homePageImage2 from '../../../../assets/images/cfx-optimize.png';
import homePageImage3 from '../../../../assets/images/cfx-rightsizing.png';
import homePageImage4 from '../../../../assets/images/cfx-schedule.png';
import homePageImage5 from '../../../../assets/images/cfx-costA.png';
import homePageImage6 from '../../../../assets/images/cfx-painless.png';
import icon1 from '../../../../assets/images/cfx-icon-1.png';
import icon2 from '../../../../assets/images/cfx-icon-2.png';
import icon3 from '../../../../assets/images/cfx-icon-3.png';
import utilResource from '../../../../assets/images/utilResource.svg';
import { Button } from '@material-ui/core';
import '../Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import SupportHeader from '../../../../components/Header/SupportHeader';
import { Footer } from '../../../../components';
import { RoutesFun } from '../../../../schemas';

export const Home: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.search.split('=')[1];
  const Routes=RoutesFun();
  return (
    <React.Fragment>
      <div className="outerContiner scrollContainer">
        <SupportHeader />

        {/* banners */}
        <div className="home-banner cf-row p-0 flex-container homeBanner">
          <div className="innerContainer12 homeBImage">
            <div className="homeBannerText">
              <h1>Cost Analysis</h1>
              <p className="fw-300 mb-20">
                CloudForestX offers budget control, forecasting, spend
                analytics, and personalized recommendations. It helps you
                understand your Azure / AWS spend through easy-to-read charts and
                reports.
              </p>
              <Button
                variant="outlined"
                className="white white-border"
                onClick={() => history.push(Routes.Demo)}
              >
                Schedule a Demo
              </Button>
            </div>
            <div className="mb-5 flex-container flex-img d-none">
              <img className="flex-img " src={homePageImage1} width="100%" />
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
          <div className="innerContainer12 flex-container">
            <div className="contentImage">
              <img
                className="flex-img img-width"
                src={homePageImage3}
                width="100%"
              />
            </div>

            <div className="contentArea">
              <div>
                <h4>
                  <span className="red">RightSizing</span>
                  {/* <span>Solution for Enterprises</span> */}
                </h4>
                <p className="mt-20 mb-30">
                  We have developed a deep dataset of every resource type
                  available on Azure / AWS, and we analyze your resource usage to
                  recommend the exact resources that you need to minimize waste
                  and unused spend.
                  <span
                    className="cursor-pointer"
                    onClick={() => history.push(Routes.RightsizingDetail)}
                  >
                    <u>
                      <i>Learn more...</i>
                    </u>
                  </span>
                </p>
                <Button
                  variant="outlined"
                  className="red red-border"
                  onClick={() => history.push(Routes.RegistrationLogin)}
                >
                  Sign-up to know more
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
          <div className="innerContainer12 flex-container">
            <div className="contentImage">
              <img
                className="flex-img img-width"
                src={homePageImage4}
                width="100%"
              />
            </div>
            <div className="mb-5 contentArea">
              <div>
                <h4>
                  <span className="purple">Scheduling</span>
                  {/* <span>for Making Money</span> */}
                </h4>
                <p className="mt-20 mb-30">
                  We track usage patterns of your instances to identify zero
                  usage periods to recommend times you can turn off
                  non-production instances. Save money by turning off your QA
                  and Staging resources when you don’t need them.
                  <span
                    className="cursor-pointer"
                    onClick={() => history.push(Routes.SchedulingDetail)}
                  >
                    <u>
                      <i>Learn more...</i>
                    </u>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
          <div className="innerContainer12 flex-container">
            <div className="contentImage">
              <img
                className="flex-img img-width"
                src={homePageImage5}
                width="100%"
              />
            </div>
            <div className="mb-5 contentArea">
              <div>
                <h4>
                  <span className="red">Optimize your Cloud Spend</span>
                  {/* <span>for Making Money</span> */}
                </h4>
                <p className="mt-20 mb-30">
                  CloudForestX is a cloud intelligence platform for Microsoft
                  Azure / AWS. It provides actionable insights from your Azure / AWS cloud
                  operations so you can save money and optimize the performance
                  of your infrastructure.
                  <span
                    className="cursor-pointer"
                    onClick={() => history.push(Routes.AzureSpend)}
                  >
                    <u>
                      <i>Learn more...</i>
                    </u>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
          <div className="innerContainer12 flex-container">
            <div className="contentImage">
              <img
                className="flex-img img-width"
                src={homePageImage6}
                width="100%"
              />
            </div>
            <div className="mb-5 contentArea">
              <div>
                <h4>
                  <span className="purple">Quick and Painless</span>
                  {/* <span>for Making Money</span> */}
                </h4>
                <p className="mt-20 mb-30">
                  Our quick and easy onboarding will have you set up within 15
                  minutes.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* banners */}

        <div className="home-tile cf-row d-none">
          <div className="innerContainer12 flex-container">
            <div className="contentArea">
              <div>
                <h4>
                  <span className="purple">Optimize your Cloud Spend</span>
                  <br />
                  {/* <span>with Comprehensive Visibility</span> */}
                </h4>
                <p className="mt-20 mb-30">
                  CloudForestX is a cloud intelligence platform for Microsoft
                  Azure / AWS. It provides actionable insights from your Azure / AWS cloud
                  operations so you can save money and optimize the performance
                  of your infrastructure.
                  <span
                    className="cursor-pointer"
                    onClick={() => history.push(Routes.AzureSpend)}
                  >
                    <u>
                      <i>Learn more...</i>
                    </u>
                  </span>
                </p>
                <Button
                  variant="outlined"
                  className="purple purple-border"
                  onClick={() => history.push('/book-demo')}
                >
                  Get a Demo
                </Button>
              </div>
            </div>
            <div className="contentImage">
              <img
                className="flex-img img-width"
                src={homePageImage2}
                width="100%"
              />
            </div>
          </div>
        </div>

        <div className="innerContainer12 p-20 flex-container pKeyPointsCont d-none">
          <div className="paper pKeyPoints purple-border m-10 border">
            <div>
              <img src={icon1} className="icon-width my-10" />
              <h5 className="mt-5 fw-500"></h5>
              <p className="my-15">
                Get a detailed breakdown of your Azure / AWS spend so you can make
                sense of your unreadable bill and know exactly where your money
                is going.
              </p>
            </div>
          </div>
          <div className="paper pKeyPoints blue-border m-10 border">
            <div>
              <img src={icon2} className="icon-width my-10" />
              <h5 className="mt-5 fw-500">Quality Management of Assets</h5>
              <p className="my-15">
                With CloudForestX's cross-account dashboard, get real-time,
                quality reporting for enterprise-wide inventory, with functions,
                tags, geography, etc., and effectively modify workloads.
              </p>
            </div>
          </div>
          <div className="paper pKeyPoints orange-border m-10 border">
            <div>
              <img src={icon3} className="icon-width my-10" />
              <h5 className="mt-5 fw-500">Quick and Painless</h5>
              <p className="my-15">
                Our quick and easy onboarding will have you set up within 15
                minutes.
              </p>
            </div>
          </div>
          <div className="paper pKeyPoints dBlue-border m-10 border">
            <div>
              <img src={utilResource} className="my-10" width="50" />
              <h5 className="mt-5 fw-500">
                Effective Utilization of Resources
              </h5>
              <p className="my-15">
                To help users make most of the available resources, CloudForestX
                CMx offers concise and comprehensive resource usage statistics
                across AWS, Azure, and Google Cloud.
              </p>
            </div>
          </div>
          <div className="paper pKeyPoints green-border m-10 border">
            <div>
              <FontAwesomeIcon
                icon={faFileMedicalAlt}
                className="icon-width my-15 font-40px"
              />
              {/* <img src={icon3} className="icon-width my-10"/> */}
              <h5 className="mt-5 fw-500">Automated Self-Healing</h5>
              <p className="my-15">
                Now ensure improved operational efficiency with CloudForestX
                CMx's automated cloud functions and effectively save your time,
                money, and efforts.
              </p>
            </div>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
