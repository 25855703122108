import { userActions } from './userSlice';
import { accountActions } from './accountSlice';
import { accountService, userService } from '../services';
import { CLOUD_PROVIDER_SERVICES } from '../utils/constants';
import { ALL_VALUE } from '../pages/Invoice/config';

export const fetchUserData = () => {
  return async (dispatch: any) => {
    const fetchUserData = async () => {
      const response = await userService.getUserDetails();
      if (!response) {
        throw new Error('Unable to fetch data');
      }
      return response;
    };

    const fetchAccountData = async () => {
      const response = accountService.getSelectedAccount();
      if (!response) {
        throw new Error('Unable to fetch data');
      }
      return response;
    };

    try {
      const userData = await fetchUserData();
      let accountData = await fetchAccountData();

      dispatch(
        userActions.replaceUserState({
          userData: userData,
          isUserSelected: true,
        })
      );

      if (!accountData) {
        // localStorage.setItem(storageKeys.ACCOUNTS_PAGE_NO, value);
        accountData = { CloudProvider: CLOUD_PROVIDER_SERVICES.AWS };
        dispatch(
          accountActions.replaceAccountState({
            selectedAccount: accountData,
            isAccountSelected: false,
            selectedAccountName: ALL_VALUE,
            selectedSubscriptionName: ALL_VALUE,
            selectedAccountId: ALL_VALUE,
            selectedSubscriptionId: ALL_VALUE,
            selectedSubData: [],
          })
        );
      } else {
        dispatch(
          accountActions.replaceAccountState({
            selectedAccount: accountData,
            isAccountSelected: true,
            selectedAccountName: ALL_VALUE,
            selectedSubscriptionName: ALL_VALUE,
            selectedAccountId: ALL_VALUE,
            selectedSubscriptionId: ALL_VALUE,
            selectedSubData: [],
          })
        );
      }
    } catch (error) {
      // console.log(error);
    }
  };
};
