import React, { Fragment, useEffect } from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';

import {
  accountService,
  authService,
  httpService,
  // toasterService,
  userService,
} from '../../../services';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { DashboardHeader } from '../../../components/Header/DashboardHeader';
import useStyles from './ConfigureAccount.style';
import helperStyles from '../../../styles/helper.styles';
import {
  AccountDetailsProps,
  AccountFieldProps,
} from '../../../schemas/account.schema';
import { ConfigureCheckModal } from '../../../components/Modals/ConfigureCheckModal/ConfigureCheckModal';
import { RoutesFun } from '../../../schemas';
import { handleError } from '../../../utils/config';

interface LOGINSCHEMA {
  EmailAddress: 'string';
  Password: 'string';
}

interface ConfigureNextProps {
  childern?: React.ReactNode;
  location?: {
    state?: {
      Name: string;
      CloudProvider: string;
      Location: string;
      PayeeOrganisation: string;
      Attributes: string;
    };
  };
}
export const ConfigureAccountNextPage: React.FC<ConfigureNextProps> = (
  props
) => {
  const Routes=RoutesFun();
  const classes = useStyles();
  const helperClasses = helperStyles();
  const history = useHistory();
  const [accountType, setAccountType] = React.useState<string[]>([]);
  const [isNetworkError, setNetworkError] = React.useState(false);

  let state: any;
  if (props.location && props.location.state) {
    state = props.location.state;
  }
  const [accountDetails, setAccountDetails] = React.useState<AccountFieldProps>(
    {
      ...state,
      ApplicationId: '',
      ClientSecret: '',
      SubscriptionId: '',
      AzureAccountType: '',
    }
  );

  const {
    register,
    handleSubmit,
    control,
    formState,
    errors,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  const getAllAccountTypes = async () => {
    const result = await accountService.getAccountTypes();
    setAccountType(result);
  };

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        const response = await accountService.createAccount(accountDetails);
        // toasterService.show(response, 'success');
        history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  useEffect(() => {
    getAllAccountTypes();
  }, []);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <Fragment>
      <DashboardHeader />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0} className={classes.outerContainer}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.accordionHeader}
          >
            CONFIGURE ACCOUNT
            <button type="button" className="helpBtn">
              Need Help <FontAwesomeIcon icon={faQuestionCircle} />
            </button>
          </Grid>
          <Grid container spacing={0} className={classes.innerContainer}>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={6} className={helperClasses.p0}>
                  <li>Click Register.</li>
                  <li>Copy and paste the Application ID here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  APPLICATION ID
                </InputLabel>
                <Input
                  id="ApplicationId1"
                  type="password"
                  name="ApplicationId"
                  inputRef={register({ required: true })}
                  value={accountDetails.ApplicationId}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.ApplicationId &&
                    accountDetails.ApplicationId.length === 0 &&
                    'ApplicationId is required'}
                </span>
              </FormControl>
              <span></span>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={8} className={helperClasses.p0}>
                  <li>
                    In the Manage Section of the Application blade, click
                    Certificates and secrets.
                  </li>
                  <li>Under Client Secrets, click New Client Secret.</li>
                  <li>
                    Type a name for the client secret, select which you want it
                    to expire and click Add
                  </li>
                  <li>Copy and paste the value of client secret here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  CLIENT SECRET ID
                </InputLabel>
                <Input
                  id="demo-mutiple-name"
                  type="password"
                  name="ClientSecret"
                  inputRef={register({ required: true })}
                  value={accountDetails.ClientSecret}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.ClientSecret &&
                    accountDetails.ClientSecret.length === 0 &&
                    'ClientSecret is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={12} className={helperClasses.p0}>
                  <li>
                    From the left navbar. click all Services and select
                    Subscriptions.
                  </li>
                  <li>
                    From the list, select the subscriptions you want to monitor.
                  </li>
                  <li>
                    Type a name for the client secret, select which you want it
                    to expire and click Add
                  </li>
                  <li>Copy and paste the value of Subscription ID here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  MICROSOST AZURE SUBSCRIPTION ID
                </InputLabel>
                <Input
                  id="SubscriptionId"
                  type="password"
                  name="SubscriptionId"
                  value={accountDetails.SubscriptionId}
                  inputRef={register({ required: true })}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.SubscriptionId &&
                    accountDetails.SubscriptionId.length === 0 &&
                    'SubscriptionId is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={15} className={helperClasses.p0}>
                  <li>
                    Select Access Control (IAM) and add a new Role Assignment.
                  </li>
                  <li>From the Role dropdown list, select Reader.</li>
                  <li>
                    In the select text field , type the name of the application
                    that you created and save.
                  </li>
                  <li>Select the Azure Account Type:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="AzureAccountType">
                  AZURE ACCOUNT TYPE
                  <span className={helperClasses.styleFont}>
                    Clear this field to move account to the root
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="AzureAccountType"
                  value={accountDetails.AzureAccountType}
                  onChange={handleChange}
                >
                  {accountType.map((item: any) => (
                    <MenuItem key={item.Id} value={item.Type}>
                      {item.Type}
                    </MenuItem>
                  ))}
                </Select>
                <span className={classes.warningMessage}>
                  {/* { errors?.AzureAccountType &&
										'AzureAccountType is required'} */}
                  {formState.touched.AzureAccountType &&
                    accountDetails.AzureAccountType.length === 0 &&
                    'AzureAccountType is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12} className={classes.footer}>
              <Button type="submit" className="yesBtn">
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};
