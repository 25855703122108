import { APIS } from '../../../utils/apiConfig';
import { HttpService } from '../../HttpService/HttpService';

export class AllOrganizationsService {
  constructor(private httpService: HttpService) {}

  async updateOrganisationStatus(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.UPDATE_ORGANISATON_STATUS(),
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async deleteOrganization(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.DELETE_ORGANISATON(),
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return response;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
}
