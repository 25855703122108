import { HttpService } from '../HttpService/HttpService';

export class SecurityScanService {
  constructor(private httpService: HttpService) {}

  async FetchAWSScanReport(params: any): Promise<any> {
    try {
      const response = await this.httpService.post('/aws/scan/report', params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async FetchAzureScanReport(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/azure/scan/report',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async ScanNow(params: any) {
    try {
      const response = await this.httpService.post('/aws/scan/create', params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
