import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  TablePagination,
  Tooltip,
} from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './UsersList.css';
import { useHistory } from 'react-router-dom';
import { userService } from '../../services';
import { useForm } from 'react-hook-form';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import 'react-datepicker/dist/react-datepicker.css';

import useStyles from './UsersList.style';

const planTypeMapper: any = {
  'Free Trial': '1',
  'Basic Plan': '2',
  'Premium Plan': '3',
};

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../store/index';
import { Checkbox, IconButton } from '@mui/material';
// import { NotificationManagement } from '../../components/Modals/NotificationManagementModal/NotificationManagementModal';
import { DeleteOutline } from '@mui/icons-material';
import { handleError } from '../../utils/config';

interface UDetail {
  PlanDate?: string | undefined;
  Id?: number;
  IsVerified?: number;
  PlanType?: string;
  RoleId?: number;
}

const storageKeys = {
  USERS_LIST_PAGE_NO: 'USERS_LIST_PAGE_NO',
  USERS_LIST_ROWS_PER_PAGE: 'USERS_LIST_ROWS_PER_PAGE',
};

const getUsersListPageNo = (): number => {
  const pageNo = localStorage.getItem(storageKeys.USERS_LIST_PAGE_NO) || 0;
  return +pageNo;
};

const setUsersListPageNo = (value: string): void => {
  localStorage.setItem(storageKeys.USERS_LIST_PAGE_NO, value);
};

const getUsersListRowsPerPage = (): number => {
  const rowsPerPage =
    localStorage.getItem(storageKeys.USERS_LIST_ROWS_PER_PAGE) || 10;
  return +rowsPerPage;
};

const setUsersListRowsPerPage = (value: string): void => {
  localStorage.setItem(storageKeys.USERS_LIST_ROWS_PER_PAGE, value);
};

export const UsersList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const mydate = new Date();
  const [openNotificationModal, setOpenNotificationModal] = React.useState(
    false
  );
  const [startDate, setStartDate] = React.useState<Date | null>(mydate);
  const [pDetails, setpDetails] = React.useState<UDetail>();
  const [pID, setpID] = React.useState<number>();
  const [pIsVerified, setpIsVerified] = React.useState<number>();
  const [pPlanType, setpPlanType] = React.useState<string>();
  const [pRoleId, setpRoleId] = React.useState<number>();
  const [loader, setLoader] = React.useState(false);
  const [uData, setUdata] = React.useState('');

  // const { user, selectedAccount } = useUserData();

  const user = useSelector(userState).userData;

  const [successMsg, setSuccessMsg] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [usersData, setUsersData] = React.useState<Record<string, unknown>[]>(
    []
  );
  const [usersMasterData, setUsersMasterData] = React.useState<
    Record<string, unknown>[]
  >([]);
  const [page, setPage] = React.useState(getUsersListPageNo);
  const [rowsPerPage, setRowsPerPage] = React.useState(getUsersListRowsPerPage);
  const { handleSubmit } = useForm({
    mode: 'onChange',
  });

  const [isDeleted, setIsDeleted] = useState(false);

  const [networkError, setNetworkError] = React.useState('');
  const [checkedId, setCheckedId] = React.useState<number[]>([]);
  const [checkedUserData, setCheckedUserData] = React.useState<any[]>([]);
  const [onDelete, setOnDelete] = useState(false);
  const searchRef = useRef(null);
  const isAdmin = user?.RoleId === 2;
  const selectedUserData = () => {
    // const checkedUser = usersData.filter((user: any) =>
    //   checkedId.includes(user.Id)
    // );
    setCheckedUserData(usersData);
    // setCheckedUserData(checkedUser);
  };
  // const checkRemainingDays = () => {
  //   alert(
  //     "Your Trial Period is over. Please contact support team at 'support@cloudforestx.com'."
  //   );
  //   history.push('/login');
  // };
  const closeCheckModal = () => {
    setOpenNotificationModal(false);
  };
  const emptyCheckedUsersOnSubmit = () => {
    setExpanded(false);
    const currentRef = searchRef.current ? searchRef.current : { value: '' };
    if (currentRef !== null) {
      currentRef.value = '';
    }
    setCheckedId([]);
  };
  const accordionHandleChange = (
    panel: string,
    pDate: any,
    pID: number,
    pIsVerified: number,
    pPlanType: string,
    pRoleId: number
  ) => (
    event: React.ChangeEvent<Record<string, unknown>>,
    newExpanded: boolean
  ) => {
    setStartDate(pDate.toISOString().split('T')[0]);
    setpID(pID);
    setpIsVerified(pIsVerified);
    setpPlanType(pPlanType);
    setpRoleId(pRoleId);
    setpDetails({
      PlanDate: pDate.toISOString().split('T')[0],
      Id: pID,
      IsVerified: pIsVerified,
      PlanType: pPlanType,
      RoleId: pRoleId,
    });

    setExpanded(newExpanded ? panel : false);
  };

  const updateUserObject = (userData: any) => {
    let userObjIndex = -1;
    const userObj = usersData.find(({ Id }, index) => {
      if (Id === userData.Id) {
        userObjIndex = index;
        return true;
      } else return false;
    });
    usersData[userObjIndex] = { ...userObj, ...userData };
    setUsersData((record) => [...record]);
  };

  const deleteUser = async (user: any) => {
    // setExpanded(false);
    try {
      setLoader(true);
      setIsDeleted(true);
      const ids: number[] = [];
      ids.push(user.Id);
      const data = {
        userIds: ids,
      };
      const confirmation = confirm(
        `Are you sure you want to delete user ${user.FirstName} ${user.LastName}`
      );
      if (confirmation) {
        const response = await userService.deleteUser(data);
        if (response) {
          setLoader(false);
          setIsDeleted(false);
        }
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
      setIsDeleted(false);
    }
  };

  const getAllUsersData = async () => {
    setExpanded(false);
    try {
      setLoader(true);
      const rows = await userService.getALLUserDetails();
      if (rows) {
        const sortedData = rows.sort((a: any, b: any) => {
          return a.RoleId - b.RoleId;
        });
        // const excludeCurrentUser = sortedData.filter((u: any) => {
        //   return u.EmailAddress !== user?.EmailAddress;
        // });
        if (user?.RoleId === 2) {
          const organizationUsers = sortedData.filter(
            (u: any) =>
              u.CompanyName.toLowerCase() ===
              user.CompanyName.toLocaleLowerCase()
          );
          setUsersData(organizationUsers);
          setUsersMasterData(organizationUsers);
        } else {
          setUsersData(sortedData);
          setUsersMasterData(sortedData);
        }
      }
      setLoader(false);
    } catch (e: any) {
      if (e.status !== 403) {
        setLoader(false);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      setNetworkError(e === 'Network Error' ? e : '');
    } finally {
      // if (user) {
      //   user?.RemainingDays === undefined ||
      //   user?.RemainingDays === 0 ||
      //   user?.RemainingDays < 0
      //     ? checkRemainingDays()
      //     : '';
      // }
    }
  };

  const searchValue = (target: any) => {
    setUdata(target.value);
  };

  const searchUser = () => {
    setLoader(true);
    setExpanded(false);
    try {
      const filteredData = usersMasterData.filter((user: any) => {
        const value = uData.toLowerCase();
        return user.EmailAddress.search(value) != -1;
      });
      setUsersData(filteredData);
      handleChangePage(null, 0);
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      setNetworkError(e === 'Network Error' ? e : '');
    } finally {
      setLoader(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      searchUser();
    }
  };

  const onSubmit = async (data: any, event: any) => {
    setLoader(true);
    const updateUserData = {
      Id: +event.target.Id.value,
      PlanDate: event.target.PlanDate.value,
      IsVerified: +event.target.IsVerified.value,
      PlanType: parseInt(event.target.PlanType.value),
      RoleId: event.target.RoleId.value,
    };
    // if(updateUserData.RoleId === 1){}

    try {
      const result = await userService.patchUserDetailData(updateUserData);
      if (result) {
        setErrorMsg('');
        setSuccessMsg(result.message);
        updateUserObject(updateUserData);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
    } finally {
      setLoader(false);
      setTimeout(() => {
        setSuccessMsg('');
        setErrorMsg('');
      }, 2000);
    }
  };

  const onVerifiedChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    if ((name === 'PlanDate' && value !== '') || name !== 'PlanDate')
      setpDetails({ ...pDetails, [name]: value });
  };

  const refreshData = () => {
    setExpanded(false);
    const currentRef = searchRef.current ? searchRef.current : { value: '' };
    if (currentRef !== null) {
      currentRef.value = '';
    }
    setCheckedId([]);
    setCheckedUserData([]);
    getAllUsersData();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (usersMasterData.length > 0) {
      setUsersListRowsPerPage(event.target.value);
      setRowsPerPage(parseInt(event.target.value));
    } else {
      setUsersListRowsPerPage(String(5));
      setRowsPerPage(5);
    }
    setUsersListPageNo(String(0));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setExpanded(false);
    setUsersListPageNo(String(newPage));
    setPage(newPage);
  };
  const roleId = user?.RoleId || 0;
  const isDisabled = (role: any) => roleId > role;

  useEffect(() => {
    getAllUsersData();
  }, []);

  useEffect(() => {
    getAllUsersData();
  }, [isDeleted]);

  if (networkError) {
    throw new Error(networkError);
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedUserID = +event.target.value;
    const Index = checkedId.indexOf(checkedUserID);
    if (Index < 0) {
      setCheckedId((preValue: any) => [...preValue, checkedUserID]);
    } else {
      const filterdList = checkedId.filter((Id) => {
        return Id !== checkedUserID;
      });
      setCheckedId(filterdList);
    }
  };

  return (
    <Fragment>
      {loader ? <LoadingScreen /> : ''}
      <div className="outerContainer max-h100vh usersListSection">
        <div className="">
          <div>
            <div className="topSearchRefBar">
              <div className="refreshBtn p-10" onClick={refreshData}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </span>
              </div>

              {/* <button
                className={
                  checkedId.length > 0 ? 'btn-primary btn ml-15 mt-2' : 'd-none'
                }
                onClick={() => {
                  selectedUserData(), setOpenNotificationModal(true);
                }}
              >
                Edit
              </button> */}
              <form
                noValidate
                onSubmit={handleSubmit(searchUser)}
                autoComplete="off"
                className="w-100"
              >
                <div className="searchBar">
                  <TextField
                    label="SEARCH BY EMAIL"
                    id="filled-size-small"
                    inputRef={searchRef}
                    defaultValue={uData}
                    variant="outlined"
                    size="small"
                    onChange={(e) => searchValue(e.target)}
                    onKeyDown={handleKeyDown}
                    className="w-55"
                  />
                  <input
                    type="submit"
                    value="Search"
                    className="btn-primary btn ml-10"
                  />
                </div>
              </form>
            </div>

            <div className="userListCont">
              {usersData &&
                (rowsPerPage > 0
                  ? usersData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : usersData
                ).map((uDetail: any, i: any) => {
                  const upDate =
                    uDetail.PlanDate != null
                      ? new Date(uDetail.PlanDate)
                      : new Date();
                  const upID = uDetail.Id;
                  const upIsVerified = uDetail.IsVerified;
                  const upPlanType = uDetail.PlanType;
                  const upRoleId = uDetail.RoleId;
                  if (upRoleId !== 1) {
                    return (
                      <Fragment key={i}>
                        <div className="outerCont">
                          {/* <span className="pt-3">
                            <Checkbox
                              value={uDetail.Id}
                              checked={checkedId.includes(uDetail.Id)}
                              onChange={handleChange}
                              sx={{
                                '& .MuiSvgIcon-root': { fontSize: '1.8rem' },
                              }}
                            />
                          </span> */}
                          <span className="w-100">
                            <Accordion
                              className="uAccCont"
                              expanded={expanded === 'panel' + i}
                              onChange={accordionHandleChange(
                                'panel' + i,
                                upDate,
                                upID,
                                upIsVerified,
                                upPlanType,
                                upRoleId
                              )}
                              key={'acc' + i}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <FontAwesomeIcon icon={faArrowDown} />
                                }
                                aria-controls={'panel1a-content' + i}
                                id={'panel1a-header' + i}
                                className="uACC"
                              >
                                <form
                                  className="ulRow"
                                  id={'ulRow' + uDetail.Id}
                                >
                                  <div className="uShortDetail w-100 ">
                                    <div className="uShortDetail">
                                      <div className="acID">
                                        <span className="fw-300">#</span>
                                        {uDetail.Id}
                                      </div>
                                      <div className="uName">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                        </svg>
                                        <Tooltip
                                          title={
                                            uDetail.FirstName +
                                            ' ' +
                                            uDetail.LastName
                                          }
                                          arrow
                                        >
                                          <span className="ml-5">
                                            {uDetail.FirstName +
                                              ' ' +
                                              uDetail.LastName}
                                          </span>
                                        </Tooltip>
                                      </div>

                                      <div className="uEmail">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-envelope"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                                        </svg>
                                        <span className="ml-5">
                                          {uDetail.EmailAddress}
                                        </span>
                                      </div>
                                      <div className="uCompany">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-building"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                                          />
                                          <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                                        </svg>
                                        <Tooltip
                                          title={uDetail.CompanyName}
                                          arrow
                                        >
                                          <span className="text-ellipsis ml-5">
                                            {uDetail.CompanyName}
                                          </span>
                                        </Tooltip>
                                      </div>
                                      <div
                                        className="uStatus"
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          width: '158px',
                                        }}
                                      >
                                        {uDetail.IsActive ? (
                                          <span className="alert-success">
                                            Active
                                          </span>
                                        ) : (
                                          <span className="alert-danger">
                                            InActive
                                          </span>
                                        )}
                                        {uDetail.IsVerified ? (
                                          <span className="alert-success">
                                            Verified
                                          </span>
                                        ) : (
                                          <span
                                            className="alert-danger"
                                            style={{ width: '75px' }}
                                          >
                                            Unverified
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {uDetail.RoleId === 2 && (
                                      <div className="uStatus ">
                                        <span className="br-3 p-3 color-light adminTag">
                                          Admin
                                        </span>
                                      </div>
                                    )}
                                    {uDetail.RoleId !== 2 && (
                                      <IconButton
                                        color="inherit"
                                        className="mr-10"
                                        onClick={(event: any) => {
                                          deleteUser(uDetail),
                                            event.stopPropagation();
                                        }}
                                      >
                                        <DeleteOutline fontSize="large" />
                                      </IconButton>
                                    )}
                                  </div>
                                </form>
                              </AccordionSummary>
                              <AccordionDetails>
                                <LazyLoad>
                                  {successMsg ? (
                                    <div className="successMsg w-100 text-center font-12px">
                                      {successMsg}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {pDetails && (
                                    <form
                                      noValidate
                                      onSubmit={handleSubmit(onSubmit)}
                                      autoComplete="off"
                                      className="w-100"
                                    >
                                      <div className="UlDetails">
                                        <div className="ulDetailForm">
                                          <span className="d-none">
                                            <input
                                              type="text"
                                              defaultValue={pDetails?.Id}
                                              name="Id"
                                            />
                                          </span>

                                          <span>
                                            <label>Account Verified</label>
                                            <select
                                              className={
                                                isDisabled(pDetails?.RoleId)
                                                  ? 'notAllowedCursor'
                                                  : ''
                                              }
                                              value={pDetails?.IsVerified}
                                              name="IsVerified"
                                              onChange={onVerifiedChange}
                                              disabled={isDisabled(
                                                pDetails?.RoleId
                                              )}
                                            >
                                              <option value="1">Yes</option>
                                              <option value="0">No</option>
                                            </select>
                                          </span>

                                          <span>
                                            <label>Plan Type</label>
                                            <select
                                              className={
                                                isAdmin
                                                  ? 'notAllowedCursor'
                                                  : ''
                                              }
                                              value={
                                                planTypeMapper[
                                                  pDetails?.PlanType ||
                                                    'Free Trial'
                                                ]
                                              }
                                              disabled={isAdmin}
                                              name="PlanType"
                                              onChange={onVerifiedChange}
                                            >
                                              <option value="1">
                                                Free Trial
                                              </option>
                                              <option value="2">
                                                Basic Plan
                                              </option>
                                              <option value="3">
                                                Premium Plan
                                              </option>
                                            </select>
                                          </span>

                                          <span>
                                            <label>Role Type</label>
                                            <select
                                              value={pDetails?.RoleId}
                                              className={
                                                isDisabled(pDetails?.RoleId)
                                                  ? 'notAllowedCursor'
                                                  : ''
                                              }
                                              name="RoleId"
                                              onChange={onVerifiedChange}
                                              disabled={isDisabled(
                                                pDetails?.RoleId
                                              )}
                                            >
                                              {/* {(uDetail.CompanyName?.toLowerCase() === 'thinksys') && !isAdmin ? () : ''} */}
                                              {/* {uDetail.CompanyName?.toLowerCase() ===
                                                'thinksys' && (
                                                <option
                                                  value="1"
                                                  disabled={user?.RoleId !== 1}
                                                >
                                                  Super Admin
                                                </option>
                                              )} */}
                                              <option value="2">Admin</option>
                                              <option value="3">
                                                Employee
                                              </option>
                                              <option value="4">QA</option>
                                              {/* {roles.map((role: any) => <option value={role.id}>{role.displayName}</option>)} */}
                                            </select>
                                          </span>

                                          <span>
                                            <label>Plan Start Date</label>
                                            <input
                                              className={
                                                isAdmin
                                                  ? 'notAllowedCursor'
                                                  : ''
                                              }
                                              disabled={isAdmin}
                                              type="date"
                                              value={pDetails?.PlanDate}
                                              name="PlanDate"
                                              required
                                              onChange={onVerifiedChange}
                                            />
                                          </span>
                                        </div>

                                        <div className="ulDetailBtn">
                                          <Tooltip
                                            title={
                                              isDisabled(pDetails?.RoleId)
                                                ? "You don't have permission to perform this action"
                                                : ''
                                            }
                                            arrow
                                          >
                                            <span>
                                              <input
                                                type="submit"
                                                value="Save"
                                                className="btn-primary btn"
                                                disabled={isDisabled(
                                                  pDetails?.RoleId
                                                )}
                                              />
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </form>
                                  )}
                                </LazyLoad>
                              </AccordionDetails>
                            </Accordion>
                          </span>
                        </div>
                      </Fragment>
                    );
                  } else null;
                })}

              {loader ? (
                ''
              ) : usersData.length === 0 ? (
                <div className="noRecordsFound">No Records Found</div>
              ) : (
                <table width="100%">
                  <tbody>
                    <tr>
                      <TablePagination
                        className={classes.pagination}
                        rowsPerPageOptions={[10, 25, 100, 125]}
                        count={usersData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {openNotificationModal && (
        <NotificationManagement
          checkedUserData={checkedUserData}
          onClose={closeCheckModal}
          getAllUsersData={getAllUsersData}
          emptyCheckedUsersOnSubmit={emptyCheckedUsersOnSubmit}
        />
      )} */}
    </Fragment>
  );
};

export default UsersList;
