import './App.css';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Router } from './Router';
import { CombinedContextProvider } from './context';
import ErrorBoundary from './ErrorBoundary';
import { useDispatch } from 'react-redux';
import { fetchUserData } from './store/thunks';
import { authService } from './services';

export const App: React.FC = () => {
  const dispatch = useDispatch();

  const auth = authService.checkAuth();
  useEffect(() => {
    if (authService.checkAuth()) {
      dispatch(fetchUserData());
    }
  }, []);

  // <LoadingProvider>
  return (
    <ErrorBoundary>
      <ReactTooltip />
      <CombinedContextProvider>
        <Router />
      </CombinedContextProvider>
    </ErrorBoundary>
  );
};

export default App;
