import { APIS } from '../../../utils/apiConfig';
import { HttpService } from '../../HttpService/HttpService';

export class OrganizationDetailsService {
  constructor(private httpService: HttpService) {}

  async getUsers(company: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ORGANISATION_USERS(company),
        {}
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async getAccountDetails(company: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ACCOUNTS_DETAILS(company),
        {}
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async updateAccountDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.UPDATE_ACCOUNT_DETAILS(),
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async deleteAccountDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.delete(
        APIS.DELETE_ACCOUNT_DETAILS(),
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
}
