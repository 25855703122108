import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
  Tabs,
  Tab,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountService,
  authService,
  httpService,
  userService,
} from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import CPVForm from './CPVForm';
import UserCredentialForm from './UserCredentialForm';
import { handleError } from '../../../utils/config';

const CSPForm = (props: any) => {
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'EA',
  });

  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });
  const [value, setValue] = React.useState(0);
  const [isNetworkError, setNetworkError] = React.useState(false);

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        // const response = await accountService.createAccount(accountDetails);
        // // toasterService.show(response, 'success');
        // history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    setValue(value);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div>
      <Grid item md={12} sm={12} xs={12} className={classes.headerGrid}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Control Panel Vendors (CPV)" value={0} />
          <Tab label="Use App & User Credentials" value={1} />
        </Tabs>
      </Grid>
      {value === 0 ? (
        <CPVForm
          register={register}
          accountDetails={props.accountDetails}
          handleTypeChange={props.handleTypeChange}
          cancel={props.cancel}
        />
      ) : (
        <UserCredentialForm
          register={register}
          accountDetails={props.accountDetails}
          handleTypeChange={props.handleTypeChange}
          cancel={props.cancel}
        />
      )}
    </div>
  );
};

export default CSPForm;
