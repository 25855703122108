import React, { useEffect } from 'react';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas';
import AzsIntroduction from './AzsIntroduction';
import AzsCostOptimization from './AzsCostOptimization';
import AzsContactUs from './AzsContactUs';

export const AzureSpend: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const Routes= RoutesFun();
  return (
    <React.Fragment>
      <div className="outerContiner scrollContainer">
        <SupportHeader />

        <div className="home-banner cf-row p-0 flex-container homeBanner azureDetail-banner">
          <div className="innerContainer12 azureSpendImage">
            <div className=" azureSpendText">
              <h1>Optimize your Cloud Spend</h1>
              <p className="fw-300 mb-20">
                Reduced Cost. Hassle-Free Management. Minimized Waste.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="home-banner cf-row p-0 flex-container homeBanner">
          <div
            className="supportBanner eula"
            style={{
              backgroundImage: `url(${topBanner})`,
            }}
          >
            <div className="innerContainer12">
              <h1>​​Optimize your Azure Spend</h1>
              <p className="mt-5">
                <i>Reduced Cost. Hassle-Free Management. Minimized Waste.</i>
              </p>
            </div>
          </div>
        </div> */}
        <AzsIntroduction />
        <AzsCostOptimization />
        <AzsContactUs />

        <div className="supportContent">
          <div className="p-0">
            {/* <div className="cf-row">
              <div className="innerContainer12 pl-15 pr-15">
                <p className="mt-10">
                  As organizations are rapidly moving their maximum resources to
                  the cloud, cloud costs have become an integral, as well as a
                  prominent part of IT budgets. This has created a need to spend
                  money wisely while ensuring maximum benefits and quality
                  resource management. This is where CloudForestX's Azure Cost
                  Optimization steps in.
                </p>

                <p className="mt-20">
                  CloudForestX is the easiest way to manage, optimize, and lower
                  cloud costs for small, medium, and enterprise businesses
                  globally. CloudForestX has a unique automated platform that
                  optimizes the cloud spend per each cloud provider. This allows
                  companies to save <b>25%</b> or more on their cloud spend.
                </p>

                <p className="mt-20">
                  So, leverage the advantages of Microsoft Azure, the most
                  popular Cloud Computing Platform with CloudForestX, and unpack
                  an enormous amount of potential for your organization.
                  Effectively manage your cloud spending and focus on aspects
                  that matter most to your business.
                </p>
              </div>
            </div> */}

            {/* <div className="cf-row">
              <div
                className="innerContainer12 pl-15 pr-15"
                style={{
                  backgroundImage: `url(${costOptimization})`,
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <h5 className="text-center mt-20 font-25px">
                  CloudForestX &amp; Azure Cost Optimization
                </h5>
                <p className="text-center">
                  <i>Maximizing Cloud Potential &amp; Fueling Innovation.</i>
                </p>

                <p className="mt-20">
                  CloudForestX leverages its years of Microsoft Azure expertise
                  and knowledge of Cloud Computing to help organizations analyze
                  their Azure usage, services, and cost options, making sure
                  that they are paying only for what they need – drastically
                  reducing the Azure monthly spend.
                </p>
                <p className="mt-20 mb-20">
                  Our well-designed approach, which includes end-to-end
                  inspection, assessment, migration, management, and
                  optimization, helps you gain complete visibility into your
                  Cloud spend so that you can understand and forecast your bill,
                  optimize workload costs, and control your spending.
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-outline-pink mt-20 mb-20 font-18px"
                    onClick={() => history.push(Routes.RegistrationLogin)}
                  >
                    Start Optimizing Your Azure Spend Today!
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div className="cf-row">
              <div
                className="innerContainer12 "
                style={{
                  backgroundImage: `url(${contactUs})`,
                }}
              >
                <h5 className="text-center mt-40 font-25px">
                  Get In Touch With Us
                </h5>
                <p className="text-center mt-5">
                  <i>
                    Reach out to CloudForestX's experts today and discover how
                    we can help streamline your Azure cloud environment.
                    <br />
                    Ensure cutting-edge Cloud and Azure spend optimization to
                    maximize your ROI.
                  </i>
                </p>
                <div className="text-center mt-20 mb-40">
                  <button
                    className="btn btn-outline-pink mt-10 text-uppercase"
                    onClick={() => history.push(Routes.Demo)}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div> */}

            {/* Commented code below */}
            <div className="cf-row d-none">
              <div className="innerContainer12 featureSection">
                {/* <h4 className="sectionTitle">
                  <span>Our Features</span>
                </h4> */}
                <div className="cardsCont">
                  <div className="cards">
                    <span>{/* <img src={cloudSpendImg} width="28" /> */}</span>
                    <div className="ml-10">
                      <h5 className="fw-400">Cost Optimization</h5>
                      <p>
                        Now ensure maximum possible savings with CloudForestX's
                        predictive analytics and actionable resource purchasing
                        recommendations.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={billingControlImg} width="28" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">Right-Sizing VMs</h5>
                      <p className="mt-10">
                        CloudForestX helps ensure optimal performance by
                        right-sizing your virtual machines with Azure / AWS. Get
                        highly customizable and scalable VMs for maximum
                        computing power at the least cost.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={costAllocationImg} width="24" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">B-Series VMs</h5>
                      <p className="mt-10">
                        Switch to B-Series VMs and save up to 15-55% on your
                        usage and cost. These VMs are designed for applications
                        that are typically idle and then have sudden bursts of
                        usage.
                      </p>
                    </div>
                  </div>

                  <div className="cards">
                    <span>{/* <img src={reportImg} width="28" /> */}</span>
                    <div className="ml-10">
                      <h5 className="fw-400">Migration</h5>
                      <p className="mt-10">
                        CloudForestX helps you migrate from Database VMs to
                        Elastic DBs to significantly reduce your costs by
                        enabling you to only pay for the database resources you
                        are actually using.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={bestPracticeImg} width="28" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">Storage Tiering</h5>
                      <p className="mt-10">
                        We take advantage of Azure / AWS multiple storage tiers to
                        better manage your cloud spend, integrating storage
                        tiring automation into your applications to move less
                        sensitive or less-frequently accessed data to a
                        lower-cost tier.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={invoiceBillingImg} width="24" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">Auto-Scaling</h5>
                      <p className="mt-10">
                        Dynamically allocate and deallocate resources to match
                        your performance needs, with the Azure / AWS autoscaling
                        feature and scale your cloud services, VM hosts,
                        application instances based on specific criteria.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={invoiceBillingImg} width="24" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">Removing Unused Disks</h5>
                      <p className="mt-10">
                        Clean up your Azure / AWS infrastructure by deallocating
                        unnecessary or underutilized resources with
                        CloudForestX's and effectively prevent excessive cost
                        expenditure.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={invoiceBillingImg} width="24" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">Shift Workloads to Containers</h5>
                      <p className="mt-50">
                        Significantly cut costs without incurring any technical
                        compromises by containerizing your business applications
                        and shifting workloads from VMs to Azure / AWS Containers.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <span>
                      {/* <img src={invoiceBillingImg} width="24" /> */}
                    </span>
                    <div className="ml-10">
                      <h5 className="fw-400">
                        Storage Cost Optimization with Cloud Volumes ONTAP
                      </h5>
                      <p className="mt-10">
                        With CloudForestX's Azure / AWS storage cost optimization with
                        Cloud Volumes ONTAP, optimize your use of Azure / AWS storage
                        services and reduce the storage footprint and costs
                        remarkably.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AzureSpend;
