import React, { ReactNode, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Grid, ListItem, ListItemText, TextField } from '@material-ui/core';
import '../Modal.css';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { Tooltip } from '@mui/material';

interface KeyValue {
  key: string;
  value: string;
}

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
  onSubmit?: { (): void };
  successMsg?: string;
  handleTagButton?: (tagList: any, instanceData: any, updatedList: any) => void;
  handleDeleteTag?: (data: any, tag: any) => void;
  isDuplicateKey?: boolean;
  responseMessage?: string;
  errorMsg?: string;
  instanceData?: any;
  data?: any;
  handleDeleteData?: { (data: any): void } | undefined;
  // message?: any;
  type?: string;
}

export const AddTagModal: React.FC<FixProps> = (props) => {
  const {
    show = false,
    onClose,
    instanceData,
    handleTagButton,
    handleDeleteTag,
    isDuplicateKey,
    responseMessage,
  } = props;

  const { handleSubmit, errors, register } = useForm<KeyValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const focusInput = useRef<HTMLInputElement>(null);
  const [inputList, setinputList] = useState([{ key: '', value: '' }]);
  const [updatedList, setUpdatedList] = useState<any>({});
  const [deletedTags, setDeletedTags] = useState<any>({});
  const [duplicateKey, setDuplicateKey] = useState(false);
  const [dKey, setDKey] = useState('');

  // handleDeleteData(deletedTags);

  const suggestionList = [
    'Environment',
    'Department',
    'Platform',
    'Backup',
    'Project',
    'AppName',
    'Service',
    'AccessType',
    'Patching',
    'SupportTeam',
  ];
  const handleAddTag = () => {
    setinputList([...inputList, { key: '', value: '' }]);
  };

  const handleinputchange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name as keyof typeof list[typeof index]] = value;
    setinputList(list);
  };

  const lineThroughFunc = (key: any) => {
    instanceData.Tags.map((item: any) => {
      if (item.key === key) {
        if (item.completed) {
          item.completed = false;

          delete deletedTags[item.key];

          setDeletedTags({
            ...deletedTags,
          });
        } else {
          item.completed = true;
          setDeletedTags({
            ...deletedTags,
            [item.key]: item.value,
          });
        }
      }
      return item;
    });
  };

  const deleteInputListTags = (tag: any) => {
    const list = [...inputList];
    const filteredList = list.filter((e) => e.key !== tag.key);
    setinputList(filteredList);
  };

  const checkDuplicate = () => {
    const duplicateTab = instanceData.Tags.find((tag: any) => {
      return inputList.find((newTag: any) => tag.key === newTag.key);
    });
    if (duplicateTab) setDKey(duplicateTab.key);
    return duplicateTab;
  };

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
      {...props}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">
              Tags -{' '}
              <span className="fw-600">{instanceData.Tag_Modal_Header}</span>
            </span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>
        <form
          noValidate
          onSubmit={handleSubmit(() => {
            if (checkDuplicate()) {
              setDuplicateKey(true);
            } else {
              setDKey('');
              setDuplicateKey(false);
              checkDuplicate();

              handleTagButton?.(instanceData, inputList, updatedList);

              if (Object.keys(deletedTags).length > 0) {
                handleDeleteTag?.(instanceData, deletedTags);
              }
            }
          })}
          autoComplete="off"
          className={'d-block w-100 '}
        >
          <Grid item md={12} sm={12} xs={12} className="modalScrollBar">
            <div className="theadBlue" style={{ background: '#F5F9FF' }}>
              <Grid container spacing={0} className="tableBody">
                <Grid item md={12} sm={12} xs={12}>
                  {instanceData.Tags ? (
                    instanceData.Tags.map((item: any, index: number) => (
                      <ListItem key={index} className="mb-0 mt-0">
                        <div className="tagInputs">
                          <input
                            type="text"
                            className={
                              item.completed
                                ? 'input borderNone w-80 background-lightGrey rightBorder cut'
                                : 'input borderNone w-80 background-lightGrey rightBorder unCut'
                            }
                            defaultValue={item.key}
                            readOnly
                          />
                          <Tooltip
                            title={
                              item.value.split('').length > 37 ? item.value : ''
                            }
                          >
                            <input
                              type="text"
                              readOnly={item.key.split(':').includes('aws')}
                              className={
                                item.completed
                                  ? 'input borderNone w-100 cut'
                                  : 'input borderNone w-100 unCut'
                              }
                              defaultValue={item.value}
                              data-xyz={item.key}
                              onChange={(e) => {
                                const key: any = e.target.getAttribute(
                                  'data-xyz'
                                );
                                const value = e.target.value;
                                setUpdatedList({
                                  ...updatedList,
                                  [key]: value,
                                });
                              }}
                            />
                          </Tooltip>

                          {item.key.split(':').includes('aws') ? (
                            <Tooltip title="aws doesn't allow to delete tags starting with 'aws:'">
                              <span className="pointer background-lightGrey borderNone leftBorder">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="25"
                                  fill="#999"
                                  className="bi bi-x "
                                  viewBox="0 0 16 14"
                                >
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </span>
                            </Tooltip>
                          ) : (
                            <span
                              className="pointer background-lightGrey borderNone leftBorder"
                              onClick={() => {
                                lineThroughFunc(item.key);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="25"
                                fill="red"
                                className="bi bi-x "
                                viewBox="0 0 16 14"
                              >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </span>
                          )}
                        </div>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary="No Tags" />
                    </ListItem>
                  )}
                </Grid>
              </Grid>
            </div>
            <Grid item md={12} className="modalBody">
              {inputList.map((tag, i) => {
                return (
                  <div>
                    <div key={i} className="row fw-600 ">
                      <div>
                        <Autocomplete
                          className="ml-5 tagKeyInput  m-5 w-100 "
                          options={suggestionList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="Key"
                              ref={focusInput}
                              name="key"
                              value={tag.key}
                              label="Key"
                              variant="outlined"
                              inputRef={register({
                                required:
                                  i === 0
                                    ? tag.key === '' && tag.value === ''
                                      ? false
                                      : tag.key !== '' || tag.value !== ''
                                      ? true
                                      : false
                                    : true,
                              })}
                              // onKeyDown={handleKeyDown}
                            />
                          )}
                          value={tag.key}
                          autoSelect
                          onChange={(event, newValue: string | null) => {
                            const list = [...inputList];
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            list[i].key = newValue!;
                            setinputList(list);
                          }}
                          freeSolo
                        />
                        {errors.key && tag.key === '' && (
                          <div className="tagRegMsg">Enter valid key.</div>
                        )}
                        {tag.key === null && (
                          <div className="tagRegMsg">Enter valid key.</div>
                        )}
                      </div>

                      <div>
                        <TextField
                          id="value"
                          name="value"
                          className="ml-5  m-5 w-100 tagKeyInput"
                          label="value"
                          variant="outlined"
                          value={tag.value}
                          inputRef={register({
                            required:
                              i === 0
                                ? tag.key === '' && tag.value === ''
                                  ? false
                                  : tag.key !== '' || tag.value !== ''
                                  ? true
                                  : false
                                : true,
                          })}
                          // onChange={(e) => setValue(e.target.value)}
                          onChange={(e) => handleinputchange(e, i)}
                          // onKeyDown={handleKeyDown}
                        />
                        {errors.value && tag.value === '' && (
                          <div className="tagRegMsg ">Enter valid value.</div>
                        )}
                      </div>

                      {inputList.length !== 1 ? (
                        <span
                          className="pointer background-lightGrey borderNone  removeTag"
                          onClick={() => deleteInputListTags(tag)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="42"
                            height="25"
                            fill="red"
                            className="bi bi-x "
                            viewBox="0 0 16 14"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>

                    {inputList.length - 1 === i && inputList.length < 20 && (
                      <div key={i + 1} className="d-flex flex-rowReverse">
                        <button
                          // type="button"
                          className={'btn  m-5 w-30 btn-outline-secondary'}
                          onClick={handleSubmit(handleAddTag)}
                          disabled={
                            inputList[0].key === '' || inputList[0].value === ''
                              ? true
                              : false
                          }
                        >
                          <span className="addIcon">Add More Tags</span>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
              {isDuplicateKey && (
                <div className="duplicateMsg">{responseMessage}</div>
              )}
              {duplicateKey && (
                <div className="duplicateMsg">Tag "{dKey}" already present</div>
              )}
            </Grid>
          </Grid>

          <div className="mt-0 modalFooter">
            <button
              type="submit"
              className={'btn btn-primary ml-10 cursor-notAllowed '}
              disabled={
                Object.keys(deletedTags).length > 0 ||
                Object.keys(updatedList).length > 0 ||
                (inputList[0].key !== '' && inputList[0].value !== '')
                  ? false
                  : true
              }
              onClick={() => {
                if (errors) {
                  return;
                }
                if (checkDuplicate()) {
                  setDuplicateKey(true);
                } else {
                  setDKey('');
                  setDuplicateKey(false);
                  handleTagButton?.(instanceData, inputList, updatedList);
                }

                // handleDeleteTag?.(instanceData, deletedTags);
              }}
            >
              Save
            </button>

            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Grid>
    </Modal>
  );
};
