import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import '../Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
}

export const IdleInstanceRequirementModal: React.FC<FixProps> = (props) => {
  const { children, show = false, onClose } = props;
  const selectedAccount = useSelector(accountState).selectedAccount;
  let resourcetext: string = '';
  let resourceHeading: string = '';
  selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
    ? ((resourceHeading = 'Instance'), (resourcetext = 'instances'))
    : ((resourceHeading = 'Resource'), (resourcetext = 'resources'));
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
      {...props}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">
              {`Requirement to be an Idle ${resourceHeading}`}
            </span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <Grid item md={12} className="modalBody">
          <ul className="instanceList">
            <li>
              <FontAwesomeIcon icon={faCheckCircle} className="checkIcon" />
              <span className="ml-10">
                {`Automatic scaling creates ${resourcetext} based on request rate,
                response latencies and other application metrics.`}
              </span>
            </li>
            <li className="mt-10">
              <FontAwesomeIcon icon={faCheckCircle} className="checkIcon" />
              <span className="ml-10">
                {`Basic scaling creates ${resourcetext} when your application receives
                requests.`}
              </span>
            </li>
            <li className="mt-10 mb-10">
              <FontAwesomeIcon icon={faCheckCircle} className="checkIcon" />
              <span className="ml-10">
                {`Manual scaling specifies the number of ${resourcetext} that
                continuously run regardless of the load level.`}
              </span>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Modal>
  );
};
