import React from 'react';
import { RoutesFun } from '../../../../schemas';
import { useHistory } from 'react-router';

import { ReactComponent as AWSLogo } from '../../../../assets/ICONS/logos_aws.svg';
import { ReactComponent as AzureLogo } from '../../../../assets/ICONS/logos_microsoft-azure.svg';
import { ReactComponent as GCPLogo } from '../../../../assets/ICONS/logos_google-cloud.svg';
import TSIcon from '../../../../assets/images/tsLogo.png';
import GEFENIcon from '../../../../assets/images/Gefen_3001.png';
interface CompanyTile {
  company: any;
  index: number;
}

const CompanyTile: React.FC<CompanyTile> = ({ company, index }) => {
  const history = useHistory();
  const Routes = RoutesFun();
  return (
    <div
      onClick={() =>
        history.push({
          pathname: Routes.Organization,
          state: {
            CompanyName: company.name,
          },
        })
      }
      key={index}
      className="companiesBox"
    >
      <div className="companyName ml-15">{company.name}</div>
      <div className="cloudProvider ml-15 mt-10">
        {' '}
        <div>
          {company.cloudProviders.includes('AWS') && (
            <AWSLogo height={30} width={30} className="adminCloudLogo mr-10" />
          )}
        </div>
        <div>
          {company.cloudProviders.includes('AZURE') && (
            <AzureLogo height={25} width={25} className=" mr-10" />
          )}
        </div>
        <div>
          {company.cloudProviders.includes('GCP') && (
            <GCPLogo height={25} width={25} />
          )}
        </div>
      </div>
      <div className="companyLogo mt-55 ml-150">
        {company.name === 'thinksys' ? (
          <img src={TSIcon} alt="" className="imgAdmin" />
        ) : company.name === 'Gefen' ? (
          <img src={GEFENIcon} alt="" className="imgAdmin" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CompanyTile;
