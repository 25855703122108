import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { RoutesFun } from '../../schemas';
import { authService } from '../../services';

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
}

export class ProtectedRoute extends Route<RouteProps> {
  public render() {
    const Routes = RoutesFun();
    let redirectPath: string = '';
    if (!authService.checkAuth() || localStorage.getItem('LOGIN_ACCESS')) {
      redirectPath = Routes.Login;
    }

    if (redirectPath) {
      const renderComponent = () => (
        <Redirect to={{ pathname: redirectPath }} />
      );
      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      );
    } else {
      return <Route {...this.props} />;
    }
  }
}
