import React, { useRef, useState } from 'react';
import { CustomModal } from '../../components';
import './AddSiteModal.css';
import '../../index.css';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material';
import CustomTextField from '../../components/MasterComponent/Input/CustomTextField';
interface addModalProp {
  show: boolean;
  onClose?: { (): void };
  submitFunc?: any;
  buttonMsg?: string;
  headerMsg: string;
}
export interface SiteData {
  domain: string;
  name: string;
  threshold: number;
  statistic: string;
}
export const AddSiteModal: React.FC<addModalProp> = (props) => {
  const { show, onClose, headerMsg, submitFunc } = props;
  const urlInputRef = useRef<HTMLInputElement | null>(null);
  const nameInputRef = useRef<HTMLInputElement | null>(null);

  const [isValidUrl, setIsValidUrl] = useState<boolean>(true);
  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [satisfiedLoadTime, setSatisfiedLoadTime] = useState<number>(4);

  const [calculationMethod, setCalculationMethod] = useState<string>('median');
  const submitFunction = async () => {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const url = urlInputRef?.current?.value || '';
    const name = nameInputRef?.current?.value || '';
    if (!regex.test(url)) {
      setIsValidUrl(false);
    } else {
      setIsValidUrl(true);
    }
    if (!name.length) {
      setIsValidName(false);
    } else {
      setIsValidName(true);
    }
    if (regex.test(url) && name.length) {
      const siteData: SiteData = {
        domain: url,
        name: name,
        threshold: satisfiedLoadTime,
        statistic: calculationMethod,
      };
      await submitFunc(siteData);
    }
  };

  const toleratingLoadTime = satisfiedLoadTime * 4;
  const frustratingLoadTime = toleratingLoadTime;

  return (
    <CustomModal
      show={show}
      onClose={onClose}
      headerMsg={headerMsg}
      isSubmitBtnDisabled={false}
      submitFunc={submitFunction}
      style={{ maxWidth: '900px' }}
    >
      <div className="addModalContent">
        <CustomTextField
          label="Site URL"
          defaultValue="https://"
          inputRef={urlInputRef}
          style={{ marginBottom: '2rem' }}
        />
        {!isValidUrl && (
          <div className="regMsg first" style={{ marginTop: '-2rem' }}>
            {' '}
            Enter a valid URL.
          </div>
        )}
        <CustomTextField label="Site Name" inputRef={nameInputRef} />

        {!isValidName && <div className="regMsg"> Name is required.</div>}

        {/* Advanced Settings Section */}
        <Box mt={4}>
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bolder',
              color: 'black',
              textAlign: 'center',
              // padding: '2px',
              borderRadius: '5px',
              textTransform: 'uppercase',
              letterSpacing: '1px',
            }}
          >
            Advanced Settings
          </Typography>
          <Box
            mt={2}
            p={2}
            borderRadius={2}
            sx={{ backgroundColor: '#f5f5f5' }}
          >
            {/* Calculation Method */}
            <div className="accountBtn rumAdvanceSetting">
              <Typography
                variant="body1"
                style={{
                  fontSize: '16px',
                  marginBottom: '1rem',
                  marginRight: '2rem',
                  fontWeight: '600',
                }}
              >
                Calculation Method:
              </Typography>
              <RadioGroup
                row
                value={calculationMethod}
                onChange={(e) => setCalculationMethod(e.target.value)}
                style={{ marginBottom: '1.5rem' }}
              >
                <FormControlLabel
                  value="average"
                  control={<Radio />}
                  label="Average"
                  sx={{ color: '#000', fontSize: '16px' }}
                />
                <FormControlLabel
                  value="median"
                  control={<Radio />}
                  label="Median"
                  sx={{ color: '#000', fontSize: '16px' }}
                />
              </RadioGroup>
            </div>

            {/* Satisfied Load Time */}
            <div className="accountBtn mb-15">
              <Typography
                variant="body1"
                style={{
                  fontSize: '16px',
                  marginBottom: '1rem',
                  marginRight: '2rem',
                  fontWeight: '600',
                }}
              >
                Satisfied Load Time:
              </Typography>
              <Box sx={{ position: 'relative', width: '76%' }}>
                <Slider
                  value={satisfiedLoadTime}
                  onChange={(e, newValue) =>
                    setSatisfiedLoadTime(newValue as number)
                  }
                  // valueLabelFormat={(value) => `0 - ${value}s`}
                  aria-labelledby="satisfied-load-time-slider"
                  valueLabelDisplay="auto"
                  min={1}
                  max={30}
                  // marks={[
                  //   { value: 1, label: '1s' },
                  //   { value: 30, label: '30s' },
                  // ]}
                  sx={{ marginBottom: '1.5rem' }}
                />
                {/* Custom label below the slider thumb */}
                <Typography
                  className="slider-label"
                  style={{
                    position: 'absolute',
                    top: '30px',
                    left: `calc(${((satisfiedLoadTime - 1) / 29) * 100}%)`,
                    transform: 'translateX(-50%)',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  {`0 - ${satisfiedLoadTime}s`}
                </Typography>
              </Box>
            </div>

            {/* Dynamic Notice */}
            <Typography
              variant="body1"
              style={{ fontSize: '16px', marginBottom: '1rem' }}
            >
              <span className="font-16px fw-600">Notice: </span>
              {`When your site's satisfied load time is set to 0-${satisfiedLoadTime}s, the tolerating load time is ${satisfiedLoadTime}-${toleratingLoadTime}s and frustrating load time is ${frustratingLoadTime}+ s.`}
            </Typography>
          </Box>
        </Box>
        {/* Redesigned Terms and Conditions Section */}
        <Box mt={4}>
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bolder',
              // backgroundColor: '#1E88E5',
              color: 'black',
              textAlign: 'center',
              // padding: '2px',
              borderRadius: '5px',
              textTransform: 'uppercase',
              letterSpacing: '1px',
            }}
          >
            Terms & Conditions
          </Typography>
          <Box
            style={{
              marginTop: '1.5rem',
              padding: '15px',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              lineHeight: '1.8',
            }}
          >
            <Typography
              variant="body1"
              style={{ fontSize: '16px', marginBottom: '1rem' }}
            >
              We value your privacy and are committed to maintaining it. Please
              review the following points regarding the data collected through
              our monitoring script:
            </Typography>

            <ul style={{ marginLeft: '20px' }}>
              <li>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  <strong>No PII Collected:</strong> Our script does not collect
                  or store any personally identifiable information (PII), such
                  as login credentials, passwords, or sensitive user data.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  <strong>Technical Data Only:</strong> We solely gather
                  performance metrics like page load times, server response
                  times, and resource utilization to improve your website's
                  performance.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  <strong>Data Privacy Commitment:</strong> You can rest assured
                  that only technical data crucial to monitoring and enhancing
                  website efficiency is collected.
                </Typography>
              </li>
            </ul>

            <Typography
              variant="body1"
              style={{ fontSize: '16px', marginTop: '1.5rem' }}
            >
              By integrating this script, you agree to allow us to monitor
              performance and technical data while ensuring the privacy and
              security of your users.
            </Typography>
          </Box>
        </Box>
      </div>
    </CustomModal>
  );
};
