import {
  Button,
  Grid,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { memo, useMemo, useState } from 'react';
import { ReactComponent as MonitorIcon } from '../../assets/ICONS/monitor.svg';
import { ReactComponent as CrossMonitorIcon } from '../../assets/ICONS/crossMonitor.svg';
import { ReactComponent as MaintenanceIcon } from '../../assets/ICONS/maintenance.svg';
import { ReactComponent as PauseIcon } from '../../assets/ICONS/pause.svg';
import { returnSearchResults, tileType } from './config';
import moment from 'moment';
import { MONITORING_TYPES } from '../../components/Modals/UptimeMonitoringModal/config';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from 'react-router-dom';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';

interface props {
  loader: boolean;
  tabChangeLoader: boolean;
  QuickStatsGrid: {
    up: number;
    down: number;
    maintenance: number;
    pause: number;
  };
  URLData: any;
  accordionFriendlyNameSearch: string;
  tabValue: string;
}

const QuickStats: React.FC<props> = (props) => {
  const {
    loader,
    tabChangeLoader,
    QuickStatsGrid,
    URLData,
    accordionFriendlyNameSearch,
    tabValue,
  } = props;

  const [pageNo, setPageNo] = useState(1);
  const [isUptimeClick, setIsUptimeClick] = useState<boolean>(false);
  const [isDowntimeClick, setIsDownTimeClick] = useState<boolean>(false);
  const [isMaintenanceClick, setIsMaintenanceClick] = useState<boolean>(false);
  const [isPausedClick, setIsPausedClick] = useState<boolean>(false);
  const [isResetClick, setIsResetClick] = useState<boolean>(true);
  const [selectedQuickStatsData, setSelectedQuickStatsData] = useState<any>();
  const isOnline: boolean = useOnlineStatus();

  const [quickTileSelectLoader, setQuickTileSelectLoader] = useState<boolean>(
    false
  );
  const history = useHistory();
  const company = localStorage.getItem('CompanyName');

  const quickStatusTileHandler = (type: string) => {
    setQuickTileSelectLoader(true);

    setIsUptimeClick(type === tileType.uptime);
    setIsDownTimeClick(type === tileType.downtime);
    setIsMaintenanceClick(type === tileType.maintenance);
    setIsPausedClick(type === tileType.paused);
    setIsResetClick(type === tileType.reset);

    setTimeout(() => {
      setQuickTileSelectLoader(false);
    }, 500);
    setPageNo(1);
  };

  //Pagination section//

  const recordsPerPage = 15;
  const handlePageChangeNew = (e: any, value: any) => {
    setPageNo(value);
  };

  useMemo(() => {
    const data = Object.values(
      returnSearchResults(URLData, accordionFriendlyNameSearch, tabValue)
    ).flat();

    isUptimeClick &&
      setSelectedQuickStatsData(
        data.filter(
          (item: any) =>
            item.LastActive &&
            item.IsMonitoring &&
            (item.Maintenance
              ? new Date().getTime() < +item.Maintenance.split('-')[1] &&
                new Date().getTime() > +item.Maintenance.split('-')[0]
              : true)
        )
      );

    isDowntimeClick &&
      setSelectedQuickStatsData(
        data.filter(
          (item: any) =>
            !item.LastActive &&
            item.IsMonitoring &&
            (item.Maintenance
              ? new Date().getTime() < +item.Maintenance.split('-')[1] &&
                new Date().getTime() > +item.Maintenance.split('-')[0]
              : true)
        )
      );

    isMaintenanceClick &&
      setSelectedQuickStatsData(
        data.filter(
          (item: any) =>
            item.Maintenance &&
            item.Maintenance.split('-')[0] < Date.now() &&
            item.Maintenance.split('-')[1] > Date.now()
        )
      );

    isPausedClick &&
      setSelectedQuickStatsData(data.filter((item: any) => !item.IsMonitoring));

    isResetClick &&
      setSelectedQuickStatsData(
        Object.values(
          returnSearchResults(URLData, accordionFriendlyNameSearch, tabValue)
        ).flat()
      );
  }, [
    isMaintenanceClick,
    isPausedClick,
    isResetClick,
    isDowntimeClick,
    isResetClick,
    URLData,
    accordionFriendlyNameSearch,
  ]);

  const tablePaginationData = useMemo(() => {
    // const data = Object.values(
    //   returnSearchResults(URLData, accordionFriendlyNameSearch)
    // ).flat();

    const lastIndex = pageNo * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;

    const records = selectedQuickStatsData?.slice(firstIndex, lastIndex) || [];
    const nPages = Math.ceil(selectedQuickStatsData?.length / recordsPerPage);

    return { records, nPages };
  }, [
    URLData,
    accordionFriendlyNameSearch,
    tabValue,
    pageNo,
    isMaintenanceClick,
    selectedQuickStatsData,
  ]);

  const isPortTabSelected = tabValue === MONITORING_TYPES.PORT;

  const SkeletonComponent = (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={'11rem'}
      style={{
        borderRadius: '10px',
        padding: '1rem',
        marginBottom: '16px',
      }}
    />
  );

  const handleFriendlyNameClick = (
    clickedData: any,
    regionSelected: string
  ) => {
    history.push({
      pathname:
        '/' +
        company +
        '/uptime+ssl-monitoring/' +
        Object.keys(clickedData)[0].split(' ').join('-').toLocaleLowerCase(),
      state: { clickedData, regionSelected },
    });
  };

  return (
    <>
      <div className="d-flex quickStatusHeader">
        <div className="tabResourceHeading flex-justifySpaceBetween p-0 d-flex mb-15 mt-15">
          <div>Quick Stats</div>
        </div>
        <Button
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={() => quickStatusTileHandler(tileType.reset)}
        >
          Reset
        </Button>
      </div>
      <Grid container spacing={2}>
        {/* Box 1 */}
        <Grid item xs={3}>
          {loader || tabChangeLoader || !isOnline ? (
            SkeletonComponent
          ) : (
            <div
              className="quickStatsBox uptimeBox"
              onClick={() => quickStatusTileHandler(tileType.uptime)}
            >
              <MonitorIcon />
              <div>
                <p>{QuickStatsGrid.up}</p>
                <p>Up</p>
              </div>
            </div>
          )}
        </Grid>
        {/* Box 2 */}
        <Grid item xs={3}>
          {loader || tabChangeLoader || !isOnline ? (
            SkeletonComponent
          ) : (
            <div
              className="quickStatsBox downTimeBox"
              onClick={() => quickStatusTileHandler(tileType.downtime)}
            >
              <CrossMonitorIcon />
              <div>
                <p>{QuickStatsGrid.down}</p>
                <p>Down</p>
              </div>
            </div>
          )}
        </Grid>
        {/* Box 3 */}
        <Grid item xs={3}>
          {loader || tabChangeLoader || !isOnline ? (
            SkeletonComponent
          ) : (
            <div
              className="quickStatsBox maintenanceBox"
              onClick={() => quickStatusTileHandler(tileType.maintenance)}
            >
              <MaintenanceIcon />
              <div>
                <p>{QuickStatsGrid.maintenance}</p>
                <p>Maintenance</p>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={3}>
          {loader || tabChangeLoader || !isOnline ? (
            SkeletonComponent
          ) : (
            <div
              className="quickStatsBox pauseBox"
              onClick={() => quickStatusTileHandler(tileType.paused)}
            >
              <PauseIcon />
              <div>
                <p>{QuickStatsGrid.pause}</p>
                <p>Paused</p>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <TableContainer className="accContainer urlTableContainer URLMonitoringTable">
        <Table
          stickyHeader
          className="table adminTable adminPanelTable"
          size="small"
          aria-label="sticky table"
        >
          <TableHead className="admintableHead">
            <TableCell>Friendly Name</TableCell>
            {isPortTabSelected && (
              <>
                <TableCell>Host Name</TableCell>
                <TableCell>Port Number</TableCell>
              </>
            )}
            <TableCell>Region</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Time</TableCell>
            {!isPortTabSelected && (
              <>
                <TableCell>SSL Status</TableCell>
                <TableCell>SSL Expires In</TableCell>
              </>
            )}
          </TableHead>

          <TableBody>
            {!isOnline ? (
              <>
                <TableCell>Reconnecting...</TableCell>

                <TableCell></TableCell>
                <TableCell></TableCell>

                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            ) : loader || tabChangeLoader || quickTileSelectLoader ? (
              <>
                <TableCell>Loading...</TableCell>

                <TableCell></TableCell>
                <TableCell></TableCell>

                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            ) : Object.keys(URLData).length > 0 ? (
              Object.keys(
                returnSearchResults(
                  URLData,
                  accordionFriendlyNameSearch,
                  tabValue
                )
              ).length > 0 && tablePaginationData.records.length > 0 ? (
                tablePaginationData.records?.map((value: any) => (
                  <TableRow key={value.Id}>
                    <TableCell>
                      <div
                        onClick={() =>
                          handleFriendlyNameClick(
                            {
                              [value.FriendlyName]: [value],
                            },
                            value.Region
                          )
                        }
                        className="accFriendlyName"
                      >
                        {value.FriendlyName}
                      </div>
                    </TableCell>
                    {isPortTabSelected && (
                      <>
                        <TableCell>{value.WebUrl}</TableCell>
                        <TableCell>{value.Port}</TableCell>
                      </>
                    )}
                    <TableCell>{value.Region}</TableCell>
                    <TableCell className="urlStatus">
                      {value.Maintenance &&
                      new Date().getTime() <
                        +value.Maintenance?.split('-')[1] &&
                      new Date().getTime() >
                        +value.Maintenance?.split('-')[0] ? (
                        <div className="urlMaintenance">Maintenance</div>
                      ) : value.LastChecked === null ? (
                        value.IsMonitoring ? (
                          <div className="urlConnecting">Connecting...</div>
                        ) : (
                          '-'
                        )
                      ) : value.LastActive ? (
                        <div className="urlUp">Up</div>
                      ) : (
                        <div className="urlDown">Down</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {value.LastChecked === null
                        ? '-'
                        : moment
                            .utc(value.LastChecked)
                            .local()
                            .format('DD-MM-YYYY HH:mm')}
                    </TableCell>
                    {!isPortTabSelected && (
                      <>
                        <TableCell className="urlStatus">
                          {value.SSLStatus === null ? (
                            '-'
                          ) : value.SSLStatus ? (
                            <div className="urlUp">Valid</div>
                          ) : (
                            <div className="urlDown">Invalid</div>
                          )}
                        </TableCell>
                        <TableCell>
                          {value.SSLExpiry === null
                            ? '-'
                            : +value.SSLExpiry === 1
                            ? `${value.SSLExpiry} day`
                            : +value.SSLExpiry === 0
                            ? 'Expiring today'
                            : `${value.SSLExpiry} days`}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              ) : (
                <>
                  <TableCell>No data found</TableCell>
                  {isPortTabSelected && (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {!isPortTabSelected && (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <TableCell>No data found</TableCell>
                {isPortTabSelected && (
                  <>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                )}
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {!isPortTabSelected && (
                  <>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="customizePagination"
        style={{ position: 'absolute', bottom: 2 }}
      >
        <Pagination
          onChange={handlePageChangeNew}
          className="mt-15"
          size="large"
          color="standard"
          variant="outlined"
          count={Number(tablePaginationData.nPages)}
          shape="rounded"
          page={pageNo}
        />
      </div>
    </>
  );
};

export default memo(QuickStats);
