import { rumService } from '../../services';
import { CountryData } from './Description';
import { ChangeType, DataType, RUMFilters } from './interfaces';

interface Iconfig {
  getRUMData: (params: getPlatformBrowserDataParams) => any;
  addRumSite: (params: addSiteParams) => any;
  getPlatformBrowserData: (params: getPlatformBrowserDataParams) => any;
  getCountryWiseSessionData: (params: RUMFilters) => any;
  getCountryWiseViews: (params: RUMFilters) => any;
  getSessionViews: (params: RUMFilters) => any;
  deleteRumClient: (params: any) => any;
  getScript: (params: getScriptParams) => any;
  getApdexDetail: (params: RUMFilters) => any;
  getPageWiseDetail: (params: RUMFilters) => any;
}

export interface getScriptParams {
  client: string;
}

export interface getCountryWiseViewsParams {
  clientId: string;
  platform?: string;
  browser?: string;
  country?: string;
  period?: string;
  pageUrl?: string;
}
export interface addSiteParams {
  name: string;
  domain: string;
}
export interface getRumDataParams {
  clientId?: string;
}

export interface getPlatformBrowserDataParams extends RUMFilters {
  datatype?: 'device' | 'browsers';
}

export const config: Iconfig = {
  getRUMData: (params: RUMFilters) => rumService.getRUMData(params),
  addRumSite: (params) => rumService.addRUMSite(params),
  getPlatformBrowserData: (params: getPlatformBrowserDataParams) =>
    rumService.getPlatformBrowserData(params),
  getCountryWiseSessionData: (params: RUMFilters) =>
    rumService.getCountryWiseSessionData(params),
  getCountryWiseViews: (params: RUMFilters) =>
    rumService.getCountryWiseViews(params),
  getSessionViews: (params: RUMFilters) => rumService.getSessionViews(params),
  deleteRumClient: (params: string) => rumService.deleteRUMClient(params),
  getScript: (params: getScriptParams) => rumService.getScript(params),
  getApdexDetail: (params: RUMFilters) => rumService.getApdexDetail(params),
  getPageWiseDetail: (params: RUMFilters) =>
    rumService.getPageWiseDetail(params),
};

export const percentageChange = (
  current: number,
  previous: number
): { change: ChangeType; percentage: number } => {
  let change: ChangeType = 'NoChange';
  let percentage = 0;
  if (previous <= 0) {
    return {
      change: 'Up',
      percentage: 100,
    };
  }
  percentage = ((current - previous) / previous) * 100;
  if (percentage < 0) {
    change = 'Down';
  } else if (percentage === 0) {
    change = 'NoChange';
  } else {
    change = 'Up';
  }
  return { percentage: Math.abs(percentage), change };
};

export const TIME_FILTERS_MAP = {
  ONE_DAY: { LONG: '24 hours', SHORT: '1d' },
  ONE_HOURS: { LONG: '1 hour', SHORT: '1h' },
  ONE_WEEK: { LONG: '7 days', SHORT: '7d' },
  ONE_MONTH: { LONG: '1 month', SHORT: '1m' },
  // THREE_MONTHS: { LONG: '3 months', SHORT: '3m' },
};
export const TIME_FILTERS_MAPPING: any = {
  '24 hours': '1d',
  '1 hour': '1h',
  '7 days': '7d',
  '1 month': '1m',
  // '3 months': '3m',
};
export type periodValueType = '24 hours' | '1 hour' | '7 days' | '1 month';
// | '3 months';
export const PLATFORMS = ['Desktop', 'Mobile', 'Tablet'];
export const BROWSERS = [
  'Chrome',
  'Firefox',
  'Edge',
  'Safari',
  'Electron',
  'Others',
];

export const FILTER_LABELS: (keyof RUMFilters)[] = [
  'period',
  'platform',
  'browser',
  'country',
];

export const countryData: CountryData[] = [
  { name: 'Afghanistan', code: 'AF', value: [] },
  { name: 'Albania', code: 'AL', value: [] },
  { name: 'Algeria', code: 'DZ', value: [] },
  { name: 'Andorra', code: 'AD', value: [] },
  { name: 'Angola', code: 'AO', value: [] },
  { name: 'Antigua and Barbuda', code: 'AG', value: [] },
  { name: 'Argentina', code: 'AR', value: [] },
  { name: 'Armenia', code: 'AM', value: [] },
  { name: 'Australia', code: 'AU', value: [] },
  { name: 'Austria', code: 'AT', value: [] },
  { name: 'Azerbaijan', code: 'AZ', value: [] },
  { name: 'Bahamas', code: 'BS', value: [] },
  { name: 'Bahrain', code: 'BH', value: [] },
  { name: 'Bangladesh', code: 'BD', value: [] },
  { name: 'Barbados', code: 'BB', value: [] },
  { name: 'Belarus', code: 'BY', value: [] },
  { name: 'Belgium', code: 'BE', value: [] },
  { name: 'Belize', code: 'BZ', value: [] },
  { name: 'Benin', code: 'BJ', value: [] },
  { name: 'Bhutan', code: 'BT', value: [] },
  { name: 'Bolivia', code: 'BO', value: [] },
  { name: 'Bosnia and Herzegovina', code: 'BA', value: [] },
  { name: 'Botswana', code: 'BW', value: [] },
  { name: 'Brazil', code: 'BR', value: [] },
  { name: 'Brunei', code: 'BN', value: [] },
  { name: 'Bulgaria', code: 'BG', value: [] },
  { name: 'Burkina Faso', code: 'BF', value: [] },
  { name: 'Burundi', code: 'BI', value: [] },
  { name: 'Cabo Verde', code: 'CV', value: [] },
  { name: 'Cambodia', code: 'KH', value: [] },
  { name: 'Cameroon', code: 'CM', value: [] },
  { name: 'Canada', code: 'CA', value: [] },
  { name: 'Central African Republic', code: 'CF', value: [] },
  { name: 'Chad', code: 'TD', value: [] },
  { name: 'Chile', code: 'CL', value: [] },
  { name: 'China', code: 'CN', value: [] },
  { name: 'Colombia', code: 'CO', value: [] },
  { name: 'Comoros', code: 'KM', value: [] },
  { name: 'Congo', code: 'CG', value: [] },
  { name: 'Costa Rica', code: 'CR', value: [] },
  { name: 'Croatia', code: 'HR', value: [] },
  { name: 'Cuba', code: 'CU', value: [] },
  { name: 'Cyprus', code: 'CY', value: [] },
  { name: 'Czechia', code: 'CZ', value: [] },
  { name: 'Denmark', code: 'DK', value: [] },
  { name: 'Djibouti', code: 'DJ', value: [] },
  { name: 'Dominica', code: 'DM', value: [] },
  { name: 'Dominican Republic', code: 'DO', value: [] },
  { name: 'Ecuador', code: 'EC', value: [] },
  { name: 'Egypt', code: 'EG', value: [] },
  { name: 'El Salvador', code: 'SV', value: [] },
  { name: 'Equatorial Guinea', code: 'GQ', value: [] },
  { name: 'Eritrea', code: 'ER', value: [] },
  { name: 'Estonia', code: 'EE', value: [] },
  { name: 'Eswatini', code: 'SZ', value: [] },
  { name: 'Ethiopia', code: 'ET', value: [] },
  { name: 'Fiji', code: 'FJ', value: [] },
  { name: 'Finland', code: 'FI', value: [] },
  { name: 'France', code: 'FR', value: [] },
  { name: 'Gabon', code: 'GA', value: [] },
  { name: 'Gambia', code: 'GM', value: [] },
  { name: 'Georgia', code: 'GE', value: [] },
  { name: 'Germany', code: 'DE', value: [] },
  { name: 'Ghana', code: 'GH', value: [] },
  { name: 'Greece', code: 'GR', value: [] },
  { name: 'Grenada', code: 'GD', value: [] },
  { name: 'Guatemala', code: 'GT', value: [] },
  { name: 'Guinea', code: 'GN', value: [] },
  { name: 'Guinea-Bissau', code: 'GW', value: [] },
  { name: 'Guyana', code: 'GY', value: [] },
  { name: 'Haiti', code: 'HT', value: [] },
  { name: 'Honduras', code: 'HN', value: [] },
  { name: 'Hungary', code: 'HU', value: [] },
  { name: 'Iceland', code: 'IS', value: [] },
  { name: 'India', code: 'IN', value: [] },
  { name: 'Indonesia', code: 'ID', value: [] },
  { name: 'Iran', code: 'IR', value: [] },
  { name: 'Iraq', code: 'IQ', value: [] },
  { name: 'Ireland', code: 'IE', value: [] },
  { name: 'Israel', code: 'IL', value: [] },
  { name: 'Italy', code: 'IT', value: [] },
  { name: 'Jamaica', code: 'JM', value: [] },
  { name: 'Japan', code: 'JP', value: [] },
  { name: 'Jordan', code: 'JO', value: [] },
  { name: 'Kazakhstan', code: 'KZ', value: [] },
  { name: 'Kenya', code: 'KE', value: [] },
  { name: 'Kiribati', code: 'KI', value: [] },
  { name: 'Kuwait', code: 'KW', value: [] },
  { name: 'Kyrgyzstan', code: 'KG', value: [] },
  { name: 'Laos', code: 'LA', value: [] },
  { name: 'Latvia', code: 'LV', value: [] },
  { name: 'Lebanon', code: 'LB', value: [] },
  { name: 'Lesotho', code: 'LS', value: [] },
  { name: 'Liberia', code: 'LR', value: [] },
  { name: 'Libya', code: 'LY', value: [] },
  { name: 'Liechtenstein', code: 'LI', value: [] },
  { name: 'Lithuania', code: 'LT', value: [] },
  { name: 'Luxembourg', code: 'LU', value: [] },
  { name: 'Macao', code: 'MO', value: [] },
  { name: 'Madagascar', code: 'MG', value: [] },
  { name: 'Malawi', code: 'MW', value: [] },
  { name: 'Malaysia', code: 'MY', value: [] },
  { name: 'Maldives', code: 'MV', value: [] },
  { name: 'Mali', code: 'ML', value: [] },
  { name: 'Malta', code: 'MT', value: [] },
  { name: 'Marshall Islands', code: 'MH', value: [] },
  { name: 'Mauritania', code: 'MR', value: [] },
  { name: 'Mauritius', code: 'MU', value: [] },
  { name: 'Mexico', code: 'MX', value: [] },
  { name: 'Micronesia', code: 'FM', value: [] },
  { name: 'Moldova', code: 'MD', value: [] },
  { name: 'Monaco', code: 'MC', value: [] },
  { name: 'Mongolia', code: 'MN', value: [] },
  { name: 'Montenegro', code: 'ME', value: [] },
  { name: 'Morocco', code: 'MA', value: [] },
  { name: 'Mozambique', code: 'MZ', value: [] },
  { name: 'Myanmar', code: 'MM', value: [] },
  { name: 'Namibia', code: 'NA', value: [] },
  { name: 'Nauru', code: 'NR', value: [] },
  { name: 'Nepal', code: 'NP', value: [] },
  { name: 'Netherlands', code: 'NL', value: [] },
  { name: 'New Zealand', code: 'NZ', value: [] },
  { name: 'Nicaragua', code: 'NI', value: [] },
  { name: 'Niger', code: 'NE', value: [] },
  { name: 'Nigeria', code: 'NG', value: [] },
  { name: 'North Macedonia', code: 'MK', value: [] },
  { name: 'Norway', code: 'NO', value: [] },
  { name: 'Oman', code: 'OM', value: [] },
  { name: 'Pakistan', code: 'PK', value: [] },
  { name: 'Palau', code: 'PW', value: [] },
  { name: 'Palestine', code: 'PS', value: [] },
  { name: 'Panama', code: 'PA', value: [] },
  { name: 'Papua New Guinea', code: 'PG', value: [] },
  { name: 'Paraguay', code: 'PY', value: [] },
  { name: 'Peru', code: 'PE', value: [] },
  { name: 'Philippines', code: 'PH', value: [] },
  { name: 'Poland', code: 'PL', value: [] },
  { name: 'Portugal', code: 'PT', value: [] },
  { name: 'Qatar', code: 'QA', value: [] },
  { name: 'Romania', code: 'RO', value: [] },
  { name: 'Russia', code: 'RU', value: [] },
  { name: 'Rwanda', code: 'RW', value: [] },
  { name: 'Saint Kitts and Nevis', code: 'KN', value: [] },
  { name: 'Saint Lucia', code: 'LC', value: [] },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', value: [] },
  { name: 'Samoa', code: 'WS', value: [] },
  { name: 'San Marino', code: 'SM', value: [] },
  { name: 'Sao Tome and Principe', code: 'ST', value: [] },
  { name: 'Saudi Arabia', code: 'SA', value: [] },
  { name: 'Senegal', code: 'SN', value: [] },
  { name: 'Serbia', code: 'RS', value: [] },
  { name: 'Seychelles', code: 'SC', value: [] },
  { name: 'Sierra Leone', code: 'SL', value: [] },
  { name: 'Singapore', code: 'SG', value: [] },
  { name: 'Slovakia', code: 'SK', value: [] },
  { name: 'Slovenia', code: 'SI', value: [] },
  { name: 'Solomon Islands', code: 'SB', value: [] },
  { name: 'Somalia', code: 'SO', value: [] },
  { name: 'South Africa', code: 'ZA', value: [] },
  { name: 'South Korea', code: 'KR', value: [] },
  { name: 'South Sudan', code: 'SS', value: [] },
  { name: 'Spain', code: 'ES', value: [] },
  { name: 'Sri Lanka', code: 'LK', value: [] },
  { name: 'Sudan', code: 'SD', value: [] },
  { name: 'Suriname', code: 'SR', value: [] },
  { name: 'Sweden', code: 'SE', value: [] },
  { name: 'Switzerland', code: 'CH', value: [] },
  { name: 'Syria', code: 'SY', value: [] },
  { name: 'Taiwan', code: 'TW', value: [] },
  { name: 'Tajikistan', code: 'TJ', value: [] },
  { name: 'Tanzania', code: 'TZ', value: [] },
  { name: 'Thailand', code: 'TH', value: [] },
  { name: 'Timor-Leste', code: 'TL', value: [] },
  { name: 'Togo', code: 'TG', value: [] },
  { name: 'Tonga', code: 'TO', value: [] },
  { name: 'Trinidad and Tobago', code: 'TT', value: [] },
  { name: 'Tunisia', code: 'TN', value: [] },
  { name: 'Turkey', code: 'TR', value: [] },
  { name: 'Turkmenistan', code: 'TM', value: [] },
  { name: 'Tuvalu', code: 'TV', value: [] },
  { name: 'Uganda', code: 'UG', value: [] },
  { name: 'Ukraine', code: 'UA', value: [] },
  { name: 'United Arab Emirates', code: 'AE', value: [] },
  { name: 'United Kingdom', code: 'GB', value: [] },
  { name: 'United States', code: 'US', value: [] },
  { name: 'Uruguay', code: 'UY', value: [] },
  { name: 'Uzbekistan', code: 'UZ', value: [] },
  { name: 'Vanuatu', code: 'VU', value: [] },
  { name: 'Venezuela', code: 'VE', value: [] },
  { name: 'Vietnam', code: 'VN', value: [] },
  { name: 'Yemen', code: 'YE', value: [] },
  { name: 'Zambia', code: 'ZM', value: [] },
  { name: 'Zimbabwe', code: 'ZW', value: [] },
  { name: 'Western Sahara', code: 'EH', value: [] },
  { name: 'Kosovo', code: 'XK', value: [] },
  { name: 'Vatican City', code: 'VA', value: [] },
  { name: 'Cook Islands', code: 'CK', value: [] },
  { name: 'Niue', code: 'NU', value: [] },
  { name: 'Northern Cyprus', code: 'CN', value: [] },
  { name: 'Pitcairn Islands', code: 'PN', value: [] },
  { name: 'Hong Kong', code: 'HK', value: [] }, // Added Hong Kong
  { name: 'Macau', code: 'MO', value: [] },
];

export const MONITORING_DATA_TYPE: DataType = {
  ACTIVE_SESSION: 'ACTIVE_SESSION',
  LOADTIME: 'LOADTIME',
};

export const getCountryCode = (countryName: string): string | undefined => {
  const country = countryData.find((c) => c.name === countryName);
  return country ? country.code : undefined;
};

export interface PageData {
  pageUrl: string;
  pageViews: number;
  isExitPage: number;
  medianLoadTime: number;
  totalPageLoadTime: number;
}

export function formatSecondsAsTime(milliseconds: number) {
  // Create a moment duration object from the seconds
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;

  // Format the duration as "X h Y m"
  return `${minutes.toFixed(2)}m`;
}

export const lowerCaseToTitleCase = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
