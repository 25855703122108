import { makeStyles, createStyles } from '@material-ui/core/styles';

export const helperStyles = makeStyles(() =>
  createStyles({
    positionRelative: {
      position: 'relative',
    },
    w100: {
      width: '100%',
    },
    styleColor: {
      color: '#0abde3',
    },
    styleCursor: {
      cursor: 'pointer',
    },
    styleWeight: {
      fontWeight: 600,
    },
    styleCenter: {
      textAlign: 'center',
    },
    styleRight: {
      float: 'right',
    },
    styleGreen: {
      color: '#9ed351',
    },
    styleRed: {
      color: '#f63c0b',
    },
    styleFont: {
      fontSize: '12px',
    },
    p0: {
      padding: 0,
    },
  })
);

export default helperStyles;
