import { APIS } from '../../utils/apiConfig';
import { HttpService } from '../HttpService/HttpService';

export class SlackNotificationManagementService {
  constructor(private httpService: HttpService) {}

  async FetchSlackCreds(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_SLACK_CREDS(), {});
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async setSlackCreds(slackCreds: any): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.SET_SLACK_CREDS(), {
        ...slackCreds,
      });
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async FetchEmails(): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_MONITORING_EMAILS(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
