import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  ReactNode,
  createContext,
} from 'react';
import { useEffectOnce } from 'react-use';
import { AccountDetailsProps } from '../../schemas/account.schema';
import { UserProps } from '../../schemas/user.schema';
import { accountService, authService, userService } from '../../services';
import { useHistory } from 'react-router-dom';
import { handleError } from '../../utils/config';

interface UserDataActions {
  getUserDetails(): void;
  getSelectedAccount(): void;
  setSuccessMessage(message: string): void;
}

export interface UserData {
  user?: UserProps;
  selectedAccount?: AccountDetailsProps;
  successMessage?: string;
}

interface RestContextData {
  userDataInitialized: boolean;
  userSelectionInitialized: boolean;
  isAccountSelected: boolean;
}

export type metaUserData = UserData & RestContextData;

interface GlobalUserDataProviderProps {
  children: ReactNode;
  testUserData?: Partial<metaUserData>;
}

const UserDataContext = createContext<metaUserData | undefined>(undefined);
const UserDataActionsContext = createContext<UserDataActions | undefined>(
  undefined
);

export const GlobalUserDataProvider: React.FC<GlobalUserDataProviderProps> = ({
  children,
  testUserData,
}) => {
  const history = useHistory();
  const [userDataInitialized, setUserDataInitialized] = useState(false);
  const [userSelectionInitialized, setUserSelectionInitialized] = useState(
    false
  );
  const [user, setUser] = useState<UserData['user']>(undefined);
  const [selectedAccount, setSelectedAccount] = useState<AccountDetailsProps>();
  const [successMessage, setSuccessMsg] = useState<string>('');

  const [isAccountSelected, setIsAccountSelected] = useState(false);

  const getUserDetails = useCallback(async () => {
    try {
      const userDetails = await userService.getUserDetails();
      if (userDetails) {
        setUser(userDetails);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    }
  }, [userService]);

  const getSelectedAccount = useCallback(async () => {
    try {
      const accountDetails = await accountService.getSelectedAccount();
      setSelectedAccount(accountDetails);
    } catch (e) {
      throw e;
    }
  }, [accountService]);

  const setSuccessMessage = (message: string) => {
    setSuccessMsg(message);
  };

  useEffectOnce(() => {
    if (authService.checkAuth()) {
      (async () => {
        await getUserDetails();
        setUserDataInitialized(true);

        await getSelectedAccount();
        setIsAccountSelected(true);
      })();
    }
  });

  const actions = useMemo(
    () => ({
      getUserDetails,
      getSelectedAccount,
      setSuccessMessage,
    }),
    [getUserDetails, getSelectedAccount, setSuccessMessage]
  );

  const state = useMemo(() => {
    return {
      user,
      userDataInitialized,
      userSelectionInitialized,
      isAccountSelected,
      selectedAccount,
      successMessage,
    };
  }, [
    user,
    userDataInitialized,
    userSelectionInitialized,
    isAccountSelected,
    selectedAccount,
    successMessage,
  ]);

  return (
    <UserDataContext.Provider value={{ ...state, ...testUserData }}>
      <UserDataActionsContext.Provider value={actions}>
        {children}
      </UserDataActionsContext.Provider>
    </UserDataContext.Provider>
  );
};

export function useUserData(): metaUserData {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error(
      'useUserData must be used within a UserDataContext.Provider'
    );
  }
  return context;
}

export function useUserDataActions(): UserDataActions {
  const context = useContext(UserDataActionsContext);
  if (context === undefined) {
    throw new Error(
      'useUserDataActions must be used within a UserDataActionsContext.Provider'
    );
  }
  return context;
}
