import Modal from 'react-modal';
import React, { useRef, useState } from 'react';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import './ShareDocumentModal.css';
import Checkbox from '@mui/material/Checkbox';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

interface ShareDocumentModalInterface {
  onSubmit: { (data: { [key: string]: string[] }): void };
  show: boolean;
  onClose?: { (): void };
  shareToEmails: string[];
  usersEmails: string[];
  documentsName: {
    [key: number]: string;
  };
  // sharedDocumentsName: string[];
}

const ShareDocumentModal: React.FC<ShareDocumentModalInterface> = ({
  show,
  onClose,
  shareToEmails,
  usersEmails,
  onSubmit,
  documentsName,
  // sharedDocumentsName,
}) => {
  const [selectedEmails, setSelectedEmails] = useState(shareToEmails);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  const handleCheckChange = (e: any) => {
    if (selectedEmails.includes(e.target.value)) {
      setSelectedEmails(
        selectedEmails.filter((user: string) => user !== e.target.value)
      );
    } else {
      setSelectedEmails([...selectedEmails, e.target.value]);
    }
  };

  const handleDocumentShareChange = (e: any) => {
    const documentName = e.target.value;
    setSelectedDocuments((prevValue) =>
      prevValue.includes(documentName)
        ? prevValue.filter((document: string) => document !== e.target.value)
        : [...prevValue, documentName]
    );
  };

  return (
    <>
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{
          overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 },
        }}
        className="fixModal shareDoc-fixModal"
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle-shareDocModal">Shared Document</span>
              <span className="modalClose" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
          <Table
            className="table mt-10"
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              {Object.values(documentsName).map((document: string) => (
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedDocuments.includes(document)}
                      onChange={handleDocumentShareChange}
                      value={document}
                    />
                  </TableCell>
                  <TableCell className="shareDoc-documentName">
                    {document}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="shareDocOuterBox">
            <TableContainer className="accContainer">
              <Table className="table " size="small" aria-label="a dense table">
                <TableHead className="tableHead">
                  <TableRow>
                    <TableCell align="left" width="10%" className="heading">
                      Sr. No.
                    </TableCell>
                    <TableCell width="30%" align="left" className="heading">
                      Name
                    </TableCell>
                    <TableCell width="80%" align="left" className="heading">
                      Email
                    </TableCell>
                    <TableCell
                      width="30%"
                      align="left"
                      className="heading"
                    ></TableCell>

                    <TableCell width="10%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(usersEmails).map(
                    ([key, value], index: number) => (
                      <TableRow key={key + index}>
                        <TableCell className="shareDoc-documentName">
                          {index + 1}
                        </TableCell>
                        <TableCell className="shareDoc-documentName">
                          {value}
                        </TableCell>
                        <TableCell className="shareDoc-documentName">
                          {key}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            size="medium"
                            value={key}
                            onChange={handleCheckChange}
                            checked={selectedEmails.includes(key)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>

        <div className=" modalFooter">
          <button
            type="button"
            className="btn btn-primary ml-10 "
            onClick={() => {
              onSubmit({ selectedEmails, selectedDocuments });
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ShareDocumentModal;
