import React, { useEffect } from 'react';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import rightSizingImage0 from '../../../assets/images/cfx-rightsizing.png';
import rightSizingImage1 from '../../../assets/images/cfx-rightsizing.png';
import rightSizingImage2 from '../../../assets/images/cost-forecasting-tab.png';
import rightSizingImage3 from '../../../assets/images/virtual-machines-tab.png';
import rightSizingImage4 from '../../../assets/images/cloud-infrastructure-tab.png';
import rightSizingImage5 from '../../../assets/images/data-storage-tab.png';
import rightSizingImage6 from '../../../assets/images/continuous-process-tab.png';

export const RightsizingDetail: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="outerContiner scrollContainer">
        <SupportHeader />

        <div className="home-banner cf-row p-0 flex-container homeBanner">
          <div className="innerContainer12 rightsizingDetailImage">
            <div className="homeBannerText">
              <h1>CloudForestX &amp; Rightsizing</h1>
              <p className="fw-300 mb-20">
              Save your hefty expenses of cloud due to mismanagement with the expert RightSizing from CloudForestX
              </p>
            </div>
            <div className="mb-5 flex-container flex-img d-none">
              <img className="flex-img " src={rightSizingImage0} width="100%" />
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
          <div className="supportContent">
            <div className="innerContainer12 flex-container">
              <div className="py-0 px-20">
                <div className="innerContainer12">
                  <p className="mt-10">
                      Migration to Microsoft Azure / AWS cloud is an effective way to adapt to new technologies. However, if left
                      unmanaged, the same can cause a big hole in the pocket. Being an overall expensive task, spending
                      money wisely post-migration will ensure its success in the long term. Organizations may fail to
                      determine the running resources, their owners, and their capacity. With that thing in mind,
                      CloudForestX provides RightSizing, the solution to control unnecessary Azure / AWS expenses while keeping it effective.
                  </p>

                  <p className="mt-20">
                      With industry-leading tools combined with efficacious techniques, CloudForestX will aid you in
                      minimizing your unwanted Azure / AWS expenses. RightSizing is the process of managing the workload performance as per the capacity and requirements to reduce overall cost.
                  </p>

                  <p className="mt-20">
                      By analyzing the core metrics, cloud infrastructure, and virtual machines, we will RightSize your
                      machines accurately. With our professional service by your side, you can leave the task of controlling
                      your unnecessary cloud expenditures to us, and have the advantage of the most effective RightSizing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
                <div className="contentArea">
                    <div>
                    <h4>
                        <span className="red">RightSizing</span>
                        {/* <span>Solution for Enterprises</span> */}
                    </h4>
                    <p className="mt-20 mb-30">
                    CloudForestX RightSizing is a proven way to manage workload while being cost-effective. Our ingenious key RightSizing mechanism comes with plenty of effective features including picking the right size of the virtual machine, managing inventories, finding unused resources, and simplifying cloud billing.
                    </p>

                    <p className="mt-20 mb-30">
                    With our RightSizing expertise, we will provide you with a broad range of specialized and optimized VM types, meeting the demands of any workload and saving overall cost by only getting what is necessary and eliminating excess capacity.
                    </p>
                    </div>
                </div>

                <div className="contentImage">
                    <img
                    className="flex-img img-width"
                    src={rightSizingImage1}
                    width="100%"
                    />
                </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
                <div className="contentArea">
                    <div>
                    <h4>
                      <span className="green">Cost Forecasting</span>
                    </h4>
                    <p className="mt-20 mb-30">
                    CloudForestX ensures that changing conditions never hamper your over expenses by adjusting the cost planning accordingly. Our system evaluates the necessary activities depending on the planned, and actual values in the cloud.
                    </p>
                    </div>
                </div>
                
                <div className="contentImage">
                    <img
                    className="flex-img img-width"
                    src={rightSizingImage2}
                    width="100%"
                    />
                </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
              <div className="contentArea">
                <div>
                  <h4>
                    <span className="purple">Azure / AWS Virtual Machines</span>
                  </h4>
                  <p className="mt-20 mb-30">
                  CloudForestX helps in RightSizing Azure / AWS VMs by reviewing the trending metrics of CPU, network in/out, and memory disk periodically, allowing you to reduce the VM size without compromising its performance.
                  </p>
                </div>
              </div>
            
              <div className="contentImage">
                <img
                className="flex-img img-width"
                src={rightSizingImage3}
                width="100%"
                />
              </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
              <div className="contentArea">
                <div>
                  <h4>
                    <span className="blue">Cloud Infrastructure</span>
                  </h4>
                  <p className="mt-20 mb-30">
                  Only use the effective cloud infrastructure by determining their running efficiency and making required modifications like an upgrade, downgrade or terminate on several infra types.
                  </p>
                </div>
              </div>
            
              <div className="contentImage">
                <img
                className="flex-img img-width"
                src={rightSizingImage4}
                width="100%"
                />
              </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container my-40">
              <div className="contentArea">
                <div>
                  <h4>
                    <span className="green">Disk Storage</span>
                  </h4>
                  <p className="mt-20 mb-30">
                  By studying the disk storage capacity, IOPs, picking the right disk size from the assortment as per the requirements is made easier. We ensure that your storage needs are fulfilled while keeping the overall cost minimal.
                  </p>
                </div>
              </div>
            
              <div className="contentImage">
                <img
                className="flex-img img-width"
                src={rightSizingImage5}
                width="100%"
                />
              </div>
            </div>
        </div>

        <div className="home-tile cf-row">
            <div className="innerContainer12 flex-container">
              <div className="contentArea">
                <div>
                  <h4>
                    <span className="red">Continuous Process</span>
                  </h4>
                  <p className="mt-20 mb-30">
                  RightSizing is not a one-time action, but an ongoing process. CloudForestX gives priority to cost by eradicating unused resources and waste spending.
                  </p>
                </div>
              </div>
            
              <div className="contentImage">
                <img
                className="flex-img img-width"
                src={rightSizingImage6}
                width="100%"
                />
              </div>
            </div>
        </div>

        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightsizingDetail;
