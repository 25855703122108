import React from 'react';
import SupportHeader from '../../components/Header/SupportHeader';
import { Footer } from '../../components';
import { Link } from 'react-router-dom';
import './404.css';

export const Error403: React.FC = () => {
  return (
    <div className="outerContiner scrollContainer error container">
      <SupportHeader />
      <div className="container-404 py-35 my-35">
        <div className="item-404">403</div>
        <div className="error-text p-10">
          You don't have permission to access this page!
        </div>
        <Link className="home-link m-30" to={{ pathname: '/' }}>
          Go to Home
        </Link>
      </div>

      <Footer />
    </div>
  );
};

export default Error403;
