import { adminDashboardService, systemHealthService } from '../../../services';

export const config: any = {
  getSubscribedCompanies: () => adminDashboardService.getAllOrgDetails(),
  getSystemHealthData: (accountId: string, cloud: string) =>
    systemHealthService.getAccountHealthData(accountId, cloud),
  getActiveAccounts: (companyName: string) =>
    systemHealthService.getAccounts(companyName),
  getJobFailurDetails: (
    services: string,
    account: string,
    jobdate: string,
    cloud: string
  ) =>
    systemHealthService.getJobFailurDetails(services, account, jobdate, cloud),
};
