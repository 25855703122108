import React from 'react'; // Importing CSSProperties from React
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Custom styles for the TextField
const useStyles = makeStyles({
  inputRoot: {
    borderRadius: '5px',
    padding: '5px 10px', // Reduces the padding
    '& .MuiOutlinedInput-input': {
      padding: '8px', // Controls the padding inside the input box
    },
    fontSize: '14px',
    '&:hover': {
      borderColor: '#1E88E5',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)', // Center the label vertically
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjusts the shrunk position of the label
    },
  },
  labelRoot: {
    fontSize: '13px',
    color: '#888',
    top: '-5px',
  },
  notchedOutline: {
    borderColor: '#ccc',
  },
});

interface CustomTextFieldProps {
  label: string;
  defaultValue?: string;
  style?: React.CSSProperties; // Use React's CSSProperties here
  inputRef: React.RefObject<HTMLInputElement>;
  [x: string]: any; // Allows any other prop to be passed to the TextField
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  label,
  defaultValue = '',
  inputRef,
  style,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      label={label}
      variant="outlined"
      defaultValue={defaultValue}
      inputRef={inputRef}
      fullWidth
      InputProps={{
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.notchedOutline,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
        },
      }}
      style={style} // Apply the inline style passed via props
      {...rest}
    />
  );
};

export default CustomTextField;
