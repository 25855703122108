import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    innerContainer: {
      width: '100%',
      margin: '0 auto',
      padding: '0px 15px',
      '& .fa-cog': {
        padding: '0 5px',
      },
    },
    colBtn: {
      padding: 0,
      fontSize: '16px',
      lineHeight: 0,
    },
    btn: {
      background: '#1986e0',
      border: '1px solid #1986e0',
      boxSizing: 'border-box',
      borderRadius: 'revert',
      color: 'white',
      width: '185px',
      padding: '12px',
      float: 'left',
      '&:hover': {
        background: '#1678c8',
        border: '1px solid #1678c8',
      },
      '& .fa-plus-circle': {
        margin: '0 8px',
      },
    },
    headerGrid: {
      backgroundColor: 'white',
      margin: '20px 0px 10px',
    },
    acntHeader: {
      backgroundColor: '#fff',
      padding: '15px',
      margin: '10px 0px',
      cursor: 'pointer    ',
    },
    styleColor: {
      color: '#00c83c',
    },
    styleIcon: {
      padding: '0 5px',
      cursor: 'pointer',
    },
    accordionHeader: {
      cursor: 'default',
      '& .accordionTitle': {
        padding: '5px 20px',
      },
      '& .actionsBtn': {
        textAlign: 'center',
        padding: '5px 20px',
        color: '#333',
      },
    },
    accordionContainer: {
      padding: '0',
      margin: '0 0 10px 0 !important',
      boxShadow: 'none',
      border: '1px solid #eee',
      borderTop: '2px solid #1582dc',
      borderRadius: '5px',
      '& .MuiTablePagination-actions svg': {
        fontSize: '2rem',
        position: 'relative',
        top: '2px',
      },
      '& .accordionContainerHead': {
        background: '#e6f4ff',
      },
      '& table.MuiTable-root': {
        // minHeight: '370px',
      },
      '& th.MuiTableCell-root': {
        fontSize: '12px',
      },
      '& td.MuiTableCell-root': {
        fontSize: '14px',
        padding: '8px 12px',
      },
      '& .MuiTablePagination-toolbar': {
        minHeight: '30px',
      },
      '& .actionsBtn': {
        display: 'flex',
        justifyContent: 'center',
        '& [data-prefix="fas"]': {
          margin: '0 16px',
          fontSize: '15px',
          alignSelf: 'center',
          '&.fa-toggle-on': {
            fontSize: '18px',
          },
        },
        '& span': {
          alignSelf: 'center',
          width: 'auto',
          height: 'auto',
          fontSize: 'initial',
        },
      },
      '& .accordionTitle': {
        '& span': {
          display: 'inline-block',
          padding: '8px',
        },
        '& p': {
          fontSize: '15px',
          padding: '8px 10px',
          display: 'inline-block',
        },
      },
      '& .accordionContentDesc': {
        padding: '5px 0 !important',
        fontSize: '1.4rem',
        margin: '3px !important',
        border: 'none',
        borderTop: '1px solid #ddd',
        '& .MuiTablePagination-caption': {
          fontSize: '13px',
        },
        '& span': {
          display: 'inline-block',
          padding: '0',
        },
      },
      '& .accordionContentBox': {
        background: '#fdfdfd',
        padding: '0 !important',

        '& .accordionContent': {
          display: 'flex',
          '& span': {
            display: 'flex',
            //   background: '#e8e8e8',
            //   padding: '8px 4px 8px 5px',
            //
          },
          '& .accDetails': {
            padding: '0px',
            width: '100%',
            '& .accDetailRow': {
              borderBottom: '1px solid #ccc',
            },
            '& .accDetailArea': {
              padding: '8px',
              fontSize: '13px',
              '& b': {
                fontWeight: '500',
              },
              '& span': {
                display: 'inline-block',
                padding: '0',
                color: '#0078db',
                background: 'transparent',
              },
              '& ul': {
                margin: '5px 0',
                padding: '0',
                '& li': {
                  listStyle: 'none',
                  width: '24%',
                  display: 'inline-block',
                  '& span': {
                    color: 'inherit',
                  },
                },
              },
            },
          },
        },
      },
      '& .Mui-expanded .accordionTitle span .fa-caret-right': {
        transform: 'rotate(90deg)',
      },
      '& .MuiTablePagination-input': {
        border: '1px solid #ccc',
        borderRadius: '3px',
        '& svg': {
          top: 'calc(50% - 9px)',
        },
      },
    },
    emptyRow: {
      height: '100px',
      fontSize: '15px',
    },
    styleRow: {
      '&:nth-child(even)': {
        background: '#e6f4ff',
      },
      cursor: 'default',
      '& td': {
        borderBottom: 'none',
      },
    },
    selectedRow: {
      '& td.MuiTableCell-root': {
        borderBottom: 'none',
        borderCollapse: 'separate',
        borderSpacing: '3px',
      },
      '&:nth-child(even)': {
        background: '#e6f4ff',
      },
      cursor: 'default',
    },
    tableRows: {
      borderCollapse: 'separate',
      borderSpacing: '0 13px',
    },
  })
);

export default useStyles;
