import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import './SearchedResults.css';
import React, { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';

import { useHistory } from 'react-router';
const SearchedResults: React.FC<any> = ({ searchedResult }) => {
  const [searchedResources, setSearchedResources] = useState([]);
  const history = useHistory();
  const formattedData = Object.entries(searchedResult).reduce(
    (resources: any, [key, value]) => {
      const resource = [];
      const resourceDetails: any = {};
      resourceDetails['ResourceName'] = key;
      resourceDetails['Details'] = value;
      resource.push(resourceDetails);
      resources.push(resource);
      return resources;
    },
    []
  );

  useEffect(() => {
    setSearchedResources(formattedData);
  }, [searchedResult]);
  const tableRow = [
    'VM Name',
    'VM CPU Utilization',
    'Total Capacity Used',
    'Usage',
    'Last Usage Date',
    'Utilization Score',
  ];
  const type = window.location.pathname.split('/')[2];
  const handleClick = (Url: string) => {
    if (Url) {
      if (Url === 'rightsizing') {
        history.push(`/${Url.toLowerCase()}`);
      } else {
        const caseUrl = `/${Url.toLowerCase()}/idle/${type}`;
        history.push(caseUrl);
      }
    }
  };
  return (
    <React.Fragment>
      {searchedResources.length > 0 ? (
        searchedResources.map((inventoryDetails: any, key) => (
          <>
            <div
              key={`inventoryBox ${key}`}
              className="rsTableHeader inventoryHeader mb-10"
            >
              <div className="inventoryHeading">
                <div className="d-inline-block searchedResourceHeading">
                  {inventoryDetails[0].ResourceName}
                </div>

                <sup className="searchedInventoryDetailCount font-16px fw-500 ml-10">
                  {inventoryDetails[0].Details.length}
                </sup>
              </div>
            </div>
            <Grid key={`tableRow ${key}`} item md={12} className="mb-35">
              <TableContainer className="accContainer searchedTable">
                <Table stickyHeader className="table" aria-label="sticky table">
                  <TableHead className="tableHead">
                    {!inventoryDetails || inventoryDetails.length === 0 ? (
                      <TableRow>
                        {tableRow.map((row, index) => (
                          <TableCell
                            className="stickyTableHeading"
                            key={index}
                            align="left"
                          >
                            {row}
                          </TableCell>
                        ))}
                      </TableRow>
                    ) : (
                      inventoryDetails.map((inventory: any, index: any) => (
                        <TableRow key={'inventory' + index}>
                          {inventory.Details && inventory.Details.length === 0
                            ? tableRow.map((row, index) => (
                                <TableCell
                                  className="stickyTableHeading"
                                  key={'details' + index}
                                  align="left"
                                >
                                  {row}
                                </TableCell>
                              ))
                            : Object.keys(inventory.Details[0]).map(
                                (key, index) =>
                                  key === 'Id' ||
                                  key === 'Unit' ||
                                  key === 'Category' ||
                                  key === 'Tags' ||
                                  key === 'Tag_Resource_Id' ||
                                  key === 'Tag_Modal_Header' ||
                                  key === 'SubscriptionId' ? null : key ===
                                    'CPU_Util' ? (
                                    <TableCell
                                      className="stickyTableHeading"
                                      key={'cell' + index}
                                      align="left"
                                    >
                                      {key.replace(/_/g, ' ')} (%)
                                    </TableCell>
                                  ) : key === 'CapacityUsed' ||
                                    key === 'Storage_Capacity' ||
                                    key === 'Volume_Size' ||
                                    key === 'Size' ? (
                                    <TableCell
                                      className="stickyTableHeading"
                                      key={'cell' + index}
                                      align="left"
                                    >
                                      {key.replace(/_/g, ' ')}
                                      <span className="font-12px">
                                        ({inventory.Details[0].Unit})
                                      </span>
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      className="stickyTableHeading"
                                      key={'cell' + index}
                                      align={`${
                                        key === 'Utilization_Score'
                                          ? 'center'
                                          : 'left'
                                      }`}
                                    >
                                      {key.replace(/_/g, ' ')}
                                    </TableCell>
                                  )
                              )}
                          <TableCell className="stickyTableHeading">
                            Tags
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableHead>
                  <TableBody>
                    {inventoryDetails?.length > 0
                      ? inventoryDetails.map((inventory: any, index: any) =>
                          inventory.Details &&
                          inventory.Details.length !== 0 ? (
                            inventory.Details.map(
                              (inventoryDetails: any, i: any) => (
                                <TableRow key={i}>
                                  {Object.keys(inventoryDetails).map(
                                    (key, index) =>
                                      key === 'Utilization_Score' ? (
                                        <TableCell
                                          className="inventoryTableCell"
                                          align="left"
                                          key={key + index}
                                        >
                                          <GaugeChart
                                            id="gauge-chart2"
                                            nrOfLevels={5}
                                            percent={inventoryDetails[key] / 5}
                                            className="guageChart"
                                          />
                                          <div className="guageChartText">
                                            {inventoryDetails.Utilization_Score}
                                            /5
                                          </div>
                                        </TableCell>
                                      ) : key === 'Id' ||
                                        key === 'Unit' ||
                                        key === 'Category' ||
                                        key === 'Tags' ||
                                        key === 'Tag_Resource_Id' ||
                                        key === 'Tag_Modal_Header' ||
                                        key ===
                                          'SubscriptionId' ? null : index ===
                                          1 && inventoryDetails.Category ? (
                                        <TableCell
                                          key={key + index}
                                          className="inventoryTableCellLink"
                                          onClick={() => {
                                            handleClick(
                                              inventoryDetails.Category
                                            );
                                          }}
                                          align="left"
                                        >
                                          {inventoryDetails[key] == null
                                            ? '-'
                                            : inventoryDetails[key]}
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          key={key + index}
                                          className="inventoryTableCell"
                                          align="left"
                                        >
                                          {inventoryDetails[key] == null
                                            ? '-'
                                            : inventoryDetails[key]}
                                        </TableCell>
                                      )
                                  )}
                                  <TableCell className="tagCell">
                                    <div className="tagScrollBar tagList">
                                      {inventoryDetails.Tags &&
                                      inventoryDetails.Tags.length > 0 ? (
                                        inventoryDetails.Tags.reduce(
                                          (acc: any, row: any) => {
                                            let flag = 0;
                                            acc.map((tag: any) => {
                                              if (tag.key === row.key) flag = 1;
                                            });
                                            if (!flag) acc.push(row);

                                            return acc;
                                          },
                                          []
                                        ).map((tag: any, i: any) => (
                                          <div
                                            key={i}
                                            className="p-2 ml-4 mr-5"
                                          >
                                            <span className="p-2  pl-4 pr-4 tagKey  ">
                                              {tag.key}
                                            </span>
                                            <span className="p-2 pl-4 pr-4 tagValue ">
                                              {tag.value}
                                            </span>
                                          </div>
                                        ))
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )
                            )
                          ) : (
                            <TableRow key={'row' + index}>
                              <TableCell
                                className="inventoryTableCell"
                                colSpan={17}
                              >
                                No records found
                              </TableCell>
                            </TableRow>
                          )
                        )
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        ))
      ) : (
        <>
          <div className="noRecordsFound">Loading...</div>
        </>
      )}
    </React.Fragment>
  );
};

export default SearchedResults;
