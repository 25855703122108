import React from 'react';
import {
  Grid,
  FormControl,
  OutlinedInput,
  InputLabel,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas';
import { accountService } from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { useDispatch } from 'react-redux';
import { handleError } from '../../../utils/config';

const AWSform = (props: any) => {
  const Routes = RoutesFun();
  const classes = useStyles();
  const helperClasses = helperStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();

  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    Type: 'Account',
    AccountId: '',
    AccessKeyId: '',
    SecretAccessKeyId: '',
    BucketName: '',
    BucketRegion: '',
    BucketPrefix: '',
  });

  const [showClientSecret, setShowClientSecret] = React.useState<any>(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [errmsgDisplay, seterrmsgDisplay] = React.useState('d-none');
  const [successMsg, setSuccessMsg] = React.useState<any>([]);
  const [successmsgDisplay, setsuccessmsgDisplay] = React.useState('d-none');
  const [buttonDisabled, setButtonDisabled] = React.useState<any>(false);
  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const onSubmit = async () => {
    try {
      setLoader(true);
      if (accountDetails) {
        // const accountData = { CloudProvider: 'AWS' };
        // dispatch(
        //   accountActions.replaceAccountState({
        //     selectedAccount: accountData,
        //     isAccountSelected: true,
        //   })
        // );
        const response = await accountService.createAccount(accountDetails);
        setErrorMsg('');
        setSuccessMsg(response);
        seterrmsgDisplay('d-none');
        setsuccessmsgDisplay('d-block');
        setButtonDisabled(true);
        setTimeout(() => {
          history.push(Routes.AccountDetails);
          setLoader(false);
        }, 2000);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      setButtonDisabled(false);
      if (e && e.error && e.error.message) {
        if (e.error.message !== 'The provided credentials is invalid!') {
          setErrorMsg(e.error.message);
        }
        const company = localStorage.getItem('CompanyName');
        seterrmsgDisplay('d-block');
        setsuccessmsgDisplay('d-none');
        handleError(e, history);
        if (e.error.message == 'The provided credentials is invalid!') {
          history.push('/' + company + '/accounts');
        }
      }
    } finally {
      // setTimeout(() => {
      setLoader(false);
      // }, 2000);
    }
  };

  const handleClickShowClientSecret = () => {
    setShowClientSecret(!showClientSecret);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div className="mt-10">
      {loader ? <LoadingScreen /> : ''}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol className={helperClasses.p0}>
                  <li>
                    <span>
                      Log in to your AWS Management Console and access the IAM
                      dashboard.
                    </span>
                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className="mt-15 mb-20 configureFormElement w-70"
                      >
                        <InputLabel
                          className=""
                          id="demo-mutiple-name-label"
                          htmlFor="accountId"
                        >
                          Account ID
                        </InputLabel>
                        <OutlinedInput
                          id="accountId"
                          type="text"
                          name="AccountId"
                          inputRef={register({
                            required: true,
                            // pattern: /^[^-\s][\/a-zA-Z0-9@._\s-~]*$/
                          })}
                          defaultValue={accountDetails.AccountId}
                          onChange={handleChange}
                          // MenuProps={MenuProps}
                        />
                        <span className={classes.warningMessage}>
                          {(formState.touched.AccountId &&
                            accountDetails.AccountId.length === 0 &&
                            'Account Id is required') ||
                            (errors.AccountId && 'Enter valid Account ID')}
                        </span>
                      </FormControl>
                    </Grid>
                  </li>
                  <li>
                    Select Policies from the left menu and click the Create
                    policy button.
                  </li>
                  <li>
                    Go to our support site and copy the policy or policies that
                    apply to your business needs.
                  </li>
                  <li>
                    <span>For each policy, follow these steps:</span>
                    <ol>
                      <li>
                        Click the JSON tab, and paste the new policy into the
                        tab.
                      </li>
                      <li>Click Review Policy.</li>
                      <li>
                        Type a name for the policy and click Create policy.
                      </li>
                      <li>
                        Select the policy from the list and from the Policy
                        actions drop-down menu, select Attach.
                      </li>
                      <p>
                        <b>Note:</b> For any DBR, CUR, or Cloud Trail policies
                        that you create, make sure that you replace the default
                        S3 bucket with the name of the new S3 bucket identified
                        in the policy.
                      </p>
                    </ol>
                  </li>
                  <li>
                    Select Groups from the left menu and click the Create New
                    Group button.
                  </li>
                  <li>
                    Type a name for the group, attach your policy, and click
                    Create Group.
                  </li>
                  <li>Select Users from the left menu and click Add User.</li>
                  <li>
                    Type a name for the new user, select the Programmatic access
                    checkbox, and click Next: Permissions.
                  </li>
                  <li>Select the new group from the list.</li>
                  <li>Click Next: Review and click Create user</li>
                  <li>
                    <span>
                      Copy the Access Key ID and the Secret Access Key and paste
                      the values in the fields:
                    </span>

                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className="mt-15 mb-20 configureFormElement w-70"
                      >
                        <InputLabel
                          className=""
                          id="demo-mutiple-name-label"
                          htmlFor="bucketName"
                        >
                          Bucket Name
                        </InputLabel>
                        <OutlinedInput
                          id="bucketName"
                          type="text"
                          name="BucketName"
                          inputRef={register({
                            required: true,
                            minLength: 3,
                            maxLength: 63,
                            pattern: /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/,
                          })}
                          defaultValue={accountDetails.BucketName}
                          onChange={handleChange}
                          // MenuProps={MenuProps}
                        />
                        <span className={classes.warningMessage}>
                          {(formState.touched.BucketName &&
                            accountDetails.BucketName.length === 0 &&
                            'Bucket Name is required') ||
                            (errors.BucketName && 'Enter valid Bucket Name')}
                        </span>
                      </FormControl>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className="mt-15 mb-20 configureFormElement w-70"
                      >
                        <InputLabel
                          className=""
                          id="demo-mutiple-name-label"
                          htmlFor="bucketRegion"
                        >
                          Bucket Region
                        </InputLabel>
                        <OutlinedInput
                          id="bucketRegion"
                          type="text"
                          name="BucketRegion"
                          inputRef={register({
                            required: true,
                            minLength: 3,
                            maxLength: 63,
                            pattern: /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/,
                          })}
                          defaultValue={accountDetails.BucketRegion}
                          onChange={handleChange}
                          // MenuProps={MenuProps}
                        />
                        <span className={classes.warningMessage}>
                          {(formState.touched.BucketRegion &&
                            accountDetails.BucketRegion.length === 0 &&
                            'Bucket Region is required') ||
                            (errors.BucketRegion &&
                              'Enter valid Bucket Region')}
                        </span>
                      </FormControl>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className="mt-15 mb-20 configureFormElement w-70"
                      >
                        <InputLabel
                          className=""
                          id="demo-mutiple-name-label"
                          htmlFor="bucketPrefix"
                        >
                          Bucket Prefix
                        </InputLabel>
                        <OutlinedInput
                          id="bucketPrefix"
                          type="text"
                          name="BucketPrefix"
                          inputRef={register({
                            required: true,
                          })}
                          defaultValue={accountDetails.BucketPrefix}
                          onChange={handleChange}
                          // MenuProps={MenuProps}
                        />
                        <span className={classes.warningMessage}>
                          {(formState.touched.BucketPrefix &&
                            accountDetails.BucketPrefix.length === 0 &&
                            'Bucket Prefix is required') ||
                            (errors.BucketPrefix &&
                              'Enter valid Bucket Prefix')}
                        </span>
                      </FormControl>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className="mt-15 mb-20 configureFormElement w-70"
                      >
                        <InputLabel
                          className=""
                          id="demo-mutiple-name-label"
                          htmlFor="accessKeyId"
                        >
                          Access Key ID
                        </InputLabel>
                        <OutlinedInput
                          id="accessKeyId"
                          type="text"
                          name="AccessKeyId"
                          inputRef={register({
                            required: true,
                            pattern: /^[^-\s][a-zA-Z0-9@._\s-~]*$/,
                          })}
                          defaultValue={accountDetails.AccessKeyId}
                          onChange={handleChange}
                          // MenuProps={MenuProps}
                        />
                        <span className={classes.warningMessage}>
                          {(formState.touched.AccessKeyId &&
                            accountDetails.AccessKeyId.length === 0 &&
                            'Access Key Id is required') ||
                            (errors.AccessKeyId && 'Enter valid Access Key Id')}
                        </span>
                      </FormControl>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl
                        variant="outlined"
                        className="mt-15 mb-20 configureFormElement w-70"
                      >
                        <InputLabel
                          className=""
                          id="demo-mutiple-name-label"
                          htmlFor="secretAccessKeyId"
                        >
                          Secret Access Key
                        </InputLabel>
                        <OutlinedInput
                          id="secretAccessKeyId"
                          type="text"
                          name="SecretAccessKeyId"
                          inputRef={register({
                            required: true,
                            // pattern: /\/^[^-\s][a-zA-Z0-9@._\s-~]*$/
                          })}
                          // defaultValue={accountDetails.SecretAccessKeyId}
                          onChange={handleChange}
                          // MenuProps={MenuProps}
                        />
                        <span className={classes.warningMessage}>
                          {(formState.touched.SecretAccessKeyId &&
                            accountDetails.SecretAccessKeyId.length === 0 &&
                            'Secret Access Key Id Id is required') ||
                            (errors.SecretAccessKeyId &&
                              'Enter valid Secret Access Key Id Id')}
                        </span>
                      </FormControl>
                    </Grid>
                  </li>
                </ol>
              </ul>
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className="accountsMsg m-5 mx-10"
            >
              <div
                className={
                  errorMsg.length
                    ? 'accountsMsg alert-danger p-8 px-20 mt-5 mb-5 w-100 font-14px' +
                      errmsgDisplay
                    : ''
                }
              >
                {errorMsg}
              </div>
              <div
                className={
                  successMsg.length
                    ? 'accountsMsg alert-success p-8 px-20 mt-5 mb-5 w-100 font-14px' +
                      successmsgDisplay
                    : ''
                }
              >
                {successMsg}
              </div>
            </Grid>
          </Grid>
          <Grid container className="configureAccountBtns">
            <button
              className={`${
                buttonDisabled ? classes.disabledButton : ''
              } btn btn-secondary`}
              onClick={props.cancel}
              disabled={buttonDisabled}
            >
              BACK
            </button>

            <button
              type="submit"
              className={`${
                buttonDisabled ? classes.disabledButton : ''
              } btn btn-primary`}
              disabled={buttonDisabled}
            >
              UPDATE
            </button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AWSform;
