import { Box, Divider } from '@material-ui/core';
import * as echarts from 'echarts';
import './rumComponents.css';
import CustomBarChart from './barChart';
import { Skeleton } from '@mui/material';
import { GraphData } from '../../pages/RUM/interfaces';
import Chart from './RUMChart';
import CustomTooltip from '../MasterComponent/CustomTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export interface topEntityProps {
  title: string;
  subtitle1: string;
  subtitle2: string;
  tooltip: string;
  loader: boolean;
  pageLoadData: GraphData[];
  sessionsData: GraphData[];
}

export const RUMColors = [
  '#066bc2',
  '#4fa2ea',
  '#77b3ec',
  '#8ab6e6',
  '#a3ceee',
  '#daefff',
];

export const TopEntityCard = ({
  title,
  subtitle1,
  subtitle2,
  tooltip,
  loader,
  pageLoadData,
  sessionsData,
}: topEntityProps) => {
  const XAxisTitles: string[] = pageLoadData.map((data) => data.name);

  const pieAndBarDataHandler = (data: any) => {
    const pieOptions: echarts.EChartsOption = {
      tooltip: {
        trigger: 'item',
        //   formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        left: 'right',
        // top: 'top',
        // bottom: 'top',
        icon: 'circle',
        //   align: 'right',
        itemGap: 5, // Adjust this value to change the spacing between legend items
        padding: [30, 30, 0, 0],
        data: data?.map((d: any) => d.name),
        //   data: [{ name: 'Chrome' }],
      },
      series: [
        {
          name: 'Sessions',
          type: 'pie',
          radius: '55px',
          top: '-5',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          color: RUMColors,
          label: {
            show: false,
          },
          // right: 250,
          left: '-50px',
        },
      ],
    };

    return pieOptions;
  };

  return (
    <Box
      className="top-entity-card"
      style={{ height: '48%', border: '1px solid #e0e0e0', padding: '20px' }}
    >
      <div
        className="section-title descriptionCardTitle"
        style={{
          textAlign: 'left',
          marginBottom: '10px',
          paddingBottom: '0px',
          width: '14.5rem',
        }}
      >
        {title}
        <CustomTooltip title={tooltip}>
          <HelpOutlineIcon
            style={{
              fontSize: '16px',
              cursor: 'pointer',
              color: '#666',
            }}
          />
        </CustomTooltip>
      </div>
      <Box className="top-entity-chart">
        <div className="bar-chart">
          <p className="fw-600">{subtitle1}</p>
          {loader ? (
            <Skeleton style={{ width: '90%', height: '17rem' }} />
          ) : (
            <CustomBarChart
              width="90%"
              height={165}
              XAxisTitles={XAxisTitles}
              // YAxisMax={100}
              data={pageLoadData}
              key={1}
            />
          )}
        </div>
        <Divider
          className="vertical-divider"
          orientation="vertical"
          style={{
            color: '1px solid grey',
            height: '190px',
            marginRight: '15px',
          }}
        />
        <Divider
          className="horizontal-divider"
          orientation="horizontal"
          style={{
            color: '1px solid grey',
            width: '100%',
          }}
        />
        <div className="pie-chart">
          <p className="fw-600">{subtitle2}</p>
          {loader ? (
            <Skeleton style={{ width: '90%', height: '17rem' }} />
          ) : (
            <Chart
              option={pieAndBarDataHandler(sessionsData)}
              style={{
                width: '100%',
                height: '165px',
                // border: '1px solid red',
              }}
            />
            // <div ref={pieRef} style={{ width: '100%', height: '150px' }}></div>
          )}
        </div>
      </Box>
    </Box>
  );
};
