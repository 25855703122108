import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { hasSpecialCharacters } from '../../../utils/config';

// interface IMenuItem {
//   item: string;
// }

interface ICustomMenuItem {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

type SelectProps = {
  menuItems: string[];
  // sendSelectItemList: (updatedData: string[], changedValue: string) => void;
  sendSelectedItem: (item: string) => void;
  itemSelected: string;
  // selectedValue: string;
  itemIdentifier: string;
};

const SelectAddItem: React.FC<SelectProps> = ({
  menuItems,
  // sendSelectItemList,
  sendSelectedItem,
  // selectedValue,
  itemIdentifier,
  itemSelected,
}) => {
  // const [environments, setEnvironments] = useState([
  //   { value: 10, label: 'Ten' },
  //   { value: 20, label: 'Twenty' },
  //   { value: 30, label: 'Thirty' },
  // ]);
  // const [selectedValue, setSelectedValue] = useState(20); // Initial selected value
  const [isAddingItem, setIsAddingItem] = useState(false); // Flag for add item state
  // const [itemSelected, setItemSelected] = useState<string>(selectedValue);
  const [itemList, setItemList] = useState<string[]>(menuItems);
  const newItemInputRef = useRef<HTMLInputElement>(null); // Reference to new item input field
  const [isSingleItemAdded, setIsSingleItemAdded] = useState<boolean>(false);
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    sendSelectedItem(event.target.value);
    // sendSelectItemList(itemList, changedValue);
  };

  const handleAddItem = () => {
    // Prevent adding empty items
    if (!newItemInputRef.current?.value.trim()) {
      return;
    }

    // const updatedData: string[] = [...menuItems];

    // updatedData.push(newItemInputRef.current.value);

    const updatedData = newItemInputRef.current.value;
    if (hasSpecialCharacters(updatedData)) {
      setIsSpecialCharacter(true);
      setDuplicateError(false);
      return;
    }
    if (
      itemList
        .map((item) => item.toLocaleLowerCase())
        .includes(updatedData.trim().toLocaleLowerCase())
    ) {
      setDuplicateError(true);
      setIsSpecialCharacter(false);
      return;
    }
    setItemList((prevItemList) => [...prevItemList, updatedData]);

    // sendSelectedItem(updatedData);

    sendSelectedItem(updatedData);
    setIsSingleItemAdded(true);

    setIsAddingItem(false); // Reset add item state after successful addition
    newItemInputRef.current.value = ''; // Clear input field
  };

  const handleAddItemClick = () => {
    // Only allow adding items if not already adding
    if (!isAddingItem) {
      setIsAddingItem(true);
    }

    newItemInputRef.current?.focus();
  };

  const CustomMenuItem = ({ children, onClick }: ICustomMenuItem) => {
    const handleClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      if (onClick) {
        onClick(event);
      }
    };

    return (
      <li
        className="MuiMenuItem-root"
        role="option"
        aria-selected="false"
        style={{ padding: '6px 16px' }}
        onClick={handleClick}
      >
        {children}
      </li>
    );
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation(); // Stop propagation only when the MenuItem itself is clicked
  };

  return (
    <FormControl fullWidth>
      <InputLabel style={{ fontSize: '14px' }} id="demo-simple-select-label">
        {itemIdentifier}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        style={{ fontSize: '14px' }}
        inputProps={{ style: { fontSize: 16 } }}
        id="demo-simple-select"
        value={itemSelected}
        label="Environment"
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '40vh',
            },
          },
        }}
      >
        {itemList.map((menuItem: string) => (
          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            key={menuItem}
            value={menuItem}
          >
            {menuItem}
          </MenuItem>
        ))}
        {/* Add Item button (conditionally rendered based on isAddingItem) */}

        {!isSingleItemAdded &&
          (isAddingItem ? (
            <CustomMenuItem onClick={handleMenuItemClick}>
              <TextField
                inputRef={newItemInputRef}
                label={`New ${itemIdentifier}`}
                variant="outlined"
                size="small"
                fullWidth
                onKeyDown={(e) => e.stopPropagation()} // Prevent click propagation
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleAddItem();
                  }
                }}
              />
              {duplicateError && (
                <div className="regMsg">Please add unique environment</div>
              )}
              {isSpecialCharacter && (
                <div className="regMsg">Special characters are not allowed</div>
              )}
            </CustomMenuItem>
          ) : (
            <CustomMenuItem>
              <Button
                onClick={handleAddItemClick}
                variant="contained"
                size="small"
                style={{ padding: '6px 16px' }}
              >
                Add {itemIdentifier}
              </Button>
            </CustomMenuItem>
          ))}

        {/* Input field for adding new item (conditionally rendered) */}
      </Select>
    </FormControl>
  );
};

export default SelectAddItem;
