import { APIS } from '../../utils/apiConfig';
import { HttpService } from '../HttpService/HttpService';

export class UrlMonitoringService {
  constructor(private httpService: HttpService) {}
  async getUrlMonitoring(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.GET_URL_MONITORING(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async addUrlMaintenance(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        APIS.ADD_URL_MAINTENANCE(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async editUrlMaintenance(params: any): Promise<any> {
    try {
      const response = await this.httpService.patch(
        APIS.EDIT_URL_MAINTENANCE(),
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getSSLDays(): Promise<any> {
    try {
      const response = await this.httpService.get(APIS.GET_SSL_DAYS());
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async setSSLDays(params: { RecommendedDays: string }): Promise<any> {
    try {
      const response = await this.httpService.post(APIS.SET_SSL_DAYS(), params);
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
  async deleteSSLDays(): Promise<any> {
    try {
      const response = await this.httpService.delete(
        APIS.DELETE_SSL_DAYS(),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        return data.result;
      }
    } catch (e) {
      throw e;
    }
  }
}
