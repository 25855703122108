import { billingService } from '../../services';

export const config: any = {
  createCheckout: (
    id: string,
    priceId: string,
    planName: string,
    PlanSubscriptionId: string,
    autoRenew: boolean,
    SubscriptionStatus: string
  ) =>
    billingService.createCheckout(
      id,
      priceId,
      planName,
      PlanSubscriptionId,
      autoRenew,
      SubscriptionStatus
    ),
  addCard: (id: string) => billingService.addCard(id),
  getCard: (id: string) => billingService.getCard(id),
  getPlans: (companyName: string) => billingService.getPlans(companyName),
  cancelSubscription: (PlanSubscriptionId: string) =>
    billingService.cancleSubscription(PlanSubscriptionId),
  deleteCard: (PaymentMethodId: string) =>
    billingService.deleteCard(PaymentMethodId),
  companyDetails: () => billingService.companyDetails(),
  retryPayment: (stripeCustomerId: string, planSubscriptionId: string) =>
    billingService.retryPayment(stripeCustomerId, planSubscriptionId),
  requestQuoteModal: (companyName: string, planName: string) =>
    billingService.requestQuote(companyName, planName),
  setAsDefault: (params: {
    stripe_customer_id: string;
    PaymentMethodId: string;
  }) => billingService.setAsDefault(params),
};

interface PlanData {
  [planName: string]: string[];
}

export const plansInfo: PlanData = {
  Basic: [
    '$4.99/mo',
    '50 Endpoints',
    '300 second interval',
    'HTTP, Keyword, SSL Monitoring',
    'AWS/Azure Price Monitoring',
    'Infrastructure Security Scans',
  ],
  Growth: [
    '200 Endpoints',
    '60 second interval',
    'HTTP, Keyword, Port, and Ping Monitoring',
    'RightSizing',
    'Idle Instance Monitoring',
    'Instance Scheduling',
    'Inventory Reports',
  ],
  'Growth+': [
    'Regular PenTesting',
    'Regular Cost Audits',
    '24/7 Monitoring',
    'Patch Management',
    'SOC and HIPAA Compliance',
  ],
};
