import { createSlice } from '@reduxjs/toolkit';
import { AccountDetailsProps } from '../schemas/account.schema';
import { ALL_VALUE } from '../pages/Invoice/config';

interface accountStateProps {
  selectedAccount: AccountDetailsProps | undefined;
  isAccountSelected: boolean;
  selectedAccountName: string;
  selectedSubscriptionName: string;
  selectedAccountId: string;
  selectedSubscriptionId: string;
  selectedSubData: any;
  defaultCurrency: string;
}

const initialUserState: accountStateProps = {
  selectedAccount: undefined,
  isAccountSelected: false,
  selectedAccountName: ALL_VALUE,
  selectedSubscriptionName: ALL_VALUE,
  selectedAccountId: ALL_VALUE,
  selectedSubscriptionId: ALL_VALUE,
  selectedSubData: [],
  defaultCurrency: 'USD',
};
const accountSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    replaceAccountState(state, action) {
      state.selectedAccount = action.payload.selectedAccount;
      state.isAccountSelected = action.payload.isAccountSelected;
      state.selectedAccountName = action.payload.selectedAccountName;
      state.selectedSubscriptionName = action.payload.selectedSubscriptionName;
      state.selectedAccountId = action.payload.selectedAccountId;
      state.selectedSubscriptionId = action.payload.selectedSubscriptionId;
      state.selectedSubData = action.payload.selectedSubData;
    },
    replaceDefaultCurrency(state, action) {
      state.defaultCurrency = action.payload;
    },
  },
});

export const accountActions = accountSlice.actions;
export default accountSlice.reducer;
