import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select,
  Button,
  MenuItem,
} from '@material-ui/core';
import useStyles from './ConfigureAccount.style';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountService,
  authService,
  httpService,
  userService,
} from '../../../services';
import helperStyles from '../../../styles/helper.styles';
import { handleError } from '../../../utils/config';

const ActiveDirectory = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const helperClasses = helperStyles();
  const location = useLocation();
  let state: any;
  if (location && location.state) {
    state = location.state;
  }

  const [accountDetails, setAccountDetails] = React.useState({
    ...state,
    ...(location && location.state
      ? { ...(location.state as Record<string, unknown>) }
      : {}),
    ServiceType: 'EA',
    SubscriptionId: '',
    ApplicationId: '',
    AccessKey: '',
    AccountType: '',
  });
  const [isNetworkError, setNetworkError] = React.useState(false);

  const { register, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: state,
  });

  const onSubmit = async () => {
    try {
      if (accountDetails) {
        // const response = await accountService.createAccount(accountDetails);
        // // toasterService.show(response, 'success');
        // history.push(Routes.AccountDetails);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      // throw e;
      //   toasterService.show(e && e.error && e.error.message, 'error');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setAccountDetails({
      ...accountDetails,
      [event.target.name]: event.target.value,
    });
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <p className="mb-10">
                Your resources or service usage data is accessed by CloudForestX
                if you have Azure Active Directory set up with Microsoft. To get
                instructions on how to navigate Active Directory, check out our{' '}
                <b>support page.</b>
              </p>
              <ul className={classes.list}>
                <ol className={helperClasses.p0}>
                  <li>
                    Login to the Azure Portal and opt for Active Directory in
                    the main toolbar. Then move to the Properties tab.
                  </li>
                  <li>Enter Microsoft Azure Active Directory ID here:</li>
                  <li>
                    Submit the <b>Enrollment Number</b> here:
                  </li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  MICROSOFT AZURE SUBSCRIPTION ID
                </InputLabel>
                <Input
                  id="demo-mutiple-name"
                  type="password"
                  name="SubscriptionId"
                  inputRef={register({ required: true })}
                  value={accountDetails.SubscriptionId}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.SubscriptionId &&
                    accountDetails.SubscriptionId.length === 0 &&
                    'SubscriptionId is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={4} className={helperClasses.p0}>
                  <li>
                    Move to the App Registrations tab and click Add in the
                    toolbar.
                  </li>
                  <li>Write the following values:</li>
                  <p>Name: CloudForestX</p>
                  <p>Application Type: Web app / API</p>
                  <p>Sign-on URL: https://localhost/logon</p>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={5} className={helperClasses.p0}>
                  <li>
                    Save and from the listing, choose the newly created app
                    named CloudForestX.
                  </li>
                  <li>Enter Application ID:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  APPLICATION ID
                </InputLabel>
                <Input
                  id="applictionId"
                  type="password"
                  name="ApplicationId"
                  inputRef={register({ required: true })}
                  value={accountDetails.ApplicationId}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.ApplicationId &&
                    accountDetails.ApplicationId.length === 0 &&
                    'ApplicationId is required'}
                </span>
              </FormControl>
              <span></span>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={7} className={helperClasses.p0}>
                  <li>
                    Select Keys from the Settings menu. Enter details of the new
                    key, select duration, and save.
                  </li>
                  <li>Enter the value of the newly created key here:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">ACCESS KEY</InputLabel>
                <Input
                  id="demo-mutiple-name"
                  type="password"
                  name="AccessKey"
                  inputRef={register({ required: true })}
                  value={accountDetails.AccessKey}
                  onChange={handleChange}
                  // MenuProps={MenuProps}
                />
                <span className={classes.warningMessage}>
                  {formState.touched.AccessKey &&
                    accountDetails.AccessKey.length === 0 &&
                    'Access Key is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ul className={classes.list}>
                <ol start={9} className={helperClasses.p0}>
                  <li>
                    Set new permissions for Microsoft Graph in the Required
                    Permissions.
                  </li>
                  <li>
                    Select the following permission in Application Permissions
                    and hit save:
                  </li>
                  <ol type="a">
                    <li>Read Directory Data</li>
                    <li>Read All Usage Reports</li>
                  </ol>
                  <li>
                    Grant these permissions to your application by clicking
                    Grant Permission.
                  </li>
                  <li>Choose your Azure Account type:</li>
                </ol>
              </ul>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">
                  ACCOUNT TYPE
                </InputLabel>
                <Select
                  id="demo-mutiple-name"
                  // multiple
                  name="AccountType"
                  value={accountDetails.AccountType}
                  ref={register({ required: true })}
                  onChange={handleChange}
                  input={<Input />}
                  // MenuProps={MenuProps}
                >
                  {['Commercial', 'Azure Government', 'Azure Germany'].map(
                    (item: any) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
                <span className={classes.warningMessage}>
                  {errors?.AccountType && 'AccountType is required'}
                </span>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={6}></Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button className="noBtn" onClick={props.cancel}>
                BACK
              </button>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.footer}>
              <button type="submit" className="yesBtn">
                UPDATE
              </button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ActiveDirectory;
