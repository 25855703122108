import {
  adminDashboardService,
  documentUploadService,
  uploadedDocumentsService,
} from '../../../services';

export const config: any = {
  getUploadedDocuments: () => uploadedDocumentsService.getUploadedDocuments(),

  fetchCompaniesData: () => adminDashboardService.getAllOrgDetails(),
  updateUsersData: (params: any) =>
    adminDashboardService.updateUserDetails(params),
  deleteUsersData: (params: any) =>
    adminDashboardService.deleteUserDetails(params),
  addOrganizations: (params: any) =>
    documentUploadService.addOrganization(params),
  getAllUsersForSuperAdmin: () =>
    documentUploadService.getAllUsersForSuperAdmin(),
};
