import React, { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import './SetResourceWiseCost.css';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import '../Modal.css';
import { useHistory } from 'react-router-dom';
import { filterAwsAccountId, handleError } from '../../../utils/config';
import { CLOUD_PROVIDER_SERVICES } from '../../../utils/constants';
import { config } from '../../../pages/CostForecasting/BarGraphAndPieChart/config';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { ALL_VALUE } from '../../../pages/Invoice/config';
import { accountService } from '../../../services';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
  iType?: any;
  selected?: any;
  selectedAzureId?: any;
  selectedAWSId?: any;
  accountSelected?: string;
  selectedAccountId?: string;
  accountIdData?: any;
  sub?: Array<any>;
  resourceList?: any;
  // currency: any;
  user: any;
}

export const SetResourceWiseCostModal: React.FC<FixProps> = (props) => {
  const {
    onClose,
    selected,
    // currency,
    selectedAWSId,
    accountIdData,
    selectedAzureId,
    selectedAccountId,
    resourceList,
    accountSelected,
  } = props;
  const [errorRes, setErrorRes] = React.useState('');
  const [successRes, setSuccessRes] = React.useState('');
  const history = useHistory();
  const [buttonStatus, setButtonStatus] = React.useState(true);
  const [networkError, setNetworkError] = React.useState('');
  const [accountLimit, setAccountLimit] = React.useState<any>();
  const [loader, setLoader] = React.useState(false);
  const [cloudProvider] = useState(selected.CloudProvider.toUpperCase());
  const [resourceDetail, setResourceDetail] = useState<Record<string, any>[]>(
    []
  );
  const [saveResourceDetail, setSaveResourceDetail] = useState<any[]>([]);
  const [saveAwsResourceDetail, setSaveAwsResourceDetail] = useState<any[]>([]);
  const [resourceName, setResourceName] = useState<string>('');
  const [defaultValues, setDefaultValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [azureIdsLimit, setAzureIdsLimit] = useState<any[]>([]);

  const setData = (e: any, accountId: any) => {
    const inputValue = Number(e.target.value).toFixed(2);
    setButtonStatus(false);
    if (e.target.name === 'account') {
      setAccountLimit(inputValue);
      const index = saveAwsResourceDetail.findIndex(
        (item: any) => item.AccountId === accountId
      );
      if (index !== -1) {
        const updatedData = saveAwsResourceDetail.map((item: any) => {
          if (item.AccountId === accountId) {
            return {
              ...item,
              Cost: inputValue,
            };
          }
          return item;
        });
        setSaveAwsResourceDetail(updatedData);
      } else {
        setSaveAwsResourceDetail([
          ...saveAwsResourceDetail,
          {
            AccountId: accountId,
            Cost: inputValue,
            ResourseName: resourceName,
          },
        ]);
      }
    } else {
      const index = saveResourceDetail.findIndex(
        (item: any) => item.SubscriptionId === accountId
      );
      if (index !== -1) {
        // Subscription ID already exists, update the cost value for the existing object
        const updatedData = saveResourceDetail.map((item: any) => {
          if (item.SubscriptionId === accountId) {
            return {
              ...item,
              Cost: inputValue,
            };
          }
          return item;
        });
        setSaveResourceDetail(updatedData);
      } else {
        // Subscription ID does not exist, create a new object and add it to the array
        setSaveResourceDetail([
          ...saveResourceDetail,
          {
            SubscriptionId: accountId,
            Cost: inputValue,
            ResourseName: resourceName,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    const defaultValuesForSelectedResource: { [key: string]: number } = {};
    if (
      cloudProvider === CLOUD_PROVIDER_SERVICES.AWS &&
      resourceName.length > 0
    ) {
      resourceDetail.forEach((detail) => {
        defaultValuesForSelectedResource[detail.AccountId] =
          detail.Cost === 0 ? 'not set' : detail.Cost;
      });
      setDefaultValues(defaultValuesForSelectedResource);
    } else if (
      cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE &&
      resourceName.length > 0
    ) {
      resourceDetail.forEach((detail) => {
        defaultValuesForSelectedResource[detail.SubscriptionId] =
          detail.Cost === 0 ? 'not set' : detail.Cost;
      });
      setDefaultValues(defaultValuesForSelectedResource);
    }
  }, [resourceDetail]);

  const handleResourceChange = async (
    event: React.ChangeEvent<Record<string, unknown>>
  ) => {
    const resourceName: any = event.target.value;
    setResourceName(resourceName);

    let data;
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
      if (selectedAWSId === ALL_VALUE) {
        data = {
          AccountId: selectedAWSId,
          ResourseName: resourceName,
        };
      } else {
        const accountId = filterAwsAccountId(accountIdData, selectedAWSId);
        data = {
          AccountId: accountId,
          ResourseName: resourceName,
        };
      }
    } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (selectedAzureId === ALL_VALUE && accountSelected === ALL_VALUE) {
        data = {
          SubscriptionId: selectedAccountId,
          ResourseName: resourceName,
        };
      } else if (
        selectedAzureId !== ALL_VALUE &&
        accountSelected === ALL_VALUE
      ) {
        data = {
          AzureId: selectedAccountId,
          ResourseName: resourceName,
        };
      } else if (
        selectedAzureId !== ALL_VALUE &&
        accountSelected !== ALL_VALUE
      ) {
        data = {
          SubscriptionId: accountSelected,
          ResourseName: resourceName,
        };
      }
    }

    try {
      setLoader(true);

      const result = await accountService.getAccountDetails(cloudProvider);
      setAzureIdsLimit(result);

      let response;
      if (resourceName.length > 0) {
        response = await config[cloudProvider].getResourceDetail(data);
      }

      setResourceDetail(response);
      setLoader(false);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        setErrorRes(err);
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const submit = async () => {
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS && accountLimit === '') {
      setErrorRes('Limit cannot be empty');
      return;
    } else {
      let flag = true;
      saveResourceDetail.forEach((el: any) => {
        if (el.Cost === '') flag = false;
      });
      if (!flag) {
        setErrorRes('Limit cannot be empty');
        return;
      }
    }

    let data;
    cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE
      ? (data = saveResourceDetail)
      : cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
      ? (data = saveAwsResourceDetail)
      : (data = saveAwsResourceDetail);

    setButtonStatus(true);
    try {
      const response = await config[cloudProvider].setResourceCost(data);
      if (response) {
        setErrorRes('');
        setSuccessRes(response.message);
      }
      setTimeout(() => {
        onClose && onClose();
      }, 2000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;

        setErrorRes(err);
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setButtonStatus(false);
        setSuccessRes('');
      }, 1000);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className={
        cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE
          ? 'fixModal setResourceWiseCostLimit-fixModal'
          : 'fixModal'
      }
      {...props}
    >
      <Grid container spacing={0}>
        {loader ? <LoadingScreen /> : ''}
        <Grid item md={12} sm={12} xs={12} className="modalHeader ">
          <div>
            <span className="modalTitle">Set Resource Wise Limit</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <Grid item md={12} className="modalBody ">
          <form noValidate>
            <TableContainer className="">
              <Table className="tableBody">
                <TableHead className="theadBlue">
                  <TableRow>
                    <TableCell colSpan={2} className="font-13px border0">
                      Resources
                    </TableCell>
                    <TableCell className="border0" align="right">
                      <div className="resourceSelect mb-0">
                        <FormControl
                          variant="outlined"
                          className="w-50 mt-0 p-0"
                        >
                          <InputLabel id="demo-simple-select-autowidth-label">
                            Resource List
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={resourceName}
                            onChange={handleResourceChange}
                            label="Subscription"
                            className="p-0"
                          >
                            <MenuItem value="">SELECT</MenuItem>
                            {resourceList.map((resource: any, index: any) => (
                              <MenuItem
                                key={index}
                                value={resource?.resoursename}
                              >
                                {resource?.resoursename}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {resourceDetail && resourceName.length ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className="p-0 border0" colSpan={3}>
                        <TableContainer className="table-scroll p-0 mt-10">
                          <Table className="pl-10">
                            <TableHead className="subTableHead">
                              <TableRow>
                                {cloudProvider ===
                                CLOUD_PROVIDER_SERVICES.AZURE ? (
                                  <TableCell className="font-13px">
                                    Azure Id Name
                                  </TableCell>
                                ) : null}
                                <TableCell className="font-13px">
                                  {cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                                    ? 'Account Name'
                                    : 'Subscription Name'}
                                </TableCell>
                                <TableCell className="font-13px">
                                  {cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                                    ? 'Account Id'
                                    : 'Subscription Id'}
                                </TableCell>

                                <TableCell className="font-13px">
                                  Set Limit
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody className="tbody">
                              {resourceDetail?.map((s, i) => (
                                <TableRow className="tableRow" key={'sub' + i}>
                                  {cloudProvider ===
                                  CLOUD_PROVIDER_SERVICES.AZURE ? (
                                    <TableCell className="font-12px">
                                      {azureIdsLimit?.map((data) => {
                                        if (data.AzureId === s.AzureId) {
                                          return data.AliasName;
                                        }
                                      })}
                                    </TableCell>
                                  ) : null}

                                  <TableCell className="font-12px">
                                    {cloudProvider ===
                                    CLOUD_PROVIDER_SERVICES.AWS
                                      ? s.AccountName
                                      : s.SubscriptionName}
                                  </TableCell>
                                  <TableCell className="font-12px">
                                    {cloudProvider ===
                                    CLOUD_PROVIDER_SERVICES.AWS
                                      ? s.AccountId
                                      : s.SubscriptionId}
                                  </TableCell>

                                  <TableCell>
                                    <div className="setLimitInput">
                                      <span className="font-15px mr-5">$</span>

                                      <input
                                        type="number"
                                        defaultValue={
                                          cloudProvider ===
                                          CLOUD_PROVIDER_SERVICES.AWS
                                            ? defaultValues[s.AccountId]
                                            : defaultValues[s.SubscriptionId]
                                        }
                                        min="0"
                                        placeholder={
                                          s.Cost === 0 ? 'not set' : ''
                                        }
                                        name={
                                          cloudProvider ===
                                          CLOUD_PROVIDER_SERVICES.AWS
                                            ? 'account'
                                            : s.SubscriptionId
                                        }
                                        onChange={(event) =>
                                          setData(
                                            event,
                                            cloudProvider ===
                                              CLOUD_PROVIDER_SERVICES.AWS
                                              ? s.AccountId
                                              : s.SubscriptionId
                                          )
                                        }
                                        className="input mr-10"
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </form>

          {errorRes && <div className="regMsg mt-10">{errorRes}</div>}
          {successRes && (
            <div className="alert-success p-2 px-5 mt-10">{successRes}</div>
          )}
        </Grid>
        <div className="mt-0 modalFooter">
          <input
            type="submit"
            className="btn btn-primary ml-10"
            value="SAVE"
            disabled={buttonStatus}
            onClick={submit}
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};
