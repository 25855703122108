import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { PageData } from './config';

const EnhancedTableHead: React.FC<{
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PageData
  ) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}> = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property: keyof PageData) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  interface HeadCell {
    id: keyof PageData;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    { id: 'pageUrl', numeric: false, label: 'Page URL' },
    {
      id: 'pageViews',
      numeric: true,
      label: 'Page Views',
    },
    {
      id: 'isExitPage',
      numeric: true,
      label: 'Exit Rate (%)',
    },
    {
      id: 'medianLoadTime',
      numeric: true,
      label: 'Median Load Time',
    },
    {
      id: 'totalPageLoadTime',
      numeric: false,
      label: 'Total Page Load Time',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
