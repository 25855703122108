import { invoiceService } from '../../services';

export const storageKeys: { [keys: string]: string } = {
  INVOICE_HISTORY_ROWS_PER_PAGE: 'INVOICE_HISTORY_ROWS_PER_PAGE',
  INVOICE_HISTORY_PAGE_NO: 'INVOICE_HISTORY_PAGE_NO',
  SCHEDULED_INVOICE_ROWS_PER_PAGE: 'SCHEDULED_INVOICE_ROWS_PER_PAGE',
  SCHEDULED_INVOICE_PAGE_NO: 'SCHEDULED_INVOICE_PAGE_NO',
  INVOICE_SELECTED_SUBSCRIPTION: 'INVOICE_SELECTED_SUBSCRIPTION',
};

export interface tagDetail {
  tagKey?: string;
  tagValue?: string;
  currentMonthCost?: number;
  previousMonthCost?: number;
  approxCost?: number;
  percentChange?: number;
  order?: string;
}

// export interface resourceDetail {
//   provider?: string;
//   year?: any;
//   accountId?: string;
//   azureId?: string;
//   subscriptionId?: string;
// }

export const formattedTagBillingData = (
  data: any,
  formatMonthSelected: string
) => {
  const resourceCostData = data?.currentMonthData.filter(
    (obj: any) => +obj.TotalCost >= 0.01
  );

  const previousTagData = data?.previousMonthData;

  const filterResourceCostData = resourceCostData?.filter((obj: any) => {
    return previousTagData.some(
      (f: any) => f.Key === obj.Key && f.Value === obj.Value
    );
  });

  const sortedResourceData = filterResourceCostData.sort(
    (a: any, b: any) => b.TotalCost - a.TotalCost
  );

  const formatCostResources = sortedResourceData.reduce(
    (accumulator: any, currentValue: any) => {
      const obj: tagDetail = {};
      const previousTagData = data?.previousMonthData.filter(
        (obj: any) =>
          obj.Key === currentValue.Key && obj.Value === currentValue.Value
      );
      const previousMonthCost: number = +previousTagData[0]?.TotalCost;
      const currentMonthCost: number = +currentValue.TotalCost;
      let approxCost: number;

      obj.tagKey = currentValue.Key;
      obj.tagValue = currentValue.Value;
      obj.currentMonthCost = currentMonthCost;
      if (formatMonthSelected === monthChosen.current) {
        approxCost = (currentMonthCost / (new Date().getDate() - 1)) * 30;
        obj.approxCost = approxCost;
        obj.percentChange =
          (+(+approxCost.toFixed(2) - +previousMonthCost.toFixed(2)) /
            (+previousMonthCost.toFixed(2) === 0.0
              ? 1
              : +previousMonthCost.toFixed(2))) *
          100;
      } else {
        obj.approxCost = currentMonthCost;
        obj.percentChange =
          (+(+currentMonthCost.toFixed(2) - +previousMonthCost.toFixed(2)) /
            (+previousMonthCost.toFixed(2) === 0.0
              ? 1
              : +previousMonthCost.toFixed(2))) *
          100;
      }

      if (obj.percentChange < 0) obj.order = order.low;
      else obj.order = order.high;

      obj.previousMonthCost = previousMonthCost;

      accumulator?.push(obj);
      return accumulator;
    },
    []
  );
  const highestDiff: any = formatCostResources;
  const lowestDiff: any = formatCostResources;
  return {
    highResourcesData: highestDiff.filter(
      (obj: any) => obj.currentMonthCost >= 0.01
    ),
    lowResourcesData: lowestDiff,
  };
};

export const config: any = {
  tagBillingCost: (params: string) => invoiceService.billingCostByTag(params),
};

export const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthChosen: { [key: string]: string } = {
  current: 'current',
  previous: 'previous',
};

export const ALL_VALUE = 'all';

export const order: { [key: string]: string } = {
  high: 'high',
  low: 'low',
};
