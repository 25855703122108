import React, { useState } from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import './AddOrganizationModal.css';

interface AddOrganizationInterface {
  show: boolean;
  onClose: { (): void };
  onSubmit: {
    (CompanyName: string, EmailAddress: string): void;
  };
}

const AddOrganizationModal: React.FC<AddOrganizationInterface> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const [organizationDetails, setOrganizationDetails] = useState({
    Name: '',
    Email: '',
  });

  const handleChange = (e: any) => {
    setOrganizationDetails({
      ...organizationDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
    >
      {' '}
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">Add Organization Details</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>
        <div className=" AddOrgBody mt-25 ">
          <Grid item md={12} className="modalBody pt-20">
            <div className="mb-30 ">
              <TextField
                inputProps={{ style: { fontSize: 16 } }}
                InputLabelProps={{ style: { fontSize: 16 } }}
                className="w-80 ml-25 AddOrgTextFields"
                id="outlined-basic"
                label="Organization Name"
                variant="outlined"
                onChange={handleChange}
                value={organizationDetails.Name}
                name="Name"
                required
                autoComplete="off"
              />
            </div>

            <div>
              <TextField
                inputProps={{ style: { fontSize: 16 } }}
                InputLabelProps={{ style: { fontSize: 16 } }}
                className="w-80 ml-25 AddOrgTextFields"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={handleChange}
                value={organizationDetails.Email}
                name="Email"
                required
                autoComplete="off"
              />
            </div>
          </Grid>
          <div className="mt-15 mb-25 ">
            <button
              type="button"
              className="discardButton mr-15 "
              onClick={onClose}
            >
              Discard
            </button>
            <button
              type="button"
              className="acceptButton"
              onClick={() =>
                onSubmit(organizationDetails.Name, organizationDetails.Email)
              }
            >
              Save
            </button>
          </div>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddOrganizationModal;
