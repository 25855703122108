import React, { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './DocumentUpload.css';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { Autocomplete, Checkbox } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ListItemText } from '@material-ui/core';
import { Box } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import { config } from './config';
import TextField from '@mui/material/TextField';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableContainer } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ReactComponent as PDFIcon } from '../../../assets/ICONS/pdf-files-icon.svg';
import { ReactComponent as CSVIcon } from '../../../assets/ICONS/csv-file-icon.svg';
import { ReactComponent as DOCIcon } from '../../../assets/ICONS/doc-file-icon.svg';
import { ReactComponent as DOCXIcon } from '../../../assets/ICONS/docx-file-icon.svg';
import { ReactComponent as XLSXIcon } from '../../../assets/ICONS/xlsx-file-icon.svg';
import { ReactComponent as PPTIcon } from '../../../assets/ICONS/ppt-file-icon.svg';
import { ReactComponent as FileErrorIcon } from '../../../assets/ICONS/document-error-icon.svg';
import { LinearProgress } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { ERRORS, EXCLUDED_EMAILS } from '../../../utils/constants';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/userSlice';
import AddOrganizationModal from '../../components/Modals/AddOrganizationModal/AddOrganizationModal';
import AddEmailsModal from '../../components/Modals/AddEmailsModal/AddEmailsModal';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const label = {
  inputProps: { 'aria-label': 'Checkbox demo' },
};

export interface UploadableFile {
  file: File;
  errors: FileError[];
}

let currentId = 0;
function getNewId() {
  return ++currentId;
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: '2px dashed #00bbdc',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(58),
    outline: 'none',
  },
}));

const useStylesRedZone = makeStyles((theme) => ({
  dropzone: {
    border: '2px dashed red',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(58),
    outline: 'none',
  },
}));

const emailRegex = /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/;
const RegexForSpecialChar = /[^a-zA-Z0-9_ -]/;

const DocumentUpload = () => {
  const classes = useStyles();
  const classesRedZone = useStylesRedZone();
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [documentShowLoader, setDocumentShowLoader] = useState(false);
  const [docs, setDocs] = useState([]);
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<UploadableFile[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [companyUsers, setCompanyUsers] = useState<string[]>([]);
  const [subCompanies, setSubCompanies] = useState<string[]>([]);
  const [filteredSubCompany, setFilteredSubCompany] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [selectLoader, setSelectLoader] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [progress, setProgress] = useState(0);
  const [networkError, setNetworkError] = useState('');
  const [note, setNote] = useState('');
  const [replyEmails, setReplyEmails] = useState('');
  const [mapedUsers, setMapedUsers] = useState<any>({});
  const [redDropZone, setRedDropZone] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  // const [showMenu, setShowMenu] = useState(false);
  const [
    showAddOrganizationModal,
    setShowAddOrganizationModal,
  ] = useState<boolean>(false);
  const [showAddEmailsModal, setShowAddEmailsModal] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [selectCompanyError, setSelectCompanyError] = useState(false);
  const [companyUsersError, setcompanyUsersError] = useState(false);
  const [replyToError, setReplyToError] = useState(false);
  const [mailSubjectError, setMailSubError] = useState(false);
  const [invalidFileError, setInvalidFileError] = useState('');
  const [friendlyNameError, setFriendlyNameError] = useState(false);
  const [friendlyName, setFriendlyName] = useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const onCloseAddOrganizationModal = () => setShowAddOrganizationModal(false);
  const onCloseAddEmailsModal = () => {
    setShowAddEmailsModal(false);
    setIsSelectOpen(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const companyRef = useRef<any>(null);
  const companyUsersRef = useRef<any>(null);
  const replyToRef = useRef<any>(null);
  const friendlyNameRef = useRef<any>(null);
  const subRef = useRef<any>(null);

  const handlePrivate = (e: any) => {
    setIsPrivate(e.target.checked);
  };

  // file upload
  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const mappedAcc = accFiles.map((file) => ({
      file,
      errors: [],
      id: getNewId(),
    }));
    const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
    setRejectedFiles(mappedRej);

    setProgress(0);
    setNote('');
    if (mappedAcc.length || mappedRej.length)
      setFiles((preValue: any) => [...preValue, ...mappedAcc, ...mappedRej]);
    setRedDropZone(false);
    setInvalidFileError('');
    setSuccessMsg(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
        '.docx',
      ],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
    },
    maxSize: 10 * 1024 * 1024, // 10mb
  });

  const UPLOADED_FILES_ICONS: {
    [key: string]: React.ReactNode;
  } = {
    'application/pdf': <PDFIcon width={38} height={38} />,
    'text/csv': <CSVIcon width={38} height={38} />,
    'application/vnd.ms-excel': <XLSXIcon width={38} height={38} />,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
      <XLSXIcon width={38} height={38} />
    ),
    'application/msword': <DOCIcon width={38} height={38} />,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
      <DOCXIcon width={38} height={38} />
    ),
    'application/vnd.ms-powerpoint': <PPTIcon width={38} height={38} />,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': (
      <PPTIcon width={38} height={38} />
    ),
  };

  // checkbox select
  const ITEM_HEIGHT = 58;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeSubscribedCompanyUsers = (e: any) => {
    const {
      target: { value },
    } = e;
    let flag = true;
    value.forEach((emails: any) => {
      if (emails === undefined) flag = false;
    });
    if (flag) setSelectedUsers(value);

    setcompanyUsersError(false);
  };

  const handleChangeCompany = (e: any, value: any) => {
    // const {
    //   target: { value },
    // } = e;
    setSelectedCompany(value);
    setSelectedUsers([]);
    setCompanyUsers([]);
    fetchCompanyUsers(value);
    setSelectCompanyError(false);
  };

  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
  };

  const handleReplyChange = (e: any) => {
    setReplyEmails(e.target.value);
  };
  const handleFriendlyName = (e: any) => {
    setFriendlyName(e.target.value);
  };
  const handleSubChange = (e: any) => {
    setMailSubject(e.target.value);
  };

  /// api calls

  const fetchCompanyUsers = async (company: string) => {
    try {
      setSelectLoader(true);
      const result = await config.getCompanyUsers(company);
      if (result.length) {
        result.forEach((user: any) => {
          if (user.IsActive && !EXCLUDED_EMAILS.includes(user.EmailAddress)) {
            if (user.FirstName || user.LastName)
              setCompanyUsers((pre: any) => [
                ...pre,
                `${user.FirstName} ${user.LastName}`,
              ]);
            else setCompanyUsers((pre: any) => [...pre, user.EmailAddress]);
          }

          setMapedUsers((prevValue: any) => {
            return {
              ...prevValue,
              [user.FirstName + ' ' + user.LastName]: user.EmailAddress,
            };
          });
        });
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setSelectLoader(false);
    }
  };

  const fetchSubscribedCompanies = async () => {
    try {
      const result = await config.getSubscribedCompanies();
      if (result.length) {
        const activeCompanies: string[] = [];
        result.forEach((company: any) => {
          if (company.IsActive) activeCompanies.push(company.name);
        });
        setSubCompanies(activeCompanies);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const uploadDocument = async () => {
    try {
      //applying limit on document size and type
      if (rejectedFiles.length) {
        dispatch(
          userActions.replaceUserState({
            errorMessage:
              'Uploaded file type is not supported or file size is larger then 10 MB.',
          })
        );
        return;
      }

      //validating friendly name of a document

      if (RegexForSpecialChar.test(friendlyName)) {
        friendlyNameRef.current.focus();
        setFriendlyNameError(true);
        dispatch(
          userActions.replaceUserState({
            successMessage:
              'The file name does not contain any special characters.',
          })
        );
        return;
      }

      let toEmails: any;

      if (
        selectedCompany === '' &&
        selectedUsers.length === 0 &&
        files.length === 0
      ) {
        companyRef.current.focus();
        setSelectCompanyError(true);
        setcompanyUsersError(true);
        setRedDropZone(true);
        return;
      }
      if (
        selectedCompany !== '' &&
        selectedUsers.length === 0 &&
        files.length === 0
      ) {
        companyUsersRef.current.focus();
        setcompanyUsersError(true);
        setRedDropZone(true);
        return;
      }
      if (
        selectedCompany === '' &&
        selectedUsers.length === 0 &&
        files.length !== 0
      ) {
        companyRef.current.focus();
        setcompanyUsersError(true);
        setSelectCompanyError(true);
        return;
      }

      if (files.length === 0) {
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Please choose a file',
          })
        );
        setRedDropZone(true);
        return;
      }

      if (replyEmails.length) {
        // checking valid reply emails
        toEmails = replyEmails.split(', ');
        const rejectedEmails: string[] = [];
        let flag = true;
        toEmails.forEach((email: string) => {
          if (!emailRegex.test(email)) {
            flag = false;
            rejectedEmails.push(email);
          }
        });
        if (!flag) {
          replyToRef.current.focus();
          setReplyToError(true);
          dispatch(
            userActions.replaceUserState({
              successMessage: `${rejectedEmails.join(
                ', '
              )} is wrong email address`,
            })
          );
          return;
        }
      }

      //Empty field validations
      if (selectedCompany === '') {
        companyRef.current.focus();
        setSelectCompanyError(true);
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Please select Company',
          })
        );
        return;
      }
      if (selectedUsers.length === 0) {
        companyUsersRef.current.focus();
        setcompanyUsersError(true);
        dispatch(
          userActions.replaceUserState({
            successMessage: 'Please select users',
          })
        );
        return;
      }

      const formData = new FormData();
      files.forEach((file: any) => formData.append('file', file.file));
      const params = {
        companyName: selectedCompany,
        files: formData,
        friendlyName: friendlyName,
      };
      setLoader(true);
      return;
      const res = await config.uploadDocument(params);
      if (res.message === 'The following file was uploaded successfully') {
        setProgress(100);
        const responseId = res.responseId;

        const usersEmail: any = {};
        let count = 1;
        selectedUsers.forEach((user: string) => {
          if (mapedUsers[user] === undefined)
            usersEmail[count.toString()] = user;
          else usersEmail[count.toString()] = mapedUsers[user];
          count++;
        });
        let removingExtraSpaces: any;
        if (replyEmails.length)
          removingExtraSpaces = replyEmails
            .replace(/\s+/g, '')
            .trim()
            .split(',');
        else removingExtraSpaces = [];

        const usersParams = {
          docResponseId: responseId,
          isSubscribed: selectedBtn === 0 ? '1' : '0',
          emailMessage: note,
          users: usersEmail,
          replyToEmails: removingExtraSpaces,
          email_subject: mailSubject,
          isPrivate: isPrivate ? 1 : 0,
        };

        const docUploadFinalResponse = await config.updateUsersInfo(
          usersParams
        );
        if (docUploadFinalResponse.message) {
          setSuccessMsg(true);
          setReplyToError(false);
          dispatch(
            userActions.replaceUserState({
              successMessage: docUploadFinalResponse.message,
            })
          );
          resetFormData();
          getUploadedDocuments();
        }
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const getUploadedDocuments = async () => {
    try {
      setDocumentShowLoader(true);
      const result = await config.getUploadedDocuments();

      if (result.docResultObj.length) {
        const sortedDocumentOnDate = result.docResultObj.sort(
          (a: any, b: any) => {
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);

            return bDate.getTime() - aDate.getTime();
          }
        );
        setDocs(sortedDocumentOnDate.splice(0, 5));
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setDocumentShowLoader(false);
    }
  };

  const addOrganizations = async (
    companyName: string,
    emailAddress: string
  ) => {
    try {
      setLoader(true);
      const params = { CompanyName: companyName, EmailAddress: emailAddress };
      await config.addOrganizations(params);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      fetchSubscribedCompanies();
      setLoader(false);
      setSelectedUsers([emailAddress]);
      setSelectedCompany(companyName);
      setShowAddOrganizationModal(false);
    }
  };

  const addEmailsOfNonSubscribedUsers = (email: string) => {
    if (!email.length) return;
    setSelectedUsers((prev: any) => [...prev, email]);
    setShowAddEmailsModal(false);
  };

  const removeUploadedDocument = (fileName: string) => {
    //handled case: on removing rejected file from uploaded files
    if (rejectedFiles.length) {
      setRejectedFiles((prevValue: any) =>
        prevValue.filter((fileObj: any) => fileObj.file.name !== fileName)
      );
    }

    setFiles((preValue: any) =>
      preValue.filter((fileObj: any) => fileObj.file.name !== fileName)
    );
    setInvalidFileError('');
  };

  useEffect(() => {
    fetchSubscribedCompanies();
    getUploadedDocuments();
  }, []);

  useEffect(() => {
    if (RegexForSpecialChar.test(friendlyName)) {
      friendlyNameRef.current.focus();
      setFriendlyNameError(true);
    } else {
      setFriendlyNameError(false);
    }
  }, [friendlyName]);

  useEffect(() => {
    // Filter company based on inputValue
    const filtered = subCompanies?.filter((tag: string) =>
      tag.trim().toLowerCase()
    );
    setFilteredSubCompany(filtered);
  }, [subCompanies]);

  const openDocInNewTab = (link: string) => {
    window.open(link);
  };
  const resetFormData = () => {
    setFiles([]);
    setSelectedUsers([]);
    setReplyEmails('');
    setSelectedCompany('');
    setInvalidFileError('');
    setReplyToError(false);
    setFriendlyName('');
    setFriendlyNameError(false);
    setMailSubject('');
    setIsPrivate(false);
  };
  return (
    <>
      {loader && <LoadingScreen />}
      <div className="DocUpload-UpperCont">
        <div className="uploadDocTextHeading">Upload Document</div>

        <br />
        <hr style={{ border: '1px solid #f6f6f6' }} />
      </div>
      <div className="DocUpload-BottomCont mt-30">
        <div className="DocUpload-leftPanel">
          <div className="selectBoxOuterCont">
            <div
              className={
                selectCompanyError
                  ? ' DocUpload-selectBox  inputErrorBorder'
                  : 'DocUpload-selectBox inputSvg'
              }
            >
              <Autocomplete
                id="demo-simple-select-outlined"
                value={selectedCompany}
                onChange={handleChangeCompany}
                options={filteredSubCompany}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Company"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
            </div>
            <div
              className={
                companyUsersError
                  ? ' DocUpload-selectBox  inputErrorBorder'
                  : 'DocUpload-selectBox inputSvg'
              }
            >
              <FormControl fullWidth className="docUpload-inputBoxes">
                <InputLabel id="demo-multiple-chip-label">
                  Select {selectedCompany === undefined ? '' : selectedCompany}{' '}
                  Users
                </InputLabel>
                <Select
                  inputRef={companyUsersRef}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  open={isSelectOpen}
                  onOpen={() => setIsSelectOpen(true)}
                  onClose={() => setIsSelectOpen(false)}
                  value={selectedUsers}
                  onChange={handleChangeSubscribedCompanyUsers}
                  input={
                    <OutlinedInput label={`Select ${selectedCompany} Users`} />
                  }
                  renderValue={(selected: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}
                    >
                      {selected.length <= 2 ? (
                        selected.map((value: any) => (
                          <Chip
                            className="Custom-chip"
                            size="small"
                            key={value}
                            label={value}
                            onDelete={() =>
                              setSelectedUsers(
                                selectedUsers.filter(
                                  (item: any) => item !== value
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                style={{ color: '#00BBDC', fontSize: '20px' }}
                                onMouseDown={(event: any) =>
                                  event.stopPropagation()
                                }
                              />
                            }
                          />
                        ))
                      ) : (
                        <div className="countPosition">
                          <div className="countP-fDiv">
                            {selected.map((value: any, i: number) => {
                              if (i >= 2) return;
                              return (
                                <Chip
                                  className="Custom-chip"
                                  size="small"
                                  key={value}
                                  label={value}
                                  onDelete={() =>
                                    setSelectedUsers(
                                      selectedUsers.filter(
                                        (item: any) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      style={{
                                        color: '#00BBDC',
                                        fontSize: '20px',
                                      }}
                                      onMouseDown={(event: any) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                          <div className="extraFields-DocUpload ">
                            {' '}
                            + {selected.length - 2}
                          </div>
                        </div>
                      )}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {selectLoader ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : companyUsers.length ? (
                    companyUsers.map((names: any, i) => (
                      <MenuItem key={names + i} value={names}>
                        <Checkbox
                          style={{
                            color: '#00BBDC',
                          }}
                          checked={selectedUsers?.indexOf(names) > -1}
                        />
                        <ListItemText primary={names} />
                      </MenuItem>
                    ))
                  ) : selectedCompany === '' ||
                    selectedCompany === undefined ? (
                    <MenuItem>Please select company</MenuItem>
                  ) : (
                    <></>
                  )}
                  {
                    <MenuItem
                      onClick={() => {
                        setShowAddEmailsModal(true);
                        setIsSelectOpen(false);
                      }}
                    >
                      <AddIcon
                        className="ml-5"
                        style={{ fontSize: '20px', color: '#00BBDC' }}
                      />

                      <ListItemText
                        className="ml-10"
                        primary="Add non-existing users"
                      />
                    </MenuItem>
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex">
            <TextField
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              inputRef={replyToRef}
              onChange={handleReplyChange}
              className={
                replyToError
                  ? 'w-50 mt-25  inputErrorBorder docUpload-inputBoxes'
                  : 'w-50 mt-25 docUpload-inputBoxes'
              }
              id="outlined-multiline-static"
              label="Reply to..."
              rows={1}
              value={replyEmails}
            />
            <TextField
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              inputRef={friendlyNameRef}
              onChange={handleFriendlyName}
              className={
                friendlyNameError
                  ? 'w-40 mt-25 ml-70  inputErrorBorder docUpload-inputBoxes'
                  : 'w-40 mt-25 ml-70 docUpload-inputBoxes'
              }
              id="outlined-multiline-static"
              label="Add friendly name to document"
              rows={1}
              value={friendlyName}
            />
          </div>
          <div>
            <TextField
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              inputRef={subRef}
              onChange={handleSubChange}
              className={
                mailSubjectError
                  ? 'w-100 mt-25  inputErrorBorder docUpload-inputBoxes'
                  : 'w-100 mt-25 docUpload-inputBoxes'
              }
              id="outlined-multiline-static"
              label="Add subject to mail..."
              rows={1}
              multiline
              value={mailSubject}
            />
          </div>

          <div className="mt-10">
            <span className="display5 ml-5">Is Document private</span>
            <span className="ml-20 ">
              <Checkbox
                size="medium"
                checked={isPrivate}
                onChange={handlePrivate}
                {...label}
              />
            </span>
          </div>

          <div className="UplodFiles">
            {files.length > 0 ? (
              <>
                <div className="uploadDoc-note">
                  <TextField
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    onChange={handleNoteChange}
                    className="w-100 mt-25"
                    id="outlined-multiline-static"
                    label="Write your message here..."
                    rows={1}
                    value={note}
                  />
                </div>
                <div className="uploadedFilesOuterCont mt-10">
                  {files?.map((el: any, i: number) => (
                    <div key={el?.file.name + i}>
                      <div>
                        <div className="UploadedFiles">
                          {Object.keys(UPLOADED_FILES_ICONS).includes(
                            el?.file.type
                          ) ? (
                            UPLOADED_FILES_ICONS[el?.file.type]
                          ) : (
                            <FileErrorIcon fill="red" width={38} height={38} />
                          )}
                          <div className="w-85">
                            <div>
                              <span
                                className={
                                  el?.errors[0]?.code === 'file-invalid-type'
                                    ? 'red'
                                    : 'black'
                                }
                              >
                                {el?.file?.name}{' '}
                              </span>
                              <span
                                className={el?.errors.length ? 'red' : 'black'}
                              >
                                <b>
                                  ({(el?.file?.size / 1048576).toFixed(3)} MB)
                                </b>
                              </span>
                            </div>
                            <div className="mt-10 progressBarOuter">
                              <div>
                                <LinearProgress
                                  variant="determinate"
                                  value={progress}
                                />{' '}
                              </div>
                              <div className="percentageText">
                                {' '}
                                <b>{progress}%</b>
                              </div>
                            </div>
                          </div>
                          <div>
                            <CancelOutlinedIcon
                              className="ml-50 cursor"
                              sx={{ fontSize: '20px' }}
                              onClick={() =>
                                removeUploadedDocument(el?.file.name)
                              }
                            />
                            {successMsg ? (
                              <div className=" ml-20 alert-success-documetUpload">
                                Success
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="DocUpload-rightPanel mb-70">
          <div className="DocUpload-dropBox ">
            <div
              {...getRootProps({
                className: !redDropZone
                  ? classes.dropzone
                  : classesRedZone.dropzone,
              })}
            >
              <input {...getInputProps()} />

              <div className={redDropZone ? 'dragDropBox red' : 'dragDropBox'}>
                <div>
                  <span className="clickUploadText">
                    Choose PDF / XLSX / PPT / DOC files
                  </span>{' '}
                  or drag and drop
                </div>
                <div className="smallText clickUploadText">
                  Maximum file size should be{' '}
                  <span className="fw-600 uderline">10MB</span>
                </div>
              </div>
              {invalidFileError ? (
                <div className="alert-danger-documentUpload mt-10">
                  {invalidFileError}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="mt-30">
              <button
                className="btn btn-secondary  mr-10"
                disabled={files.length === 0}
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                onClick={() => uploadDocument()}
                className="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="OrgText mt-30">Recently Uploaded Documents</div>
      <div>
        <TableContainer
          // sx={{ maxHeight: '315px', overflowY: 'scroll' }}
          className="accContainer mt-10 mb-10"
        >
          <Table
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="a dense table"
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell
                  width="1%"
                  align="center"
                  className="adminTableheading"
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  width="30%"
                  align="left"
                  className="adminTableheading"
                >
                  Document Name
                </TableCell>
                <TableCell
                  width="30%"
                  align="left"
                  className="adminTableheading"
                >
                  Shared With
                </TableCell>
                <TableCell
                  width="30%"
                  align="left"
                  className="adminTableheading"
                >
                  Shared
                </TableCell>
                <TableCell
                  width="15%"
                  align="left"
                  className="adminTableheading"
                >
                  Organization Name
                </TableCell>

                <TableCell
                  width="8%"
                  align="left"
                  className="adminTableheading"
                >
                  Uploaded Date
                </TableCell>
                <TableCell
                  width="8%"
                  align="left"
                  className="adminTableheading"
                >
                  Uploaded Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentShowLoader ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> Loading...</TableCell>
                </TableRow>
              ) : docs.length ? (
                docs.map((doc: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell
                      align="center"
                      className="tableCell uploadedDoc-serialNo"
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell className="tableCell" width="20%">
                      {Object.values(doc?.docName).map(
                        (docName: any, i: number) => (
                          <TableRow key={i}>
                            <span
                              className="docName"
                              onClick={() =>
                                openDocInNewTab(
                                  Object.values(doc.docLink)[i] as string
                                )
                              }
                            >
                              {docName}
                            </span>
                          </TableRow>
                        )
                      )}
                    </TableCell>
                    <TableCell className="tableCell">{doc.shareto}</TableCell>
                    <TableCell
                      className={doc.IsPrivate ? ' active' : 'tableCell'}
                    >
                      {doc.isPrivate ? 'Privately' : 'Publically'}
                    </TableCell>
                    <TableCell>{doc.company}</TableCell>
                    <TableCell>{doc.date.split('T')[0]}</TableCell>
                    <TableCell>
                      {doc.date.split('T')[1].split('.')[0]}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> No Documents Uploaded yet</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {showAddOrganizationModal && (
        <AddOrganizationModal
          show={showAddOrganizationModal}
          onClose={onCloseAddOrganizationModal}
          onSubmit={addOrganizations}
        />
      )}
      {showAddEmailsModal && (
        <AddEmailsModal
          show={showAddEmailsModal}
          onClose={onCloseAddEmailsModal}
          onSubmit={addEmailsOfNonSubscribedUsers}
        />
      )}
    </>
  );
};

export default DocumentUpload;
