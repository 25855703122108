import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { accountState } from '../../store';
import { Grid } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';
import { CLOUD_PROVIDER_SERVICES, ERRORS } from '../../utils/constants';
import { handleError } from '../../utils/config';
import { config } from './config';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { ALL_VALUE } from '../Invoice/config';

const HighestConsumedResources = () => {
  const history = useHistory();
  const [serviceLoader, setServiceLoader] = React.useState(false);
  const selectedAccountInfo = useSelector(accountState);
  const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider.toUpperCase();

  const [isNetworkError, setNetworkError] = React.useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const [selectedAccountId, setSelectedAccountId] = React.useState<any>(
    selectedAccountInfo.selectedAccountId
  );
  const [
    highestConsumedResList,
    setHighestConsumedResList,
  ] = React.useState<any>([]);
  const [currency, setCurrency] = React.useState<any>('');

  const getHighestConsumedResources = async () => {
    try {
      setHighestConsumedResList([]);
      setServiceLoader(true);
      let resourceParams;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        resourceParams = {
          ...(selectedAccountId !== ALL_VALUE
            ? { selectedAccounts: selectedAccountId }
            : {}),
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        resourceParams = {
          ...(selectedAccountId === ALL_VALUE
            ? { subscriptionId: selectedSubscriptionId }
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
        };
      }

      const result = await config.getHigestConsumedResources(resourceParams);

      setHighestConsumedResList(result?.summary);
      setCurrency(result?.currency);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setServiceLoader(false);
    }
  };

  useEffect(() => {
    getHighestConsumedResources();
  }, [selectedSubscriptionId, selectedAccountId]);

  if (isNetworkError) {
    throw new Error(ERRORS.NETWORK_ERROR);
  }

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  return (
    <>
      {/* id selection */}
      <div className="rsTableHeader">
        <Grid className="inventoryReportsHeading  mt-5">
          Top 10 Consumed Resources
        </Grid>
        <Grid className="flex">
          <SelectedAccount onAccountChange={onAccountChange} />
        </Grid>
      </div>
      {/* {id selection ends} */}

      <TableContainer className="accContainer">
        <Table
          className="table adminTable adminPanelTable"
          size="small"
          aria-label="a dense table"
        >
          <TableHead className="admintableHead">
            <TableRow>
              <TableCell
                width="1%"
                align="center"
                className="adminTableheading"
              >
                Sr. No.
              </TableCell>
              <TableCell align="left" className="adminTableheading">
                Service Name
              </TableCell>
              <TableCell align="left" className="adminTableheading">
                Resource Name
              </TableCell>
              <TableCell align="left" className="adminTableheading">
                Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {highestConsumedResList?.length ? (
              highestConsumedResList.map((service: any, index: number) => (
                <TableRow key={service.ResourceName + index}>
                  <TableCell>{++index}</TableCell>
                  <TableCell>{service.ServiceName}</TableCell>
                  <TableCell>{service.ResourceId}</TableCell>
                  <TableCell>{currency + +service.Cost.toFixed(2)}</TableCell>
                </TableRow>
              ))
            ) : serviceLoader ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell> Loading...</TableCell>
              </TableRow>
            ) : highestConsumedResList &&
              highestConsumedResList?.length === 0 ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell> No Records</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default HighestConsumedResources;
