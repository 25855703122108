import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import '../Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import ProtectedComponent from '../../ProtectedComponent/ProtectedComponent';
import { PERMISSION_LEVELS } from '../../../utils/constants';
// const noop = () => {};

// interface ModalProps{
//     isOpen:boolean = false,
//     onClose:boolean = noop,
//     children,
//     wrapperClassName:string,
//   ...props
// }

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  onClose?: { (): void };
  onSubmit?: { (): void };
  successMsg?: string;
  errorMsg?: string;
  data?: any;
  message?: any;
  type?: string;
  actionPermission?: any;
}

export const RequestFixModal: React.FC<FixProps> = (props) => {
  const {
    children,
    show = false,
    onClose,
    data,
    onSubmit,
    message,
    type,
    successMsg,
    errorMsg,
    actionPermission,
  } = props;

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{
        overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 },
      }}
      className="fixModal"
      {...props}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">
              {type == 'rightsizing'
                ? 'REQUEST FIX'
                : type == 'reserveResources'
                ? 'STEPS TO RESERVE RESOURCE'
                : 'REQUEST FIX'}
            </span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>

        <Grid item md={12} className="modalBody">
          <p dangerouslySetInnerHTML={{ __html: data.Header_Tag }}></p>
          <ul className="instanceList">
            {/*data.Permissions.map((permission: any, index: number) => (
              <li className="fw-600" key={index}>{permission}</li>
            ))*/}
          </ul>
          <ul className="instanceList">
            {data.Steps.map((step: any, index: any) => (
              <li className="" key={index}>
                <FontAwesomeIcon icon={faCheckCircle} />
                <span className="ml-10">{step}</span>
              </li>
            ))}
          </ul>
          <p className="pt-15 pb-15">
            {data.Descriptions.Body_tag}
            <br />
            <a
              href={data.Descriptions.References_Url}
              target="_blank"
              style={{ color: 'black' }}
            >
              {data.Descriptions.References_Url}
            </a>
          </p>

          <p>{/*data.Actions.Action_Tag*/}</p>

          {message && (
            <div className="alert-success p-2 px-5 mt-10">{message}</div>
          )}

          {successMsg && (
            <div className="alert-success p-2 px-5 mt-10">{successMsg}</div>
          )}

          {errorMsg && (
            <div className="alert-danger p-2 px-5 mt-10">{errorMsg}</div>
          )}
        </Grid>
        <div className="mt-0 modalFooter">
          {/* {type !== 'reserveResources' && (
            <ProtectedComponent
              level={PERMISSION_LEVELS.WRITE}
              permission={actionPermission}
            >
              <button
                type="button"
                className="btn btn-primary ml-10"
                onClick={onSubmit}
              >
                {type === 'rightsizing'
                  ? 'Resize'
                  : type == 'previousGeneration'
                  ? 'Confirm'
                  : 'Delete'}
              </button>
            </ProtectedComponent>
          )} */}

          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={onClose}
          >
            {type === 'reserveResources' ? 'Ok' : 'Cancel'}
          </button>
        </div>
      </Grid>
    </Modal>
  );
};
