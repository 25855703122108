import { APIS } from '../../../utils/apiConfig';
import { HttpService } from '../../HttpService/HttpService';

export class SystemHealthService {
  constructor(private httpService: HttpService) {}

  async getAccountHealthData(accountId: string, cloud: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_SYSTEM_HEALth_DATA(accountId, cloud),
        {}
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async getAccounts(companyName: string): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_ACTIVE_ACCOUNTS(companyName),
        {}
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
  async getJobFailurDetails(
    services: string,
    account: string,
    jobdate: string,
    cloud: string
  ): Promise<any> {
    try {
      const response = await this.httpService.get(
        APIS.GET_JOB_FAILUR_DETAILS(services, account, jobdate, cloud),
        {}
      );
      const { data } = response;

      if (data.sc == 1) {
        return data.result;
      }
      return response;
    } catch (e) {
      throw e;
    }
  }
}
