export interface CountryFlagProps {
  countryCode: string;
  width: string;
}
const CountryFlag = ({ countryCode, width }: CountryFlagProps) => {
  //   const flagUrl = `https://www.countryflags.io/${countryCode}/flat/64.png`;
  const flagUrl = `https://flagcdn.com/w320/${countryCode?.toLowerCase()}.png`;
  return (
    <img
      src={flagUrl}
      alt={`Flag of ${countryCode}`}
      style={{ width, marginRight: '10px', display: 'inline' }}
    />
  );
};

export default CountryFlag;
