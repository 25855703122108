import React, { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import { Grid, TextField } from '@material-ui/core';
import '../Modal.css';
import { useForm } from 'react-hook-form';
import { rightSizingService } from '../../../services';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { accountState } from '../../../store/index';
import { handleError } from '../../../utils/config';

interface FixProps {
  children?: ReactNode;
  show?: boolean;
  selectedInstance: {
    id: string;
    resGrp: string;
    subscriptionId: string;
    instanceId: string;
  };
  onClose?: { (): void };
}

interface CONFIGURSCHEMA {
  ResourceGroup: string;
  Days: string;
  UpperThreshold: string;
  VMName: string;
  InstanceId: string;
  SubscriptionId: string;
}

export const ConfigureCheckModal: React.FC<FixProps> = (props) => {
  const { onClose, selectedInstance } = props;
  const selectedAccount = useSelector(accountState).selectedAccount;
  const { id, resGrp, subscriptionId, instanceId } = selectedInstance;
  const [msg, setMsg] = React.useState({
    errMsg: '',
    successMsg: '',
  });
  const history = useHistory();
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const {
    register,
    formState,
    handleSubmit,
    errors,
    getValues,
  } = useForm<CONFIGURSCHEMA>({
    mode: 'onChange',
  });
  const values = getValues();

  useEffect(() => {
    if (errors.UpperThreshold || errors.Days)
      setMsg({ ['successMsg']: '', ['errMsg']: '' });
  });
  const regMsgElement = () => {
    if (msg.successMsg)
      return <div className="successMsg">{msg.successMsg}</div>;
    else if (msg.errMsg) return <div className="regMsg">{msg.errMsg}</div>;
  };
  const onSubmit = async (data: CONFIGURSCHEMA, e: any) => {
    setButtonStatus(true);
    try {
      data.VMName = id;
      data.ResourceGroup = resGrp;
      data.SubscriptionId = subscriptionId;
      data.InstanceId = instanceId;
      const response = await rightSizingService.configureRightSizingDetails({
        ...data,
        Days: Number(data.Days),
        UpperThreshold: Number(data.UpperThreshold),
        InstanceId: data.InstanceId,
      });
      if (response) {
        setMsg({ ['errMsg']: '', ['successMsg']: response.message });
        e.target.reset();
        formState.touched.Days = void 0;
        formState.touched.UpperThreshold = void 0;
      }
      setTimeout(() => {
        onClose && onClose();
      }, 2000);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        const err = `${e.error.message}`;
        setMsg({ ['successMsg']: '', ['errMsg']: err });
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        // setMsg({...msg, ['successMsg']: '' });
        setButtonStatus(false);
        //e.target.reset();
      }, 1000);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <Modal
      isOpen={true}
      // onRequestClose={true}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal"
      {...props}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">CONFIGURE CHECK</span>
            <span className="modalClose" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
          </div>
        </Grid>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="w-100"
        >
          <Grid item md={12} className="modalBody pt-20">
            <p>
              Change Configuration for
              {selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
                ? ' Virtual Machine '
                : selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
                ? ' Instance '
                : ' Instance '}
              <b>({id})</b>
            </p>
            <TextField
              label="DAYS"
              className="days"
              name="Days"
              type="number"
              inputRef={register({
                required: true,
                max: 30,
              })}
            />
            {errors?.Days && parseInt(values.Days) > 30 && (
              <div className="regMsg mt-5">Maximum 30 days allowed</div>
            )}
            {formState.touched.Days && values.Days.length === 0 && (
              <div className="regMsg mt-5">Days is required</div>
            )}
            <TextField
              label="AVG CPU UTILIZATION (%)"
              className="util"
              type="number"
              name="UpperThreshold"
              inputRef={register({
                required: true,
                pattern: /^[0-9\b]+$/,
                max: 100,
              })}
            />
            {errors?.UpperThreshold?.type === 'pattern' && (
              <div className="regMsg mt-5">Only numbers are allowed</div>
            )}
            {parseInt(values.UpperThreshold) > 100 && (
              <div className="regMsg mt-5">
                Maximum 100% UpperThreshold allowed
              </div>
            )}
            {formState.touched.UpperThreshold &&
              values.UpperThreshold.length === 0 && (
                <div className="regMsg mt-5">
                  Avg CPU Utilization is required
                </div>
              )}
          </Grid>
          <div className="mt-10 modalFooter">
            <button
              type="submit"
              className="btn btn-primary ml-10"
              disabled={buttonStatus}
            >
              UPDATE
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={onClose}
            >
              CANCEL
            </button>
          </div>
        </form>
      </Grid>
    </Modal>
  );
};
