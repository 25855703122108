import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../../schemas/routes.schema';
import contactUs from '../../../assets/images/contact-us.png';

export const AzsContactUs: React.FC = () => {
  const Routes= RoutesFun();
  const history = useHistory();
  return (
    <>
      <div className="home-tile cf-row">
        <div className="innerContainer12 flex-container">
          <div className="contentArea">
            <div className='py-20'>
              <h4>
                <span className="blue">Get In Touch With Us</span>
                {/* <span>Solution for Enterprises</span> */}
              </h4>
              <p className="mt-20 mb-20">
                Reach out to CloudForestX's experts today and discover how we
                can help streamline your Azure / AWS cloud environment.
              </p>
              <p className="mt-10 mb-30">
                Ensure cutting-edge Cloud and Azure / AWS spend optimization to
                maximize your ROI.
              </p>
              <Button
                variant="outlined"
                className="blue blue-border"
                onClick={() => history.push(Routes.Demo)}
              >
                Contact Us
              </Button>
            </div>
          </div>
          <div className="contentImage">
            <img className="flex-img img-width" src={contactUs} width="100%" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AzsContactUs;
