import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box } from '@mui/system';
import {
  labels as headerLabels,
  azureLabels as azureHeaderLabels,
} from '../../utils/securityScan/formattedData';
import { SCAN_RESULT_STATUSES } from '../../utils/constants';
import { JSONViewerModal } from '../../components/Modals/JsonViewerModal';

const CONFIG = {
  PASSED: {
    rowColor: '#f5fefb',
    statusColor: 'green',
    statusText: 'Passed',
  },
  FAILED: {
    rowColor: '#ffebe8',
    statusColor: 'red',
    statusText: 'Failed',
  },
};

type RawPayloadType = {
  title: string;
  data: any | null;
  type: string;
};
const styles = {
  JSONViewerModal: {
    error: {
      header: {
        background: 'red',
      },
    },
  },
};
export const AzurePractice = ({ meta, practice }: any) => {
  const [open, setOpen] = useState(false);
  const [rawPayload, setRawPayload]: Array<RawPayloadType | any> = useState({
    title: '',
    data: null,
    type: '',
  });
  const passed = !practice.find(
    (entry: any) => entry.status === SCAN_RESULT_STATUSES.FAILED
  );
  const config = passed ? CONFIG.PASSED : CONFIG.FAILED;
  return (
    <>
      <TableRow
        sx={{
          bgcolor: config.rowColor,
        }}
      >
        <TableCell align="left">
          {passed ? (
            <CheckOutlinedIcon sx={{ margin: '0 0 0 28%' }} />
          ) : (
            <IconButton
              size="large"
              onClick={() => setOpen(!open)}
              color="error"
            >
              {open ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="left">{meta.ShortDescription}</TableCell>
        <TableCell align="left">{meta.LongDescription}</TableCell>
        <TableCell
          align="left"
          sx={{
            color: config.statusColor,
            fontWeight: 500,
          }}
        >
          {config.statusText}
        </TableCell>
      </TableRow>

      {!passed ? (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, background: '#ffebe8' }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {practice && practice.length ? (
                <Box
                  sx={{
                    margin: 1,
                    border: 'none',
                    overflow: 'auto',
                    maxHeight: '300px',
                  }}
                >
                  <Table
                    className="table"
                    size="small"
                    aria-label="collapsible table"
                    stickyHeader
                  >
                    <TableHead
                      sx={{
                        bgcolor: '#fff9f8',
                      }}
                    >
                      {practice[0].error ? (
                        <TableCell align="justify">Information</TableCell>
                      ) : (
                        <TableRow>
                          {Object.keys(azureHeaderLabels[meta.Code]).map(
                            (field: string, i: number) => {
                              const obj = azureHeaderLabels[meta.Code];
                              return (
                                <TableCell key={i}>
                                  {obj[field as keyof typeof obj] || field}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      )}
                    </TableHead>
                    {}
                    {practice[0].error ? (
                      <TableBody>
                        <TableRow
                          sx={{
                            bgcolor: '#ffffff',
                            borderRadius: 2,
                          }}
                        >
                          <TableCell
                            onClick={() =>
                              setRawPayload({
                                title: practice[0].error
                                  ? 'Error while executing practice'
                                  : 'Payload',
                                data: practice[0].error
                                  ? practice[0].error
                                  : practice[0].payload,
                                type: practice[0].error ? 'error' : '',
                              })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill={practice[0].error ? 'red' : 'blue'}
                              className="bi bi-info-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {practice.map((prac: any, i: number) => (
                          <TableRow
                            key={i}
                            sx={{
                              bgcolor: '#ffffff',
                              borderRadius: 2,
                            }}
                          >
                            {Object.keys(azureHeaderLabels[meta.Code]).map(
                              (field: any, i: number) => (
                                <TableCell key={i}>
                                  {prac.payload && prac.payload[field]
                                    ? prac.payload[field].toString()
                                    : '-'}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </Box>
              ) : (
                <Box
                  sx={{
                    margin: 1,
                    border: 'none',
                    height: '50px',
                    overflow: 'auto',
                  }}
                >
                  <div
                    style={{
                      background: '#fff',
                      textAlign: 'center',
                      fontSize: '15px',
                      color: '#7C7C7C',
                      padding: '10px 0',
                    }}
                  >
                    No data available
                  </div>
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <></>
      )}
      {rawPayload.data && (
        <JSONViewerModal
          onClose={() =>
            setRawPayload({
              title: '',
              data: null,
              type: '',
            })
          }
          title={rawPayload.title}
          styles={
            rawPayload.type === 'error' ? styles.JSONViewerModal.error : {}
          }
          payload={rawPayload.data}
        />
      )}
    </>
  );
};
