import { inventoryService } from '../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';

export const config: any = {
  [CLOUD_PROVIDER_SERVICES.AWS]: {
    tagging: (params: any, type: string) =>
      inventoryService.awsTagging(params, type),
    searchData: (params: any) => inventoryService.getAllResourceDetails(params),
  },
  [CLOUD_PROVIDER_SERVICES.AZURE]: {
    tagging: (params: any, type: string) =>
      inventoryService.azureTagging(params, type),
    searchData: (params: any) => inventoryService.getAllResourceDetails(params),
  },
  recentCreatedResource: (params: any) =>
    inventoryService.getRecentCreatedResource(params),
};

export type SearchField = {
  [key: string]: string;
};

export interface DataItem {
  [key: string]: any;
}

export const AwsSearchField: SearchField = {
  Services: 'See All',
  EC2: 'Instance_Name',
  S3: 'S3_Bucket_Name',
  EFS: 'EFS_Name',
  'AWS Resource Groups': 'Resource_Group_Name',
  'AWS Load Balancers': 'Load_Balancer_Name',
  ElastiCache: 'Cluster_Id',
  'Elastic Container Service Instance': '',
  FSx: 'FSx_Name',
  'S3 Glacier': 'S3_Glacier_Name',
  'Simple Queue Service': 'SQS_Name',
  'Elastic BeanStalk': 'App_Name',
  WorkSpaces: 'Computer_Name',
  EBS: 'Volume_Id',
  'Elastic IP addresses': 'Elastic_IP_Name',
  DynamoDB: 'DynamoDB_Name',
  RDS: 'RDS_Name',
  EMR: 'Cluster_Id',
  SNS: 'SNS_Name',
  'Global Accelerator': 'Global_Accelerator_Name',
  Lambda: 'FunctionName',
};

export const AzureSearchField: SearchField = {
  Services: 'See All',
  'Virtual Machines': 'Virtual_Machine_Name',
  'Storage Accounts': 'Storage_Name',
  'Load Balancers': 'Load_Balancer_Name',
  'App Service Plans': 'App_Name',
  'COSMOS DB': 'Cosmos_DB_Name',
  'Container Instances': 'Container_Instance_Name',
  'HDInSight Cluster': 'HD_Cluster_Name',
  'Managed Disks': 'Disk_Name',
  'Public IP': 'Public_IP_Name',
  'Redis Cache': 'Redis_Cache_Name',
  'Resource Groups': 'Resource_Group_Name',
  'SQL Server': 'SQL_Server_Name',
  'Service Bus': 'Service_Bus_Name',
  'Traffic Manager': 'Traffic_Manager_Name',
  WorkSpaces: 'Workspace_Name',
};

// Helper function to check if a date is within the last 7 days
// const isWithinLast7Days = (dateStr: any) => {
//   const currentDate = new Date();
//   const targetDate = new Date(dateStr);
//   const timeDiff = currentDate.getTime() - targetDate.getTime();
//   const daysDiff = timeDiff / (1000 * 3600 * 24);
//   return daysDiff <= 7;
// };

// Function to format the data as required
// export const formatData = (originalData: any) => {
//   const formattedData: any = {};

//   for (const category in originalData) {
//     const entries = originalData[category];
//     const filteredEntries = entries.filter((entry: any) =>
//       isWithinLast7Days(entry.createdAt)
//     );

//     const totalCost = filteredEntries.reduce(
//       (acc: any, entry: any) => acc + entry.cost,
//       0
//     );

//     formattedData[category] = {
//       data: filteredEntries,
//       totalCost: totalCost,
//     };
//   }

//   return formattedData;
// };

export const resourceNameColumn: any = {
  'Virtual Machines': 'Virtual_Machine_Name',
  'Storage Accounts': 'Storage_Name',
  'Managed Disks': 'Disk_Name',
  'SQL Server': 'SQL_Server_Name',
  'Public IP': 'Public_IP_Name',
  WorkSpaces: 'Workspace_Name',
  'App Service Plans': 'App_Name',
  'Container Instances': 'Container_Instance_Name',
  'Redis Cache': 'Redis_Cache_Name',
  'HDInSight Cluster': 'HD_Cluster_Name',
  'Service Bus': 'Service_Bus_Name',
  'Traffic Manager': 'Traffic_Manager_Name',
  'Resource Groups': 'Resource_Group_Name',
  'COSMOS DB': 'Cosmos_DB_Name',
  'Load Balancers': 'Load_Balancer_Name',
  EC2: 'Instance_Name',
  S3: 'S3_Bucket_Name',
  EFS: 'EFS_Name',
  FSx: 'FSx_Name',
  'S3 Glacier': 'S3_Glacier_Name',
  ElastiCache: 'CacheSubnetGroupName',
  'Elastic Container Service Instance': 'Container_Instance',
  'Simple Queue Service': 'SQS_Name',
  'Elastic BeanStalk': 'App_Name',
  EBS: 'Volume_Id',
  'Elastic IP addresses': 'Elastic_IP_Name',
  DynamoDB: 'DynamoDB_Name',
  RDS: 'RDS_Name',
  EMR: 'Cluster_Id',
  SNS: 'SNS_Name',
  'Global Accelerator': 'Global_Accelerator_Name',
  'AWS Load Balancers': 'Load_Balancer_Name',
  'AWS Resource Groups': 'Resource_Group_Name',
  Lambda: 'FunctionName',
};
