import { Grid } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../Modal.css';
import { ReactComponent as CloseLogo } from '../../../assets/ICONS/close.svg';

interface CustomModalProp {
  show: boolean;
  onClose?: { (): void };
  submitFunc?: any;
  buttonMsg?: string;
  headerMsg: string;
  isSubmitBtnDisabled?: boolean;
  style?: CSSProperties;
}

export const CustomModal: React.FC<CustomModalProp> = ({
  buttonMsg = 'save',
  headerMsg,
  show,
  submitFunc,
  onClose,
  children,
  isSubmitBtnDisabled,
  style,
}) => {
  const [modalClass, setModalClass] = useState<string>('fixModal');
  const [overlayClass, setOverlayClass] = useState<string>('customOverlay');

  useEffect(() => {
    if (show) {
      setModalClass('fixModal');
      setOverlayClass('customOverlay');
    } else {
      setModalClass('fixModal closeAnimation');
      setOverlayClass('customOverlay closeOverlay');
    }
  }, [show]);

  const handleClose = () => {
    setModalClass('fixModal closeAnimation');
    setOverlayClass('customOverlay closeOverlay');
    setTimeout(() => {
      if (onClose) onClose();
    }, 300);
  };

  return (
    <Modal
      isOpen={show}
      onAfterOpen={() => {
        setModalClass('fixModal');
        setOverlayClass('customOverlay');
      }}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ content: style }}
      className={modalClass}
      overlayClassName={overlayClass}
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="modalHeader">
          <div>
            <span className="modalTitle">{headerMsg}</span>
            <span className="modalClose" onClick={handleClose}>
              <CloseLogo />
            </span>
          </div>
        </Grid>
        <div
          style={{
            padding: '2rem',
            width: '100%',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          {' '}
          {children}
        </div>
        <div className="mt-0 modalFooter">
          {submitFunc && (
            <button
              type="button"
              className="btn btn-primary ml-10 "
              onClick={() => {
                submitFunc(), onClose;
              }}
              disabled={isSubmitBtnDisabled}
            >
              {buttonMsg}
            </button>
          )}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </Grid>
    </Modal>
  );
};
