import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import accountReducer from './accountSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    account: accountReducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const userState = (state: RootState) => state.user;
export const accountState = (state: RootState) => state.account;

export default store;
