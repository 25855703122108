import { GlobalQueryParams } from '../../utils/config';
import { HttpService } from '../HttpService/HttpService';

export class CostSavingService {
  constructor(private httpService: HttpService) {}

  async getCostSavingDataRes(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/costsaving/get?'),
        {}
      );

      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getAWSCostSavingDataRes(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/costsaving/get?'),
        {}
      );

      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
}
