import { Box, Grid, Tabs, Tab } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import './AppDashboard.css';
import '../../index.css';
import awsIcon from '../../assets/images/aws-icon.png';
import azureIcon from '../../assets/images/azure-icon.png';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as UpIcon } from '../../assets/images/circle-arrow-up.svg';
import { ReactComponent as UpLongIcon } from '../../assets/images/arrow-up.svg';
import { ReactComponent as DownLongIcon } from '../../assets/images/arrow-down.svg';
import { ReactComponent as DownIcon } from '../../assets/images/circle-arrow-down.svg';
import InfoIcon from '@material-ui/icons/Info';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Line,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Text,
  Tooltip as RechartsTooltip,
  XAxis,
} from 'recharts';
import { Notifications } from '../../components/Notifications/Notifications';
import { useSelector } from 'react-redux';
import { accountState, userState } from '../../store';
import { emitter, listener } from '../../Socket';
import { userService } from '../../services';

import { useHistory } from 'react-router-dom';
import { defaultColor, config, piRedColorsArr, barColors } from './config';
import {
  CLOUD_PROVIDER_SERVICES,
  ERRORS,
  currencies,
} from '../../utils/constants';
import {
  formatingIdleResourcesData,
  formatingRightsizing,
  formattedForecastData,
} from '../../utils/appDashboard/formattedData';
import { dataInterface } from '../../schemas/dashboard.schema';
import { costFormatter2, formatNumber, handleError } from '../../utils/config';
import SpendForecast from './SpendForecast';

export const AppDashboard: React.FC = () => {
  const selectedAccountInfo = useSelector(accountState);
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');

  const [activeAwsIndex, setActiveAwsIndex] = useState(-1);
  const [activeAzureIndex, setActiveAzureIndex] = useState(-1);

  const [activeSpendAwsIndex, setActiveSpendAwsIndex] = useState(-1);
  const [activeSpendAzureIndex, setActiveSpendAzureIndex] = useState(-1);
  const selectedAccount = useSelector(accountState).selectedAccount;
  const valueOfActiveTab =
    selectedAccount?.CloudProvider.toUpperCase() ===
    CLOUD_PROVIDER_SERVICES.AZURE
      ? 1
      : 0;
  const [activeTab, setActiveTab] = useState(valueOfActiveTab);

  const userData = useSelector(userState);
  const user = userData.userData;
  const [totalNotifications, setTotalNotifications] = React.useState<any>(0);
  const [
    newNotificationDetails,
    setNewNotificationDetails,
  ] = React.useState<any>(null);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [notificationList, setNotificationList] = React.useState([]);
  const [isNextPageLoading, setIsNextPageLoading] = React.useState(false);
  // const [chunkNo, setChunkNo] = React.useState(0);
  const defaultPieData: any = [{ name: 'No Data Found', count: 0.00001 }];
  const defaultSpendByServicePieData: any = [
    { name: 'No Data Found', value: 0.0002 },
  ];
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const [loader, setLoader] = useState({
    rightSizing: false,
    idle: false,
    spendForecast: false,
    spendByService: false,
    accounts: false,
    spendAmount: false,
    perChange: false,
  });

  const [data, setData] = useState<dataInterface>({
    rightSizing: [],
    idle: [],
    spendByService: {
      aws: {
        piChartData: [],
        currencySymbol: '',
      },
      azure: {
        piChartData: [],
        currencySymbol: '',
      },
    },

    spendForecast: { aws: [], azure: [] },
    spendAmount: 0,
    accounts: {
      AWS: 0,
      AZURE: {
        subscriptions: 0,
        accounts: 0,
      },
    },
    perChange: {
      AWS: 0,
      AZURE: 0,
    },
  });
  const [rightSizingTotal, setRightSizingTotal] = useState(0);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const company = localStorage.getItem('CompanyName');

  const history = useHistory();

  const fetchNotifications = async () => {
    setIsNextPageLoading(true);
    try {
      // setChunkNo((prevNum) => prevNum + 1);

      if (user) {
        const result = await config.fetchDashboardNotification();

        setNotificationList((prevNotifications): any => [
          ...prevNotifications,
          ...result,
        ]);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setIsNextPageLoading(false);
    }
  };

  const resetNotificationState = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setNotificationList([]);
    // setChunkNo(0);
  };

  useEffect(() => {
    resetNotificationState();
  }, [totalNotifications]);

  const loadMoreItems = isNextPageLoading ? () => null : fetchNotifications;
  const itemCount = hasNextPage
    ? notificationList.length + 1
    : notificationList.length;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < notificationList.length;

  const getItemSize = (index: number) => {
    const notification: any = notificationList[index];
    const noOfLines = Math.floor(notification?.Description.length / 40);
    if (isNaN(noOfLines)) return 0;
    return notification?.UpdatedBy ? 55 + noOfLines * 30 : 65 + noOfLines * 30;
  };

  /********************* Accounts section starts ************************ */
  const fetchAccountsData = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        accounts: true,
      }));
      const accounts = await config.fetchDashboardAccounts();
      setData((prevValue) => {
        return {
          ...prevValue,
          accounts: {
            AWS: accounts.aws.awsCount,
            AZURE: {
              subscriptions: accounts.azure.azureSubscriptionCount,
              accounts: accounts.azure.azureCount,
            },
          },
        };
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevalue) => ({
        ...prevalue,
        accounts: false,
      }));
    }
  };
  /********************* Accounts section ends ************************ */

  /********************* Spend amount section starts ************************ */
  const fetchSpendAmount = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        spendAmount: true,
      }));
      const totalAmount = await config.fetchDashboardSpendAmount({
        selectedCurrency,
      });
      setData((prevValue) => {
        return {
          ...prevValue,
          spendAmount: totalAmount.totalSpendAmt,
        };
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setUpdatedCurrency(selectedCurrency);
      setLoader((prevalue) => ({
        ...prevalue,
        spendAmount: false,
      }));
    }
  };
  /********************* Spend amount section ends ************************ */

  /********************  Right Sizing section starts *********************/

  const fetchRightSizingData = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        rightSizing: true,
      }));
      const result = await config.fetchDashboardRightsizingData({
        selectedCurrency,
      });
      const formattedrightSizingData = formatingRightsizing(result);
      setData((prevValue) => {
        return { ...prevValue, rightSizing: formattedrightSizingData };
      });
      const totalRightsizingCount = formattedrightSizingData.reduce(
        (acc: any, row: any) => {
          if (!acc['total']) {
            acc.total = 0;
          }
          acc.total += row.downscale + row.upscale;
          return acc;
        },
        {}
      );
      setRightSizingTotal(totalRightsizingCount.total);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setUpdatedCurrency(selectedCurrency);
      setLoader((prevalue) => ({
        ...prevalue,
        rightSizing: false,
      }));
    }
  };

  const CustomDownscaleTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">Possible Monthly Cost</div>
          <div className="ttLabel">
            <span className="ttKey">Cost</span>
            <span className="ttValue">
              {costFormatter2(updatedCurrency, payload[0]?.value)}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  /********************  Right Sizing section ends *********************/

  /********************  Idle Resources section starts *********************/

  const fetchIdleResourcesData = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        idle: true,
      }));
      const idleResources = await config.fetchDashboardIdleResourcesData({
        selectedCurrency,
      });
      // if (
      //   idleResources?.AZURE &&
      //   Object.keys(idleResources?.AZURE).length > 0
      // ) {
      //   await formatingAzureIdleResourcesData(idleResources.AZURE);
      // }
      const formattedIdleResourcesData = formatingIdleResourcesData(
        idleResources
      );
      if (formattedIdleResourcesData.AWS)
        formattedIdleResourcesData.AWS.data.sort(
          (a: any, b: any) => b.count - a.count
        );
      if (formattedIdleResourcesData.AZURE)
        formattedIdleResourcesData.AZURE.data.sort(
          (a: any, b: any) => b.count - a.count
        );
      setData((prevValue) => {
        return { ...prevValue, idle: [formattedIdleResourcesData] };
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setUpdatedCurrency(selectedCurrency);
      setLoader((prevalue) => ({
        ...prevalue,
        idle: false,
      }));
    }
  };

  const renderAwsActiveShape = (props: any) => {
    const {
      cx,
      cy,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      innerRadius,
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };
  const renderAzureActiveShape = (props: any) => {
    const {
      cx,
      cy,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      innerRadius,
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  /********************  Idle Resources section ends *********************/

  /********************  Spend By Service starts *********************/

  const fetchSpendByServiceData = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        spendByService: true,
      }));
      const spendByService = await config.fetchDashboardSpendByServiceData({
        selectedCurrency,
      });

      setData((preValue) => {
        return {
          ...preValue,
          spendByService: {
            aws: spendByService?.aws,
            azure: spendByService?.azure,
          },
        };
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setUpdatedCurrency(selectedCurrency);
      setLoader((prevalue) => ({
        ...prevalue,
        spendByService: false,
      }));
    }
  };

  if (data.spendByService && data.spendByService.aws) {
    data.spendByService.aws.piChartData = data.spendByService.aws?.piChartData
      ?.sort((a, b) => b.value - a.value)
      ?.filter((el) => el.value > 0.01);
  }
  if (data.spendByService && data.spendByService.azure) {
    data.spendByService.azure.piChartData = data.spendByService.azure?.piChartData
      ?.sort((a, b) => b.value - a.value)
      ?.filter((el) => el.value > 0.01);
  }

  /********************  Spend Forecast section starts *********************/

  const fetchSpendForecastData = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        spendForecast: true,
      }));
      const spendForecast = await config.fetchDashboardSpendForecastData({
        selectedCurrency,
      });

      const formattedSpendForecastData = formattedForecastData(spendForecast);
      setData((prevValue: any) => {
        return { ...prevValue, spendForecast: formattedSpendForecastData };
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setUpdatedCurrency(selectedCurrency);
      setLoader((prevalue) => ({
        ...prevalue,
        spendForecast: false,
      }));
    }
  };

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderColorfulLegendText = (value: any, { payload }: any) => {
    return (
      <React.Fragment>
        <span
          style={{
            textDecoration: 'none',
            color: payload.fill,
            pointerEvents: 'auto',
          }}
        >
          {value}
        </span>

        <span>{costFormatter2(updatedCurrency, payload?.value)}</span>
      </React.Fragment>
    );
  };

  const CustomTooltipPie = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">{payload[0].name}</div>
          <div className="ttLabel">
            <span className="ttKey">Monthly Cost</span>
            <span className="ttValue">
              {costFormatter2(updatedCurrency, payload[0].value)}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipSpendByService = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">{payload[0].name}</div>
          <div className="ttLabel">
            <span className="ttKey">{payload[0].dataKey}</span>
            <span className="ttValue">
              {costFormatter2(updatedCurrency, payload[0].value)}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  /********************  Spend Forecast section ends *********************/

  /********************  per change section start *********************/

  const fetchPerChangeData = async () => {
    try {
      setLoader((prevalue) => ({
        ...prevalue,
        perChange: true,
      }));
      const perChangeData = await config.fetchDashboardPerChange();

      setData((prevValue) => {
        return {
          ...prevValue,
          perChange: {
            AWS: perChangeData.AWS?.perChange,
            AZURE: perChangeData.AZURE ? perChangeData.AZURE.perChange : 0,
          },
        };
      });
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevalue) => ({
        ...prevalue,
        perChange: false,
      }));
    }
  };

  /********************  per change section ends *********************/
  useEffect(() => {
    fetchPerChangeData();
    fetchAccountsData();
    fetchSpendAmount();
    fetchRightSizingData();
    fetchIdleResourcesData();
    fetchSpendForecastData();
    fetchSpendByServiceData();
  }, [selectedCurrency]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /************************ Render Notifications Functional Props Start ***********************/

  const countAllNotifications = async () => {
    try {
      const cloudProvider = selectedAccount?.CloudProvider.toUpperCase();
      const LastViewed =
        localStorage.getItem(
          `LAST_VIEWED${
            cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
              ? selectedAccount?.AccountId
              : selectedAccount?.AzureId
          }`
        ) || '';
      if (user) {
        let accountId;
        if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS && selectedAccount) {
          accountId = selectedAccount?.AccountId;
        } else if (
          cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE &&
          selectedAccount
        ) {
          accountId = selectedAccount?.AzureId;
        }
        const result = await userService.getNotificationDetails(accountId, {
          Offset: 0,
          Limit: 1,
          Date: '',
          LastViewed,
          GetCounts: true,
          CloudProvider: selectedAccount?.CloudProvider.toUpperCase(),
          CustomerId: user?.stripe_customer_id,
        });
        const { notificationCount } = result;
        setTotalNotifications(notificationCount);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  useEffect(() => {
    listener('New Notification', (notification: any) => {
      if (!newNotificationDetails) {
        setNewNotificationDetails(notification);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      selectedAccount.CloudProvider.toUpperCase() ===
      CLOUD_PROVIDER_SERVICES.AZURE
        ? emitter('azureId', selectedAccount?.AzureId)
        : selectedAccount.CloudProvider.toUpperCase() ===
          CLOUD_PROVIDER_SERVICES.AWS
        ? emitter('azureId', selectedAccount?.AccountId)
        : emitter('azureId', selectedAccount?.AccountId);
    }
    countAllNotifications();
    fetchNotifications();
  }, [selectedAccount?.AzureId, selectedAccount?.AccountId, user]);

  useEffect(() => {
    const valueOfActiveTab =
      selectedAccount?.CloudProvider.toUpperCase() ===
      CLOUD_PROVIDER_SERVICES.AZURE
        ? 1
        : 0;
    setActiveTab(valueOfActiveTab);
  }, [selectedAccount?.CloudProvider]);

  return (
    <>
      <div className="appDashboard">
        <div className="topStrip">
          <div className="dashboardLeftPanel">
            <div className="cloudAccountCard">
              <div className="cloudAccountLogo">
                <img src={awsIcon} alt="" height="25" />
              </div>
              <div className="cloudAccountDetail">
                <div className="cloudAccountCount">
                  <div className="cloudAccountLabel">Accounts:</div>
                  <div className="cloudAccountNumber">
                    {loader.accounts ? (
                      <span>
                        <CircularProgress size={'1rem'} />
                      </span>
                    ) : (
                      <span>
                        {(data?.accounts?.AWS).toString().padStart(2, '0')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="cloudAccountCard">
              <div className="cloudAccountLogo">
                <img src={azureIcon} alt="" height="25" />
              </div>
              <div className="cloudAccountDetail">
                <div className="cloudAccountCount">
                  <div className="cloudAccountLabel">Accounts:</div>
                  <div className="cloudAccountNumber">
                    {loader.accounts ? (
                      <span>
                        <CircularProgress size={'1rem'} />
                      </span>
                    ) : (
                      <span>
                        {(data?.accounts?.AZURE?.accounts)
                          .toString()
                          .padStart(2, '0')}
                      </span>
                    )}
                  </div>
                </div>

                <div className="cloudAccountCount">
                  <div className="cloudAccountLabel">Subscriptions:</div>
                  <div className="cloudAccountNumber">
                    {loader.accounts ? (
                      <span>
                        <CircularProgress size={'1rem'} />
                      </span>
                    ) : (
                      <span>
                        {(data?.accounts?.AZURE?.subscriptions)
                          .toString()
                          .padStart(2, '0')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="infoBtn">
              <Tooltip
                title={
                  <div className="tooltipText">
                    The costs in various currencies are converted into{' '}
                    {currencies[selectedCurrency].symbol}. There may be a small
                    variance of 0.5% to 1% due to fluctuations in exchange
                    rates.
                  </div>
                }
              >
                <Box style={{ display: 'flex', alignItems: 'right' }}>
                  <InfoIcon
                    fontSize="large"
                    style={{ color: '#0078DB', cursor: 'pointer' }}
                  />
                  <span className="infoText">Info</span>
                </Box>
              </Tooltip>
            </div>
          </div>

          <div className="dashboardRightPanel">
            <div className="cloudAccountCard spendMonthly">
              <div className="cloudAccountLogo">
                <h3>SPEND</h3>
                <div className="subText">Monthly</div>
              </div>
              <div className="cloudAccountDetail">
                <div className="cloudAccountCount">
                  {loader.spendAmount ? (
                    <div>
                      <CircularProgress size={'2.5rem'} />
                    </div>
                  ) : (
                    <div>
                      <h3 className="fw-600">
                        {costFormatter2(updatedCurrency, data?.spendAmount)}
                        {/* ${data?.spendAmount} */}
                      </h3>
                      <div className="subText">(AWS + Azure)</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottomPanel">
          <div className="dashboardLeftPanel flexColumn">
            <div className="topLevel">
              {/* RightSizing */}
              <div className="topLevelCards ">
                <div className="rightSizingCardHeader mb-10 mt-10">
                  <span className="dashboardText font-20px fw-300">
                    Rightsizing
                  </span>
                  {loader.rightSizing ? (
                    <Box>
                      <CircularProgress size={'1.5rem'} />
                    </Box>
                  ) : (
                    <span className=" font-25px fw-500">
                      {rightSizingTotal}
                    </span>
                  )}
                </div>

                {loader.rightSizing ? (
                  <div className="loadingBoxSpend">
                    <Box>
                      <CircularProgress />
                    </Box>
                  </div>
                ) : (
                  <Grid
                    alignItems="center"
                    justifyContent="space-around"
                    className="mt-10 "
                    container
                    spacing={2}
                    direction="row"
                  >
                    <Grid className="d-middle-center flex-column" item>
                      <div className="mb-20 d-flex space-around ">
                        <UpIcon width={17} fill="red" />{' '}
                        <p className="fw-600 font-12px ml-10">Upsize</p>
                      </div>
                      <BarChart
                        onClick={() => history.push(`/${company}/rightsizing`)}
                        className="cursor-pointer"
                        width={140}
                        height={130}
                        data={data.rightSizing}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        {/* <CartesianGrid strokeDasharray="1" vertical={false} /> */}
                        <XAxis
                          onClick={() =>
                            history.push(`/${company}/rightsizing`)
                          }
                          className="cursor-pointer"
                          dataKey="name"
                          domain={[
                            CLOUD_PROVIDER_SERVICES.AWS,
                            CLOUD_PROVIDER_SERVICES.AZURE,
                          ]}
                        />
                        {data.rightSizing.length > 0 ? (
                          <RechartsTooltip
                            content={<CustomDownscaleTooltip />}
                            cursor={{ fill: '#f5f5f5' }}
                          />
                        ) : (
                          ''
                        )}

                        <Bar
                          onClick={() =>
                            history.push(`/${company}/rightsizing`)
                          }
                          className="cursor-pointer"
                          dataKey="upscaleMonthlyCost"
                          width="50"
                          barSize={15}
                          stackId="a"
                        >
                          <LabelList
                            onClick={() =>
                              history.push(`/${company}/rightsizing`)
                            }
                            className="cursor-pointer"
                            dataKey="upscaleMonthlyCost"
                            position="top"
                            content={(props: any) => (
                              <text
                                x={props.x + props.width / 2}
                                y={props.y - 10}
                                fill="#666"
                                textAnchor="middle"
                              >
                                {costFormatter2(updatedCurrency, props.value)}
                                {/* {`$${props.value.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )}`} */}
                              </text>
                            )}
                          />
                          {data.rightSizing.map((entry: any, index: any) => (
                            <Cell
                              onClick={() =>
                                history.push(`/${company}/rightsizing`)
                              }
                              className="cursor-pointer"
                              key={`cell-${index}`}
                              fill={barColors[index]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </Grid>
                    <Grid className="d-middle-center flex-column " item>
                      <div className="mb-20 d-flex space-around ">
                        <DownIcon width={17} fill="green" />{' '}
                        <p className="fw-600 font-12px ml-10">Downsize</p>
                      </div>

                      <BarChart
                        onClick={() => history.push(`/${company}/rightsizing`)}
                        className="cursor-pointer"
                        width={140}
                        height={130}
                        data={data.rightSizing}
                        margin={{
                          top: 20,
                          right: 20,
                          left: 10,
                          bottom: 5,
                        }}
                      >
                        <XAxis
                          onClick={() =>
                            history.push(`/${company}/rightsizing`)
                          }
                          className="cursor-pointer"
                          dataKey="name"
                          domain={[
                            CLOUD_PROVIDER_SERVICES.AWS,
                            CLOUD_PROVIDER_SERVICES.AZURE,
                          ]}
                        />
                        {data.rightSizing.length > 0 ? (
                          <RechartsTooltip
                            content={<CustomDownscaleTooltip />}
                            cursor={{ fill: '#f5f5f5' }}
                          />
                        ) : (
                          ''
                        )}

                        <Bar
                          onClick={() =>
                            history.push(`/${company}/rightsizing`)
                          }
                          className="cursor-pointer"
                          dataKey="downscaleMonthlyCost"
                          width="50"
                          barSize={15}
                          stackId="b"
                        >
                          <LabelList
                            onClick={() =>
                              history.push(`/${company}/rightsizing`)
                            }
                            className="cursor-pointer"
                            dataKey="downscaleMonthlyCost"
                            position="top"
                            content={(props: any) => (
                              <text
                                className="cursor-pointer"
                                x={props.x + props.width / 2}
                                y={props.y - 10}
                                fill="#666"
                                textAnchor="middle"
                              >
                                {costFormatter2(updatedCurrency, props.value)}
                                {/* {`$${props.value.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )}`} */}
                              </text>
                            )}
                          />
                          {data.rightSizing.map((entry: any, index: any) => (
                            <Cell
                              className="cursor-pointer"
                              onClick={() =>
                                history.push(`/${company}/rightsizing`)
                              }
                              key={`cell-${index}`}
                              fill={barColors[index]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </Grid>
                  </Grid>
                )}
              </div>

              {/* Idle Resources section */}

              <div className="topLevelCards">
                <div className="rightSizingCardHeader mb-10 mt-10">
                  <span className="dashboardText font-20px fw-300">
                    Idle Resources
                  </span>
                  {loader.idle ? (
                    <Box>
                      <CircularProgress size={'1.5rem'} />
                    </Box>
                  ) : (
                    <Tooltip
                      title={
                        <>
                          <div className="p-5">Total Idle Resources</div>
                          <div className="tooltipText idleTooltip">
                            <div>
                              <span>AWS: </span>
                              <span>AZURE: </span>
                            </div>
                            <div>
                              <span>{data.idle[0]?.AWS.totalCount}</span>
                              <span>{data.idle[0]?.AZURE.totalCount}</span>
                            </div>
                          </div>
                        </>
                      }
                    >
                      <span className=" font-25px fw-500 pointer">
                        {data.idle[0]?.AZURE.totalCount +
                          data.idle[0]?.AWS.totalCount || 0}
                      </span>
                    </Tooltip>
                  )}
                </div>
                <Grid
                  alignItems="center"
                  justifyContent="space-around"
                  className="mt-10 "
                  container
                  spacing={2}
                  direction="row"
                  width={'100%'}
                  margin={'auto'}
                >
                  <Grid item>
                    {loader.idle ? (
                      <div className="mt-40 ml-90">
                        <Box>
                          <CircularProgress />
                        </Box>
                      </div>
                    ) : (
                      <PieChart
                        width={115}
                        height={160}
                        className="cursor-pointer"
                        onClick={() => history.push(`/${company}/idleresource`)}
                      >
                        <text
                          className="cursor-pointer"
                          onClick={() =>
                            history.push(`/${company}/idleresource`)
                          }
                          x={57}
                          y={65}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          {currencies[selectedCurrency].symbol}
                          {data.idle[0]?.AWS
                            ? formatNumber(
                                +data.idle[0]?.AWS.totalCost.toFixed(2)
                              )
                            : 0.0}
                        </text>
                        <Pie
                          className="cursor-pointer"
                          onClick={() =>
                            history.push(`/${company}/idleresource`)
                          }
                          activeIndex={activeAwsIndex}
                          activeShape={renderAwsActiveShape}
                          data={
                            data?.idle[0]?.AWS
                              ? data.idle[0]?.AWS.data
                              : defaultPieData
                          }
                          cx={'50%'}
                          cy={'40%'}
                          innerRadius={32}
                          outerRadius={47}
                          fill="#aaaaaa"
                          dataKey="count"
                          onMouseEnter={(e: any, index: any) => {
                            setActiveAwsIndex(index);
                          }}
                          onMouseOut={(e, index) => {
                            setActiveAwsIndex(index);
                          }}
                        >
                          {data.idle[0]?.AWS.data.map(
                            (entry: any, index: any) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  data.idle[0]?.AWS.data.length > 0
                                    ? piRedColorsArr[
                                        index % data.idle[0]?.AWS.data.length
                                      ]
                                    : defaultColor[index]
                                }
                                cursor="pointer"
                                onClick={() =>
                                  history.push(`/${company}/idleresource`)
                                }
                              />
                            )
                          )}
                        </Pie>
                        <g style={{ zIndex: 10 }}>
                          <Text x={57} y={150} textAnchor="middle" fill="black">
                            AWS
                          </Text>
                        </g>
                        {data?.idle[0]?.AWS &&
                        data.idle[0]?.AWS.data.length > 0 ? (
                          <RechartsTooltip
                            content={<CustomTooltipPie />}
                            position={{ x: 0, y: 0 }}
                            cursor={{ fill: '#f5f5f5' }}
                          />
                        ) : (
                          ''
                        )}
                      </PieChart>
                    )}
                  </Grid>

                  <Grid item>
                    {loader.idle ? (
                      <div className="mt-40 "></div>
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => history.push(`/${company}/idleresource`)}
                        style={{ position: 'relative', zIndex: 1 }}
                      >
                        <PieChart
                          width={115}
                          height={160}
                          className="cursor-pointer"
                          onClick={() =>
                            history.push(`/${company}/idleresource`)
                          }
                        >
                          <text
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(`/${company}/idleresource`)
                            }
                            x={57}
                            y={65}
                            textAnchor="middle"
                            dominantBaseline="middle"
                          >
                            {currencies[selectedCurrency].symbol}
                            {formatNumber(
                              +data.idle[0]?.AZURE.totalCost.toFixed(2)
                            ) || 0.0}
                          </text>
                          <Pie
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(`/${company}/idleresource`)
                            }
                            activeIndex={activeAzureIndex}
                            activeShape={renderAzureActiveShape}
                            data={
                              data.idle[0]?.AZURE.data.length > 0
                                ? data.idle[0]?.AZURE.data
                                : defaultPieData
                            }
                            cx={'50%'}
                            cy={'40%'}
                            innerRadius={32}
                            outerRadius={47}
                            fill="#aaaaaa"
                            dataKey="count"
                            onMouseEnter={(e: any, index: any) => {
                              setActiveAzureIndex(index);
                            }}
                            onMouseOut={(e, index) => {
                              setActiveAzureIndex(index);
                            }}
                          >
                            {data.idle[0]?.AZURE.data.map(
                              (entry: any, index: any) => (
                                <Cell
                                  onClick={() =>
                                    history.push(`/${company}/idleresource`)
                                  }
                                  className="cursor-pointer"
                                  key={`cell-${index}`}
                                  fill={
                                    data.idle[0]?.AZURE.data.length > 0
                                      ? piRedColorsArr[
                                          index %
                                            data.idle[0]?.AZURE.data.length
                                        ]
                                      : defaultColor[index]
                                  }
                                  cursor="pointer"
                                />
                              )
                            )}
                            <Line
                              onClick={() =>
                                history.push(`/${company}/idleresource`)
                              }
                              className="cursor-pointer"
                              x1={200}
                              y1={200}
                              x2={200}
                              y2={250}
                              stroke="black"
                              strokeWidth={1}
                            />
                          </Pie>
                          <g style={{ zIndex: 10 }}>
                            <Text
                              x={57}
                              y={150}
                              textAnchor="middle"
                              fill="black"
                            >
                              AZURE
                            </Text>
                          </g>
                          {data.idle[0]?.AZURE.data.length > 0 ? (
                            <RechartsTooltip
                              content={<CustomTooltipPie />}
                              cursor={{ fill: '#f5f5f5' }}
                            />
                          ) : (
                            ''
                          )}
                        </PieChart>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* Spend section */}

              <div style={{ zIndex: '0' }} className="topLevelCards">
                <div className="rightSizingCardHeader mb-10 mt-10">
                  <span className="dashboardText font-20px fw-300">Spend</span>
                  <span className=" font-23px fw-500">
                    % <span className="font-18px">Change</span>
                  </span>
                </div>

                {loader.perChange ? (
                  <div className="loadingBox mt-40">
                    <Box>
                      <CircularProgress />
                    </Box>
                  </div>
                ) : (
                  <Grid
                    direction="row"
                    className="d-middle-center spendSection mt-40 "
                    width={'100%'}
                    margin={'auto'}
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item>
                      <div
                        className={
                          +Math.round(data.perChange.AWS) >= 0
                            ? +Math.round(data.perChange.AWS) > 0
                              ? 'errorColor minw-120 centerBaseline cursor'
                              : 'neutralColor cursor'
                            : 'successColor minw-120 centerBaseline cursor'
                        }
                        onClick={() => history.push(`/${company}/billing`)}
                      >
                        <span className="font-60px fw-600">
                          {Math.abs(Math.round(data.perChange.AWS))}
                        </span>
                        <span className="font-20px fw-600">%</span>
                        <span>
                          {Math.round(data.perChange.AWS) >= 0 ? (
                            Math.round(data.perChange.AWS) > 0 ? (
                              <UpLongIcon fill="red" width={13} height={14} />
                            ) : null
                          ) : (
                            <DownLongIcon fill="green" width={13} height={14} />
                          )}
                        </span>
                      </div>
                      <div className="d-middle-center mt-44">AWS</div>
                    </Grid>
                    <Grid item>
                      <div className="flex-column sContainer">
                        <div
                          className={
                            +Math.round(data.perChange.AZURE) >= 0
                              ? +Math.round(data.perChange.AZURE) > 0
                                ? 'errorColor minw-120 centerBaseline cursor'
                                : 'neutralColor cursor'
                              : 'successColor minw-120 centerBaseline cursor'
                          }
                          onClick={() => history.push(`/${company}/billing`)}
                        >
                          <span className="font-60px fw-600">
                            {Math.abs(Math.round(data.perChange.AZURE))}
                          </span>
                          <span className="font-20px fw-600">%</span>
                          <span>
                            {Math.round(data.perChange.AZURE) >= 0 ? (
                              Math.round(data.perChange.AZURE) > 0 ? (
                                <UpLongIcon fill="red" width={13} height={14} />
                              ) : null
                            ) : (
                              <DownLongIcon
                                fill="green"
                                width={13}
                                height={14}
                              />
                            )}
                          </span>
                        </div>
                        <div className="d-middle-center mt-44">AZURE</div>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>

            <div className="sbsSFCards ">
              {/* Spend by service */}
              <div className="sbsCard">
                <div className="rightSizingCardHeader mb-10 mt-10">
                  <span className="dashboardText font-20px fw-300">
                    Spend By Service
                  </span>
                  <span>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                      <Tab
                        label={
                          <img src={awsIcon} alt="AWS logo" height="22px" />
                        }
                      />
                      <Tab
                        label={
                          <img src={azureIcon} alt="Azure logo" height="22px" />
                        }
                      />
                    </Tabs>
                  </span>
                </div>
                <div>
                  {loader.spendByService ? (
                    <Box className="loadingBoxSpend ">
                      <CircularProgress />
                    </Box>
                  ) : activeTab === 0 ? (
                    <ResponsiveContainer
                      width="100%"
                      height={windowSize < 1600 ? 450 : 250}
                    >
                      <PieChart width={520} height={250}>
                        <Pie
                          onClick={() =>
                            history.push(`/${company}/costforecasting`)
                          }
                          className="cursor-pointer"
                          activeIndex={activeSpendAwsIndex}
                          activeShape={renderAwsActiveShape}
                          dataKey="value"
                          data={
                            data.spendByService.aws?.piChartData?.length ?? 0
                              ? data.spendByService.aws?.piChartData
                              : defaultSpendByServicePieData
                          }
                          cx={'50%'}
                          cy={'50%'}
                          innerRadius={70}
                          outerRadius={100}
                          fill="#aaaaaa"
                          onMouseEnter={(e: any, index: any) => {
                            setActiveSpendAwsIndex(index);
                          }}
                          onMouseOut={(e, index) => {
                            setActiveSpendAwsIndex(index);
                          }}
                        >
                          {data.spendByService?.aws?.piChartData?.map(
                            (entry, index) => (
                              <Cell
                                onClick={() =>
                                  history.push(`/${company}/costforecasting`)
                                }
                                className="cursor-pointer"
                                key={`cell-${index}`}
                                fill={
                                  data.spendByService.aws?.piChartData
                                    ?.length ?? 0 > 0
                                    ? piRedColorsArr[
                                        index %
                                          (data.spendByService.aws?.piChartData
                                            ?.length ?? 0)
                                      ]
                                    : defaultColor[index]
                                }
                                cursor="pointer"
                              />
                            )
                          )}
                        </Pie>
                        {data.spendByService.aws?.piChartData?.length ??
                        0 > 0 ? (
                          <RechartsTooltip
                            content={<CustomTooltipSpendByService />}
                            cursor={{ fill: '#f5f5f5' }}
                          />
                        ) : (
                          ''
                        )}
                        <Legend
                          align={windowSize < 1600 ? 'center' : 'right'}
                          verticalAlign={
                            windowSize < 1600 ? 'bottom' : 'middle'
                          }
                          iconType="square"
                          iconSize={16}
                          layout="vertical"
                          wrapperStyle={
                            windowSize < 1600
                              ? data.spendByService.aws?.piChartData?.length ??
                                0 > 0
                                ? {
                                    top: '280px',
                                  }
                                : {
                                    top: '350px',
                                  }
                              : {
                                  top: '28.5058px',
                                }
                          }
                          formatter={renderColorfulLegendText}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : activeTab === 1 ? (
                    <ResponsiveContainer
                      width="100%"
                      height={windowSize < 1600 ? 450 : 250}
                    >
                      <PieChart width={520} height={250}>
                        <Pie
                          onClick={() =>
                            history.push(`/${company}/costforecasting`)
                          }
                          className="cursor-pointer"
                          activeIndex={activeSpendAzureIndex}
                          activeShape={renderAzureActiveShape}
                          dataKey="value"
                          data={
                            data.spendByService.azure?.piChartData?.length ??
                            0 > 0
                              ? data.spendByService.azure?.piChartData
                              : defaultSpendByServicePieData
                          }
                          cx={'50%'}
                          cy={'45%'}
                          innerRadius={70}
                          outerRadius={100}
                          fill="#aaaaaa"
                          onMouseEnter={(e: any, index: any) => {
                            setActiveSpendAzureIndex(index);
                          }}
                          onMouseOut={(e, index) => {
                            setActiveSpendAzureIndex(index);
                          }}
                        >
                          {data.spendByService?.azure?.piChartData?.map(
                            (entry, index) => (
                              <Cell
                                onClick={() =>
                                  history.push(`/${company}/costforecasting`)
                                }
                                className="cursor-pointer"
                                key={`cell-${index}`}
                                fill={
                                  data.spendByService.azure?.piChartData
                                    ?.length ?? 0 > 0
                                    ? piRedColorsArr[
                                        index %
                                          (data.spendByService?.azure
                                            ?.piChartData?.length ?? 0)
                                      ]
                                    : defaultColor[index]
                                }
                                cursor="pointer"
                              />
                            )
                          )}
                        </Pie>
                        {data.spendByService?.azure?.piChartData?.length ??
                        0 > 0 ? (
                          <RechartsTooltip
                            content={<CustomTooltipSpendByService />}
                            cursor={{ fill: '#f5f5f5' }}
                          />
                        ) : (
                          ''
                        )}
                        <Legend
                          align={windowSize < 1600 ? 'center' : 'right'}
                          verticalAlign={
                            windowSize < 1600 ? 'bottom' : 'middle'
                          }
                          iconType="square"
                          iconSize={16}
                          layout="vertical"
                          wrapperStyle={
                            windowSize < 1600
                              ? data.spendByService.azure?.piChartData
                                  ?.length ?? 0 > 0
                                ? {
                                    top: '280px',
                                  }
                                : {
                                    top: '350px',
                                  }
                              : {
                                  top: '28.5058px',
                                }
                          }
                          formatter={renderColorfulLegendText}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {/* spend forecast */}
              <div className="sfCard">
                <div className="rightSizingCardHeader mb-30 mt-10">
                  <span className="dashboardText font-20px fw-300">
                    Spend Forecast
                  </span>
                </div>
                {loader.spendForecast ? (
                  <div className="loadingBoxSpend">
                    <Box>
                      <CircularProgress />
                    </Box>
                  </div>
                ) : (
                  <div className="gridCenter mr-30 ">
                    <Grid
                      direction="row"
                      className="d-middle-center spendSection "
                      width={'100%'}
                      margin={'auto'}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Grid item>
                        <SpendForecast
                          data={data.spendForecast.aws}
                          cloudProviderName={CLOUD_PROVIDER_SERVICES.AWS}
                          selectedCurrency={selectedCurrency}
                        />
                      </Grid>
                      <Grid item>
                        <SpendForecast
                          data={data.spendForecast.azure}
                          cloudProviderName={CLOUD_PROVIDER_SERVICES.AZURE}
                          selectedCurrency={selectedCurrency}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboardRightPanel">
            <div className="dashBoardActivities">
              <div className="activitiesBubble">
                <div className="activitiesList">
                  <div className="activitiesHead">
                    <h6>Recent Activities</h6>
                  </div>
                  <div>
                    {!user ? (
                      <p className="noNotificationFound">No Activities Found</p>
                    ) : totalNotifications === 0 ? (
                      <p className="noNotificationFound">No Activities Found</p>
                    ) : (
                      <Notifications
                        className="activitiesBody"
                        totalNotifications={totalNotifications}
                        isItemLoaded={isItemLoaded}
                        notificationList={notificationList}
                        loadMoreItems={loadMoreItems}
                        itemCount={itemCount}
                        getItemSize={getItemSize}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AppDashboard);
