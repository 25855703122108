import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { spendForecastMetaData } from '../../schemas/dashboard.schema';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { costFormatter2 } from '../../utils/config';

interface spendForecastData {
  data?: spendForecastMetaData[];
  cloudProviderName: string;
  selectedCurrency: string;
}

const SpendForecast: React.FC<spendForecastData> = ({
  data,
  cloudProviderName,
  selectedCurrency,
}) => {
  const xAxisTicks = ['Last Month', 'MTD Cost', 'Forecast'];

  const history = useHistory();
  const company = localStorage.getItem('CompanyName');

  const CustomSpendForecastTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="ttTitle">{label}</div>
          {label !== 'Forecast' && (
            <div className="ttLabel">
              <span className="ttKey">Spend</span>
              <span className="ttValue">
                {costFormatter2(selectedCurrency, payload[0].value)}
              </span>
            </div>
          )}
          {label !== 'Forecast' && payload[1] && (
            <div className="ttLabel">
              <span className="ttKey">Spend</span>
              <span className="ttValue">
                {costFormatter2(selectedCurrency, payload[1].value)}
              </span>
            </div>
          )}
          {label === 'Forecast' && (
            <div className="ttLabel">
              <span className="ttKey">Total</span>
              <span className="ttValue">
                {costFormatter2(selectedCurrency, payload[0].value)}
              </span>
            </div>
          )}
        </div>
      );
    }

    return null;
  };
  const costs: any = data?.map((item: any) =>
    parseFloat(item[cloudProviderName])
  );
  const maxCost = Math.max(...costs);
  const yAxisMax = Math.ceil(maxCost * 1.1);
  const yAxisInterval = Math.ceil(yAxisMax / 4);
  const yAxisTicks = Array.from(Array(5).keys()).map(
    (tick) => tick * yAxisInterval
  );
  return (
    <BarChart width={250} height={250} data={data}>
      <CartesianGrid
        className="cursor-pointer"
        strokeDasharray="1"
        vertical={false}
      />
      <XAxis
        className="cursor-pointer"
        style={{
          fontSize: '1.1rem',
          textAnchor: 'middle',
        }}
        dataKey="name"
        interval={0}
        domain={['Last Month', 'MTD Cost', 'Forecast']}
        ticks={xAxisTicks}
      />
      <YAxis
        className="cursor-pointer"
        tickFormatter={(e) => `${costFormatter2(selectedCurrency, e)}`}
        domain={[0, yAxisMax]}
        ticks={yAxisTicks}
      />
      <Legend />
      <RechartsTooltip
        content={<CustomSpendForecastTooltip />}
        cursor={{ fill: '#f5f5f5' }}
      />
      <Bar
        onClick={() => history.push(`/${company}/costforecasting`)}
        className="cursor-pointer"
        barSize={30}
        dataKey={cloudProviderName}
        fill={
          cloudProviderName === CLOUD_PROVIDER_SERVICES.AWS
            ? '#FE9401'
            : '#008ADD'
        }
      />
    </BarChart>
  );
};

export default SpendForecast;
