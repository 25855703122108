import { GlobalQueryParams } from '../../utils/config';
import { HttpService } from '../HttpService/HttpService';

export class IdleInstanceService {
  constructor(private httpService: HttpService) {}

  async getIdleinstanceList(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/idleinstances/get/type?'),
        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async getIdleinstanceDetails(params: any): Promise<any> {
    try {
      const response = await this.httpService.get(
        GlobalQueryParams(params, '/idleinstances/get?'),

        {}
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async getunusedResourceDetails(
    type: string,
    subscriptionId: any
  ): Promise<any> {
    try {
      const response = await this.httpService.get(
        '/unusedresources/get?ResourceType=' + type,
        {
          params: {
            ...(subscriptionId !== 'all'
              ? { SubscriptionId: subscriptionId }
              : {}),
          },
        }
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async toggleInstanceState(params: any) {
    try {
      const response = await this.httpService.post(
        '/idleinstances/toggleState',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async toggleUnusedResourceState(params: any) {
    try {
      const response = await this.httpService.post(
        '/unusedresources/toggleState',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async requestFix(params: any): Promise<any> {
    try {
      const response = await this.httpService.post(
        '/idleinstances/requestFix',
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async removeInstance(params: any): Promise<any> {
    try {
      const response = await this.httpService.delete(
        '/idleinstances/removeInstance',
        params
      );
      const { data } = response;

      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
  async removeUnusedResource(params: any): Promise<any> {
    try {
      const response = await this.httpService.delete(
        '/unusedresources/removeResource',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }

  async setEmailNotification(params: any) {
    try {
      const response = await this.httpService.post(
        '/users/registerEmailAddresses',
        params
      );
      const { data } = response;
      if (data.sc == 1) {
        const { result } = data;
        // authService.setAuth(result.token);
        return result;
      }
    } catch (e) {
      throw e;
    }
  }
}
