import React, { Fragment, useState, useEffect } from 'react';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { ReactComponent as EditIcon } from '../../assets/images/editPen.svg';
import { ReactComponent as AddIcon } from '../../assets/images/addIcon.svg';
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
} from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import { inventoryService, subscriptionsService } from '../../services';
import GaugeChart from 'react-gauge-chart';
import { useHistory } from 'react-router';

import { useSelector } from 'react-redux';
import { accountState, userState } from '../../store/index';
import { AddTagModal } from '../../components/Modals/AddTagModal/AddTagModal';
import { config } from './config';
import {
  CLOUD_PROVIDER_SERVICES,
  HTTP_STATUS_CODE,
  PERMISSION_KEY,
  PERMISSION_LEVELS,
} from '../../utils/constants';
import {
  getSelectedSubscription,
  setSelectedSubscription,
} from './Inventories';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { handleError } from '../../utils/config';

interface Inventory {
  childern?: React.ReactNode;
  location?: {
    state?: {
      SubscriptionId: string;
      InventoryType: string;
    };
  };
}

export const Inventory: React.FC = (props: any) => {
  const history = useHistory();
  const count = props.location.state.totalResourcesCount;
  const tableRow = [
    'VM Name',
    'VM CPU Utilization',
    'Total Capacity Used',
    'Usage',
    'Last Usage Date',
    'Utilization Score',
  ];
  const [loader, setLoader] = useState<any>(false);
  const [openAddTagModal, setOpenAddTagModal] = useState<boolean>(false);
  const selectedAccount = useSelector(accountState).selectedAccount;
  const [search, setSearch] = useState<any>('');
  const isAccountSelected = useSelector(accountState).isAccountSelected;
  const [instanceData, setInstanceData] = useState<any>();
  const [inventoryDetails, setInventoyDetails] = useState([]);
  const [allInventoryDetails, setAllInventoyDetails] = useState([]);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [filterData, setFilterData] = React.useState<any>([]);
  const [toggle, setToggle] = useState(true);
  const [isDuplicateKey, setIsDuplicateKey] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [pageNo, setPageNo] = useState<number>(1);
  const resourceName = props.location.state.InventoryType;
  const accountId = props.location.state.selectedAccountId;
  const subscriptionId = props.location.state.selectedSubscriptionId;
  const cloudProvider = props.location.state.cloudProvider;
  const user = useSelector(userState).userData;
  const actionPermission = user?.Role?.permissions;
  const tagsPermission = actionPermission?.find(
    (obj: any) => obj.Entity.Key === PERMISSION_KEY.Tags
  );
  const type = window.location.pathname.split('/')[2];

  const getFilterData = async () => {
    if (selectedAccount && isAccountSelected) {
      const result = await subscriptionsService.getFilterData(
        selectedAccount?.CloudProvider.toUpperCase()
      );

      result.length === 1 &&
        getSelectedSubscription() !==
          (selectedAccount?.CloudProvider.toUpperCase() ===
          CLOUD_PROVIDER_SERVICES.AZURE
            ? result[0].SubscriptionId
            : selectedAccount?.CloudProvider.toUpperCase() ===
              CLOUD_PROVIDER_SERVICES.AWS
            ? result[0].IAMUserId
            : result[0].SubscriptionId) &&
        setSelectedSubscription(
          selectedAccount?.CloudProvider.toUpperCase() ===
            CLOUD_PROVIDER_SERVICES.AZURE
            ? result[0].SubscriptionId
            : selectedAccount?.CloudProvider.toUpperCase() ===
              CLOUD_PROVIDER_SERVICES.AWS
            ? result[0].IAMUserId
            : result[0].SubscriptionId
        );
      setFilterData(result);
    }
  };

  const handleTagButton = async (
    resourceData: any,
    tagList: any,
    updatedList: any
  ) => {
    try {
      setToggle(true);
      let data;
      selectedAccount?.CloudProvider.toUpperCase() ===
      CLOUD_PROVIDER_SERVICES.AZURE
        ? (data = {
            Id: resourceData.Id,
            resourceData: resourceData,
          })
        : selectedAccount?.CloudProvider.toUpperCase() ===
          CLOUD_PROVIDER_SERVICES.AWS
        ? (data = {
            Id: resourceData.Id,
            resourceData: resourceData,
          })
        : (data = {
            Id: resourceData.Id,
            resourceData: resourceData,
          });

      const tags: any = {};
      for (let i = 0; i < tagList.length; i++) {
        tags[tagList[i].key] = tagList[i].value;
      }

      const type = 'create';
      const secType = 'update';

      setLoader(true);

      if (Object.keys(updatedList).length > 0) {
        const updateValues = {
          accountId: resourceData.Account_Id,
          subscriptionId: resourceData.SubscriptionId,
          azureId: filterData[0]?.AzureId,
          region: data.resourceData.Region,
          resourceName,
          resourceGroupName:
            data.resourceData.Resource_Group ||
            data.resourceData.Resource_Group_Name,
          resourceIdentifierValue: data.resourceData.Tag_Resource_Id,
          Tags: updatedList,
          type: 'update',
        };
        let response;
        if (
          selectedAccount?.CloudProvider.toUpperCase() ===
          CLOUD_PROVIDER_SERVICES.AZURE
        ) {
          response = await inventoryService.azureTagging(updateValues, secType);
        } else if (
          selectedAccount?.CloudProvider.toUpperCase() ===
          CLOUD_PROVIDER_SERVICES.AWS
        ) {
          response = await inventoryService.awsTagging(updateValues, secType);
        }
        if (response) {
          setLoader(false);
          setOpenAddTagModal(false);
        }
      }

      //adding CFX- in front to every key of tag
      const newTags = Object.entries(tags).reduce(
        (acc: any, [key, value]: any) => {
          acc[`CFX-${key}`] = value;
          return acc;
        },
        {}
      );

      const values = {
        accountId: resourceData.Account_Id,
        subscriptionId: resourceData.SubscriptionId,
        azureId: filterData[0]?.AzureId,
        region: data.resourceData.Region,
        resourceName,
        resourceGroupName:
          data.resourceData.Resource_Group ||
          data.resourceData.Resource_Group_Name,
        resourceIdentifierValue: data.resourceData.Tag_Resource_Id,
        Tags: newTags,
        type: 'create',
      };
      let response;

      if (
        selectedAccount?.CloudProvider.toUpperCase() &&
        !Object.keys(tags).includes('') &&
        !Object.values(tags).includes('')
      ) {
        response = await config[
          selectedAccount?.CloudProvider.toUpperCase()
        ].tagging(values, type);

        if (response.status === HTTP_STATUS_CODE.CONFLICT) {
          setResponseMessage(response.message);
          setOpenAddTagModal(true);
          setIsDuplicateKey(true);
        } else {
          setOpenAddTagModal(false);
          setIsDuplicateKey(false);
        }
        setLoader(false);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleDeleteTag = async (resourceData: any, tag: any) => {
    try {
      let data;
      setToggle(true);
      setLoader(true);
      const cloudProvider:
        | string
        | undefined = selectedAccount?.CloudProvider.toUpperCase();
      cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE
        ? (data = {
            Id: resourceData.Id,
            resourceData: resourceData,
          })
        : cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
        ? (data = {
            Id: resourceData.Id,
            resourceData: resourceData,
          })
        : (data = {
            Id: resourceData.Id,
            resourceData: resourceData,
          });

      const resourceName = props.location.state.InventoryType;
      const values = {
        accountId: resourceData.Account_Id,
        subscriptionId: resourceData.SubscriptionId,
        region: data.resourceData.Region,
        resourceName,
        resourceGroupName:
          data.resourceData.Resource_Group ||
          data.resourceData.Resource_Group_Name,
        resourceIdentifierValue: data.resourceData.Tag_Resource_Id,
        Tags: tag,
        azureId: filterData[0]?.AzureId,
      };
      const confirmation = confirm(
        `Are you sure you want to delete ${tag.length == 1 ? 'tag?' : 'tags?'}`
      );

      let response;
      const deleteType = 'delete';
      if (confirmation) {
        if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
          // setLoader(true);
          response = await inventoryService.azureTagging(values, deleteType);
        } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
          response = await inventoryService.awsTagging(values, deleteType);
        }
      } else {
        setLoader(false);
      }
      if (response) {
        setLoader(false);
        setOpenAddTagModal(false);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleTagModal: any = (event: any, resourceData: any) => {
    try {
      resourceData?.Tags?.map((tag: any) => {
        tag?.completed === false ? setToggle(false) : setToggle(true);
      });
      setOpenAddTagModal(true);
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  // const checkRemainingDays = () => {
  //   alert(
  //     "Your Trial Period is over. Please contact support team at 'support@cloudforestx.com'."
  //   );
  //   history.push('/login');
  // };

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleSearchButton = () => {
    allInventoryDetails.forEach((inventory: any) => {
      const details = inventory.Details;
      const data: any = [];

      details.forEach((detail: any) => {
        if (detail.Tags.length > 0) {
          detail.Tags.forEach((tag: any) => {
            if (
              tag.key.toLowerCase() === search.toLowerCase() ||
              tag.value.toLowerCase() === search.toLowerCase()
            ) {
              data.push(detail);
            }
          });
        }
      });
      if (data) {
        const result: any = [
          {
            ...inventory,
            Details: data,
          },
        ];
        setInventoyDetails(result);
      }
      if (search === '') {
        setInventoyDetails(allInventoryDetails);
      }
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearchButton();
    }
  };

  const handleClick = (Url: string) => {
    const company = localStorage.getItem('CompanyName');
    if (Url) {
      if (Url === 'rightsizing') {
        history.push(`/${company}/${Url.toLowerCase()}`);
      } else {
        const caseUrl = `/${company}/${Url.toLowerCase()}/idle/${type}`;
        history.push(caseUrl);
      }
    }
  };

  const handlePageChangeNew = (e: any, value: any) => {
    setPageNo(value);
  };

  const getInventoryResourceDetails = async () => {
    try {
      setLoader(true);
      const params = {
        page: pageNo,
        subscriptionId,
        InventoryType:
          props.location &&
          props.location.state &&
          props.location.state.InventoryType,
        accountId,
        cloudProvider,
      };

      const result = await inventoryService.getInventoryResourceDetails(params);
      // result.forEach((res: any) => {
      //   if (res && res.Details) {
      //     setCount(res.Details.length);
      //   }
      // });
      setInventoyDetails(result);
      setAllInventoyDetails(result);
      setSearch('');
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      // if (user) {
      //   user?.RemainingDays === undefined ||
      //   user?.RemainingDays === 0 ||
      //   user?.RemainingDays < 0
      //     ? checkRemainingDays()
      //     : '';
      // }
    }
  };

  useEffect(() => {
    if (toggle) {
      getInventoryResourceDetails();
      getFilterData();
    }
  }, [openAddTagModal, pageNo]);

  const closeModal = () => {
    setIsDuplicateKey(false);
    setOpenAddTagModal(false);
    setToggle(false);
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <Fragment>
      {loader ? <LoadingScreen /> : ''}
      <React.Fragment>
        <div className="rsTableHeader inventoryHeader mb-20">
          <div className="inventoryHeading">
            <div className="d-inline-block">
              {props.location &&
                props.location.state &&
                props.location.state.InventoryType}
            </div>

            <sup className="inventoryDetailCount font-20px fw-500 ml-20">
              {count}
            </sup>
          </div>

          <div className="w-55 mr-0 inventoryFlexContent">
            <TextField
              id="searchInventory"
              className=" w-80 inventoryFlexItem "
              label="SEARCH BY TAG NAME"
              variant="outlined"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <button
              className="btn-primary btn ml-5 mr-0 inventoryFlexItem"
              onClick={handleSearchButton}
            >
              Search
            </button>
          </div>
        </div>
        <Grid item md={12} className="mb-10">
          <TableContainer
            className="accContainer"
            // style={{ maxHeight: '80vh', overflow: 'auto' }}
          >
            <Table
              className="table adminTable adminPanelTable"
              size="small"
              aria-label="a dense table"
              stickyHeader
            >
              <TableHead className="admintableHead">
                {!inventoryDetails || inventoryDetails.length === 0 ? (
                  <TableRow>
                    {tableRow.map((row, index) => (
                      <TableCell
                        className="adminTableheading"
                        key={index}
                        align="left"
                      >
                        {row}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  inventoryDetails.map((inventory: any, index: any) => (
                    <TableRow key={'inventory' + index}>
                      {inventory.Details && inventory.Details.length === 0
                        ? tableRow.map((row, index) => (
                            <TableCell
                              className="adminTableheading"
                              key={'details' + index}
                              align="left"
                            >
                              {row}
                            </TableCell>
                          ))
                        : Object.keys(inventory.Details[0]).map((key, index) =>
                            key === 'Id' ||
                            key === 'Unit' ||
                            key === 'Category' ||
                            key === 'Tags' ||
                            key === 'Tag_Resource_Id' ||
                            key === 'Tag_Modal_Header' ||
                            key === 'SubscriptionId' ? null : key ===
                              'CPU_Util' ? (
                              <TableCell
                                className="adminTableheading"
                                key={'cell' + index}
                                align="left"
                              >
                                {key.replace(/_/g, ' ')} (%)
                              </TableCell>
                            ) : key === 'CapacityUsed' ||
                              key === 'Storage_Capacity' ||
                              key === 'Volume_Size' ||
                              key === 'Size' ? (
                              <TableCell
                                className="adminTableheading"
                                key={'cell' + index}
                                align="left"
                              >
                                {key.replace(/_/g, ' ')}
                                <span className="font-12px">
                                  ({inventory.Details[0].Unit})
                                </span>
                              </TableCell>
                            ) : (
                              <TableCell
                                className="adminTableheading"
                                key={'cell' + index}
                                align={`${
                                  key === 'Utilization_Score'
                                    ? 'center'
                                    : 'left'
                                }`}
                              >
                                {key
                                  .replace(/_/g, ' ')
                                  .replace(/([a-z])([A-Z])/g, '$1 $2')
                                  .replace(/\b\w/g, (match) =>
                                    match.toUpperCase()
                                  )}
                              </TableCell>
                            )
                          )}
                      <TableCell className="adminTableheading">Tags</TableCell>
                      <TableCell className="adminTableheading"></TableCell>
                    </TableRow>
                  ))
                )}
              </TableHead>
              <TableBody>
                {inventoryDetails?.length > 0
                  ? inventoryDetails.map((inventory: any, index: any) =>
                      inventory.Details && inventory.Details.length !== 0 ? (
                        inventory.Details.map(
                          (inventoryDetails: any, i: any) => (
                            <TableRow className="accDetailRow" key={i}>
                              {Object.keys(inventoryDetails).map((key, index) =>
                                key === 'Utilization_Score' ? (
                                  <TableCell
                                    className="inventoryTableCell"
                                    align="left"
                                    key={key + index}
                                  >
                                    <GaugeChart
                                      id="gauge-chart2"
                                      nrOfLevels={5}
                                      percent={inventoryDetails[key] / 5}
                                      className="guageChart"
                                    />
                                    <div className="guageChartText">
                                      {inventoryDetails.Utilization_Score}/5
                                    </div>
                                  </TableCell>
                                ) : key === 'Id' ||
                                  key === 'Unit' ||
                                  key === 'Category' ||
                                  key === 'Tags' ||
                                  key === 'Tag_Resource_Id' ||
                                  key === 'Tag_Modal_Header' ||
                                  key === 'SubscriptionId' ? null : index ===
                                    1 && inventoryDetails.Category ? (
                                  <TableCell
                                    key={key + index}
                                    className="inventoryTableCellLink"
                                    align="left"
                                    onClick={() => {
                                      handleClick(inventoryDetails.Category);
                                    }}
                                  >
                                    {inventoryDetails[key] == null
                                      ? '-'
                                      : inventoryDetails[key]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={key + index}
                                    className="inventoryTableCell"
                                    align="left"
                                  >
                                    {inventoryDetails[key] == null
                                      ? '-'
                                      : inventoryDetails[key]}
                                  </TableCell>
                                )
                              )}
                              <TableCell className="tagCell">
                                <div className="tagScrollBar tagList">
                                  {inventoryDetails.Tags &&
                                  inventoryDetails.Tags.length > 0 ? (
                                    inventoryDetails.Tags.reduce(
                                      (acc: any, row: any) => {
                                        let flag = 0;
                                        acc.map((tag: any) => {
                                          if (tag.key === row.key) flag = 1;
                                        });
                                        if (!flag) acc.push(row);

                                        return acc;
                                      },
                                      []
                                    ).map((tag: any, i: any) => (
                                      <div key={i} className="p-2 ml-4 mr-5">
                                        <span className="p-2  pl-4 pr-4 tagKey  ">
                                          {tag.key}
                                        </span>
                                        <span className="p-2 pl-4 pr-4 tagValue ">
                                          {tag.value}
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <span>-</span>
                                  )}
                                </div>
                              </TableCell>
                              <ProtectedComponent
                                level={PERMISSION_LEVELS.WRITE}
                                permission={tagsPermission}
                              >
                                <TableCell
                                // className="d-flex d-inline mt-10 float-r"
                                >
                                  {inventoryDetails.Tags?.length === 0 ? (
                                    <span
                                      className="mr-5 cursor-pointer"
                                      onClick={(e) => {
                                        setInstanceData(inventoryDetails);
                                        handleTagModal(e, inventoryDetails);
                                      }}
                                      key={index}
                                    >
                                      <AddIcon className="bi bi-plus-square" />
                                    </span>
                                  ) : (
                                    <>
                                      <span
                                        className="mr-5 cursor-pointer svgLogo"
                                        onClick={(e) => {
                                          setInstanceData(inventoryDetails);
                                          handleTagModal(e, inventoryDetails);
                                        }}
                                      >
                                        <EditIcon />
                                      </span>
                                    </>
                                  )}
                                </TableCell>
                              </ProtectedComponent>
                            </TableRow>
                          )
                        )
                      ) : (
                        <TableRow key={'row' + index}>
                          <TableCell
                            className="inventoryTableCell"
                            colSpan={17}
                          >
                            No records found
                          </TableCell>
                        </TableRow>
                      )
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <div className="customizePagination">
          <Pagination
            onChange={handlePageChangeNew}
            className="mt-15"
            size="large"
            color="standard"
            count={Math.ceil(count / 10)}
            variant="outlined"
            shape="rounded"
            page={pageNo}
          />
        </div>
        {openAddTagModal && (
          <AddTagModal
            show={openAddTagModal}
            onClose={closeModal}
            instanceData={instanceData}
            handleTagButton={handleTagButton}
            handleDeleteTag={handleDeleteTag}
            isDuplicateKey={isDuplicateKey}
            responseMessage={responseMessage}
          />
        )}
      </React.Fragment>
    </Fragment>
  );
};

export default React.memo(Inventory);
