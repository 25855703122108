import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      width: '88%',
      margin: '0 auto',
      padding: '50px 15px',
      '& .fa-cog': {
        padding: '0 5px',
      },
    },
    countryList:{
      '& > div':{
        paddingRight:'15px !important'
      }
    },
    innerContainer: {
      padding: '15px 0',
      fontSize: '12px',
    },
    colBtn: {
      padding: 0,
      fontSize: '16px',
      lineHeight: 0,
    },
    headerGrid: {
      backgroundColor: 'white',
      margin: '20px 0px',
    },
    acntHeader: {
      backgroundColor: '#fff',
      padding: '15px',
      margin: '10px 0px',
      cursor: 'pointer    ',
    },
    styleColor: {
      color: '#00c83c',
    },
    styleIcon: {
      padding: '0 5px',
      cursor: 'pointer',
    },
    accordionHeader: {
      background: '#fbfbfb',
      borderTop: '2px solid #1582dc',
      padding: '18px 5px ',
      '& .accordionTitle': {
        padding: '5px 20px',
      },
      '& .actionsBtn': {
        textAlign: 'center',
        padding: '5px 20px',
        color: '#333',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },

    footer: {
      padding: '0 10px',
      '& .yesBtn': {
        background: '#1583dc',
        border: '1px solid #1583dc',
        boxSizing: 'border-box',
        color: 'white',
        width: '250px',
        padding: '15px',
        float: 'left',
        cursor: 'pointer',
        '&:hover': {
          background: '#1678c8',
          border: '1px solid #1678c8',
        },
      },
      '& .noBtn': {
        background: '#777',
        border: '1px solid #777',
        boxSizing: 'border-box',
        color: 'white',
        width: '250px',
        padding: '15px',
        float: 'right',
        cursor: 'pointer',
        '&:hover': {
          background: '#777',
          color: '#fff',
        },
      },
      '& .errMsg': {
        textAlign: 'center',
        padding: '10px 10px',
      },
    },
    warningMessage: {
      padding: '2px 0px',
      color: 'red',
      width: '100%',
      maxWidth: '350px',
      borderRadius: '5px',
      fontSize: '12px',
      textAlign: 'left',
    },
    menuPaper: {
      maxHeight: 400
    }
  })
);

export default useStyles;
